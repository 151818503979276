import { FC } from 'react';
import { capitalize } from 'lodash';
import { Icons, SelectField } from '@jux/ui/components';
import {
  MuiListItemIcon,
  MuiListItemText,
  MuiMenuItem,
} from '@jux/ui/components/common/mui';
import { selectedNodeComponent, useStore } from '@jux/canjux/core';
import { COMMON_SELECT_FIELD_ICON } from '@jux/ui/theme/palette';
import { useDisplayField } from './useDisplayField';
import { getDisplayValueList } from './DisplayField.utils';
import { iconsToDisplayValues } from './DisplayField.interface';

export const DisplayField: FC = () => {
  const { selectedDisplay, handleDisplayChange } = useDisplayField();
  const { tagName } = useStore(selectedNodeComponent) || {};

  return (
    <SelectField
      name="display"
      type="text"
      value={selectedDisplay || ''}
      onChange={(e) => handleDisplayChange(e.target.value)}
    >
      {getDisplayValueList(tagName).map(
        (option) =>
          option && (
            <MuiMenuItem key={option} value={option}>
              <MuiListItemIcon>
                <Icons
                  variant={iconsToDisplayValues[option]}
                  color={COMMON_SELECT_FIELD_ICON}
                />
              </MuiListItemIcon>
              <MuiListItemText primary={capitalize(option)} disableTypography />
            </MuiMenuItem>
          )
      )}
    </SelectField>
  );
};
