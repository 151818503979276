import {
  isNodeSelected,
  selectSelectedNodeIds,
  storeApi,
  useStoreActions,
} from '@jux/canjux/core';
import { MouseEvent, useCallback } from 'react';

export const useSelectCurrentNode = ({ nodeId }: { nodeId: string }) => {
  const {
    commonActions: { resetHoveredNodes, setSelectedNodes },
  } = useStoreActions();

  const getIsNodeSelected = useCallback(
    () => isNodeSelected(nodeId)(storeApi.getState()),
    [nodeId]
  );
  const getSelectedNodes = useCallback(
    () => selectSelectedNodeIds(storeApi.getState()),
    []
  );

  const addToSelection = useCallback(
    (event: MouseEvent) => {
      // if user currently start selecting text inside a text node, there is no need to deselect that particular node when clicking another node
      if (storeApi.getState().textNodeUserSelectionActive) {
        return;
      }

      // When selecting node, unhover all hovered nodes
      resetHoveredNodes();

      setSelectedNodes({
        nodeIds: [nodeId],
        append: true,
      });

      event.stopPropagation();
    },
    [nodeId, resetHoveredNodes, setSelectedNodes]
  );

  const removeFromSelection = useCallback(
    (event: MouseEvent) => {
      // if user currently start selecting text inside a text node, there is no need to deselect that particular node when clicking another node
      if (storeApi.getState().textNodeUserSelectionActive) {
        return;
      }

      const selectedNodes = getSelectedNodes();

      // When selecting node, unhover all hovered nodes
      resetHoveredNodes();

      const nodeIds = selectedNodes.filter((n) => n !== nodeId);
      setSelectedNodes({
        nodeIds,
      });

      event.stopPropagation();
    },
    [getSelectedNodes, nodeId, resetHoveredNodes, setSelectedNodes]
  );

  const replaceSelection = useCallback(
    (event: MouseEvent) => {
      // if user currently start selecting text inside a text node, there is no need to deselect that particular node when clicking another node
      if (storeApi.getState().textNodeUserSelectionActive) {
        return;
      }

      // When selecting node, unhover all hovered nodes
      resetHoveredNodes();

      setSelectedNodes({
        nodeIds: [nodeId],
      });

      event.stopPropagation();
    },
    [nodeId, resetHoveredNodes, setSelectedNodes]
  );

  const selectCurrentNode = useCallback(
    (event: MouseEvent) => {
      // if user currently start selecting text inside a text node, there is no need to deselect that particular node when clicking another node
      if (storeApi.getState().textNodeUserSelectionActive) {
        return;
      }

      const isSelected = getIsNodeSelected();

      // When selecting node, unhover all hovered nodes
      resetHoveredNodes();

      const isMultiSelectionActive = event.shiftKey;

      if (!isMultiSelectionActive) {
        replaceSelection(event);
      } else {
        if (isSelected) {
          removeFromSelection(event);
        } else {
          addToSelection(event);
        }
      }

      event.stopPropagation();
    },
    [
      addToSelection,
      getIsNodeSelected,
      removeFromSelection,
      replaceSelection,
      resetHoveredNodes,
    ]
  );

  return {
    selectCurrentNode,
    addToSelection,
    removeFromSelection,
    replaceSelection,
  };
};
