import { CSSProperties } from 'react';

export type SupportedFlexWrapValues = Extract<
  CSSProperties['flexWrap'],
  'nowrap' | 'wrap'
>;

export const FlexWrapValue: {
  [wrap in SupportedFlexWrapValues]: wrap;
} = {
  nowrap: 'nowrap',
  wrap: 'wrap',
} as const;
