// eslint-disable-next-line @jux/must-import-FC-in-tsx-files
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const BoxShadowDDPIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M3 10V3H10V10H3ZM4.3125 4.3125H8.6875V8.6875H4.3125V4.3125Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
