import {
  CanjuxState,
  CommonActionsParams,
  JuxStoreActionFn,
} from '@jux/canjux/core';
import { isAncestorNode } from './utils';

/**
 * Set the selected nodes
 */
export const setSelectedNodes: JuxStoreActionFn<
  CommonActionsParams['setSelectedNodes'],
  CanjuxState
> = ({ nodeIds, append, state }) => {
  const nodeIdsSet = new Set(nodeIds);
  const nodeIdsToKeep = new Set(
    Array.from(nodeIdsSet).concat(append ? state.selectedNodesStack : [])
  );

  for (const nodeId of nodeIdsSet) {
    for (const selectedNodeId of state.selectedNodesStack) {
      // nodeId is already selected or is an ancestor of a selected node
      // or, nodeId is a descendant of the selected node
      if (
        isAncestorNode({
          ancestorId: nodeId,
          childId: selectedNodeId,
          components: state.components,
        }) ||
        isAncestorNode({
          ancestorId: selectedNodeId,
          childId: nodeId,
          components: state.components,
        })
      ) {
        nodeIdsToKeep.delete(selectedNodeId);
      }
    }
  }

  state.selectedNodesStack = Array.from(nodeIdsToKeep);
  state.selectedNodesStackTimestamp = Date.now();

  // Remove selected nodes from the hovered nodes list
  state.hoveredNodesStack = state.hoveredNodesStack.filter(
    (n) => !state.selectedNodesStack.includes(n.id)
  );
  return state;
};
