export type UITransitions = {
  all: string;
  create: (props: string | string[], customTransition: string) => string;
};

const easing = 'cubic-bezier(0.4, 0, 0.2, 1)';
const duration = '300ms';
const delay = '0s';
const transition = `${duration} ${easing} ${delay}`;

const create = (props: string | string[], customTransition: string): string => {
  if (typeof props === 'string') {
    return `${props} ${customTransition ?? transition}`;
  }

  return props
    .map((prop) => `${prop} ${customTransition ?? transition}`)
    .join(', ');
};

const all = `all ${transition}`;

export const transitions: UITransitions = {
  all,
  create,
};
