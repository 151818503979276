import { GroupedTokens, GroupedTokensByType } from './getGroupedTokens';

export const recursivelyFindExistingTokenPaths = ({
  data: { groups, tokens },
  tokensPaths,
  groupsPaths,
}: {
  data: GroupedTokens;
  tokensPaths: Array<string>;
  groupsPaths: Array<string>;
}): {
  tokensPaths: Array<string>;
  groupsPaths: Array<string>;
} => {
  const newTokensPaths = tokens.map(({ path }) => path);
  tokensPaths.push(...newTokensPaths);

  groups.forEach((group) => {
    groupsPaths.push(group.path);

    recursivelyFindExistingTokenPaths({
      data: group,
      tokensPaths,
      groupsPaths,
    });
  });

  return {
    tokensPaths,
    groupsPaths,
  };
};

export const getExistingPaths = (
  groupsByType: GroupedTokensByType
): {
  tokenPaths: Array<string>;
  groupPaths: Array<string>;
} => {
  const existingTokenPaths: Array<string> = [];
  const existingGroupsPaths: Array<string> = [];

  if (groupsByType) {
    Object.values(groupsByType).forEach((groups) => {
      const { groupsPaths, tokensPaths } = recursivelyFindExistingTokenPaths({
        data: groups,
        groupsPaths: [groups.path],
        tokensPaths: [],
      });

      existingTokenPaths.push(...tokensPaths);
      existingGroupsPaths.push(...groupsPaths);
    });
  }

  return {
    tokenPaths: existingTokenPaths,
    groupPaths: existingGroupsPaths,
  };
};
