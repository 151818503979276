import { FC } from 'react';
import FocusTrapReact from 'focus-trap-react';

export const FocusTrap: FC<FocusTrapReact.Props> = ({
  children,
  active = true,
  paused = false,
  focusTrapOptions,
  containerElements,
}) => {
  return (
    <FocusTrapReact
      active={active}
      paused={paused}
      focusTrapOptions={{
        returnFocusOnDeactivate: false,
        // using fallbackFocus won't trigger the returnFocusOnDeactivate option
        // - which cause the focus to be set to the previous focused element again on deactivation
        ...focusTrapOptions,
      }}
      containerElements={containerElements}
    >
      {children}
    </FocusTrapReact>
  );
};
