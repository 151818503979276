import { FC } from 'react';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
import { IconBaseProps } from '../../Icons.interface';

export const ChipElementPanelIcon: FC<IconBaseProps> = ({
  textColor,
  ...props
}) => (
  <CustomIcon
    tag="svg"
    width="48"
    height="25"
    attr={{
      viewBox: '0 0 48 25',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg',
    }}
    child={[
      {
        tag: 'g',
        attr: {
          clipPath: 'url(#clip0_2239_19945)',
        },
        child: [
          {
            tag: 'rect',
            attr: {
              x: '2',
              y: '2.5',
              width: '44',
              height: '20',
              rx: '10',
              stroke: 'currentColor',
              strokeWidth: '4',
            },
            child: [],
          },
          {
            tag: 'path',
            attr: {
              d: 'M34.5 11C35.3284 11 36 11.6716 36 12.5C36 13.3284 35.3284 14 34.5 14H13.5C12.6716 14 12 13.3284 12 12.5C12 11.6716 12.6716 11 13.5 11H34.5Z',
              fill: 'currentColor',
            },
            child: [],
          },
        ],
      },
      {
        tag: 'defs',
        attr: {},
        child: [
          {
            tag: 'clipPath',
            attr: {
              id: 'clip0_2239_19945',
            },
            child: [
              {
                tag: 'rect',
                attr: {
                  width: '48',
                  height: '24',
                  fill: 'currentColor',
                  transform: 'translate(0 0.5)',
                },
                child: [],
              },
            ],
          },
        ],
      },
    ]}
    {...props}
  />
);
