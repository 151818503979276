import { FC, useCallback } from 'react';
import { DDPSubModulesKeys, PanelsKeys } from '@jux/ui/components/editor/state';
import { IconButton } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { usePanelStateByKey } from '@jux/ui/components/editor/hooks';
import { SubModule } from '../../../base';
import { useSubModule } from '../../../hooks';

export const EditProperties: FC = () => {
  const { shouldRender: shouldRenderEditPropertiesSubModule } = useSubModule(
    DDPSubModulesKeys.editProperties
  );
  const { togglePanel: togglePropertiesPanel } = usePanelStateByKey(
    PanelsKeys.properties
  );

  const handleClick = useCallback(() => {
    // // TODO: fix - when clicking on the button while the panel is open the properties panel is closing and opening again
    togglePropertiesPanel();
  }, [togglePropertiesPanel]);

  if (!shouldRenderEditPropertiesSubModule) return null;

  return (
    <SubModule>
      <MuiGrid marginTop="8px">
        <IconButton
          onClick={handleClick}
          variant="secondary"
          fullWidth
          icon="EDIT"
        >
          Edit properties
        </IconButton>
      </MuiGrid>
    </SubModule>
  );
};
