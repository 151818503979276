/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const DetachTokenDDPIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M5.5 1C5.77614 1 6 1.22386 6 1.5V3.5C6 3.77614 5.77614 4 5.5 4C5.22386 4 5 3.77614 5 3.5V1.5C5 1.22386 5.22386 1 5.5 1Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M9.94203 2.16385C11.016 1.08991 12.7572 1.08991 13.8311 2.16385C14.9051 3.2378 14.9051 4.979 13.8311 6.05294L11.3562 8.52782C11.161 8.72308 10.8444 8.72308 10.6491 8.52782L10.2956 8.17426C10.1003 7.979 10.1003 7.66242 10.2956 7.46716L12.7705 4.99228C13.2586 4.50413 13.2586 3.71267 12.7705 3.22451C12.2823 2.73636 11.4908 2.73636 11.0027 3.22451L8.52782 5.69939C8.33255 5.89465 8.01597 5.89465 7.82071 5.69939L7.46715 5.34584C7.27189 5.15057 7.27189 4.83399 7.46715 4.63873L9.94203 2.16385Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M2.16385 13.8311C3.2378 14.9051 4.979 14.9051 6.05294 13.8311L8.52782 11.3562C8.72308 11.161 8.72308 10.8444 8.52782 10.6491L8.17426 10.2956C7.979 10.1003 7.66242 10.1003 7.46716 10.2956L4.99228 12.7705C4.50413 13.2586 3.71267 13.2586 3.22451 12.7705C2.73636 12.2823 2.73636 11.4908 3.22451 11.0027L5.69939 8.52782C5.89465 8.33255 5.89465 8.01597 5.69939 7.82071L5.34584 7.46716C5.15057 7.27189 4.83399 7.27189 4.63873 7.46715L2.16386 9.94203C1.08991 11.016 1.08991 12.7572 2.16385 13.8311Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M10.5 15C10.2239 15 10 14.7761 10 14.5V12.5C10 12.2239 10.2239 12 10.5 12C10.7761 12 11 12.2239 11 12.5V14.5C11 14.7761 10.7761 15 10.5 15Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M12 10.5C12 10.7761 12.2239 11 12.5 11H14.5C14.7761 11 15 10.7761 15 10.5C15 10.2239 14.7761 10 14.5 10H12.5C12.2239 10 12 10.2239 12 10.5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M4 5.5C4 5.22386 3.77614 5 3.5 5H1.5C1.22386 5 1 5.22386 1 5.5C1 5.77614 1.22386 6 1.5 6H3.5C3.77614 6 4 5.77614 4 5.5Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
