import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { PropertiesActions } from './PropertiesActions';
import { PropertiesFields } from './PropertiesFields';

export const PropertiesPanelContent: FC = () => (
  <MuiGrid container item>
    <PropertiesFields />
    <PropertiesActions />
  </MuiGrid>
);
