import { MouseEventHandler, useCallback, useMemo } from 'react';
import { LayersPanelItem } from '@jux/ui/components/editor/components/panels/layers/state/getLayersItemsRecursive';
import { useSetLayersPanelState } from './useSetLayersPanelState';
import { useLayersPanelVisibleItems } from './useLayersPanelVisibleItems';

export const useLayersPanelExpansion = ({
  items,
}: {
  items: Map<string, LayersPanelItem>;
}) => {
  const setState = useSetLayersPanelState();

  const visibleItems = useLayersPanelVisibleItems({ items });

  const collapseAll: MouseEventHandler = useCallback(
    (event) => {
      if (event) {
        event.stopPropagation();
      }
      setState((prev) => ({
        ...prev,
        expandedPaths: new Set<string>(
          // Replace by copying all expanded paths except for the root nodes
          Array.from(visibleItems.values())
            .filter((item) => prev.expandedPaths.has(item.path) && !item.isRoot)
            .map((item) => item.path)
        ),
      }));
    },
    [setState, visibleItems]
  );

  const expandAll: MouseEventHandler = useCallback(
    (event) => {
      if (event) {
        event.stopPropagation();
      }
      setState((prev) => ({
        ...prev,
        expandedPaths: new Set<string>(
          // Add the root nodes to expanded paths
          Array.from(prev.expandedPaths).concat(
            Array.from(visibleItems.values())
              .filter((item) => item.isRoot && item.hasExpander)
              .map((item) => item.path)
          )
        ),
      }));
    },
    [setState, visibleItems]
  );

  const toggleItemExpansion = useCallback(
    (itemPath: string) => {
      setState((prev) => {
        const newState = { ...prev };

        if (newState.expandedPaths.has(itemPath)) {
          newState.expandedPaths.delete(itemPath);
        } else {
          newState.expandedPaths.add(itemPath);
        }

        return newState;
      });
    },
    [setState]
  );

  const expandItem = useCallback(
    (itemPath: string) => {
      setState((prev) => {
        const newState = structuredClone(prev);

        if (!newState.expandedPaths.has(itemPath)) {
          newState.expandedPaths.add(itemPath);
        }

        return newState;
      });
    },
    [setState]
  );

  const setExpanderIconsVisibility = useCallback(
    (show: boolean) => {
      setState((prev) => ({
        ...prev,
        shouldShowGlobalExpanders: show,
      }));
    },
    [setState]
  );

  const hideGlobalExpanders = useCallback(
    () => setExpanderIconsVisibility(false),
    [setExpanderIconsVisibility]
  );

  const showGlobalExpanders = useCallback(
    () => setExpanderIconsVisibility(true),
    [setExpanderIconsVisibility]
  );

  const isAllRootsCollapsed = useMemo(
    () =>
      // Check if all root nodes are collapsed
      Array.from(visibleItems.values())
        .filter((item) => item.isRoot && item.hasExpander)
        .every((item) => !item.isExpanded),
    [visibleItems]
  );

  return {
    collapseAll,
    expandAll,
    expandItem,
    hideGlobalExpanders,
    isAllRootsCollapsed,
    showGlobalExpanders,
    toggleItemExpansion,
  };
};
