import { useEffect } from 'react';
import { useDebounceCallback } from 'usehooks-ts';
import { useStore } from '@jux/canjux/core';
import { useUserDefaultCanvasOptions } from './useUserDefaultCanvasOptions';

const TIMEOUT = 1000;

export const useSetDefaultViewportEffect = () => {
  const { setDefaultViewport } = useUserDefaultCanvasOptions();
  const debouncedSetDefaultViewport = useDebounceCallback(
    setDefaultViewport,
    TIMEOUT
  );

  const { transform, canvasId } = useStore((state) => ({
    transform: state.transform,
    canvasId: state.canvases[state.currentCanvasName]?.id,
  }));

  useEffect(() => {
    debouncedSetDefaultViewport({ transform, canvasId });
    // TODO: need to update presence
  }, [debouncedSetDefaultViewport, transform, canvasId]);
};
