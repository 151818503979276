import { FC } from 'react';
import {
  LoadingButtonWithIcon as Button,
  LoadingButtonWithIconProps as ButtonProps,
  IconsVariantsType,
} from '@jux/ui/components';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { TOKENS_CREATE_GROUP_BUTTON_TEXT_COLOR } from '@jux/ui/theme/palette';

export const CreateActionButton: FC<
  ButtonProps & {
    label: string;
    icon: IconsVariantsType;
  }
> = ({ label, icon, sx, ...props }) => (
  <Button
    disableTooltip
    icon={icon}
    variant="text"
    {...props}
    typographyVariant="body-regular"
    sx={mergeTyped(
      {
        width: 'fit-content',
        padding: 0,
        color: TOKENS_CREATE_GROUP_BUTTON_TEXT_COLOR,
        transition: (theme) => theme.drimz.transitions.all,

        '&:hover': {
          backgroundColor: (theme) => theme.drimz.palette.transparent,
          color: (theme) => theme.drimz.palette.text.primary,
        },
      },
      sx
    )}
  >
    {label}
  </Button>
);
