import { KeyboardEvent } from 'react';
import { toast } from '@jux/ui/toast';
import { TextValidatorFunction } from '@jux/ui/utils/textValidators';
import { TypographyElement } from '../typography';

export const isTypographyValid = (
  label: string,
  customValidators?: Array<TextValidatorFunction>
) => {
  if (!customValidators) return true;

  return customValidators.every((validator) => {
    const { isValid, message } = validator(label);

    if (!isValid && message) {
      toast.error(message);
    }

    return isValid;
  });
};

export const handleMaxLength = (
  e: KeyboardEvent<TypographyElement>,
  maxLength?: number
) => {
  const { code: keyCode, currentTarget: element, ctrlKey, metaKey } = e;

  const text = element?.innerText;

  const passedMaxLength = maxLength && text?.length >= maxLength;
  const isAllowedKey = [
    'Backspace',
    'Delete',
    'Tab',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
    'Shift',
    'Control',
    'Alt',
    'Meta',
  ].includes(keyCode);
  const isAllowedKeyWithMeta = ['a', 'c', 'x', 'v'].includes(keyCode);
  const hasCtrlKey = ctrlKey || metaKey;
  if (
    passedMaxLength &&
    !isAllowedKey &&
    !isAllowedKeyWithMeta &&
    !hasCtrlKey
  ) {
    e.preventDefault();
  }
};
