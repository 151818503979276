import { useCallback } from 'react';
import { toast } from '@jux/ui/toast';
import { useStoreActions } from '@jux/canjux/core';
import { CORE, CORE_TOKEN_SET_NAME, isCoreTokenSetName } from '@jux/types';
import { useTokenSetState } from '@jux/ui/state';

export const useRenameTokenSet = () => {
  const { currentTokenSetId, isCoreTokenSet } = useTokenSetState();
  const {
    tokenSetsActions: { renameTokenSet: renameTokenSetAction },
  } = useStoreActions();

  const renameTokenSet = useCallback(
    (newName: string) =>
      new Promise<boolean>((resolve) => {
        if (!currentTokenSetId) return resolve(false);

        if (isCoreTokenSet) {
          toast.error(`Cannot rename '${CORE}' token set`);
          return resolve(false);
        }

        if (isCoreTokenSetName(newName)) {
          toast.error(`Cannot name token set as '${CORE_TOKEN_SET_NAME}'`);
          return resolve(false);
        }

        renameTokenSetAction({ tokenSetId: currentTokenSetId, name: newName });

        resolve(true);
      }),
    [currentTokenSetId, isCoreTokenSet, renameTokenSetAction]
  );

  return {
    renameTokenSet,
  };
};
