import * as CSS from 'csstype';

export enum ChangeAction {
  StyleChange = 'style_change',
  DeleteElement = 'delete_element',
  AddElement = 'add_element',
  MoveElement = 'move_element',
}

export interface ModuleChanges {
  [drimzID: string]: { changeAction: ChangeAction; actionData: CSS.Properties };
}

export declare type ComponentChanges = {
  [modulePath: string]: ModuleChanges;
};
