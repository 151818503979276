import { FC } from 'react';
import { ToggleSwitchProps } from './ToggleSwitch.interface';
import * as S from './ToggleSwitch.style';

export const ToggleSwitch: FC<ToggleSwitchProps> = ({
  checked,
  disabled,
  handleToggle,
}) => (
  <S.ToggleSwitchWrapper
    checked={checked}
    disabled={disabled}
    onClick={handleToggle}
    tabIndex={disabled ? -1 : 0}
  >
    <input
      type="checkbox"
      checked={checked === true}
      data-aria-checked={checked === true}
      onChange={handleToggle}
    />
    <S.ToggleSwitchKnob />
  </S.ToggleSwitchWrapper>
);
