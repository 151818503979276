import { useCallback, useMemo } from 'react';
import merge from 'lodash/merge';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import {
  BoxShadow,
  stringifyShadowsValues,
} from '@jux/ui/utils/parseBoxShadow';
import {
  useFieldValues,
  useSetFieldValue,
  useSetSelectedElementStylesOnDOM,
} from '../../../../hooks';
import { emptyShadow, parseShadowsValues } from './useShadowsFields.utils';

const FIELD_KEY = DDPPrimitiveFieldsKeys.boxShadow;

export const useShadowsFields = () => {
  const { value, initialValue } = useFieldValues(FIELD_KEY);
  const { saveValue: setFieldValue } = useSetFieldValue(FIELD_KEY);
  const setSelectedElementStylesOnDOM = useSetSelectedElementStylesOnDOM();

  const parsedShadowsValues = useMemo(() => parseShadowsValues(value), [value]);
  const parsedInitialShadowsValues = useMemo(
    () => parseShadowsValues(initialValue),
    [initialValue]
  );

  const shadowsValues = useMemo(() => {
    if (parsedShadowsValues) {
      return parsedShadowsValues;
    }

    if (parsedInitialShadowsValues) {
      return parsedInitialShadowsValues;
    }

    return [emptyShadow];
  }, [parsedShadowsValues, parsedInitialShadowsValues]);

  const saveShadowChanges = useCallback(
    (newShadowsValues: Array<BoxShadow>, isChangeComplete?: boolean) => {
      const stringifiedShadows = stringifyShadowsValues(newShadowsValues);
      if (isChangeComplete || isChangeComplete === undefined) {
        setFieldValue(stringifiedShadows);
      } else {
        setSelectedElementStylesOnDOM(FIELD_KEY, stringifiedShadows);
      }
    },
    [setFieldValue, setSelectedElementStylesOnDOM]
  );

  const updateShadowValues = useCallback(
    (newShadowValues: Partial<BoxShadow>, index: number) => {
      const currentShadowValues = shadowsValues[index];

      const mergedCurrentShadowValues = merge(
        {},
        currentShadowValues,
        newShadowValues
      );

      const newShadowsValues = [...shadowsValues];
      newShadowsValues[index] = mergedCurrentShadowValues;

      return newShadowsValues;
    },
    [shadowsValues]
  );

  // TODO: add support for multiple shadows
  const firstShadowValue: BoxShadow | undefined = useMemo(
    () => parsedShadowsValues?.[0],
    [parsedShadowsValues]
  );
  const firstShadowInitialValue: BoxShadow | undefined = useMemo(
    () => parsedInitialShadowsValues?.[0] ?? emptyShadow,
    [parsedInitialShadowsValues]
  );

  const saveFirstShadowChanges = useCallback(
    (newShadowValues: Partial<BoxShadow>, isChangeComplete?: boolean) => {
      const newShadowsValues = updateShadowValues(newShadowValues, 0);
      saveShadowChanges([newShadowsValues[0]], isChangeComplete);
    },
    [saveShadowChanges, updateShadowValues]
  );

  return {
    value: firstShadowValue,
    initialValue: firstShadowInitialValue,
    saveChanges: saveFirstShadowChanges,
  };
};
