import { FC } from 'react';
import { Floors, useOverlaysZIndex } from '@jux/ui/hooks';
import { LayoutProps } from './Layout.interface';
import * as S from './Layout.style';

export const Layout: FC<LayoutProps> = ({
  navbar,
  content,
  sidebar,
  contentFullscreen,
}) => {
  const navbarZIndex = useOverlaysZIndex({
    id: 'layout-navbar',
    floor: Floors.navbar,
  });

  return (
    <S.LayoutRoot>
      <S.LayoutNavbar
        container
        zIndex={navbarZIndex}
        position={contentFullscreen ? 'fixed' : 'sticky'}
      >
        {navbar}
      </S.LayoutNavbar>
      {sidebar}
      <S.LayoutContent container>{content}</S.LayoutContent>
    </S.LayoutRoot>
  );
};
