import { BorderTokenFormProps } from '@jux/ui/components/tokens/token-drawer/forms/types';
//import { colorTokenValueParser } from '@jux/ui/components/tokens/token-drawer/forms/colorTokenForm/ColorTokenForm.utils';

export const parseBorderTokenValue = (
  value: BorderTokenFormProps['initialValues']['value']
) => {
  // if (value?.color) {
  //   value.color =
  //     typeof value.color === 'string'
  //       ? value.color
  //       : colorTokenValueParser.stringToObject.parse(value.color);
  // }

  return value;
};
