import { trimLowerCaseStr } from '@jux/ui/utils/trimLowerCaseStr';
import {
  SupportedAlignItemsValues,
  AlignItemsValue,
} from './AlignItemsField.interface';

export const parseAlignItems = (
  value: string
): SupportedAlignItemsValues | undefined => {
  switch (trimLowerCaseStr(value)) {
    case 'flex-start':
    case 'start':
      return AlignItemsValue.flexStart;
    case 'center':
      return AlignItemsValue.center;
    case 'flex-end':
    case 'end':
      return AlignItemsValue.flexEnd;
    case 'stretch':
      return AlignItemsValue.stretch;
    case 'baseline':
      return AlignItemsValue.baseline;
  }

  return undefined;
};
