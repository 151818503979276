import { useCallback } from 'react';
import {
  selectedNodeComponent,
  useStore,
  useStoreActions,
} from '@jux/canjux/core';
import { useTrackEvents } from '@jux/ui/hooks';
import { useSelectedNodeId } from '@jux/ui/components/editor/hooks';
import { NodeType } from '@jux/data-entities';

export const useHandleRenamePropValue = () => {
  const {
    propsActions: { renameComponentPropValue },
  } = useStoreActions();

  const selectedNodeData = useStore(selectedNodeComponent);
  const selectedNodeId = useSelectedNodeId();

  const { trackPropValueRenamedEvent } = useTrackEvents();

  const renamePropValue = useCallback(
    (
      propName: string,
      newOptionValue: {
        value: string;
        newValue: string;
      }
    ): boolean => {
      if (
        !selectedNodeId ||
        !selectedNodeData ||
        (selectedNodeData.type !== NodeType.LIBRARY_COMPONENT &&
          selectedNodeData.type !== NodeType.LOCAL_COMPONENT)
      )
        return false;

      try {
        renameComponentPropValue({
          componentId: selectedNodeId,
          propName,
          propValueLabel: newOptionValue.value,
          newPropValue: newOptionValue.newValue,
        });

        trackPropValueRenamedEvent({
          componentUUID: selectedNodeData.id,
          tagName: selectedNodeData.tagName,
          propName,
          oldPropValueName: newOptionValue.value,
          newPropValueName: newOptionValue.newValue,
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    [
      renameComponentPropValue,
      selectedNodeData,
      selectedNodeId,
      trackPropValueRenamedEvent,
    ]
  );

  return {
    handleRenamePropValue: renamePropValue,
  };
};
