import { useCallback } from 'react';
import { PoppersKey, usePopper } from '@jux/ui/components';
import { Floors } from '@jux/ui/hooks';
import { GroupHeaderActionsPopper } from '../tokenSet/GroupHeaderActionsPopper';

export const useOpenGroupHeaderActionsPopper = () => {
  const { open, close, isOpen, currentlyOpenedInnerKey } = usePopper(
    PoppersKey.groupHeaderActions
  );

  const withClose = useCallback(
    (callback: () => void) => {
      callback();
      close();
    },
    [close]
  );

  const openGroupHeaderActionsPopper = useCallback(
    ({
      sourceElement,
      disableDeleteGroup,
      onCreateSubGroup,
      onDeleteGroup,
      onCreateToken,
      path,
    }: {
      sourceElement: HTMLElement;
      disableDeleteGroup: boolean;
      onCreateSubGroup: () => void;
      onDeleteGroup: () => void;
      onCreateToken: () => void;
      path: string;
    }) => {
      open({
        innerKey: path,
        content: (
          <GroupHeaderActionsPopper
            onCreateSubGroup={() => withClose(onCreateSubGroup)}
            onDeleteGroup={() => withClose(onDeleteGroup)}
            onCreateToken={() => withClose(onCreateToken)}
            disableDelete={disableDeleteGroup}
          />
        ),
        sourceElement,
        styles: {
          direction: 'ltr',
          positioning: 'bottom-left',
          offset: {
            top: -1,
          },
          floor: Floors.innerNavbar,
        },
      });
    },
    [open, withClose]
  );

  const isGroupHeaderActionsPopperOpen = useCallback(
    (path: string) => isOpen(path) && currentlyOpenedInnerKey === path,
    [currentlyOpenedInnerKey, isOpen]
  );

  return {
    openGroupHeaderActionsPopper,
    isGroupHeaderActionsPopperOpen,
  };
};
