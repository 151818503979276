import { CanjuxState, PlaceholderMode } from '@jux/canjux/core';
import { TEXT_CONTENT_PROP_NAME } from '@jux/types';
import { createSelector } from 'reselect';
import { getResolvedSourceComponentData } from './utils/getResolvedSourceComponentData';

export const selectHasTextPropAndTagName = createSelector(
  [(state: CanjuxState) => state.components],
  (components) => (id: string) => {
    const component = getResolvedSourceComponentData({
      component: components[id],
      components,
    });

    // check if the source node has the text prop or is an input/textarea element with "value" or "placeholder", because not necessarily all nodes have it
    return {
      hasTextProp:
        TEXT_CONTENT_PROP_NAME in (component?.config.props ?? {}) ||
        PlaceholderMode.text in (component?.config.props ?? {}) ||
        PlaceholderMode.placeholder in (component?.config.props ?? {}),
      tagName: component?.tagName,
    };
  }
);
