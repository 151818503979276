import { Colors } from '../base/base-theme-colors';
import { palette, TRANSPARENCY_PATTERN_BACKGROUND } from '../palette';

export const darkColors: Colors = {
  // Primary
  primaryColor: '#907CFF',

  // Tooltip
  tooltipTextColor: '#000000',
  tooltipBackground: '#FAFAFA',

  // Toast
  toastBackground: '#000000',

  // Backdrop
  backdrop: 'rgba(0, 0, 0, 0.1)',

  // Severity colors
  error: '#FFFFFF',
  success: '#4caf50',
  info: '#2196F3',
  warning: '#D14343',

  // Border
  primaryBorder: '#474747',
  secondaryBorder: '#222222',
  hoverBorder: '#111111',

  // Background
  primaryBackground: '#141414',
  hoverBackground: '#272727',
  secondaryBackground: '#474747',
  secondaryHoverBackground: '#272727',
  navigationBackground: '#141414',
  selectedBackground: palette.naturals600, // TODO: make sure this is the right color
  selectedHoverBackground: palette.naturals500, // TODO: make sure this is the right color

  // Text
  primaryText: '#E0E0E0',
  secondaryText: '#9E9E9E',
  contrastText: '#FFFFFF',
  buttonText: '#FFFFFF',
  disabledText: '#474747',

  // Shadows
  primaryShadow: 'rgba(0, 0, 0, 0.20)',

  // Other
  transparencyPatternBackground: TRANSPARENCY_PATTERN_BACKGROUND,
};
