import { FocusEvent, useCallback, useState } from 'react';
import { InputFieldProps } from '../input';

// TODO: maybe move this logic into `useTokenSelectionAutocomplete()`?
export const useOptionsMenu = ({
  onBlur,
  triggerInputBlur,
  setIsInputFocused,
  forceShowOptionsMenu = false,
}: {
  onBlur?: InputFieldProps['onBlur'];
  triggerInputBlur: () => void;
  setIsInputFocused: (isFocused: boolean) => void;
  forceShowOptionsMenu?: boolean;
}) => {
  const [showOptionMenu, setShowOptionMenu] = useState(false);

  const activateDrawer = useCallback(() => {
    setIsInputFocused(true);
    setShowOptionMenu(true);
  }, [setIsInputFocused]);

  const deactivateOptionMenu = useCallback(() => {
    triggerInputBlur();
    setIsInputFocused(false);
    setShowOptionMenu(false);
  }, [setIsInputFocused, triggerInputBlur]);

  const handleOnFocus = useCallback(() => {
    activateDrawer();
  }, [activateDrawer]);

  const handleOnBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      deactivateOptionMenu();
      onBlur?.(e);
    },
    [onBlur, deactivateOptionMenu]
  );

  const handleSelection = useCallback(() => {
    deactivateOptionMenu();
  }, [deactivateOptionMenu]);

  return {
    showOptionMenu: forceShowOptionsMenu || showOptionMenu,
    handleOnBlur,
    handleOnFocus,
    handleSelection,
  };
};
