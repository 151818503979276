import { TokenSetData } from '@jux/data-entities';
import { DesignTokensParser } from '@jux/design-tokens';
import { getExistingPaths } from './getExistingPaths';
import { getGroupedTokens } from './getGroupedTokens';

export const formatTokenSetData = ({
  tokenSet,
}: {
  tokenSet: TokenSetData;
}) => {
  const parser = new DesignTokensParser(tokenSet.value);

  const aliasesMap = parser.getAliasesMap();
  const valuesMap = parser.getValuesMap();

  const groupsByType = getGroupedTokens({
    aliasesMap,
    valuesMap,
    tokens: tokenSet.value,
    isCoreTokenSet: false,
    parser,
  });

  const { groupPaths, tokenPaths } = getExistingPaths(groupsByType);

  const coreGroupsByType = getGroupedTokens({
    aliasesMap,
    valuesMap,
    tokens: tokenSet.value.core,
    isCoreTokenSet: true,
    parser,
  });

  const { groupPaths: coreGroupPaths, tokenPaths: coreTokenPaths } =
    getExistingPaths(coreGroupsByType);

  return {
    ...tokenSet,
    aliasesMap,
    core: {
      groupsByType: coreGroupsByType,
      existingTokenPaths: coreTokenPaths,
      existingGroupsPaths: coreGroupPaths,
    },
    existingGroupsPaths: groupPaths,
    existingTokenPaths: tokenPaths,
    groupsByType,
    valuesMap,
  };
};
