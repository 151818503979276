import {
  ComponentActionsParams,
  JuxStore,
  JuxStoreActionFn,
  setLayersData,
} from '@jux/canjux/core';
import { NodeType } from '@jux/data-entities';
import { getLocalComponentDependencies } from '../helpers/getLocalComponentDependencies';

/**
 * Add a Local Component and all of its dependencies that are Local components to Library
 */
export const addComponentToLibrary: JuxStoreActionFn<
  ComponentActionsParams['addComponentToLibrary'],
  JuxStore
> = ({ componentId, state }) => {
  const component = state.components[componentId];
  if (component.type !== NodeType.LOCAL_COMPONENT) {
    return state;
  }

  const localComponentDependencies = getLocalComponentDependencies({
    componentId,
    components: state.components,
  });

  // Add all dependencies that are Local components to library
  for (const localCompDepId of localComponentDependencies) {
    const comp = state.components[localCompDepId];
    if (comp.type === NodeType.LOCAL_COMPONENT) {
      comp.type = NodeType.LIBRARY_COMPONENT;
      comp.updatedAt = new Date().getTime();
    }
  }

  // Add chosen component to library
  component.type = NodeType.LIBRARY_COMPONENT;
  component.updatedAt = new Date().getTime();

  setLayersData(state);

  return state;
};
