import { CanjuxState } from '@jux/canjux/core';
import type { Draft as WritableDraft } from 'mutative';

export const isAncestorNode = ({
  ancestorId,
  childId,
  components,
}: {
  ancestorId: string;
  childId: string;
  components: WritableDraft<CanjuxState>['components'];
}) => {
  let child = components[childId];
  while (child?.parentId) {
    if (child.parentId === ancestorId) {
      return true;
    }
    child = components[child.parentId];
  }
  return false;
};
