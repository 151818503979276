import { ModulesKeys } from '../components/panels/DDP/DDP.interface';

export type BaseSubModuleState = {
  shouldRender: boolean | ((...args: Array<any>) => boolean);
};

type MultiViewSubModule<T> = T & {
  isOpen: boolean;
};

type SingleViewSubModule<T> = T & {
  isOpen?: never;
};

// SubModules by Module
type BackgroundSubModules = {
  backgroundColor: SingleViewSubModule<BaseSubModuleState>;
};
type BorderSubModules = {
  borderColor: SingleViewSubModule<BaseSubModuleState>;
  borderRadius: SingleViewSubModule<BaseSubModuleState>;
  borderStyle: SingleViewSubModule<BaseSubModuleState>;
  borderWidth: SingleViewSubModule<BaseSubModuleState>;
};
type ColorSubModule = {
  color: SingleViewSubModule<BaseSubModuleState>;
};
type ContextSubModules = {
  parentContext: SingleViewSubModule<BaseSubModuleState>;
};
type EffectsSubModules = {
  boxShadow: SingleViewSubModule<BaseSubModuleState>;
};
type InstanceSubModules = object;
type LayoutSubModules = {
  padding: MultiViewSubModule<BaseSubModuleState>;
  margin: MultiViewSubModule<BaseSubModuleState>;
  display: SingleViewSubModule<BaseSubModuleState>;
  flexDirectionAndWrapping: SingleViewSubModule<BaseSubModuleState>;
  alignItems: SingleViewSubModule<BaseSubModuleState>;
  justifyContent: SingleViewSubModule<BaseSubModuleState>;
  gap: SingleViewSubModule<BaseSubModuleState>;
};
type OpacitySubModules = {
  opacity: SingleViewSubModule<BaseSubModuleState>;
};
type PropsSubModules = {
  assetContent: SingleViewSubModule<BaseSubModuleState>;
  dynamicProperties: SingleViewSubModule<BaseSubModuleState>;
  editProperties: SingleViewSubModule<BaseSubModuleState>;
  nodeInteractiveState: SingleViewSubModule<BaseSubModuleState>;
  textContent: SingleViewSubModule<BaseSubModuleState>;
};
type SizeSubModules = {
  height: SingleViewSubModule<BaseSubModuleState>;
  width: SingleViewSubModule<BaseSubModuleState>;
  position: SingleViewSubModule<BaseSubModuleState>;
  relativeTo: SingleViewSubModule<BaseSubModuleState>;
  constraints: MultiViewSubModule<BaseSubModuleState>;
  zIndex: SingleViewSubModule<BaseSubModuleState>;
};
type TextSubModules = {
  textColor: SingleViewSubModule<BaseSubModuleState>;
  alignment: SingleViewSubModule<BaseSubModuleState>;
  typography: SingleViewSubModule<BaseSubModuleState>;
};

export type DDPSubModulesState = BackgroundSubModules &
  BorderSubModules &
  ColorSubModule &
  ContextSubModules &
  EffectsSubModules &
  InstanceSubModules &
  LayoutSubModules &
  OpacitySubModules &
  PropsSubModules &
  SizeSubModules &
  TextSubModules;

export type DDPSubModulesStateKeys = keyof DDPSubModulesState;

export const DDPSubModulesKeys: {
  [key in DDPSubModulesStateKeys]: key;
} = {
  // Background
  backgroundColor: 'backgroundColor',

  // Border
  borderColor: 'borderColor',
  borderRadius: 'borderRadius',
  borderStyle: 'borderStyle',
  borderWidth: 'borderWidth',

  // Color
  color: 'color',

  // Context
  parentContext: 'parentContext',

  // Effects
  boxShadow: 'boxShadow',

  // Layout
  padding: 'padding',
  margin: 'margin',
  display: 'display',
  flexDirectionAndWrapping: 'flexDirectionAndWrapping',
  alignItems: 'alignItems',
  justifyContent: 'justifyContent',
  gap: 'gap',

  // Opacity
  opacity: 'opacity',

  // Props
  assetContent: 'assetContent',
  dynamicProperties: 'dynamicProperties',
  editProperties: 'editProperties',
  nodeInteractiveState: 'nodeInteractiveState',
  textContent: 'textContent',

  // Size
  width: 'width',
  height: 'height',
  position: 'position',
  relativeTo: 'relativeTo',
  constraints: 'constraints',
  zIndex: 'zIndex',

  // Text
  textColor: 'textColor',
  alignment: 'alignment',
  typography: 'typography',
};

export const DDPSubModulesByModule: {
  [ModulesKeys.background]: { [key in keyof BackgroundSubModules]: key };
  [ModulesKeys.border]: { [key in keyof BorderSubModules]: key };
  [ModulesKeys.color]: { [key in keyof ColorSubModule]: key };
  [ModulesKeys.context]: { [key in keyof ContextSubModules]: key };
  [ModulesKeys.effects]: { [key in keyof EffectsSubModules]: key };
  [ModulesKeys.instance]: { [key in keyof InstanceSubModules]: key };
  [ModulesKeys.layout]: { [key in keyof LayoutSubModules]: key };
  [ModulesKeys.opacity]: { [key in keyof OpacitySubModules]: key };
  [ModulesKeys.props]: { [key in keyof PropsSubModules]: key };
  [ModulesKeys.size]: { [key in keyof SizeSubModules]: key };
  [ModulesKeys.text]: { [key in keyof TextSubModules]: key };
} = {
  background: {
    backgroundColor: DDPSubModulesKeys.backgroundColor,
  },
  border: {
    borderColor: DDPSubModulesKeys.borderColor,
    borderRadius: DDPSubModulesKeys.borderRadius,
    borderStyle: DDPSubModulesKeys.borderStyle,
    borderWidth: DDPSubModulesKeys.borderWidth,
  },
  color: {
    color: DDPSubModulesKeys.color,
  },
  context: {
    parentContext: DDPSubModulesKeys.parentContext,
  },
  effects: {
    boxShadow: DDPSubModulesKeys.boxShadow,
  },
  instance: {},
  layout: {
    padding: DDPSubModulesKeys.padding,
    margin: DDPSubModulesKeys.margin,
    display: DDPSubModulesKeys.display,
    flexDirectionAndWrapping: DDPSubModulesKeys.flexDirectionAndWrapping,
    alignItems: DDPSubModulesKeys.alignItems,
    justifyContent: DDPSubModulesKeys.justifyContent,
    gap: DDPSubModulesKeys.gap,
  },
  opacity: {
    opacity: DDPSubModulesKeys.opacity,
  },
  props: {
    assetContent: DDPSubModulesKeys.assetContent,
    dynamicProperties: DDPSubModulesKeys.dynamicProperties,
    editProperties: DDPSubModulesKeys.editProperties,
    nodeInteractiveState: DDPSubModulesKeys.nodeInteractiveState,
    textContent: DDPSubModulesKeys.textContent,
  },
  size: {
    width: DDPSubModulesKeys.width,
    height: DDPSubModulesKeys.height,
    position: DDPSubModulesKeys.position,
    relativeTo: DDPSubModulesKeys.relativeTo,
    constraints: DDPSubModulesKeys.constraints,
    zIndex: DDPSubModulesKeys.zIndex,
  },
  text: {
    textColor: DDPSubModulesKeys.textColor,
    alignment: DDPSubModulesKeys.alignment,
    typography: DDPSubModulesKeys.typography,
  },
};
