import { KeyboardEvent } from 'react';

const SHIFT_JUMP = 10;
const DEFAULT_JUMP = 1;

/**
 * Handles the arrow key input for adjusting dimensions.
 * @param e - The keyboard event.
 * @param handleChange - The function to handle the dimension change.
 */
export const handleDimensionInputArrows = (
  e: KeyboardEvent<HTMLInputElement>,
  handleChange: (addition: number) => void
) => {
  // The amount to jump when using arrow keys to adjust dimensions.
  // If shift key is pressed, the jump is multiplied by SHIFT_JUMP, otherwise it uses DEFAULT_JUMP.
  const { shiftKey } = e.nativeEvent;
  const jump = shiftKey ? SHIFT_JUMP : DEFAULT_JUMP;

  switch (e.key) {
    case 'ArrowUp': {
      e.preventDefault(); // prevent caret from moving
      handleChange(jump);
      break;
    }
    case 'ArrowDown': {
      e.preventDefault(); // prevent caret from moving
      handleChange(-jump);
      break;
    }
  }
};
