/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const ArrowPointsDownIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M4.11615 5.82325C4.40905 5.53035 4.88392 5.53035 5.17681 5.82325L8.00004 8.64647L10.8233 5.82325C11.1162 5.53035 11.591 5.53035 11.8839 5.82325C12.1768 6.11614 12.1768 6.59101 11.8839 6.88391L8.00004 10.7678L4.11615 6.88391C3.82326 6.59101 3.82326 6.11614 4.11615 5.82325Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
