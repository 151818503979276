import {
  AssetData,
  CanvasData,
  JuxComponentData,
  TokenSetData,
} from '@jux/data-entities';
import { EnumPropValue } from '@jux/types';
import { LiveObject } from '@liveblocks/client';

export const PROP_EDITING_MODE_ANY = 'Any';
export type VariantsEditingContext = {
  [variant: string]: EnumPropValue | typeof PROP_EDITING_MODE_ANY;
};

export type Storage = {
  assets: LiveObject<Record<string, LiveObject<AssetData>>>;

  // All the canvases under a certain organization
  canvases: LiveObject<Record<string, LiveObject<CanvasData>>>;

  // All the components under a certain organization
  components: LiveObject<Record<string, LiveObject<JuxComponentData>>>;

  editorTokenSetId: string;

  importedOldComponents?: boolean;

  // All the token sets under a certain organization
  tokenSets: LiveObject<Record<string, LiveObject<TokenSetData>>>;
};
