import { COMPONENT_ROOT_ELEMENT_TAG } from '@jux/data-entities';
import { createDrimz } from '@jux/dui';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { FC, JSX, forwardRef, useMemo } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { useEditableTextElement } from './useEditableTextElement';
import { ComponentConfigWithStates, StylesState } from '@jux/types';
import { useCalculatedStyles } from '@jux/canjux/core';
import { textValidators } from '@jux/ui/utils/textValidators';

const { styled } = createDrimz<any>();

const TEXT_CHANGE_DEBOUNCE_WAIT = 500;

type EditableTextElementProps = JSX.IntrinsicElements['p'] & {
  text: string;
  styles: ComponentConfigWithStates;
  stylesState: StylesState;
  onTextChange?: (text: string) => void;
  onDimensionsChange?: (width: number, height: number) => void;
};

export const EditableTextElement = forwardRef<
  HTMLElement,
  EditableTextElementProps
>(
  (
    {
      text,
      styles,
      stylesState,
      style,
      onTextChange,
      onDimensionsChange,
      ...externalProps
    },
    ref
  ) => {
    const calculatedStyles = useCalculatedStyles(styles, stylesState);
    const validators = useMemo(
      () => [textValidators.isNotEmptyValidator({})],
      []
    );
    const { ref: elementRef, ...props } = useEditableTextElement({
      text,
      autoFocus: true,
      debounceWait: TEXT_CHANGE_DEBOUNCE_WAIT,
      validators,
      onTextChange,
      onDimensionsChange,
      ...externalProps,
    });

    const StyledTextElement = useMemo(
      () =>
        styled(COMPONENT_ROOT_ELEMENT_TAG.JuxText, {
          styles: calculatedStyles,
        }) as FC<JSX.IntrinsicElements['p']>,
      [calculatedStyles]
    );

    return (
      <StyledTextElement
        {...externalProps}
        {...props}
        ref={mergeRefs([ref, elementRef])}
        contentEditable
        suppressContentEditableWarning
        style={mergeTyped(style, {
          pointerEvents: 'all',
          cursor: 'text',
        })}
      />
    );
  }
);
