import log from 'loglevel';
import prefix from 'loglevel-plugin-prefix';
import clc from 'chalk';

const colors = {
  TRACE: clc.magenta,
  DEBUG: clc.cyan,
  INFO: clc.green,
  WARN: clc.yellow,
  ERROR: clc.red,
};

type LEVELS = 'TRACE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR';

const logger = log.getLogger('drimz');

logger.setLevel('TRACE');

prefix.reg(log);

prefix.apply(logger, {
  format(level, _name, timestamp) {
    return `${clc.blue(`[${timestamp}]`)} ${colors[level as LEVELS](level)}`;
  },
});

prefix.apply(log.getLogger('critical'), {
  format(level, name, timestamp) {
    return clc.red.bold(`[${timestamp}] ${level} ${name}:`);
  },
});

export { logger };
export default logger;
