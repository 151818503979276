import { FC } from 'react';
import { TooltipTypography, Typography } from '@jux/ui/components';
import {
  EDITOR_DDP_FIELD_TOKEN_SELECTED_VALUE,
  SELECT_FIELD_BOX_TEXT_COLOR,
} from '@jux/ui/theme/palette';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { logger } from '@jux/ui-logger';
import { AutocompleteRenderOptionProps } from '../autocomplete/Autocomplete.interface';
import * as S from './AutocompleteOption.style';

const parseValue = (value: any): string => {
  if (typeof value === 'string') {
    return value;
  }
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  logger.warn('AutocompleteOption: value is not a string or an array', value);
  return '';
};

export const AutocompleteOption: FC<AutocompleteRenderOptionProps> = ({
  option: { label, value },
  isSelected,
  hideValue,
}) => (
  <S.AutoCompleteOptionWrapper container isSelected={isSelected}>
    <TooltipTypography
      variant="label-s-regular"
      color={(theme) =>
        isSelected
          ? theme.drimz.palette.text.primary
          : SELECT_FIELD_BOX_TEXT_COLOR
      }
    >
      {label}
    </TooltipTypography>
    {!hideValue && (
      <MuiGrid item alignItems="center" flex={0}>
        <Typography
          variant="label-xs-medium"
          sx={{
            color: (theme) =>
              isSelected
                ? EDITOR_DDP_FIELD_TOKEN_SELECTED_VALUE
                : theme.drimz.palette.text.secondary,
            Width: '35px',
          }}
        >
          {parseValue(value)}
        </Typography>
      </MuiGrid>
    )}
  </S.AutoCompleteOptionWrapper>
);
