/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

export const juxTheme = {
  light: {
    tokenSet: {
      color: {
        core: {
          grays_0: 'rgba(255 255 255 / 100%)',
          blues_100: 'rgba(230 242 250 / 100%)',
          blues_200: 'rgba(217 239 255 / 100%)',
          blues_500: 'rgba(5 143 242 / 100%)',
          grays_100: 'rgba(242 242 242 / 100%)',
          grays_150: 'rgba(235 235 235 / 100%)',
          grays_200: 'rgba(224 224 224 / 100%)',
          grays_300: 'rgba(189 189 189 / 100%)',
          grays_400: 'rgba(158 158 158 / 100%)',
          grays_500: 'rgba(97 97 97 / 100%)',
          grays_600: 'rgba(71 71 71 / 100%)',
          grays_650: 'rgba(48 48 48 / 100%)',
          grays_700: 'rgba(39 39 39 / 100%)',
          grays_750: 'rgba(33 33 33 / 100%)',
          grays_800: 'rgba(26 26 26 / 100%)',
          grays_900: 'rgba(20 20 20 / 100%)',
          pinks_200: 'rgba(255 214 218 / 100%)',
          pinks_300: 'rgba(255 153 163 / 100%)',
          pinks_500: 'rgba(255 92 108 / 100%)',
          grays_1000: 'rgba(0 0 0 / 100%)',
          purples_200: 'rgba(222 173 255 / 100%)',
          purples_300: 'rgba(198 112 255 / 100%)',
          purples_500: 'rgba(157 10 255 / 100%)',
          yellows_200: 'rgba(255 238 194 / 100%)',
          yellows_300: 'rgba(255 220 133 / 100%)',
          yellows_500: 'rgba(255 203 71 / 100%)',
        },
        semantic: {
          fill_base_2: 'rgba(55 45 107 / 100%)',
          fill_base_3: 'rgba(26 26 26 / 100%)',
        },
      },
      dimension: {
        core: {
          size_m: '24px',
          size_lg: '40px',
          size_ml: '32px',
          size_sm: '16px',
          size_xl: '52px',
          size_xs: '8px',
          radius_l: '12px',
          radius_m: '8px',
          radius_s: '4px',
          size_2lg: '48px',
          size_2xl: '64px',
          size_3xl: '128px',
          space_lg: '16px',
          space_md: '8px',
          space_ml: '12px',
          space_sm: '4px',
          space_xl: '24px',
          space_xs: '2px',
          space_2xl: '32px',
          space_3xl: '48px',
          radius_round: '9999px',
          padding_sides: '2.5rem',
        },
        semantic: { small_border_radius: '2px' },
        specific: { button_4: '4px', button_radius: '2px' },
      },
      fontFamily: {
        core: {
          base: 'Inter, sans-serif',
          titles: 'Libre Franklin, sans-serif',
        },
      },
      typography: {
        core: {
          '12_bold': {
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '700',
            lineHeight: '16px',
            letterSpacing: '',
          },
          '14_bold': {
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '700',
            lineHeight: '20px',
            letterSpacing: '1%',
          },
          '14_semi': {
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '600',
            lineHeight: '20px',
            letterSpacing: '1%',
          },
          '11_medium': {
            fontSize: '11px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '500',
            lineHeight: '16px',
            letterSpacing: '',
          },
          '12_medium': {
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '500',
            lineHeight: '16px',
            letterSpacing: '',
          },
          '11_regular': {
            fontSize: '11px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '14px',
            letterSpacing: '',
          },
          '12_regular': {
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '16px',
            letterSpacing: '',
          },
          '14_regular': {
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '20px',
            letterSpacing: '',
          },
          '16_regular': {
            fontSize: '16px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '150%',
            letterSpacing: '',
          },
          '28_regular': {
            fontSize: '28px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '100px',
            letterSpacing: '',
          },
        },
        semantic: {
          header_1: {
            fontSize: '28px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '100px',
            letterSpacing: '',
          },
          header_2: {
            fontSize: '16px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '150%',
            letterSpacing: '',
          },
          body_bold: {
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '700',
            lineHeight: '20px',
            letterSpacing: '1%',
          },
          body_semi: {
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '600',
            lineHeight: '20px',
            letterSpacing: '1%',
          },
          body_regular: {
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '20px',
            letterSpacing: '',
          },
          'label-small-bold': {
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '700',
            lineHeight: '16px',
            letterSpacing: '',
          },
          'label-small-medium': {
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '500',
            lineHeight: '16px',
            letterSpacing: '',
          },
          'label-small-regular': {
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '16px',
            letterSpacing: '',
          },
          'helper-small-regular': {
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '16px',
            letterSpacing: '',
          },
          'label-x-small-medium': {
            fontSize: '11px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '500',
            lineHeight: '16px',
            letterSpacing: '',
          },
          'label-x-small-regular': {
            fontSize: '11px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '14px',
            letterSpacing: '',
          },
        },
      },
    },
  },
  dark: {
    tokenSet: {
      color: {
        core: {
          grays_0: 'rgba(255 255 255 / 100%)',
          blues_100: 'rgba(230 242 250 / 100%)',
          blues_200: 'rgba(217 239 255 / 100%)',
          blues_500: 'rgba(5 143 242 / 100%)',
          grays_100: 'rgba(242 242 242 / 100%)',
          grays_150: 'rgba(235 235 235 / 100%)',
          grays_200: 'rgba(224 224 224 / 100%)',
          grays_300: 'rgba(189 189 189 / 100%)',
          grays_400: 'rgba(158 158 158 / 100%)',
          grays_500: 'rgba(97 97 97 / 100%)',
          grays_600: 'rgba(71 71 71 / 100%)',
          grays_650: 'rgba(48 48 48 / 100%)',
          grays_700: 'rgba(39 39 39 / 100%)',
          grays_750: 'rgba(33 33 33 / 100%)',
          grays_800: 'rgba(26 26 26 / 100%)',
          grays_900: 'rgba(20 20 20 / 100%)',
          pinks_200: 'rgba(255 214 218 / 100%)',
          pinks_300: 'rgba(255 153 163 / 100%)',
          pinks_500: 'rgba(255 92 108 / 100%)',
          grays_1000: 'rgba(0 0 0 / 100%)',
          purples_200: 'rgba(222 173 255 / 100%)',
          purples_300: 'rgba(198 112 255 / 100%)',
          purples_500: 'rgba(157 10 255 / 100%)',
          yellows_200: 'rgba(255 238 194 / 100%)',
          yellows_300: 'rgba(255 220 133 / 100%)',
          yellows_500: 'rgba(255 203 71 / 100%)',
        },
        semantic: {
          fill_base_2: 'rgba(55 45 107 / 100%)',
          fill_base_3: 'rgba(242 242 242 / 100%)',
        },
      },
      dimension: {
        core: {
          size_m: '24px',
          size_lg: '40px',
          size_ml: '32px',
          size_sm: '16px',
          size_xl: '52px',
          size_xs: '8px',
          radius_l: '12px',
          radius_m: '8px',
          radius_s: '4px',
          size_2lg: '48px',
          size_2xl: '64px',
          size_3xl: '128px',
          space_lg: '16px',
          space_md: '8px',
          space_ml: '12px',
          space_sm: '4px',
          space_xl: '24px',
          space_xs: '2px',
          space_2xl: '32px',
          space_3xl: '48px',
          radius_round: '9999px',
          padding_sides: '8rem',
        },
        semantic: { small_border_radius: '2px' },
        specific: { button_4: '4px', button_radius: '2px' },
      },
      fontFamily: {
        core: {
          base: 'Inter, sans-serif',
          titles: 'Libre Franklin, sans-serif',
        },
      },
      typography: {
        core: {
          '12_bold': {
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '700',
            lineHeight: '16',
            letterSpacing: '',
          },
          '14_bold': {
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '700',
            lineHeight: '20',
            letterSpacing: '1%',
          },
          '14_semi': {
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '600',
            lineHeight: '20px',
            letterSpacing: '1%',
          },
          '11_medium': {
            fontSize: '11px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '500',
            lineHeight: '16px',
            letterSpacing: '',
          },
          '12_medium': {
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '600',
            lineHeight: '',
            letterSpacing: '',
          },
          '11_regular': {
            fontSize: '11px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '14',
            letterSpacing: '',
          },
          '12_regular': {
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '16',
            letterSpacing: '',
          },
          '14_regular': {
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '',
            letterSpacing: '',
          },
          '16_regular': {
            fontSize: '16px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '150%',
            letterSpacing: '',
          },
          '28_regular': {
            fontSize: '28px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '100',
            letterSpacing: '',
          },
        },
        semantic: {
          header_1: {
            fontSize: '28px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '100',
            letterSpacing: '',
          },
          header_2: {
            fontSize: '16px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '150%',
            letterSpacing: '',
          },
          body_bold: {
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '700',
            lineHeight: '20',
            letterSpacing: '1%',
          },
          body_semi: {
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '600',
            lineHeight: '20px',
            letterSpacing: '1%',
          },
          body_regular: {
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '',
            letterSpacing: '',
          },
          'label-small-bold': {
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '700',
            lineHeight: '16',
            letterSpacing: '',
          },
          'label-small-medium': {
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '600',
            lineHeight: '',
            letterSpacing: '',
          },
          'label-small-regular': {
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '16',
            letterSpacing: '',
          },
          'helper-small-regular': {
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '16',
            letterSpacing: '',
          },
          'label-x-small-medium': {
            fontSize: '11px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '500',
            lineHeight: '16px',
            letterSpacing: '',
          },
          'label-x-small-regular': {
            fontSize: '11px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            lineHeight: '14',
            letterSpacing: '',
          },
        },
      },
    },
  },
};
