import {
  CanjuxState,
  CommonActionsParams,
  getHoveredNode,
  JuxStoreActionFn,
} from '@jux/canjux/core';
import { setHoveredNodeInternal } from '../helpers/setHoveredNodeInternal';

/**
 * Update a node's position and intersections
 */
export const updateNodePositionAndIntersections: JuxStoreActionFn<
  CommonActionsParams['updateNodePositionAndIntersections'],
  CanjuxState
> = ({ changes, intersectingNodes, state }) => {
  const currentHoveredNodeId = getHoveredNode({ state });

  if (intersectingNodes.length > 0) {
    if (
      !state.hoveredNodesStack.some(
        (n) => n.id === intersectingNodes[intersectingNodes.length - 1]
      )
    ) {
      setHoveredNodeInternal({
        nodeId: intersectingNodes[intersectingNodes.length - 1],
        isHovered: true,
        affectChildren: true,
        state,
      });
    }
  }

  if (
    currentHoveredNodeId &&
    currentHoveredNodeId !== intersectingNodes[intersectingNodes.length - 1]
  ) {
    // We need to unhover the node if it is not intersecting with any other node
    setHoveredNodeInternal({
      nodeId: currentHoveredNodeId,
      isHovered: false,
      affectChildren: false,
      state,
    });
  }

  for (const positionChange of changes) {
    state.canvases[state.currentCanvasName].nodes[positionChange.id].position =
      positionChange.data.position;
  }

  return state;
};
