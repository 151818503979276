import {
  CoordinateExtent,
  Presence,
  Storage,
  UserMeta,
} from '@jux/canjux/core';
import { Client } from '@liveblocks/client';
import { liveblocks, type WithLiveblocks } from '@liveblocks/zustand';
import { StateCreator } from 'zustand';
import { mutative } from 'zustand-mutative';
import { subscribeWithSelector } from 'zustand/middleware';
import { createStore as createVanillaStore } from 'zustand/vanilla';
import { CanvasSlice, createCanvasSlice } from './canvas.slice';
import { createNewStoreSlice, JuxStore } from './wip';

export const infiniteExtent: CoordinateExtent = [
  [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY],
  [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY],
];

export type CanjuxState = WithLiveblocks<
  CanvasSlice & JuxStore,
  Presence,
  Storage,
  UserMeta
>;

export type StateSlice<T> = StateCreator<
  CanjuxState,
  [['zustand/mutative', never]],
  [],
  T
>;

export const createStore = (client: Client) => {
  const storeWithLiveblocks = liveblocks<
    CanjuxState,
    [['zustand/mutative', never]]
  >(
    (set, get, store) => ({
      ...createCanvasSlice(set, get, store),
      ...createNewStoreSlice(set, get, store),
    }),
    {
      client,
      storageMapping: {
        assets: true,
        canvases: true,
        components: true,
        editorTokenSetId: true,
        importedOldComponents: true,
        importedOldTokens: true,
        tokenSets: true,
      },
      presenceMapping: {},
    }
  );

  return createVanillaStore(
    subscribeWithSelector(mutative(storeWithLiveblocks))
  );
};
