import { atom, useAtom } from 'jotai';
import { RESET, atomWithReset, useResetAtom } from 'jotai/utils';
import {
  PopperActions,
  PopperContent,
  PopperHandlers,
  PopperStyles,
  PopperSourceElement,
  PopperStateByKey,
  PopperStates,
  UpdatePopperArgs,
  PoppersKeys,
  PopperInnerKey,
} from './usePopperState.interface';

export const popperCurrentlyOpenedKeyAtom = atomWithReset<PoppersKeys | null>(
  null
);
export const popperPreviouslyOpenedKeyAtom = atomWithReset<PoppersKeys | null>(
  null
);

export const popperCurrentlyOpenedInnerKeyAtom =
  atomWithReset<PopperInnerKey>(null);

export const popperPreviouslyOpenedInnerKeyAtom =
  atomWithReset<PopperInnerKey>(null);

export const popperStateAtom = atomWithReset<PopperStateByKey>({});

export const popperContentAtom = atomWithReset<PopperContent>(null);

export const popperHandlersAtom = atomWithReset<PopperHandlers>({
  onClose: () => {},
});

export const popperSourceElementAtom = atomWithReset<PopperSourceElement>(null);

export const popperStylesAtom = atomWithReset<PopperStyles>({});

export const popperElementRefAtom = atomWithReset<PopperContent>(null);

export const popperAtom = atom(
  (get) => ({
    currentlyOpenedKey: get(popperCurrentlyOpenedKeyAtom),
    previouslyOpenedKey: get(popperPreviouslyOpenedKeyAtom),
    state: get(popperStateAtom),
    currentlyOpenedInnerKey: get(popperCurrentlyOpenedInnerKeyAtom),
    previouslyOpenedInnerKey: get(popperPreviouslyOpenedInnerKeyAtom),
    content: get(popperContentAtom),
    handlers: get(popperHandlersAtom),
    styles: get(popperStylesAtom),
    sourceElement: get(popperSourceElementAtom),
  }),
  (_get, set, update: UpdatePopperArgs | typeof RESET) => {
    // handle reset
    if (update === RESET) {
      set(popperCurrentlyOpenedKeyAtom, RESET);
      set(popperPreviouslyOpenedKeyAtom, RESET);
      set(popperStateAtom, RESET);
      set(popperCurrentlyOpenedInnerKeyAtom, RESET);
      set(popperPreviouslyOpenedInnerKeyAtom, RESET);
      set(popperHandlersAtom, RESET);
      set(popperStylesAtom, RESET);
      set(popperSourceElementAtom, RESET);
      /*
       * due to the built-in transitions, and to avoid flickers/bugs,
       * we could wait a bit before resetting the content,
       * or use some observable + intermediate "closing/opening" states,
       * but instead - we simply update it only when opening the popper.
       * // set(popperContentAtom, RESET);
       */
      return;
    }

    const {
      action: [popperKey, action],
      content,
      handlers,
      styles,
      sourceElement,
      previouslyOpenedPopperKey,
      innerKey,
    } = update;

    const updateStateByKey = (newState: PopperStates) => {
      set(popperStateAtom, (prev) => ({
        ...prev,
        [popperKey]: newState,
      }));
    };

    // handle open action
    if (action === PopperActions.open) {
      if (popperKey) {
        set(popperCurrentlyOpenedKeyAtom, popperKey);
      }
      // set/update content when opening
      if (content) {
        set(popperContentAtom, content);
      }
      // set/update handlers when opening
      if (handlers) {
        set(popperHandlersAtom, handlers);
      }
      // place the popper in the right styles
      if (styles) {
        set(popperStylesAtom, styles);
      }
      // set source element
      if (sourceElement) {
        set(popperSourceElementAtom, sourceElement);
      }
      if (innerKey) {
        set(popperCurrentlyOpenedInnerKeyAtom, innerKey);
      }

      // open popper
      updateStateByKey(PopperStates.opened);
    }
    // handle close action
    else {
      updateStateByKey(PopperStates.closed);

      set(popperSourceElementAtom, null);
      set(popperCurrentlyOpenedKeyAtom, null);
      set(popperPreviouslyOpenedKeyAtom, previouslyOpenedPopperKey ?? null);
      set(popperCurrentlyOpenedInnerKeyAtom, null);
      set(popperPreviouslyOpenedInnerKeyAtom, innerKey ?? null);
      set(popperStylesAtom, {});
    }
  }
);

export const usePopperState = () => {
  const [
    {
      state,
      content,
      handlers,
      styles,
      sourceElement,
      currentlyOpenedKey,
      previouslyOpenedKey,
      currentlyOpenedInnerKey,
      previouslyOpenedInnerKey,
    },
    setState,
  ] = useAtom(popperAtom);

  const resetPreviouslyOpenedKey = useResetAtom(popperPreviouslyOpenedKeyAtom);
  const resetPreviouslyOpenedInnerKey = useResetAtom(
    popperPreviouslyOpenedInnerKeyAtom
  );

  const reset = useResetAtom(popperAtom);

  return {
    currentlyOpenedKey,
    previouslyOpenedKey,
    state,
    content,
    handlers,
    styles,
    sourceElement,
    setState,
    reset,
    resetPreviouslyOpenedKey,
    currentlyOpenedInnerKey,
    previouslyOpenedInnerKey,
    resetPreviouslyOpenedInnerKey,
  };
};
