import { useCallback } from 'react';
import {
  selectNodeSourceComponentOnCurrentCanvas,
  useStore,
  useStoreActions,
  storeApi,
} from '@jux/canjux/core';

export const useRestoreSourceComponent = () => {
  const getNodeSourceComponent = useStore(
    selectNodeSourceComponentOnCurrentCanvas
  );
  const {
    componentActions: { restoreSourceComponent: restoreSourceComponentAction },
    commonActions: { setSelectedNodes },
  } = useStoreActions();

  const restoreSourceComponent = useCallback(
    (instanceNodeId: string) => {
      const sourceComponentNode = getNodeSourceComponent(instanceNodeId);
      // if the node has already it's source component on the canvas, do nothing
      if (sourceComponentNode) return;

      restoreSourceComponentAction({
        instanceNodeId,
      });

      const newRestoredSource = selectNodeSourceComponentOnCurrentCanvas(
        storeApi.getState()
      )(instanceNodeId);
      if (!newRestoredSource) {
        return;
      }

      setSelectedNodes({ nodeIds: [newRestoredSource.id] });
    },
    [getNodeSourceComponent, setSelectedNodes, restoreSourceComponentAction]
  );

  return {
    restoreSourceComponent,
  };
};
