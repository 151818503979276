import type { Draft as WritableDraft } from 'mutative';
import { ContextStyle, EnumPropValue, StyleVariant } from '@jux/types';

export const renameStylesForPropValue = ({
  variantsStyles,
  propName,
  oldPropValue,
  newPropValue,
}: {
  variantsStyles: WritableDraft<StyleVariant>[];
  propName: string;
  oldPropValue: EnumPropValue;
  newPropValue: EnumPropValue;
}) => {
  for (const variant of variantsStyles) {
    const variantContainsOldPropValue =
      propName in variant.propsValues &&
      variant.propsValues[propName] === oldPropValue;

    if (variantContainsOldPropValue) {
      variant.propsValues[propName] = newPropValue;
    }
  }
};

export const renameContextStylesForNewPropValue = ({
  contextStyles,
  propName,
  oldPropValue,
  newPropValue,
}: {
  contextStyles: WritableDraft<ContextStyle>[];
  propName: string;
  oldPropValue: EnumPropValue;
  newPropValue: EnumPropValue;
}) => {
  for (const contextStyle of contextStyles) {
    const contextConditionProps = contextStyle?.condition?.propsValues;

    const conditionContainsOldPropValue =
      contextConditionProps &&
      propName in contextConditionProps &&
      contextConditionProps[propName] === oldPropValue;

    if (conditionContainsOldPropValue) {
      contextConditionProps[propName] = newPropValue;
    }
  }
};
