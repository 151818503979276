import { ComponentProps } from 'react';
import { MuiGrid, styled } from '@jux/ui/components/common/mui';
import { NAVIGATOR_COMPONENT_ITEM_ICON_COLOR } from '@jux/ui/theme/palette';

export const ComponentPreviewStretched = styled('div')({
  position: 'absolute',
  // This is necessary so that the browser wont prefer of doing "word wrap" on text elements
  // all elements will be stretched to their maximum width
  minWidth: 'max-content',
});

export const ComponentPreviewScaled = styled(ComponentPreviewStretched)(
  ({ transformRatio }: { transformRatio?: number }) => ({
    display: transformRatio ? 'flex' : 'initial',
    visibility: transformRatio ? 'visible' : 'hidden',
    transform: `scale(${transformRatio})`,
  })
);

export const ComponentPreviewWrapper = styled(MuiGrid)(() => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: 'none',
}));

const COMPONENT_PREVIEW_LOADING_ICON_CLASS_NAME =
  'component-preview-loading-icon';

export const ComponentPreviewLoadingWrapper = styled(
  (props: ComponentProps<typeof MuiGrid>) => (
    <MuiGrid className={COMPONENT_PREVIEW_LOADING_ICON_CLASS_NAME} {...props} />
  )
)(() => ({
  // Putting the icon in the center of the preview area
  display: 'contents',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  color: NAVIGATOR_COMPONENT_ITEM_ICON_COLOR,
}));
