import { createSelector } from 'reselect';
import { CanjuxState } from '@jux/canjux/core';
import { getSelectedNodeComponent } from './utils';
import { AssetData } from '@jux/data-entities';

type SelectedAsset = {
  selectedAssetId: string | undefined;
  selectedAssetData: AssetData | undefined;
};

const EMPTY_ASSET: SelectedAsset = {
  selectedAssetId: undefined,
  selectedAssetData: undefined,
};

export const selectSelectedNodeAsset = createSelector(
  [
    (state: CanjuxState) => state.components,
    (state: CanjuxState) => state.selectedNodesStack,
    (state: CanjuxState) => state.assets,
  ],
  (components, selectedNodesStack, assets): SelectedAsset => {
    const sourceComponent = getSelectedNodeComponent({
      components,
      selectedNodesStack,
    });

    if (!sourceComponent) return EMPTY_ASSET;

    const selectedAssetId = sourceComponent.sourceComponentId;

    if (!selectedAssetId) return EMPTY_ASSET;

    return {
      selectedAssetId,
      selectedAssetData: assets[selectedAssetId],
    };
  }
);
