import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { AssetPreviewItem } from './assetPreviewItem/AssetPreviewItem';
import { AssetsComponentType } from './Assets.interface';

export const SystemAssets: FC<AssetsComponentType> = ({
  assets,
  handleAssetClick,
  isDraggable,
  actions,
  selectedAssetId,
}) => (
  <MuiGrid container item gap="16px">
    {assets
      .filter(([, { isSystemAsset }]) => isSystemAsset)
      .map(
        ([assetId, assetData]) =>
          assetData?.content && (
            <AssetPreviewItem
              key={assetId}
              assetData={assetData}
              assetId={assetId}
              onAssetClick={() => handleAssetClick({ assetId, assetData })}
              actions={actions?.(assetId)}
              isDraggable={isDraggable}
              selected={Boolean(selectedAssetId && selectedAssetId === assetId)}
            />
          )
      )}
  </MuiGrid>
);
