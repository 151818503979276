import { ChangeEvent, useCallback } from 'react';

export const useImportFiles = (
  onFilesImport?: (files: FileList) => void | Promise<void>
) => {
  const importFiles = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const fileImported = event.target.files;

      if (!fileImported || !onFilesImport || fileImported.length === 0) return;

      await onFilesImport(fileImported);

      event.target.value = '';
      event.target.files = null;
    },
    [onFilesImport]
  );

  return {
    importFiles,
  };
};
