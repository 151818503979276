import { forwardRef } from 'react';
import { TOKENS_GROUP_POPPER_ACTION_TEXT_COLOR } from '@jux/ui/theme/palette';
import { colorWithOpacity } from '@jux/ui/utils/colorWithOpacity';
import { withImportant } from '@jux/ui/utils/css';
import { IconButton, IconButtonProps } from '@jux/ui/components';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';

type ActionButtonProps = Omit<IconButtonProps, 'variant'> & {
  onClick: () => void;
  icon?: Required<IconButtonProps['icon']>;
};

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  (
    { onClick, icon, children, sx, typographyProps, disabled, ...props },
    ref
  ) => (
    <IconButton
      ref={ref}
      fullWidth
      variant="secondary"
      icon={icon}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      sx={mergeTyped(
        {
          justifyContent: 'flex-start',
          padding: withImportant('0 8px'),
          border: withImportant('none'),

          // we're using this and not counting on the disabled prop because we want to show the tooltip
          pointerEvents: withImportant('auto'),
          '&:hover': {
            backgroundColor: (theme) =>
              disabled
                ? theme.drimz.palette.transparent
                : colorWithOpacity(theme.drimz.palette.primary.main, 0.1),
          },
        },
        sx
      )}
      color={TOKENS_GROUP_POPPER_ACTION_TEXT_COLOR}
      typographyVariant="body-regular"
      typographyProps={mergeTyped(
        {
          sx: {
            marginLeft: icon ? '10px' : 0,
          },
        },
        typographyProps
      )}
      {...props}
    >
      {children}
    </IconButton>
  )
);
