import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { getSourceComponentById } from 'libs/canjux/core/src/store/wip/selectors/utils';
import {
  useGoToSourceComponent,
  useRestoreSourceComponent,
  useSelectedNodeId,
} from '@jux/ui/components/editor/hooks';
import {
  selectCurrentCanvas,
  selectedSourceComponentCanvas,
  storeApi,
  useStore,
  useStoreActions,
} from '@jux/canjux/core';
import { useTrackEvents } from '@jux/ui/hooks';
import { PATHS } from '@jux/ui/utils/paths';

const GO_TO_COMPONENT_LABEL = 'Go to source';
const RESTORE_COMPONENT_LABEL = 'Restore';

export const useInstance = () => {
  const {
    commonActions: { setCurrentCanvasName },
  } = useStoreActions();
  const selectedNodeId = useSelectedNodeId();
  const sourceComponentCanvas = useStore(selectedSourceComponentCanvas);
  const router = useRouter();

  const { goToSourceComponent } = useGoToSourceComponent();

  const { restoreSourceComponent } = useRestoreSourceComponent();
  const { trackRestoreSourceComponent, trackGoToSourceComponent } =
    useTrackEvents();

  const instanceButtonLabel = useMemo(() => {
    if (!selectedNodeId) {
      return undefined;
    }

    if (sourceComponentCanvas) {
      return GO_TO_COMPONENT_LABEL;
    }

    return RESTORE_COMPONENT_LABEL;
  }, [selectedNodeId, sourceComponentCanvas]);

  const handleInstanceButtonClick = useCallback(() => {
    if (!selectedNodeId) {
      return;
    }

    if (sourceComponentCanvas) {
      const components = storeApi.getState().components;
      const currentCanvas = selectCurrentCanvas(storeApi.getState());
      const sourceComponent = getSourceComponentById({
        id: selectedNodeId,
        components,
      });
      const sourceComponentId = sourceComponent?.id;

      if (!sourceComponentId) return;

      if (sourceComponentCanvas.name !== currentCanvas.name) {
        setCurrentCanvasName({ canvasName: sourceComponentCanvas.name });
        router.push(`${PATHS.EDITOR}/${sourceComponentCanvas.id}`, undefined, {
          shallow: true,
        });
      }

      goToSourceComponent(sourceComponentId);
      trackGoToSourceComponent();
    } else {
      restoreSourceComponent(selectedNodeId);
      trackRestoreSourceComponent();
    }
  }, [
    selectedNodeId,
    sourceComponentCanvas,
    goToSourceComponent,
    trackGoToSourceComponent,
    setCurrentCanvasName,
    router,
    restoreSourceComponent,
    trackRestoreSourceComponent,
  ]);

  return {
    handleInstanceButtonClick,
    instanceButtonLabel,
    shouldRender: Boolean(instanceButtonLabel),
  };
};
