import { useEffect } from 'react';
import { useSelectedNodeInteractiveState } from '@jux/ui/components/editor/hooks';

export const useEffectOnSelectedNodeStateChange = (callback: () => void) => {
  const { selectedNodeInteractiveState } = useSelectedNodeInteractiveState();

  useEffect(() => {
    callback();
  }, [callback, selectedNodeInteractiveState]);
};
