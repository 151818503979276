import { NodeType } from '@jux/data-entities';
import { DropPositionType, DropPosition } from '../../types';
import { LayersPanelItem } from '../getLayersItemsRecursive';
import { isCurrentItemRootAComponentAndDraggedItemTreeHasInstance } from './checkIfDraggedItemOrItsChildrenAreInstanceOfCurrentItemSourceComponent.utils';
import { isNextSiblingItem } from './isNextSiblingItem.utils';

export const dndReorderItemsBehaviors = ({
  draggedItem,
  hoveredItem,
  currentItem,
  items,
  isExpanded,
  dropPosition,
}: {
  draggedItem: LayersPanelItem | undefined;
  hoveredItem: LayersPanelItem | undefined;
  currentItem: LayersPanelItem;
  items: Map<string, LayersPanelItem>;
  isExpanded: boolean;
  dropPosition: DropPositionType | undefined;
}): {
  droppable: boolean;
  draggable: boolean;
  allowDropAbove: boolean;
  allowDropInside: boolean;
  allowDropBelow: boolean;
  showDroppableLine: boolean;
} => {
  const isDraggedAndHoveredItemTheSame = draggedItem?.id === hoveredItem?.id;
  const isLogicalSlot =
    draggedItem && draggedItem.objectType === NodeType.LOGICAL_SLOT;
  const isLogicalSlotDroppable =
    isLogicalSlot &&
    (draggedItem?.parentId === currentItem.id ||
      draggedItem?.parentId === currentItem.parentId);

  const isDraggable = currentItem.draggable;

  if (
    !draggedItem ||
    !hoveredItem ||
    isDraggedAndHoveredItemTheSame ||
    (isLogicalSlot && !isLogicalSlotDroppable)
  ) {
    return {
      draggable: isDraggable,
      droppable: false,
      allowDropAbove: false,
      allowDropInside: false,
      allowDropBelow: false,
      showDroppableLine: false,
    };
  }

  // get the dragged item id, if the dragged item is a parent of the hoveredItem then we should not allow the drop
  const currentItemPath = currentItem.path;
  const isDraggedItemParentOfCurrentItem = Boolean(
    currentItemPath && currentItemPath.includes(draggedItem.id)
  );

  // dragged item
  const isDraggedItemVariantsGroup =
    draggedItem.objectType === NodeType.VARIANTS_GROUP;

  const isDraggedItemOrItsChildrenAreInstanceOfCurrentItemSourceComponent =
    isCurrentItemRootAComponentAndDraggedItemTreeHasInstance(
      draggedItem,
      currentItem,
      items
    );

  const isTargetRootLibraryComponetAndDraggedContainsLocalComponents =
    draggedItem.isContainsLocalComponentInstance &&
    currentItem.rootNodeType === NodeType.LIBRARY_COMPONENT;

  // current item
  const isCurrentItemRootElement = !currentItem.parentId;

  // - if the `dragged item` is a `local component` or 'library component' or 'variants group'
  // - if the `dragged item` is an item that either him or one of his decadent is an `instance component` of the `current item`’s that either him or one of his ancestors is a `source component` of that same instance
  // - if the `dragged item` is an item that is an ancestor of `current item`
  const blockDroppable =
    (!isCurrentItemRootElement && // in all following cases allow if moving to root
      (isDraggedItemVariantsGroup ||
        isDraggedItemOrItsChildrenAreInstanceOfCurrentItemSourceComponent)) ||
    isDraggedItemParentOfCurrentItem ||
    isTargetRootLibraryComponetAndDraggedContainsLocalComponents;

  const isDroppable =
    currentItem.droppable &&
    currentItem.parentId !== draggedItem.id &&
    !blockDroppable;

  const isLogicalSlotAndTargetIsNotSibling =
    isLogicalSlot && currentItem.parentId !== draggedItem.parentId;

  const blockDropAbove =
    isNextSiblingItem(draggedItem, currentItem) ||
    isLogicalSlotAndTargetIsNotSibling;

  const allowDropAbove = !blockDropAbove;

  // block drag below if:
  // - if the `dragged item` is the following child of the `current item` under the same parent
  // - if the `current item` has an expander and is expanded
  const blockDropBelow =
    isNextSiblingItem(currentItem, draggedItem) ||
    (isExpanded && currentItem.hasExpander);

  const allowDropBelow = !blockDropBelow;

  const isLogicalSlotAndTargetIsNotCurrentParent =
    isLogicalSlot && currentItem.id !== draggedItem.parentId;

  const blockDropInside =
    isDraggedItemOrItsChildrenAreInstanceOfCurrentItemSourceComponent ||
    isLogicalSlotAndTargetIsNotCurrentParent;

  const allowDropInside = currentItem.isContainer && !blockDropInside;

  const showDroppableLine =
    currentItem.droppable &&
    currentItem.id === hoveredItem.id &&
    currentItem.id !== draggedItem.id &&
    dropPosition !== DropPosition.inside;

  return {
    draggable: isDraggable,
    droppable: isDroppable,
    allowDropAbove,
    allowDropInside,
    allowDropBelow,
    showDroppableLine,
  };
};
