/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const AlignItemsBaselineRowDDPIcon: IconType = (
  props: IconBaseProps
) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M7 12C7 12.5523 6.55229 13 6 13C5.44772 13 5 12.5523 5 12L5 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H5L5 4C5 3.44772 5.44771 3 6 3C6.55228 3 7 3.44772 7 4L7 7L9 7L9 4C9 3.44772 9.44771 3 10 3C10.5523 3 11 3.44772 11 4L11 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H11C11 8.55229 10.5523 9 10 9C9.44772 9 9 8.55228 9 8L7 8L7 12Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
