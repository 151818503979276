/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const JustifyContentSpaceAroundColumnDDPIcon: IconType = (
  props: IconBaseProps
) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M14 2.5C14 2.22386 13.7761 2 13.5 2H2.5C2.22386 2 2 2.22386 2 2.5C2 2.77614 2.22386 3 2.5 3L13.5 3C13.7761 3 14 2.77614 14 2.5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M14 13.5C14 13.2239 13.7761 13 13.5 13L2.5 13C2.22386 13 2 13.2239 2 13.5C2 13.7761 2.22386 14 2.5 14H13.5C13.7761 14 14 13.7761 14 13.5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M6 11C5.44772 11 5 10.5523 5 10C5 9.44772 5.44772 9 6 9L10 9C10.5523 9 11 9.44771 11 10C11 10.5523 10.5523 11 10 11L6 11Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M5 6C5 6.55228 5.44772 7 6 7L10 7C10.5523 7 11 6.55228 11 6C11 5.44771 10.5523 5 10 5L6 5C5.44772 5 5 5.44772 5 6Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
