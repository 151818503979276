import { FC } from 'react';
import { DropPositionType } from '../../types';
import { LineWrapper } from './DroppableLine.style';

export const DroppableLine: FC<{
  dropPosition: DropPositionType;
  indentLevel?: number;
}> = ({ dropPosition, indentLevel }) => (
  <LineWrapper dropPosition={dropPosition} indentLevel={indentLevel ?? 0} />
);
