import createCache, { EmotionCache } from '@emotion/cache';
import { AppProps } from 'next/app';
import { NextPage } from 'next';

const createEmotionCache = () => createCache({ key: 'css' });

export default createEmotionCache;

export type EnhancedAppProps = AppProps<{ dehydratedState: unknown }> & {
  Component: NextPage<{ dehydratedState: unknown }>;
  emotionCache: EmotionCache;
};
