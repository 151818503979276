import { atom, useAtom } from 'jotai';
import { PanelsState } from './usePanelsState.interface';

const defaultPanelsState: PanelsState = {
  // TODO: support more panels
  properties: {
    shouldRender: false,
  },
};

const panelsStateAtom = atom<PanelsState>(defaultPanelsState);

export const usePanelsState = () => useAtom(panelsStateAtom);
