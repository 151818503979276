import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { useCreateTokenForm } from '../../token-drawer/forms/createEditHooks';
import {
  useRenameGroup,
  useDeleteGroup,
  useCreateGroup,
  useEditToken,
  useRenameToken,
  useDeleteToken,
} from '../hooks';
import { useSelectedTokenState } from '../state';
import { useTokenDrawer } from '../../token-drawer/useTokenDrawer';
import { TokenGroup } from './tokenGroup/TokenGroup';
import { GroupType } from './tokenGroup/TokenGroup.interface';

export const TokenGroups: FC<{
  groups: Array<GroupType>;
  rootPath: string;
}> = ({ groups, rootPath }) => {
  const { renameGroup } = useRenameGroup();
  const { deleteGroup } = useDeleteGroup();
  const { createGroup } = useCreateGroup();
  const { editToken } = useEditToken();
  const { renameToken } = useRenameToken();
  const { deleteToken } = useDeleteToken();
  const { isTokenSelected } = useSelectedTokenState();
  const { isOpen: isDrawerOpen } = useTokenDrawer();

  const { openCreateTokenForm } = useCreateTokenForm();

  return (
    <MuiGrid item container gap="28px">
      {groups?.map((group) => (
        <TokenGroup
          key={group.path}
          {...group}
          parentGroupPath={rootPath}
          isDrawerOpen={isDrawerOpen}
          isTokenSelected={isTokenSelected}
          handleRenameGroup={renameGroup}
          handleCreateToken={openCreateTokenForm}
          handleCreateGroup={createGroup}
          handleDeleteGroup={deleteGroup}
          handleEditToken={editToken}
          handleRenameToken={renameToken}
          handleDeleteToken={deleteToken}
        />
      ))}
    </MuiGrid>
  );
};
