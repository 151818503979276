import { ComponentProps } from 'react';
import { MuiGrid, styled } from '@jux/ui/components/common/mui';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { JUX_BUTTON_GROUP_CLASS_NAME } from './ButtonGroupField.constant';

export const ButtonGroupField = styled(
  ({ sx, ...props }: ComponentProps<typeof MuiGrid>) => (
    <MuiGrid
      sx={mergeTyped(
        {
          gap: '1px',
          mt: '1px',
          display: 'flex',
          position: 'relative',
          backgroundColor: (theme) => theme.drimz.palette.background.default,
        },
        sx
      )}
      {...props}
      className={JUX_BUTTON_GROUP_CLASS_NAME}
    />
  )
)(() => ({}));
