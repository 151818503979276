import { FC } from 'react';
import { Module } from '../../base';
import { useShouldRenderModuleByKey } from '../../hooks/useShouldRenderModuleByKey';
import { ModulesKeys } from '../../DDP.interface';
import { BackgroundColor } from './sub-modules/BackgroundColor';

const MODULE_KEY = ModulesKeys.background;

export const Background: FC = () => {
  const { shouldRender } = useShouldRenderModuleByKey(MODULE_KEY);

  if (!shouldRender) return null;

  return (
    <Module moduleKey={MODULE_KEY} title="Background">
      <BackgroundColor />
    </Module>
  );
};
