import { FC } from 'react';
import { DimensionField } from '../../../../base';
import { useConstraintsFields } from './useConstraintsFields';

export const BottomField: FC = () => {
  const { bottomFieldProps } = useConstraintsFields();

  return (
    <DimensionField
      icon="DDP_FIELD_CONSTRAINTS_BOTTOM"
      tooltipTitle="Bottom"
      {...bottomFieldProps}
    />
  );
};
