import { FC } from 'react';
import { TooltipTypography } from '@jux/ui/components';
import { CardAliasType } from './CardContentByType.interface';
import { formatAliasDisplay } from './CardContentByType.utils';

export const CardContentValue: FC<CardAliasType & { value: string }> = ({
  isAlias,
  aliasName,
  value,
}) => (
  <TooltipTypography
    variant="body-regular"
    sx={{
      color: (theme) => theme.drimz.palette.text.secondary,
    }}
    tooltipContent={() => value}
    shouldWordBreak
  >
    {isAlias && aliasName ? formatAliasDisplay(aliasName) : value}
  </TooltipTypography>
);
