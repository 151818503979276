import { FC } from 'react';
import { IconButton, Typography } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { useTokenDrawer } from '../useTokenDrawer';

export const TokenDrawerHeader: FC<{ title: string }> = ({ title }) => {
  const { close } = useTokenDrawer();
  return (
    <MuiGrid
      height={(theme) => theme.drimz.size.layout.tokenDrawer.header.height}
      p="15px 20px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        borderBottom: 1,
        borderColor: (theme) => theme.drimz.palette.divider.primary,
      }}
    >
      <Typography variant="label-s-regular">{title}</Typography>
      <IconButton
        variant="icon"
        tooltipTitle="Close"
        onClick={close}
        icon="CLOSE"
      />
    </MuiGrid>
  );
};
