import Head from 'next/head';
import { FC } from 'react';
import { withImportant } from '@jux/ui/utils/css';

export const AppGlobalStyles: FC<{
  selectionBackgroundColor: CSSStyleDeclaration['backgroundColor'];
}> = ({ selectionBackgroundColor }) => (
  <Head>
    <style>
      {`
        body {
          *::selection {
            background-color: ${withImportant(selectionBackgroundColor)};
          }
        }
      `}
    </style>
  </Head>
);
