import { FC } from 'react';
import {
  CardContentValue,
  CardContentValueWrapper,
  TypographyTokenProps,
} from './base';
import { formatTypographyTokenValueToString } from './helpers';

export const TypographyToken: FC<TypographyTokenProps> = ({
  aliasProps: { aliasName, isAlias },
  value,
}) => (
  <CardContentValueWrapper>
    <CardContentValue
      value={formatTypographyTokenValueToString(value)}
      aliasName={aliasName}
      isAlias={isAlias}
    />
  </CardContentValueWrapper>
);
