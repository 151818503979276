import colorString from 'color-string';

const toRgbaString = (r: number, g: number, b: number, alpha: number) =>
  `rgba(${r}, ${g}, ${b}, ${alpha})`;

// rgb to rgba using our helper
// when using computed styles, we need to always convert rgb to rgba
// due to the fact that computed styles only return rgb when alpha is 1
// computed styles: rgb(255, 255, 255);
// what we actually need: rgba(255, 255, 255, 1);
export const colorWithOpacity = (color?: string, alpha?: number) => {
  if (!color) {
    return 'inherit';
  }

  const parsedColor = colorString.get.rgb(color || '');

  // for example: in case the color is token
  if (!parsedColor) {
    return color;
  }

  const [r, g, b, a] = parsedColor;
  return toRgbaString(r, g, b, alpha ?? a);
};
