import { FC, useCallback, useRef } from 'react';
import { useHover } from 'usehooks-ts';
import { EditableTypography, IconButton } from '@jux/ui/components';
import { TextValidatorFunction } from '@jux/ui/utils/textValidators';
import {
  formatTokenNameKeyPress,
  formatTokenNamePaste,
} from '@jux/ui/utils/tokens/tokenNameFormat';
import { CORE, isCoreTokenSetName } from '@jux/types';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { useOpenTokenSetActionsPopper } from '../hooks/useOpenTokenSetActionsPopper';

export const TokenSetsTab: FC<{
  setKey: string;
  id: string;
  name: string;

  isCurrentTokenSet: boolean;
  validators: Array<TextValidatorFunction> | undefined;
  hasGroups: boolean;

  handleDeleteTokenSet: (tokenSetId: string) => void;
  handleRenameTokenSet: (newTokenSetName: string) => Promise<boolean>;
  handleCreateGroup: () => void;
  handleClick: () => void;
}> = ({
  setKey,
  id,
  name,

  isCurrentTokenSet,
  validators,

  handleDeleteTokenSet,
  handleRenameTokenSet,
  handleCreateGroup,
  handleClick,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isWrapperHovered = useHover<HTMLDivElement>(wrapperRef);

  const isCurrentTokenSetCoreTokenSet = isCoreTokenSetName(name ?? '');

  const disableEditing = !isCurrentTokenSet || isCurrentTokenSetCoreTokenSet;

  const { openTokenSetActionsPopper, isTokenSetActionsPopperOpen } =
    useOpenTokenSetActionsPopper();

  const showTokenSetActions =
    isTokenSetActionsPopperOpen(setKey) || isWrapperHovered;

  const onClickActionsButton = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      openTokenSetActionsPopper({
        sourceElement: e.currentTarget as HTMLElement,
        setKey,
        onDeleteSet: () => handleDeleteTokenSet(id),
        showDeleteSet: !isCurrentTokenSetCoreTokenSet,
        onCreateGroup: handleCreateGroup,
      });
    },
    [
      handleCreateGroup,
      handleDeleteTokenSet,
      id,
      setKey,
      isCurrentTokenSetCoreTokenSet,
      openTokenSetActionsPopper,
    ]
  );

  return (
    <MuiGrid
      ref={wrapperRef}
      onClick={handleClick}
      sx={{
        cursor: 'pointer',
        height: (theme) => theme.drimz.size.layout.tokens.set.height,
        minWidth: (theme) => theme.drimz.size.layout.tokens.set.minWidth,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: isCurrentTokenSet ? 1 : 0,
        color: (theme) => theme.drimz.palette.text.primary,
        paddingRight: '16px',
      }}
    >
      <EditableTypography
        key={setKey}
        label={isCurrentTokenSetCoreTokenSet ? CORE : name ?? ''}
        tooltipContent={disableEditing ? undefined : 'Rename token set'}
        disableEditing={disableEditing}
        onSaveChanges={handleRenameTokenSet}
        customValidators={validators}
        onKeyDown={formatTokenNameKeyPress}
        onPaste={formatTokenNamePaste}
        variant={isCurrentTokenSet ? 'label-s-bold' : 'label-s-regular'}
        cursor="pointer"
        cursorOnNonEditable="pointer"
        sx={{
          color: (theme) => theme.drimz.palette.text.primary,
          padding: '16px 2px',
          width: '100%',
        }}
      />
      {showTokenSetActions && (
        <IconButton
          variant="icon"
          size="medium"
          onClick={onClickActionsButton}
          icon="DOTS_MENU_HORIZONTAL"
        />
      )}
    </MuiGrid>
  );
};
