import { createSelector } from 'reselect';
import { CanjuxState } from '@jux/canjux/core';
import { getSelectedNodeComponent } from './utils';
import { getResolvedSourceComponentData } from './utils/getResolvedSourceComponentData';

export const selectedNodeVariants = createSelector(
  [
    (state: CanjuxState) => state.components,
    (state: CanjuxState) => state.selectedNodesStack,
  ],
  (components, selectedNodesStack) => {
    const component = getSelectedNodeComponent({
      components,
      selectedNodesStack,
    });
    if (!component) {
      return undefined;
    }

    const sourceComponent = getResolvedSourceComponentData({
      component,
      components,
    });

    // Only return the variants to direct instances - this will be changed soon
    // on the task to allow editing any internal instances props
    if (sourceComponent.id !== component.sourceComponentId) {
      return undefined;
    }

    return sourceComponent?.config.variants;
  }
);
