import * as uuid from 'uuid';
import { StylesObject } from '@jux/types';
import { Colors } from '../common/colors';
import { COMPONENT_TAG_NAME, NodeType } from '@jux/data-entities';
import { ElementDataInit } from '../types/ElementDataInit';

const TEXT_ELEMENT_DEFAULT_ROOT_STYLES: StylesObject = {
  color: Colors.Neutrals_1000,
  width: 'auto',
  height: 'auto',
  fontFamily: 'Inter',
  fontSize: '20px',
  lineHeight: '130%',
  display: 'inline-block',
  overflowWrap: 'break-word',
  whiteSpace: 'pre-wrap',
};

const TEXT_ELEMENT_DEFAULT_DISPLAY_NAME = 'text';

export const getJuxTextElementData: ElementDataInit<{
  rootStyles?: StylesObject;
  text?: string;
  displayName?: string;
}> = ({
  contextParentId = uuid.v4(),
  rootStyles = TEXT_ELEMENT_DEFAULT_ROOT_STYLES,
  text = 'Text Element',
  displayName = TEXT_ELEMENT_DEFAULT_DISPLAY_NAME,
  parentId,
} = {}) => {
  const textId = uuid.v4();
  return {
    root: {
      type: NodeType.ELEMENT,
      id: textId,
      parentId,
      scopes: [],
      tagName: COMPONENT_TAG_NAME.JuxText,
      styles: {
        root: rootStyles,
        states: {},
      },
      config: {
        contextParentId,
        contextId: textId,
        props: {
          text,
        },
        variants: [],
      },
      children: [],
      displayName,
    },
  };
};
