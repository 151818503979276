import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { Module } from '../../base';
import { useShouldRenderModuleByKey } from '../../hooks/useShouldRenderModuleByKey';
import { ModulesKeys } from '../../DDP.interface';
import { BorderColor } from './sub-modules/borderColor/BorderColor';
import { BorderRadius } from './sub-modules/borderRadius/BorderRadius';
import { BorderWidth } from './sub-modules/borderWidth/BorderWidth';
import { BorderStyle } from './sub-modules/borderStyle/BorderStyle';

const MODULE_KEY = ModulesKeys.border;

export const Border: FC = () => {
  const { shouldRender } = useShouldRenderModuleByKey(MODULE_KEY);

  if (!shouldRender) return null;

  return (
    <Module moduleKey={MODULE_KEY} title="Border">
      <MuiGrid container gap="4px">
        <BorderWidth />
        <BorderColor />
        <BorderStyle />
        <BorderRadius />
      </MuiGrid>
    </Module>
  );
};
