import { CanjuxState } from '@jux/canjux/core';
import { createSelector } from 'reselect';

export const selectCanvasNodesDimensions = (id: string) =>
  createSelector(
    [(s: CanjuxState) => s.canvasNodesDimensions],
    (data) =>
      data[id] || {
        height: 0,
        width: 0,
      }
  );
