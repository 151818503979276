import { useEffect, useReducer } from 'react';
import { CanjuxState, storeApi } from '@jux/canjux/core';

export const useSignalSelector = (selector: (s: CanjuxState) => any) => {
  // This is the same implementation as in https://github.com/zustandjs/zustand-signal.
  // Their pragma was not always triggering render (for example when setting prop value)
  const [, rerender] = useReducer((c) => c + 1, 0);
  useEffect(() => {
    let lastValue: number;
    const unsubscribe = storeApi.subscribe(() => {
      const nextValue = selector(storeApi.getState());
      if (lastValue !== nextValue) {
        lastValue = nextValue;
        rerender();
      }
    });
    return unsubscribe;
  }, [selector]);

  return selector(storeApi.getState());
};
