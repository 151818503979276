import { CSSProperties } from 'react';

export type SupportedAlignItemsValues = Extract<
  CSSProperties['alignItems'],
  'center' | 'flex-end' | 'flex-start' | 'stretch' | 'baseline'
>;

export const AlignItemsValue: {
  center: Extract<SupportedAlignItemsValues, 'center'>;
  flexEnd: Extract<SupportedAlignItemsValues, 'flex-end'>;
  flexStart: Extract<SupportedAlignItemsValues, 'flex-start'>;
  stretch: Extract<SupportedAlignItemsValues, 'stretch'>;
  baseline: Extract<SupportedAlignItemsValues, 'baseline'>;
} = {
  center: 'center',
  flexEnd: 'flex-end',
  flexStart: 'flex-start',
  stretch: 'stretch',
  baseline: 'baseline',
} as const;
