import { useCallback, useMemo } from 'react';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import { useFieldValues, useSetMultiFieldsValues } from '../../../../hooks';
import { DisplayValue } from '../display/DisplayField.interface';
import { useFlexDirectionField } from '../flexDirectionAndWrapping/flexDirection/useFlexDirectionField';
import {
  AlignItemsValue,
  SupportedAlignItemsValues,
} from './AlignItemsField.interface';
import { parseAlignItems } from './AlignItemsField.utils';

export const useAlignItemsField = () => {
  const { value, initialValue } = useFieldValues(
    DDPPrimitiveFieldsKeys.alignItems
  );
  const { setMultiFieldsValues } = useSetMultiFieldsValues();

  const { isColumnSelected: isFlexDirectionIsColumn } = useFlexDirectionField();

  const parsedAlignItems = useMemo(
    () => parseAlignItems(value ?? initialValue),
    [initialValue, value]
  );

  const isFlexStartSelected = useMemo(
    () => parsedAlignItems === AlignItemsValue.flexStart,
    [parsedAlignItems]
  );

  const isCenterSelected = useMemo(
    () => parsedAlignItems === AlignItemsValue.center,
    [parsedAlignItems]
  );

  const isFlexEndSelected = useMemo(
    () => parsedAlignItems === AlignItemsValue.flexEnd,
    [parsedAlignItems]
  );

  const isStretchSelected = useMemo(
    () => parsedAlignItems === AlignItemsValue.stretch,
    [parsedAlignItems]
  );

  const isBaselineSelected = useMemo(
    () => parsedAlignItems === AlignItemsValue.baseline,
    [parsedAlignItems]
  );

  const saveChanges = useCallback(
    (newAlignItemsValue: SupportedAlignItemsValues | undefined) => {
      setMultiFieldsValues({
        [DDPPrimitiveFieldsKeys.alignItems]: newAlignItemsValue,
        [DDPPrimitiveFieldsKeys.display]: DisplayValue.flex,
      });
    },
    [setMultiFieldsValues]
  );

  const handleCenterClick = useCallback(() => {
    saveChanges(AlignItemsValue.center);
  }, [saveChanges]);

  const handleFlexStartClick = useCallback(() => {
    saveChanges(AlignItemsValue.flexStart);
  }, [saveChanges]);

  const handleFlexEndClick = useCallback(() => {
    saveChanges(AlignItemsValue.flexEnd);
  }, [saveChanges]);

  const handleStretchClick = useCallback(() => {
    saveChanges(AlignItemsValue.stretch);
  }, [saveChanges]);

  const handleBaselineClick = useCallback(() => {
    saveChanges(AlignItemsValue.baseline);
  }, [saveChanges]);

  return {
    isCenterSelected,
    isFlexEndSelected,
    isFlexStartSelected,
    isStretchSelected,
    isBaselineSelected,
    handleCenterClick,
    handleFlexStartClick,
    handleFlexEndClick,
    handleStretchClick,
    handleBaselineClick,
    isFlexDirectionIsColumn,
  };
};
