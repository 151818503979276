import { FC } from 'react';
import { MultiDirectionalDimensionField } from '../../../../base';
import { usePaddingFields } from './usePaddingFields';

export const PaddingField: FC = () => {
  const { paddingField, rawPaddingValue, parsedRawPaddingValue, isComputed } =
    usePaddingFields();

  return (
    <MultiDirectionalDimensionField
      icon="DDP_FIELD_PADDING_ALL"
      tooltipTitle="Padding"
      {...paddingField}
      rawValue={rawPaddingValue}
      parsedRawValue={parsedRawPaddingValue}
      isComputed={isComputed}
    />
  );
};
