import { CanjuxState } from '@jux/canjux/core';

export const getNodeComponentById = ({
  components,
  id,
}: {
  components: CanjuxState['components'];
  id?: string;
}) => {
  return id ? components[id] : undefined;
};
