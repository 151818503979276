import { trimLowerCaseStr } from '@jux/ui/utils/trimLowerCaseStr';
import { ComponentTagName, ComponentTagNames } from '@jux/data-entities';
import {
  SupportedDisplayValues,
  DisplayValue,
  svgDisplayValueList,
  fullDisplayValueList,
} from './DisplayField.interface';

export const parseDisplay = (
  value: string
): SupportedDisplayValues | undefined => {
  switch (trimLowerCaseStr(value)) {
    case 'block':
      return DisplayValue.block;
    case 'flex':
      return DisplayValue.flex;
    case 'inline':
      return DisplayValue.inline;
    case 'inline-block':
      return DisplayValue.inlineBlock;
    case 'inline-flex':
      return DisplayValue.inlineFlex;
    case 'none':
      return DisplayValue.none;
  }

  return undefined;
};

export const getDisplayValueList = (tagName?: ComponentTagName) => {
  switch (tagName) {
    case ComponentTagNames.JuxSvg:
      return svgDisplayValueList;
    default:
      return fullDisplayValueList;
  }
};
