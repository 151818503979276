import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { withPx } from '@jux/ui/utils/css';
import { PopperDirection } from '@jux/ui/components';
import { useUserMenu } from './useUserMenu';
import { UserMenuItem } from './UserMenuItem';

const shadowSpace = (radius: number) => withPx(radius * 2);
const minusShadowSpace = (radius: number) => `-${shadowSpace(radius)}`;

export const MenuPopper: FC<{
  direction: PopperDirection;
}> = ({ direction }) => {
  const { hasUser, menuList } = useUserMenu();

  // TODO: Create proper element for Signup
  if (!hasUser) return <>Signup</>;

  return (
    <MuiGrid
      sx={{
        // This is a hack to disable the box shadow effect on top of the element, so it will not be shown on top of other elements
        [direction === 'rtl' ? 'marginRight' : 'marginLeft']: (theme) =>
          `${minusShadowSpace(
            theme.drimz.shadow.common.menuList.spreadRadius
          )}`,
        padding: (theme) =>
          `${shadowSpace(theme.drimz.shadow.common.menuList.spreadRadius)}`,
        paddingTop: 0,
        overflow: 'hidden',
      }}
    >
      <MuiGrid
        sx={{
          background: (theme) => theme.drimz.palette.background.default,
          borderRadius: (theme) =>
            `0 0 ${theme.drimz.size.borderRadius.small} ${theme.drimz.size.borderRadius.small}`,
          overflow: 'hidden',
          minWidth: 296,
          border: '1px solid',
          borderColor: (theme) => theme.drimz.palette.divider.primary,
          boxShadow: (theme) => theme.drimz.shadow.common.menuList.boxShadow,
          padding: '4px',
        }}
      >
        {menuList.map((props, index) => (
          <UserMenuItem key={index} {...props} />
        ))}
      </MuiGrid>
    </MuiGrid>
  );
};
