import { useEffect } from 'react';

export const useScripts = ({
  context,
  scripts,
  onScriptsMounted,
  onScriptsUnmounted,
}: {
  context?: Window;
  scripts?: string[];
  onScriptsMounted?: () => void;
  onScriptsUnmounted?: () => void;
}) => {
  // inject scripts into the iframe's head
  useEffect(() => {
    const head = context?.document.head;
    let scriptElements: HTMLScriptElement[] = [];

    if (head && scripts && scripts.length > 0) {
      // add scripts to the iframe's head
      scriptElements = scripts?.map((script) => {
        const scriptElement = context.document.createElement('script');

        scriptElement.type = 'text/javascript';
        scriptElement.text = script;

        head.appendChild(scriptElement);

        return scriptElement;
      });

      onScriptsMounted?.();
    }

    return () => {
      if (head && scriptElements.length > 0) {
        for (const scriptElement of scriptElements) {
          head.removeChild(scriptElement);
        }

        onScriptsUnmounted?.();
      }
    };
  }, [context?.document, scripts, onScriptsMounted, onScriptsUnmounted]);
};
