import { useCallback } from 'react';
import { atom, useAtom } from 'jotai';

export const isSidebarOpenStateAtom = atom<boolean>(false);

export const useIsSidebarOpenState = () => useAtom(isSidebarOpenStateAtom);

export const useToggleSidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useIsSidebarOpenState();

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen((prev) => !prev);
  }, [setIsSidebarOpen]);

  const setOpen = useCallback(() => {
    setIsSidebarOpen(true);
  }, [setIsSidebarOpen]);

  const setClose = useCallback(() => {
    setIsSidebarOpen(false);
  }, [setIsSidebarOpen]);

  return {
    isOpen: isSidebarOpen,
    isClose: !isSidebarOpen,
    toggle: toggleSidebar,
    open: setOpen,
    close: setClose,
  };
};
