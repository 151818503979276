import { styled } from '@jux/ui/components/common/mui';
import { NAVIGATOR_COMPONENT_ITEM_ICON_HOVER_COLOR } from '@jux/ui/theme/palette';
import { ItemBox } from '../ItemLayout.style';
import { ComponentPreviewLoadingWrapper } from './ComponentPreview.style';

export const ComponentPreviewItemWrapper = styled(ItemBox)(({ theme }) => ({
  position: 'relative',
  justifyContent: 'flex-start',
  gap: '4px',

  backgroundColor: theme.drimz.palette.transparent,
  border: `1px solid ${theme.drimz.palette.background.hover}`,

  '&:hover': {
    border: `1px solid ${theme.drimz.palette.background.secondaryHover}`,

    [`& .${ComponentPreviewLoadingWrapper.defaultProps?.className}`]: {
      color: NAVIGATOR_COMPONENT_ITEM_ICON_HOVER_COLOR,
    },
  },
}));
