import { useEventListener } from 'usehooks-ts';
import { RefObject, useCallback } from 'react';

type EventType = 'mousedown' | 'mouseup' | 'touchstart' | 'touchend' | 'click';
type RefOrSelector = RefObject<HTMLElement> | string;

export function useOnClickOutside({
  refOrSelector,
  handler,
  eventType = 'mousedown',
  options = {},
}: {
  refOrSelector: Array<RefOrSelector | RefOrSelector>;
  handler: (event: MouseEvent | TouchEvent) => void;
  eventType?: EventType;
  options?: { eventListenerOptions?: AddEventListenerOptions };
}): void {
  const { eventListenerOptions } = options;

  const isOutside = useCallback(
    (target: Node) => {
      const checkRef = (ref: RefOrSelector) => {
        if (typeof ref === 'string') {
          const elements = document.querySelectorAll(ref);
          return Array.from(elements).every((el) => !el.contains(target));
        }
        return ref?.current ? !ref.current.contains(target) : true;
      };

      return Array.isArray(refOrSelector)
        ? refOrSelector.every(checkRef)
        : checkRef(refOrSelector);
    },
    [refOrSelector]
  );

  useEventListener(
    eventType,
    (event) => {
      const target = event.target as Node;

      if (!target || !target.isConnected) {
        return;
      }

      if (isOutside(target)) {
        handler(event);
      }
    },
    undefined,
    eventListenerOptions
  );
}
