/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';

const { styled } = createDrimz();

type JuxButton_text_root_Props = PropsOf<typeof JuxReactElements.JuxText>;

const JuxButton_text_root: FC<JuxButton_text_root_Props> = forwardRef(
  (props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />
);

JuxButton_text_root.displayName = 'JuxButton_text_root';

JuxButton_text_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        color: '#000000',
        width: 'auto',
        height: 'auto',
        display: 'inline-block',
        fontSize: '20px',
        fontFamily: 'Inter',
        lineHeight: '130%',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
      },
    },
  }) as JuxButton_text_root_Props['rootElement'],
};

export type JuxButton_text_Props = JuxButton_text_root_Props;

export const JuxButton_text: FC<JuxButton_text_Props> = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>,
  JuxButton_text_Props
>((props, ref) => <JuxButton_text_root {...props} ref={ref} />);

JuxButton_text.displayName = 'JuxButton_text';
JuxButton_text.defaultProps = { text: 'Send Invite' };

type JuxButton_root_Props = PropsOf<typeof JuxReactElements.JuxButton> & {
  hierarchy: 'secondary' | 'Primary';
  disabled: true | false;
  size: 'medium' | 'big';
};

const JuxButton_root: FC<JuxButton_root_Props> = forwardRef((props, ref) => (
  <JuxReactElements.JuxButton {...props} ref={ref} />
));

JuxButton_root.displayName = 'JuxButton_root';

JuxButton_root.defaultProps = {
  rootElement: styled('button', {
    styles: {
      root: {
        gap: '8px',
        color: '#FFFFFF',
        width: 'auto',
        cursor: 'pointer',
        height: '36px',
        display: 'flex',
        padding: '8px 16px',
        fontSize: '14px',
        fontStyle: 'normal',
        alignItems: 'center',
        fontFamily: 'Inter',
        fontWeight: '500',
        lineHeight: '20px',
        borderColor: '',
        borderStyle: 'none',
        borderRadius: '4px',
        flexDirection: 'row',
        justifyContent: 'center',
        '&:focus': { outline: 'none' },
        '&:active': { outline: 'none' },
        '&:focus-visible': {
          borderRadius: '8px',
          outlineColor: '#3851DD',
          outlineStyle: 'solid',
          outlineWidth: '2px',
          outlineOffset: '2px',
        },
      },
      variants: [
        {
          propsValues: {
            size: 'medium',
            disabled: false,
            hierarchy: 'primary',
          },
          styles: {
            gap: '{dimension.core.size_xs}',
            width: '124px',
            height: '32px',
            padding: '0px 0px 0px 0px',
            borderRadius: '2px',
            backgroundColor: '{color.core.grays_0}',
            '[data-jux-context="b34633ac-ece7-4d31-ad41-50bb9477b7dc"]': {
              color: '{color.core.grays_800}',
            },
            '&:hover': {
              backgroundColor: '{color.core.grays_200}',
              '[data-jux-context="b34633ac-ece7-4d31-ad41-50bb9477b7dc"]': {
                color: '{color.core.grays_800}',
              },
            },
            '&:active': { backgroundColor: '{color.core.grays_400}' },
          },
        },
        {
          propsValues: {
            size: 'medium',
            disabled: false,
            hierarchy: 'secondary',
          },
          styles: {
            height: '28px',
            padding: '10px 10px 10px 10px',
            borderColor: '{color.core.grays_400}',
            borderStyle: 'Solid',
            borderWidth: '1px',
            borderRadius: '2px',
            backgroundColor: '{color.core.grays_0}',
            '[data-jux-context="7f54036e-ba76-46a3-957f-bd766d732540"]': {
              fontSize: '{typography.core.12_regular.fontSize}',
              fontFamily: '{typography.core.12_regular.fontFamily}',
              fontWeight: '{typography.core.12_regular.fontWeight}',
              lineHeight: '{typography.core.12_regular.lineHeight}',
              letterSpacing: '{typography.core.12_regular.letterSpacing}',
            },
            '&:hover': {
              borderColor: '{color.core.grays_500}',
              backgroundColor: '{color.core.grays_0}',
            },
            '&:active': {
              borderColor: '{color.core.grays_800}',
              backgroundColor: '{color.core.grays_0}',
            },
          },
        },
        {
          propsValues: { size: 'medium', disabled: true, hierarchy: 'primary' },
          styles: {
            cursor: 'default',
            height: '28px',
            padding:
              '{dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs}',
            borderRadius: '2px',
            backgroundColor: '{color.core.grays_200}',
            '&:active': {
              height: '28px',
              padding:
                '{dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs}',
              borderRadius: '2px',
            },
          },
        },
        {
          propsValues: {
            size: 'medium',
            disabled: true,
            hierarchy: 'secondary',
          },
          styles: { cursor: 'default', backgroundColor: '#C8C8C8' },
        },
        {
          propsValues: {
            size: 'medium',
            disabled: false,
            hierarchy: 'Primary',
          },
          styles: {
            gap: '{dimension.core.size_xs}',
            width: 'auto',
            height: '28px',
            display: 'flex',
            padding: '10px 10px 10px 10px',
            alignItems: 'center',
            borderRadius: '2px',
            justifyContent: 'center',
            backgroundColor: '{color.core.grays_800}',
            '[data-jux-context="b34633ac-ece7-4d31-ad41-50bb9477b7dc"]': {
              color: '{color.core.grays_0}',
            },
            '[data-jux-context="7f54036e-ba76-46a3-957f-bd766d732540"]': {
              color: 'rgba(255, 255, 255, 1)',
              fontSize: '{typography.core.12_regular.fontSize}',
              fontFamily: '{typography.core.12_regular.fontFamily}',
              fontWeight: '{typography.core.12_regular.fontWeight}',
              lineHeight: '{typography.core.12_regular.lineHeight}',
              letterSpacing: '{typography.core.12_regular.letterSpacing}',
            },
            '&:hover': { backgroundColor: '{color.core.grays_700}' },
            '&:active': { backgroundColor: '{color.core.grays_1000}' },
          },
        },
        {
          propsValues: { size: 'medium', disabled: true, hierarchy: 'Primary' },
          styles: {
            cursor: 'default',
            height: '28px',
            padding:
              '{dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs}',
            borderRadius: '2px',
            backgroundColor: '{color.core.grays_200}',
            '&:active': {
              height: '28px',
              padding:
                '{dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs}',
              borderRadius: '2px',
            },
          },
        },
        {
          propsValues: { size: 'big', disabled: false, hierarchy: 'primary' },
          styles: {
            gap: '{dimension.core.size_xs}',
            width: '124px',
            height: '32px',
            padding: '0px 0px 0px 0px',
            borderRadius: '2px',
            backgroundColor: '{color.core.grays_0}',
            '[data-jux-context="b34633ac-ece7-4d31-ad41-50bb9477b7dc"]': {
              color: '{color.core.grays_800}',
            },
            '&:hover': {
              backgroundColor: '{color.core.grays_200}',
              '[data-jux-context="b34633ac-ece7-4d31-ad41-50bb9477b7dc"]': {
                color: '{color.core.grays_800}',
              },
            },
            '&:active': { backgroundColor: '{color.core.grays_400}' },
          },
        },
        {
          propsValues: { size: 'big', disabled: false, hierarchy: 'secondary' },
          styles: {
            height: '36px',
            padding:
              '{dimension.core.size_sm} {dimension.core.size_ml} {dimension.core.size_sm} {dimension.core.size_ml}',
            borderColor: '{color.core.grays_400}',
            borderStyle: 'Solid',
            borderWidth: '1px',
            borderRadius: '2px',
            backgroundColor: '{color.core.grays_0}',
            '[data-jux-context="7f54036e-ba76-46a3-957f-bd766d732540"]': {
              fontSize: '{typography.core.12_regular.fontSize}',
              fontFamily: '{typography.core.12_regular.fontFamily}',
              fontWeight: '{typography.core.12_regular.fontWeight}',
              lineHeight: '{typography.core.12_regular.lineHeight}',
              letterSpacing: '{typography.core.12_regular.letterSpacing}',
            },
            '&:hover': {
              borderColor: '{color.core.grays_500}',
              backgroundColor: '{color.core.grays_0}',
            },
            '&:active': {
              borderColor: '{color.core.grays_800}',
              backgroundColor: '{color.core.grays_0}',
            },
          },
        },
        {
          propsValues: { size: 'big', disabled: true, hierarchy: 'primary' },
          styles: {
            cursor: 'default',
            height: '28px',
            padding:
              '{dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs}',
            borderRadius: '2px',
            backgroundColor: '{color.core.grays_200}',
            '&:active': {
              height: '28px',
              padding:
                '{dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs}',
              borderRadius: '2px',
            },
          },
        },
        {
          propsValues: { size: 'big', disabled: true, hierarchy: 'secondary' },
          styles: { cursor: 'default', backgroundColor: '#C8C8C8' },
        },
        {
          propsValues: { size: 'big', disabled: false, hierarchy: 'Primary' },
          styles: {
            gap: '{dimension.core.size_xs}',
            width: 'auto',
            height: '36px',
            display: 'flex',
            padding:
              '{dimension.core.size_sm} {dimension.core.size_ml} {dimension.core.size_sm} {dimension.core.size_ml}',
            borderRadius: '2px',
            justifyContent: 'space-between',
            backgroundColor: '{color.core.grays_800}',
            '[data-jux-context="b34633ac-ece7-4d31-ad41-50bb9477b7dc"]': {
              color: '{color.core.grays_0}',
            },
            '[data-jux-context="7f54036e-ba76-46a3-957f-bd766d732540"]': {
              color: 'rgba(255, 255, 255, 1)',
              fontSize: '{typography.core.12_regular.fontSize}',
              fontFamily: '{typography.core.12_regular.fontFamily}',
              fontWeight: '{typography.core.12_regular.fontWeight}',
              lineHeight: '{typography.core.12_regular.lineHeight}',
              letterSpacing: '{typography.core.12_regular.letterSpacing}',
            },
            '&:hover': { backgroundColor: '{color.core.grays_700}' },
            '&:active': { backgroundColor: '{color.core.grays_1000}' },
          },
        },
        {
          propsValues: { size: 'big', disabled: true, hierarchy: 'Primary' },
          styles: {
            cursor: 'default',
            height: '28px',
            padding:
              '{dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs}',
            borderRadius: '2px',
            backgroundColor: '{color.core.grays_200}',
            '&:active': {
              height: '28px',
              padding:
                '{dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs} {dimension.core.size_xs}',
              borderRadius: '2px',
            },
          },
        },
      ],
    },
  }) as JuxButton_root_Props['rootElement'],
};

export type JuxButton_Props = JuxButton_root_Props & {
  JuxButton_text_Props?: JuxButton_text_Props;
};

export const JuxButton: FC<JuxButton_Props> = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxButton>,
  JuxButton_Props
>(({ JuxButton_text_Props, ...props }, ref) => (
  <JuxButton_root
    {...props}
    ref={ref}
    data-jux-context="29604b27-5ff7-4b22-93ce-4c0184b1c325"
  >
    {props.children || (
      <>
        <JuxButton_text
          {...JuxButton_text_Props}
          data-jux-context="7f54036e-ba76-46a3-957f-bd766d732540"
        />
      </>
    )}
  </JuxButton_root>
));

JuxButton.displayName = 'JuxButton';
JuxButton.defaultProps = {
  size: 'medium',
  disabled: false,
  hierarchy: 'secondary',
};
