import {
  CanjuxState,
  getClonedContextStyles,
  getContextParentByNodeId,
  getContextParentOfNode,
  JuxStore,
  selectOrgComponentsNames,
} from '@jux/canjux/core';
import { NodeType } from '@jux/data-entities';
import { generateName } from '@jux/ui/utils/generateName';
import type { Draft as WritableDraft } from 'mutative';
import { create } from 'mutative';
import { v4 as uuidv4 } from 'uuid';
import { getDefaultNodeData } from '../../helpers';
import { moveNodes } from '../moveNodes';

const duplicateNodeAndItsChildren = ({
  canvases,
  components,
  displayName,
  position,
  sourceComponentId,
  targetCanvasName,
  targetNodeId,
}: {
  canvases: WritableDraft<JuxStore['canvases']>;
  components: WritableDraft<JuxStore['components']>;
  displayName?: string;
  position?: { x: number; y: number };
  sourceComponentId: string;
  targetCanvasName: string;
  targetNodeId?: string;
}) => {
  const newNodeId = uuidv4();
  const sourceComponent = components[sourceComponentId];

  // Create new component data from the source component
  components[newNodeId] = create(sourceComponent, (draft) => {
    draft.id = newNodeId;

    // change the parent
    draft.parentId = targetNodeId;

    // reset children and copy them later
    draft.children = [];

    // Reset context styles (will be cloned later)
    if (draft.styles) {
      draft.styles.contextStyles = [];
    }

    // set display name if passed
    if (displayName) {
      draft.displayName = displayName;
    }
  });

  if (targetNodeId) {
    // Add the new component to the target node's children
    components[targetNodeId].children.push(newNodeId);

    // Get the context parent of the source node
    const sourceContextParent = getContextParentOfNode({
      components,
      nodeId: sourceComponentId,
    });

    // Get the context parent of the new node (must be after it was added to a parent)
    const target = getContextParentOfNode({
      components,
      nodeId: newNodeId,
    });

    // Copy context styles from the source context parent to the new context parent
    if (sourceContextParent && target) {
      target.styles.contextStyles = getClonedContextStyles({
        sourceChildUuid: sourceComponentId,
        sourceContextStyles: sourceContextParent.styles.contextStyles,
        targetChildUuid: newNodeId,
      });
    }
  } else {
    // root node
    canvases[targetCanvasName].rootNodesOrder.unshift(newNodeId);
  }

  // Create new node data on the target canvas
  canvases[targetCanvasName].nodes[newNodeId] = getDefaultNodeData({
    isContainer:
      canvases[targetCanvasName].nodes[sourceComponentId]?.properties
        .isContainer,
    parentId: targetNodeId,
    position,
  });

  // Copy children of the source node recursively
  components[sourceComponentId].children.forEach((childComponentId) =>
    duplicateNodeAndItsChildren({
      canvases,
      components,
      sourceComponentId: childComponentId,
      targetCanvasName: targetCanvasName,
      targetNodeId: newNodeId,
    })
  );

  return newNodeId;
};

/**
 * Duplicates a node and its children to a target node on canvas.
 * If target node is under a component, the new node will be added to all component instances.
 * If target node is not supplied, the new node will be added to the canvas root nodes order.
 * @param sourceComponentId - The ID of the source component.
 * @param targetCanvasName - The Name of the target canvas.
 * @param targetNodeId - The ID of the target node.
 * @param position - The position of the new node.
 * @param state - The draft of the store.
 */
export const duplicateNode = ({
  position,
  sourceComponentId,
  state,
  targetCanvasName,
  targetIndex,
  targetNodeId,
}: {
  position?: { x: number; y: number };
  sourceComponentId: string;
  state: WritableDraft<CanjuxState>;
  targetCanvasName: string;
  targetIndex?: number;
  targetNodeId?: string;
}) => {
  const sourceComponent = state.components[sourceComponentId];
  let displayName;
  if (
    sourceComponent.type === NodeType.LOCAL_COMPONENT ||
    sourceComponent.type === NodeType.LIBRARY_COMPONENT
  ) {
    displayName = generateName({
      baseName: sourceComponent.displayName + '_copy',
      namesArray: selectOrgComponentsNames(state),
      options: { formatters: ['formatCase', 'removeIllegalChars'] },
      separator: '',
    });
  }

  // Duplicate the node and its children to a new root node
  const newNodeId = duplicateNodeAndItsChildren({
    // Do not supply targetNodeId. first create as root
    canvases: state.canvases,
    components: state.components,
    displayName,
    position,
    sourceComponentId,
    targetCanvasName: targetCanvasName,
  });

  if (targetNodeId) {
    // Move the new node to the target node
    moveNodes({
      sourceNodeIds: [newNodeId],
      targetNodeId,
      targetIndex,
      state,
    });

    const contextParentId = getContextParentByNodeId({
      id: sourceComponentId,
      components: state.components,
    });

    if (contextParentId) {
      // Duplicate context styles of the sourceComponentId to the new node
      const parentContextComponent = state.components[contextParentId];
      const parentContextStyles =
        parentContextComponent?.type !== NodeType.INSTANCE
          ? parentContextComponent?.styles?.contextStyles ?? []
          : [];
      for (const contextStyle of parentContextStyles) {
        if (contextStyle.contextChildUuid === sourceComponentId) {
          parentContextStyles.push({
            ...contextStyle,
            contextChildUuid: newNodeId,
          });
        }
      }
    }
  }

  return newNodeId;
};
