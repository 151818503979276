import { useCallback } from 'react';
import { usePopperState } from './usePopperState';
import {
  PopperActions,
  PopperHandlersRecord,
  PopperStates,
  PoppersKeys,
  UpdatePopperArgs,
} from './usePopperState.interface';

export const usePopper = <PH extends PopperHandlersRecord>(
  popperKey?: PoppersKeys
) => {
  const {
    content,
    handlers,
    styles,
    sourceElement,
    reset,
    setState,
    state,
    currentlyOpenedKey,
    previouslyOpenedKey,
    resetPreviouslyOpenedKey,
    currentlyOpenedInnerKey,
    previouslyOpenedInnerKey,
    resetPreviouslyOpenedInnerKey,
  } = usePopperState();

  const key = popperKey || currentlyOpenedKey;

  const close = useCallback(
    (closeProps?: {
      options?: { shouldResetPreviouslyOpenedKey?: boolean };
      innerKey?: UpdatePopperArgs<PH>['innerKey'];
    }) => {
      const { options, innerKey } = closeProps ?? {};
      const { shouldResetPreviouslyOpenedKey } = options ?? {};

      handlers.onClose?.();

      if (key) {
        setState({
          action: [key, PopperActions.close],
          previouslyOpenedPopperKey: shouldResetPreviouslyOpenedKey
            ? null
            : key,
          innerKey: shouldResetPreviouslyOpenedKey
            ? null
            : innerKey ?? currentlyOpenedInnerKey,
        });
      }
    },
    [currentlyOpenedInnerKey, handlers, key, setState]
  );

  const open = useCallback(
    ({
      handlers: newHandlers,
      content: newContent,
      sourceElement: newSourceElement,
      styles: newStyles,
      innerKey: newInnerKey,
    }: {
      handlers?: UpdatePopperArgs<PH>['handlers'];
      content: UpdatePopperArgs<PH>['content'];
      sourceElement: HTMLElement | null;
      styles?: UpdatePopperArgs<PH>['styles'];
      innerKey?: UpdatePopperArgs<PH>['innerKey'];
    }) => {
      if (!key) return;

      // this is a hack to prevent the popper from re-opening when clicking on the same source element
      const wasPopperKeyOpen = previouslyOpenedKey === key;
      const wasPopperInnerKeyOpen = previouslyOpenedInnerKey === newInnerKey;
      if (wasPopperKeyOpen && (newInnerKey ? wasPopperInnerKeyOpen : true)) {
        resetPreviouslyOpenedKey();
        resetPreviouslyOpenedInnerKey();
        return;
      }

      setState({
        action: [key, PopperActions.open],
        content: newContent,
        handlers: newHandlers,
        styles: newStyles,
        sourceElement: newSourceElement,
        previouslyOpenedPopperKey: null,
        innerKey: newInnerKey,
      });
    },
    [
      key,
      previouslyOpenedInnerKey,
      previouslyOpenedKey,
      resetPreviouslyOpenedInnerKey,
      resetPreviouslyOpenedKey,
      setState,
    ]
  );

  const isOpen = useCallback(
    (innerKey?: string): boolean => {
      if (!key) return false;

      const opened = state[key] === PopperStates.opened;

      if (innerKey) {
        return opened && currentlyOpenedInnerKey === innerKey;
      }

      return opened;
    },
    [currentlyOpenedInnerKey, key, state]
  );

  const isClosed = useCallback(
    (innerKey?: string): boolean => {
      if (!key) return true;

      const closed = state[key] === PopperStates.closed;

      if (innerKey) {
        return closed && currentlyOpenedInnerKey === innerKey;
      }

      return closed;
    },
    [currentlyOpenedInnerKey, key, state]
  );

  return {
    content,
    styles,
    sourceElement,
    state,
    open,
    close,
    reset,
    isOpen,
    isClosed,
    currentlyOpenedInnerKey,
  };
};
