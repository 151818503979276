import {
  type ComponentConfig,
  type ComponentConfigWithStates,
  ComponentStatesStyles,
  ContextStyle,
  DefaultState,
  ElementState,
  StylesObject,
  type StyleVariantsList,
} from '@jux/types';
import deepmerge from 'deepmerge';
import { isEmpty } from 'lodash';
import { getContextChildSelector } from './getContextChildSelector';
import { sortStates } from './sortStates';

const mergeContextStyles = (
  styles: Record<string, StylesObject>,
  contextStyle: ContextStyle
) => {
  const selector = getContextChildSelector(contextStyle.contextChildUuid);
  styles[selector] = deepmerge(styles[selector] || {}, contextStyle.styles);
  return styles;
};

const DEFAULT_STATES: {
  [state in ElementState]: ComponentStatesStyles[ElementState | DefaultState];
} = {
  hover: {
    contextStyles: [],
    root: {},
    variants: [],
  },
  active: {
    contextStyles: [],
    root: {},
    variants: [],
  },
  focus: {
    contextStyles: [],
    root: {},
    variants: [],
  },
  'focus-visible': {
    contextStyles: [],
    root: {},
    variants: [],
  },
  'focus-within': {
    contextStyles: [],
    root: {},
    variants: [],
  },
};

/**
 * Returns an augmented clone of the original object,
 * state styles are injected into root and variants,
 * using their respective state selectors
 * @param styles {ComponentConfigWithStates} - The component's config with states.
 * @returns The same config, with state selectors.
 */
export const getStylesWithStateSelectors = (
  styles: ComponentConfigWithStates
): ComponentConfig => {
  const { states: partialStates, ...stylesWithoutStates } = styles;

  const states = deepmerge(DEFAULT_STATES, partialStates);

  if (!states) {
    return {
      ...stylesWithoutStates,
      root: deepmerge(
        stylesWithoutStates.root,
        stylesWithoutStates.contextStyles
          ?.filter(({ condition }) => !condition?.state)
          .reduce(mergeContextStyles, {}) || {}
      ),
    };
  }

  if (!stylesWithoutStates.variants) {
    stylesWithoutStates.variants = [];
  }

  const statelessContextStyles =
    stylesWithoutStates.contextStyles?.filter(
      (contextStyle) =>
        !contextStyle.condition?.state ||
        contextStyle.condition?.state === 'default'
    ) || [];

  // Extrapolate variants from context styles without states
  for (const contextStyle of statelessContextStyles) {
    stylesWithoutStates.variants.push({
      propsValues: contextStyle.condition?.propsValues || {},
      styles: {
        [getContextChildSelector(contextStyle.contextChildUuid)]:
          contextStyle.styles,
      },
    });
  }

  // Extrapolate root and variants from the states
  Object.entries(states)
    .sort(sortStates)
    .forEach(([state, stateOverrides]) => {
      const stateSelector = `&:${state}`;

      const relevantContextStyles =
        stylesWithoutStates.contextStyles?.filter(
          (contextStyle) => contextStyle.condition?.state === state
        ) || [];

      Object.entries(stateOverrides).forEach(([key, value]) => {
        // Handle root styles separately from variants -
        // turn this: { root: { color: 'lightsalmon' } }
        // into this: { root: { color: 'lightsalmon', '&:hover': { color: 'darksalmon' } } }
        const mergedStyles = deepmerge(
          value as StylesObject,
          relevantContextStyles
            .filter(({ condition }) => !condition?.propsValues)
            .reduce(mergeContextStyles, {}) || {}
        );
        if (key === 'root' && !isEmpty(mergedStyles)) {
          stylesWithoutStates.root = {
            ...stylesWithoutStates.root,
            [stateSelector]: mergedStyles,
          };

          return;
        }

        if (key === 'variants') {
          if (!stylesWithoutStates.variants) {
            stylesWithoutStates.variants = [];
          }

          // Append the state selector to each variant value (e.g. `color.primary`) -
          // turn this: { color: { primary: { color: 'lightsalmon' } } }
          // into this: { color: { primary: { color: 'lightsalmon', '&:hover': { color: 'darksalmon' } } } }
          for (const {
            propsValues,
            styles: variantStyles,
          } of value as StyleVariantsList) {
            if (!isEmpty(variantStyles)) {
              stylesWithoutStates.variants.push({
                propsValues,
                styles: {
                  [stateSelector]: variantStyles,
                },
              });
            }
          }

          // Extrapolate variants from context styles relevant to this state
          for (const contextStyle of relevantContextStyles) {
            if (
              contextStyle.condition?.propsValues &&
              !isEmpty(contextStyle.styles)
            ) {
              stylesWithoutStates.variants.push({
                propsValues: contextStyle.condition.propsValues || {},
                styles: {
                  [stateSelector]: {
                    [getContextChildSelector(contextStyle.contextChildUuid)]:
                      contextStyle.styles,
                  },
                },
              });
            }
          }
        }
      });
    });

  return stylesWithoutStates;
};
