import {
  KeyboardEvent as ReactKeyboardEvent,
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent,
} from 'react';

const isReactKeyboardEvent = (
  event: KeyboardEvent | ReactKeyboardEvent
): event is ReactKeyboardEvent => 'nativeEvent' in event;

export function isInputDOMNode(
  event: KeyboardEvent | ReactKeyboardEvent
): boolean {
  const kbEvent = isReactKeyboardEvent(event) ? event.nativeEvent : event;
  // using composed path for handling shadow dom
  const target = (kbEvent.composedPath?.()?.[0] || event.target) as HTMLElement;

  const isInput =
    ['INPUT', 'SELECT', 'TEXTAREA'].includes(target?.nodeName) ||
    target?.hasAttribute('contenteditable');

  // when an input field is focused we don't want to trigger deletion or movement of nodes
  return isInput || !!target?.closest('.nokey');
}

export const isMouseEvent = (
  event: MouseEvent | ReactMouseEvent | TouchEvent | ReactTouchEvent
): event is MouseEvent | ReactMouseEvent => 'clientX' in event;

export const getEventPosition = (
  event: MouseEvent | ReactMouseEvent | TouchEvent | ReactTouchEvent,
  bounds?: DOMRect | null
) => {
  const isMouseTriggered = isMouseEvent(event);
  const evtX = isMouseTriggered ? event.clientX : event.touches?.[0].clientX;
  const evtY = isMouseTriggered ? event.clientY : event.touches?.[0].clientY;

  return {
    x: evtX - (bounds?.left ?? 0),
    y: evtY - (bounds?.top ?? 0),
  };
};

export const getPositionFromMouseEvent = (event: any) => {
  const x = event.touches ? event.touches[0].clientX : event.clientX;
  const y = event.touches ? event.touches[0].clientY : event.clientY;

  return { x, y };
};
