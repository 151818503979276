import {
  LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_IDLE_BG_COLOR,
  LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_LIBRARY_COMPONENT_TEXT_COLOR,
  LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_TEXT_COLOR,
  LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_LIBRARY_COMPONENT_SELECTED_BG_COLOR,
  LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_SELECTED_BG_COLOR,
  LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_LIBRARY_COMPONENT_ANCESTOR_SELECTED_BG_COLOR,
  LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_ANCESTOR_SELECTED_BG_COLOR,
  LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_HOVERED_BORDER_COLOR,
  NAVIGATOR_LOGICAL_SLOT_COLOR,
} from '@jux/ui/theme/palette';
import { ObjectNavigatorItemWrapperProps } from '../ObjectNavigatorItemWrapper';

export type GetColorsProps = Pick<
  ObjectNavigatorItemWrapperProps,
  | 'isSelected'
  | 'hasAncestorSelected'
  | 'isHovered'
  | 'isLibraryComponent'
  | 'isLogicalSlot'
  | 'hasAncestorLibraryComponent'
  | 'isDraggedInside'
>;

// missing drag inside colors
// missing children of library component colors

// the cases
// 1. idle - supporting
// 2. hovered - supporting
// 3. selected - supporting
// 4. hovered & selected - supporting
// 5. ancestor selected (style for child) - supporting
// 6. ancestor hovered (style for child) - not needed to style child of hovered ancestor
// 7. ancestor hovered & ancestor selected (style for child) - not needed to style child of hovered ancestor
// 8. ancestor selected & child is hovered (style for child) - supporting
// 9. ancestor selected & ancestor is hovered (style for child) - not needed to style child of hovered ancestor
export const getColors = ({
  isSelected,
  hasAncestorSelected,
  isHovered,
  isLibraryComponent,
  isLogicalSlot,
  hasAncestorLibraryComponent,
  isDraggedInside,
}: GetColorsProps): {
  color: string;
  backgroundColor: string;
  borderColor: string;
} => {
  const shouldShowBorder = isHovered || isDraggedInside;
  const shouldUseLibraryComponentStyles =
    isLibraryComponent || hasAncestorLibraryComponent;

  const idleBgColor = LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_IDLE_BG_COLOR;

  const textColor = shouldUseLibraryComponentStyles
    ? LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_LIBRARY_COMPONENT_TEXT_COLOR
    : isLogicalSlot
    ? NAVIGATOR_LOGICAL_SLOT_COLOR
    : LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_TEXT_COLOR;

  const selectedBgColor = shouldUseLibraryComponentStyles
    ? LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_LIBRARY_COMPONENT_SELECTED_BG_COLOR
    : LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_SELECTED_BG_COLOR;
  const ancestorSelectedBgColor = shouldUseLibraryComponentStyles
    ? LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_LIBRARY_COMPONENT_ANCESTOR_SELECTED_BG_COLOR
    : LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_ANCESTOR_SELECTED_BG_COLOR;
  const hoveredBorderColor =
    LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_HOVERED_BORDER_COLOR;

  if (hasAncestorSelected) {
    return {
      color: textColor,
      backgroundColor: ancestorSelectedBgColor,
      borderColor: shouldShowBorder
        ? hoveredBorderColor
        : ancestorSelectedBgColor,
    };
  }

  if (isSelected) {
    return {
      color: textColor,
      backgroundColor: selectedBgColor,
      borderColor: shouldShowBorder ? hoveredBorderColor : selectedBgColor,
    };
  }

  return {
    color: textColor,
    backgroundColor: idleBgColor,
    borderColor: shouldShowBorder ? hoveredBorderColor : idleBgColor,
  };
};
