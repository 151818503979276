import { CSSProperties } from 'react';

export type SupportedJustifyContentValues = Extract<
  CSSProperties['justifyContent'],
  'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around'
>;

export const JustifyContentValue: {
  flexStart: Extract<SupportedJustifyContentValues, 'flex-start'>;
  center: Extract<SupportedJustifyContentValues, 'center'>;
  flexEnd: Extract<SupportedJustifyContentValues, 'flex-end'>;
  spaceBetween: Extract<SupportedJustifyContentValues, 'space-between'>;
  spaceAround: Extract<SupportedJustifyContentValues, 'space-around'>;
} = {
  flexStart: 'flex-start',
  center: 'center',
  flexEnd: 'flex-end',
  spaceAround: 'space-around',
  spaceBetween: 'space-between',
} as const;
