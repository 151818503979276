import {
  CANJUX_CONTAINER_PANE,
  getPositionFromMouseEvent,
  getPositionOnCanjux,
  storeApi,
  useStoreActions,
} from '@jux/canjux/core';
import logger from '@jux/ui-logger';
import type { D3ZoomEvent } from 'd3';
import { pointer, select, zoom, zoomIdentity } from 'd3';
import { FC, useEffect, useRef } from 'react';
import { useStore } from '../../hooks';
import useResizeHandler from '../../hooks/useResizeHandler';
import type { Viewport } from '../../types';
import { CoordinateExtent, PanOnScrollMode } from '../../types';
import {
  clamp,
  isEditableTextNodeExistInPosition,
  isNodeExistInPosition,
} from '../../utils';
import { CanvasContainerPaneProps } from './CanvasContainerPane.interface';
import * as S from './CanvasContainerPane.style';
import {
  eventToFlowTransform,
  isZoomScrollEvent,
  viewChanged,
} from './CanvasContainerPane.utils';

/**
 * CanvasContainer is the main container in charge of canvas behavior such as pan/scroll/zoom
 */
export const CanvasContainerPane: FC<CanvasContainerPaneProps> = ({
  children,
  defaultViewport,
  panOnDrag = false,
  panOnScroll = false,
  panOnScrollMode = PanOnScrollMode.Free,
  panOnScrollSpeed = 0.5,
  translateExtent,
  zoomOnDoubleClick = false,
  zoomOnPinch = true,
  zoomOnScroll = true,
}) => {
  const isZoomingOrPanning = useRef(false);
  const zoomedWithRightMouseButton = useRef(false);
  const zoomPane = useRef<HTMLDivElement>(null);
  const prevTransform = useRef<Viewport>({ x: 0, y: 0, zoom: 0 });

  const {
    d3Selection,
    d3Zoom,
    d3ZoomHandler,
    maxZoom,
    minZoom,
    paneDragging,
    transform,
    updatePresence,
    userSelectionActive,
  } = useStore((s) => ({
    d3Selection: s.d3Selection,
    d3Zoom: s.d3Zoom,
    d3ZoomHandler: s.d3ZoomHandler,
    maxZoom: s.maxZoom,
    minZoom: s.minZoom,
    paneDragging: s.paneDragging,
    transform: s.transform,
    updatePresence: s.liveblocks.room?.updatePresence,
    userSelectionActive: s.userSelectionActive,
  }));

  const {
    commonActions: { resetHoveredNodes, initD3Zoom },
  } = useStoreActions();

  const mouseButton = useRef<number>(0);

  useResizeHandler(zoomPane);

  useEffect(() => {
    if (zoomPane.current) {
      const bbox = zoomPane.current.getBoundingClientRect();
      const d3ZoomInstance = zoom()
        .scaleExtent([minZoom, maxZoom])
        .translateExtent(translateExtent);
      const selection = select(zoomPane.current as Element).call(
        d3ZoomInstance
      );
      const updatedTransform = zoomIdentity
        .translate(defaultViewport.x, defaultViewport.y)
        .scale(clamp(defaultViewport.zoom, minZoom, maxZoom));

      const extent: CoordinateExtent = [
        [0, 0],
        [bbox.width, bbox.height],
      ];

      const constrainedTransform = d3ZoomInstance.constrain()(
        updatedTransform,
        extent,
        translateExtent
      );

      d3ZoomInstance.transform(selection, constrainedTransform);

      initD3Zoom({
        d3Zoom: d3ZoomInstance,
        d3Selection: selection,
        d3ZoomHandler: selection.on('wheel.zoom'),
        transform: {
          x: constrainedTransform.x,
          y: constrainedTransform.y,
          zoom: constrainedTransform.k,
        },
      });
    }
  }, [
    defaultViewport.x,
    defaultViewport.y,
    defaultViewport.zoom,
    initD3Zoom,
    maxZoom,
    minZoom,
    translateExtent,
  ]);

  useEffect(() => {
    if (d3Selection && d3Zoom) {
      if (panOnScroll && !userSelectionActive) {
        d3Selection.on(
          'wheel.zoom',
          (event) => {
            event.preventDefault();
            event.stopImmediatePropagation();

            const currentZoom = d3Selection.property('__zoom').k || 1;
            const isZoomKeyPressed = isZoomScrollEvent(event);

            if (isZoomKeyPressed && zoomOnPinch) {
              const point = pointer(event);
              // taken from https://github.com/d3/d3-zoom/blob/master/src/zoom.js
              const pinchDelta =
                -event.deltaY *
                (event.deltaMode === 1 ? 0.05 : event.deltaMode ? 1 : 0.002) *
                10;
              const scaleFactor = currentZoom * Math.pow(2, pinchDelta);
              d3Zoom.scaleTo(d3Selection, scaleFactor, point);

              return;
            }

            // increase scroll speed in firefox
            // firefox: deltaMode === 1; chrome: deltaMode === 0
            const deltaNormalize = event.deltaMode === 1 ? 20 : 1;
            const deltaX =
              panOnScrollMode === PanOnScrollMode.Vertical
                ? 0
                : event.deltaX * deltaNormalize;
            const deltaY =
              panOnScrollMode === PanOnScrollMode.Horizontal
                ? 0
                : event.deltaY * deltaNormalize;

            d3Zoom.translateBy(
              d3Selection,
              -(deltaX / currentZoom) * panOnScrollSpeed,
              -(deltaY / currentZoom) * panOnScrollSpeed
            );
          },
          { passive: false }
        );
      } else if (typeof d3ZoomHandler !== 'undefined') {
        d3Selection.on(
          'wheel.zoom',
          function (event, d) {
            event.preventDefault();
            d3ZoomHandler.call(this, event, d);
          },
          { passive: false }
        );
      }
    }
  }, [
    userSelectionActive,
    panOnScroll,
    panOnScrollMode,
    d3Selection,
    d3Zoom,
    d3ZoomHandler,
    zoomOnPinch,
    panOnScrollSpeed,
  ]);

  useEffect(() => {
    if (d3Zoom) {
      d3Zoom.on('start', (event: D3ZoomEvent<HTMLDivElement, any>) => {
        if (!event.sourceEvent) {
          return;
        }

        // we need to remember it here, because it's always 0 in the "zoom" event
        mouseButton.current = event.sourceEvent.button;

        isZoomingOrPanning.current = true;

        if (event.sourceEvent?.type === 'mousedown') {
          storeApi.setState({
            paneDragging: true,
          });
        }
      });
    }
  }, [d3Zoom]);

  useEffect(() => {
    if (d3Zoom) {
      if (userSelectionActive && !isZoomingOrPanning.current) {
        d3Zoom.on('zoom', null);
      } else if (!userSelectionActive) {
        d3Zoom.on('zoom', (event: D3ZoomEvent<HTMLDivElement, any>) => {
          storeApi.setState({
            transform: {
              x: event.transform.x,
              y: event.transform.y,
              zoom: event.transform.k,
            },
          });
        });
      }
    }
  }, [userSelectionActive, d3Zoom]);

  useEffect(() => {
    if (d3Zoom) {
      d3Zoom.on('end', (event: D3ZoomEvent<HTMLDivElement, any>) => {
        if (!event.sourceEvent) {
          return;
        }

        isZoomingOrPanning.current = false;
        storeApi.setState({
          paneDragging: false,
        });

        zoomedWithRightMouseButton.current = false;

        if (viewChanged(prevTransform.current, event.transform)) {
          prevTransform.current = eventToFlowTransform(event.transform);
        }
      });
    }
  }, [d3Zoom, panOnScroll]);

  useEffect(() => {
    if (d3Zoom) {
      d3Zoom.filter((event: MouseEvent) => {
        const isZoomKeyPressed = isZoomScrollEvent(event);
        const zoomScroll = isZoomKeyPressed || zoomOnScroll;
        const pinchZoom = zoomOnPinch && isZoomKeyPressed;

        // Disable moving the pane when node or text exist in the current position, only allow zoom functionality
        if (
          !zoomScroll &&
          (isNodeExistInPosition(event.clientX, event.clientY) ||
            isEditableTextNodeExistInPosition(event.clientX, event.clientY))
        ) {
          return false;
        }

        if (event.button === 1 && event.type === 'mousedown') {
          return true;
        }

        // if all interactions are disabled, we prevent all zoom events
        if (
          !panOnDrag &&
          !zoomScroll &&
          !panOnScroll &&
          !zoomOnDoubleClick &&
          !zoomOnPinch
        ) {
          return false;
        }

        // during a selection we prevent all other interactions
        if (userSelectionActive) {
          return false;
        }

        // if zoom on double click is disabled, we prevent the double click event
        if (!zoomOnDoubleClick && event.type === 'dblclick') {
          return false;
        }

        if (!zoomOnPinch && isZoomKeyPressed && event.type === 'wheel') {
          return false;
        }

        // when there is no scroll handling enabled, we prevent all wheel events
        if (
          !zoomScroll &&
          !panOnScroll &&
          !pinchZoom &&
          event.type === 'wheel'
        ) {
          return false;
        }

        // if the pane is not movable, we prevent dragging it with mousedown or touchstart
        if (
          !panOnDrag &&
          (event.type === 'mousedown' || event.type === 'touchstart')
        ) {
          return false;
        }

        // We only allow right clicks if pan on drag is set to right-click
        const buttonAllowed = !event.button || event.button <= 1;

        // default filter for d3-zoom
        return (!isZoomKeyPressed || event.type === 'wheel') && buttonAllowed;
      });
    }
  }, [
    d3Zoom,
    panOnDrag,
    panOnScroll,
    userSelectionActive,
    zoomOnDoubleClick,
    zoomOnPinch,
    zoomOnScroll,
  ]);

  useEffect(() => {
    storeApi.setState({
      containerPaneElement: zoomPane.current,
    });
  }, []);

  return (
    <S.CanvasContainerPaneWrapper
      id={CANJUX_CONTAINER_PANE}
      isDragging={paneDragging}
      onPointerMove={(event) => {
        if (!zoomPane.current) {
          logger.error('ContainerRef is not defined');
          return;
        }

        const { x, y } = getPositionFromMouseEvent(event);

        const relativeRect = zoomPane.current.getBoundingClientRect();

        const pos = getPositionOnCanjux(
          {
            x: relativeRect ? x - relativeRect.left : x,
            y: relativeRect ? y - relativeRect.top : y,
          },
          transform,
          false
        );

        updatePresence?.({
          cursor: {
            x: pos.x,
            y: pos.y,
          },
        });

        resetHoveredNodes();
      }}
      onPointerLeave={() => {
        updatePresence?.({ cursor: null });
      }}
      ref={zoomPane}
    >
      {children}
    </S.CanvasContainerPaneWrapper>
  );
};
