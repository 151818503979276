import { FC, PropsWithChildren } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { IconButton } from '@jux/ui/components';
import * as S from './Modal.style';

export const ModalWrapper: FC<PropsWithChildren<{ onClose: () => void }>> = ({
  onClose,
  children,
}) => (
  <S.Paper container>
    <MuiGrid
      sx={{
        position: 'absolute',
        top: '0',
        right: '0',
        padding: '10px',
      }}
    >
      <IconButton icon="CLOSE" variant="icon" onClick={onClose} />
    </MuiGrid>
    {children}
  </S.Paper>
);
