import {
  JuxStore,
  JuxStoreActionFn,
  TokenSetsActionsParams,
} from '@jux/canjux/core';
import { getCurrentTimestamp } from '@jux/design-tokens';
import cloneDeep from 'lodash/cloneDeep';
import { getDesignTokensParserContext } from '../../../store-utils/getDesignTokensParserContext';

const designTokensParser = getDesignTokensParserContext();

export const deleteTokenOrGroup: JuxStoreActionFn<
  TokenSetsActionsParams['deleteTokenOrGroup'],
  JuxStore
> = ({ path, state }) => {
  Object.values(state.tokenSets).forEach((tokenSet) => {
    tokenSet.value = designTokensParser
      .parse(cloneDeep(tokenSet.value))
      .deleteByPath(path)
      .getRawValueCopy();

    tokenSet.updatedAt = getCurrentTimestamp();
  });

  return state;
};
