import {
  IsEmail,
  IsNumber,
  IsString,
  MaxLength,
  MinLength,
  ValidateNested,
} from 'class-validator';
import { Organization } from '../prisma/accounts-service.prisma';

export class CreateUserFromInviteDto {
  @IsString()
  @MinLength(8)
  @MaxLength(40)
  password: string;
}

export class IInviteTokenPayload {
  @IsEmail()
  email: string;

  @ValidateNested()
  organization: Pick<Organization, 'id' | 'name'>;
}

export class IInviteUsersByEmails {
  @IsNumber()
  org_id: number;

  @IsEmail({}, { each: true })
  emails: string[];
}
