import { ValidateNested } from 'class-validator';
import type {
  DependencyTree,
  DrimzJSXElements,
  SourcesData,
} from '../common/component-metadata';
import type { ComponentChanges } from '../common/component-changes';

export class ComponentVersionMetadata {
  @ValidateNested()
  componentDrimzJSXElements: DrimzJSXElements;

  @ValidateNested()
  dependencyTree: DependencyTree;

  @ValidateNested()
  sourcesData: SourcesData;

  @ValidateNested()
  changes: ComponentChanges;

  @ValidateNested()
  unpublishedChanges: ComponentChanges;
}
