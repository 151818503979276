import { forwardRef } from 'react';
import {
  StyledTextField,
  StyledTextFieldProps,
} from '@jux/ui/components/tokens/token-drawer/base';
import {
  handleTokenNameInputKeyDown,
  handleTokenNameInputPaste,
} from '@jux/ui/utils/tokens/tokenNameFormat';

export const TokenNameField = forwardRef<
  HTMLInputElement,
  StyledTextFieldProps
>((props, ref) => (
  <StyledTextField
    inputProps={{
      placeholder: 'Token name',
      onKeyDown: handleTokenNameInputKeyDown,
      onPaste: handleTokenNameInputPaste,
    }}
    {...props}
    ref={ref}
  />
));
