import { FC } from 'react';
import {
  hasSelectedNodes as hasSelectedNodesSelector,
  selectedNodeComponentBooleanType,
  useCurrentCanvasName,
  useStore,
} from '@jux/canjux/core';
import { Typography } from '@jux/ui/components';
import * as UI from '@jux/ui-core';
import { useCreateComponent, useAddToLibrary } from '../hooks';

export const MiddleSlot: FC = () => {
  const hasSelectedNodes = useStore(hasSelectedNodesSelector);
  const canvasName = useCurrentCanvasName();
  const { isElement, isLibraryComponent, isLocalComponent, isDiv } = useStore(
    selectedNodeComponentBooleanType
  );
  const { handleCreateComponent } = useCreateComponent();
  const { handleAddToLibrary } = useAddToLibrary();

  if (hasSelectedNodes && !isLibraryComponent) {
    if (isElement && isDiv) {
      return (
        <UI.JuxButton
          onClick={handleCreateComponent}
          hierarchy="Primary"
          disabled={false}
          size="medium"
          label="Create Component"
        />
      );
    }

    if (isLocalComponent) {
      return (
        <UI.JuxButton
          onClick={handleAddToLibrary}
          hierarchy="Primary"
          disabled={false}
          size="medium"
          label="Add to Library"
        />
      );
    }
  }

  return <Typography variant="label-s-regular">{canvasName}</Typography>;
};
