import { useCallback, useMemo } from 'react';
import { useDDPModulesState } from '@jux/ui/components/editor/state';
import { useTrackEvents } from '@jux/ui/hooks';
import { ModulesKeysType } from '../DDP.interface';

export const useModuleOpened = (moduleKey: ModulesKeysType) => {
  const { ddpModules, updateModuleState } = useDDPModulesState();
  const { trackModuleToggleOpenEvent } = useTrackEvents();

  const toggleOpen = useCallback(() => {
    const { isOpened } = ddpModules[moduleKey];

    updateModuleState(moduleKey, {
      isOpened: !isOpened,
    });
    trackModuleToggleOpenEvent(moduleKey, !isOpened);
  }, [ddpModules, moduleKey, trackModuleToggleOpenEvent, updateModuleState]);

  return useMemo(
    () => ({
      ...ddpModules[moduleKey],
      toggleOpen,
    }),
    [ddpModules, moduleKey, toggleOpen]
  );
};
