import { useCallback } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import { useTokenSetState } from '@jux/ui/state';

export const useRenameGroup = () => {
  const { currentTokenSetId } = useTokenSetState();
  const {
    tokenSetsActions: { renameGroup: renameGroupAction },
  } = useStoreActions();

  const renameGroup = useCallback(
    ({
      newName,
      oldName,
      parentGroupPath,
    }: {
      newName: string;
      oldName: string;
      parentGroupPath: string;
    }): Promise<boolean> =>
      new Promise<boolean>((resolve) => {
        if (!currentTokenSetId) return resolve(false);

        renameGroupAction({
          newName,
          oldName,
          path: parentGroupPath,
        });
        resolve(true);
      }),
    [currentTokenSetId, renameGroupAction]
  );

  return {
    renameGroup,
  };
};
