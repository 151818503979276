import { GenIcon, IconBaseProps, IconTree } from 'react-icons/lib';

export const CustomIcon = ({
  attr,
  tag,
  child,
  ...props
}: IconTree & IconBaseProps) =>
  GenIcon({
    attr,
    tag,
    child,
  })(props);
