import { createSelector } from 'reselect';
import { CanjuxState, getRootNodeOfNode } from '@jux/canjux/core';

export const selectRootComponentIdByComponentId = createSelector(
  [(state: CanjuxState) => state.components],
  (components) => (id: string) => {
    const component = components[id];

    if (!component) return undefined;

    const rootComponent = getRootNodeOfNode({
      components,
      nodeId: component.id,
    });

    return rootComponent?.id;
  }
);
