import { useMemo } from 'react';
import { DesignTokenTypeEnum, SupportedTokenTypes } from '@jux/design-tokens';
import { useTokenSetState } from '@jux/ui/state';
import { CORE } from '@jux/types';
import { createPath } from '../utils/tokensPath';

const getDefaultGroupPathOptions = (): Record<
  SupportedTokenTypes,
  Array<string>
> => ({
  [DesignTokenTypeEnum.color]: [],
  [DesignTokenTypeEnum.dimension]: [],
  [DesignTokenTypeEnum.fontFamily]: [],
  [DesignTokenTypeEnum.typography]: [],
  [DesignTokenTypeEnum.border]: [],
});

export const useTokens = () => {
  const { tokenSetData, currentTokenType, isCoreTokenSet } = useTokenSetState();

  const tokenSet = isCoreTokenSet ? tokenSetData?.core : tokenSetData;

  const groupPathOptionsByType = useMemo(() => {
    const result = getDefaultGroupPathOptions();

    if (!tokenSet?.existingGroupsPaths) {
      return result;
    }

    Object.keys(result).forEach((typeName) => {
      result[typeName as SupportedTokenTypes] =
        tokenSet?.existingGroupsPaths.filter((path) =>
          path.startsWith(
            createPath([isCoreTokenSet ? CORE : undefined, typeName])
          )
        );
    });

    return result;
  }, [isCoreTokenSet, tokenSet?.existingGroupsPaths]);

  const existingCoreTokenPaths = useMemo(
    () => tokenSetData?.core?.existingTokenPaths || [],
    [tokenSetData?.core?.existingTokenPaths]
  );

  return useMemo(
    () => ({
      tokenSetDataByType: tokenSet?.groupsByType[currentTokenType],
      groupPathOptionsByType,
      existingTokenPaths: isCoreTokenSet
        ? existingCoreTokenPaths
        : tokenSet?.existingTokenPaths || [],
      existingCoreTokenPaths,

      valuesMap: tokenSetData?.valuesMap || {},
      aliasesMap: tokenSetData?.aliasesMap || {},
      tokenSetDesignTokens: tokenSetData?.value || {},
    }),
    [
      tokenSet?.groupsByType,
      tokenSet?.existingTokenPaths,
      currentTokenType,
      groupPathOptionsByType,
      isCoreTokenSet,
      existingCoreTokenPaths,
      tokenSetData?.valuesMap,
      tokenSetData?.aliasesMap,
      tokenSetData?.value,
    ]
  );
};
