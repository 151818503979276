import { useEffect } from 'react';
import type { MutableRefObject } from 'react';

import { useStoreApi } from './useStore';
import { getDimensions } from '../utils';

function useResizeHandler(
  rendererNode: MutableRefObject<HTMLDivElement | null>
): void {
  const store = useStoreApi();

  useEffect(() => {
    let resizeObserver: ResizeObserver;

    const updateDimensions = () => {
      if (!rendererNode.current) {
        return;
      }

      const size = getDimensions(rendererNode.current);

      if (size.height === 0 || size.width === 0) {
        throw new Error(
          'The canjux parent container needs a width and a height to render the canvas'
        );
      }

      store.setState({ width: size.width || 500, height: size.height || 500 });
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    if (rendererNode.current) {
      resizeObserver = new ResizeObserver(() => updateDimensions());
      resizeObserver.observe(rendererNode.current);
    }

    return () => {
      window.removeEventListener('resize', updateDimensions);

      if (resizeObserver && rendererNode.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(rendererNode.current);
      }
    };
  }, [rendererNode, store]);
}

export default useResizeHandler;
