import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { OpacityField } from './OpacityField';

export const Opacity: FC = () => {
  const { shouldRender } = useSubModule(DDPSubModulesKeys.opacity);

  if (!shouldRender) return null;
  return (
    <SubModule>
      <OpacityField />
    </SubModule>
  );
};
