import { useCallback } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import { useTokenSetState } from '@jux/ui/state';
import { replaceLastPathSegment } from '@jux/ui/utils/tokensPath';

export const useRenameToken = () => {
  const { currentTokenSetId } = useTokenSetState();
  const {
    tokenSetsActions: { renameToken: renameTokenAction },
  } = useStoreActions();

  const renameToken = useCallback(
    ({ newName, path }: { newName: string; path: string }): Promise<boolean> =>
      new Promise<boolean>((resolve) => {
        if (!currentTokenSetId) return resolve(false);

        const oldTokenPath = path;
        const newTokenPath = replaceLastPathSegment(oldTokenPath, newName);

        renameTokenAction({
          newName: newTokenPath,
          oldName: oldTokenPath,
        });

        resolve(true);
      }),
    [currentTokenSetId, renameTokenAction]
  );

  return { renameToken };
};
