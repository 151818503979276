import { z } from 'zod';
import { useFieldForm } from './useFieldForm';

// const emailValidationSchema = z.string().email().nonempty('Email is required');
const emailValidationSchema = z.string();

export const useInviteMembersForm = () => {
  const {
    registerField: registerEmailField,
    getValue: getEmailValue,
    setValue: setEmailValue,
    handleSubmit: handleSubmitEmail,
    discardChanges: discardEmailChanges,
  } = useFieldForm({
    fieldName: 'email',
    initialValue: '',
    fieldSchema: emailValidationSchema,
  });

  return {
    registerEmailField,
    discardEmailChanges,
    getEmailValue,
    setEmailValue,
    handleSubmitEmail,
  };
};
