import {
  canjuxElementSelector,
  getElementAbsolutePosition,
  getPositionOnCanjux,
  NodeDragItems,
  selectCurrentCanvas,
  storeApi,
} from '@jux/canjux/core';
import { XYPosition } from '@jux/data-entities';
import { getSnapAdjustedPositionIfEnabled } from './getSnapAdjustedPositionIfEnabled';

// looks for all selected nodes and created a NodeDragItem for each of them
export function getDragItems(mousePos: XYPosition): NodeDragItems {
  const draggedItems: NodeDragItems = {};

  const { transform, snapToGrid, snapGrid, selectedNodesStack, components } =
    storeApi.getState();

  const currentCanvas = selectCurrentCanvas(storeApi.getState());
  selectedNodesStack.forEach((nodeId) => {
    const nodeProperties = currentCanvas.nodes[nodeId]?.properties;

    if (!nodeProperties?.isDraggable) return;

    // Getting the absolute position of the node relative to the canvas
    const selector = canjuxElementSelector(nodeId);
    const nodeElement = document.querySelector(selector);

    if (!nodeElement) return;

    const draggedNodeStartPosition = getPositionOnCanjux(
      getElementAbsolutePosition(nodeElement as HTMLElement),
      transform,
      snapToGrid,
      snapGrid
    );

    const pointerDistanceFromNode = {
      x: mousePos.x - draggedNodeStartPosition.x,
      y: mousePos.y - draggedNodeStartPosition.y,
    };

    const snapAdjustedPosition = getSnapAdjustedPositionIfEnabled(
      draggedNodeStartPosition
    );

    draggedItems[nodeId] = {
      parentId: components[nodeId]?.parentId || undefined,
      startPosition: {
        ...draggedNodeStartPosition,
        xSnapped: snapAdjustedPosition.x,
        ySnapped: snapAdjustedPosition.y,
      },
      /**
       * Represent the distance between the mouse an the current absolute position of the node on the canvas
       * Preserving the distance between the mouse and the node that is being dragged, so whenever the mouse moves, the node in its original position will move along it.
       * */
      distance: pointerDistanceFromNode,
    };
  });

  return draggedItems;
}
