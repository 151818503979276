import { ReactNode } from 'react';

export enum ModalStates {
  opened = 'opened',
  closed = 'closed',
}

export type ModalState = keyof typeof ModalStates;

export enum ModalActions {
  open = 'open',
  close = 'close',
}

export type ModalHandlersRecord =
  | Record<string, (...args: any[]) => void>
  | undefined;

type DefaultModalHandlers = {
  onClose: () => void;
};

type OptionalModalHandlers = undefined;

export type ModalHandlers<T extends ModalHandlersRecord = undefined> =
  T extends OptionalModalHandlers
    ? DefaultModalHandlers
    : T & DefaultModalHandlers;

export type ModalAction = keyof typeof ModalActions;

export type ModalContent = ReactNode;

export type UpdateModalArgs<MH extends ModalHandlersRecord = undefined> = {
  action: ModalAction;
  content?: ModalContent;
  handlers?: ModalHandlers<MH>;
};
