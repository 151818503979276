import { FC } from 'react';
import {
  CardContentValue,
  CardContentValueWrapper,
  FontFamilyTokenProps,
} from './base';

export const FontFamilyToken: FC<FontFamilyTokenProps> = ({
  value,
  aliasProps: { isAlias, aliasName },
}) => (
  <CardContentValueWrapper>
    <CardContentValue value={value} aliasName={aliasName} isAlias={isAlias} />
  </CardContentValueWrapper>
);
