import { useCallback, useEffect, useMemo, useState } from 'react';
import { ToggleSwitchHook } from './ToggleSwitch.interface';

export const useToggleSwitch: ToggleSwitchHook = ({
  onToggleClick,
  checked,
  disabled,
} = {}) => {
  const [internalState, setInternalState] = useState(checked || false);

  const isControlled = checked !== undefined;

  const handleToggle = useCallback(() => {
    if (disabled) return;

    setInternalState((prevState) => !prevState);
  }, [disabled]);

  useEffect(() => {
    onToggleClick?.(Boolean(internalState));
  }, [internalState, onToggleClick]);

  return useMemo(
    () => ({
      handleToggle,
      checked: isControlled ? checked : internalState,
      disabled: disabled || false,
    }),
    [handleToggle, isControlled, checked, internalState, disabled]
  );
};
