import { FC } from 'react';
import { capitalize } from 'lodash';
import { SelectField } from '@jux/ui/components';
import { MuiMenuItem } from '@jux/ui/components/common/mui';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { InteractiveStateProps } from './InteractiveState.interface';

export const InteractiveState: FC<InteractiveStateProps> = ({
  nodeInteractiveState,
  handleNodeInteractiveStateChange,
  options,
  forceRender,
}) => {
  const { shouldRender } = useSubModule(DDPSubModulesKeys.nodeInteractiveState);

  if (!shouldRender && !forceRender) return null;

  return (
    <SubModule title="Interactive state" renderInline>
      <SelectField
        name="nodeState"
        type="text"
        value={nodeInteractiveState}
        onChange={handleNodeInteractiveStateChange}
      >
        {options.map(
          (option) =>
            option && (
              <MuiMenuItem key={option} value={option}>
                {capitalize(option)}
              </MuiMenuItem>
            )
        )}
      </SelectField>
    </SubModule>
  );
};
