import { FC, useCallback } from 'react';
import {
  selectedNodeContextParentDisplayName,
  selectedNodeHasContextParent,
  useStore,
} from '@jux/canjux/core';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { useSubModule } from '@jux/ui/components/editor/components/panels/DDP/hooks';
import {
  useNodeParentContextState,
  useSelectedNodeId,
} from '@jux/ui/components/editor/hooks';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import {
  EDITOR_DDP_PARENT_CONTEXT_BACKGROUND_COLOR,
  EDITOR_DDP_PARENT_CONTEXT_BORDER_COLOR,
} from '@jux/ui/theme/palette';
import { ParentContextHeader } from './ParentContextHeader';
import { ParentContextContent } from './ParentContextContent';

export const ParentContext: FC = () => {
  const { shouldRender } = useSubModule(DDPSubModulesKeys.parentContext);

  const selectedNodeId = useSelectedNodeId();

  const { isNodeParentContextOn: isOn, setNodeParentContextOn } =
    useNodeParentContextState(selectedNodeId);

  const selectedNodeHasNodeContext = useStore(selectedNodeHasContextParent);
  const selectedNodeContextDisplayName = useStore(
    selectedNodeContextParentDisplayName
  );

  const handleToggleClick = useCallback(
    (checked: boolean) => {
      setNodeParentContextOn(checked);
    },
    [setNodeParentContextOn]
  );

  if (!shouldRender || !selectedNodeId || !selectedNodeHasNodeContext) {
    return null;
  }

  return (
    <MuiGrid
      container
      flexDirection="column"
      gap="4px"
      sx={{
        backgroundColor: EDITOR_DDP_PARENT_CONTEXT_BACKGROUND_COLOR,
        borderColor: EDITOR_DDP_PARENT_CONTEXT_BORDER_COLOR,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: (theme) => theme.drimz.size.borderRadius.main,
        padding: '0 12px',
        ...(isOn && { paddingBottom: '12px' }),
      }}
    >
      <ParentContextHeader
        title={`Parent Context: ‘${selectedNodeContextDisplayName}‘`}
        checked={isOn}
        onToggleClick={handleToggleClick}
      />
      {isOn && <ParentContextContent />}
    </MuiGrid>
  );
};
