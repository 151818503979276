import { atom } from 'jotai';
import { DropPositionType } from '../types';
import { ReorderItem } from './ReorderItem.interface';

type LayersPanelStateAtom = {
  expandedPaths: Set<string>;
  shouldShowGlobalExpanders: boolean;
  editingPath?: string;
  reorderDraggedItem?: ReorderItem;
  reorderHoveredItem?: ReorderItem;
  reorderHoveredItemDropZonePosition?: DropPositionType;
};

export const layersPanelStateAtom = atom<LayersPanelStateAtom>({
  expandedPaths: new Set<string>(),
  shouldShowGlobalExpanders: false,
  editingPath: undefined,
});
