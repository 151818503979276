import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { FC, useMemo } from 'react';
import { Mount } from './Mount';
import { MountComponentOnIframeBodyProps } from './Iframe.interface';
import { useSetIframeBodyStyle } from './useSetIframeBodyStyle';

export const MountComponentOnIframeBody: FC<
  MountComponentOnIframeBodyProps
> = ({ context, render }) => {
  const iframeBodyElement = useMemo(
    () => context?.document.body,
    [context?.document.body]
  );

  const iframeHeadElement = useMemo(
    () => context?.document.head,
    [context?.document.head]
  );

  useSetIframeBodyStyle(iframeBodyElement);

  if (!context || !iframeBodyElement || !iframeHeadElement) {
    return null;
  }

  const cache = createCache({
    // !!! IT SHOULD BE DIFFERENT KEY FROM 'css' in order to be rendered properly !!!
    key: 'iframe',
    container: iframeHeadElement,
  });

  return (
    <Mount
      component={
        /**
         * creating a wrapper around the iframe's body content to allow styled components to work.
         * Styled components creates custom css classes that should be injected into the iframe's head and not to the main document's head.
         * In order to achieve this, the use of the CacheProvider is required.
         */
        <CacheProvider value={cache}>{render(context)}</CacheProvider>
      }
      at={iframeBodyElement}
    />
  );
};
