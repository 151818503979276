/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const DotsThreeVerticalIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="4"
    height="20"
    attr={{
      viewBox: '0 0 4 20',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M2 11.5C2.82843 11.5 3.5 10.8284 3.5 10C3.5 9.17157 2.82843 8.5 2 8.5C1.17157 8.5 0.5 9.17157 0.5 10C0.5 10.8284 1.17157 11.5 2 11.5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M2 3.5C2.82843 3.5 3.5 2.82843 3.5 2C3.5 1.17157 2.82843 0.5 2 0.5C1.17157 0.5 0.5 1.17157 0.5 2C0.5 2.82843 1.17157 3.5 2 3.5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M2 19.5C2.82843 19.5 3.5 18.8284 3.5 18C3.5 17.1716 2.82843 16.5 2 16.5C1.17157 16.5 0.5 17.1716 0.5 18C0.5 18.8284 1.17157 19.5 2 19.5Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
