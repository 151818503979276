import {
  CustomColorChangeHandler,
  IconsVariantsType,
} from '@jux/ui/components/common';
import { PopupStateType } from '@jux/ui/components/common/mui';
import { UseDDPFieldFormRegisterReturn } from '../../../hooks';
import { BaseFieldProps } from '../common';
import { InputFieldProps } from '../input';

export const DDP_FIELD_NAME_PREFIX = 'ddp-color-field-';
export const DDP_FIELD_CLASS_NAME = 'ddp-color-field';

export type ColorFieldDDPIcon = Extract<IconsVariantsType, 'DDP_FIELD_COLOR'>;

export type ColorFieldValue = {
  rgbaColor: string; // rgba(0,0,0,1)
  hexValue: string; // #000000 - #FFFFFF
  alpha: number; // 0-100
};

export type ColorFieldProps = BaseFieldProps & {
  icon: ColorFieldDDPIcon;
  parsedValue?: ColorFieldValue;
  popupState: PopupStateType;
  setValueByRGBA: CustomColorChangeHandler;
  hexValueInputRegister: () => UseDDPFieldFormRegisterReturn;
  alphaValueInputRegister: () => UseDDPFieldFormRegisterReturn;
  hexInputProps: Omit<InputFieldProps, 'value'>;
  alphaInputProps: Omit<InputFieldProps, 'value'>;
  hasMultipleValues?: boolean;
};

export type ColorFieldContentProps = Pick<
  ColorFieldProps,
  | 'tokensProps'
  | 'isComputed'
  | 'parsedValue'
  | 'popupState'
  | 'hexValueInputRegister'
  | 'alphaValueInputRegister'
  | 'hexInputProps'
  | 'alphaInputProps'
  | 'setValueByRGBA'
>;
