import { CSSProperties } from 'react';

export type SupportedFlexDirectionValues = Extract<
  CSSProperties['flexDirection'],
  'column-reverse' | 'row-reverse' | 'column' | 'row'
>;

export const FlexDirectionValue: {
  columnReverse: Extract<SupportedFlexDirectionValues, 'column-reverse'>;
  rowReverse: Extract<SupportedFlexDirectionValues, 'row-reverse'>;
  column: Extract<SupportedFlexDirectionValues, 'column'>;
  row: Extract<SupportedFlexDirectionValues, 'row'>;
} = {
  columnReverse: 'column-reverse',
  rowReverse: 'row-reverse',
  column: 'column',
  row: 'row',
} as const;
