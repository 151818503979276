import { FC } from 'react';
import { DimensionField } from '../../../../base';
import { useGapField } from './useGapField';

export const GapRowField: FC = () => {
  const { rowGapProps } = useGapField();

  return (
    <DimensionField
      icon="DDP_FIELD_ROW_GAP"
      tooltipTitle="Rows gap"
      {...rowGapProps}
    />
  );
};
