import { FC } from 'react';
import { ImportTokensButton } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { EDITOR_DDP_FIELD_TOKEN_SELECTION_ACTIONS_BORDER_COLOR } from '@jux/ui/theme/palette';
import { TokenCreationAction } from '../tokenCreation/TokenCreationAction';

export const TokenSelectionActions: FC<{
  hasNoTokens: boolean;
  handleTokenCreationAction: () => void;
  isValidTokenType: boolean;
}> = ({ hasNoTokens, isValidTokenType, handleTokenCreationAction }) => {
  const shouldRenderImportTokensButton = hasNoTokens && isValidTokenType;

  return (
    <MuiGrid
      item
      container
      sx={{
        padding: hasNoTokens ? 0 : '3px 3px 4px 3px',
        borderTop: hasNoTokens ? 0 : 1,
        borderColor: EDITOR_DDP_FIELD_TOKEN_SELECTION_ACTIONS_BORDER_COLOR,
        justifyContent: 'space-between',
        gap: '8px',
      }}
    >
      {shouldRenderImportTokensButton && (
        <MuiGrid item flex={1}>
          <ImportTokensButton label="Import" />
        </MuiGrid>
      )}

      <MuiGrid item flex={1}>
        <TokenCreationAction
          onClick={handleTokenCreationAction}
          shouldRenderImportTokensButton={shouldRenderImportTokensButton}
          hasNoTokens={hasNoTokens}
        />
      </MuiGrid>
    </MuiGrid>
  );
};
