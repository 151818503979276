import {
  DesignTokensParser,
  getDefaultStructuredTokenSet,
} from '@jux/design-tokens';
import { merge } from 'lodash';

export const getDesignTokensParserContext = () =>
  new DesignTokensParser(
    {},
    {
      onChange: (parser) =>
        parser.parse(
          merge(getDefaultStructuredTokenSet(), parser.getRawValueCopy())
        ),
    }
  );
