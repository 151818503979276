import { useRef } from 'react';
import { useHover } from 'usehooks-ts';

export const useComponentPreviewActions = () => {
  const actionsMenuRef = useRef<HTMLDivElement>(null);
  const isActionsMenuHovered = useHover(actionsMenuRef);

  return {
    actionsMenuRef,
    isActionsMenuHovered,
  };
};
