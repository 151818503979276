import { FC } from 'react';
import { Icons, IconsVariantsType, Typography } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';

export const TokenDrawerIconLabel: FC<{
  iconVariant: IconsVariantsType;
  labelText: string;
}> = ({ iconVariant, labelText }) => (
  <MuiGrid
    justifyContent="flex-start"
    alignItems="center"
    sx={(theme) => ({
      ...theme.drimz.size.layout.tokenDrawer.field,
      color: theme.drimz.palette.text.primary,
    })}
  >
    <Icons
      variant={iconVariant}
      sx={{
        color: (theme) => theme.drimz.palette.primary.main,
        mr: '6px',
      }}
    />
    <Typography variant="label-s-regular">{labelText}</Typography>
  </MuiGrid>
);
