import { CanjuxState } from '@jux/canjux/core';

export const getCurrentCanvasData = ({
  canvases,
  currentCanvasName,
}: {
  canvases: CanjuxState['canvases'];
  currentCanvasName: string;
}) => {
  return canvases[currentCanvasName];
};
