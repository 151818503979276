import type { Draft as WritableDraft } from 'mutative';
import { JuxStore } from '@jux/canjux/core';
import { findInstancesOfInstances } from './findInstancesOfInstances';

const recursiveDeleteInstanceNodes = ({
  componentId,
  deleteChildren = true,
  components,
  canvases,
}: {
  componentId: string;
  deleteChildren?: boolean;
  components: WritableDraft<JuxStore['components']>;
  canvases: WritableDraft<JuxStore['canvases']>;
}) => {
  const parentId = components[componentId]?.parentId;
  const parentComponent = parentId ? components[parentId] : null;

  // Remove component instance from parent
  if (parentComponent) {
    parentComponent.children = parentComponent.children.filter(
      (childId) => childId !== componentId
    );
  }

  if (deleteChildren) {
    // go over all children and delete them too
    components[componentId].children.forEach((childId) => {
      recursiveDeleteInstanceNodes({
        componentId: childId,
        components,
        canvases,
        deleteChildren,
      });
    });
  }

  // After detached from parent compnoent we can delete the component and all of its canvas appearances
  for (const canvas of Object.values(canvases)) {
    if (componentId in canvas.nodes) {
      delete canvas.nodes[componentId];
    }
  }

  const instancesOfInstance = Object.values(components)
    .filter((component) => component?.sourceComponentId === componentId)
    .map((component) => component.id);
  for (const instanceOfInstance of instancesOfInstance) {
    recursiveDeleteInstanceNodes({
      componentId: instanceOfInstance,
      components,
      canvases,
      deleteChildren,
    });
  }

  // after recursivley deleting all instances of instances we can delete this component
  delete components[componentId];
};

export const deleteInstanceNodes = ({
  sourceNodeId,
  components,
  canvases,
}: {
  sourceNodeId: string;
  components: WritableDraft<JuxStore['components']>;
  canvases: WritableDraft<JuxStore['canvases']>;
}) => {
  const componentInstances = findInstancesOfInstances({
    sourceNodeId: sourceNodeId,
    components,
  }).reverse(); // return in order of distant children first (e.g 3'd level instances, 2'd level instances, 1'st level instances)

  for (const instance of componentInstances) {
    recursiveDeleteInstanceNodes({
      componentId: instance.id,
      components,
      canvases,
      deleteChildren: true,
    });
  }
};
