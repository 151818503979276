import { FC, useMemo } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { IconButton } from '@jux/ui/components';
import { CHIP_INPUT_BORDER_COLOR } from '@jux/ui/theme/palette';
import { textValidators } from '@jux/ui/utils/textValidators';
import { Chip } from './Chip';
import { ChipsInputProps } from './ChipsInput.interface';

export const addChipClassName = 'add-chip-chips-input';

export const ChipsInput: FC<ChipsInputProps> = ({
  values,
  onAdd,
  onDelete,
  onEditChipValue,
  disableEditing,
  maxLength,
  hideAddWithoutHover,
}) => {
  const valueNames = useMemo(
    () => values.map((value) => value.label),
    [values]
  );

  const customValidators = useMemo(
    () => [
      textValidators.nameAlreadyExistsValidator({
        namesArray: valueNames,
        errorMessages: {
          nameAlreadyExists: 'Value name already exists',
          namesArrayUndefined: 'Value is undefined',
        },
      }),
    ],
    [valueNames]
  );

  return (
    <MuiGrid
      container
      item
      sx={{
        alignItems: 'center',
        gap: '8px',
        border: '1px solid',
        padding: '8px',
        borderColor: CHIP_INPUT_BORDER_COLOR,
      }}
    >
      {valueNames.map((value) => (
        <Chip
          key={value}
          value={value}
          maxLength={maxLength}
          customValidators={customValidators}
          disableEditing={disableEditing}
          onDelete={() => onDelete(value)}
          onSaveChanges={(newValue) =>
            onEditChipValue({
              value,
              newValue,
            })
          }
        />
      ))}
      {!disableEditing && (
        <IconButton
          className={addChipClassName}
          variant="icon"
          icon="ADD"
          onClick={onAdd}
          sx={{
            display: hideAddWithoutHover ? 'none' : 'flex',
          }}
        />
      )}
    </MuiGrid>
  );
};
