import {
  Direction,
  Theme,
  createMuiTheme,
  responsiveFontSizes,
  MuiThemeOptions,
} from '@jux/ui/components/common/mui';
import { baseThemeOptions } from './base';
import { darkThemeOptions } from './dark';
import { lightThemeOptions } from './light';

export const createTheme = (config: {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  mode: 'light' | 'dark';
  themeOptions?: MuiThemeOptions;
}): Theme => {
  let theme = createMuiTheme(
    { ...baseThemeOptions, ...config.themeOptions },
    config.mode === 'dark' ? darkThemeOptions : lightThemeOptions,
    {
      direction: config.direction,
    }
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
