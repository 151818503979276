import { useMemo } from 'react';
import type { StoreApi } from 'zustand';
import { CanjuxState, storeApi } from '../crdt';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

type ExtractState<StoreState> = StoreApi<StoreState> extends {
  getState: () => infer T;
}
  ? T
  : never;

// Store API is used when we don't want to subscribe to the store.
// It is used in case we're not interested in re-rendering when the store changes, or when we want to access
// the store's functions.
export const useStoreApi = () => {
  return useMemo(
    () => ({
      getState: storeApi.getState,
      setState: storeApi.setState,
      subscribe: storeApi.subscribe,
      destroy: storeApi.destroy,
    }),
    []
  );
};

// Store is used when we want to subscribe to the store and re-render when the store changes
export function useStore<StateSlice = ExtractState<CanjuxState>>(
  selector: (state: CanjuxState) => StateSlice,
  equalityFn?: (a: StateSlice, b: StateSlice) => boolean
) {
  return useStoreWithEqualityFn(storeApi, selector, equalityFn || shallow);
}
