import { FC } from 'react';
import { Icons, Typography } from '@jux/ui/components/common';
import { MuiGrid, styled } from '@jux/ui/components/common/mui';

const ContentBody = styled(MuiGrid)(() => ({
  width: '85px',
  position: 'relative',

  ':after': {
    content: '"-"',
    position: 'absolute',
    right: 0,
  },
}));

export const ColorMixedFieldContent: FC = () => (
  <MuiGrid item display="flex" alignItems="center" gap="8px">
    <MuiGrid item>
      <Icons variant="DDP_FIELD_TEXT_MIXED_COLOR" />
    </MuiGrid>
    <ContentBody item>
      <Typography variant="label-s-regular">Mixed</Typography>
    </ContentBody>
  </MuiGrid>
);
