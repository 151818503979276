import { useCallback } from 'react';
import {
  selectExistingAssetNames,
  useStore,
  useStoreActions,
} from '@jux/canjux/core';
import { generateName } from '@jux/ui/utils/generateName';
import { convertAssetNameToComponentName } from '@jux/ui/utils/assets';
import { useTrackEvents } from '../useTrackEvents';
import { initAssetData } from './assets.utils';

/**
 * Handles the state of assets the user is uploading
 */
export const useUploadAssets = () => {
  const {
    assetsActions: { createAsset },
  } = useStoreActions();
  const existingAssetNames = useStore(selectExistingAssetNames);
  const { trackCreateAssetEvent } = useTrackEvents();

  const uploadAssets = useCallback(
    async (assetFiles: FileList) => {
      const assets = await Promise.all(
        Array.from(assetFiles).map(async (file) => {
          let assetName = convertAssetNameToComponentName(file.name);

          assetName = generateName({
            baseName: assetName,
            namesArray: existingAssetNames,
            separator: ' ',
          });

          existingAssetNames.push(assetName);

          return {
            content: await file.text(),
            name: assetName,
            type: file.type,
            data: {},
          };
        })
      );

      assets.forEach((asset) => {
        const { assetData } = initAssetData({
          assetName: asset.name,
          assetContent: asset.content,
        });

        createAsset({ assetData });
        trackCreateAssetEvent({ assetName: asset.name });
      });
    },
    [createAsset, existingAssetNames, trackCreateAssetEvent]
  );

  return {
    uploadAssets,
  };
};
