/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const ArrowPointsUpIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    attr={{
      viewBox: '0 0 7 4.21',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'm3.5,0l3.35,3.35c.2.2.2.51,0,.71s-.51.2-.71,0L3.5,1.41.85,4.06c-.2.2-.51.2-.71,0s-.2-.51,0-.71L3.5,0Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
