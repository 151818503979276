import type { Draft as WritableDraft } from 'mutative';
import { ContextStyle, StyleVariant } from '@jux/types';
import { JuxStore } from '@jux/canjux/core';

export const updateStylesOnRenameProp = ({
  variantsStyles,
  propName,
  newPropName,
}: {
  variantsStyles: WritableDraft<StyleVariant>[];
  propName: string;
  newPropName: string;
}) => {
  for (const variant of variantsStyles) {
    if (propName in variant.propsValues) {
      variant.propsValues[newPropName] = variant.propsValues[propName];
      delete variant.propsValues[propName];
    }
  }
};

export const updateContextStylesOnRenameProp = ({
  contextStyles,
  propName,
  newPropName,
}: {
  contextStyles: WritableDraft<ContextStyle>[];
  propName: string;
  newPropName: string;
}) => {
  for (const contextStyle of contextStyles) {
    const conditionProps = contextStyle?.condition?.propsValues;
    if (conditionProps && propName in conditionProps) {
      // Delete old prop name and add new prop name
      conditionProps[newPropName] = conditionProps[propName];
      delete conditionProps[propName];
    }
  }
};

export const fixInstancesOnRenameProp = ({
  componentId,
  propName,
  newPropName,
  components,
}: {
  componentId: string;
  propName: string;
  newPropName: string;
  components: WritableDraft<JuxStore['components']>;
}) => {
  const instances = Object.values(components).filter(
    (component) => component?.sourceComponentId === componentId
  );

  for (const instance of instances) {
    if (propName in instance.config.props) {
      instance.config.props[newPropName] = instance.config.props[propName];
      delete instance.config.props[propName];
    }
  }
};
