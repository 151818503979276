import {
  ComponentTagName,
  AssetData,
  ComponentSourceData,
  ComponentProps,
} from '@jux/data-entities';

export const CANVAS_ITEMS = {
  Component: 'Component',
  Element: 'Element',
  Asset: 'Asset',
} as const;

export type CanvasComponentItem = {
  type: typeof CANVAS_ITEMS.Component;
  id: string;
  data: ComponentSourceData;
  displayName?: string;
};

export type CanvasAssetItem = {
  type: typeof CANVAS_ITEMS.Asset;
  assetId: string;
  assetData: AssetData;
};

type CanvasItems = {
  [CANVAS_ITEMS.Component]: CanvasComponentItem;
  [CANVAS_ITEMS.Element]: {
    type: typeof CANVAS_ITEMS.Element;
    tagName: ComponentTagName;
  };
  [CANVAS_ITEMS.Asset]: CanvasAssetItem;
};

export type CanvasItemType = keyof CanvasItems;
export type CanvasItemValue<
  Type extends CanvasItemType = CanvasItemType,
  TagName extends ComponentTagName = ComponentTagName
> = Type extends typeof CANVAS_ITEMS.Element
  ? CanvasItems[Type] & {
      tagName: TagName;
      // adding the props to initiate a default element
      props?: ComponentProps<TagName>;
    }
  : CanvasItems[Type];

export const isCanvasItemComponent = (
  item: CanvasItemValue
): item is CanvasItemValue<typeof CANVAS_ITEMS.Component> =>
  'type' in item && item.type === CANVAS_ITEMS.Component;

export const isCanvasItemAsset = (
  item: CanvasItemValue
): item is CanvasItemValue<typeof CANVAS_ITEMS.Asset> =>
  'type' in item && item.type === CANVAS_ITEMS.Asset;
