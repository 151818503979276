/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const AddIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M7 8.75C7.13807 8.75 7.25 8.86193 7.25 9V12.5C7.25 12.7761 7.47386 13 7.75 13H8.25C8.52614 13 8.75 12.7761 8.75 12.5V9C8.75 8.86193 8.86193 8.75 9 8.75H12.5C12.7761 8.75 13 8.52614 13 8.25V7.75C13 7.47386 12.7761 7.25 12.5 7.25H9C8.86193 7.25 8.75 7.13807 8.75 7V3.5C8.75 3.22386 8.52614 3 8.25 3H7.75C7.47386 3 7.25 3.22386 7.25 3.5V7C7.25 7.13807 7.13807 7.25 7 7.25H3.5C3.22386 7.25 3 7.47386 3 7.75V8.25C3 8.52614 3.22386 8.75 3.5 8.75H7Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
