import { useEffect } from 'react';
import { useStore } from '@jux/canjux/core';

export const useEffectOnPlaceholderModeChange = (callback: () => void) => {
  const placeholderMode = useStore((s) => s.placeholderMode);

  useEffect(() => {
    callback();
  }, [callback, placeholderMode]);
};
