/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const ResetViewIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="15"
    height="14"
    attr={{
      viewBox: '0 0 15 14',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M2.75 1.9375C2.42208 1.9375 2.15625 2.18934 2.15625 2.5V11.5C2.15625 11.8107 2.42208 12.0625 2.75 12.0625H12.25C12.5779 12.0625 12.8438 11.8107 12.8438 11.5V2.5C12.8438 2.18934 12.5779 1.9375 12.25 1.9375H2.75ZM1.5625 0.25C0.906662 0.25 0.375 0.75368 0.375 1.375V12.625C0.375 13.2463 0.906662 13.75 1.5625 13.75H13.4375C14.0933 13.75 14.625 13.2463 14.625 12.625V1.375C14.625 0.75368 14.0933 0.25 13.4375 0.25H1.5625Z',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
