import { useCallback, useMemo } from 'react';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import { useFieldValues, useSetMultiFieldsValues } from '../../../../../hooks';
import { DisplayValue } from '../../display/DisplayField.interface';
import { SupportedFlexWrapValues } from './FlexWrapField.interface';
import {
  isFlexWrap,
  parseFlexWrap,
  toggleFlexWrap,
} from './FlexWrapField.utils';

export const useFlexWrapField = () => {
  const { value, initialValue } = useFieldValues(
    DDPPrimitiveFieldsKeys.flexWrap
  );
  const { setMultiFieldsValues } = useSetMultiFieldsValues();

  const parsedFlexWrap = useMemo(
    () => parseFlexWrap(value ?? initialValue),
    [initialValue, value]
  );

  const isFlexWrapSelected = useMemo(
    () => isFlexWrap(parsedFlexWrap),
    [parsedFlexWrap]
  );

  const saveChanges = useCallback(
    (newFlexWrapValue: SupportedFlexWrapValues | undefined) => {
      setMultiFieldsValues({
        [DDPPrimitiveFieldsKeys.flexWrap]: newFlexWrapValue,
        [DDPPrimitiveFieldsKeys.display]: DisplayValue.flex,
      });
    },
    [setMultiFieldsValues]
  );

  const handleFlexWrapClick = useCallback(() => {
    const toggledFlexWrap = toggleFlexWrap(parsedFlexWrap);

    saveChanges(toggledFlexWrap);
  }, [parsedFlexWrap, saveChanges]);

  return {
    handleFlexWrapClick,
    isFlexWrapSelected,
  };
};
