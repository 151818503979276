import { MuiGrid, styled } from '@jux/ui/components/common/mui';
import {
  CHIP_INPUT_CHIP_BACKGROUND_COLOR,
  CHIP_INPUT_CHIP_BACKGROUND_COLOR_HOVER,
} from '@jux/ui/theme/palette';

export const Chip = styled(MuiGrid, {
  shouldForwardProp: (prop) => prop !== 'disableEditing',
})<{
  disableEditing?: boolean;
}>(({ theme, disableEditing }) => ({
  display: 'flex',
  width: 'auto',
  maxWidth: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '4px',
  padding: disableEditing ? '4px 6px' : '4px 4px 4px 6px',
  backgroundColor: CHIP_INPUT_CHIP_BACKGROUND_COLOR,
  borderRadius: theme.drimz.size.borderRadius.main,
  transition: theme.drimz.transitions.all,

  '&:hover': {
    backgroundColor: CHIP_INPUT_CHIP_BACKGROUND_COLOR_HOVER,
  },
}));
