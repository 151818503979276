/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const TextColorDDPIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M7.00685 3.13053C5.82221 4.60043 4 7.30021 4 10C4 12.2091 5.79086 14 8 14C10.2091 14 12 12.2091 12 10C12 7.30021 10.1778 4.60043 8.99315 3.13054C8.42264 2.42264 8 2 8 2C8 2 7.57736 2.42264 7.00685 3.13053ZM8 12.5C9.38071 12.5 10.5 11.3807 10.5 10C10.5 8.45087 9.70212 6.75734 8.75192 5.33205C8.49512 4.94684 8.23762 4.59663 8 4.29215C7.76238 4.59663 7.50488 4.94684 7.24808 5.33205C6.29788 6.75734 5.5 8.45087 5.5 10C5.5 11.3807 6.61929 12.5 8 12.5Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
