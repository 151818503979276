import { FC, PropsWithChildren, useState } from 'react';
import { Resizable } from 're-resizable';
import { Panel } from '@jux/canjux/core';
import { useTheme } from '@jux/ui/components/common/mui';
import { ResizablePanelProps } from './ResizablePanel.interface';

export const ResizablePanel: FC<PropsWithChildren<ResizablePanelProps>> = ({
  width,
  setWidth,
  minWidth,
  maxWidth,
  height,
  children,
  position,
  enableResizeFrom,
  defaultBorderColor: defaultBorderColorProp,
  activeBorderColor: activeBorderColorProp,
  fullHeight,
}) => {
  const [innerWidth, setInnerWidth] = useState(width);
  const theme = useTheme();

  const defaultBorderColor =
    defaultBorderColorProp ?? theme.drimz.palette.divider.primary;
  const activeBorderColor =
    activeBorderColorProp ?? theme.drimz.palette.divider.hover;

  const [borderColor, setBorderColor] = useState(defaultBorderColor);

  return (
    <Panel position={position} fullHeight={fullHeight}>
      <Resizable
        defaultSize={{
          height: height ?? '100%',
          width: innerWidth,
        }}
        size={{
          height: height ?? '100%',
          width: innerWidth,
        }}
        maxWidth={maxWidth}
        minWidth={minWidth}
        onResize={(e, direction, ref, d) => {
          setInnerWidth(width + d.width);
        }}
        onResizeStart={() => {
          setBorderColor(activeBorderColor);
        }}
        onResizeStop={() => {
          setBorderColor(defaultBorderColor);
          setWidth(innerWidth);
        }}
        enable={{
          top: false,
          bottom: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
          ...enableResizeFrom,
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
          justifyContent: 'stretch',
          alignItems: 'stretch',

          ...(enableResizeFrom.left && {
            borderLeft: `1px solid ${borderColor}`,
          }),
          ...(enableResizeFrom.right && {
            borderRight: `1px solid ${borderColor}`,
          }),
        }}
      >
        {children}
      </Resizable>
    </Panel>
  );
};
