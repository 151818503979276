import { forwardRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Error } from '@jux/ui/components/errors/Error';
import { MountComponentOnIframeBody } from './MountComponentOnIframeBody';
import { IframeProps } from './Iframe.interface';
import * as S from './Iframe.style';
import { useIframe } from './useIframe';

const INITIAL_HTML = '<!DOCTYPE html/>';

export const Iframe = forwardRef<HTMLIFrameElement, IframeProps>(
  (
    { iframeKey, title, messageOnError: errorMessage, styles, scripts, render },
    ref
  ) => {
    const { iframeContext, setupIframeHTML } = useIframe({ styles, scripts });

    return (
      <ErrorBoundary
        fallback={
          <Error error={errorMessage || 'Failed to render component'} />
        }
      >
        <S.Iframe
          ref={ref}
          key={iframeKey}
          title={title}
          srcDoc={INITIAL_HTML}
          onLoad={setupIframeHTML}
        >
          <MountComponentOnIframeBody context={iframeContext} render={render} />
        </S.Iframe>
      </ErrorBoundary>
    );
  }
);
