/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const JustifyContentCenterRowDDPIcon: IconType = (
  props: IconBaseProps
) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M7.25 2.75C7.25 2.33579 7.58579 2 8 2C8.41421 2 8.75 2.33579 8.75 2.75V13.25C8.75 13.6642 8.41421 14 8 14C7.58579 14 7.25 13.6642 7.25 13.25V2.75Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M12 10C12 10.5523 11.5523 11 11 11C10.4477 11 10 10.5523 10 10L10 6C10 5.44772 10.4477 5 11 5C11.5523 5 12 5.44772 12 6L12 10Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M5 11C5.55229 11 6 10.5523 6 10L6 6C6 5.44772 5.55228 5 5 5C4.44771 5 4 5.44772 4 6V10C4 10.5523 4.44772 11 5 11Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
