/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '../core/CustomIcon';

export const HiddenLayerIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M12.4339 3.38409C12.2386 3.18882 11.922 3.18883 11.7268 3.38409L3.20206 11.9088C3.00679 12.1041 3.00679 12.4206 3.20206 12.6159C3.39732 12.8112 3.7139 12.8112 3.90916 12.6159L12.4339 4.09119C12.6291 3.89593 12.6291 3.57935 12.4339 3.38409Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M8 3.99999C8.66104 3.99999 9.26745 4.10923 9.81925 4.29161L8.57299 5.53786C8.38778 5.51311 8.1968 5.49999 8 5.49999C6.53814 5.49999 5.39717 6.22414 4.56066 7.06065C4.23206 7.38924 3.96649 7.72037 3.76622 7.99999C3.96649 8.27961 4.23206 8.61073 4.56066 8.93932C4.66126 9.03992 4.76625 9.13889 4.87568 9.23517L3.81335 10.2975C2.60445 9.20889 2 7.99999 2 7.99999C2 7.99999 4 3.99999 8 3.99999Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M5.91259 11.6125C6.5343 11.8518 7.23011 12 8 12C12 12 14 7.99999 14 7.99999C14 7.99999 13.3307 6.6614 11.9921 5.53294L10.9269 6.59815C11.1089 6.74539 11.2797 6.90097 11.4393 7.06065C11.7679 7.38924 12.0335 7.72037 12.2338 7.99999C12.0335 8.27961 11.7679 8.61073 11.4393 8.93932C10.6028 9.77583 9.46186 10.5 8 10.5C7.69116 10.5 7.39663 10.4677 7.11628 10.4088L5.91259 11.6125Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
