import {
  CommonActionsParams,
  JuxStore,
  JuxStoreActionFn,
} from '@jux/canjux/core';
import { createCanvasData } from '../helpers/createCanvasData';
import { getValidCanvasName } from './utils';

/**
 * Create a new canvas
 */
export const createCanvas: JuxStoreActionFn<
  CommonActionsParams['createCanvas'],
  JuxStore
> = ({ name, state }) => {
  const canvasName = getValidCanvasName(name);
  state.canvases[canvasName] = createCanvasData(canvasName);

  return state;
};
