import { FC, useCallback, useMemo } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import {
  DesignTokensParser,
  DesignTokenTypeEnum,
  STROKE_STYLE_OPTIONS,
} from '@jux/design-tokens';
import { CORE } from '@jux/types';
import {
  FieldContainer,
  StyledSelectField,
  StyledTextField,
  TITLE_CREATE_NEW_TOKEN,
  TITLE_EDIT_TOKEN,
  TokenDrawerFormLayout,
  TokenDrawerIconLabel,
  TokenNameField,
} from '@jux/ui/components/tokens/token-drawer/base';
import {
  ALIAS_PLACEHOLDER_TEXT,
  BORDER_PATH_PREFIX,
  formatAliasPath,
  formatGroupPath,
  GetValueFn,
  parseBorderTokenValue,
  STROKE_STYLE_PLACEHOLDER_TEXT,
  useFormValueAlias,
  useInitialValues,
  useRegisterFieldFns,
  useValidateTokenName,
} from '@jux/ui/components/tokens/token-drawer/forms/helpers';
import { BorderTokenFormProps } from '@jux/ui/components/tokens/token-drawer/forms/types';
import { useTokenDrawer } from '@jux/ui/components/tokens/token-drawer/useTokenDrawer';
import { getUpdatedTokenData } from '@jux/ui/components/tokens/token-drawer/utils/getUpdatedTokenData';
import { useZodForm } from '@jux/ui/hooks/useZodForm';
import { borderTokenInputSchema } from '@jux/ui/trpc/validations';
import { createPath } from '@jux/ui/utils/tokensPath';

export const BorderTokenForm: FC<BorderTokenFormProps> = ({
  initialValues,
  groupPathOptions,
  existingTokenPaths = [],
  existingCoreTokenPaths = [],
  isCoreTokenSet,
  tokens,
  valuesMap,
}) => {
  const {
    tokenSetsActions: { setToken },
  } = useStoreActions();

  const defaultValues = {
    ...initialValues,
    value: parseBorderTokenValue(initialValues.value),
  };

  const form = useZodForm({
    schema: borderTokenInputSchema,
    defaultValues,
  });

  const [
    registerTokenNameField,
    registerGroupPathField,
    registerAliasField,
    registerColorField,
    registerWidthField,
    registerStyleField,
    registerDescriptionField,
  ] = useRegisterFieldFns(form, [
    'tokenName',
    'groupPath',
    'alias',
    'value.color',
    'value.width',
    'value.style',
    'description',
  ]);

  // using controlled fields to support aliases.
  // each field has a default/initial value, but if the user has changed the value,
  // we want to use the value they have changed it to, rather than the default value.
  const [colorValue, widthValue, styleValue] = useInitialValues(
    ['value.color', 'value.width', 'value.style'],
    {
      form,
      initialValues: defaultValues,
    }
  );

  const getValue: GetValueFn = useCallback(
    (valuePath) =>
      parseBorderTokenValue(
        (tokens
          ? new DesignTokensParser(tokens).getTokenRawValue(valuePath, true)
          : valuesMap[
              valuePath
            ]) as BorderTokenFormProps['initialValues']['value']
      ),
    [tokens, valuesMap]
  );

  const existingBorderTokens = useMemo(() => {
    if (isCoreTokenSet) {
      return existingCoreTokenPaths.filter((p) =>
        p.startsWith(createPath([CORE, BORDER_PATH_PREFIX]))
      );
    }

    return existingTokenPaths.filter((p) => p.startsWith(BORDER_PATH_PREFIX));
  }, [existingCoreTokenPaths, existingTokenPaths, isCoreTokenSet]);

  const existingCoreBorderTokens = useMemo(
    () =>
      existingCoreTokenPaths.filter((p) =>
        p.startsWith(createPath([CORE, BORDER_PATH_PREFIX]))
      ),
    [existingCoreTokenPaths]
  );

  const { aliasOptionsProps, hasAliases, aliasValue, isAliasToken } =
    useFormValueAlias<typeof form, typeof borderTokenInputSchema>({
      form,
      getValue,
      existingTokenPaths: existingBorderTokens,
      existingCoreTokenPaths: existingCoreBorderTokens,
      isCoreTokenSet,
    });

  const { handlers } = useTokenDrawer();

  const validateTokenName = useValidateTokenName({
    form,
    existingTokenPaths: existingBorderTokens,
  });

  const handleSubmit = useMemo(
    () =>
      form.handleSubmit((values) => {
        if (validateTokenName()) {
          setToken({
            data: {
              ...values,
              alias: formatAliasPath(values.alias),
              isCore: isCoreTokenSet,
              value: {
                ...values.value,
              },
            },
            type: DesignTokenTypeEnum.border,
          });

          handlers.onSave(
            getUpdatedTokenData({
              tokenPath: createPath([values.groupPath, values.tokenName]),
              tokenSetId: values.tokenSetId,
            })
          );
        }
      }, validateTokenName),
    [form, validateTokenName, setToken, isCoreTokenSet, handlers]
  );

  // TODO: remove this once we get the token color picker from DDP
  const colorOptions: string[] = ['rgba(243 5 95 / 100%)'];

  return (
    <TokenDrawerFormLayout
      title={
        initialValues.tokenName ? TITLE_EDIT_TOKEN : TITLE_CREATE_NEW_TOKEN
      }
      onSubmit={handleSubmit}
    >
      <TokenDrawerIconLabel iconVariant="BORDER" labelText="Border" />
      <FieldContainer error={form.formState.errors.tokenName?.message}>
        <TokenNameField
          error={Boolean(form.formState.errors.tokenName)}
          inputRef={registerTokenNameField().ref}
          {...registerTokenNameField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.groupPath?.message}>
        <StyledSelectField
          placeholder="Folder"
          defaultValue={initialValues.groupPath}
          renderValue={(item) => formatGroupPath(item, isCoreTokenSet)}
          error={Boolean(form.formState.errors.groupPath)}
          inputRef={registerGroupPathField().ref}
          options={groupPathOptions}
          disableMenuPortal
          {...registerGroupPathField()}
        />
      </FieldContainer>
      <FieldContainer>
        <StyledSelectField
          disabled={!hasAliases}
          placeholder={ALIAS_PLACEHOLDER_TEXT}
          value={aliasValue}
          renderValue={(item) => formatGroupPath(item, isCoreTokenSet)}
          error={Boolean(form.formState.errors.alias)}
          inputRef={registerAliasField().ref}
          disableMenuPortal
          {...aliasOptionsProps}
          {...registerAliasField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.value?.color?.message}>
        <StyledSelectField
          disabled={isAliasToken}
          placeholder="Color"
          value={colorValue}
          error={Boolean(form.formState.errors.value?.color)}
          options={colorOptions}
          inputRef={registerColorField().ref}
          disableMenuPortal
          {...registerColorField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.value?.width?.message}>
        <StyledTextField
          disabled={isAliasToken}
          placeholder="Width"
          value={widthValue}
          error={Boolean(form.formState.errors.value?.width)}
          inputRef={registerWidthField().ref}
          {...registerWidthField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.value?.style?.message}>
        <StyledSelectField
          disabled={isAliasToken}
          placeholder={STROKE_STYLE_PLACEHOLDER_TEXT}
          value={styleValue}
          inputProps={{
            placeholder: 'Stroke Style',
          }}
          error={Boolean(form.formState.errors.value?.style)}
          inputRef={registerStyleField().ref}
          options={STROKE_STYLE_OPTIONS}
          disableMenuPortal
          {...registerStyleField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.description?.message}>
        <StyledTextField
          inputProps={{
            placeholder: 'Token description',
          }}
          error={Boolean(form.formState.errors.description)}
          inputRef={registerDescriptionField().ref}
          {...registerDescriptionField()}
        />
      </FieldContainer>
    </TokenDrawerFormLayout>
  );
};
