import { FC, PropsWithChildren, useState } from 'react';
import { Resizable } from 're-resizable';
import { Panel } from '../Panel';
import { ResizablePanelProps } from './ResizablePanel.interface';

// TODO: resize doesn't work properly yet
export const ResizablePanel: FC<PropsWithChildren<ResizablePanelProps>> = ({
  initialWidth,
  minWidth,
  maxWidth,
  height,
  children,
  position,
  enableResizeFrom,
  defaultBorderColor,
  activeBorderColor,
}) => {
  const [width, setDesignInterfaceWidth] = useState<number>(initialWidth);
  const [borderColor, setBorderColor] = useState(defaultBorderColor);

  return (
    <Panel
      position={position}
      style={{
        width,
        height,
        minWidth,
        maxWidth,
      }}
    >
      <Resizable
        defaultSize={{
          height: height || '100%',
          width,
        }}
        size={{
          height: height || '100%',
          width,
        }}
        maxWidth={maxWidth}
        minWidth={minWidth}
        onResize={(_e, _direction, _ref, d) => {
          setDesignInterfaceWidth(width + d.width);
        }}
        onResizeStart={() => {
          setBorderColor(activeBorderColor);
        }}
        onResizeStop={() => {
          setBorderColor(defaultBorderColor);
        }}
        enable={{
          top: false,
          bottom: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
          ...enableResizeFrom,
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
          justifyContent: 'stretch',
          alignItems: 'stretch',

          ...(enableResizeFrom.left && {
            borderLeft: `1px solid ${borderColor}`,
          }),
          ...(enableResizeFrom.right && {
            borderRight: `1px solid ${borderColor}`,
          }),
        }}
      >
        {children}
      </Resizable>
    </Panel>
  );
};
