/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const PaddingLeftDDPIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M7 5.25C7.41421 5.25 7.75 5.58579 7.75 6L7.75 10C7.75 10.4142 7.41421 10.75 7 10.75C6.58579 10.75 6.25 10.4142 6.25 10V8.75H3.5L3.5 12.5H12.5V3.5L3.5 3.5L3.5 7.25H6.25V6C6.25 5.58579 6.58579 5.25 7 5.25ZM2 13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V3C14 2.44772 13.5523 2 13 2L3 2C2.44772 2 2 2.44772 2 3L2 13Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
