import type { Draft as WritableDraft } from 'mutative';
import { ContextStyle, EnumPropValue, StyleVariant } from '@jux/types';

export const updateStylesOnDeletePropValue = ({
  variantsStyles,
  propName,
  propValueToDelete,
}: {
  variantsStyles: WritableDraft<StyleVariant>[];
  propName: string;
  propValueToDelete: EnumPropValue;
}) => {
  return variantsStyles.filter((variantStyles) =>
    // keep variants that don't have this prop in their condition
    // or variants that have it but with a different value
    propName in variantStyles.propsValues
      ? variantStyles.propsValues[propName] !== propValueToDelete
      : true
  );
};

export const updateContextStylesOnDeletePropValue = ({
  contextStyles,
  propName,
  propValueToDelete,
}: {
  contextStyles: WritableDraft<ContextStyle>[];
  propName: string;
  propValueToDelete: EnumPropValue;
}) => {
  return contextStyles.filter((contextStyle) => {
    const conditionProps = contextStyle?.condition?.propsValues;
    // keep variants that don't have this prop in their condition
    // or variants that have it but with a different value
    return conditionProps && propName in conditionProps
      ? conditionProps[propName] !== propValueToDelete
      : true;
  });
};
