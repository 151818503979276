import { useCallback } from 'react';
import { DesignTokenData } from '@jux/design-tokens';
import { useTrackEvents } from '@jux/ui/hooks';
import {
  parseSelectedTokenToValidTokenName,
  pickValueAsStringFromToken,
} from './useSelectedToken.utils';
import { useSelectedTokenState } from './useSelectedTokenState';

export const useSelectedToken = ({
  setFieldValue,
  setStateByTokenValue,
  fieldName,
  closeTokensPopper,
}: {
  setStateByTokenValue: (newToken: DesignTokenData) => void;
  setFieldValue: (value: string | undefined) => void;
  fieldName: string;
  closeTokensPopper: () => void;
}) => {
  const { selectedToken, setSelectedToken } = useSelectedTokenState();

  const { trackTokenDetachedEvent } = useTrackEvents();

  const resetSelectedToken = useCallback(
    () => setSelectedToken(undefined),
    [setSelectedToken]
  );

  const selectToken = useCallback(
    (token: DesignTokenData) => {
      setSelectedToken(token);
      setStateByTokenValue(token);
    },
    [setSelectedToken, setStateByTokenValue]
  );

  const saveToken = useCallback(
    (token?: DesignTokenData) => {
      if (!token) {
        setFieldValue(undefined);
      } else {
        // save the token as an alias
        const parsedTokenName = parseSelectedTokenToValidTokenName(token);
        setFieldValue(parsedTokenName);
      }
    },
    [setFieldValue]
  );

  const saveTokenValue = useCallback(
    (token: DesignTokenData) => {
      const parsedTokenValue = pickValueAsStringFromToken(token);
      setFieldValue(parsedTokenValue);
    },
    [setFieldValue]
  );

  const handleTokenSelection = useCallback(
    (token: DesignTokenData) => {
      selectToken(token);
      saveToken(token);
    },
    [saveToken, selectToken]
  );

  const handleTokenDetach = useCallback(() => {
    if (!selectedToken) return;

    resetSelectedToken();
    saveTokenValue(selectedToken);

    trackTokenDetachedEvent(fieldName);
  }, [
    fieldName,
    resetSelectedToken,
    saveTokenValue,
    selectedToken,
    trackTokenDetachedEvent,
  ]);

  const handleCompositeTokenDetach = useCallback(() => {
    resetSelectedToken();
    saveToken(undefined);
    trackTokenDetachedEvent(fieldName);
  }, [fieldName, resetSelectedToken, saveToken, trackTokenDetachedEvent]);

  const handleCreateNewTokenSaved = useCallback(
    async (token: DesignTokenData) => {
      saveToken(token);
      selectToken(token);
      closeTokensPopper();
    },
    [saveToken, selectToken, closeTokensPopper]
  );

  return {
    selectedToken,
    setSelectedToken,
    handleTokenSelection,
    handleTokenDetach,
    handleCompositeTokenDetach,
    handleCreateNewTokenSaved,
  };
};
