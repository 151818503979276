import { FC } from 'react';
import { Tooltip, TooltipProps } from '@jux/ui/components/common';
import { formatTimeDistance } from '@jux/ui/utils/formatTimeDistance';

export const ComponentPreviewItemTooltip: FC<
  Omit<TooltipProps, 'content'> & {
    displayName: string;
    updatedAt?: number;
  }
> = ({ displayName, updatedAt, ...props }) => (
  <Tooltip
    data-id="component-preview-item-tooltip"
    header={displayName}
    content={updatedAt ? `Updated ${formatTimeDistance(updatedAt)}` : ''}
    {...props}
  />
);
