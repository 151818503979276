import { ComponentProps } from 'react';
import { Typography } from '@jux/ui/components';
import { styled } from '@jux/ui/components/common/mui';

export const InnerSubModuleHeader = styled(
  (props: Partial<ComponentProps<typeof Typography>>) => (
    <Typography
      color={(theme) => theme.drimz.palette.text.secondary}
      variant="label-s-medium"
      mt="8px"
      {...props}
    />
  )
)(() => ({}));
