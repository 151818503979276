import { useCallback } from 'react';
import { PoppersKey, usePopper } from '@jux/ui/components';
import { Floors } from '@jux/ui/hooks';
import {
  CanvasActionPopperMenu,
  CanvasPopperMenuActions,
} from './CanvasActionMenu';

export const useOpenCanvasItemActionsPopper = () => {
  const { open, close } = usePopper(PoppersKey.canvasActions);

  const withClose = useCallback(
    (callback: () => void) => {
      callback();
      close();
    },
    [close]
  );

  const openCanvasItemActionsPopper = useCallback(
    ({
      sourceElement,
      id,
      onDelete,
      onOpenNewTab,
      onRename,
    }: {
      sourceElement: HTMLElement;
      id: string;
    } & CanvasPopperMenuActions) => {
      open({
        innerKey: id,
        content: (
          <CanvasActionPopperMenu
            onDelete={() => withClose(onDelete)}
            onOpenNewTab={() => withClose(onOpenNewTab)}
            onRename={() => withClose(onRename)}
          />
        ),
        sourceElement,
        styles: {
          direction: 'ltr',
          positioning: 'bottom-left',
          offset: {
            left: 0,
          },
          floor: Floors.sidebarPopper,
        },
      });
    },
    [open, withClose]
  );

  return {
    openCanvasItemActionsPopper,
  };
};
