import {
  ComponentElementType,
  ComponentProps,
  ComponentTagName,
} from '@jux/data-entities';
import { forwardRef } from 'react';
import { Button } from './Button';
import { Checkbox, Label } from '@jux/primitives';
import { Chip } from './Chip';
import { Div } from './Div';
import { Divider } from './Divider';
import { IconButton } from './IconButton';
import { Input } from './Input';
import { Radio } from './Radio';
import { Svg } from './Svg';
import { Text } from './Text';
import { Textarea } from './TextArea';
import { Toggle } from './Toggle';

export * from './Button';
export * from './Chip';
export * from './Div';
export * from './Divider';
export * from './IconButton';
export * from './Input';
export * from './Radio';
export * from './Svg';
export * from './Text';
export * from './TextArea';
export * from './Toggle';

export type JuxComponentElement<T extends ComponentTagName = ComponentTagName> =
  ReturnType<typeof forwardRef<ComponentElementType<T>, ComponentProps<T>>>;

export const JuxReactElements: {
  [tagName in ComponentTagName]: JuxComponentElement<tagName>;
} = {
  JuxButton: Button,
  JuxCheckboxField: Div,
  JuxCheckbox: Checkbox.Root as JuxComponentElement<'JuxCheckbox'>,
  JuxLabelTrigger: Label.Root,
  JuxChip: Chip,
  JuxDiv: Div,
  JuxDivider: Divider,
  JuxIconButton: IconButton,
  JuxInput: Input,
  JuxTextField: Div,
  JuxRadio: Radio,
  JuxSvg: Svg,
  JuxText: Text,
  JuxTextarea: Textarea,
  JuxTextareaField: Div,
  JuxToggle: Toggle,
};

export const getJuxComponentElement = (tagName: ComponentTagName) =>
  JuxReactElements[tagName] as JuxComponentElement<typeof tagName>;
