import { useCallback, useMemo } from 'react';
import {
  selectCanvasesWithComponent,
  selectNodeInstances,
  useStore,
  useStoreActions,
} from '@jux/canjux/core';

export const useDeleteComponent = (componentId: string) => {
  const componentInstances = useStore(selectNodeInstances(componentId));
  const canvasesWithNode = useStore(selectCanvasesWithComponent(componentId));

  const {
    componentActions: { deleteComponent },
  } = useStoreActions();

  const isDeletable = useMemo(
    () => componentInstances.length === 0 && canvasesWithNode.length === 0,
    [canvasesWithNode.length, componentInstances.length]
  );

  const handleComponentDelete = useCallback(() => {
    if (!isDeletable) return;
    deleteComponent({ componentId });
  }, [componentId, deleteComponent, isDeletable]);

  return {
    isDeletable,
    handleComponentDelete,
  };
};
