import { TRPCClientError, TRPCLink } from '@trpc/client';
import { observable } from '@trpc/server/observable';
import { AppRouter } from '@jux/ui/trpc/server/api/routers/root';

export const errorLink =
  (onError: (e: TRPCClientError<AppRouter>) => void): TRPCLink<AppRouter> =>
  () =>
  ({ next, op }) =>
    observable((observer) =>
      next(op).subscribe({
        next(value) {
          observer.next(value);
        },
        error(err) {
          observer.error(err);
          onError(err);
        },
        complete() {
          observer.complete();
        },
      })
    );
