import { CSSProperties } from 'react';
import { IconBaseProps as ReactIconBaseProps } from 'react-icons/lib';
import { IconProps, SystemProps, Theme } from '@jux/ui/components/common/mui';
import { IconsVariants } from './IconsVariants';

export type IconsVariantsType = keyof typeof IconsVariants;

export type ExportedIconBaseProps = {
  textColor?: CSSProperties['color'];
  backgroundColor?: CSSProperties['backgroundColor'];
};

export type IconBaseProps = ReactIconBaseProps & ExportedIconBaseProps;

export type IconsProps = {
  variant: IconsVariantsType;
  size?: number;
  iconBaseProps?: ExportedIconBaseProps;
  width?: string | number;
  height?: string | number;
  color?: SystemProps<Theme>['color'];
} & Omit<IconProps, 'color' | 'size' | 'fontSize'>;

export const IconsSupportedComponentTagName = {
  JuxDiv: 'JuxDiv',
  JuxSvg: 'JuxSvg',
  JuxText: 'JuxText',
} as const;

export const isSupportedComponentTagName = (
  tagName: string
): tagName is keyof typeof IconsSupportedComponentTagName =>
  Object.keys(IconsSupportedComponentTagName).includes(tagName);

export type IconsSupportedComponentTagNameType =
  keyof typeof IconsSupportedComponentTagName;

export const ICONS_VARIANT_BY_TAG_NAME: Record<
  IconsSupportedComponentTagNameType,
  IconsVariantsType
> = {
  JuxDiv: 'DIV_ELEMENT',
  JuxSvg: 'SVG_ELEMENT',
  JuxText: 'TEXT_ELEMENT',
} as const;
