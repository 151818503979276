export const selectAllText = (element: HTMLElement) => {
  const selection = window.getSelection();
  const range = document.createRange();

  range.selectNodeContents(element);

  selection?.removeAllRanges();
  selection?.addRange(range);
};

export const deselectAllText = () => {
  const selection = window.getSelection();

  selection?.removeAllRanges();
};
