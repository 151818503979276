import Head from 'next/head';
import { FC } from 'react';
import { withImportant } from '@jux/ui/utils/css';

export const BackgroundHead: FC<{
  backgroundColor: CSSStyleDeclaration['backgroundColor'];
}> = ({ backgroundColor }) => (
  <Head>
    <style>
      {`
        body {
          background-color: ${withImportant(backgroundColor)};
        }
      `}
    </style>
  </Head>
);
