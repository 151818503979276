import { MutableRefObject, useEffect, useState } from 'react';

// focus on mount if autoFocus is true
export const useAutofocus = ({
  ref,
  shouldAutoFocus,
}: {
  ref: MutableRefObject<HTMLElement | null>;
  shouldAutoFocus?: boolean;
}) => {
  const [autofocus, setAutofocus] = useState(Boolean(shouldAutoFocus));

  useEffect(() => {
    if (ref.current && autofocus) {
      ref.current.focus();
      setAutofocus(false);
    }
  }, [autofocus, ref]);
};
