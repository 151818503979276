import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { GapRowField } from './GapRowField';
import { GapColumnField } from './GapColumnField';

export const Gap: FC = () => {
  const { shouldRender } = useSubModule(DDPSubModulesKeys.gap);

  if (!shouldRender) return null;
  return (
    <SubModule title="Gap">
      <GapRowField />
      <GapColumnField />
    </SubModule>
  );
};
