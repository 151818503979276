import { createSelector } from 'reselect';
import {
  CanjuxState,
  getContextParentByNodeId,
  JuxStore,
} from '@jux/canjux/core';
import { ComponentPropValue, INHERITED_FROM_PARENT } from '@jux/data-entities';
import { ComponentTagNames } from '@jux/data-entities';

const getInheritedPropValueFromParent = ({
  propName,
  id,
  components,
}: {
  propName: string;
  id: string;
  components: JuxStore['components'];
}): ComponentPropValue => {
  const parentContextNodeId = getContextParentByNodeId({ components, id });
  if (!parentContextNodeId) {
    return undefined;
  }

  const parentContextComponent = components[parentContextNodeId];
  if (
    !parentContextComponent?.config.props ||
    !(propName in parentContextComponent.config.props)
  ) {
    return undefined;
  }

  return parentContextComponent.config.props[propName];
};

// TODO: remove this export
export const resolvedProps = ({
  components,
  assets,
  id,
  onlyVariantsProps = false,
}: {
  components: JuxStore['components'];
  assets: JuxStore['assets'];
  id: string;
  onlyVariantsProps?: boolean;
}): Record<string, ComponentPropValue> => {
  const component = components[id];
  if (!component) {
    return {};
  }

  const propsSources = [component.config.props];
  let currentAncestor = component;
  while (currentAncestor?.sourceComponentId) {
    if (currentAncestor?.tagName === ComponentTagNames.JuxSvg) {
      const svgContent = assets[currentAncestor.sourceComponentId]?.content;

      if (svgContent) {
        propsSources.push({ content: svgContent });
        break;
      }
    }

    currentAncestor = components[currentAncestor?.sourceComponentId];
    if (currentAncestor) {
      propsSources.push(currentAncestor.config.props);
    }
  }
  propsSources.reverse();

  const mergedProps = propsSources.reduce(
    (acc, props) => ({ ...acc, ...props }),
    {}
  );

  // get parent context props
  Object.entries(mergedProps).forEach(([propName, propValue]) => {
    if (propValue === INHERITED_FROM_PARENT) {
      // Replace with actual parent context prop value
      mergedProps[propName] = getInheritedPropValueFromParent({
        propName,
        id,
        components,
      });
    }
  });

  if (!onlyVariantsProps) {
    return mergedProps;
  }

  const variantsConfig = currentAncestor?.config.variants;
  if (!variantsConfig || variantsConfig.length === 0) {
    return {};
  }

  const filteredPropsValues = Object.keys(mergedProps)
    .filter((propName) =>
      variantsConfig.find((variant) => variant.variant === propName)
    )
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: mergedProps[key],
      };
    }, {});
  return filteredPropsValues;
};

/*
   Selects the resolved component props for a given component ID.
 */
export const selectResolvedComponentProps = ({
  id,
  onlyVariantsProps,
}: {
  id: string;
  onlyVariantsProps?: boolean;
}) =>
  createSelector(
    [
      (state: CanjuxState) => state.components,
      (state: CanjuxState) => state.assets,
    ],
    (components, assets): Record<string, any> =>
      resolvedProps({ assets, id, components, onlyVariantsProps })
  );
