import { FC } from 'react';
import { Tab, Tabs } from '@jux/ui/components';

export const AssetsHeader: FC<{
  isSystemAssets: boolean;
  setSystemAssets: () => void;
  isUserAssets: boolean;
  setUserAssets: () => void;
}> = ({ setSystemAssets, setUserAssets, isSystemAssets, isUserAssets }) => (
  <Tabs>
    <Tab
      title="Element assets"
      onClick={setSystemAssets}
      selected={isSystemAssets}
    />
    <Tab title="Your assets" onClick={setUserAssets} selected={isUserAssets} />
  </Tabs>
);
