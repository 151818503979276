import { useCallback } from 'react';
import {
  selectedNodeComponentInstancesCount,
  useStore,
  selectedNodeComponent,
  useStoreActions,
} from '@jux/canjux/core';
import { NodeType } from '@jux/data-entities';
import { useTrackEvents } from '@jux/ui/hooks';
import { useSelectedNodeId } from '@jux/ui/components/editor/hooks';
import { useDeletePropConfirmationModal } from './useDeletePropConfirmationModal';

export const useHandleDeleteProp = () => {
  const {
    propsActions: { deleteComponentProp },
  } = useStoreActions();

  const selectedComponentInstancesCount = useStore(
    selectedNodeComponentInstancesCount
  );
  const selectedNodeData = useStore(selectedNodeComponent);
  const selectedNodeId = useSelectedNodeId();
  const { openDeletePropConfirmationModal } = useDeletePropConfirmationModal();

  const { trackPropDeletedEvent } = useTrackEvents();

  const deleteProp = useCallback(
    (propName: string) => {
      if (
        !selectedNodeId ||
        !selectedNodeData ||
        (selectedNodeData.type !== NodeType.LIBRARY_COMPONENT &&
          selectedNodeData.type !== NodeType.LOCAL_COMPONENT)
      )
        return;

      deleteComponentProp({ componentId: selectedNodeId, propName });

      trackPropDeletedEvent({
        componentUUID: selectedNodeData.id,
        tagName: selectedNodeData.tagName,
        deletedPropName: propName,
      });
    },
    [
      deleteComponentProp,
      selectedNodeData,
      selectedNodeId,
      trackPropDeletedEvent,
    ]
  );

  const handleDeleteProp = useCallback(
    ({
      defaultValue,
      propName,
    }: {
      propName: string;
      defaultValue: string;
    }) => {
      if (selectedComponentInstancesCount === 0) {
        deleteProp(propName);
      } else {
        openDeletePropConfirmationModal({
          defaultValue,
          propName,
          onConfirm: () => {
            deleteProp(propName);
          },
        });
      }
    },
    [
      deleteProp,
      openDeletePropConfirmationModal,
      selectedComponentInstancesCount,
    ]
  );

  return {
    deleteProp,
    handleDeleteProp,
  };
};
