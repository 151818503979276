import { useCallback } from 'react';
import {
  DDPSubModulesKeys,
  DDPSubModulesState,
  useDDPSubModulesState,
} from '@jux/ui/components/editor/state';
import { SupportedStyles } from '@jux/ui/components/editor/utils';
import { useIsPlaceholderInputMode } from '../components/panels/DDP/hooks';
import {
  isOpenPrevValueOrDefault,
  isStyleExistInStyles,
} from './useSetupShouldRenderSubModule.utils';

export const useSetupShouldRenderSubModule = () => {
  const { setSubModulesState } = useDDPSubModulesState();
  const isInputPlaceholderMode = useIsPlaceholderInputMode();

  const setupShouldRenderSubModulesState = useCallback(
    (styles: SupportedStyles, initialStyles: SupportedStyles) => {
      const isDisplayFlex = isStyleExistInStyles(styles, initialStyles, {
        display: 'flex',
      });
      // we don't want to select the initial styles for the position check
      const isPositionRelative = isStyleExistInStyles(styles, undefined, {
        position: 'relative',
      });
      const isPositionAbsolute = isStyleExistInStyles(styles, undefined, {
        position: 'absolute',
      });

      // rerun this function when styles changed
      setSubModulesState(
        (prev): DDPSubModulesState => ({
          // Background
          backgroundColor: {
            shouldRender: true,
          },
          // Border
          borderColor: {
            shouldRender: !isInputPlaceholderMode,
          },
          borderRadius: {
            shouldRender: !isInputPlaceholderMode,
          },
          borderStyle: {
            shouldRender: !isInputPlaceholderMode,
          },
          borderWidth: {
            shouldRender: !isInputPlaceholderMode,
          },
          // Color
          color: {
            shouldRender: true,
          },
          // Context
          parentContext: {
            shouldRender: true,
          },
          // Effects
          boxShadow: {
            shouldRender: true,
          },
          // Layout
          padding: {
            shouldRender: !isInputPlaceholderMode,
            isOpen: isOpenPrevValueOrDefault(prev, DDPSubModulesKeys.padding),
          },
          margin: {
            shouldRender: !isInputPlaceholderMode,
            isOpen: isOpenPrevValueOrDefault(prev, DDPSubModulesKeys.margin),
          },
          display: {
            shouldRender: !isInputPlaceholderMode,
          },
          flexDirectionAndWrapping: {
            shouldRender: isDisplayFlex && !isInputPlaceholderMode,
          },
          alignItems: {
            shouldRender: isDisplayFlex && !isInputPlaceholderMode,
          },
          justifyContent: {
            shouldRender: isDisplayFlex && !isInputPlaceholderMode,
          },
          gap: {
            shouldRender: isDisplayFlex && !isInputPlaceholderMode,
          },
          // Opacity
          opacity: {
            shouldRender: true,
          },
          // Props
          assetContent: {
            shouldRender: true,
          },
          dynamicProperties: {
            shouldRender: true,
          },
          editProperties: {
            shouldRender: true,
          },
          nodeInteractiveState: {
            shouldRender: true,
          },
          textContent: {
            shouldRender: true,
          },
          // Typography
          textColor: {
            shouldRender: true,
          },
          alignment: {
            shouldRender: true,
          },
          typography: {
            shouldRender: true,
          },
          // Size
          height: {
            shouldRender: !isInputPlaceholderMode,
          },
          width: {
            shouldRender: !isInputPlaceholderMode,
          },
          position: {
            shouldRender: !isInputPlaceholderMode,
          },
          relativeTo: {
            shouldRender: isPositionAbsolute && !isInputPlaceholderMode,
          },
          constraints: {
            isOpen: isOpenPrevValueOrDefault(
              prev,
              DDPSubModulesKeys.constraints
            ),
            shouldRender:
              (isPositionRelative || isPositionAbsolute) &&
              !isInputPlaceholderMode,
          },
          zIndex: {
            shouldRender:
              (isPositionRelative || isPositionAbsolute) &&
              !isInputPlaceholderMode,
          },
        })
      );
    },
    [setSubModulesState, isInputPlaceholderMode]
  );

  return {
    setupShouldRenderSubModulesState,
  };
};
