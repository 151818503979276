import { CanjuxState } from '@jux/canjux/core';
import { createSelector } from 'reselect';
import {
  getCurrentCanvasData,
  getNodeById,
  getSourceComponentById,
} from './utils';

export const selectNodeSourceComponentOnCurrentCanvas = createSelector(
  [
    (state: CanjuxState) => state.components,
    (state: CanjuxState) => state.canvases,
    (state: CanjuxState) => state.currentCanvasName,
  ],
  (components, canvases, currentCanvasName) => (id: string) => {
    const { nodes } = getCurrentCanvasData({ canvases, currentCanvasName });

    const sourceComponent = getSourceComponentById({
      id,
      components,
    });

    const node = getNodeById({ nodes, id: sourceComponent?.id });

    if (!node || !sourceComponent) return undefined;

    return {
      ...node,
      id: sourceComponent.id,
    };
  }
);
