/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '../core/CustomIcon';

export const LogicalSlotLayerIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M12 4.5C12.2761 4.5 12.5 4.72386 12.5 5V11C12.5 11.2761 12.2761 11.5 12 11.5H11V13H12C13.1046 13 14 12.1046 14 11V5C14 3.89543 13.1046 3 12 3H11V4.5H12Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M8.5 13L10.6708 8.33541C10.7871 8.10292 10.7746 7.82681 10.638 7.6057C10.5013 7.38459 10.2599 7.25 10 7.25H7.21353L9.5 3H7.82295L5.32918 7.66459C5.21293 7.89708 5.22536 8.17318 5.36201 8.3943C5.49867 8.61541 5.74007 8.75 6 8.75H8.78648L6.82295 13H8.5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M3.5 5C3.5 4.72386 3.72386 4.5 4 4.5H5V3H4C2.89543 3 2 3.89543 2 5V11C2 12.1046 2.89543 13 4 13H5V11.5H4C3.72386 11.5 3.5 11.2761 3.5 11V5Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
