const nodeInteractiveStateOptions = [
  'default',
  'hover',
  'focus',
  'focus-visible',
  'focus-within',
  'active',
] as const;

export type NodeInteractiveState = typeof nodeInteractiveStateOptions[number];

export const isSelectedNodeValidInteractiveStateOptions = (
  nodeInteractiveState: string
): nodeInteractiveState is NodeInteractiveState =>
  nodeInteractiveStateOptions.includes(
    nodeInteractiveState as NodeInteractiveState
  );
