import { ComponentConfigWithStates as StylesConfig } from '@jux/types';
import { NodeType } from './nodetypes.enum';
import { ComponentTagName } from './componentTagName';
import { VariantsConfig } from './component-data';

// TODO: Fix the any
export type ComponentPropValue = any;

export type ComponentConfigData = {
  // This should be deprecated, exists only for backward compatibility.
  // parent context is now being calculated at runtime.
  // TODO: Make sure this can be removed
  contextParentId?: string;

  // This should be deprecated, exists only for backward compatibility.
  // new components use their id as contextId.
  // TODO: Make sure this can be removed
  contextId?: string;

  props: Record<string, ComponentPropValue>;
  variants?: VariantsConfig; // doesn't exist in instances
};
// Component base data should only include props that must be present in all components and have the same type
export type ComponentBaseData = {
  children: string[];
  config: ComponentConfigData;
  // TODO: change to use Date.toISOString()
  createdAt?: number;
  id: string;
  parentId?: string;
  sourceComponentId?: string;
  type: NodeType;
  // TODO: change to use Date.toISOString()
  updatedAt?: number;
};
// Source component data is a component that can be used as a template for instances.
// It can only be used as a root node.
// Element data is a component that can be used as a child node of another source component, or as a root node.
// Some elements can be made into source components, but not "JuxText" elements.
export type ComponentSourceData = ComponentBaseData & {
  displayName: string;
  // TODO: decide if we want to keep this (isDeleted), or
  //  change to a different approach, e.g. deletedAt (Date)
  isDeleted?: boolean;
  scopes: string[];
  styles: StylesConfig;
  tagName: ComponentTagName;
  type: NodeType;
};

// Component instance data references a source component or an element.
// It can be used as a child node of another component, or as a root node.
export type ComponentInstanceData = ComponentBaseData & {
  displayName?: string;
  scopes?: never;
  styles?: never;
  tagName?: never;
  type: NodeType.INSTANCE;
};
export type JuxComponentData = ComponentSourceData | ComponentInstanceData;

/**
 * Represents the data for component slots.
 * TODO: Add this to component data once slots are implemented.
 */
export enum SlotsType {
  DYNAMIC_SLOT = 'dynamic_slot',
  TEXT_SLOT = 'text_slot',
}

export type ComponentSlot = {
  id: string; // This is a reference to the component instance id (should be present in children).
  name: string; // This is the name of the slot. e.g. 'leadingIcon', 'children'
  type: SlotsType.DYNAMIC_SLOT | SlotsType.TEXT_SLOT;
};
