/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const AlignItemsStretchColumnDDPIcon: IconType = (
  props: IconBaseProps
) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M3 13.5C3 13.7761 2.77614 14 2.5 14C2.22386 14 2 13.7761 2 13.5V2.5C2 2.22386 2.22386 2 2.5 2C2.77614 2 3 2.22386 3 2.5L3 13.5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M4 6C4 6.55229 4.44772 7 5 7L11 7C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5L5 5C4.44772 5 4 5.44772 4 6Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M4 10C4 10.5523 4.44772 11 5 11H11C11.5523 11 12 10.5523 12 10C12 9.44772 11.5523 9 11 9H5C4.44772 9 4 9.44772 4 10Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M13.5 14C13.7761 14 14 13.7761 14 13.5L14 2.5C14 2.22386 13.7761 2 13.5 2C13.2239 2 13 2.22386 13 2.5L13 13.5C13 13.7761 13.2239 14 13.5 14Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
