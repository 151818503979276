import { FC, ReactNode } from 'react';
import {
  MuiFormControl,
  MuiFormHelperText,
  FormControlProps,
} from '@jux/ui/components/common/mui';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';

export const FieldContainer: FC<
  Omit<FormControlProps, 'error'> & { error?: ReactNode }
> = ({ children, sx, error }) => (
  <MuiFormControl
    sx={mergeTyped((theme) => theme.drimz.size.layout.tokenDrawer.field, sx)}
    error={Boolean(error)}
  >
    {children}
    <MuiFormHelperText
      sx={{
        // error
        '&.MuiFormHelperText-root.Mui-error': {
          color: (theme) => theme.drimz.palette.error.main,
        },
      }}
    >
      {error}
    </MuiFormHelperText>
  </MuiFormControl>
);
