import { FC } from 'react';
import { DimensionField } from '../../../../base';
import { useConstraintsFields } from './useConstraintsFields';

export const TopField: FC = () => {
  const { topFieldProps } = useConstraintsFields();

  return (
    <DimensionField
      icon="DDP_FIELD_CONSTRAINTS_TOP"
      tooltipTitle="Top"
      {...topFieldProps}
    />
  );
};
