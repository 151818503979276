import { HttpException } from '@nestjs/common';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { CreateUserFromInviteDto } from '@jux/data-entities';
import DrimzAPI from '@jux/ui/lib/api';

export function useAcceptUserInviteMutation(token: string) {
  return useMutation<
    boolean,
    AxiosError<HttpException>,
    CreateUserFromInviteDto
  >((userInfo) =>
    DrimzAPI.post(`/api/users/${token}`, userInfo).then(
      (resp: AxiosResponse<boolean>) => resp.data
    )
  );
}
