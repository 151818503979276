import deepmerge from 'deepmerge';

export function mergeTyped<T1, T2 = T1>(
  obj1: T1,
  obj2: T2
): T1 | T2 | (T1 & T2) {
  // deepmerge cannot handle undefined values, so we need to check for that
  // other option is to add NonNullable<T> to the generic type of each object, but that would make us to check on every merge wether the value is undefined or not
  if (!obj1) return obj2;
  if (!obj2) return obj1;

  return deepmerge(obj1, obj2);
}
