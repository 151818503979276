import { useCallback } from 'react';
import { useStoreActions } from '@jux/canjux/core';

export const useInteractionsRelativeToComponent = (componentId: string) => {
  const {
    commonActions: { setHoveredNode, resetHoveredNodes, setSelectedNodes },
  } = useStoreActions();

  const handleMouseEnter = useCallback(() => {
    setHoveredNode({
      affectChildren: true,
      isHovered: true,
      nodeId: componentId,
    });
  }, [componentId, setHoveredNode]);

  const handleMouseLeave = useCallback(() => {
    resetHoveredNodes();
  }, [resetHoveredNodes]);

  const handleClick = useCallback(() => {
    if (!componentId) return;

    setSelectedNodes({
      nodeIds: [componentId],
    });

    resetHoveredNodes();
  }, [componentId, resetHoveredNodes, setSelectedNodes]);

  return {
    handleMouseEnter,
    handleMouseLeave,
    handleClick,
  };
};
