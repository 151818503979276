import { MuiGrid, styled } from '@jux/ui/components/common/mui';
import { colorWithOpacity } from '@jux/ui/utils/colorWithOpacity';
import { MODAL_BOX_SHADOW_COLOR } from '@jux/ui/theme/palette';

export const Modal = styled(MuiGrid)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const MAX_PAPER_WIDTH = 420;
export const Paper = styled(MuiGrid)(({ theme }) => ({
  padding: '24px',
  maxWidth: MAX_PAPER_WIDTH,
  width: '100%',
  position: 'relative',
  zIndex: 1,
  backgroundColor: theme.drimz.palette.background.default,
  borderRadius: theme.drimz.size.borderRadius.main,
  boxShadow: `0px 2px 16px 0px ${colorWithOpacity(
    MODAL_BOX_SHADOW_COLOR,
    0.3
  )}`,
}));
