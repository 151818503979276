import { useCallback } from 'react';
import { storeApi, useStoreActions } from '@jux/canjux/core';
import { CORE_TOKEN_SET_NAME } from '@jux/types';
import { useTokenSetState } from '@jux/ui/state';

export const useDeleteTokenSet = () => {
  const {
    setCurrentTokenSet,
    currentTokenSetKey,
    currentTokenSetId,
    tokenSets,
  } = useTokenSetState();

  const {
    tokenSetsActions: { deleteTokenSet: deleteTokenSetAction },
  } = useStoreActions();

  const findWhichTokenSetToSelect = useCallback(
    (
      id: string
    ): {
      key: string;
      id: string;
    } => {
      const deletedCurrentTokenSet = currentTokenSetId === id;

      if (!deletedCurrentTokenSet) {
        return {
          id: currentTokenSetId,
          key: currentTokenSetKey,
        };
      }

      const currentIdIndex = tokenSets[id]?.index;

      const nextAvailableTokenSetId = Object.values(tokenSets).find(
        (tokenSet) => tokenSet.index === currentIdIndex + 1
      )?.id;
      const prevAvailableTokenSetId = Object.values(tokenSets).find(
        (tokenSet) => tokenSet.index === currentIdIndex - 1
      )?.id;

      const closetAvailableTokenSetId =
        nextAvailableTokenSetId ?? prevAvailableTokenSetId;

      const coreTokenSetId = tokenSets[CORE_TOKEN_SET_NAME]?.id;

      return {
        id: closetAvailableTokenSetId ?? coreTokenSetId,
        key: closetAvailableTokenSetId ?? CORE_TOKEN_SET_NAME,
      };
    },
    [currentTokenSetId, currentTokenSetKey, tokenSets]
  );

  const deleteTokenSet = useCallback(
    async (id: string) => {
      if (!id) return;

      deleteTokenSetAction({ tokenSetId: id });

      const state = storeApi.getState();

      const tokenSetsList = Object.values(state.tokenSets);

      const shouldSelectCoreTokenSet =
        tokenSetsList.length === 1 &&
        tokenSetsList[0]?.name === CORE_TOKEN_SET_NAME;

      if (shouldSelectCoreTokenSet) {
        setCurrentTokenSet({
          key: CORE_TOKEN_SET_NAME,
          id,
        });
      } else {
        setCurrentTokenSet(findWhichTokenSetToSelect(id));
      }
    },
    [deleteTokenSetAction, setCurrentTokenSet, findWhichTokenSetToSelect]
  );

  return {
    deleteTokenSet,
  };
};
