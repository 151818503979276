/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const JUXShortLogoIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="24"
    height="24"
    attr={{
      viewBox: '0 0 24 24',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M11.7667 12L8.24414 17.122H12.3009L15.7563 12L12.3009 6.87805H8.24414L11.7667 12Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
