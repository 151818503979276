// Token types
export enum DesignTokenTypeEnum {
  // this is a string enum - keys must equal values
  color = 'color',
  dimension = 'dimension',
  fontFamily = 'fontFamily',
  fontWeight = 'fontWeight',
  duration = 'duration',
  cubicBezier = 'cubicBezier',
  number = 'number',
  strokeStyle = 'strokeStyle',
  border = 'border',
  transition = 'transition',
  shadow = 'shadow',
  gradient = 'gradient',
  typography = 'typography',
  fontStyle = 'fontStyle',
  textDecoration = 'textDecoration',
}

// this is so that we can do either of the following:
// 1. `token.type = DesignTokenTypeEnum.color`
// 2. `token.type = 'color'`
export type DesignTokenType = keyof typeof DesignTokenTypeEnum;

// Token value types
export type DesignTokenPrimitive = string | number | boolean | undefined;
export type DesignTokenComposite = Record<string, DesignTokenPrimitive>;
export type DesignTokenValue = DesignTokenComposite | DesignTokenPrimitive;
export type DesignTokensExtensions = {
  createdAt?: string;
  isLocked?: boolean;
  updatedAt?: string;

  /**
   * External data (e.g. Figma ID, etc.)
   **/
  // TODO: Define external data type
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  external?: any;
};

// Token interface
export type DesignToken = {
  $value?: DesignTokenValue;
  $type?: DesignTokenType;
  $description?: string;
  $extensions?: DesignTokensExtensions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

// Group of tokens
export type DesignTokens = {
  [key: string]: DesignTokens | DesignToken;
};

export type DesignTokenData = {
  name: string;
  value: DesignTokenValue;
};
