const MARGIN_SIZE = '4px';
const FALLBACK_MARGIN_SIZE = '0';

export const typographyDefaultIconMargin = ({
  hasIcon,
  shouldAddMargin,
}: {
  hasIcon: boolean;
  shouldAddMargin: boolean;
}): string => {
  if (!hasIcon || !shouldAddMargin) return FALLBACK_MARGIN_SIZE;

  return MARGIN_SIZE;
};
