import { CanjuxState } from '@jux/canjux/core';
import equal from 'fast-deep-equal';
import { createSelector } from 'reselect';

export const selectIsNodeToolbarLabelInEditMode = (nodeId: string) =>
  createSelector(
    [(state: CanjuxState) => state.nodeIdLabelInEditMode],
    (nodeIdLabelInEditMode) => nodeIdLabelInEditMode === nodeId,
    {
      memoizeOptions: {
        equalityCheck: equal,
        resultEqualityCheck: equal,
      },
    }
  );
