// eslint-disable-next-line @jux/must-import-FC-in-tsx-files
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const BoxShadowInsetFalseDDPIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: 'currentColor',
          d: 'M3 4C3 3.44772 3.44772 3 4 3H11C11.5523 3 12 3.44772 12 4V11C12 11.5523 11.5523 12 11 12H4C3.44772 12 3 11.5523 3 11V4ZM4.5 10.5V4.5H10.5V10.5H4.5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M14.5 5.75L13 4.25V11.75L14.5 10.25V5.75Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M5.75 14.5H10.25L11.75 13H4.25L5.75 14.5Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
