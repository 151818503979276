import { trimLowerCaseStr } from '@jux/ui/utils/trimLowerCaseStr';
import {
  SupportedPositionValues,
  PositionValue,
} from './PositionField.interface';

export const DEFAULT_POSITION_VALUE = PositionValue.static;

export const parsePosition = (
  value: string
): SupportedPositionValues | undefined => {
  switch (trimLowerCaseStr(value)) {
    case 'absolute':
      return PositionValue.absolute;
    case 'relative':
      return PositionValue.relative;
    case 'static':
      return PositionValue.static;
  }

  return undefined;
};
