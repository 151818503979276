import { ClipboardEvent } from 'react';
import { insertIntoElement } from '../helpers/insertIntoElement';

export const formatTokenNamePaste = <T extends HTMLElement>(
  event: ClipboardEvent<T>,
  element?: T
) => {
  event.preventDefault();

  const data = event.clipboardData.getData('text/plain');

  const target = element || (event.target as HTMLElement);

  insertIntoElement(target, data);
};
