import { useMemo } from 'react';
import { useCurrentOrg } from '@jux/ui/hooks';
import { useGetCanvasDataQuery } from '@jux/ui/data';

// TODO: Rename this to `useLiveblocksRoomId` or similar
export const useGetCanvasData = () => {
  const { id: orgId } = useCurrentOrg();

  const { data, isLoading } = useGetCanvasDataQuery(orgId);

  const canvas = useMemo(
    () => ({
      canvasId: data?.uuid,
      roomId: data?.data.liveblocksRoomId,
    }),
    [data?.data.liveblocksRoomId, data?.uuid]
  );

  return {
    canvas,
    isLoading,
  };
};
