import {
  COMPONENT_TAG_NAME,
  ComponentElementType,
  ComponentProps,
} from '@jux/data-entities';
import {
  MouseEventHandler,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';

export const Chip = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxChip>,
  ComponentProps<typeof COMPONENT_TAG_NAME.JuxChip>
>(
  (
    {
      children,
      disabled,
      selected: value,
      onClick,
      rootElement: RootElement = 'button',
      ...props
    },
    forwardedRef
  ) => {
    const isDisabled = disabled || false;
    const isControlled = useMemo(() => value !== undefined, [value]);

    const [internalState, setInternalState] = useState(Boolean(value));

    const selected = useMemo(
      () => (isControlled ? Boolean(value) : internalState),
      [value, internalState, isControlled]
    );

    const handleButtonClick: MouseEventHandler<HTMLButtonElement> = useCallback(
      (e) => {
        onClick?.(e);

        if (isControlled) {
          return;
        }
        setInternalState(!selected);
      },
      [isControlled, selected, onClick]
    );

    return (
      <RootElement
        {...props}
        ref={forwardedRef}
        onClick={handleButtonClick}
        selected={selected || false}
        aria-selected={selected || false}
        disabled={isDisabled}
        aria-disabled={isDisabled}
      >
        {children}
      </RootElement>
    );
  }
);
