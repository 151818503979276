import {
  CanjuxState,
  CommonActionsParams,
  JuxStoreActionFn,
} from '@jux/canjux/core';

/**
 * Set node dragging start
 */
export const setNodeDraggingStart: JuxStoreActionFn<
  CommonActionsParams['setNodeDraggingStart'],
  CanjuxState
> = ({ nodeId, state }) => {
  state.canvases[state.currentCanvasName].nodes[nodeId].properties.isDragged =
    true;
  state.dragNodeStarted = true;

  return state;
};
