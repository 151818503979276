import { DesignTokenTypeEnum } from '@jux/design-tokens';
import { useFieldTokens } from '../useFieldTokens';
import { useParseFieldValue } from '../useParseFieldValue';
import {
  UseDimensionFieldProps,
  UseDimensionFieldReturnProps,
} from './useDimensionField.interface';
import { useDimensionFieldValue } from './useDimensionFieldValue';
import { useDimensionFieldInputs } from './useDimensionFieldInputs';
import { DEFAULT_DIMENSION_OPTIONS } from './useDimensionField.utils';

const TOKEN_TYPE = DesignTokenTypeEnum.dimension;

export const useDimensionField = ({
  fieldName,
  initialValue,
  value,
  saveChanges: setFieldValue,
  options,
  parentFieldTokenType,
}: // supportTokens = true // TODO: add support for disabling tokens
UseDimensionFieldProps): UseDimensionFieldReturnProps => {
  options = { ...DEFAULT_DIMENSION_OPTIONS, ...options };

  const { rawValue, isComputed } = useParseFieldValue({
    fieldName,
    value,
    initialValue,
    tokenType: TOKEN_TYPE,
    parentFieldTokenType,
  });

  const {
    parsedValue,
    setUnit,
    parseUnit,
    unit,
    activateUnit,
    fieldSchema,
    parseValue,
    validateField,
  } = useDimensionFieldValue({
    rawValue,
    options,
  });

  const {
    fieldValue,
    updateStateByTokenValue,
    registerField,
    handleEscape,
    handleBlur,
    handleEnter,
    handleUnitChange,
    handleKeyDown,
    setValue,
  } = useDimensionFieldInputs({
    fieldName,
    value,
    parsedValue,
    initialValue,
    unit,
    setUnit,
    saveChanges: setFieldValue,
    fieldSchema,
    parseValue,
    validateField,
    parseUnit,
    options,
  });

  const {
    isTokenized,
    tokens,
    selectedToken,
    tokenDisplayName,
    tokenParsedValue,
    handleCreateNewTokenSaved,
    handleTokenSelection,
    handleTokenDetach,
    tokensPopper,
  } = useFieldTokens({
    fieldName,
    setStateByTokenValue: updateStateByTokenValue,
    setFieldValue,
    value,
    tokenType: TOKEN_TYPE,
    parentFieldTokenType,
  });

  const onTokenDrawerCancel = (initialVal: string) => {
    setFieldValue(initialVal);
  };

  const setFieldValueOnTokenDrawerChange = ({
    newValue,
  }: {
    newValue: string;
    subFieldName?: string;
  }) => {
    setFieldValue(newValue);
  };

  return {
    isComputed,
    setUnit,
    setValue,

    value: fieldValue,
    rawValue,
    unit,
    activateUnit,
    onUnitChange: handleUnitChange,

    inputProps: {
      inputRegister: registerField,
      onEscape: handleEscape,
      onEnter: handleEnter,
      onBlur: handleBlur,
      onKeyDown: handleKeyDown,
    },

    tokensProps: {
      tokenType: TOKEN_TYPE,
      tokens,
      isTokenized,
      selectedToken,
      tokenDisplayName,
      tokenParsedValue,
      onTokenSelection: handleTokenSelection,
      onTokenDetach: handleTokenDetach,
      tokensPopperProps: tokensPopper,
      tokenDrawerProps: {
        initialValue: rawValue,
        onValueChange: setFieldValueOnTokenDrawerChange,
        onSave: handleCreateNewTokenSaved,
        onCancelButton: onTokenDrawerCancel,
      },
    },
  };
};
