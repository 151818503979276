import { styled } from '@jux/ui/components/common/mui';
import { withPx } from '@jux/ui/utils/css';

export const PaneIndicatorsWrapper = styled('div')<{
  x: number;
  y: number;
  zoom: number;
}>(({ x, y, zoom }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  transformOrigin: 'left top',
  transform: `translate(${withPx(x)}, ${withPx(y)}) scale(${zoom})`,
  zIndex: 1,
  pointerEvents: 'none',
}));
