import * as uuid from 'uuid';
import {
  ComponentConfigWithStates as StylesConfig,
  ContextStylesList,
  StylesObject,
} from '@jux/types';
import {
  COMPONENT_TAG_NAME,
  ComponentPropType,
  VariantsConfig,
  NodeType,
} from '@jux/data-entities';
import { Colors } from '../common/colors';
import { getJuxTextElementData } from './text';
import { getJuxDivElementData } from './div';
import { ElementDataInit } from '../types/ElementDataInit';

const JUX_RADIO_LABEL_DEFAULT_CONTENT = 'label';
const JUX_RADIO_HELPER_TEXT_DEFAULT_CONTENT = 'Helper text';
const RADIO_ELEMENT_DEFAULT_DISPLAY_NAME = 'radio_button';

const RADIO_ELEMENT_VARIANTS_CONFIG: VariantsConfig = [
  {
    variant: 'disabled',
    propType: ComponentPropType.Enum,
    options: [
      { value: true, label: 'True' },
      { value: false, label: 'False' },
    ],
    isFixed: true,
    defaultValue: false,
  },
  {
    variant: 'checked',
    propType: ComponentPropType.Enum,
    options: [
      { value: true, label: 'Checked' },
      { value: false, label: 'Unchecked' },
    ],
    isFixed: true,
    defaultValue: true,
  },
];

const RADIO_ELEMENT_DEFAULT_STYLES: StylesConfig = {
  root: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '0 10px',
    borderStyle: 'none',
    borderColor: '', // this is set so that DDP would not show browser default #767676 and show empty value instead
    outline: 'none',
    background: 'none',
    color: Colors.Neutrals_0,
    width: 'auto',
    height: 'auto',
    // Input styles (included by the base element itself, not in the element data)
    '& > input[type=radio]': {
      transform: 'translateX(-100%)',
      position: 'absolute',
      pointerEvents: 'none',
      opacity: 0,
    },
  },
  variants: [
    {
      propsValues: {
        disabled: true,
      },
      styles: {
        cursor: 'default',
      },
    },
  ],
  states: {
    // TODO: make states work in editor without having to include such empty objects
    hover: {
      root: {},
      variants: [],
    },
    // TODO: make states work in editor without having to include such empty objects
    active: {
      root: {},
      variants: [],
    },
    'focus-visible': {
      root: {
        '& > input[type=radio] + div': {
          outlineColor: Colors.Brand_500,
          outlineWidth: '2px',
          outlineStyle: 'solid',
          outlineOffset: '2px',
        },
      },
    },
  },
};

const JUX_RADIO_LABEL_DEFAULT_STYLES: StylesObject = {
  fontFamily: 'Inter',
  fontWeight: '500',
  fontStyle: 'normal',
  fontSize: '14px',
  lineHeight: '17px',
  display: 'inline-block',
  color: Colors.Neutrals_500,
  width: 'auto',
  height: 'auto',
};

const JUX_RADIO_HELPER_TEXT_DEFAULT_STYLES: StylesObject = {
  fontFamily: 'Inter',
  fontWeight: '400',
  fontStyle: 'normal',
  fontSize: '12px',
  lineHeight: '14px',
  display: 'inline-block',
  color: Colors.Neutrals_400,
  width: 'auto',
  height: 'auto',
};

const getJuxRadioChildren = ({ rootRadioId }: { rootRadioId: string }) => {
  const radioElement = getJuxDivElementData({
    contextParentId: rootRadioId,
    parentId: rootRadioId,
    displayName: 'radio',
    styles: {
      root: {
        color: 'currentColor',
        height: '16px',
        minHeight: '16px',
        width: '16px',
        minWidth: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
      },
      states: {},
    },
  }).root;

  const radioLabel = getJuxTextElementData({
    contextParentId: rootRadioId,
    rootStyles: JUX_RADIO_LABEL_DEFAULT_STYLES,
    text: JUX_RADIO_LABEL_DEFAULT_CONTENT,
    displayName: 'label',
  }).root;
  const radioTextHelper = getJuxTextElementData({
    contextParentId: rootRadioId,
    rootStyles: JUX_RADIO_HELPER_TEXT_DEFAULT_STYLES,
    text: JUX_RADIO_HELPER_TEXT_DEFAULT_CONTENT,
    displayName: 'helper_text',
  }).root;

  const textWrapperElement = getJuxDivElementData({
    contextParentId: rootRadioId,
    parentId: rootRadioId,
    displayName: 'text_wrapper',
    styles: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '4px 0',
        width: 'auto',
        height: 'auto',
      },
      states: {},
    },
    children: [radioLabel.id, radioTextHelper.id],
  }).root;
  radioLabel.parentId = textWrapperElement.id;
  radioTextHelper.parentId = textWrapperElement.id;

  return { radioElement, textWrapperElement, radioLabel, radioTextHelper };
};

const getJuxRadioContextStyles = ({
  radioElementContextUuid,
}: {
  radioElementContextUuid: string;
}) => {
  const uncheckedContextStyles: ContextStylesList = [
    // Default + unchecked
    {
      contextChildUuid: radioElementContextUuid,
      condition: {
        propsValues: { checked: false, disabled: false },
      },
      styles: {
        borderColor: Colors.Neutrals_400,
        borderWidth: '1.5px',
        borderStyle: 'solid',
      },
    },

    // Default + unchecked + disabled
    {
      contextChildUuid: radioElementContextUuid,
      condition: {
        propsValues: { checked: false, disabled: true },
      },
      styles: {
        borderColor: Colors.Neutrals_300,
        borderWidth: '1.5px',
        borderStyle: 'solid',
      },
    },

    // Hover + unchecked
    {
      contextChildUuid: radioElementContextUuid,
      condition: {
        state: 'hover',
        propsValues: { checked: false, disabled: false },
      },
      styles: {
        borderColor: Colors.Neutrals_700,
        borderWidth: '1.5px',
        borderStyle: 'solid',
      },
    },

    // Active + unchecked
    {
      contextChildUuid: radioElementContextUuid,
      condition: {
        state: 'active',
        propsValues: { checked: false, disabled: false },
      },
      styles: {
        borderColor: Colors.Neutrals_800,
        borderWidth: '1.5px',
        borderStyle: 'solid',
      },
    },
  ];

  const checkedContextStyles: ContextStylesList = [
    // Default + checked
    {
      contextChildUuid: radioElementContextUuid,
      condition: {
        propsValues: { checked: true, disabled: false },
      },
      styles: {
        borderColor: Colors.Brand_500,
        borderWidth: '5px',
        borderStyle: 'solid',
      },
    },

    // Default + checked + disabled
    {
      contextChildUuid: radioElementContextUuid,
      condition: {
        propsValues: { checked: true, disabled: true },
      },
      styles: {
        borderColor: Colors.Brand_200,
        borderWidth: '5px',
        borderStyle: 'solid',
      },
    },

    // Hover + checked
    {
      contextChildUuid: radioElementContextUuid,
      condition: {
        state: 'hover',
        propsValues: { checked: true, disabled: false },
      },
      styles: {
        borderColor: Colors.Brand_600,
        borderWidth: '5px',
        borderStyle: 'solid',
      },
    },

    // Active + checked
    {
      contextChildUuid: radioElementContextUuid,
      condition: {
        state: 'active',
        propsValues: { checked: true, disabled: false },
      },
      styles: {
        borderColor: Colors.Brand_700,
        borderWidth: '5px',
        borderStyle: 'solid',
      },
    },
  ];

  return [...uncheckedContextStyles, ...checkedContextStyles];
};

export const getJuxRadioElementData: ElementDataInit<undefined> = ({
  contextParentId,
  parentId,
} = {}) => {
  const rootRadioId = uuid.v4();

  const { radioElement, textWrapperElement, radioLabel, radioTextHelper } =
    getJuxRadioChildren({
      rootRadioId,
    });

  const contextStyles: ContextStylesList = getJuxRadioContextStyles({
    radioElementContextUuid: radioElement.id,
  });

  return {
    root: {
      displayName: RADIO_ELEMENT_DEFAULT_DISPLAY_NAME,
      type: NodeType.LOCAL_COMPONENT,
      id: rootRadioId,
      parentId,
      scopes: [],
      tagName: COMPONENT_TAG_NAME.JuxRadio,
      styles: { ...RADIO_ELEMENT_DEFAULT_STYLES, contextStyles },
      config: {
        contextParentId,
        props: {
          checked: true,
          disabled: false,
        },
        variants: RADIO_ELEMENT_VARIANTS_CONFIG,
      },
      children: [radioElement.id, textWrapperElement.id],
    },
    subComponents: [
      radioElement,
      textWrapperElement,
      radioLabel,
      radioTextHelper,
    ],
  };
};
