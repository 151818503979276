/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const AlignItemsStretchRowDDPIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M14 2.5C14 2.22386 13.7761 2 13.5 2H2.5C2.22386 2 2 2.22386 2 2.5C2 2.77614 2.22386 3 2.5 3L13.5 3C13.7761 3 14 2.77614 14 2.5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M6 12C6.55229 12 7 11.5523 7 11L7 5C7 4.44772 6.55228 4 6 4C5.44772 4 5 4.44772 5 5L5 11C5 11.5523 5.44772 12 6 12Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M10 12C10.5523 12 11 11.5523 11 11L11 5C11 4.44772 10.5523 4 10 4C9.44771 4 9 4.44772 9 5L9 11C9 11.5523 9.44772 12 10 12Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M13.5 13C13.7761 13 14 13.2239 14 13.5C14 13.7761 13.7761 14 13.5 14H2.5C2.22386 14 2 13.7761 2 13.5C2 13.2239 2.22386 13 2.5 13L13.5 13Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
