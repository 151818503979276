import { useSelect } from 'downshift';
import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { preventClickPropagation } from '@jux/ui/utils/preventClickPropagation';
import { UnitType, filteredUnits } from '../../../../hooks';
import { Option } from './Option';
import { parseUnitStr } from './parseUnitStr';
import * as S from './UnitSelection.style';

export const UnitSelection: FC<{
  unit?: UnitType;
  onUnitChange: (newUnit: UnitType) => void;
  fieldClassName: string;
  disabled?: boolean;
  isFieldDisabled?: boolean;
}> = ({ unit, onUnitChange, fieldClassName, disabled, isFieldDisabled }) => {
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: filteredUnits,
    selectedItem: unit || '',
    onSelectedItemChange: ({ selectedItem }) =>
      onUnitChange(selectedItem as UnitType),
  });

  return (
    <MuiGrid onClick={preventClickPropagation}>
      <S.UnitSelectionSelector
        {...getToggleButtonProps()}
        isOpen={isOpen}
        fieldClassName={fieldClassName}
        disabled={disabled}
        isFieldDisabled={isFieldDisabled}
      >
        {parseUnitStr(unit)}
      </S.UnitSelectionSelector>
      <S.OptionsWrapper
        {...getMenuProps()}
        hidden={disabled || isFieldDisabled || !isOpen}
      >
        {filteredUnits.map((unitOption, index) => (
          <Option
            key={`${unitOption}${index}`}
            isHighlighted={highlightedIndex === index}
            {...getItemProps({ item: unitOption, index })}
          >
            {parseUnitStr(unitOption)}
          </Option>
        ))}
      </S.OptionsWrapper>
    </MuiGrid>
  );
};
