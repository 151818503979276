import { FC } from 'react';
import { Icons } from '@jux/ui/components';
import {
  ExpanderIconWrapper,
  ExpanderIconWrapperProps,
} from './ExpanderIconWrapper';

const DEFAULT_ICON_SIZE = 16;

export type ExpanderProps = Pick<
  ExpanderIconWrapperProps,
  'showExpanderIcon' | 'onClick'
> & {
  isExpanded: boolean;
};

export const Expander: FC<ExpanderProps> = ({
  isExpanded,
  onClick,
  showExpanderIcon,
}) => (
  <ExpanderIconWrapper
    showExpanderIcon={showExpanderIcon}
    onClick={(e) => {
      e.stopPropagation();
      onClick(e);
    }}
  >
    <Icons
      size={DEFAULT_ICON_SIZE}
      variant={
        isExpanded
          ? 'LAYERS_PANEL_DROP_ARROW_OPENED'
          : 'LAYERS_PANEL_DROP_ARROW_CLOSED'
      }
    />
    {}
  </ExpanderIconWrapper>
);
