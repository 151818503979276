import { FC, PropsWithChildren } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { SubModuleProps } from './SubModule.interface';
import { SubModuleHeader } from './SubModuleHeader';

export const SubModule: FC<PropsWithChildren<SubModuleProps>> = ({
  title,
  children,
  isOpen,
  setIsOpen,
  renderInline,
  renderFieldToMax,
  hideHeader,
}) => (
  <MuiGrid
    container
    gap={renderInline ? '12px' : '4px'}
    flexDirection={renderInline ? 'row' : 'column'}
    flexWrap="nowrap"
  >
    {!hideHeader && title && (
      <MuiGrid
        item
        flex={renderFieldToMax ? undefined : 4}
        minWidth={renderInline ? 'calc(42% - 12px)' : undefined}
      >
        <SubModuleHeader
          title={title}
          isOpen={Boolean(isOpen)}
          setIsOpen={setIsOpen}
          renderInline={renderInline}
        />
      </MuiGrid>
    )}
    <MuiGrid
      container
      item
      flex={6}
      flexDirection="column"
      gap="4px"
      minWidth={renderInline ? '58%' : undefined}
    >
      {children}
    </MuiGrid>
  </MuiGrid>
);
