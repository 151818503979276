import { BoxShadow, parseBoxShadow } from '@jux/ui/utils/parseBoxShadow';

const DEFAULT_SHADOW_X = '0px';
const DEFAULT_SHADOW_Y = '0px';
const DEFAULT_SHADOW_BLUR = '0px';
const DEFAULT_SHADOW_COLOR = '';
const DEFAULT_SHADOW_SPREAD = '0px';

export const emptyShadow: BoxShadow = {
  offsetX: DEFAULT_SHADOW_X,
  offsetY: DEFAULT_SHADOW_Y,
  blurRadius: DEFAULT_SHADOW_BLUR,
  color: DEFAULT_SHADOW_COLOR,
  spreadRadius: DEFAULT_SHADOW_SPREAD,
  inset: false,
};

export const parseShadowsValues = (
  value: string
): Array<BoxShadow> | undefined => {
  const parsedShadowsValues = parseBoxShadow(value);

  if (!parsedShadowsValues?.length) return undefined;

  return parsedShadowsValues;
};
