import { FC, useMemo } from 'react';
import { capitalize } from 'lodash';
import { MuiGrid } from '@jux/ui/components/common/mui';
import {
  Icons,
  EditableTypography,
  Typography,
  IconButton,
} from '@jux/ui/components';
import {
  formatTokenNameKeyPress,
  formatTokenNamePaste,
} from '@jux/ui/utils/tokens/tokenNameFormat';
import { TOKENS_GROUP_HEADER_ARROW_ICON_COLOR } from '@jux/ui/theme/palette';
import { textValidators } from '@jux/ui/utils/textValidators';
import { TokenGroupHeaderProps } from './TokenGroupHeader.interface';

export const GROUP_HEADER_ACTION_BUTTON_CLASS_NAME =
  'group-header-actions-button';

export const TokenGroupHeader: FC<TokenGroupHeaderProps> = ({
  totalTokens = 0,
  isGroupOpen,
  toggleGroupView,
  groupNameProps,
  isLocked,
  groupDescription,
  onClickActionsButton,
  existingGroupNames,
}) => {
  const groupNameValidator = useMemo(
    () =>
      textValidators.nameAlreadyExistsValidator({
        namesArray: existingGroupNames,
        errorMessages: {
          nameAlreadyExists: 'Group name already exists',
          namesArrayUndefined: 'Group is undefined',
        },
      }),
    [existingGroupNames]
  );

  return (
    <MuiGrid
      item
      container
      sx={{
        alignItems: 'center',
        gap: '8px',
        height: (theme) => theme.drimz.size.layout.tokens.group.header.height,
      }}
    >
      <MuiGrid
        item
        onClick={toggleGroupView}
        sx={{
          cursor: 'pointer',
          width: 'fit-content',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <MuiGrid item>
          <Icons
            variant={isGroupOpen ? 'ARROW_POINTS_DOWN' : 'ARROW_POINTS_RIGHT'}
            sx={{
              color: TOKENS_GROUP_HEADER_ARROW_ICON_COLOR,
            }}
          />
        </MuiGrid>
        <MuiGrid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap="4px"
        >
          <EditableTypography
            {...groupNameProps}
            onClick={(e) => isLocked || e.stopPropagation()}
            customValidators={[groupNameValidator]}
            onKeyDown={formatTokenNameKeyPress}
            onPaste={formatTokenNamePaste}
            variant="body-regular"
            disableEditing={isLocked}
            tooltipContent={isLocked ? groupDescription : 'Rename group'}
            tooltipProps={{
              header: `${capitalize(groupNameProps.label)} tokens`,
            }}
            cursor="pointer"
          />
          <Typography variant="body-regular">({totalTokens})</Typography>
        </MuiGrid>
      </MuiGrid>

      <IconButton
        variant="icon"
        size="medium"
        onClick={onClickActionsButton}
        icon="DOTS_MENU_HORIZONTAL"
        className={GROUP_HEADER_ACTION_BUTTON_CLASS_NAME}
      />
    </MuiGrid>
  );
};
