/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const DocumentationIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="28"
    height="24"
    attr={{
      viewBox: '0 0 28 24',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M18 2C17.2044 2 16.4413 2.31607 15.8787 2.87868C15.3161 3.44129 15 4.20435 15 5C15 5.55228 14.5523 6 14 6C13.4477 6 13 5.55228 13 5C13 3.67392 13.5268 2.40215 14.4645 1.46447C15.4021 0.526784 16.6739 0 18 0H26C26.5304 0 27.0391 0.210714 27.4142 0.585787C27.7893 0.960861 28 1.46957 28 2V18C28 18.5304 27.7893 19.0391 27.4142 19.4142C27.0391 19.7893 26.5304 20 26 20H18C17.2044 20 16.4413 20.3161 15.8787 20.8787C15.3161 21.4413 15 22.2044 15 23C15 23.5523 14.5523 24 14 24C13.4477 24 13 23.5523 13 23C13 21.6739 13.5268 20.4021 14.4645 19.4645C15.4021 18.5268 16.6739 18 18 18H26L26 2L18 2Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M0.585786 0.585787C0.960859 0.210714 1.46957 0 2 0H10C11.3261 0 12.5979 0.526784 13.5355 1.46447C14.4732 2.40215 15 3.67392 15 5V23C15 23.5523 14.5523 24 14 24C13.4477 24 13 23.5523 13 23C13 22.2044 12.6839 21.4413 12.1213 20.8787C11.5587 20.3161 10.7956 20 10 20H2C1.46957 20 0.960861 19.7893 0.585786 19.4142C0.210713 19.0391 0 18.5304 0 18V2C0 1.46957 0.210714 0.960859 0.585786 0.585787ZM13 19V5C13 4.20435 12.6839 3.44129 12.1213 2.87868C11.5587 2.31607 10.7956 2 10 2L2 2V18H10C11.0881 18 12.1396 18.3547 13 19Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
