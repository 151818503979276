import {
  COMPONENT_TAG_NAME,
  ComponentElementType,
  ComponentProps,
} from '@jux/data-entities';
import { forwardRef } from 'react';

export const Text = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>,
  ComponentProps<typeof COMPONENT_TAG_NAME.JuxText>
>(({ text, rootElement: RootElement = 'p', ...props }, ref) => (
  <RootElement ref={ref} {...props}>
    {text}
  </RootElement>
));
