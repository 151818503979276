import { createSelector } from 'reselect';
import { CanjuxState } from '@jux/canjux/core';

export const selectIsLiveMode = () =>
  createSelector(
    [(state: CanjuxState) => state.disableNodesInteraction],
    (disableNodesInteraction) => disableNodesInteraction
  );

export const selectHoveredNodesStack = createSelector(
  [(state: CanjuxState) => state.hoveredNodesStack],
  (hoveredNodesStack) => hoveredNodesStack
);
