import {
  CanjuxState,
  CommonActionsParams,
  JuxStoreActionFn,
  setLayersData,
} from '@jux/canjux/core';

/**
 * Reset selected nodes
 */
export const resetSelectedNodes: JuxStoreActionFn<
  CommonActionsParams['resetSelectedNodes'],
  CanjuxState
> = ({ state }) => {
  state.selectedNodesStack = [];

  setLayersData(state);

  return state;
};
