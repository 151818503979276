import { useCallback } from 'react';
import { DesignTokenData, DesignTokenTypeEnum } from '@jux/design-tokens';
import {
  BaseFieldTokenProps,
  NO_TOKEN,
} from '@jux/ui/components/editor/components/panels/DDP/base/fields/common';
import {
  DDPCompositeFieldsKeys,
  DDPPrimitiveFieldsKeys,
} from '@jux/ui/components/editor/state';
import { useSetFieldValue } from '@jux/ui/components/editor/components/panels/DDP/hooks';
import { parseTypographyTokenValue } from '@jux/ui/components/tokens/token-drawer/forms/helpers';
import { TypographyTokenFormProps } from '@jux/ui/components/tokens/token-drawer/forms/types';
import { useFieldTokens } from '../useFieldTokens';
import { useTypographyFieldValue } from './useTypographyFieldValue';
import {
  UseTypographyFieldProps,
  UseTypographyFieldReturnProps,
} from './useTypographyField.interface';
const TOKEN_TYPE = DesignTokenTypeEnum.typography;

export const useTypographyField = ({
  saveChanges: setFieldValue,
}: UseTypographyFieldProps): UseTypographyFieldReturnProps => {
  const {
    fontFamilyValue,
    fontWeightValue,
    fontSizeValue,
    lineHeightValue,
    letterSpacingValue,
    parsedValue: typographyFieldValue,
    updateStateByTokenValue,
    detachChildrenFromParent,
  } = useTypographyFieldValue({
    fieldName: DDPCompositeFieldsKeys.typography,
  });

  const {
    isTokenized,
    tokens,
    selectedToken,
    tokenDisplayName,
    tokenParsedValue,
    handleCreateNewTokenSaved,
    handleTokenSelection,
    handleCompositeTokenDetach,
    tokensPopper,
  } = useFieldTokens({
    fieldName: DDPCompositeFieldsKeys.typography,
    setStateByTokenValue: updateStateByTokenValue,
    setFieldValue,
    value: typographyFieldValue,
    tokenType: TOKEN_TYPE,
  });

  // TODO: fix this hook to get the field name into the callback instead of calling it for every field
  // CR comment: https://github.com/Drimz-io/drimz/pull/478#discussion_r1429266086
  const { saveValue: saveFontFamily } = useSetFieldValue(
    DDPPrimitiveFieldsKeys.fontFamily
  );
  const { saveValue: saveFontWeight } = useSetFieldValue(
    DDPPrimitiveFieldsKeys.fontWeight
  );
  const { saveValue: saveFontSize } = useSetFieldValue(
    DDPPrimitiveFieldsKeys.fontSize
  );
  const { saveValue: saveLineHeight } = useSetFieldValue(
    DDPPrimitiveFieldsKeys.lineHeight
  );
  const { saveValue: saveLetterSpacing } = useSetFieldValue(
    DDPPrimitiveFieldsKeys.letterSpacing
  );

  const handleTokenDrawerChange = useCallback(
    ({
      newValue,
      subFieldName,
    }: {
      newValue: string;
      subFieldName?: string;
    }) => {
      if (!subFieldName) {
        const tokenData = tokens.find((token) => token.name === newValue);
        if (!tokenData) return;
        updateStateByTokenValue(tokenData);
        return;
      }

      switch (subFieldName) {
        case DDPPrimitiveFieldsKeys.fontFamily:
          saveFontFamily(newValue);
          break;
        case DDPPrimitiveFieldsKeys.fontWeight:
          saveFontWeight(newValue);
          break;
        case DDPPrimitiveFieldsKeys.fontSize:
          saveFontSize(newValue);
          break;
        case DDPPrimitiveFieldsKeys.lineHeight:
          saveLineHeight(newValue);
          break;
        case DDPPrimitiveFieldsKeys.letterSpacing:
          saveLetterSpacing(newValue);
          break;
      }
    },
    [
      saveFontFamily,
      saveFontWeight,
      saveFontSize,
      saveLineHeight,
      saveLetterSpacing,
      tokens,
      updateStateByTokenValue,
    ]
  );

  const onTokenDrawerCancel = (
    initialValues: TypographyTokenFormProps['initialValues']['value']
  ) => {
    const value = parseTypographyTokenValue(initialValues);
    if (value) {
      saveFontFamily(value.fontFamily);
      saveFontWeight(value.fontWeight);
      saveFontSize(value.fontSize);
      saveLineHeight(value.lineHeight);
      saveLetterSpacing(value.letterSpacing);
    }
  };

  const onTokenDetach = useCallback(
    (token?: DesignTokenData) => {
      handleCompositeTokenDetach();
      detachChildrenFromParent(token);
    },
    [handleCompositeTokenDetach, detachChildrenFromParent]
  );

  return {
    parsedValue: typographyFieldValue,
    isComputed: NO_TOKEN === typographyFieldValue,
    setValue: setFieldValue,

    tokensProps: {
      tokens: tokens,
      isTokenized: isTokenized,
      selectedToken: selectedToken,
      tokenDisplayName,
      tokenParsedValue,
      onTokenSelection: handleTokenSelection,
      onTokenDetach,
      tokensPopperProps: tokensPopper,
      tokenType: TOKEN_TYPE,
      tokenDrawerProps: {
        initialValue: {
          fontFamily: fontFamilyValue,
          fontWeight: fontWeightValue,
          fontSize: fontSizeValue,
          lineHeight: lineHeightValue,
          letterSpacing: letterSpacingValue,
        },
        onValueChange: handleTokenDrawerChange,
        onSave: handleCreateNewTokenSaved,
        onCancelButton: onTokenDrawerCancel,
      },
    } as BaseFieldTokenProps,
  };
};
