/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const OpacityDDPIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M7.25 3.81597C5.26049 4.1702 3.75 5.9087 3.75 8C3.75 10.0913 5.26049 11.8298 7.25 12.184V3.81597ZM8.75 3.81584V12.1842C9.10029 12.1219 9.43541 12.0167 9.75 11.8743V4.12566C9.43541 3.9833 9.10029 3.87813 8.75 3.81584ZM11.25 5.26106V10.7389C11.8744 9.99885 12.25 9.04309 12.25 8C12.25 6.95691 11.8744 6.00115 11.25 5.26106ZM2.25 8C2.25 4.82436 4.82436 2.25 8 2.25C9.04608 2.25 10.029 2.53003 10.8755 3.01971C12.5923 4.01278 13.75 5.87074 13.75 8C13.75 10.1293 12.5923 11.9872 10.8755 12.9803C10.029 13.47 9.04608 13.75 8 13.75C4.82436 13.75 2.25 11.1756 2.25 8Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
