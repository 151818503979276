import { DesignTokenValue } from '@jux/design-tokens';

export const getFirstValue = (value: DesignTokenValue): string => {
  if (typeof value === 'string') {
    return value?.split(',')[0];
  } else if (Array.isArray(value)) {
    return value[0]?.split(',')[0];
  }

  return value?.toString() ?? '';
};
