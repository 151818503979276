import { FC } from 'react';
import { Tab, Tabs } from '@jux/ui/components';

export const ComponentsHeader: FC<{
  setLocalComponents: () => void;
  setLibraryComponents: () => void;
  isLocalComponents: boolean;
  isLibraryComponents: boolean;
}> = ({
  setLocalComponents,
  setLibraryComponents,
  isLocalComponents,
  isLibraryComponents,
}) => (
  <Tabs>
    <Tab
      title="Local"
      onClick={setLocalComponents}
      selected={isLocalComponents}
    />
    <Tab
      title="Library"
      onClick={setLibraryComponents}
      selected={isLibraryComponents}
    />
  </Tabs>
);
