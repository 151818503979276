import { useCallback } from 'react';
import { useModalState } from './useModalState';
import {
  ModalActions,
  ModalHandlersRecord,
  ModalStates,
  UpdateModalArgs,
} from './useModalState.interface';

export const useModal = <MH extends ModalHandlersRecord>() => {
  const { content, handlers, reset, setState, state } = useModalState();

  const handlerWithClose = useCallback(
    (handlerKey: keyof MH) => {
      if (!handlers) return;

      const handler = handlers[handlerKey as keyof typeof handlers];
      handler?.();
      setState({
        action: ModalActions.close,
      });
    },
    [handlers, setState]
  );

  const close = useCallback(() => {
    handlerWithClose('onClose' as keyof MH);
  }, [handlerWithClose]);

  const open = useCallback(
    ({
      handlers: newHandlers,
      content: newContent,
    }: Omit<UpdateModalArgs<MH>, 'action'>) => {
      setState({
        action: ModalActions.open,
        content: newContent,
        handlers: newHandlers,
      });
    },
    [setState]
  );

  return {
    content,
    state,
    open,
    close,
    handlerWithClose,
    reset,
    isOpen: state === ModalStates.opened,
    isClosed: state === ModalStates.closed,
  };
};
