export const recursivelyFindIfParentIsSourceElement = ({
  htmlElement,
  sourceHtmlElement,
}: {
  htmlElement: HTMLElement | null;
  sourceHtmlElement: HTMLElement;
}): boolean => {
  if (!htmlElement) return false;

  if (htmlElement === sourceHtmlElement) return true;

  return recursivelyFindIfParentIsSourceElement({
    htmlElement: htmlElement.parentElement,
    sourceHtmlElement: sourceHtmlElement,
  });
};
