import { ComponentSourceData } from '@jux/data-entities';
import { InteractiveStateOptionsByTagName } from '@jux/ui/components/editor/components/panels/DDP/modules/props/sub-modules/interactiveState';
import { VariantsValues } from '@jux/types';
import { getCartesianVariants } from './cartesianProduct';

export type RunForEachVariantCombination = (
  variantValues: VariantsValues,
  state: string,
  variantStateKey: string
) => void;
/**
 * run Callback for each permutation of specific variant and state combination
 */
export const runForEachVariantStateCombination = (
  rootSourceComponentData: ComponentSourceData,
  callback: RunForEachVariantCombination
) => {
  const cartesianProductValues = getCartesianVariants(rootSourceComponentData);

  const elementStates =
    InteractiveStateOptionsByTagName[rootSourceComponentData.tagName] || [];

  for (const state of elementStates) {
    if (rootSourceComponentData.config.variants?.length === 0) {
      callback({}, state, `-${state}`);
      return;
    } else {
      for (const variantCombination of cartesianProductValues) {
        const variantValues: VariantsValues = {};
        for (let i = 0; i < variantCombination.length; i++) {
          variantValues[rootSourceComponentData.config.variants[i].variant] =
            variantCombination[i];
        }

        //combine all strings in variantCombination into one string
        const variantCombinationString =
          '-' + variantCombination.join('-') + `-${state}`;
        callback(variantValues, state, variantCombinationString);
      }
    }
  }
};
