/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';

const { styled } = createDrimz();

import { TopNavigation_item, type TopNavigation_item_Props } from '../';

type Nav_left_actions_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;

const Nav_left_actions_root: FC<Nav_left_actions_root_Props> = forwardRef(
  (props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />
);

Nav_left_actions_root.displayName = 'Nav_left_actions_root';

Nav_left_actions_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '0px 0px 0px 0px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF',
      },
    },
  }) as Nav_left_actions_root_Props['rootElement'],
};

export type Nav_left_actions_Props = Nav_left_actions_root_Props & {
  TopNavigation_item_1_Props?: TopNavigation_item_Props;
  TopNavigation_item_2_Props?: TopNavigation_item_Props;
  TopNavigation_item_3_Props?: TopNavigation_item_Props;
};

export const Nav_left_actions: FC<Nav_left_actions_Props> = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>,
  Nav_left_actions_Props
>(
  (
    {
      TopNavigation_item_1_Props,
      TopNavigation_item_2_Props,
      TopNavigation_item_3_Props,
      ...props
    },
    ref
  ) => (
    <Nav_left_actions_root
      {...props}
      ref={ref}
      data-jux-context="61b1bbda-0d93-46fe-88e6-e5b346440fa3"
    >
      {props.children || (
        <>
          <TopNavigation_item
            selected={'false'}
            {...TopNavigation_item_1_Props}
          />
          <TopNavigation_item
            selected={'false'}
            {...TopNavigation_item_2_Props}
          />
          <TopNavigation_item
            selected={'false'}
            {...TopNavigation_item_3_Props}
          />
        </>
      )}
    </Nav_left_actions_root>
  )
);

Nav_left_actions.displayName = 'Nav_left_actions';
Nav_left_actions.defaultProps = {};
