import {
  DDPActionsParams,
  JuxStore,
  JuxStoreActionFn,
} from '../store.interface';
import { NodeType } from '@jux/data-entities';

/**
 * Update a component variants editing context.
 */
export const updateComponentVariantsEditingContext: JuxStoreActionFn<
  DDPActionsParams['updateComponentVariantsEditingContext'],
  JuxStore
> = ({ nodeId, state }) => {
  const component = state.components[nodeId];
  if (!component) return state;

  if (
    component.type === NodeType.VARIANTS_GROUP ||
    component.type === NodeType.INSTANCE
  ) {
    return state;
  }

  // TODO: reimplement
  // if (!component.variantsEditingContext) {
  //   component.variantsEditingContext = {};
  // }
  // component.variantsEditingContext[propName] = propValue;

  return state;
};
