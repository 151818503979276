import { PaletteMode } from '@jux/ui/components/common/mui';
import { Colors } from './base-theme-colors';
import { DrimzPalette } from './base-theme-palette.interface';

export const drimzPalette = (
  colors: Colors,
  mode: PaletteMode
): DrimzPalette => ({
  mode,
  transparent: 'transparent',
  tooltip: {
    color: colors.tooltipTextColor,
    background: colors.tooltipBackground,
  },
  toast: {
    background: colors.toastBackground,
    text: colors.primaryText,
  },
  backdrop: colors.backdrop,
  background: {
    default: colors.primaryBackground,
    hover: colors.hoverBackground,
    secondary: colors.secondaryBackground,
    secondaryHover: colors.secondaryHoverBackground,
    navigation: colors.navigationBackground,
    selected: colors.selectedBackground,
    selectedHover: colors.selectedHoverBackground,
  },
  divider: {
    primary: colors.primaryBorder,
    secondary: colors.secondaryBorder,
    hover: colors.hoverBorder,
  },
  primary: {
    main: colors.primaryColor,
    contrastText: colors.contrastText,
  },
  secondary: {
    main: colors.secondaryText,
    contrastText: colors.contrastText,
  },
  success: {
    main: colors.success,
    contrastText: colors.contrastText,
  },
  error: {
    main: colors.error,
    contrastText: colors.contrastText,
  },
  info: {
    main: colors.info,
    contrastText: colors.contrastText,
  },
  warning: {
    main: colors.warning,
    contrastText: colors.contrastText,
  },
  text: {
    primary: colors.primaryText,
    secondary: colors.secondaryText,
    disabled: colors.disabledText,
    button: colors.buttonText,
  },
  transparencyPattern: {
    background: colors.transparencyPatternBackground,
  },
});
