import { atom, useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { DEFAULT_STATE } from '@jux/types';
import { NodeInteractiveState } from './useNodeInteractiveState.interface';
import { useCanvasLiveOrEdit } from './useCanvasLiveOrEdit';

const nodesInteractiveStateAtom = atom<
  Record<string, NodeInteractiveState | undefined>
>({});

export const useNodeInteractiveState = (nodeId?: string) => {
  const [nodesInteractiveState, setNodesInteractiveState] = useAtom(
    nodesInteractiveStateAtom
  );
  const { isLive } = useCanvasLiveOrEdit();

  const nodeInteractiveState = useMemo(
    () =>
      isLive || !nodeId
        ? undefined
        : nodesInteractiveState[nodeId] ?? DEFAULT_STATE,
    [isLive, nodeId, nodesInteractiveState]
  );

  const getNodeInteractiveStateById = useCallback(
    (nodeIdToFind?: string) => {
      if (!nodeIdToFind) return DEFAULT_STATE;

      return nodesInteractiveState[nodeIdToFind] ?? DEFAULT_STATE;
    },
    [nodesInteractiveState]
  );

  const setNodeInteractiveState = useCallback(
    (state: NodeInteractiveState) => {
      if (!nodeId) return;

      setNodesInteractiveState((prev) => ({
        ...prev,
        [nodeId]: state,
      }));
    },
    [nodeId, setNodesInteractiveState]
  );

  const resetNodeInteractiveState = useCallback(() => {
    if (!nodeId) return;

    setNodesInteractiveState(({ [nodeId]: nodeIdToRemove, ...rest }) => rest);
  }, [nodeId, setNodesInteractiveState]);

  return {
    nodeInteractiveState,
    getNodeInteractiveStateById,
    setNodeInteractiveState,
    resetNodeInteractiveState,
  };
};
