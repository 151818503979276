import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Organization } from '@jux/data-entities';
import DrimzAPI from '@jux/ui/lib/api';

export function useGetUserOrganizationListQuery() {
  return useQuery<Array<Organization>, AxiosError>(
    ['get-organizations-list'],
    () => DrimzAPI.get('/api/organizations').then((resp) => resp.data)
  );
}
