import {
  JuxStore,
  JuxStoreActionFn,
  TokenSetsActionsParams,
} from '@jux/canjux/core';
import { bulkUpsertTokenInputSchema } from '@jux/ui/trpc/validations';
import { bulkUpsertToken } from '../../helpers/token-sets/bulkUpsertToken';

export const setToken: JuxStoreActionFn<
  TokenSetsActionsParams['setToken'],
  JuxStore
> = ({ data, state, type }) => {
  state.tokenSets = bulkUpsertToken({
    tokenData: bulkUpsertTokenInputSchema.parse(data),
    tokenType: type,
    state,
  });

  return state;
};
