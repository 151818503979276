import { createSelector } from 'reselect';
import { CanjuxState } from '@jux/canjux/core';
//import { getNodesInRect } from '../helpers/getNodesInRect';

// const withTransformSteps = (
//   transform: Transform,
//   width: number,
//   height: number
// ): Transform => {
//   const roundedTransform = {
//     x: Math.ceil(transform.x / width) * width,
//     y: Math.ceil(transform.y / height) * height,
//     zoom:
//       transform.zoom > 1
//         ? Math.floor(transform.zoom * 100) / 100
//         : Math.ceil(transform.zoom * 100) / 100,
//   };
//
//   return roundedTransform;
// };

export const selectVisibleNodes = createSelector(
  [(s: CanjuxState) => s.canvases, (s: CanjuxState) => s.currentCanvasName],
  (canvases, currentCanvasName) => {
    return canvases[currentCanvasName].rootNodesOrder;

    // TODO: improve performance with this logic
    // const bufferX = width / 2; // half of the width
    // const bufferY = height / 2; // half of the height
    // return getNodesInRect({
    //   canvas,
    //   viewport: {
    //     x: -bufferX,
    //     y: -bufferY,
    //     width: width + bufferX * 2,
    //     height: height + bufferY * 2,
    //   }, // Entire screen with a buffer
    //   onlyRootNodes: true,
    //   nodeOrigin: { x: 0, y: 0 },
    //   transform: transform,
    // });
  }
);
