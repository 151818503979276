import { memo, MutableRefObject, useEffect, useRef } from 'react';

let intervalId = 0;
let frameId = 0;
let timeoutId = 0;

const nativeFpsCounter = (ref: MutableRefObject<HTMLDivElement | null>) => {
  if (typeof window === 'undefined') {
    return;
  }

  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  if (!ref.current) {
    timeoutId = window.setTimeout(() => {
      nativeFpsCounter(ref);
    });
    return;
  }

  const fpsEl = ref.current;

  let frameStart = 0;
  let frameCount = 0;
  let fps = 0;

  if (intervalId) {
    clearInterval(intervalId);
  }

  intervalId = window.setInterval(() => {
    fps = Math.floor((frameCount / (performance.now() - frameStart)) * 1000);
    fpsEl.innerHTML = `FPS: ${fps}`;
    fpsEl.style.color = fps > 50 ? 'green' : 'red';
    frameCount = 0;
    frameStart = performance.now();
  }, 1000);

  if (frameId) {
    cancelAnimationFrame(frameId);
  }

  const runFrame = () => {
    frameCount++;
    frameId = window.requestAnimationFrame(runFrame);
  };

  runFrame();
};

export const FpsCounter = memo(() => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    nativeFpsCounter(ref);
  }, []);

  return (
    <div
      ref={ref}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1000000,
        background: 'rgba(0, 0, 0, 0.8',
        color: 'lightsalmon',
        fontWeight: 'bold',
        height: '40px',
        width: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none',
      }}
    />
  );
});
