import { CommonActionsParams, JuxStoreActionFn } from '../store.interface';
import { CanjuxState } from '@jux/canjux/core';
import { validateAll } from '../validators';

/**
 * Repair Org data state
 */
export const repairState: JuxStoreActionFn<
  CommonActionsParams['repairState'],
  CanjuxState
> = ({ state }) => {
  validateAll({ state, recover: true });
  return state;
};
