export const isSiblingOrParentOfSelectedNodes = ({
  nodeSiblings,
  selectedNodes,
  nodeChildren,
}: {
  nodeSiblings: string[];
  selectedNodes: string[];
  nodeChildren: string[] | undefined;
}) => {
  const isSiblingSelected = nodeSiblings.some((siblingId) =>
    selectedNodes.includes(siblingId)
  );

  const isParentOfSelectedNode = selectedNodes.some((id) =>
    nodeChildren?.includes(id)
  );

  return isSiblingSelected || isParentOfSelectedNode;
};
