import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { CANVAS_ITEMS } from '@jux/ui/components/editor/hooks';
import { ComponentPreview } from './ComponentPreview';
import * as S from './ComponentPreviewItem.style';
import { ComponentPreviewItemActionsMenu } from './ComponentPreviewItemActionsMenu';
import { ComponentPreviewItemTooltip } from './componentPreviewItemTooltip/ComponentPreviewItemTooltip';
import { useComponentPreviewActions, useComponentPreviewItem } from './hooks';
import { ComponentPreviewItemProps } from './ComponentPreviewItem.interface';

const TEST_ID_COMPONENT_PREVIEW_ITEM = 'component-preview-item';

export const ComponentPreviewItem: FC<ComponentPreviewItemProps> = ({
  actions,
  componentId,
  component,
  loadingRenderer,
  previewPortalContainer,
  wrapperProps,
}) => {
  const {
    componentContainerRef,
    componentDragRef,
    componentPreviewItemWrapperRef,
    componentRectRef,
    isDragging,
    isPreviewItemHovered,
    handleClick,
    showLoading,
    originStyles,
    transformRatio,
  } = useComponentPreviewItem({
    component: {
      id: componentId,
      data: { ...component },
      displayName: component.displayName,
      type: CANVAS_ITEMS.Component,
    },
  });

  const { actionsMenuRef, isActionsMenuHovered } = useComponentPreviewActions();

  return (
    <ComponentPreviewItemTooltip
      displayName={component.displayName ?? ''}
      updatedAt={component.updatedAt}
      visible={isPreviewItemHovered && !isActionsMenuHovered}
      disabled={isDragging}
    >
      <S.ComponentPreviewItemWrapper
        isDragging={isDragging}
        onClick={handleClick}
        data-testid={TEST_ID_COMPONENT_PREVIEW_ITEM}
        {...wrapperProps}
      >
        <MuiGrid
          ref={componentPreviewItemWrapperRef}
          width="100%"
          height="100%"
          padding="8px"
        >
          {actions && (
            <MuiGrid
              ref={actionsMenuRef}
              display="contents"
              onClick={(e) => e.stopPropagation()}
            >
              <ComponentPreviewItemActionsMenu isVisible={isPreviewItemHovered}>
                {actions}
              </ComponentPreviewItemActionsMenu>
            </MuiGrid>
          )}
          <ComponentPreview
            ref={componentContainerRef}
            componentId={componentId}
            componentDragRef={componentDragRef}
            componentRectRef={componentRectRef}
            loadingRenderer={loadingRenderer}
            previewStyles={originStyles}
            previewPortalContainer={previewPortalContainer}
            showLoading={showLoading}
            transformRatio={transformRatio}
          />
        </MuiGrid>
      </S.ComponentPreviewItemWrapper>
    </ComponentPreviewItemTooltip>
  );
};
