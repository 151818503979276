import { AssetLayerIcon } from './AssetLayerIcon';
import { CheckboxLayerIcon } from './CheckboxLayerIcon';
import { ChipLayerIcon } from './ChipLayerIcon';
import { ContractIcon } from './ContractIcon';
import { DropArrowClosedIcon } from './DropArrowClosedIcon';
import { DropArrowOpenedIcon } from './DropArrowOpenedIcon';
import { ExpandIcon } from './ExpandIcon';
import { InstanceLayerIcon } from './InstanceLayerIcon';
import { HiddenLayerIcon } from './HiddenLayerIcon';
import { RadioLayerIcon } from './RadioLayerIcon';
import { SingleVariantLayerIcon } from './SingleVariantLayerIcon';
import { ToggleLayerIcon } from './ToggleLayerIcon';
import { TooltipLayerIcon } from './TooltipLayerIcon';
import { LogicalSlotLayerIcon } from './LogicalSlotLayerIcon';

export const layersCustomIcons = {
  AssetLayerIcon,
  CheckboxLayerIcon,
  ChipLayerIcon,
  ContractIcon,
  DropArrowClosedIcon,
  DropArrowOpenedIcon,
  ExpandIcon,
  InstanceLayerIcon,
  HiddenLayerIcon,
  RadioLayerIcon,
  SingleVariantLayerIcon,
  ToggleLayerIcon,
  TooltipLayerIcon,
  LogicalSlotLayerIcon,
};
