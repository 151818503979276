import {
  JuxStore,
  JuxStoreActionFn,
  TokenSetsActionsParams,
} from '@jux/canjux/core';
import { getCurrentTimestamp, namingPatternsSchema } from '@jux/design-tokens';
import cloneDeep from 'lodash/cloneDeep';
import { z } from 'zod';
import { getDesignTokensParserContext } from '../../../store-utils/getDesignTokensParserContext';

const designTokensParser = getDesignTokensParserContext();

export const renameGroup: JuxStoreActionFn<
  TokenSetsActionsParams['renameGroup'],
  JuxStore
> = ({ state, ...params }) => {
  const {
    path: groupPath,
    newName: groupName,
    oldName,
  } = z
    .object({
      newName: namingPatternsSchema,
      oldName: namingPatternsSchema,
      path: z.string(),
    })
    .parse(params);

  Object.values(state.tokenSets).forEach((tokenSet) => {
    tokenSet.value = designTokensParser
      .parse(cloneDeep(tokenSet.value))
      .setGroup({
        allowOverwrite: false, // we want to throw error if the new group name already exists in the containing group
        groupPath,
        groupName,
        oldName,
      })
      .getRawValueCopy();

    tokenSet.updatedAt = getCurrentTimestamp();
  });

  return state;
};
