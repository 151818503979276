import { SetStateAction, useEffect } from 'react';

// In case all the default data is not stored in the local storage,
// this hook will set the default data into the local storage
export const usePersistLocalStorageDataEffect = <T extends object>({
  key,
  defaultData,
  setData,
}: {
  key: string;
  defaultData: T;
  setData: (data: SetStateAction<T>) => void;
}) => {
  // setting the default data into the local storage
  useEffect(() => {
    const currentStoredData = localStorage.getItem(key);

    // if there is no data stored, set the default data
    if (!currentStoredData) {
      setData(defaultData);
      return;
    }

    // if the data stored is not the default data, set the prev data with the default data
    // to make sure there is no missing new data
    if (JSON.parse(currentStoredData) !== defaultData) {
      setData((prev) => ({
        ...defaultData,
        ...prev,
      }));
    }
  }, [defaultData, key, setData]);
};
