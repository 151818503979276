import { ClipboardEvent } from 'react';
import { formatTokenNamePaste } from './formatTokenNamePaste';

export const handleTokenNameInputPaste = (
  e: ClipboardEvent<HTMLInputElement>
) => {
  const { currentTarget } = e;

  const inputElement = currentTarget.getElementsByTagName('input')[0];

  formatTokenNamePaste(e, inputElement);
};
