import {
  CanjuxState,
  CommonActionsParams,
  JuxStoreActionFn,
} from '@jux/canjux/core';

import { setTransform } from './setTransform';

/**
 * Initialize D3 zoom helpers
 */
export const initD3Zoom: JuxStoreActionFn<
  CommonActionsParams['initD3Zoom'],
  CanjuxState
> = ({ d3Zoom, d3Selection, d3ZoomHandler, transform, state }) => {
  state.d3Zoom = d3Zoom;
  state.d3Selection = d3Selection;
  state.d3ZoomHandler = d3ZoomHandler;

  setTransform({ transform, state });

  return state;
};
