import {
  CommonActionsParams,
  JuxStore,
  JuxStoreActionFn,
} from '@jux/canjux/core';

/**
 * Set the currently worked on canvas
 */
export const setCurrentCanvasName: JuxStoreActionFn<
  CommonActionsParams['setCurrentCanvasName'],
  JuxStore
> = ({ canvasName, state }) => {
  if (state.currentCanvasName !== canvasName && canvasName in state.canvases) {
    state.currentCanvasName = canvasName;
  }

  return state;
};
