import { DDPFieldsStateKeys } from '@jux/ui/components/editor/state';
import {
  DimensionFieldOptions,
  UseDimensionFieldReturnProps,
} from '../useDimensionField/useDimensionField.interface';

export type DirectionalField = 'top' | 'right' | 'bottom' | 'left';

export const DirectionFields: { [key in DirectionalField]: key } = {
  top: 'top',
  right: 'right',
  bottom: 'bottom',
  left: 'left',
} as const;

export type DirectionFieldsValues = Record<DirectionalField, string>;

export type UseMultiDirectionFieldsProps = {
  value: any;
  initialValue: any;
  fieldName: Extract<DDPFieldsStateKeys, 'padding' | 'margin'>;
  saveChanges: (value: any) => void;
  dimensionFieldOptions?: DimensionFieldOptions;
};

export type UseMultiDirectionFieldsReturnProps = {
  rawAllDirectionsValue: string;
  parsedRawAllDirectionsValue: string; // this is for the future feature to show the actual value of the token

  allDirectionsField: UseDimensionFieldReturnProps;

  topField: UseDimensionFieldReturnProps;
  rightField: UseDimensionFieldReturnProps;
  bottomField: UseDimensionFieldReturnProps;
  leftField: UseDimensionFieldReturnProps;
  isComputed: boolean;
};
