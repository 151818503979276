import { PlaceholderMode, storeApi } from '@jux/canjux/core';
import { useSelectedNodeId } from '@jux/ui/components/editor/hooks';

export const useIsPlaceholderInputMode = () => {
  const selectedNodeId = useSelectedNodeId();
  if (!selectedNodeId) {
    return false;
  }
  const inputMode = storeApi.getState().placeholderMode[selectedNodeId];
  return inputMode === PlaceholderMode.placeholder;
};
