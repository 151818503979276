export enum ComponentDependencyType {
  Asset = 'asset',
  Component = 'component',
}

export type ComponentDependency = {
  name: string;
  id: string; // The node id this component depends on (should be an instance node)
  alias?: string; // The alias to use for the import
  type: ComponentDependencyType.Asset | ComponentDependencyType.Component;
};

export type ComponentFileStructure = {
  name: string;
  dependencies: ComponentDependency[];
  file: {
    name: string;
    content: string;
  };
};
