// eslint-disable-next-line @jux/must-import-FC-in-tsx-files
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const BoxShadowXDDPIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M11.7751 5.96967C11.4822 5.67678 11.0073 5.67678 10.7144 5.96967C10.4215 6.26256 10.4215 6.73744 10.7144 7.03033L10.9341 7.25H5.06066L5.28033 7.03033C5.57322 6.73744 5.57322 6.26256 5.28033 5.96967C4.98744 5.67678 4.51256 5.67678 4.21967 5.96967L2.71967 7.46967C2.42678 7.76256 2.42678 8.23744 2.71967 8.53033L4.21967 10.0303C4.51256 10.3232 4.98744 10.3232 5.28033 10.0303C5.57322 9.73744 5.57322 9.26256 5.28033 8.96967L5.06066 8.75H10.9341L10.7144 8.96967C10.4215 9.26256 10.4215 9.73744 10.7144 10.0303C11.0073 10.3232 11.4822 10.3232 11.7751 10.0303L13.2751 8.53033C13.568 8.23744 13.568 7.76256 13.2751 7.46967L11.7751 5.96967Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
