import { ComponentTagName } from '@jux/data-entities';
import { DefaultState, ElementState } from '@jux/types';
import { SelectChangeEvent } from '@jux/ui/components/common/mui';
import { NodeInteractiveState } from '@jux/ui/components/editor/hooks';

type InteractiveStateOptionsByTagNameType = Partial<
  Record<ComponentTagName, Array<NodeInteractiveState>>
>;

export const InteractiveStateOptionsByTagName: InteractiveStateOptionsByTagNameType =
  {
    JuxButton: ['default', 'hover', 'active'],
    JuxCheckbox: ['default', 'hover', 'active'],
    JuxCheckboxField: ['default', 'hover', 'active'],
    JuxChip: ['default', 'hover', 'active'],
    JuxDiv: ['default', 'hover', 'active'],
    JuxInput: ['default', 'hover', 'focus-visible'],
    JuxTextField: ['default', 'hover', 'focus-within'],
    JuxTextarea: ['default', 'hover', 'focus-visible'],
    JuxTextareaField: ['default', 'hover', 'focus-within'],
    JuxRadio: ['default', 'hover', 'active'],
    JuxSvg: ['default', 'hover', 'active'],
    JuxToggle: ['default', 'hover', 'active'],
  };

export type InteractiveStateProps = {
  nodeInteractiveState: ElementState | DefaultState | undefined;
  handleNodeInteractiveStateChange: (e: SelectChangeEvent) => void;
  options: Array<NodeInteractiveState>;
  forceRender?: boolean;
};
