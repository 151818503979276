import {
  JuxStore,
  JuxStoreActionFn,
  PropsActionsParams,
} from '../../store.interface';
import type { Draft as WritableDraft } from 'mutative';
import { ComponentConfigWithStates } from '@jux/types';
import {
  deletePropInAllInstances,
  updateContextStylesDeleteProp,
  updateStylesDeleteProp,
} from '../utils/deletePropUtils';
import { NodeType } from '@jux/data-entities';

/**
 * Delete a property of a component and fixes all instances in all canvases
 */
export const deleteComponentProp: JuxStoreActionFn<
  PropsActionsParams['deleteComponentProp'],
  JuxStore
> = ({ componentId, propName, state }) => {
  if (!componentId) return state;

  const component = state.components[componentId];
  if (!component) return state;

  switch (component.type) {
    case NodeType.ELEMENT:
    case NodeType.LOCAL_COMPONENT:
    case NodeType.LIBRARY_COMPONENT:
      const variantConfig = component.config.variants?.find(
        (variant) => variant.variant === propName
      );
      if (!variantConfig) {
        return state;
      }

      // remove variant from variant config
      component.config.variants = component.config.variants?.filter(
        (variant) => variant.variant !== propName
      );

      if (propName in component.config.props) {
        delete component.config.props[propName];
      }

      const styles =
        component.styles as WritableDraft<ComponentConfigWithStates>;
      if (styles.variants) {
        styles.variants = updateStylesDeleteProp({
          variantsStyles: styles.variants,
          propName: propName,
          defaultValue: variantConfig.defaultValue,
        });
      }

      // Go over state styles and do the same
      for (const stateStyles of Object.values(styles.states)) {
        if (!stateStyles.variants) {
          continue;
        }
        stateStyles.variants = updateStylesDeleteProp({
          variantsStyles: stateStyles.variants,
          propName: propName,
          defaultValue: variantConfig.defaultValue,
        });
      }

      if (styles.contextStyles) {
        styles.contextStyles = updateContextStylesDeleteProp({
          contextStyles: styles.contextStyles,
          propName: propName,
          defaultValue: variantConfig.defaultValue,
        });
      }

      deletePropInAllInstances({
        componentId,
        propName,
        components: state.components,
      });

      component.updatedAt = new Date().getTime();

      break;
    case NodeType.INSTANCE:
      throw new Error('Cannot delete prop value for component instance');
  }

  return state;
};
