import { createSelector } from 'reselect';
import { CanjuxState } from '@jux/canjux/core';
import { getSelectedNodeComponent } from './utils';

export const selectedNodeComponent = createSelector(
  [
    (state: CanjuxState) => state.components,
    (state: CanjuxState) => state.selectedNodesStack,
  ],
  (components, selectedNodesStack) =>
    getSelectedNodeComponent({ components, selectedNodesStack })
);
