import {
  CanjuxState,
  CommonActionsParams,
  JuxStoreActionFn,
  selectOrgComponentsNames,
  setLayersData,
} from '@jux/canjux/core';
import { JuxComponentData, NodeData, NodeType } from '@jux/data-entities';
import { generateName } from '@jux/ui/utils/generateName';
import type { Draft as WritableDraft } from 'mutative';
import { addStorageNode } from '../../store.changes.utils';
import { addInstanceNodes } from './utils';

export const createNodeInternal = ({
  canvasName,
  data,
  nodeId,
  state,
}: {
  canvasName: string;
  nodeId: string;
  data: {
    component?: JuxComponentData;
    node?: NodeData;
    targetIndex?: number;
  };
  state: WritableDraft<CanjuxState>;
}) => {
  if (
    data.node &&
    // Can't create a node if it already exists (update it instead)
    !state.canvases[canvasName].nodes[nodeId]
  ) {
    // Add the new node to the canvas
    state.canvases[canvasName].nodes[nodeId] = data.node;
  }

  if (
    data.component &&
    // Can't create a component if it already exists (update it instead)
    !state.components[nodeId]
  ) {
    const component = data.component;

    // Add the new component to the store
    if (
      component.type === NodeType.LOCAL_COMPONENT ||
      component.type === NodeType.LIBRARY_COMPONENT
    ) {
      component.displayName = generateName({
        baseName: component.displayName,
        namesArray: selectOrgComponentsNames(state),
        options: {
          formatters: ['formatCase', 'removeIllegalChars'],
        },
      });
    }

    state.components[nodeId] = component;

    const parentId = component.parentId;

    // Root node - add it to the canvas's nodes order list
    if (!parentId) {
      state.canvases[canvasName].rootNodesOrder.unshift(nodeId);
    }

    // Nested node - add it to the parent's children list
    if (parentId) {
      const parent = state.components[parentId];
      if (!parent.children.includes(nodeId)) {
        const targetIndex =
          data?.targetIndex !== undefined
            ? data.targetIndex
            : parent.children.length;

        addStorageNode(parent.children, nodeId, targetIndex);

        addInstanceNodes({
          sourceNodeId: nodeId,
          state,
          targetIndex,
          targetNodeId: parentId,
        });
      }
    }
  }
};

/**
 * Create a new node and component data.
 */
export const createNode: JuxStoreActionFn<
  CommonActionsParams['createNode'],
  CanjuxState
> = ({ canvasName, data, nodeId, state }) => {
  createNodeInternal({ canvasName, data, nodeId, state });

  setLayersData(state);

  return state;
};
