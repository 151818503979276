import SuperJSON from 'superjson';
import { AUTH0_UNAUTHORIZED_ERROR } from '@jux/ui/trpc/client/helpers/constants';

/**
 * Override deserialization to handle errors from Auth0
 * This is necessary because trpcNext.createNextApiHandler is
 * wrapped in withApiAuthRequired - which throws an error when
 * the user is not authenticated.
 * This error would otherwise not be handled by properly by tRPC
 * which handles it as a different error ("Badly formatted response")
 */
// create a new instance to get a fresh copy of the original deserialize method
const { deserialize } = new SuperJSON();
SuperJSON.deserialize = (data) => {
  if (typeof data === 'string' && data === AUTH0_UNAUTHORIZED_ERROR) {
    // return expected error shape to prevent tRPC from throwing a different error
    return {
      code: 401,
      message: data,
    } as never;
  } else {
    // call the instance method to prevent infinite recursion
    return deserialize(data);
  }
};

export default SuperJSON;
