import * as uuid from 'uuid';
import { StylesObject } from '@jux/types';
import { COMPONENT_TAG_NAME, NodeType } from '@jux/data-entities';
import { ElementDataInit } from '../types/ElementDataInit';
import { Colors } from '../common/colors';

export const SVG_ELEMENT_DEFAULT_DISPLAY_NAME = 'svg';

const SVG_ELEMENT_DEFAULT_ROOT_STYLES: StylesObject = {
  display: 'inline-block',
  color: Colors.Neutrals_1000,
};

export const getJuxSvgElementData: ElementDataInit<{
  sourceComponentId: string;
  rootStyles?: StylesObject;
  displayName?: string;
}> = (
  {
    sourceComponentId,
    contextParentId = uuid.v4(),
    rootStyles = {},
    displayName = SVG_ELEMENT_DEFAULT_DISPLAY_NAME,
    parentId,
  } = {
    sourceComponentId: '',
    parentId: '',
  }
) => {
  const rootSvgId = uuid.v4();

  return {
    root: {
      type: NodeType.ELEMENT,
      tagName: COMPONENT_TAG_NAME.JuxSvg,
      id: rootSvgId,
      sourceComponentId,
      parentId,
      styles: {
        root: {
          ...SVG_ELEMENT_DEFAULT_ROOT_STYLES,
          ...rootStyles,
        },
        states: {},
      },
      scopes: [],
      children: [],
      config: {
        contextId: rootSvgId,
        contextParentId,
        variants: [],
        props: {},
      },
      displayName,
    },
  };
};
