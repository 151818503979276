import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const REORDER_HOOK_KEY = 'reorder-layers-panel';

export const useReorderLayers = () => {
  const hoveredOnRef = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: REORDER_HOOK_KEY,
  });
  const [, drag, dragPreviewRef] = useDrag({
    type: REORDER_HOOK_KEY,
  });

  drag(drop(hoveredOnRef));

  return { hoveredOnRef, dragPreviewRef };
};
