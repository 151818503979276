import { useEffect } from 'react';
import { storeApi } from '@jux/canjux/core';

export const useEnterRoomEffect = (roomId: string | undefined) => {
  useEffect(() => {
    if (!roomId) return;

    storeApi.getState().liveblocks.enterRoom(roomId);
  }, [roomId]);
};
