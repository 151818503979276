import { useCallback } from 'react';
import { WebfontFamily } from '@jux/data-entities';

const loadFontsInContext = (
  fonts: WebfontFamily[] | undefined,
  contextWindow?: Window // used for loading fonts in the iframe, if not provided, it will load the fonts in the main window
) => {
  // webfonts is attaching itself to window object, and we don't have access to it when importing like any other library
  // So load it here
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const webfonts = require('webfontloader');

  if (!fonts) {
    return;
  }

  const loadedFonts: Array<string> = [];

  fonts.forEach((font) => {
    try {
      // webfonts.load is safe to call multiple times, it will create a <link> tag for each font family
      // and should not re-download the font if it's already loaded
      webfonts.load({
        google: {
          families: [`${font.family}:100,200,300,400,500,600,700,800,900`],
        },
        context: contextWindow,
      });

      loadedFonts.push(font.family);
    } catch (error) {
      throw Error(`Failed to load font: ${font.family}. Error: ${error}`);
    }
  });

  return loadedFonts;
};

export const useLoadFonts = (supportedFonts: WebfontFamily[] | undefined) => {
  const loadFonts = useCallback(
    (contextWindow?: Window) =>
      loadFontsInContext(supportedFonts, contextWindow),
    [supportedFonts]
  );

  return { loadFonts };
};
