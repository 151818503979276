import {
  CanjuxState,
  CommonActionsParams,
  JuxStoreActionFn,
  setLayersData,
} from '@jux/canjux/core';

/**
 * Reset hovered nodes
 */
export const resetHoveredNodes: JuxStoreActionFn<
  CommonActionsParams['resetHoveredNodes'],
  CanjuxState
> = ({ state }) => {
  if (state.hoveredNodesStack.length > 0) {
    state.hoveredNodesStack = [];
    setLayersData(state);
  }

  return state;
};
