import { useCallback } from 'react';
import { XYCoord } from 'react-dnd';
import {
  getDefaultNodePropertiesByTagName,
  useCurrentCanvasName,
  useStoreActions,
} from '@jux/canjux/core';
import {
  ComponentSourceData,
  ComponentTagNames,
  XYPosition,
} from '@jux/data-entities';
import { ElementComponentData, ELEMENTS_DATA } from '@jux/elements';
import { useTrackEvents } from '@jux/ui/hooks';
import {
  CanvasAssetItem,
  CanvasItemValue,
  isCanvasItemAsset,
  isCanvasItemComponent,
} from './useCanvasItem.interface';

type AddCanvasElementProps = {
  component: ElementComponentData;
  dropPosition: XYPosition;
  parentId?: string | null;
};

type AddCanvasInstanceProps = {
  componentId: string;
  position: XYPosition;
  parentId?: string | null;
};

export const useAddCanvasItem = () => {
  const canvasName = useCurrentCanvasName();
  const {
    commonActions: {
      createNodeWithChildren,
      createComponentInstanceNodeAction,
    },
  } = useStoreActions();

  const { trackObjectAddedToCanvasEvent } = useTrackEvents();

  const addCanvasInstanceNode = useCallback(
    (props: AddCanvasInstanceProps) => {
      createComponentInstanceNodeAction({
        canvasName: canvasName,
        componentId: props.componentId,
        position: props.position,
      });
    },
    [canvasName, createComponentInstanceNodeAction]
  );

  const addCanvasElementNode = useCallback(
    (props: AddCanvasElementProps) => {
      createNodeWithChildren({
        canvasName: canvasName,
        component: props.component.root as ComponentSourceData,
        subComponents: props.component.subComponents,
        node: {
          position: props.dropPosition,
          properties: getDefaultNodePropertiesByTagName({
            tagName: props.component.root.tagName,
            type: props.component.root.type,
          }),
        },
        isSelected: true,
      });
    },
    [canvasName, createNodeWithChildren]
  );

  const addAssetToCanvas = useCallback(
    ({
      item: { assetData, assetId },
      dropPosition,
    }: {
      item: CanvasAssetItem;
      dropPosition: XYCoord;
    }) => {
      if (!assetData) return;

      const createSvgElement = ELEMENTS_DATA[ComponentTagNames.JuxSvg];

      const SvgElement = createSvgElement({
        sourceComponentId: assetId,
        rootStyles: assetData.initialStyles,
        displayName: assetData.name,
      });

      addCanvasElementNode({
        dropPosition,
        component: SvgElement,
      });
    },
    [addCanvasElementNode]
  );

  const addItemToCanvas = useCallback(
    ({
      item,
      dropPosition,
    }: {
      item: CanvasItemValue;
      dropPosition: XYCoord;
    }) => {
      if (isCanvasItemAsset(item)) {
        addAssetToCanvas({ item, dropPosition });

        trackObjectAddedToCanvasEvent({
          tagName: ComponentTagNames.JuxSvg,
          componentUUID: item.assetId,
        });
        return;
      }

      if (isCanvasItemComponent(item)) {
        // Creating an instance of the component
        addCanvasInstanceNode({
          componentId: item.id,
          position: { x: dropPosition.x, y: dropPosition.y },
        });

        trackObjectAddedToCanvasEvent({
          componentUUID: item.id,
          tagName: item.data.tagName,
        });

        return;
      }

      // at the moment it will not suppose to get to this point - and will stop on the first condition
      // but due to typescript limitation, we need to add this check
      if (item.tagName === ComponentTagNames.JuxSvg) return;

      // Creating an element
      const createElement =
        ELEMENTS_DATA[
          item.tagName as Exclude<
            typeof item.tagName,
            // TODO: Remove this when we have all the ELEMENTS_DATA
            'JuxCheckboxPrimitive' | 'JuxLabelTrigger'
          >
        ];
      if (!createElement) {
        return;
      }

      const component: ElementComponentData = createElement(item.props);
      const date = new Date().getTime();
      component.root.createdAt = date;
      component.root.updatedAt = date;

      addCanvasElementNode({ component, dropPosition });

      trackObjectAddedToCanvasEvent({
        tagName: component.root.tagName,
        componentUUID: component.root.id,
      });
    },
    [
      addAssetToCanvas,
      addCanvasElementNode,
      addCanvasInstanceNode,
      trackObjectAddedToCanvasEvent,
    ]
  );

  return { addItemToCanvas };
};
