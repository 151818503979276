import { FC } from 'react';
import { Icons, Typography } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { getTokenDrawerBlacklistAreaAttributes } from '@jux/ui/components/tokens/token-drawer/utils/getTokenDrawerBlacklistAreaAttributes';

const TOKEN_CARD_WIDTH = 250;
const TOKEN_CARD_HEIGHT = 80;

export const CREATE_TOKEN_CARD_CLASS_NAME = 'create-token-card';

export const CreateNewTokenCard: FC<{
  onClick: () => void;
}> = ({ onClick }) => (
  <MuiGrid
    className={CREATE_TOKEN_CARD_CLASS_NAME} // this is used to make the CreateNewButton hover work
    onClick={onClick}
    item
    container
    sx={{
      width: TOKEN_CARD_WIDTH,
      height: TOKEN_CARD_HEIGHT,
      border: 1,
      borderColor: (theme) => theme.drimz.palette.divider.primary,
      borderRadius: (theme) => theme.drimz.size.borderRadius.main,
      padding: '16px',
      alignItems: 'flex-start',
      cursor: 'pointer',
      color: (theme) => theme.drimz.palette.text.disabled,
      transition: (theme) => theme.drimz.transitions.all,

      '& p': {
        transition: (theme) => theme.drimz.transitions.all,
      },

      '&:hover': {
        borderColor: (theme) => theme.drimz.palette.divider.primary,
        color: (theme) => theme.drimz.palette.text.primary,

        '& p': {
          color: (theme) => theme.drimz.palette.text.primary,
        },
      },
    }}
    {...getTokenDrawerBlacklistAreaAttributes()}
  >
    <MuiGrid
      sx={{
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
      }}
    >
      <Icons variant="ADD" />
      <Typography
        variant="createActionButtonTypography"
        color={(theme) => theme.drimz.palette.text.disabled}
      >
        New token
      </Typography>
    </MuiGrid>
  </MuiGrid>
);
