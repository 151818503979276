import {
  ComponentTagName,
  ComponentTagNames,
  NodeProperties,
  NodeType,
} from '@jux/data-entities';

export const getDefaultNodePropertiesByTagName = ({
  tagName,
  type,
}: {
  tagName?: ComponentTagName;
  type?: NodeType;
}): NodeProperties => {
  const isContainer =
    (type === NodeType.ELEMENT ||
      type === NodeType.LOCAL_COMPONENT ||
      type === NodeType.LIBRARY_COMPONENT ||
      type === NodeType.LOGICAL_SLOT) &&
    tagName !== ComponentTagNames.JuxText &&
    tagName !== ComponentTagNames.JuxInput &&
    tagName !== ComponentTagNames.JuxSvg;

  return {
    isContainer,
    isDeletable: type !== NodeType.LOGICAL_SLOT,
    isDraggable: true,
    isDragged: false,
    isHidden: false,
    isSelectable: true,
  };
};
