import { FC, useCallback } from 'react';
import { useDimensionField } from '../../../../hooks';
import { DimensionField } from '../../../../base';
import { useShadowsFields } from './useShadowsFields';

export const ShadowSpreadField: FC = () => {
  const { value, initialValue, saveChanges } = useShadowsFields();
  const { spreadRadius } = value || {};
  const { spreadRadius: initialSpreadRadius } = initialValue || {};

  const setFieldValue = useCallback(
    (newValue: string | undefined) => {
      saveChanges({ spreadRadius: newValue });
    },
    [saveChanges]
  );

  const props = useDimensionField({
    value: spreadRadius ?? '',
    initialValue: initialSpreadRadius ?? '',
    saveChanges: setFieldValue,
    fieldName: 'boxShadowSpread',
    options: {
      supportNegativeValue: true,
    },
  });

  return (
    <DimensionField
      icon="DDP_FIELD_BOX_SHADOW_SPREAD"
      tooltipTitle="Spread"
      {...props}
    />
  );
};
