// css-shorthand-parser does not have a typescript definition file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import shorthandParser from 'css-shorthand-parser';
import { DDPFieldsStateKeys } from '@jux/ui/components/editor/state';
import { DEFAULT_DIMENSION_VALUE } from '../useDimensionField';
import {
  DirectionFieldsValues,
  DirectionFields,
  DirectionalField,
} from './useMultiDirectionFields.interface';

export const createDirectionString = ({
  top,
  right,
  bottom,
  left,
}: DirectionFieldsValues) => `${top} ${right} ${bottom} ${left}`;

// this is the css-shorthand-parser way to parse the shorthand string
// eg: paddingTop => padding-top
const formatDirectionalFieldKey = (
  fieldKey: DDPFieldsStateKeys,
  directionKey: DirectionalField
) => {
  const HYPHEN = '-';
  return `${fieldKey?.toLocaleLowerCase()}${HYPHEN}${directionKey?.toLocaleLowerCase()}`;
};

const parseShorthandToDirectionValues = (
  key: DDPFieldsStateKeys,
  shorthand: { [key: string]: string }
): DirectionFieldsValues => {
  const top = shorthand[formatDirectionalFieldKey(key, DirectionFields.top)];
  const right =
    shorthand[formatDirectionalFieldKey(key, DirectionFields.right)];
  const bottom =
    shorthand[formatDirectionalFieldKey(key, DirectionFields.bottom)];
  const left = shorthand[formatDirectionalFieldKey(key, DirectionFields.left)];

  return {
    top: top || DEFAULT_DIMENSION_VALUE,
    right: right || DEFAULT_DIMENSION_VALUE,
    bottom: bottom || DEFAULT_DIMENSION_VALUE,
    left: left || DEFAULT_DIMENSION_VALUE,
  };
};

export const parseDirectionalShorthand = (
  key: DDPFieldsStateKeys,
  value: string
) => {
  if (!value)
    return {
      value: DEFAULT_DIMENSION_VALUE,
      top: DEFAULT_DIMENSION_VALUE,
      right: DEFAULT_DIMENSION_VALUE,
      bottom: DEFAULT_DIMENSION_VALUE,
      left: DEFAULT_DIMENSION_VALUE,
      isComputed: true,
    };

  const parsedShorthand = shorthandParser(key, value);
  const { top, right, bottom, left } = parseShorthandToDirectionValues(
    key,
    parsedShorthand
  );

  return {
    value,
    top,
    right,
    bottom,
    left,
    isComputed: false,
  };
};

export const parseFromStrToMulti = (value: string) => {
  const values = value.split(' ');
  const [top, right, bottom, left] = values;

  if (values.length === 1) {
    return {
      top,
      right: top,
      bottom: top,
      left: top,
    };
  }

  return {
    top: top || DEFAULT_DIMENSION_VALUE,
    right: right || DEFAULT_DIMENSION_VALUE,
    bottom: bottom || DEFAULT_DIMENSION_VALUE,
    left: left || DEFAULT_DIMENSION_VALUE,
  };
};

export const parseFromMultiToStr = (value: {
  top: string;
  right: string;
  bottom: string;
  left: string;
}) =>
  `${value.top || DEFAULT_DIMENSION_VALUE} ${
    value.right || DEFAULT_DIMENSION_VALUE
  } ${value.bottom || DEFAULT_DIMENSION_VALUE} ${
    value.left || DEFAULT_DIMENSION_VALUE
  }`;
