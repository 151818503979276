import { useCallback } from 'react';
import {
  selectedNodeComponent,
  useStore,
  useStoreActions,
} from '@jux/canjux/core';
import { useTrackEvents } from '@jux/ui/hooks';
import { useSelectedNodeId } from '@jux/ui/components/editor/hooks';
import { NodeType } from '@jux/data-entities';
import { generatePropValueName } from '../utils';

export const useHandleAddPropValue = () => {
  const {
    propsActions: { addComponentPropValue },
  } = useStoreActions();

  const selectedNodeData = useStore(selectedNodeComponent);
  const selectedNodeId = useSelectedNodeId();

  const { trackPropValueCreatedEvent } = useTrackEvents();

  const addPropValue = useCallback(
    (propName: string, allCurrentValues: Array<string>) => {
      if (
        !selectedNodeId ||
        !selectedNodeData ||
        (selectedNodeData.type !== NodeType.LIBRARY_COMPONENT &&
          selectedNodeData.type !== NodeType.LOCAL_COMPONENT)
      )
        return;

      const newPropValue = generatePropValueName(allCurrentValues);

      addComponentPropValue({
        componentId: selectedNodeId,
        propName,
        propValue: newPropValue,
      });

      trackPropValueCreatedEvent({
        componentUUID: selectedNodeData.id,
        tagName: selectedNodeData.tagName,
        propName,
        newPropValue,
      });
    },
    [
      addComponentPropValue,
      selectedNodeData,
      selectedNodeId,
      trackPropValueCreatedEvent,
    ]
  );

  return {
    handleAddPropValue: addPropValue,
  };
};
