import { forwardRef } from 'react';
import { Svg } from '@jux/react';
import { TooltipTypography, Icons } from '@jux/ui/components';
import { MuiGrid, MuiGridProps, useTheme } from '@jux/ui/components/common/mui';
import { AssetData } from '@jux/data-entities';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';

// This component mocks the current select field UI in the DDP
export const CustomAssetSelect = forwardRef<
  HTMLDivElement,
  MuiGridProps & { selectedAssetData: AssetData | undefined; isOpen: boolean }
>(({ selectedAssetData, isOpen, sx, ...props }, ref) => {
  const theme = useTheme();

  return (
    <MuiGrid
      ref={ref}
      sx={mergeTyped(
        {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '4px',
          padding: '8px',
          border: '1px solid',
          borderColor: theme.drimz.palette.divider.secondary,
          minWidth: '100%',
          width: '100%',
          margin: 0,
          height: theme.drimz.size.editor.ddp.selectField.height,
          backgroundColor: theme.drimz.palette.background.default,
          borderRadius: theme.drimz.size.borderRadius.small,
          cursor: 'pointer',
          position: 'relative',
        },
        sx
      )}
      {...props}
    >
      <MuiGrid
        item
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '4px',
          width: 'calc(100% - 20px)',
        }}
      >
        {selectedAssetData && selectedAssetData.content && (
          <>
            <MuiGrid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '16px',
                height: '16px',
              }}
            >
              <Svg
                content={selectedAssetData.content}
                style={{
                  width: '100%',
                  height: '100%',
                  color: theme.drimz.palette.text.primary,
                }}
              />
            </MuiGrid>

            <MuiGrid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                maxWidth: 'calc(100% - 16px)',
                userSelect: 'none',
              }}
            >
              <TooltipTypography
                variant="label-s-regular"
                color={theme.drimz.palette.text.primary}
                flex={1}
              >
                {selectedAssetData.name}
              </TooltipTypography>
            </MuiGrid>
          </>
        )}
      </MuiGrid>
      <MuiGrid
        item
        sx={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          right: '0',
        }}
      >
        <Icons
          variant="MUI_DROPDOWN_ARROW"
          size={24}
          color={theme.drimz.palette.text.secondary}
          sx={{
            width: '30px',
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        />
      </MuiGrid>
    </MuiGrid>
  );
});
