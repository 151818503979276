import {
  CanjuxState,
  Dimensions,
  HoveredNodesStack,
  LineType,
  NodeChange,
  PROP_EDITING_MODE_ANY,
  SelectionRect,
  SnappedPosition,
  Transform,
} from '@jux/canjux/core';
import {
  AssetData,
  CanvasData,
  ComponentConfigData,
  ComponentProp,
  ComponentSourceData,
  JuxComponentData,
  NodeData,
  NodeProperties,
  TokenSetData,
  XYPosition,
} from '@jux/data-entities';
import { SupportedTokenTypes } from '@jux/design-tokens';
import {
  ComponentConfigWithStates as StylesConfig,
  EnumPropValue,
} from '@jux/types';
import { bulkUpsertTokenInputSchema } from '@jux/ui/trpc/validations';
import type { Draft as WritableDraft } from 'mutative';
import { z } from 'zod';
import { Selection as D3Selection, ZoomBehavior } from 'd3';

export type CanvasNodesDimensions = {
  [nodeId: string]: Dimensions;
};

export type CanvasNodesIndicatorsPositions = {
  [nodeId: string]: {
    positionAbsolute: XYPosition;
  };
};

export type SourceComponentsList = ComponentSourceData[];

// Component data actions
export type AssetsActions = {
  createAsset: (params: { assetData: AssetData }) => void;
  deleteAsset: (params: { assetId: string }) => void;
  replaceAssetContent: (params: {
    newAssetId: string;
    componentId: string;
  }) => void;
};

// Component data actions
export type CommonActions = {
  pasteCopiedNodes: (params: {
    data: {
      componentId: string;
      propsOverrides?: ComponentConfigData['props'];
    }[];
  }) => void;

  repairState: () => void;

  createComponentInstanceNodeAction: (params: {
    canvasName: string;
    componentId: string;
    parentId?: string;
    position?: XYPosition; // The position of the new root node if no parentId is provided
    propsOverrides?: ComponentConfigData['props'];
    targetIndex?: number;
  }) => void;

  createNode: (params: {
    canvasName: string;
    data: {
      component?: JuxComponentData;
      node?: NodeData;
      targetIndex?: number;
    };
    nodeId: string;
  }) => void;

  createNodeWithChildren: (params: {
    canvasName: string;
    component: ComponentSourceData;
    /** determines if the created node should be selected or not */
    isSelected?: boolean;
    node?: NodeData;
    subComponents?: JuxComponentData[];
  }) => void;

  createTextNode: (params: {
    targetCanvasName: string;
    targetNodeIds?: string[];
    text: string;
  }) => void;

  deleteNode: (params: {
    canvasName?: string;
    deleteComponent?: boolean;
    isChildOfDeletedNode?: boolean;
    nodeId: string;
  }) => void;

  deleteSelectedNodes: () => void;

  moveNodes: (params: {
    sourceNodeIds: string[];
    targetIndex?: number;
    targetNodeId?: string | null | undefined;
    targetPosition?: SnappedPosition; // The position of the new root node if no targetNodeId is provided
  }) => void;

  resetHoveredNodes: () => void;

  resetSelectedNodes: () => void;

  setEditModeInNodeToolbarLabel: (params: {
    editable: boolean;
    nodeId: string;
  }) => void;

  setEditModeInTextNode: (params: {
    editable: boolean;
    nodeId: string;
  }) => void;

  setHoveredNode: (params: {
    affectChildren?: boolean;
    isHovered: boolean;
    lineType?: LineType;
    nodeId: string;
  }) => void;

  setNodeDraggingEnd: (params: { nodeId: string }) => void;

  setNodeDraggingStart: (params: { nodeId: string }) => void;

  setNodeDragStartingPoint: (params: {
    startPosition: { x: number; y: number } | null;
  }) => void;

  setImportedOldComponents: () => void;

  setSelectedNodes: (params: { nodeIds: string[]; append?: boolean }) => void;

  setUserSelection: (params: {
    nodesSelectionActive?: boolean;
    textNodeUserSelectionActive?: boolean;
    userSelectionActive?: boolean;
    userSelectionRect?: SelectionRect | null;
  }) => void;

  updateNodePosition: (params: {
    payload: {
      nodeId: string;
      position: XYPosition;
    }[];
  }) => void;

  updateNodePositionAndIntersections: (params: {
    changes: Array<NodeChange<'position'>>;
    intersectingNodes: Array<string>;
  }) => void;

  updateNodeProperties: (params: {
    canvasName: string;
    nodeId: string;
    properties: Partial<NodeProperties>;
  }) => void;

  updateNodesDimensions: (params: {
    payload: { nodeId: string; dimensions: Dimensions }[];
  }) => void;

  triggerLayersUpdate: (params: { nodeId?: string }) => void;

  updateNodesIndicatorsPosition: (params: {
    payload: {
      nodeId: string;
      position: XYPosition;
    }[];
  }) => void;

  wrapWithDiv: (params: { nodeIds: string[] }) => void;

  // Canvas Actions

  createCanvas: (params: { name: string }) => void;

  setCanvasName: (params: {
    originalCanvasName: string;
    newName: string;
  }) => void;

  setCurrentCanvasName: (params: { canvasName: string }) => void;

  deleteCanvas: (params: { canvasName: string }) => void;

  setTransform: (params: { transform: Transform }) => void;

  initD3Zoom: (params: {
    d3Zoom: ZoomBehavior<Element, unknown> | null;
    d3Selection: D3Selection<Element, unknown, null, undefined> | null;
    d3ZoomHandler:
      | ((this: Element, event: any, d: unknown) => void)
      | undefined;
    transform: Transform;
  }) => void;
};

export type DDPActions = {
  deleteComponentStyles: (params: {
    nodeId: string;
    styles: StylesConfig;
  }) => void;

  updateComponentStyles: (params: {
    nodeId: string;
    styles: StylesConfig;
  }) => void;

  setPlaceholderMode: (params: {
    nodeId: string;
    mode: PlaceholderModeValues;
  }) => void;

  updateComponentVariantsEditingContext: (params: {
    nodeId: string;
    propName: string;
    propValue: EnumPropValue | typeof PROP_EDITING_MODE_ANY;
  }) => void;
};

export type PropsActions = {
  addComponentPropValue: (params: {
    componentId: string;
    propName: string;
    propValue: ComponentProp['defaultValue'];
  }) => void;

  createComponentProp: (params: {
    componentId: string;
    propName: string;
    propValue: ComponentProp['defaultValue'];
  }) => void;

  deleteComponentProp: (params: {
    componentId: string;
    propName: string;
  }) => void;

  deleteComponentPropValue: (params: {
    componentId: string;
    newPropValue?: ComponentProp['defaultValue'];
    propName: string;
    propValueLabel: string;
  }) => void;

  renameComponentProp: (params: {
    componentId: string;
    newPropName: string;
    propName: string;
  }) => void;

  renameComponentPropValue: (params: {
    componentId: string;
    newPropValue: ComponentProp['defaultValue'];
    propName: string;
    propValueLabel: ComponentProp['defaultValue'];
  }) => void;

  setComponentPropValueChoice: (params: {
    componentId: string;
    propName: string;
    propValue: ComponentProp['defaultValue'];
  }) => void;
};

export type ComponentActions = {
  addComponentToLibrary: (params: { componentId: string }) => void;

  createComponentFromElement: (params: { nodeId: string }) => void;

  deleteComponent: (params: { componentId: string }) => void;

  restoreSourceComponent: (params: { instanceNodeId: string }) => void;

  updateComponentDisplayName: (params: {
    displayName: string;
    nodeId: string;
  }) => void;

  duplicateSelectedNodes: () => void;
};

export type TokenSetsActions = {
  addFirstTokenSetAfterCore: (params: { name: string }) => void;
  addGroup: (params: {
    description: string;
    groupName: string;
    groupPath: string;
  }) => void;
  addTokenSet: (params: { name: string; tokenSetId: string }) => void;
  deleteTokenOrGroup: (params: { path: string }) => void;
  deleteTokenSet: (params: { tokenSetId: string }) => void;
  initTokenSetsData: () => void;
  renameGroup: (params: {
    newName: string;
    oldName: string;
    path: string;
  }) => void;
  renameToken: (params: { newName: string; oldName: string }) => void;
  renameTokenSet: (params: { name: string; tokenSetId: string }) => void;
  setEditorTokenSet: (params: { tokenSetId: string }) => void;
  setToken: (params: {
    // TODO: Make this inferred according to the type param
    data: z.infer<typeof bulkUpsertTokenInputSchema>;
    type: SupportedTokenTypes;
  }) => void;
};

export const PlaceholderMode = {
  text: 'value',
  placeholder: 'placeholder',
} as const;

export type PlaceholderModeValues =
  typeof PlaceholderMode[keyof typeof PlaceholderMode];

// TODO: Check if we need to use nested LiveObject instances here
export type JuxStore = {
  assets: {
    [assetId: string]: AssetData;
  };

  /** All the canvases under a certain organization */
  canvases: {
    [canvasName: string]: CanvasData;
  };

  canvasNodesDimensions: CanvasNodesDimensions;

  canvasNodesIndicatorsPositions: CanvasNodesIndicatorsPositions;

  /** The current canvas being edited by the user */
  currentCanvasName: string;

  // Components/elements/instances data
  /** All the components under a certain organization */
  components: {
    [componentId: string]: JuxComponentData;
  };

  // Tokens data
  /** All the components under a certain organization */
  tokenSets: {
    [tokenSetName: string]: TokenSetData;
  };

  editorTokenSetId?: string;

  importedOldComponents?: boolean;
  importedOldTokens?: boolean;

  // DDP settings
  placeholderMode: Record<string, PlaceholderModeValues>;

  // Text editing data
  /** The list of nodes that the current user is editing. For example user edit a text node */
  textEditingNodeStack: string[];
  /** The identifier of the node that it׳s label is in edit mode */
  nodeIdLabelInEditMode?: string | null;

  /** Node selection/hovering data */
  autoSelectedNodeIdOnCreation?: string | null;
  hoveredNodesStack: HoveredNodesStack;
  selectedNodesStack: Array<string>;
  selectedNodesStackTimestamp: number;
  textNodeUserSelectionActive: boolean;

  // Node dragging data
  /**
   * The dragging start position of the node before node is being dragged.
   * This is used to calculate the distance between the current position and the start dragging position,
   * so we can determine the drag sensitivity.
   * The higher the distance between the start dragging position and current user position the lower the sensitivity.
   */
  draggedNodeStartPosition: { x: number; y: number } | null;
  dragNodeStarted: boolean;

  // Actions
  /**
   * Keep actions separate from the rest of the data
   * Create store action slices by feature
   * Separate actions by feature
   */
  assetsActions: AssetsActions;
  commonActions: CommonActions;
  componentActions: ComponentActions;
  ddpActions: DDPActions;
  propsActions: PropsActions;
  tokenSetsActions: TokenSetsActions;

  // TODO: reconsider whether this is the right approach,
  //  or if we should just add the layers stuff to the state
  layersRerenderTimestamp: number;

  nodesTriggeringLayersUpdate: Set<string>;
};

// Utility types
// Util type to get the params
export type StoreActionParams<Actions extends Record<string, any>> = {
  [ActionName in keyof Actions]: Parameters<Actions[ActionName]>[0];
};

export type JuxStoreActionFn<
  Params extends Record<string, any> | undefined,
  State extends JuxStore
> = Params extends undefined
  ? (params: { state: WritableDraft<State> }) => WritableDraft<State>
  : (params: Params & { state: WritableDraft<State> }) => WritableDraft<State>;

export type ActionsWithoutStateParam<
  T extends Record<string, JuxStoreActionFn<any, any>>
> = {
  [K in keyof T]: Parameters<T[K]>[0] extends {
    state: WritableDraft<CanjuxState>;
  }
    ? () => void
    : (params: Omit<Parameters<T[K]>[0], 'state'>) => void;
};

export type SetStateFn<State extends JuxStore> = (
  fn: (state: WritableDraft<State>) => void
) => void;

// Separate usage per actions type
export type CommonActionsParams = StoreActionParams<CommonActions>;
export type DDPActionsParams = StoreActionParams<DDPActions>;
export type ComponentActionsParams = StoreActionParams<ComponentActions>;
export type PropsActionsParams = StoreActionParams<PropsActions>;
export type AssetsActionsParams = StoreActionParams<AssetsActions>;
export type TokenSetsActionsParams = StoreActionParams<TokenSetsActions>;
