import { MuiGrid, styled } from '@jux/ui/components/common/mui';

export const FocusElementForTrap = styled(MuiGrid)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: 0,
  height: 0,
  opacity: 0,
  pointerEvents: 'none',
});
