import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { alpha } from '@jux/ui/utils/css';
import { MagnifierControlItem } from './MagnifierControlItem';
import { useMagnifier } from './useMagnifier';
import { MagnifierZoomView } from './MagnifierZoomView';

export const Magnifier: FC = () => {
  const {
    zoomPercentage,
    showControls,
    handleZoomIn,
    handleZoomOut,
    handleFitView,
    handleResetView,
    handleMouseEnter,
    handleMouseLeave,
  } = useMagnifier();

  return (
    <MuiGrid
      container
      sx={{
        borderRadius: (theme) => theme.drimz.size.borderRadius.main,
        transition: (theme) => theme.drimz.transitions.all,
        backgroundColor: (theme) =>
          showControls
            ? alpha(theme.drimz.palette.background.default, 0.9)
            : theme.drimz.palette.transparent,
        px: '10px',
        alignItems: 'center',
      }}
      gap="10px"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <MagnifierControlItem
        showControl={showControls}
        icon="FIT_VIEW"
        tooltipTitle="Fit view"
        onClick={handleFitView}
      />
      <MagnifierControlItem
        showControl={showControls}
        icon="RESET_VIEW"
        tooltipTitle="Reset view"
        onClick={handleResetView}
      />

      <MagnifierZoomView
        showControls={showControls}
        zoomPercentage={zoomPercentage}
      />

      <MagnifierControlItem
        showControl={showControls}
        icon="ZOOM_OUT_VIEW"
        tooltipTitle="Zoom out"
        onClick={handleZoomOut}
      />
      <MagnifierControlItem
        showControl={showControls}
        icon="ZOOM_IN_VIEW"
        tooltipTitle="Zoom in"
        onClick={handleZoomIn}
      />
    </MuiGrid>
  );
};
