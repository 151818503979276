import { useCallback } from 'react';
import { getTargetRectCenter, useStoreApi } from '@jux/canjux/core';

export const useGetTargetCenterPosition = () => {
  const storeApi = useStoreApi();
  const {
    width: paneWidth,
    height: paneHeight,
    transform,
  } = storeApi.getState();

  const getTargetCenterPositionByDimensions = useCallback(
    ({ width, height }: { width: number; height: number }) =>
      getTargetRectCenter(
        transform.zoom,
        {
          x: transform.x,
          y: transform.y,
          width: paneWidth,
          height: paneHeight,
        },
        {
          width,
          height,
        }
      ),
    [paneHeight, paneWidth, transform.x, transform.y, transform.zoom]
  );

  return {
    getTargetCenterPositionByDimensions,
  };
};
