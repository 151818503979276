import {
  ClipboardEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAutofocus } from '@jux/ui/hooks';
import { pasteTextOnly } from '@jux/ui/utils/pasteTextOnly';
import { deselectAllText, selectAllText } from '@jux/ui/utils/textSelections';
import {
  TextValidatorFunction,
  textValidators,
} from '@jux/ui/utils/textValidators';
import { TypographyElement } from '../typography';
import { handleMaxLength, isTypographyValid } from './EditableTypography.utils';

const MIN_VALID_TEXT_LENGTH = 1;

type UseEditableTypographyInput = {
  currentTypographyRef: RefObject<TypographyElement>;
  initialLabel: string;
  isInitialLabel?: boolean;
  customValidators?: Array<TextValidatorFunction>;
  autoFocus?: boolean;
  minLength?: number;
  maxLength?: number;
  onSaveChanges?: (newLabel: string) => boolean | Promise<boolean>;
  onDiscardChanges?: () => void;
  onKeyDown?: KeyboardEventHandler<TypographyElement>;
  onPaste?: ClipboardEventHandler<TypographyElement>;
  onBlur?: FocusEventHandler<TypographyElement>;
  onFocus?: FocusEventHandler<TypographyElement>;
};

export const useEditableTypography = ({
  initialLabel,
  onSaveChanges,
  isInitialLabel,
  customValidators,
  autoFocus,
  onDiscardChanges,
  onKeyDown,
  onPaste,
  onBlur,
  onFocus,
  minLength = MIN_VALID_TEXT_LENGTH,
  maxLength,
  currentTypographyRef,
}: UseEditableTypographyInput) => {
  const [currentLabel, setCurrentLabel] = useState(initialLabel);
  const [lastLabel, setLastLabel] = useState(initialLabel);
  const [isFocused, setIsFocused] = useState(false);

  const validators = useMemo(
    () => [
      textValidators.minValidLengthValidator({
        minLength,
      }),
      ...(customValidators || []),
      ...(maxLength
        ? [textValidators.maxValidLengthValidator({ maxLength })]
        : []),
    ],
    [customValidators, maxLength, minLength]
  );

  // we're using a ref to store the save changes state because need to access it in the handleOnBlur callback
  // which cannot receive other arguments, and we need to know if the user pressed enter or escape
  // without waiting for a state lifecycle (because the state is updated at the end of the lifecycle)
  // so we're using a ref to store the state
  const shouldSaveChangesOnBlur = useRef(true);

  // focus on mount if autoFocus is true
  useAutofocus({
    ref: currentTypographyRef,
    shouldAutoFocus: autoFocus,
  });

  const discardChanges = useCallback(() => {
    // we're using a ref to store the current label because we need to access it
    // in the handleOnBlur callback, which until the sendChanges is returned - the currentTarget is null
    if (!currentTypographyRef.current) return;

    currentTypographyRef.current.innerText = lastLabel;
    setCurrentLabel(lastLabel);
    onDiscardChanges?.();
    deselectAllText();
  }, [currentTypographyRef, lastLabel, onDiscardChanges]);

  const saveChanges = useCallback(
    async (text: string) => {
      const trimmedLabel = text.trim();

      // if the label didn't change, don't save
      if (!isInitialLabel && lastLabel === trimmedLabel) {
        return;
      }

      // if the label is not valid, revert to the last valid label
      if (!isTypographyValid(trimmedLabel, validators)) {
        discardChanges();
        return;
      }

      // send the new label to the parent
      const hasChangesSaved = onSaveChanges
        ? await onSaveChanges(trimmedLabel)
        : true;

      if (hasChangesSaved) {
        setCurrentLabel(trimmedLabel);
        setLastLabel(trimmedLabel);
      } else {
        discardChanges();
      }
    },
    [discardChanges, isInitialLabel, lastLabel, onSaveChanges, validators]
  );

  const handleKeyDown: KeyboardEventHandler<TypographyElement> = useCallback(
    async (e) => {
      const { code: keyCode, currentTarget: element } = e;

      switch (keyCode) {
        case 'Enter': {
          e.preventDefault();
          await saveChanges(element.innerText);

          shouldSaveChangesOnBlur.current = false;
          element.blur();
          setIsFocused(false);
          break;
        }
        case 'Escape': {
          e.preventDefault();
          discardChanges();

          shouldSaveChangesOnBlur.current = false;
          element.blur();
          setIsFocused(false);
          break;
        }
      }

      handleMaxLength(e, maxLength);

      onKeyDown?.(e);
    },
    [maxLength, onKeyDown, saveChanges, discardChanges]
  );

  const handleOnPaste: ClipboardEventHandler<TypographyElement> = useCallback(
    (e) => {
      const { clipboardData } = e;
      let text = clipboardData.getData('text/plain');
      const element = e.currentTarget;

      const currentTextLength = element.innerText.length;
      const newTextLength = text.length;
      const maxLengthExceeded =
        maxLength && currentTextLength + newTextLength > maxLength;

      if (maxLengthExceeded) {
        text = text.slice(0, maxLength);
      }

      e.preventDefault();
      pasteTextOnly(text);

      onPaste?.(e);
    },
    [maxLength, onPaste]
  );

  const handleOnFocus: FocusEventHandler<TypographyElement> = (e) => {
    setIsFocused(true);
    selectAllText(e.currentTarget);
    onFocus?.(e);
  };

  // blur is saving the changes by default unless the user pressed enter or escape (and updates the shouldSaveChangesOnBlur ref)
  const handleOnBlur: FocusEventHandler<TypographyElement> = useCallback(
    async (e) => {
      deselectAllText();

      const { target: element } = e;

      // scroll to the start of the element (when text is too long and we're using ellipsis we need to scroll to the start of the text to see the beginning of the text)
      element.scrollLeft = 0;

      // if the user pressed enter or escape we don't want to save the changes on blur
      if (!shouldSaveChangesOnBlur.current) {
        shouldSaveChangesOnBlur.current = true;
      } else {
        await saveChanges(element.innerText);
      }

      onBlur?.(e);

      // remove focus on Blur
      setIsFocused(false);
    },
    [onBlur, saveChanges]
  );

  // reset the current label and last label when the initial label changes
  useEffect(() => {
    setCurrentLabel(initialLabel);
    setLastLabel(initialLabel);
  }, [initialLabel]);

  return {
    currentLabel,
    handleKeyDown,
    handleOnFocus,
    handleOnBlur,
    handleOnPaste,
    isFocused,
  };
};
