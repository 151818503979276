export const parseGapValue = (value: string) => {
  const [row, column] = value?.split(' ') ?? [];

  // in case of single value, we will use it for both row and column
  // e.g. 10 => { rowGap: 10, columnGap: 10 }
  return {
    rowGap: row,
    columnGap: column ?? row,
  };
};

const ZERO = '0';
export const combineGapValue = ({
  rowGap,
  columnGap,
}: {
  rowGap: string | undefined;
  columnGap: string | undefined;
}) => {
  // in case both row and column are the same:
  //    we will use a single value
  //    e.g. { rowGap: 10, columnGap: 10 } => 10

  // in case row and column are different:
  //    in case row is undefined, we will use column value and row will be 0
  //    e.g. { rowGap: undefined, columnGap: 10 } => 0 10
  //
  //    in case column is undefined, we will use row value and column will be 0
  //    e.g. { rowGap: 10, columnGap: undefined } => 10 0
  //
  //    in case both exist, we will use both values
  //    e.g. { rowGap: 10, columnGap: 20 } => 10 20

  if (rowGap === columnGap) {
    return rowGap || ZERO;
  }

  return `${rowGap || ZERO} ${columnGap || ZERO}`;
};
