import {
  clamp,
  Dimensions,
  getPositionFromMouseEvent,
  getPositionOnCanjux,
  NodeChange,
  ResizeDragEvent,
  ResizePosition,
  useStoreActions,
  useStoreApi,
} from '@jux/canjux/core';
import { XYPosition } from '@jux/data-entities';
import { useSetMultiFieldsValues } from '@jux/ui/components/editor/components/panels/DDP/hooks/useSetMultiFieldsValues';
import { useTrackEvents } from '@jux/ui/hooks';
import { drag, select } from 'd3';
import { RefObject, useEffect, useRef } from 'react';
import {
  handleDragEnd,
  isControlPositionEnableX,
  isControlPositionEnableY,
  isControlPositionHorizontal,
  isControlPositionInvertX,
  isControlPositionInvertY,
  isControlPositionVertical,
} from './node-resizer.utils';

const initPrevValues = { width: 0, height: 0, x: 0, y: 0 };

const initStartValues = {
  ...initPrevValues,
  pointerX: 0,
  pointerY: 0,
  aspectRatio: 1,
};

export default function useResizerDrag({
  controlPosition,
  nodeDimensions,
  nodeId,
  nodePosition = { x: 0, y: 0 },
  resizeControlRef,
  enabled = true,
  keepAspectRatio = false,
  minWidth = 10,
  minHeight = 10,
  maxWidth = Number.MAX_VALUE,
  maxHeight = Number.MAX_VALUE,
}: {
  resizeControlRef: RefObject<HTMLDivElement>;
  controlPosition: ResizePosition;
  nodeId: string;
  nodeDimensions: Dimensions;
  nodePosition: XYPosition;

  cssWidth?: string;
  cssHeight?: string;

  enabled?: boolean;
  keepAspectRatio?: boolean; // Whether to keep the aspect ratio of the node when resizing
  minWidth?: number; // The minimum width user can resize to
  minHeight?: number; // The minimum height user can resize to
  maxWidth?: number; // The maximum width user can resize to
  maxHeight?: number; // The maximum height user can resize to
}) {
  const storeApi = useStoreApi();
  const { setMultiFieldsValuesForNodeId } = useSetMultiFieldsValues();
  const startValues = useRef<typeof initStartValues>(initStartValues);
  const prevValues = useRef<typeof initPrevValues>(initPrevValues);
  const {
    commonActions: { updateNodePosition },
  } = useStoreActions();
  const { trackComponentResize } = useTrackEvents();

  useEffect(() => {
    if (!resizeControlRef.current || !enabled) {
      return;
    }

    const selection = select(resizeControlRef.current);

    const enableX = isControlPositionEnableX(controlPosition);
    const enableY = isControlPositionEnableY(controlPosition);

    const invertX = isControlPositionInvertX(controlPosition);
    const invertY = isControlPositionInvertY(controlPosition);

    const dragHandler = drag<HTMLDivElement, unknown>()
      .on('start', (event: ResizeDragEvent) => {
        const { transform, snapToGrid, snapGrid } = storeApi.getState();
        const { xSnapped, ySnapped } = getPositionOnCanjux(
          getPositionFromMouseEvent(event.sourceEvent),
          transform,
          snapToGrid,
          snapGrid
        );

        prevValues.current = {
          width: nodeDimensions.width ?? 0,
          height: nodeDimensions.height ?? 0,
          x: nodePosition.x ?? 0,
          y: nodePosition.y ?? 0,
        };

        startValues.current = {
          ...prevValues.current,
          pointerX: xSnapped,
          pointerY: ySnapped,
          aspectRatio: prevValues.current.width / prevValues.current.height,
        };
      })
      .on('drag', (event: ResizeDragEvent) => {
        const { transform, snapToGrid, snapGrid } = storeApi.getState();
        const { xSnapped, ySnapped } = getPositionOnCanjux(
          getPositionFromMouseEvent(event.sourceEvent),
          transform,
          snapToGrid,
          snapGrid
        );

        if (nodeDimensions) {
          // We need to change node's position in case the user is dragging the top or left control
          const positionChange: NodeChange<'position'>[] = [];
          const {
            pointerX: startX,
            pointerY: startY,
            width: startWidth,
            height: startHeight,
            x: startNodeX,
            y: startNodeY,
            aspectRatio,
          } = startValues.current;

          const {
            x: prevX,
            y: prevY,
            width: prevWidth,
            height: prevHeight,
          } = prevValues.current;

          const distX = Math.floor(enableX ? xSnapped - startX : 0);
          const distY = Math.floor(enableY ? ySnapped - startY : 0);

          let width = clamp(
            startWidth + (invertX ? -distX : distX),
            minWidth,
            maxWidth
          );
          let height = clamp(
            startHeight + (invertY ? -distY : distY),
            minHeight,
            maxHeight
          );

          if (keepAspectRatio) {
            const nextAspectRatio = width / height;
            const isDiagonal = enableX && enableY;
            const isHorizontal = enableX && !enableY;
            const isVertical = enableY && !enableX;

            width =
              (nextAspectRatio <= aspectRatio && isDiagonal) || isVertical
                ? height * aspectRatio
                : width;
            height =
              (nextAspectRatio > aspectRatio && isDiagonal) || isHorizontal
                ? width / aspectRatio
                : height;

            if (width >= maxWidth) {
              width = maxWidth;
              height = maxWidth / aspectRatio;
            } else if (width <= minWidth) {
              width = minWidth;
              height = minWidth / aspectRatio;
            }

            if (height >= maxHeight) {
              height = maxHeight;
              width = maxHeight * aspectRatio;
            } else if (height <= minHeight) {
              height = minHeight;
              width = minHeight * aspectRatio;
            }
          }

          width = Math.round(width);
          height = Math.round(height);

          const isWidthChange = width !== prevWidth;
          const isHeightChange = height !== prevHeight;

          if (invertX || invertY) {
            const x = invertX ? startNodeX - (width - startWidth) : startNodeX;
            const y = invertY
              ? startNodeY - (height - startHeight)
              : startNodeY;

            // only transform the node if the width or height changes
            const isXPosChange = x !== prevX && isWidthChange;
            const isYPosChange = y !== prevY && isHeightChange;

            if (isXPosChange || isYPosChange) {
              const change: NodeChange<'position'> = {
                id: nodeId,
                type: 'position',
                data: {
                  position: {
                    x: isXPosChange ? x : prevX,
                    y: isYPosChange ? y : prevY,
                  },
                },
              };

              positionChange.push(change);
              prevValues.current.x = change.data.position.x;
              prevValues.current.y = change.data.position.y;
            }
          }

          if (isWidthChange || isHeightChange) {
            prevValues.current.width = width;
            prevValues.current.height = height;

            requestAnimationFrame(() => {
              setMultiFieldsValuesForNodeId(
                nodeId,
                isControlPositionHorizontal(controlPosition)
                  ? { width: `${width}px` }
                  : isControlPositionVertical(controlPosition)
                  ? { height: `${height}px` }
                  : {
                      width: `${width}px`,
                      height: `${height}px`,
                    }
              );
            });
          }

          // TODO: Fix bug when dragging node inside another node to the left (flex issue)
          if (positionChange.length) {
            requestAnimationFrame(() => {
              updateNodePosition({
                payload: positionChange.map(({ id, data: { position } }) => ({
                  nodeId: id,
                  position,
                })),
              });
            });
          }
        }
      })
      .on('end', () => handleDragEnd({ trackDragEnd: trackComponentResize }));

    selection.call(dragHandler);

    return () => {
      selection.on('.drag', null);
    };
  }, [
    controlPosition,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
    keepAspectRatio,
    resizeControlRef,
    setMultiFieldsValuesForNodeId,
    enabled,
    storeApi,
    nodeDimensions,
    nodeId,
    updateNodePosition,
    nodePosition.x,
    nodePosition.y,
    trackComponentResize,
  ]);
}
