import { CanjuxState, LineType, setLayersData } from '@jux/canjux/core';
import type { Draft as WritableDraft } from 'mutative';

/**
 * Set hovered node
 */
export const setHoveredNodeInternal = ({
  nodeId,
  isHovered,
  lineType = LineType.PARENT,
  affectChildren = false,
  state,
}: {
  nodeId: string;
  isHovered: boolean;
  lineType?: LineType;
  affectChildren?: boolean;
  state: WritableDraft<CanjuxState>;
}) => {
  const nodeChildren = state.components[nodeId].children;

  if (isHovered) {
    const hoveredNode = state.hoveredNodesStack.find((h) => h.id === nodeId);
    if (!hoveredNode) {
      // node is not currently hovered, set it to be hovered
      state.hoveredNodesStack.push({
        id: nodeId,
        type: lineType,
      });
    } else {
      hoveredNode.type = lineType;
    }

    if (affectChildren) {
      nodeChildren.forEach((child) => {
        setHoveredNodeInternal({
          nodeId: child,
          isHovered: true,
          lineType: LineType.CHILD,
          state,
          affectChildren: false, // only one level
        });
      });
    }
  } else {
    // Unset hovered
    state.hoveredNodesStack = state.hoveredNodesStack.filter(
      (n) => n.id !== nodeId
    );

    if (affectChildren) {
      nodeChildren.forEach((child) => {
        setHoveredNodeInternal({
          nodeId: child,
          isHovered: false,
          state,
          affectChildren: false, // only one level
        });
      });
    }
  }

  setLayersData(state);

  return state;
};
