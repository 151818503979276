import { ComponentProps, forwardRef } from 'react';
import { Typography, TypographyElement } from '@jux/ui/components';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';

export const OptionTypography = forwardRef<
  TypographyElement,
  Omit<ComponentProps<typeof Typography>, 'variant'>
>(({ children, sx, ...props }, ref) => (
  <Typography
    ref={ref}
    variant="unitOptionTypography"
    sx={mergeTyped(
      {
        minWidth: (theme) =>
          theme.drimz.size.editor.ddp.field.unit.option.width,
        height: (theme) => theme.drimz.size.editor.ddp.field.unit.option.height,
        color: (theme) => theme.drimz.palette.text.secondary,

        display: 'flex',
        padding: '2px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '2px',
        cursor: 'pointer',
        transition: (theme) => theme.drimz.transitions.all,

        backgroundColor: (theme) => theme.drimz.palette.transparent,
      },
      sx
    )}
    {...props}
  >
    {children}
  </Typography>
));
