import { FC } from 'react';
import { Tooltip, Typography } from '@jux/ui/components';
import { MuiMenuItem } from '@jux/ui/components/common/mui';
import { useDeleteAsset } from './useDeleteAsset';

export const DeleteAssetMenuItem: FC<{
  assetId: string;
}> = ({ assetId }) => {
  const { isDeletable, handleAssetDelete } = useDeleteAsset(assetId);

  return (
    <Tooltip
      multiline
      content={
        !isDeletable
          ? 'In order to delete, remove source component and instances from canvas'
          : undefined
      }
    >
      {/* Adding additional layer of div, so the tooltip can render this element */}
      <div>
        <MuiMenuItem
          disabled={!isDeletable}
          disableRipple
          onClick={handleAssetDelete}
        >
          <Typography variant="body-regular">Delete</Typography>
        </MuiMenuItem>
      </div>
    </Tooltip>
  );
};
