import { CollapsedModuleDDPIcon } from './CollapsedModuleDDPIcon';
import { ExpandedModuleDDPIcon } from './ExpandedModuleDDPIcon';
import { TokenDDPIcon } from './TokenDDPIcon';
import { DetachTokenDDPIcon } from './DetachTokenDDPIcon';
import { WidthDDPIcon } from './WidthDDPIcon';
import { MaxWidthDDPIcon } from './MaxWidthDDPIcon';
import { MinWidthDDPIcon } from './MinWidthDDPIcon';
import { HeightDDPIcon } from './HeightDDPIcon';
import { MinHeightDDPIcon } from './MinHeightDDPIcon';
import { MaxHeightDDPIcon } from './MaxHeightDDPIcon';
import { PaddingAllDDPIcon } from './PaddingAllDDPIcon';
import { PaddingTopDDPIcon } from './PaddingTopDDPIcon';
import { PaddingRightDDPIcon } from './PaddingRightDDPIcon';
import { PaddingBottomDDPIcon } from './PaddingBottomDDPIcon';
import { PaddingLeftDDPIcon } from './PaddingLeftDDPIcon';
import { MarginAllDDPIcon } from './MarginAllDDPIcon';
import { MarginTopDDPIcon } from './MarginTopDDPIcon';
import { MarginRightDDPIcon } from './MarginRightDDPIcon';
import { MarginBottomDDPIcon } from './MarginBottomDDPIcon';
import { MarginLeftDDPIcon } from './MarginLeftDDPIcon';
import { TypographyDDPIcon } from './TypographyDDPIcon';
import { FontFamilyDDPIcon } from './FontFamilyDDPIcon';
import { FontSizeDDPIcon } from './FontSizeDDPIcon';
import { FontWeightDDPIcon } from './FontWeightDDPIcon';
import { LetterSpacingDDPIcon } from './LetterSpacingDDPIcon';
import { LineHeightDDPIcon } from './LineHeightDDPIcon';
import { AlignCenterDDPIcon } from './alignment/AlignCenterDDPIcon';
import { AlignLeftDDPIcon } from './alignment/AlignLeftDDPIcon';
import { AlignRightDDPIcon } from './alignment/AlignRightDDPIcon';
import { AlignJustifyDDPIcon } from './alignment/AlignJustifiedDDPIcon';
import { LTRDirectionDDPIcon } from './alignment/LTRDirectionDDPIcon';
import { RTLDirectionDDPIcon } from './alignment/RTLDirectionDDPIcon';
import { UnifiedSidesSubModuleDDPIcon } from './UnifiedSidesSubModuleDDPIcon';
import { SeparatedSidesSubModuleDDPIcon } from './SeparatedSidesSubModuleDDPIcon';
import { TextColorDDPIcon } from './TextColorDDPIcon';
import { TextMixedColorDDPIcon } from './TextMixedColorDDPIcon';
import { FlexDirectionColumnDDPIcon } from './display/flex/FlexDirectionColumnDDPIcon';
import { FlexDirectionRowDDPIcon } from './display/flex/FlexDirectionRowDDPIcon';
import { FlexDirectionReverseDDPIcon } from './display/flex/FlexDirectionReverseDDPIcon';
import { FlexWrapDDPIcon } from './display/flex/FlexWrapDDPIcon';
import { AlignItemsFlexStartColumnDDPIcon } from './display/flex/alignItems/column/AlignItemsFlexStartColumnDDPIcon';
import { AlignItemsCenterColumnDDPIcon } from './display/flex/alignItems/column/AlignItemsCenterColumnDDPIcon';
import { AlignItemsFlexEndColumnDDPIcon } from './display/flex/alignItems/column/AlignItemsFlexEndColumnDDPIcon';
import { AlignItemsStretchColumnDDPIcon } from './display/flex/alignItems/column/AlignItemsStretchColumnDDPIcon';
import { AlignItemsBaselineColumnDDPIcon } from './display/flex/alignItems/column/AlignItemsBaselineColumnDDPIcon';
import { JustifyContentCenterColumnDDPIcon } from './display/flex/justifyContent/column/JustifyContentCenterColumnDDPIcon';
import { JustifyContentFlexEndColumnDDPIcon } from './display/flex/justifyContent/column/JustifyContentFlexEndColumnDDPIcon';
import { JustifyContentFlexStartColumnDDPIcon } from './display/flex/justifyContent/column/JustifyContentFlexStartColumnDDPIcon';
import { JustifyContentSpaceAroundColumnDDPIcon } from './display/flex/justifyContent/column/JustifyContentSpaceAroundColumnDDPIcon';
import { JustifyContentSpaceBetweenColumnDDPIcon } from './display/flex/justifyContent/column/JustifyContentSpaceBetweenColumnDDPIcon';
import { AlignItemsFlexStartRowDDPIcon } from './display/flex/alignItems/row/AlignItemsFlexStartRowDDPIcon';
import { AlignItemsCenterRowDDPIcon } from './display/flex/alignItems/row/AlignItemsCenterRowDDPIcon';
import { AlignItemsFlexEndRowDDPIcon } from './display/flex/alignItems/row/AlignItemsFlexEndRowDDPIcon';
import { AlignItemsStretchRowDDPIcon } from './display/flex/alignItems/row/AlignItemsStretchRowDDPIcon';
import { AlignItemsBaselineRowDDPIcon } from './display/flex/alignItems/row/AlignItemsBaselineRowDDPIcon';
import { JustifyContentCenterRowDDPIcon } from './display/flex/justifyContent/row/JustifyContentCenterRowDDPIcon';
import { JustifyContentFlexEndRowDDPIcon } from './display/flex/justifyContent/row/JustifyContentFlexEndRowDDPIcon';
import { JustifyContentFlexStartRowDDPIcon } from './display/flex/justifyContent/row/JustifyContentFlexStartRowDDPIcon';
import { JustifyContentSpaceAroundRowDDPIcon } from './display/flex/justifyContent/row/JustifyContentSpaceAroundRowDDPIcon';
import { JustifyContentSpaceBetweenRowDDPIcon } from './display/flex/justifyContent/row/JustifyContentSpaceBetweenRowDDPIcon';
import { ColumnGapDDPIcon } from './display/flex/ColumnGapDDPIcon';
import { RowGapDDPIcon } from './display/flex/RowGapDDPIcon';
import { PositionRelativeDDPIcon } from './position/PositionRelativeDDPIcon';
import { PositionStaticDDPIcon } from './position/PositionStaticDDPIcon';
import { PositionAbsoluteDDPIcon } from './position/PositionAbsoluteDDPIcon';
import { RelativeToDDPIcon } from './position/RelativeToDDPIcon';
import { ConstraintsTopDDPIcon } from './position/constraints/ConstraintsTopDDPIcon';
import { ConstraintsRightDDPIcon } from './position/constraints/ConstraintsRightDDPIcon';
import { ConstraintsBottomDDPIcon } from './position/constraints/ConstraintsBottomDDPIcon';
import { ConstraintsLeftDDPIcon } from './position/constraints/ConstraintsLeftDDPIcon';
import { ConstraintsAllDDPIcon } from './position/constraints/ConstraintsAllDDPIcon';
import { ZIndexDDPIcon } from './position/ZIndexDDPIcon';
import { BorderWidthDDPIcon } from './BorderWidthDDPIcon';
import { BorderStyleDDPIcon } from './BorderStyleDDPIcon';
import { BorderRadiusAllDDPIcon } from './BorderRadiusAllDDPIcon';
import { OpacityDDPIcon } from './OpacityDDPIcon';
import { NoneColorIndicatorDDPIcon } from './NoneColorIndicatorDDPIcon';
import { DisplayBlockDDPIcon } from './display/DisplayBlockDDPIcon';
import { DisplayFlexDDPIcon } from './display/DisplayFlexDDPIcon';
import { DisplayInlineDDPIcon } from './display/DisplayInlineDDPIcon';
import { DisplayInlineBlockDDPIcon } from './display/DisplayInlineBlockDDPIcon';
import { DisplayInlineFlexDDPIcon } from './display/DisplayInlineFlexDDPIcon';
import { DisplayNoneDDPIcon } from './display/DisplayNoneDDPIcon';
import { MuiSelectArrowDDPIcon } from './MuiSelectArrowDDPIcon';
import {
  BoxShadowSpreadDDPIcon,
  BoxShadowXDDPIcon,
  BoxShadowYDDPIcon,
  BoxShadowBlurDDPIcon,
  BoxShadowInsetTrueDDPIcon,
  BoxShadowInsetFalseDDPIcon,
  BoxShadowDDPIcon,
} from './shadow';

export const DDPIcons = {
  CollapsedModuleDDPIcon,
  ExpandedModuleDDPIcon,
  UnifiedSidesSubModuleDDPIcon,
  SeparatedSidesSubModuleDDPIcon,
  TokenDDPIcon,
  DetachTokenDDPIcon,
  MuiSelectArrowDDPIcon,

  WidthDDPIcon,
  MaxWidthDDPIcon,
  MinWidthDDPIcon,
  HeightDDPIcon,
  MinHeightDDPIcon,
  MaxHeightDDPIcon,
  PaddingAllDDPIcon,
  PaddingTopDDPIcon,
  PaddingRightDDPIcon,
  PaddingBottomDDPIcon,
  PaddingLeftDDPIcon,
  MarginAllDDPIcon,
  MarginTopDDPIcon,
  MarginRightDDPIcon,
  MarginBottomDDPIcon,
  MarginLeftDDPIcon,
  OpacityDDPIcon,
  TypographyDDPIcon,
  FontFamilyDDPIcon,
  FontSizeDDPIcon,
  FontWeightDDPIcon,
  LetterSpacingDDPIcon,
  LineHeightDDPIcon,
  AlignLeftDDPIcon,
  AlignCenterDDPIcon,
  AlignRightDDPIcon,
  AlignJustifyDDPIcon,
  TextColorDDPIcon,
  NoneColorIndicatorDDPIcon,
  TextMixedColorDDPIcon,
  LTRDirectionDDPIcon,
  RTLDirectionDDPIcon,
  FlexDirectionColumnDDPIcon,
  FlexDirectionRowDDPIcon,
  FlexDirectionReverseDDPIcon,
  FlexWrapDDPIcon,
  AlignItemsFlexStartColumnDDPIcon,
  AlignItemsCenterColumnDDPIcon,
  AlignItemsFlexEndColumnDDPIcon,
  AlignItemsStretchColumnDDPIcon,
  AlignItemsBaselineColumnDDPIcon,
  JustifyContentFlexStartColumnDDPIcon,
  JustifyContentCenterColumnDDPIcon,
  JustifyContentFlexEndColumnDDPIcon,
  JustifyContentSpaceAroundColumnDDPIcon,
  JustifyContentSpaceBetweenColumnDDPIcon,
  AlignItemsFlexStartRowDDPIcon,
  AlignItemsCenterRowDDPIcon,
  AlignItemsFlexEndRowDDPIcon,
  AlignItemsStretchRowDDPIcon,
  AlignItemsBaselineRowDDPIcon,
  JustifyContentFlexStartRowDDPIcon,
  JustifyContentCenterRowDDPIcon,
  JustifyContentFlexEndRowDDPIcon,
  JustifyContentSpaceAroundRowDDPIcon,
  JustifyContentSpaceBetweenRowDDPIcon,
  ColumnGapDDPIcon,
  RowGapDDPIcon,
  BorderStyleDDPIcon,
  BorderWidthDDPIcon,
  BorderRadiusAllDDPIcon,
  BoxShadowSpreadDDPIcon,
  BoxShadowXDDPIcon,
  BoxShadowYDDPIcon,
  BoxShadowBlurDDPIcon,
  BoxShadowInsetTrueDDPIcon,
  BoxShadowInsetFalseDDPIcon,
  BoxShadowDDPIcon,
  PositionRelativeDDPIcon,
  PositionStaticDDPIcon,
  PositionAbsoluteDDPIcon,
  ConstraintsTopDDPIcon,
  ConstraintsRightDDPIcon,
  ConstraintsBottomDDPIcon,
  ConstraintsLeftDDPIcon,
  ConstraintsAllDDPIcon,
  ZIndexDDPIcon,
  RelativeToDDPIcon,
  DisplayBlockDDPIcon,
  DisplayFlexDDPIcon,
  DisplayInlineDDPIcon,
  DisplayInlineBlockDDPIcon,
  DisplayInlineFlexDDPIcon,
  DisplayNoneDDPIcon,
};
