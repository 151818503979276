import { api } from '@jux/ui/trpc/client/api';

export const useGetCanvasDataQuery = (orgId: number) =>
  api.canvas.getOrCreateOnBoardingCanvas.useQuery(
    {
      orgId,
    },
    {
      enabled: Boolean(orgId),
    }
  );
