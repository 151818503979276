import {
  CanjuxState,
  CommonActionsParams,
  createComponentInstanceCanvasNode,
  JuxStoreActionFn,
  setLayersData,
} from '@jux/canjux/core';
import { setSelectedNodes } from './setSelectedNodes';

/**
 * Create a new canvas instance node from a source component
 */
export const createComponentInstanceNodeAction: JuxStoreActionFn<
  CommonActionsParams['createComponentInstanceNodeAction'],
  CanjuxState
> = ({
  canvasName,
  componentId,
  propsOverrides,
  parentId,
  targetIndex,
  position,
  state,
}) => {
  const instanceId = createComponentInstanceCanvasNode({
    canvasName,
    componentId,
    propsOverrides,
    parentId,
    targetIndex,
    position,
    state,
  });

  if (instanceId) {
    setSelectedNodes({
      nodeIds: [instanceId],
      state,
    });
  }

  setLayersData(state);

  return state;
};
