import { trimLowerCaseStr } from '@jux/ui/utils/trimLowerCaseStr';
import {
  SupportedJustifyContentValues,
  JustifyContentValue,
} from './JustifyContentField.interface';

export const parseJustifyContent = (
  value: string
): SupportedJustifyContentValues | undefined => {
  switch (trimLowerCaseStr(value)) {
    case 'flex-start':
    case 'start':
      return JustifyContentValue.flexStart;
    case 'center':
      return JustifyContentValue.center;
    case 'flex-end':
    case 'end':
      return JustifyContentValue.flexEnd;
    case 'space-around':
      return JustifyContentValue.spaceAround;
    case 'space-between':
      return JustifyContentValue.spaceBetween;
  }

  return undefined;
};
