/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const FolderIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M7.27639 3.55279C7.107 3.214 6.76074 3 6.38197 3H3C2.44772 3 2 3.44772 2 4V12C2 12.5523 2.44772 13 3 13H13C13.5523 13 14 12.5523 14 12V6C14 5.44772 13.5523 5 13 5H8.61803C8.23926 5 7.893 4.786 7.72361 4.44721L7.27639 3.55279Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
