import { MuiGrid, styled } from '@jux/ui/components/common/mui';
import {
  EDITOR_DDP_FIELD_HOVER_BACKGROUND,
  EDITOR_DDP_FIELD_UNIT_COLOR_OPEN,
  EDITOR_DDP_FIELD_UNIT_HOVER_OPEN,
} from '@jux/ui/theme/palette';
import { OptionTypography } from './OptionTypography';

export const HIGHLIGHTED_OPTION_STYLE = {
  color: EDITOR_DDP_FIELD_UNIT_COLOR_OPEN,
  backgroundColor: EDITOR_DDP_FIELD_UNIT_HOVER_OPEN,
};

export const UnitSelectionSelector = styled(OptionTypography, {
  shouldForwardProp: (prop) =>
    prop !== 'isOpen' &&
    prop !== 'fieldClassName' &&
    prop !== 'disabled' &&
    prop !== 'isFieldDisabled',
})<{
  isOpen: boolean;
  fieldClassName: string;
  disabled?: boolean;
  isFieldDisabled?: boolean;
}>(({ theme, isOpen, fieldClassName, disabled, isFieldDisabled }) => ({
  padding: '4px',
  width: theme.drimz.size.editor.ddp.field.unit.width,
  height: theme.drimz.size.editor.ddp.field.unit.height,
  color: isOpen
    ? EDITOR_DDP_FIELD_UNIT_COLOR_OPEN
    : theme.drimz.palette.text.secondary,
  backgroundColor: isOpen
    ? EDITOR_DDP_FIELD_HOVER_BACKGROUND
    : theme.drimz.palette.transparent,
  outline: 'none',

  cursor: disabled || isFieldDisabled ? 'default' : 'pointer',

  [`.${fieldClassName}:hover &`]: {
    backgroundColor: isFieldDisabled
      ? theme.drimz.palette.transparent
      : EDITOR_DDP_FIELD_HOVER_BACKGROUND,
  },

  [`.${fieldClassName}:focus-within &`]: {
    backgroundColor: isFieldDisabled
      ? theme.drimz.palette.transparent
      : EDITOR_DDP_FIELD_HOVER_BACKGROUND,

    [`&:focus-visible`]:
      !isFieldDisabled && !isOpen && HIGHLIGHTED_OPTION_STYLE,
  },
}));

export const OptionsWrapper = styled(MuiGrid)<{ hidden: boolean }>(
  ({ theme, hidden }) => ({
    position: 'absolute',
    zIndex: 10,
    top: theme.drimz.size.editor.ddp.field.unit.height,
    backgroundColor: EDITOR_DDP_FIELD_HOVER_BACKGROUND,
    borderRadius: '2px',
    padding: '2px',
    gap: '6px',
    display: hidden ? 'none' : 'flex',
    flexDirection: 'column',
  })
);
