import { useMemo } from 'react';
import {
  FieldsOptionsState,
  useDDPFieldsOptionsState,
} from '@jux/ui/components/editor/state';

export const useFieldOptions = <Key extends keyof FieldsOptionsState>(
  key: Key
) => {
  const { getFieldOptionStateByKey } = useDDPFieldsOptionsState();

  return useMemo(
    () => getFieldOptionStateByKey(key),
    [getFieldOptionStateByKey, key]
  );
};
