import { ItemBox } from '@jux/ui/components/common/ItemLayout.style';
import { MuiGrid, styled } from '@jux/ui/components/common/mui';

export const PreviewItemWrapper = styled(ItemBox)(({ theme }) => ({
  backgroundColor: theme.drimz.palette.tooltip.background,
  borderRadius: theme.drimz.size.borderRadius.main,
}));

export const DisplayNameContainer = styled(MuiGrid)(() => ({
  position: 'absolute',
  bottom: 6,
  left: 11,
}));
