import { ReactNode } from 'react';
import {
  TableBodyProps,
  TableCellProps,
  TableHeadProps,
  TableProps,
  TableRowProps,
} from '@jux/ui/components/common/mui';

export enum OrderByDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum OrderByFieldType {
  NUMBER = 'number',
  STRING = 'string',
  DATE = 'date',
}

type SupportedValueTypes = string | number | Date;

export type TableDataProps = {
  headers: Array<
    {
      header: ReactNode;
      orderBy?: {
        fieldType: OrderByFieldType;
        direction?: OrderByDirection;
      };
    } & TableCellProps
  >;
  rows: Array<
    {
      cells: Array<
        {
          cell: ReactNode;
          value?: SupportedValueTypes;
        } & TableCellProps
      >;
    } & TableRowProps
  >;
  tableHeadProps?: TableHeadProps;
  tableHeadRowProps?: TableRowProps;
  tableBodyProps?: TableBodyProps;
} & TableProps;
