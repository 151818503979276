import * as uuid from 'uuid';
import { ComponentConfigWithStates as StylesConfig } from '@jux/types';
import { ComponentTagNames, NodeType } from '@jux/data-entities';
import { ElementDataInit } from '../types/ElementDataInit';

export const LABEL_ELEMENT_DEFAULT_ROOT_STYLES: StylesConfig = {
  root: {
    width: 'auto',
    height: 'auto',
  },
  states: {},
};

export const LABEL_ELEMENT_DEFAULT_DISPLAY_NAME = 'label';

export const getJuxLabelElementData: ElementDataInit<{
  styles?: StylesConfig;
  children?: any;
  displayName?: string;
  labelForId?: string;
}> = ({
  id = uuid.v4(),
  styles = LABEL_ELEMENT_DEFAULT_ROOT_STYLES,
  children = [],
  displayName = LABEL_ELEMENT_DEFAULT_DISPLAY_NAME,
  parentId,
  labelForId,
} = {}) => {
  return {
    root: {
      tagName: ComponentTagNames.JuxLabelTrigger,
      type: NodeType.LOGICAL_SLOT,
      id,
      parentId,
      scopes: [],
      styles,
      config: {
        contextId: id,
        props: labelForId
          ? {
              for: labelForId,
            }
          : {},
        variants: [],
      },
      displayName,
      children, // only set id's not components because they are not supplied
    },
  };
};
