import {
  JUX_ASSET_ARROW_TO_RIGHT_ICON,
  JUX_ASSET_ARROW_TO_RIGHT_ICON_ID,
} from './arrowToRightIcon';
import {
  JUX_ASSET_CHECKED_ICON,
  JUX_ASSET_CHECKED_ICON_ID,
} from './checkedIcon';
import {
  JUX_ASSET_INDETERMINATE_ICON,
  JUX_ASSET_INDETERMINATE_ICON_ID,
} from './indeterminateIcon';
import { JUX_ASSET_LINK_ICON, JUX_ASSET_LINK_ICON_ID } from './linkIcon';
import { JUX_ASSET_IMAGE_ICON, JUX_ASSET_IMAGE_ICON_ID } from './imageIcon';
import { AssetData } from '@jux/data-entities';

export const systemAssets: Record<string, AssetData> = {
  [JUX_ASSET_ARROW_TO_RIGHT_ICON_ID]: JUX_ASSET_ARROW_TO_RIGHT_ICON,
  [JUX_ASSET_CHECKED_ICON_ID]: JUX_ASSET_CHECKED_ICON,
  [JUX_ASSET_INDETERMINATE_ICON_ID]: JUX_ASSET_INDETERMINATE_ICON,
  [JUX_ASSET_LINK_ICON_ID]: JUX_ASSET_LINK_ICON,
  [JUX_ASSET_IMAGE_ICON_ID]: JUX_ASSET_IMAGE_ICON,
};
