import { CurrentOrgThemeProvider } from '@jux/ui/components/editor/components/CurrentOrgThemeProvider';
import { forwardRef } from 'react';
import { CANJUX_MAIN_WRAPPER } from '../../constants';
import { useStoreUpdater } from '../../hooks';
import { infiniteExtent } from '../../store';
import type {
  CanjuxProps,
  CanjuxRefType,
  NodeOrigin,
  Viewport,
} from '../../types';
import { PanOnScrollMode } from '../../types';
import { CanvasView } from '../CanvasView';
import * as S from './Canjux.style';

const initNodeOrigin: NodeOrigin = { x: 0, y: 0 };
const initSnapGrid = { x: 15, y: 15 };
const initDefaultViewport: Viewport = { x: 0, y: 0, zoom: 1 };

export const Canjux = forwardRef<CanjuxRefType, CanjuxProps>(
  (
    {
      selectionOnDrag = true,
      snapToGrid = false,
      snapGrid = initSnapGrid,
      nodeOrigin = initNodeOrigin,
      defaultViewport = initDefaultViewport,
      minZoom = 0.5,
      maxZoom = 2,
      translateExtent = infiniteExtent,
      zoomOnScroll = false,
      zoomOnPinch = true,
      panOnScroll = true,
      panOnScrollSpeed = 0.5,
      panOnScrollMode = PanOnScrollMode.Free,
      zoomOnDoubleClick = false,
      children,
      fitView = false,
      autoPanOnNodeDrag = true,
      style,
      id,
      disableNodesInteraction,
      ...rest
    },
    ref
  ) => {
    useStoreUpdater({
      minZoom,
      maxZoom,
      snapGrid,
      snapToGrid,
      translateExtent,
      fitView,
      nodeOrigin,
      autoPanOnNodeDrag,
      disableNodesInteraction,
    });

    return (
      <S.CanjuxContainer
        {...rest}
        ref={ref}
        // TODO: remove jux class
        className={'jux'}
        id={CANJUX_MAIN_WRAPPER}
      >
        <CurrentOrgThemeProvider>
          <CanvasView
            selectionOnDrag={selectionOnDrag}
            defaultViewport={defaultViewport}
            translateExtent={translateExtent}
            minZoom={minZoom}
            maxZoom={maxZoom}
            zoomOnScroll={zoomOnScroll}
            zoomOnPinch={zoomOnPinch}
            zoomOnDoubleClick={zoomOnDoubleClick}
            panOnScroll={panOnScroll}
            panOnScrollSpeed={panOnScrollSpeed}
            panOnScrollMode={panOnScrollMode}
            nodeOrigin={nodeOrigin}
          />
        </CurrentOrgThemeProvider>
        {children}
      </S.CanjuxContainer>
    );
  }
);
