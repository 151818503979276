import { FC, useRef } from 'react';
import { Backdrop, FocusTrap } from '@jux/ui/components';
import { colorWithOpacity } from '@jux/ui/utils/colorWithOpacity';
import { MODAL_BOX_SHADOW_COLOR } from '@jux/ui/theme/palette';
import { Floors, useOnKeyEvent, useOverlaysZIndex } from '@jux/ui/hooks';
import { useModal } from '../base-hooks';
import * as S from './Modal.style';

export const Modal: FC = () => {
  const { isClosed, isOpen, content, close } = useModal();

  const modalRef = useRef<HTMLDivElement>(null);
  const zIndex = useOverlaysZIndex({
    id: 'modal',
    floor: Floors.modals,
  });

  useOnKeyEvent(modalRef, close, 'Escape');

  if (isClosed) return null;

  return (
    <FocusTrap>
      <S.Modal container ref={modalRef} zIndex={zIndex}>
        {content}
        <Backdrop
          open={isOpen}
          onClick={close}
          color={colorWithOpacity(MODAL_BOX_SHADOW_COLOR, 0.3)}
          zIndex={-1}
        />
      </S.Modal>
    </FocusTrap>
  );
};
