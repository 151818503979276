import { FC, memo } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { DDPContent } from './DDPContent';

export const DDP: FC = memo(() => (
  <MuiGrid
    sx={{
      height: '100%',
      width: '100%',
      backgroundColor: (theme) => theme.drimz.palette.background.navigation,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
  >
    <DDPContent />
  </MuiGrid>
));
