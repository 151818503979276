export type ModulesKeysType =
  | 'background'
  | 'border'
  | 'color'
  | 'context'
  | 'effects'
  | 'instance'
  | 'layout'
  | 'opacity'
  | 'props'
  | 'size'
  | 'text';

export const ModulesKeys: {
  [key in ModulesKeysType]: key;
} = {
  background: 'background',
  border: 'border',
  color: 'color',
  context: 'context',
  effects: 'effects',
  instance: 'instance',
  layout: 'layout',
  opacity: 'opacity',
  props: 'props',
  size: 'size',
  text: 'text',
} as const;
