import { styled } from '@jux/ui/components/common/mui';
import { EDITOR_DDP_CHILD_FIELDS_INACTIVE_VALUE_COLOR } from '@jux/ui/theme/palette';
import { typographyVariants } from '@jux/ui/components';

export const Input = styled('input', {
  shouldForwardProp: (prop) =>
    prop !== 'isComputed' &&
    prop !== 'isFieldDisabled' &&
    prop !== 'withAutoWidth',
})<{
  isComputed?: boolean;
  isFieldDisabled?: boolean;
  withAutoWidth?: boolean;
}>(({ theme, isComputed, isFieldDisabled, withAutoWidth = false }) => ({
  border: 'none',
  backgroundColor: theme.drimz.palette.transparent,
  outline: 'none',
  transition: theme.drimz.transitions.all,
  width: withAutoWidth ? 'auto' : theme.drimz.size.editor.ddp.field.input.width,
  color: isFieldDisabled
    ? EDITOR_DDP_CHILD_FIELDS_INACTIVE_VALUE_COLOR
    : isComputed
    ? theme.drimz.palette.text.secondary
    : theme.drimz.palette.text.primary,

  ...typographyVariants['label-s-medium'],
}));
