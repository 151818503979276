import { FC } from 'react';
import { LayersPanelItem } from '@jux/ui/components/editor/components/panels/layers/state/getLayersItemsRecursive';
import { useStoreActions } from '@jux/canjux/core';
import { LayersPanelInnerContainer } from '../layout';
import { ObjectNavigatorItems } from './items/ObjectNavigatorItems';
import { ObjectNavigatorHeader } from './ObjectNavigatorHeader';

export const ObjectNavigator: FC<{
  items: Map<string, LayersPanelItem>;
}> = ({ items }) => {
  const {
    commonActions: { resetSelectedNodes },
  } = useStoreActions();

  return (
    <LayersPanelInnerContainer onClick={resetSelectedNodes}>
      <ObjectNavigatorHeader items={items} />
      <ObjectNavigatorItems items={items} />
    </LayersPanelInnerContainer>
  );
};
