import { z } from 'zod';
import colorString from 'color-string';
import { ColorFieldValue } from '@jux/ui/components/editor/components/panels/DDP/base';
import { compareStrValues } from '@jux/ui/utils/compareStrValues';
import { toFirstUpperCase } from '@jux/ui/utils/toFirstUpperCase';

export const ValidColorTextValue = {
  none: 'none',
};

const FALLBACK_COLOR_VALUE: ColorFieldValue = {
  rgbaColor: '',
  hexValue: toFirstUpperCase(ValidColorTextValue.none),
  alpha: 100,
};

// schema is intentionally not restricted to any number of digits after the '.' in order to pass as valid input
// but will be saved with rounding to 2 digits after the dot.
const alphaRegexpString = '100|[1-9]?[0-9]([.][0-9]+)?%?';

export const DEFAULT_COLOR_CSS_VALUE = 'inherit';

export const isValidColorTextValue = (value: string) =>
  Object.values(ValidColorTextValue).some((t) => compareStrValues(value, t));

export const isNoneColorValue = (value: string | undefined) =>
  compareStrValues(value, ValidColorTextValue.none);

export const isDefaultColorValue = (value: string) =>
  compareStrValues(value, DEFAULT_COLOR_CSS_VALUE);

const noneRegex = `|${ValidColorTextValue.none}`;

// e.g none, rgba(243 5 95 / 100%)
export const colorFieldSchema = z
  .string()
  .regex(
    new RegExp(
      `^(rgba\\((\\d{1,3}\\s*){3}\\/\\s*${alphaRegexpString}\\)${noneRegex})$`,
      'i'
    )
  )
  .optional();

export const hexColorFieldSchema = z
  .string()
  .regex(/^([0-9A-Fa-f]{6})$/i)
  .optional();

export const alphaFieldSchema = z
  .string()
  .regex(new RegExp(`^${alphaRegexpString}$`))

  .optional();

export const validateColorField = (value: string) =>
  colorFieldSchema.safeParse(value);

/**
 * Parse the alpha value from the alpha field
 * @param alpha - e.g. 0-100% | 0-100
 * @returns number - e.g. 0-100
 */
export const parseAlpha = (alpha: string | number): number =>
  parseInt(String(alpha));

export const parseIntoField = (value: string): ColorFieldValue => {
  if (!value || isDefaultColorValue(value)) {
    return FALLBACK_COLOR_VALUE;
  }

  let parsedColor: colorString.Color;
  try {
    parsedColor = colorString.get.rgb(value);
  } catch (e) {
    return FALLBACK_COLOR_VALUE;
  }

  if (!parsedColor) {
    return FALLBACK_COLOR_VALUE;
  }

  const rgbaColor = `rgba(${parsedColor?.join(',')})`;
  const hexColor = colorString.to
    .hex(parsedColor?.slice(0, 3) || [])
    .toUpperCase();

  // getting the alpha value to display in the input endAdornment
  const a = parsedColor?.[3] ?? 1; // alpha value, default to 1
  const alpha = parseAlpha(a * 100);

  return {
    rgbaColor: rgbaColor,
    hexValue: hexColor,
    alpha: alpha,
  };
};

export const parseFieldValueToTokenValue = (parsedValue: ColorFieldValue) => {
  try {
    const [r, g, b] = colorString.get.rgb(parsedValue.rgbaColor);
    const opacity = parsedValue.alpha;

    return `rgba(${r} ${g} ${b} / ${opacity}%)`;
  } catch (e) {
    return '';
  }
};

/**
 * Compose RGBA string from hex and alpha values
 * @param hex - hex color value string (e.g. ABCDEF) - without the # sign
 * @param alpha - alpha value string (e.g. 0.01-1) - without the % sign
 */
export const composeRGBA = (hex: string, alpha: string) => {
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  const a = parseAlpha(alpha) / 100;

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

/**
 * Compose RGBA string from current RGBA string and new alpha value
 * @param currentRgbaColor (e.g. rgba(0,0,0,1))
 * @param newAlpha (e.g. 0.01-1)
 */
export const composeRgbaWithNewAlpha = (
  currentRgbaColor: string,
  newAlpha: number
): string => {
  const [r, g, b] = colorString.get.rgb(currentRgbaColor);
  const newAlphaDecimal = newAlpha / 100;
  const parsedColor = [r, g, b, newAlphaDecimal];

  return `rgba(${parsedColor?.join(',')})`;
};

/**
 * Get alpha value with % sign (e.g. 0%-100%)
 * @param alpha (0-100)
 */
export const parseAlphaWithPercentSign = (alpha: string | number) =>
  `${parseAlpha(alpha)}%`;
