import {
  COMPONENT_TAG_NAME,
  ComponentElementType,
  ComponentProps,
} from '@jux/data-entities';
import { forwardRef } from 'react';

export const Button = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxButton>,
  ComponentProps<typeof COMPONENT_TAG_NAME.JuxButton>
>(({ children, rootElement: RootElement = 'button', ...props }, ref) => (
  <RootElement ref={ref} {...props}>
    {children}
  </RootElement>
));
