import { FC } from 'react';
import { DimensionField } from '../../../../base';
import { useMarginFields } from './useMarginFields';

export const MarginBottomField: FC = () => {
  const { marginBottomField, isComputed } = useMarginFields();

  return (
    <DimensionField
      icon="DDP_FIELD_MARGIN_BOTTOM"
      tooltipTitle="Margin bottom"
      {...marginBottomField}
      isComputed={isComputed}
    />
  );
};
