import { KeyboardEvent, forwardRef } from 'react';
import { MuiTextField, useTheme } from '@jux/ui/components/common/mui';
import { typographyVariants } from '@jux/ui/components';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import {
  EDITOR_DDP_TEXT_FIELD_BASE_COLOR,
  EDITOR_DDP_TEXT_FIELD_INPUT_FOCUS_COLOR,
} from '@jux/ui/theme/palette';
import { TextFieldColors, TextFieldProps } from './TextField.interface';

const DEFAULT_HEIGHT = 28;

export const TextField = forwardRef<
  HTMLDivElement,
  TextFieldProps & {
    onEnter?: (e: KeyboardEvent) => void;
  }
>(({ name, sx, colors: customColors, height, onEnter, ...props }, ref) => {
  const theme = useTheme();

  const baseColors: TextFieldColors = {
    base: {
      label: EDITOR_DDP_TEXT_FIELD_BASE_COLOR,
      input: EDITOR_DDP_TEXT_FIELD_BASE_COLOR,
      border: theme.drimz.palette.background.secondary,
    },
    hover: {
      label: theme.drimz.palette.text.secondary,
      input: theme.drimz.palette.text.secondary,
      border: theme.drimz.palette.text.secondary,
    },
    focus: {
      label: theme.drimz.palette.text.secondary,
      input: EDITOR_DDP_TEXT_FIELD_INPUT_FOCUS_COLOR,
      border: theme.drimz.palette.text.secondary,
    },
    error: {
      label: theme.drimz.palette.error.main,
      input: theme.drimz.palette.error.main,
      border: theme.drimz.palette.error.main,
    },
    disabled: {
      label: theme.drimz.palette.text.secondary,
      input: theme.drimz.palette.text.secondary,
      border: theme.drimz.palette.background.secondary,
    },
  } as const;
  const colors: TextFieldColors = customColors
    ? mergeTyped(baseColors, customColors as TextFieldColors)
    : baseColors;

  return (
    <MuiTextField
      name={name}
      id={name}
      variant="outlined"
      sx={mergeTyped(
        {
          // LABEL
          '& .MuiFormLabel-root.MuiInputLabel-root': {
            ...typographyVariants['label-s-regular'],
            display: 'flex',
            alignItems: 'center',

            // default colors
            color: colors.base.label,

            // focused colors
            '&.Mui-focused': {
              color: colors.focus.label,
            },
            // error colors
            '&.Mui-error': {
              color: colors.error.label + ' !important',
            },
            // error colors
            '&.Mui-disabled': {
              color: colors.disabled.label,
            },
          },
          '&:hover': {
            '& .MuiFormLabel-root.MuiInputLabel-root': {
              '&:not(.Mui-disabled):not(.Mui-error)': {
                color: colors.hover.label,
              },
            },
          },

          // INPUT
          '& .MuiOutlinedInput-root': {
            ...typographyVariants['label-s-regular'],
            height: height ?? DEFAULT_HEIGHT,

            // base colors
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.base.border,
              borderRadius: theme.drimz.size.borderRadius.small,
            },
            '& .MuiInputBase-input': {
              color: colors.base.input,
            },
            // disabled colors
            '&.Mui-disabled': {
              '& input.MuiOutlinedInput-input, & .MuiOutlinedInput-notchedOutline':
                {
                  color: colors.disabled.input,
                  WebkitTextFillColor: colors.disabled.input,
                  borderColor: colors.disabled.border,
                },
            },
            // hover colors
            '&:hover': {
              '&:not(.Mui-disabled):not(.Mui-error)': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.hover.border,
                },
                '& .MuiInputBase-input': {
                  color: colors.hover.input,
                },
              },
            },
            // error colors
            '&.Mui-error': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.error.border,
              },
              '& .MuiInputBase-input': {
                color: colors.error.input + ' !important',
              },
            },
            // focused colors
            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.focus.border,
              },
              '& .MuiInputBase-input': {
                color: colors.focus.input,
              },
            },
          },
          // helper text
          '& .MuiFormHelperText-root': {
            mt: 1,

            // error colors
            '&.Mui-error': {
              color: colors.error.input,
            },
          },
          // label position when not focused and not filled
          '& .MuiInputLabel-outlined.MuiFormLabel-root.MuiInputLabel-root': {
            '&:not(.Mui-focused):not(.MuiFormLabel-filled)': {
              transform: 'translate(8px, 50%) scale(1)',
            },
          },
        },
        sx
      )}
      {...props}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onEnter?.(e);
        }
      }}
      ref={ref}
    />
  );
});
