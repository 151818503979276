import { useCallback } from 'react';
import { canjuxElementSelector } from '@jux/canjux/core';

type Dimensions = {
  width?: number;
  height?: number;
};

const DEFAULT_DIMENSIONS: Dimensions = {};

export const useGetNodeDimensions = () => ({
  getNodeDimensions: useCallback((id: string): Dimensions => {
    if (!id) {
      return DEFAULT_DIMENSIONS;
    }

    const selector = canjuxElementSelector(id);
    const element = document.querySelector(selector);

    if (
      element?.clientWidth === undefined ||
      element?.clientHeight === undefined
    ) {
      return DEFAULT_DIMENSIONS;
    }

    return {
      width: element.clientWidth,
      height: element.clientHeight,
    };
  }, []),
});
