/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const AlignItemsBaselineColumnDDPIcon: IconType = (
  props: IconBaseProps
) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M8.5 14C8.77614 14 9 13.7761 9 13.5V11L12 11C12.5523 11 13 10.5523 13 10C13 9.44772 12.5523 9 12 9L9 9V7H12C12.5523 7 13 6.55228 13 6C13 5.44772 12.5523 5 12 5L9 5V2.5C9 2.22386 8.77614 2 8.5 2C8.22386 2 8 2.22386 8 2.5V5L4 5C3.44772 5 3 5.44772 3 6C3 6.55229 3.44772 7 4 7H8V9C7.44771 9 7 9.44772 7 10C7 10.5523 7.44772 11 8 11L8 13.5C8 13.7761 8.22386 14 8.5 14Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
