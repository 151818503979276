import {
  calcAutoPan,
  CANJUX_MAIN_WRAPPER,
  extractPathToRoot,
  getPositionFromMouseEvent,
  getPositionOnCanjux,
  isEditableTextNodeExistInPosition,
  isNodeSelected,
  NodeChange,
  NodeDragItems,
  selectNodeChildren,
  selectNodeParent,
  selectNodeProperties,
  selectNodeSiblings,
  selectSelectedNodeIds,
  storeApi,
  type UseDragEvent,
  useStoreActions,
} from '@jux/canjux/core';
import { XYPosition } from '@jux/data-entities';
import { drag, pointer, select } from 'd3';
import {
  MouseEvent,
  MouseEventHandler,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { getIntersectingNode } from '../../../store/wip/helpers/getIntersectingNode';
import { useSelectCurrentNode } from '../behaviors/selection/useSelectCurrentNode';
import { MINIMUM_DISTANCE_TO_START_NODE_DRAG } from '../utils/constants';
import { getDistance } from '../utils/getDistance';
import { getDragItems } from '../utils/getDragItems';
import { getSnapAdjustedPositionIfEnabled } from '../utils/getSnapAdjustedPositionIfEnabled';
import { isDirectSelection } from '../utils/isDirectSelection';
import { isMultiSelection } from '../utils/isMultiSelection';
import { isSiblingOrParentOfSelectedNodes } from '../utils/isSiblingOrParentOfSelectedNodes';

const getMainWrapperElement = () =>
  document.getElementById(CANJUX_MAIN_WRAPPER);

export const useNodeDrag = ({
  elementRef,
  nodeId,
  onClick,
}: {
  elementRef: RefObject<HTMLElement>;
  nodeId: string;
  onClick?: MouseEventHandler;
}) => {
  const element = elementRef.current;

  const isDragging = useRef(false);
  const isSelecting = useRef(false);
  const dragItems = useRef<NodeDragItems>({});
  const lastPos = useRef<XYPosition>({ x: 0, y: 0 });
  const containerBounds = useRef<DOMRect | null>(null);
  const autoPanId = useRef(0);
  const intersectingNodes = useRef<Array<string>>([]);
  const autoPanStarted = useRef(false);
  // This is the position of the mouse relative to jux__wrapper container
  const mousePosition = useRef<XYPosition>({
    x: 0,
    y: 0,
  });

  const {
    commonActions: {
      moveNodes,
      setNodeDragStartingPoint,
      setNodeDraggingEnd,
      setNodeDraggingStart,
      setSelectedNodes,
      updateNodePositionAndIntersections,
    },
  } = useStoreActions();

  const { selectCurrentNode } = useSelectCurrentNode({ nodeId });

  const getNodeProperties = useCallback(
    () => selectNodeProperties(nodeId)(storeApi.getState()),
    [nodeId]
  );
  const getNodeChildren = useCallback(
    () => selectNodeChildren(nodeId)(storeApi.getState()),
    [nodeId]
  );
  const getNodeParent = useCallback(
    () => selectNodeParent(nodeId)(storeApi.getState()),
    [nodeId]
  );
  const getIsNodeSelected = useCallback(
    () => isNodeSelected(nodeId)(storeApi.getState()),
    [nodeId]
  );
  const getNodeSiblings = useCallback(
    () => selectNodeSiblings(nodeId)(storeApi.getState()),
    [nodeId]
  );
  const getSelectedNodes = useCallback(
    () => selectSelectedNodeIds(storeApi.getState()),
    []
  );

  const setDraggingEnd = useCallback(() => {
    Object.keys(dragItems.current).forEach((draggedNodeId) => {
      setNodeDraggingEnd({
        nodeId: draggedNodeId,
      });
    });
    isDragging.current = false;
  }, [setNodeDraggingEnd]);

  const setDraggingStart = useCallback(() => {
    isDragging.current = true;
    Object.keys(dragItems.current).forEach((draggedNodeId) => {
      setNodeDraggingStart({
        nodeId: draggedNodeId,
      });
    });
  }, [setNodeDraggingStart]);

  const calculatePointerPosition = useCallback((event: UseDragEvent) => {
    const { transform, snapToGrid, snapGrid } = storeApi.getState();
    const originalMousePosition = getPositionFromMouseEvent(event.sourceEvent);

    return getPositionOnCanjux(
      originalMousePosition,
      transform,
      snapToGrid,
      snapGrid
    );
  }, []);

  const updateNodesPosition = useCallback(
    (pointerPos: XYPosition) => {
      lastPos.current = pointerPos;

      const {
        canvasNodesDimensions,
        canvasNodesIndicatorsPositions,
        components,
        currentCanvasName,
        canvases,
        height,
        snapGrid,
        snapToGrid,
        transform,
        width,
      } = storeApi.getState();
      const currentCanvas = canvases[currentCanvasName];

      const changes: Array<NodeChange<'position'>> = [];

      Object.keys(dragItems.current).forEach((draggedNodeId) => {
        const nextPosition = {
          x: pointerPos.x - dragItems.current[draggedNodeId].distance.x,
          y: pointerPos.y - dragItems.current[draggedNodeId].distance.y,
        };

        if (snapToGrid) {
          nextPosition.x = snapGrid.x * Math.round(nextPosition.x / snapGrid.x);
          nextPosition.y = snapGrid.y * Math.round(nextPosition.y / snapGrid.y);
        }

        changes.push({
          id: draggedNodeId,
          type: 'position',
          data: {
            position: getSnapAdjustedPositionIfEnabled({
              x: pointerPos.x - dragItems.current[draggedNodeId].distance.x,
              y: pointerPos.y - dragItems.current[draggedNodeId].distance.y,
            }),
          },
        });
      });

      const newIntersectingNodes: Array<string> = getIntersectingNode({
        canvas: currentCanvas,
        canvasNodesDimensionsData: canvasNodesDimensions,
        canvasNodesIndicatorsData: canvasNodesIndicatorsPositions,
        components: components,
        height,
        nodeIds: changes.map((change) => change.id),
        pointerPosition: pointerPos,
        transform,
        width,
      });

      updateNodePositionAndIntersections({
        changes,
        intersectingNodes: newIntersectingNodes,
      });

      intersectingNodes.current = newIntersectingNodes;
    },
    [updateNodePositionAndIntersections]
  );

  const autoPan = useCallback(() => {
    if (!containerBounds.current) {
      return;
    }

    const [xMovement, yMovement] = calcAutoPan(
      mousePosition.current,
      containerBounds.current
    );

    if (xMovement !== 0 || yMovement !== 0) {
      const { transform, panBy } = storeApi.getState();

      lastPos.current.x = (lastPos.current.x ?? 0) - xMovement / transform.zoom;
      lastPos.current.y = (lastPos.current.y ?? 0) - yMovement / transform.zoom;

      updateNodesPosition(lastPos.current as XYPosition);
      panBy({ x: xMovement, y: yMovement });
    }

    autoPanId.current = requestAnimationFrame(autoPan);
  }, [updateNodesPosition]);

  // This callback acts as the "mousedown" event handler for the node
  const handleDragStart = useCallback(
    (event: UseDragEvent) => {
      const nodeProperties = getNodeProperties();
      const isSelected = getIsNodeSelected();
      const selectedNodes = getSelectedNodes();
      const nodeSiblings = getNodeSiblings();
      const nodeChildren = getNodeChildren();
      const isDirectSelectionActive = isDirectSelection(event.sourceEvent);
      const isMultiSelectionActive = isMultiSelection(event.sourceEvent);

      ///// ------------- Drag filtering
      // We don't need to process this event if the node is not selectable
      if (!nodeProperties?.isSelectable) {
        return;
      }

      if (!isSelected) {
        isSelecting.current = true;

        if (isDirectSelectionActive || isMultiSelectionActive) {
          selectCurrentNode(event.sourceEvent);
          return;
        }
      }

      ///// ------------- Selecting the node
      // Disable focus for selected element (for example: clicking on input causes it to be focused.)
      requestAnimationFrame(() => {
        event.sourceEvent.target?.blur();
      });

      // always select the node when dragging if:
      // 1. Current node is root node
      // 2. Node is a sibling of selected node
      // 3. We're selecting the parent of selected node
      if (
        isSiblingOrParentOfSelectedNodes({
          nodeSiblings,
          selectedNodes,
          nodeChildren,
        })
      ) {
        if (!isSelected) {
          selectCurrentNode(event.sourceEvent);
        }
      }
      // we're not supposed to select this node if it's not selected, but user clicked on it, so choose the first draggable node up on the node׳s tree.
      else if (!isSelected) {
        const nodesPathToRoot = extractPathToRoot(nodeId);
        const isRootNode = nodesPathToRoot.length === 0;

        if (isRootNode) {
          selectCurrentNode(event.sourceEvent);
          element?.blur();
        } else {
          const isAncestorSelected = nodesPathToRoot.some((id) =>
            selectedNodes.includes(id)
          );

          if (!isAncestorSelected) {
            setSelectedNodes({
              nodeIds: nodesPathToRoot.slice(-1),
            });
            element?.blur();
          }
        }
      }

      // Takes a drag event and returns the pointer position in Canjux coordinate system
      // get the pointer position relative to the canvas
      const pointerPosition = calculatePointerPosition(event);

      const mainWrapperElement = getMainWrapperElement();

      const relativePointerPosition = pointer(event, mainWrapperElement);

      mousePosition.current = {
        x: relativePointerPosition[0],
        y: relativePointerPosition[1],
      };

      // If there's no selected nodes, we should set for dragging the root node
      dragItems.current = getDragItems(pointerPosition);

      containerBounds.current =
        mainWrapperElement?.getBoundingClientRect() || null;
    },
    [
      calculatePointerPosition,
      element,
      getIsNodeSelected,
      getNodeChildren,
      getNodeProperties,
      getNodeSiblings,
      getSelectedNodes,
      nodeId,
      selectCurrentNode,
      setSelectedNodes,
    ]
  );

  const handleDrag = useCallback(
    (event: UseDragEvent) => {
      // there is nothing to drag.
      if (Object.keys(dragItems.current).length === 0) {
        return;
      }

      const {
        dragNodeStarted,
        draggedNodeStartPosition,
        liveblocks,
        transform,
      } = storeApi.getState();

      const { parentId } = getNodeParent();

      if (!dragNodeStarted) {
        let startPosition = draggedNodeStartPosition;

        // we care about calculating the drag sensitivity only when the node is a child of another node
        if (parentId) {
          if (!startPosition) {
            startPosition = {
              x: event.x,
              y: event.y,
            };

            setNodeDragStartingPoint({
              startPosition,
            });
          }

          const distance = getDistance(
            startPosition as Exclude<typeof startPosition, null>,
            {
              x: event.x,
              y: event.y,
            }
          );

          const zoom = transform.zoom;
          const distanceThreshold = MINIMUM_DISTANCE_TO_START_NODE_DRAG * zoom;

          if (distance < distanceThreshold) {
            return;
          }
        }

        liveblocks.room?.history.pause();

        setNodeDragStartingPoint({ startPosition: null });
        setDraggingStart();

        // move the items to drag to the root level
        Object.keys(dragItems.current).forEach((draggedNodeId) => {
          const draggedItem = dragItems.current[draggedNodeId];

          // Moving to root only nodes that are not already root nodes
          if (draggedItem?.parentId) {
            moveNodes({
              sourceNodeIds: [draggedNodeId],
              targetPosition: {
                xSnapped: draggedItem.startPosition.x,
                ySnapped: draggedItem.startPosition.y,
              },
            });
          }
        });
      }

      // there are cases when user is still holding command hotkey while selecting and dragging and we don't want to process this event
      if (isDirectSelection(event.sourceEvent)) {
        return;
      }

      // Takes a drag event and returns the pointer position in Canjux coordinate system
      const pointerPosition = calculatePointerPosition(event);
      const relativePointerPosition = pointer(event, getMainWrapperElement());

      mousePosition.current = {
        x: relativePointerPosition[0],
        y: relativePointerPosition[1],
      };

      if (!autoPanStarted.current && storeApi.getState().autoPanOnNodeDrag) {
        autoPanStarted.current = true;
        autoPan();
      }

      updateNodesPosition(pointerPosition);
    },
    [
      autoPan,
      calculatePointerPosition,
      getNodeParent,
      moveNodes,
      setDraggingStart,
      setNodeDragStartingPoint,
      updateNodesPosition,
    ]
  );

  const handleDragEnd = useCallback(
    (event: UseDragEvent) => {
      const { dragNodeStarted, liveblocks } = storeApi.getState();

      // If the node was not dragged and click handler was provided, trigger a click event
      if (!dragNodeStarted && !isDragging.current && onClick) {
        return onClick(event.sourceEvent);
      }

      if (!dragNodeStarted) {
        if (!isSelecting.current) {
          selectCurrentNode(event.sourceEvent);
        } else {
          isSelecting.current = false;
        }
      }

      // We get here regardless of whether the node was actually moved or not (in case user just clicked on the node without moving it)
      if (!isDragging.current) {
        return;
      }

      setNodeDragStartingPoint({ startPosition: null });
      setDraggingEnd();

      isDragging.current = false;

      const dropPosition = calculatePointerPosition(event);
      const relativePointerPosition = pointer(event, getMainWrapperElement());

      mousePosition.current = {
        x: relativePointerPosition[0],
        y: relativePointerPosition[1],
      };

      autoPanStarted.current = false;
      cancelAnimationFrame(autoPanId.current);

      const draggedNodeId = Object.keys(dragItems.current)[0];

      if (intersectingNodes.current.length > 0) {
        // The node was dropped on top of another node, so we need to update the parent
        moveNodes({
          sourceNodeIds: Object.keys(dragItems.current),
          targetNodeId:
            intersectingNodes.current[intersectingNodes.current.length - 1],
          targetPosition: dropPosition,
        });
      } else {
        // If the node was dropped on empty space, it means that it should be a root node
        // Only move it to the root if it's not already a root node (has parent)
        if (dragItems.current[draggedNodeId].parentId) {
          moveNodes({
            sourceNodeIds: Object.keys(dragItems.current),
            targetNodeId: undefined,
            targetPosition: dropPosition,
          });
        }
      }

      liveblocks.room?.history.resume();
      intersectingNodes.current = [];
    },
    [
      calculatePointerPosition,
      moveNodes,
      onClick,
      selectCurrentNode,
      setDraggingEnd,
      setNodeDragStartingPoint,
    ]
  );

  useEffect(() => {
    if (elementRef.current) {
      const selection = select(elementRef.current as Element);

      const { disableNodesInteraction } = storeApi.getState();
      const { parentId } = getNodeParent();

      if (!disableNodesInteraction) {
        const dragHandler = drag()
          .filter((event: MouseEvent) => {
            /*
             * Do not process this dragging event if an editable text node is in the current position.
             * In order to be able to select sub text inside the editable text node,
             * it is necessary to prevent the drag event from being processed, by the ancestor nodes of this editable text node.
             */
            if (
              isEditableTextNodeExistInPosition(event.clientX, event.clientY)
            ) {
              return false;
            }

            // if node is root always return true
            if (!parentId) {
              return true;
            }

            return !event.button;
          })
          .on('start', (event: UseDragEvent) => handleDragStart(event))
          .on('drag', (event: UseDragEvent) => handleDrag(event))
          .on('end', (event: UseDragEvent) => handleDragEnd(event));

        selection.call(dragHandler);

        return () => {
          selection.on('.drag', null);
        };
      } else {
        selection.on('.drag', null);
      }
    }
    return () => {
      cancelAnimationFrame(autoPanId.current);
    };
  }, [elementRef, getNodeParent, handleDrag, handleDragEnd, handleDragStart]);

  return {
    handleDrag,
    handleDragEnd,
    handleDragStart,
  };
};
