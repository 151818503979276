/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const ArrowBackIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="12"
    height="10"
    attr={{
      viewBox: '0 0 12 10',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M0 5C0 4.72386 0.223858 4.5 0.5 4.5H11.5C11.7761 4.5 12 4.72386 12 5C12 5.27614 11.7761 5.5 11.5 5.5H0.5C0.223858 5.5 0 5.27614 0 5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M5.35355 0.146447C5.54882 0.341709 5.54882 0.658291 5.35355 0.853553L1.20711 5L5.35355 9.14645C5.54882 9.34171 5.54882 9.65829 5.35355 9.85355C5.15829 10.0488 4.84171 10.0488 4.64645 9.85355L0.146447 5.35355C-0.0488155 5.15829 -0.0488155 4.84171 0.146447 4.64645L4.64645 0.146447C4.84171 -0.0488155 5.15829 -0.0488155 5.35355 0.146447Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
