import { CORE } from '@jux/types';

export const formatGroupPath = (item: string, isCoreTokenSet?: boolean) => {
  if (!item) return '';

  if (isCoreTokenSet) {
    item = item.replace(`${CORE}.`, '');
  }

  return item;
};
