import { useCallback, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useLoadFonts } from '@jux/dui';
import { useGetSupportedFontsQuery } from '@jux/ui/data';
import { useScripts } from '@jux/ui/hooks/useScripts';

export const useIframe = ({
  styles,
  scripts,
}: {
  styles?: Array<string>;
  scripts?: Array<string>;
}) => {
  const { data: supportedFonts } = useGetSupportedFontsQuery();

  const { loadFonts } = useLoadFonts(supportedFonts);

  const [iframeContext, setIframeContext] = useState<Window>();

  // TODO: setupIframeHTML is called when the iframe is loaded, which is bad for performance
  // we should only call it when the iframe is created
  // and we shouldn't use the iframeContext to load the scripts, we should use the iframe itself
  // and we shouldn't add new html and head tags, we should use the existing ones and add the styles and fonts to them
  const setupIframeHTML = useCallback(
    ({ target: iframe }: { target: EventTarget }) => {
      const context = (iframe as HTMLIFrameElement).contentWindow as Window;

      // Render the iframe׳s html and wait for it to be ready
      createRoot(context.document.getRootNode() as Element).render(
        <html lang="en" ref={() => setIframeContext(context)}>
          <head>
            {/* TODO: bring the style from outside of the iframe */}
            <link rel="stylesheet" href="/canjux.style.css" />
            {styles?.map((style) => (
              <style type="text/css">{style}</style>
            ))}
          </head>
          <body />
        </html>
      );
    },
    [setIframeContext, styles]
  );

  // TODO: this should be done inside the setupIframeHTML function,
  //  but it doesn't work there for some reason, so we do it after
  //  the iframe is loaded and the context is set
  useEffect(() => {
    // loading the app's supported fonts into the iframe
    loadFonts(iframeContext);
  }, [iframeContext, loadFonts]);

  // loading the scripts into the iframe
  useScripts({ context: iframeContext, scripts });

  return { iframeContext, setupIframeHTML };
};
