import { ButtonElementPanelIcon } from './ButtonElementPanelIcon';
import { CheckboxElementPanelIcon } from './CheckboxElementPanelIcon';
import { ChipElementPanelIcon } from './ChipElementPanelIcon';
import { DivElementPanelIcon } from './DivElementPanelIcon';
import { DividerElementPanelIcon } from './DividerElementPanelIcon';
import { IconButtonElementPanelIcon } from './IconButtonElementPanelIcon';
import { InputElementPanelIcon } from './InputElementPanelIcon';
import { RadioElementPanelIcon } from './RadioElementPanelIcon';
import { TextareaElementPanelIcon } from './TextareaElementPanelIcon';
import { TextareaFieldElementPanelIcon } from './TextareaFieldElementPanelIcon';
import { TextElementPanelIcon } from './TextElementPanelIcon';
import { TextFieldElementPanelIcon } from './textFieldElementPanelIcon';
import { ToggleElementPanelIcon } from './ToggleElementPanelIcon';
import { CheckboxFieldElementPanelIcon } from './CheckboxFieldElementPanelIcon';

export const elementsPanel = {
  ButtonElementPanelIcon,
  CheckboxElementPanelIcon,
  CheckboxFieldElementPanelIcon,
  ChipElementPanelIcon,
  DivElementPanelIcon,
  DividerElementPanelIcon,
  IconButtonElementPanelIcon,
  InputElementPanelIcon,
  RadioElementPanelIcon,
  TextareaElementPanelIcon,
  TextareaFieldElementPanelIcon,
  TextElementPanelIcon,
  TextFieldElementPanelIcon,
  ToggleElementPanelIcon,
};
