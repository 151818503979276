import { useMemo } from 'react';
import { LineType, selectHoveredNodesStack, useStore } from '@jux/canjux/core';
import { LayersPanelItem } from '@jux/ui/components/editor/components/panels/layers/state/getLayersItemsRecursive';
import { useSelectedNodes } from '@jux/ui/components/editor/hooks/useSelectedNodes';
import { DropPosition } from '../types';
import { dndReorderItemsBehaviors, getItemAncestorPaths } from './helpers';
import { useLayersPanelStateValue } from './useLayersPanelStateValue';

export const useLayersPanelVisibleItems = ({
  items,
}: {
  items: Map<string, LayersPanelItem>;
}) => {
  const {
    editingPath,
    expandedPaths,
    reorderHoveredItemDropZonePosition: dropPosition,
    reorderHoveredItem,
    reorderDraggedItem,
  } = useLayersPanelStateValue();
  const selectedNodes = useSelectedNodes();
  const hoveredNodesStack = useStore(selectHoveredNodesStack);

  return useMemo(() => {
    return Array.from(items.values())
      .filter(({ id, isRoot, path }) => {
        if (isRoot) {
          return true;
        }

        const matches = getItemAncestorPaths({ id, path });

        // All ancestors are expanded
        return matches.every((p) => expandedPaths.has(p));
      })
      .map((item) => {
        const isExpanded = expandedPaths.has(item.path);

        const draggedItem = reorderDraggedItem
          ? items.get(reorderDraggedItem.id)
          : undefined;
        const hoveredItem = reorderHoveredItem
          ? items.get(reorderHoveredItem.id)
          : undefined;

        const {
          draggable,
          droppable,
          allowDropAbove,
          allowDropBelow,
          allowDropInside,
          showDroppableLine,
        } = dndReorderItemsBehaviors({
          currentItem: item,
          draggedItem,
          hoveredItem,
          items,
          isExpanded,
          dropPosition,
        });

        const isHovered = hoveredNodesStack.some(
          (n) => n.id === item.id && n.type === LineType.PARENT
        );

        // find if the item has an ancestor selected
        const itemPathWithoutCurrentItem = item.path.replace(`.${item.id}`, '');
        const hasSelectedNodeInPath = selectedNodes.some((id) =>
          itemPathWithoutCurrentItem.includes(id)
        );

        const isNotRootLevel = selectedNodes.every((id) => item.path !== id);
        const hasAncestorSelected = Boolean(
          isNotRootLevel && hasSelectedNodeInPath
        );

        return {
          ...item,
          hasAncestorLibraryComponent: Boolean(
            item.hasAncestorLibraryComponent
          ),
          hasAncestorSelected,
          isExpanded,
          isSelected: selectedNodes.includes(item.id),
          isHovered,
          isEditing: editingPath === item.path,
          dropPosition,
          hasDragStarted: Boolean(reorderDraggedItem),
          showDroppableLine,
          droppable,
          draggable,
          allowDropAbove,
          allowDropInside,
          allowDropBelow,
          isDraggedInside:
            reorderHoveredItem?.id === item.id &&
            dropPosition === DropPosition.inside,
        };
      });
  }, [
    dropPosition,
    editingPath,
    expandedPaths,
    hoveredNodesStack,
    items,
    reorderDraggedItem,
    reorderHoveredItem,
    selectedNodes,
  ]);
};
