import { FC } from 'react';
import {
  TooltipTypography,
  ToggleSwitch,
  useToggleSwitch,
} from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';

export const ParentContextHeader: FC<{
  title: string;
  checked: boolean;
  onToggleClick: (checked: boolean) => void;
}> = ({ title, checked, onToggleClick }) => {
  const toggleSwitchProps = useToggleSwitch({
    checked,
    onToggleClick,
  });

  return (
    <MuiGrid
      container
      item
      sx={{
        padding: '16px 0',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'nowrap',
        columnGap: '8px',
      }}
    >
      <TooltipTypography
        variant="label-s-medium"
        sx={{
          width: 'calc(100% - 40px)',
        }}
      >
        {title}
      </TooltipTypography>
      <ToggleSwitch {...toggleSwitchProps} />
    </MuiGrid>
  );
};
