import { CSSProperties } from 'react';
import { IconsVariantsType } from '@jux/ui/components';

export type SupportedDisplayValues = Extract<
  CSSProperties['display'],
  'block' | 'flex' | 'inline-block' | 'inline-flex' | 'inline' | 'none'
>;

export const DisplayValue: {
  block: Extract<SupportedDisplayValues, 'block'>;
  flex: Extract<SupportedDisplayValues, 'flex'>;
  inline: Extract<SupportedDisplayValues, 'inline'>;
  inlineBlock: Extract<SupportedDisplayValues, 'inline-block'>;
  inlineFlex: Extract<SupportedDisplayValues, 'inline-flex'>;
  none: Extract<SupportedDisplayValues, 'none'>;
} = {
  block: 'block',
  flex: 'flex',
  inline: 'inline',
  inlineBlock: 'inline-block',
  inlineFlex: 'inline-flex',
  none: 'none',
} as const;

export const fullDisplayValueList = [
  DisplayValue.block,
  DisplayValue.flex,
  DisplayValue.inline,
  DisplayValue.inlineBlock,
  DisplayValue.inlineFlex,
  DisplayValue.none,
] as const;

export const svgDisplayValueList = [
  DisplayValue.block,
  DisplayValue.inline,
  DisplayValue.inlineBlock,
  DisplayValue.none,
] as const;

export const iconsToDisplayValues: Record<
  SupportedDisplayValues,
  IconsVariantsType
> = {
  [DisplayValue.block]: 'DDP_FIELD_DISPLAY_BLOCK',
  [DisplayValue.flex]: 'DDP_FIELD_DISPLAY_FLEX',
  [DisplayValue.inline]: 'DDP_FIELD_DISPLAY_INLINE',
  [DisplayValue.inlineBlock]: 'DDP_FIELD_DISPLAY_INLINE_BLOCK',
  [DisplayValue.inlineFlex]: 'DDP_FIELD_DISPLAY_INLINE_FLEX',
  [DisplayValue.none]: 'DDP_FIELD_DISPLAY_NONE',
};
