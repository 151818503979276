import {
  CanjuxState,
  CommonActionsParams,
  CREATE_NEW_CANVAS_NODE_DISTANCE,
  getDefaultNodePropertiesByTagName,
  JuxStoreActionFn,
  selectOrgComponentsNames,
  setLayersData,
} from '@jux/canjux/core';
import {
  ComponentTagName,
  JuxComponentData,
  NodeType,
  XYPosition,
} from '@jux/data-entities';
import { ELEMENTS_INITIAL_SIZE } from '@jux/elements';
import { generateName } from '@jux/ui/utils/generateName';
import type { Draft as WritableDraft } from 'mutative';
import { defaultNodeData } from '../constants/defaultNodeData';
import { setSelectedNodes } from './setSelectedNodes';
import { setRootComponentUpdateTime } from './utils';

/**
 * Create a new node and component for each sub component (child node).
 * In some cases we also have a new local / library component - in which case we create the new node of the source
 * component 80px to the right of the root node.
 * @param subComponent
 * @param canvasName
 * @param rootNodeTagName
 * @param rootNodePosition
 * @param state
 */
const createNodeAndComponentForSubComponent = ({
  subComponent,
  canvasName,
  rootNodeTagName,
  rootNodePosition = { x: 0, y: 0 },
  state,
}: {
  subComponent: JuxComponentData;
  canvasName: string;
  rootNodeTagName: ComponentTagName;
  rootNodePosition?: XYPosition;
  state: WritableDraft<CanjuxState>;
}) => {
  const subComponentNodePosition = { x: 0, y: 0 };
  if (
    subComponent.type === NodeType.LOCAL_COMPONENT ||
    subComponent.type === NodeType.LIBRARY_COMPONENT
  ) {
    subComponent.displayName = generateName({
      baseName: subComponent.displayName,
      namesArray: selectOrgComponentsNames(state),
      options: {
        formatters: ['formatCase', 'removeIllegalChars'],
      },
    });

    state.canvases[canvasName].rootNodesOrder.unshift(subComponent.id);

    subComponentNodePosition.x =
      rootNodePosition.x +
      ELEMENTS_INITIAL_SIZE[rootNodeTagName].width +
      CREATE_NEW_CANVAS_NODE_DISTANCE;
    subComponentNodePosition.y = rootNodePosition.y;
  }

  state.components[subComponent.id] = subComponent;
  state.canvases[canvasName].nodes[subComponent.id] = {
    position: subComponentNodePosition,
    properties:
      subComponent.type === NodeType.INSTANCE
        ? defaultNodeData.properties
        : getDefaultNodePropertiesByTagName({
            tagName: subComponent.tagName,
            type: subComponent.type,
          }),
  };
};

/**
 * Create a new node and component with children - used for adding objects from elememts panel to canvas
 */
export const createNodeWithChildren: JuxStoreActionFn<
  CommonActionsParams['createNodeWithChildren'],
  CanjuxState
> = ({
  canvasName,
  component,
  isSelected = false,
  subComponents,
  node,
  state,
}) => {
  // Check that non of the new id's exists
  const childrenIds = subComponents?.map((child) => child.id);
  const newIds = [component.id].concat(childrenIds ?? []);
  for (const componentId of Object.keys(state.components)) {
    if (componentId in newIds) {
      return state;
    }
  }

  const nodeId = component.id;
  if (
    node &&
    // Can't create a node if it already exists (update it instead)
    !state.canvases[canvasName].nodes[nodeId]
  ) {
    // Add the new node to the canvas
    state.canvases[canvasName].nodes[nodeId] = node;
  }

  if (
    component.type === NodeType.LOCAL_COMPONENT ||
    component.type === NodeType.LIBRARY_COMPONENT
  ) {
    component.displayName = generateName({
      baseName: component.displayName,
      namesArray: selectOrgComponentsNames(state),
      options: {
        formatters: ['formatCase', 'removeIllegalChars'],
      },
    });
  }

  state.components[nodeId] = component;

  for (const subComponent of subComponents ?? []) {
    createNodeAndComponentForSubComponent({
      subComponent,
      canvasName,
      rootNodeTagName: component.tagName,
      rootNodePosition: node?.position, // if the node has a position, use it as the root node position
      state,
    });
  }

  const { parentId } = component;

  // Root node - add it to the canvas's nodes order list
  if (!parentId) {
    state.canvases[canvasName].rootNodesOrder.unshift(nodeId);
  }

  if (parentId) {
    if (!state.components[parentId].children.includes(nodeId)) {
      state.components[parentId].children.push(nodeId);
    }

    // if created under a parent component
    setRootComponentUpdateTime({ id: nodeId, components: state.components });
  }

  if (isSelected) {
    setSelectedNodes({ nodeIds: [nodeId], state });
  }

  setLayersData(state);

  return state;
};
