import { ThemeOptions } from '@jux/ui/components/common/mui';
import { darkColors } from '../dark/dark-theme-colors';
import { lightColors } from '../light/light-theme-colors';
import { components } from './base-theme-components';
import { drimzPalette } from './base-theme-palette';
import { DrimzPalette } from './base-theme-palette.interface';
import { baseThemeShadow } from './base-theme-shadow';
import { baseThemeSize } from './base-theme-size';
import { transitions, UITransitions } from './base-theme-transitions';
import { typography } from './base-theme-typography';

export type DrimzTheme = {
  palette: DrimzPalette;
  size: typeof baseThemeSize;
  shadow: ReturnType<typeof baseThemeShadow>;
  transitions: UITransitions;
};

declare module '@mui/material/styles' {
  interface Theme {
    drimz: DrimzTheme;
  }

  interface ThemeOptions {
    drimz: DrimzTheme;
  }
}

export const darkDrimzPalette = drimzPalette(darkColors, 'dark');
export const lightDrimzPalette = drimzPalette(lightColors, 'light');

export const baseThemeOptions: ThemeOptions = {
  drimz: {
    size: baseThemeSize,
    shadow: baseThemeShadow(lightColors),
    // palette is not defined yet - only after the theme is created (either light or dark)
    // so we set the default value to darkDrimzPalette
    // we don't want to make the palette optional because we want to use it in the components without having to check if it's defined
    palette: lightDrimzPalette,
    transitions,
  },
  typography,
  components,
  direction: 'ltr',
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1300,
      xl: 1920,
    },
  },
};
