import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { WebfontFamily } from '@jux/data-entities';
import DrimzAPI from '@jux/ui/lib/api';

export function useGetSupportedFontsQuery() {
  return useQuery<Array<WebfontFamily>, AxiosError>(
    ['get-supported-fonts'],
    () =>
      DrimzAPI.get('/api/tokens/text/fonts').then(
        (resp: AxiosResponse<Array<WebfontFamily>>) => resp.data
      )
  );
}
