import { selectCurrentCanvas } from './../selectors/selectCurrentCanvas';
import {
  CanjuxState,
  CommonActionsParams,
  JuxStoreActionFn,
  setLayersData,
} from '@jux/canjux/core';
import { NodeType } from '@jux/data-entities';
import { deleteInstanceNodes, setRootComponentUpdateTime } from './utils';

/**
 * Delete a node, its component data, and its children.
 */
export const deleteNode: JuxStoreActionFn<
  CommonActionsParams['deleteNode'],
  CanjuxState
> = ({
  canvasName,
  nodeId,
  isChildOfDeletedNode,
  deleteComponent = true,
  state,
}) => {
  const nodeComponentData = state.components[nodeId];
  const canvas = selectCurrentCanvas(state);

  setLayersData(state);

  const isDeletable = canvas.nodes[nodeId]?.properties.isDeletable;
  if (!nodeComponentData || (!isDeletable && !isChildOfDeletedNode)) {
    return state;
  }

  let shouldDeleteComponent = Boolean(deleteComponent);

  // when deleting the root of a component, do not delete the actual component,
  // we don't want to delete the source component, only the canvas node - do this for its children too.
  if (
    nodeComponentData.type === NodeType.LOCAL_COMPONENT ||
    nodeComponentData.type === NodeType.LIBRARY_COMPONENT
  ) {
    shouldDeleteComponent = false;
  }

  if (nodeComponentData.parentId && shouldDeleteComponent) {
    const parent = state.components[nodeComponentData.parentId];
    if (parent?.children) {
      parent.children = parent.children.filter((id) => id !== nodeId);
    }
  }

  if (!nodeComponentData.parentId && canvas.rootNodesOrder.includes(nodeId)) {
    // Remove node from root-nodes list
    canvas.rootNodesOrder = canvas.rootNodesOrder.filter((id) => id !== nodeId);
  }

  nodeComponentData.children.forEach((childId) => {
    deleteNode({
      canvasName,
      nodeId: childId,
      deleteComponent: shouldDeleteComponent,
      isChildOfDeletedNode: true,
      state,
    });
  });

  state.selectedNodesStack = state.selectedNodesStack.filter(
    (nId) => nId !== nodeId
  );
  state.hoveredNodesStack = state.hoveredNodesStack.filter(
    (n) => n.id !== nodeId
  );

  delete canvas.nodes[nodeId];

  // For example in case we delete an object under a local component.
  // This will not be true when we delete an instance or a root of a component
  if (shouldDeleteComponent) {
    deleteInstanceNodes({
      sourceNodeId: nodeId,
      canvases: state.canvases,
      components: state.components,
    });

    // if deleted under a component
    setRootComponentUpdateTime({ id: nodeId, components: state.components });

    delete state.components[nodeId];
  }

  return state;
};
