import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { ActionButton } from '../../../editor/components/ActionButton';

export const GroupHeaderActionsPopper: FC<{
  onCreateSubGroup: () => void;
  onDeleteGroup: () => void;
  onCreateToken: () => void;
  disableDelete: boolean;
}> = ({ onCreateSubGroup, onDeleteGroup, onCreateToken, disableDelete }) => (
  <MuiGrid
    sx={{
      width: '100%',
      position: 'relative',
      padding: '3px',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: (theme) => theme.drimz.palette.background.default,
      borderRadius: (theme) => theme.drimz.size.borderRadius.small,
      border: 1,
      borderColor: (theme) => theme.drimz.palette.divider.primary,
      boxShadow: (theme) => theme.drimz.shadow.tokens.poppers.boxShadow,
    }}
  >
    <ActionButton icon="ADD" onClick={onCreateToken}>
      Add token
    </ActionButton>
    <ActionButton icon="ADD" onClick={onCreateSubGroup}>
      Create subgroup
    </ActionButton>
    <ActionButton
      icon="DELETE"
      onClick={onDeleteGroup}
      disabled={disableDelete}
      tooltipTitle={
        disableDelete
          ? `Group with a token that used as alias can't be deleted`
          : ''
      }
      tooltipProps={{
        multiline: true,
        header: `Can't delete group`,
      }}
    >
      Delete group
    </ActionButton>
  </MuiGrid>
);
