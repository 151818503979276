import {
  CanjuxState,
  DDPActionsParams,
  JuxStoreActionFn,
  setLayersData,
} from '@jux/canjux/core';

/**
 * Delete a component style.
 */
export const deleteComponentStyles: JuxStoreActionFn<
  DDPActionsParams['deleteComponentStyles'],
  CanjuxState
> = ({ state }) => {
  // TODO: Implement deleteComponentStyles
  // we need to take the parent context under consideration
  // in order to decide which style to update

  setLayersData(state);

  return state;
};
