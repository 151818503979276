export const PATHS = {
  EDITOR: '/editor',
  MAIN_PAGE: '/',
  DOCS: 'https://docs.jux.io/',
  WELCOME: '/welcome',
  TOKENS: '/tokens',
  IMPORT_TOKENS: '/import-tokens',
  IMPORT_TOKENS_OUTSIDE:
    'https://www.figma.com/community/plugin/1401995002002640291/juxync',
} as const;
