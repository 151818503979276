import { supportedTokenTypes } from '@jux/design-tokens';
import { GroupedTokensByType } from './getGroupedTokens';

export const getTotalTokens = ({
  groupsByType,
}: {
  groupsByType: GroupedTokensByType | undefined;
}): number => {
  let totalTokens = 0;

  if (!groupsByType) return 0;

  supportedTokenTypes.forEach((type) => {
    const total = groupsByType[type].totalTokens;

    if (total) {
      totalTokens = totalTokens + total;
    }
  });

  return totalTokens;
};
