import { FC } from 'react';
import { capitalize } from 'lodash';
import { Icons, IconsVariantsType, Tooltip } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';

type SupportedIcons = Extract<IconsVariantsType, 'ALIAS' | 'ALIAS_INSIDE'>;

export const TokenIcons: FC<{
  icons: Array<SupportedIcons>;
}> = ({ icons }) => (
  <MuiGrid container item gap={1} mr={0.5}>
    {icons.map((icon, index) => (
      <Tooltip
        content={capitalize(icon.replaceAll('_', ' '))}
        key={`${icon}_${index}`}
      >
        <MuiGrid item key={icon} p={0.5}>
          <Icons
            variant={icon}
            size={16}
            sx={{
              color: (theme) => theme.drimz.palette.text.button,
            }}
          />
        </MuiGrid>
      </Tooltip>
    ))}
  </MuiGrid>
);
