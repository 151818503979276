import { FC } from 'react';
import { DimensionField } from '../../../../base';
import { useGapField } from './useGapField';

export const GapColumnField: FC = () => {
  const { columnGapProps } = useGapField();

  return (
    <DimensionField
      icon="DDP_FIELD_COLUMN_GAP"
      tooltipTitle="Columns gap"
      {...columnGapProps}
    />
  );
};
