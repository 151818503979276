import { createSelector } from 'reselect';
import { CanjuxState } from '@jux/canjux/core';

export const canvasDimensions = createSelector(
  [
    ({ width }: CanjuxState) => width,
    ({ height }: CanjuxState) => height,
    ({ transform }: CanjuxState) => transform.zoom,
    ({ transform }: CanjuxState) => transform.x,
    ({ transform }: CanjuxState) => transform.y,
  ],
  (width, height, zoom, x, y) => ({
    width,
    height,
    zoom,
    x,
    y,
  })
);
