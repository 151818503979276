import React, { ReactNode, useMemo } from 'react';
import { Typography, typographyVariants } from '@jux/ui/components';
import { MuiListSubheader, MuiMenuItem } from '@jux/ui/components/common/mui';

export type OptionConfig = {
  value: string;
  label: string;
};

export type GroupedOptions = Record<string, OptionConfig[]>;

export const useGroupedOptions = ({
  groupedOptions,
  renderValue = (v) => v,
}: {
  groupedOptions?: GroupedOptions;
  renderValue?: (value: string) => ReactNode;
}) =>
  useMemo(() => {
    if (!groupedOptions) {
      return null;
    }

    const options = Object.entries(groupedOptions).flatMap(
      ([groupLabel, optionsGroup]) =>
        [
          {
            value: groupLabel,
            label: groupLabel,
          },
        ].concat(
          optionsGroup.map(({ value, label }) => ({
            value,
            label,
          }))
        )
    );

    // This is a bit of a hack to render divider labels between option groups.
    // According to the MUI docs:
    // ⚠️ The MenuItem elements must be direct descendants (https://mui.com/material-ui/api/select/#props).
    // Therefore, we can't use fragments here, so in order to render a divider,
    // we include it in the list of options and render it in MuiListSubheader.
    return options.map(({ value, label }) => {
      if (label in groupedOptions) {
        return (
          <MuiListSubheader
            key={label}
            sx={{
              ...typographyVariants.useGroupedOptionsTypography,
              textTransform: 'uppercase',
              padding: '8px 12px',
            }}
          >
            {label}
          </MuiListSubheader>
        );
      }

      return (
        <MuiMenuItem key={value} value={value}>
          <Typography variant="label-s-regular">
            {renderValue ? renderValue(label) : label}
          </Typography>
        </MuiMenuItem>
      );
    });
  }, [groupedOptions, renderValue]);
