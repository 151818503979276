import { atom, useAtom } from 'jotai';

export enum ComponentsTabs {
  local = 'local-components',
  library = 'library-components',
}

const selectedComponentsTabsStateAtom = atom<ComponentsTabs>(
  ComponentsTabs.local
);

export const useSelectedComponentsTabsState = () => {
  const [selectedComponentsTab, setSelectedComponentsTabs] = useAtom(
    selectedComponentsTabsStateAtom
  );

  return {
    selectedComponentsTab,
    setSelectedComponentsTabs,
  };
};
