import { NodeData } from '@jux/data-entities';
import { defaultNodeData } from '../constants/defaultNodeData';

export const getDefaultNodeData = ({
  parentId,
  position,
  isContainer,
}: {
  parentId?: string;
  position?: { x: number; y: number };
  isContainer?: boolean;
}): NodeData => ({
  position: !parentId && position ? position : defaultNodeData.position,
  properties: {
    ...defaultNodeData.properties,
    isContainer: isContainer ?? false,
  },
});
