export const getTransformRatio = ({
  containerSize,
  targetSize,
}: {
  containerSize?: Partial<Pick<DOMRect, 'width' | 'height'>>;
  targetSize?: Partial<Pick<DOMRect, 'width' | 'height'>>;
}) => {
  if (
    !containerSize?.width ||
    !containerSize?.height ||
    !targetSize?.width ||
    !targetSize.height
  )
    return;

  const widthRatio = containerSize.width / targetSize.width;
  const heightRatio = containerSize.height / targetSize.height;

  return heightRatio < widthRatio ? heightRatio : widthRatio;
};
