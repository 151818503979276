import { FC } from 'react';
import { ButtonGroupField, GroupButton } from '../../../../../base';
import { useFlexDirectionField } from './useFlexDirectionField';

export const FlexDirectionField: FC = () => {
  const {
    isColumnSelected,
    handleColumnClick,
    isRowSelected,
    handleRowClick,
    isReverseSelected,
    handleReverseClick,
  } = useFlexDirectionField();

  return (
    <>
      <ButtonGroupField>
        <GroupButton
          icon="DDP_FIELD_FLEX_DIRECTION_COLUMN"
          isSelected={isColumnSelected}
          onClick={handleColumnClick}
          tooltipTitle="Column"
        />
        <GroupButton
          icon="DDP_FIELD_FLEX_DIRECTION_ROW"
          isSelected={isRowSelected}
          onClick={handleRowClick}
          tooltipTitle="Row"
        />
      </ButtonGroupField>
      <ButtonGroupField>
        <GroupButton
          icon="DDP_FIELD_FLEX_DIRECTION_REVERSE"
          onClick={handleReverseClick}
          isSelected={isReverseSelected}
          tooltipTitle="Reverse"
          allowToggle
        />
      </ButtonGroupField>
    </>
  );
};
