import {
  getTokenSetsByCreatedAt,
  JuxStore,
  JuxStoreActionFn,
  TokenSetsActionsParams,
} from '@jux/canjux/core';
import { getCurrentTimestamp, namingPatternsSchema } from '@jux/design-tokens';
import cloneDeep from 'lodash/cloneDeep';
import { z } from 'zod';
import { getDesignTokensParserContext } from '../../../store-utils/getDesignTokensParserContext';

const designTokensParser = getDesignTokensParserContext();

export const addTokenSet: JuxStoreActionFn<
  TokenSetsActionsParams['addTokenSet'],
  JuxStore
> = ({ state, ...params }) => {
  const { name, tokenSetId: id } = z
    .object({
      name: namingPatternsSchema,
      tokenSetId: z.string(),
    })
    .parse(params);

  const [tokenSet] = getTokenSetsByCreatedAt(state.tokenSets);

  state.tokenSets[name] = {
    createdAt: getCurrentTimestamp(),
    id,
    name,
    updatedAt: getCurrentTimestamp(),
    value: designTokensParser
      .parse(cloneDeep(tokenSet.value))
      .getRawValueCopy(),
  };

  return state;
};
