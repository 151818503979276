import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { DDPTypographyField } from './DDPTypographyField';

export const Typography: FC = () => {
  const { shouldRender } = useSubModule(DDPSubModulesKeys.typography);

  if (!shouldRender) return null;
  return (
    <SubModule title="Typography">
      <DDPTypographyField />
    </SubModule>
  );
};
