import { useMemo } from 'react';
import { useGetSupportedFontsQuery } from '@jux/ui/data';

export type FontFamilyFallbackMap = Record<string, string[]> & {
  default: string[];
};

type FontFamilyOptions = {
  options: string[];
  fallbacks: FontFamilyFallbackMap;
};

// Fallbacks were generated by GitHub Copilot 🤖🪄✨
// Seems correct, and we can always double-check at https://fonts.google.com/
const DEFAULT_FALLBACKS: FontFamilyFallbackMap = {
  'Alegreya Sans': ['sans-serif'],
  Alegreya: ['serif'],
  'Archivo Narrow': ['sans-serif'],
  BioRhyme: ['serif'],
  Cardo: ['serif'],
  Chivo: ['sans-serif'],
  'DM Sans': ['sans-serif'],
  'Fira Sans': ['sans-serif'],
  'IBM Plex Sans': ['sans-serif'],
  Inter: ['sans-serif'],
  Karla: ['sans-serif'],
  Lato: ['sans-serif'],
  'Libre Baskerville': ['serif'],
  'Libre Franklin': ['sans-serif'],
  Lora: ['serif'],
  Merriweather: ['serif'],
  Neuton: ['serif'],
  'Open Sans': ['sans-serif'],
  Poppins: ['sans-serif'],
  'Proza Libre': ['sans-serif'],
  'PT Sans': ['sans-serif'],
  'PT Serif': ['serif'],
  Raleway: ['sans-serif'],
  Roboto: ['sans-serif'],
  Rubik: ['sans-serif'],
  'Source Sans Pro': ['sans-serif'],
  'Source Sans 3': ['sans-serif'],
  'Source Serif Pro': ['serif'],
  'Space Mono': ['monospace'],
  Spectral: ['serif'],
  'Work Sans': ['sans-serif'],

  // Default fallback should be sans-serif
  default: ['sans-serif'],
};

export const useFontFamilyOptions = (
  {
    fontFamilyFallbackMap = DEFAULT_FALLBACKS,
  }: {
    fontFamilyFallbackMap?: FontFamilyFallbackMap;
  } = {
    fontFamilyFallbackMap: DEFAULT_FALLBACKS,
  }
): FontFamilyOptions => {
  const { data: supportedFonts } = useGetSupportedFontsQuery();

  const fontFamilies: string[] = useMemo(
    () => supportedFonts?.map(({ family }) => family) ?? [],
    [supportedFonts]
  );

  const fallbacks: FontFamilyFallbackMap = useMemo(
    () =>
      fontFamilies.reduce((acc, fontFamily) => {
        acc[fontFamily] =
          fontFamilyFallbackMap[fontFamily] ?? fontFamilyFallbackMap.default;
        return acc;
      }, {} as FontFamilyFallbackMap),
    [fontFamilies, fontFamilyFallbackMap]
  );

  return {
    options: fontFamilies,
    fallbacks,
  };
};
