import { FC } from 'react';
import { Typography } from '@jux/ui/components';
import { MuiMenuItem } from '@jux/ui/components/common/mui';
import { palette } from '@jux/ui/theme/palette';
import { UserMenuItemProps } from './UserMenuItem.interface';

export const UserMenuItem: FC<UserMenuItemProps> = ({
  label,
  description,
  onClick,
  disabled,
}) => (
  <MuiMenuItem
    onClick={onClick}
    disabled={disabled}
    sx={{
      py: 0.75,
      display: 'flex',
      justifyContent: 'space-between',
    }}
  >
    <Typography variant="body-regular">{label}</Typography>
    <Typography variant="body-regular" sx={{ color: palette.naturals400 }}>
      {description}
    </Typography>
  </MuiMenuItem>
);
