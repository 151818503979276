import {
  CommonActionsParams,
  CanjuxState,
  JuxStoreActionFn,
} from '@jux/canjux/core';

/**
 * Set the starting point of a node drag
 */
export const setNodeDragStartingPoint: JuxStoreActionFn<
  CommonActionsParams['setNodeDragStartingPoint'],
  CanjuxState
> = ({ startPosition, state }) => {
  state.draggedNodeStartPosition = startPosition;
  return state;
};
