import { z } from 'zod';
import { useCallback } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useZodForm } from '@jux/ui/hooks';

export type UseDDPFieldFormRegisterReturn = Omit<
  UseFormRegisterReturn<string>,
  'onBlur'
>;

/**
 * A hook for creating a form for a single field
 * @param fieldName
 * @param initialValue
 * @param fieldSchema
 */
export const useDDPFieldForm = <TFieldSchema extends z.ZodType>({
  fieldName,
  fieldSchema,
  initialValue,
}: {
  fieldName: string;
  fieldSchema: TFieldSchema;
  initialValue?: TFieldSchema['_input'];
}) => {
  const {
    handleSubmit, // handleSubmit is adding focus back to the element in case the value is invalid
    getValues,
    register,
    setValue: setFieldValue,
    resetField,
  } = useZodForm({
    defaultValues: {
      [fieldName]: initialValue,
    },
    schema: z.object({
      [fieldName]: fieldSchema,
    }) as z.ZodType,
  });

  const getValue = useCallback(
    () => getValues()[fieldName],
    [fieldName, getValues]
  );

  const registerField = useCallback(
    () => register(fieldName),
    [fieldName, register]
  );

  const discardChanges = useCallback(() => {
    // TODO: fix discardChanges, at the moment it's buggy
    // known issues:
    // - when you type a value and then click outside the input, then clicking back on the input - it will revert to the first value
    //    eg. you had 100px, you changed it to 200px, you clicked outside the input, then you clicked back on the input - it will revert to 100px
    resetField(fieldName);
  }, [fieldName, resetField]);

  const setValue = useCallback(
    (value: TFieldSchema['_input']) => {
      setFieldValue(fieldName, value);
    },
    [fieldName, setFieldValue]
  );

  return {
    getValue,
    handleSubmit,
    registerField,
    discardChanges,
    setValue,
  };
};
