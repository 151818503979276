import type { ComponentSourceData, JuxAsset } from '@jux/data-entities';

export enum UploadStatus {
  InProgress = 'in_progress',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export type Asset = Pick<JuxAsset, 'name' | 'type' | 'data'> & {
  content: string;
  component?: ComponentSourceData;
};

export type UploadAssetState = Asset & {
  status: {
    type: UploadStatus;
    error?: string;
  };
};
