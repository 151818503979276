import { Colors } from '../base/base-theme-colors';
import { palette, TRANSPARENCY_PATTERN_BACKGROUND } from '../palette';

export const lightColors: Colors = {
  // Primary
  primaryColor: palette.naturals800,

  // Tooltip
  tooltipTextColor: palette.naturals100,
  tooltipBackground: palette.naturals800,

  // Toast
  toastBackground: palette.naturals0,

  // Backdrop
  backdrop: 'rgba(0, 0, 0, 0.1)',

  // Severity colors
  error: palette.naturals800,
  success: '#4caf50',
  info: '#2196F3',
  warning: '#D14343',

  // Border
  primaryBorder: palette.naturals200,
  secondaryBorder: palette.naturals100,
  hoverBorder: palette.naturals400,

  // Background
  primaryBackground: palette.naturals0,
  hoverBackground: palette.naturals150,
  secondaryBackground: palette.naturals200,
  secondaryHoverBackground: palette.naturals300,
  selectedBackground: palette.naturals200,
  selectedHoverBackground: palette.naturals300,
  navigationBackground: palette.naturals0,

  // Text
  primaryText: palette.naturals800,
  secondaryText: palette.naturals400,
  buttonText: palette.naturals0,
  contrastText: palette.naturals0,
  disabledText: palette.naturals300,

  // Shadows
  primaryShadow: 'rgba(0, 0, 0, 0.20)',

  // Other
  transparencyPatternBackground: TRANSPARENCY_PATTERN_BACKGROUND,
};
