/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';

const { styled } = createDrimz();

type KeyboardHeader_text_root_Props = PropsOf<typeof JuxReactElements.JuxText>;

const KeyboardHeader_text_root: FC<KeyboardHeader_text_root_Props> = forwardRef(
  (props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />
);

KeyboardHeader_text_root.displayName = 'KeyboardHeader_text_root';

KeyboardHeader_text_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        color: '#000000',
        width: 'auto',
        height: 'auto',
        display: 'inline-block',
        fontSize: '{typography.core.12_bold.fontSize}',
        fontFamily: '{typography.core.12_bold.fontFamily}',
        fontWeight: '{typography.core.12_bold.fontWeight}',
        lineHeight: '{typography.core.12_bold.lineHeight}',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        letterSpacing: '{typography.core.12_bold.letterSpacing}',
      },
    },
  }) as KeyboardHeader_text_root_Props['rootElement'],
};

export type KeyboardHeader_text_Props = KeyboardHeader_text_root_Props;

export const KeyboardHeader_text: FC<KeyboardHeader_text_Props> = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>,
  KeyboardHeader_text_Props
>((props, ref) => <KeyboardHeader_text_root {...props} ref={ref} />);

KeyboardHeader_text.displayName = 'KeyboardHeader_text';
KeyboardHeader_text.defaultProps = { text: 'Text Element' };

import { IconButton, type IconButton_Props } from '../';

type KeyboardHeader_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;

const KeyboardHeader_root: FC<KeyboardHeader_root_Props> = forwardRef(
  (props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />
);

KeyboardHeader_root.displayName = 'KeyboardHeader_root';

KeyboardHeader_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: '280px',
        height: '52px',
        display: 'flex',
        padding: '0px 20px 0px 20px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF',
      },
    },
  }) as KeyboardHeader_root_Props['rootElement'],
};

export type KeyboardHeader_Props = KeyboardHeader_root_Props & {
  KeyboardHeader_text_Props?: KeyboardHeader_text_Props;
  IconButton_Props?: IconButton_Props;
};

export const KeyboardHeader: FC<KeyboardHeader_Props> = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>,
  KeyboardHeader_Props
>(({ KeyboardHeader_text_Props, IconButton_Props, ...props }, ref) => (
  <KeyboardHeader_root
    {...props}
    ref={ref}
    data-jux-context="701e36e6-70a3-4851-9c6a-fbb9904dce08"
  >
    {props.children || (
      <>
        <KeyboardHeader_text
          {...KeyboardHeader_text_Props}
          data-jux-context="da552fec-af07-4aa1-bd83-03f7e3505602"
        />
        <IconButton
          disabled={false}
          hierarchy={'primary'}
          {...IconButton_Props}
        />
      </>
    )}
  </KeyboardHeader_root>
));

KeyboardHeader.displayName = 'KeyboardHeader';
KeyboardHeader.defaultProps = {};
