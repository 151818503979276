import { FC, memo } from 'react';
import Cursors from '../../components/Cursor';
import { CanvasRenderer } from '../CanvasRenderer';
import { NodeRenderer } from '../NodeRenderer';
import { Viewport } from '../Viewport';
import { GraphViewProps } from './CanvasView.interface';

/*
 * This is the main component that renders the canvas view including all helpers (such as zoom pane) and the actual nodes.
 */
export const CanvasView: FC<GraphViewProps> = memo(
  ({
    selectionOnDrag,
    defaultViewport,
    translateExtent,
    zoomOnScroll,
    zoomOnPinch,
    panOnScroll,
    panOnScrollSpeed,
    panOnScrollMode,
    zoomOnDoubleClick,
  }) => (
    <CanvasRenderer
      selectionOnDrag={selectionOnDrag}
      zoomOnScroll={zoomOnScroll}
      zoomOnPinch={zoomOnPinch}
      zoomOnDoubleClick={zoomOnDoubleClick}
      panOnScroll={panOnScroll}
      panOnScrollSpeed={panOnScrollSpeed}
      panOnScrollMode={panOnScrollMode}
      defaultViewport={defaultViewport}
      translateExtent={translateExtent}
    >
      <Viewport>
        <Cursors />
        <NodeRenderer />
      </Viewport>
    </CanvasRenderer>
  )
);

CanvasView.displayName = 'CanvasView';
