import {
  CanjuxState,
  ComponentActionsParams,
  JuxStoreActionFn,
  setLayersData,
} from '@jux/canjux/core';

/**
 * Update a component's display name
 */
export const updateComponentDisplayName: JuxStoreActionFn<
  ComponentActionsParams['updateComponentDisplayName'],
  CanjuxState
> = ({ displayName, nodeId, state }) => {
  if (!nodeId) {
    return state;
  }

  state.components[nodeId].displayName = displayName;

  setLayersData(state);

  return state;
};
