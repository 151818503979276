/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const JUXLongLogoIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="59"
    height="16"
    attr={{
      viewBox: '0 0 59 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M6 15.5C3.04635 15.5 1.78064 14.5113 0 12.9148L2.44112 9.10285C3.5219 10.2355 7 12.5 7 8.4946V5.00003L2 5V0L12 3.46451e-05V9.10285C12 13 9.8005 15.5 6 15.5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M53.1581 7.5L48 15H53.9402L59 7.5L53.9402 0H48L53.1581 7.5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M35.1064 7.5L30 15H35.8807L38 11.8268L40.1193 15H46L40.8936 7.5L46 0H40.1193L38 3.17322L35.8807 0H30L35.1064 7.5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M21.5 15.5C16.5 15.5 14 12.5 14 8.5V0H19V8C19 9.5 20 10.6759 21.5 10.6759C23 10.6759 24 9.5 24 8V0H29V8.5C29 12.5 26.5 15.5 21.5 15.5Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
