import { createSelector } from 'reselect';
import { CanjuxState } from '@jux/canjux/core';
import { getSelectedNodeId } from './utils';

export const selectedSourceComponentCanvas = createSelector(
  [
    (state: CanjuxState) => state.components,
    (state: CanjuxState) => state.canvases,
    (state: CanjuxState) => state.selectedNodesStack,
  ],
  (components, canvases, selectedNodesStack) => {
    const selectedNodeId = getSelectedNodeId(selectedNodesStack);

    if (!selectedNodeId) return null;

    const sourceComponentId =
      components[selectedNodeId]?.sourceComponentId || selectedNodeId;

    for (const canvas of Object.values(canvases)) {
      if (sourceComponentId in canvas.nodes) {
        return canvas;
      }
    }
    return null;
  }
);
