// eslint-disable-next-line @jux/must-import-FC-in-tsx-files
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const BoxShadowYDDPIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M10.0277 11.7777C10.3206 11.4848 10.3206 11.0099 10.0277 10.7171C9.73483 10.4242 9.25995 10.4242 8.96706 10.7171L8.74739 10.9367L8.74739 5.06328L8.96706 5.28295C9.25995 5.57584 9.73483 5.57584 10.0277 5.28295C10.3206 4.99005 10.3206 4.51518 10.0277 4.22229L8.52772 2.72229C8.23483 2.42939 7.75995 2.42939 7.46706 2.72229L5.96706 4.22229C5.67417 4.51518 5.67417 4.99005 5.96706 5.28295C6.25995 5.57584 6.73483 5.57584 7.02772 5.28295L7.24739 5.06328L7.24739 10.9367L7.02772 10.7171C6.73483 10.4242 6.25995 10.4242 5.96706 10.7171C5.67417 11.0099 5.67417 11.4848 5.96706 11.7777L7.46706 13.2777C7.75995 13.5706 8.23483 13.5706 8.52772 13.2777L10.0277 11.7777Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
