import {
  CommonActionsParams,
  JuxStoreActionFn,
  selectCurrentCanvas,
  setLayersData,
  storeApi,
} from '@jux/canjux/core';
import { ComponentTagNames } from '@jux/data-entities';
import { ELEMENTS_DATA } from '@jux/elements';
import type { Draft as WritableDraft } from 'mutative';
import { CanjuxState } from '../../store';
import { getDefaultNodeData, getValidComponentPlacement } from '../helpers';
import { createNode } from './createNode';

const createTextNodeInternal = ({
  state,
  targetCanvasName,
  targetNodeId,
  text,
}: Pick<CommonActionsParams['createTextNode'], 'text' | 'targetCanvasName'> & {
  state: WritableDraft<CanjuxState>;
  targetNodeId?: string;
}) => {
  const createTextElement = ELEMENTS_DATA[ComponentTagNames.JuxText];
  const textElementData = createTextElement({ text });

  const { width, height, transform } = state;
  const currentCanvas = selectCurrentCanvas(storeApi.getState());
  const {
    targetNodeId: parentId,
    targetChildIndex,
    position,
  } = getValidComponentPlacement({
    targetNodeId,
    components: state.components,
    canvas: currentCanvas,
    transform,
    canvasDimensions: { width, height },
    canvasNodesDimensions: state.canvasNodesDimensions,
  });

  const component = textElementData.root;
  component.parentId = parentId;
  const nodeData = getDefaultNodeData({ parentId, position });

  createNode({
    canvasName: targetCanvasName,
    data: {
      node: nodeData,
      component,
      targetIndex: targetChildIndex,
    },
    nodeId: component.id,
    state,
  });

  return state;
};

export const createTextNode: JuxStoreActionFn<
  CommonActionsParams['createTextNode'],
  CanjuxState
> = ({ targetCanvasName, targetNodeIds, text, state }) => {
  if (targetNodeIds?.length) {
    for (const targetNodeId of targetNodeIds) {
      createTextNodeInternal({
        state,
        targetCanvasName,
        targetNodeId,
        text,
      });
    }
  } else {
    createTextNodeInternal({
      state,
      targetCanvasName,
      text,
    });
  }

  setLayersData(state);

  return state;
};
