import { useMemo } from 'react';
import { useUserOrganizationsQuery } from '@jux/ui/hooks';
import { Organization } from '@jux/data-entities';

export const useCurrentOrg = () => {
  const {
    userOrganizations: [org],
    hasOrganizations,
  } = useUserOrganizationsQuery();

  const currentOrganization = useMemo(() => {
    if (hasOrganizations) {
      return org;
    }
    return {} as Organization;
  }, [hasOrganizations, org]);

  return currentOrganization;
};
