import { NodeData } from '@jux/data-entities';

export const defaultNodeData: NodeData = {
  position: { x: 0, y: 0 },
  properties: {
    isContainer: false,
    isDeletable: true,
    isDraggable: true,
    isDragged: false,
    isHidden: false,
    isImmutable: false,
    isSelectable: true,
  },
};

export const defaultInstanceNodeData: NodeData = {
  position: { x: 0, y: 0 },
  properties: {
    isContainer: false,
    isDeletable: false,
    isDraggable: false,
    isDragged: false,
    isHidden: false,
    isImmutable: false,
    isSelectable: true,
  },
};
