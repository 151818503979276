/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';

const { styled } = createDrimz();

type Token_management_text_root_Props = PropsOf<
  typeof JuxReactElements.JuxText
>;

const Token_management_text_root: FC<Token_management_text_root_Props> =
  forwardRef((props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />);

Token_management_text_root.displayName = 'Token_management_text_root';

Token_management_text_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        color: '{color.core.grays_200}',
        width: 'auto',
        height: 'auto',
        fontFamily: 'Inter',
        fontSize: '14px',
        lineHeight: '130%',
        display: 'inline-block',
        overflowWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      },
    },
  }) as Token_management_text_root_Props['rootElement'],
};

export type Token_management_text_Props = Token_management_text_root_Props;

export const Token_management_text: FC<Token_management_text_Props> =
  forwardRef<
    ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>,
    Token_management_text_Props
  >((props, ref) => <Token_management_text_root {...props} ref={ref} />);

Token_management_text.displayName = 'Token_management_text';
Token_management_text.defaultProps = { text: 'Token management' };

type Token_management_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;

const Token_management_root: FC<Token_management_root_Props> = forwardRef(
  (props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />
);

Token_management_root.displayName = 'Token_management_root';

Token_management_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: '338px',
        height: '32px',
        backgroundColor: '{color.core.grays_800}',
        display: 'flex',
        padding: '8px 8px 8px 8px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderStyle: 'none',
        borderColor: '',
      },
    },
  }) as Token_management_root_Props['rootElement'],
};

export type Token_management_Props = Token_management_root_Props & {
  Token_management_text_Props?: Token_management_text_Props;
};

export const Token_management: FC<Token_management_Props> = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>,
  Token_management_Props
>(({ Token_management_text_Props, ...props }, ref) => (
  <Token_management_root
    {...props}
    ref={ref}
    data-jux-context="2b1598a6-aee0-40be-af8a-fef09ff943ab"
  >
    {props.children || (
      <>
        <Token_management_text
          {...Token_management_text_Props}
          data-jux-context="0534134e-f4e4-45e6-9c86-d48451e14892"
        />
      </>
    )}
  </Token_management_root>
));

Token_management.displayName = 'Token_management';
Token_management.defaultProps = {};
