import { useCallback, useEffect, useRef } from 'react';

// useEscapeKey hook is used to call a callback when the user presses the escape key, at any given time.
export function useEscapeKey<T extends HTMLElement>(callback: () => void) {
  const ref = useRef<T | null>(null);

  const handleEscape = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        callback();
      }
    },
    [callback]
  );

  useEffect(() => {
    const { current } = ref;

    current?.addEventListener('keydown', handleEscape, false);

    return () => {
      current?.removeEventListener('keydown', handleEscape, false);
    };
  }, [handleEscape]);

  return ref;
}
