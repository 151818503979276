import { createContext, FC, ReactNode } from 'react';
import { ThemeConfig } from '@jux/types';

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export const ThemeContext = createContext<DeepPartial<ThemeConfig>>({});

type ThemeProviderProps<
  T extends Record<string, DeepPartial<ThemeConfig>> = {
    [key in string]: DeepPartial<ThemeConfig>;
  }
> = {
  children?: ReactNode;
} & (
  | {
      theme: T extends Record<string, DeepPartial<ThemeConfig>> ? T : never;
      current: keyof T;
    }
  | {
      theme: DeepPartial<ThemeConfig>;
    }
);

export const ThemeProvider: FC<ThemeProviderProps> = (props) => {
  return (
    <ThemeContext.Provider
      value={'current' in props ? props.theme[props.current] : props.theme}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};
