import { AssetsActionsParams, JuxStoreActionFn } from '../../store.interface';
import { CanjuxState } from '@jux/canjux/core';

/**
 * Delete an asset.
 */
export const deleteAsset: JuxStoreActionFn<
  AssetsActionsParams['deleteAsset'],
  CanjuxState
> = ({ assetId, state }) => {
  delete state.assets[assetId];

  return state;
};
