import { PropsWithChildren, forwardRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import {
  Popover as MuiPopover,
  PopoverProps as MuiPopoverProps,
} from '@mui/material';
import { bindPopover, PopupStateType } from '@jux/ui/components/common/mui';
import { Backdrop } from './Backdrop';

type PopoverProps = PropsWithChildren<{ popupState: PopupStateType }> &
  Partial<MuiPopoverProps>;

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  ({ children, popupState, hideBackdrop, ...props }, ref) => (
    <MuiPopover {...bindPopover(popupState)} hideBackdrop {...props} ref={ref}>
      {children}
      <Backdrop
        transparent={hideBackdrop}
        open={popupState.isOpen}
        onClick={popupState.close}
        zIndex={-1}
      />
    </MuiPopover>
  )
);
