import { ForwardedRef, JSX, RefAttributes, forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { ComponentConfigWithStates } from '@jux/types';
import * as S from './ComponentPreview.style';
import { ElementPreview } from './ElementPreview';

export const ComponentPreview = forwardRef(
  (
    {
      componentId,
      componentDragRef,
      componentRectRef,
      loadingRenderer,
      previewStyles,
      previewPortalContainer,
      showLoading,
      transformRatio,
    }: {
      componentId: string;
      componentDragRef: RefAttributes<HTMLElement>['ref'];
      componentRectRef: RefAttributes<HTMLElement>['ref'];
      loadingRenderer: JSX.Element;
      previewStyles: ComponentConfigWithStates;
      previewPortalContainer: HTMLElement;
      showLoading: boolean;
      transformRatio?: number | undefined;
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <S.ComponentPreviewWrapper ref={ref} container item>
      {showLoading && (
        <S.ComponentPreviewLoadingWrapper>
          {loadingRenderer}
        </S.ComponentPreviewLoadingWrapper>
      )}
      {/* This is the actual component preview that is visible in the panel */}
      <S.ComponentPreviewScaled transformRatio={transformRatio}>
        <ElementPreview
          ref={componentDragRef}
          componentId={componentId}
          styleOverrides={previewStyles}
        />
      </S.ComponentPreviewScaled>
      {/* Rendering the element in a portal to determine its actual size */}
      {createPortal(
        <S.ComponentPreviewStretched>
          <ElementPreview ref={componentRectRef} componentId={componentId} />
        </S.ComponentPreviewStretched>,
        previewPortalContainer
      )}
    </S.ComponentPreviewWrapper>
  )
);
