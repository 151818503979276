import { generateName } from '@jux/ui/utils/generateName';

const NEW_PROP_NAME_PREFIX = 'prop';
const NEW_PROP_VALUE_PREFIX = 'value';

export const generatePropName = (allPropNames: Array<string>) =>
  generateName({
    baseName: NEW_PROP_NAME_PREFIX,
    namesArray: allPropNames,
    separator: '-',
    options: {
      formatters: ['formatCase', 'formatWhitespace', 'removeIllegalChars'],
    },
  });

export const generatePropValueName = (allValueNames: Array<string>) =>
  generateName({
    baseName: NEW_PROP_VALUE_PREFIX,
    namesArray: allValueNames,
    separator: '-',
  });
