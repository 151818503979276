import {
  PopperDirection,
  PopperPosition,
  PopperPositioning,
  PopperSourceElement,
} from '../base-hooks/usePopperState.interface';

export const calculatePositionByElement = ({
  element,
  positioning = 'bottom-left',
  offset,
  direction = 'ltr',
}: {
  element: PopperSourceElement;
  positioning?: PopperPositioning;
  offset?: PopperPosition;
  direction?: PopperDirection;
}): PopperPosition => {
  if (!element) return { top: 0, left: 0 };

  const { top, left, width, height } = element.getBoundingClientRect();

  let topPosition = 0;
  let leftPosition = 0;

  switch (positioning) {
    case 'bottom-left': {
      topPosition = top + height;
      leftPosition = left;
      break;
    }
    case 'bottom-right': {
      topPosition = top + height;
      leftPosition = left + width;
      break;
    }
    default:
      break;
  }

  return {
    top: topPosition + (offset?.top ?? 0),
    left: leftPosition + (offset?.left ?? 0),
    transform: direction === 'rtl' ? 'translateX(-100%)' : undefined,
  };
};
