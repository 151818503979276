import { createSelector } from 'reselect';
import { CanjuxState } from '@jux/canjux/core';
import { getSelectedNodeInstances } from './utils';

export const selectedNodeComponentInstancesCount = createSelector(
  [
    (state: CanjuxState) => state.components,
    (state: CanjuxState) => state.selectedNodesStack,
  ],
  (components, selectedNodesStack) => {
    // find all components instances of the selected node
    const instancesOfSelectedNode = getSelectedNodeInstances({
      components,
      selectedNodesStack,
    });

    return instancesOfSelectedNode?.length || 0;
  }
);
