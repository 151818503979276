import { createSelector } from 'reselect';
import { CanjuxState } from '@jux/canjux/core';
import { getAssetDataById, getSelectedNodeId } from './utils';
import { ComponentTagNames, NodeType } from '@jux/data-entities';

export const selectedNodeComponentAssetData = createSelector(
  [
    (state: CanjuxState) => state.assets,
    (state: CanjuxState) => state.components,
    (state: CanjuxState) => getSelectedNodeId(state.selectedNodesStack),
  ],
  (assets, components, selectedNodeId) => {
    if (!selectedNodeId) return undefined;

    const component = components[selectedNodeId];
    if (!component) return undefined;

    // if node id is not an svg node or it doesn't have a source component id
    switch (component.type) {
      case NodeType.ELEMENT:
        // SVG elements are connected to their source svg component
        if (
          component.tagName !== ComponentTagNames.JuxSvg ||
          !component?.sourceComponentId
        ) {
          return undefined;
        }
        return getAssetDataById({ assets, id: component.sourceComponentId });
      case NodeType.LIBRARY_COMPONENT:
        return getAssetDataById({ assets, id: component.id });
    }

    return undefined;
  }
);
