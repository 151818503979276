import { CanjuxState } from '@jux/canjux/core';
import { getSelectedNodeId } from './getSelectedNodeId';
import { getNodeComponentById } from './getNodeComponentById';

export const getSelectedNodeComponent = ({
  components,
  selectedNodesStack,
}: {
  components: CanjuxState['components'];
  selectedNodesStack: CanjuxState['selectedNodesStack'];
}) => {
  const selectedNodeId = getSelectedNodeId(selectedNodesStack);

  return selectedNodeId
    ? getNodeComponentById({ components, id: selectedNodeId })
    : undefined;
};
