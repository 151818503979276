import { FC } from 'react';
import { DimensionField } from '../../../../base';
import { usePaddingFields } from './usePaddingFields';

export const PaddingRightField: FC = () => {
  const { paddingRightField, isComputed } = usePaddingFields();

  return (
    <DimensionField
      icon="DDP_FIELD_PADDING_RIGHT"
      tooltipTitle="Padding right"
      {...paddingRightField}
      isComputed={isComputed}
    />
  );
};
