import { RefObject, useEffect, useMemo, useState } from 'react';
import { withPx } from '@jux/ui/utils/css';

/**
 * Hook to get the max width of a given element relative to another element.
 * @param relativeRef - The reference to the element to compare against.
 * @param spacing - The spacing to add to the max width.
 * @returns The max width relative to the ref.
 */
export function useMaxWidthRelativeToRef<T extends HTMLElement>({
  relativeRef: ref,
  spacing,
}: {
  relativeRef: RefObject<T>;
  spacing?: number;
}) {
  const [maxWidth, setMaxWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    const element = ref.current;

    const handleResize = () => {
      setMaxWidth(element?.getBoundingClientRect().width ?? 0);
    };

    handleResize();

    element?.addEventListener('resize', handleResize);

    return () => element?.removeEventListener('resize', handleResize);
  }, [ref]);

  return useMemo(
    () => `calc(100% - ${withPx((maxWidth ?? 0) + (spacing ?? 0))})`,
    [maxWidth, spacing]
  );
}
