/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { FC } from 'react';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
import { IconBaseProps } from '../../Icons.interface';

export const CheckboxElementPanelIcon: FC<IconBaseProps> = ({
  textColor,
  ...props
}) => (
  <CustomIcon
    tag="svg"
    width="32"
    height="33"
    attr={{
      viewBox: '0 0 32 33',
    }}
    child={[
      {
        tag: 'rect',
        attr: {
          y: '0.5',
          width: '32',
          height: '32',
          rx: '8',
          fill: 'currentColor',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          d: 'M10 16.5L15 21.5L23 11.5',
          stroke: textColor || 'currentColor',
          strokeWidth: '3',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
