import { useMemo } from 'react';
import { RegisterOptions, UseFormRegisterReturn } from 'react-hook-form';
import { useZodForm } from '@jux/ui/hooks/useZodForm';

export const useRegisterFieldFns = <T extends ReturnType<typeof useZodForm>>(
  form: T,
  fields: Array<
    | string
    | {
        [key: string]: RegisterOptions;
      }
  >
) =>
  useMemo(
    () =>
      fields.map<() => UseFormRegisterReturn<string>>((field) => () => {
        if (typeof field === 'string') {
          return form.register(field);
        }
        const [name, options] = Object.entries(field)[0];
        return form.register(name, options);
      }),
    [form, fields]
  );
