import {
  CanjuxState,
  CommonActions,
  AssetsActions,
  PropsActions,
  JuxStoreActionFn,
  ComponentActions,
} from '@jux/canjux/core';
import type { Draft as WritableDraft } from 'mutative';
import {
  validateAll,
  validateAssets,
  validateNodesComponents,
  validateNoDetachedNodes,
  validateRootNodes,
} from '../validators';
import { StateValidator } from '../validators/validator.interface';

type ValidationsMap = Partial<{
  [K in
    | keyof CommonActions
    | keyof AssetsActions
    | keyof PropsActions
    | keyof ComponentActions]: Array<
    StateValidator | { validator: StateValidator; recover: boolean }
  >;
}>;

const actionToValidationsMap: ValidationsMap = {
  createAsset: [validateAssets],
  createComponentFromElement: [validateAll],
  createComponentInstanceNodeAction: [validateAll],
  createNodeWithChildren: [
    validateNodesComponents,
    validateNoDetachedNodes,
    validateRootNodes,
    { validator: validateAssets, recover: true },
  ],
  createTextNode: [validateAll],
  deleteAsset: [validateAssets, validateNodesComponents],
  replaceAssetContent: [validateAssets, validateNodesComponents],
  deleteComponent: [validateAll],
  deleteNode: [validateAll],
  moveNodes: [validateAll],
  pasteCopiedNodes: [validateAll],
  restoreSourceComponent: [validateAll],
  wrapWithDiv: [validateAll],
};

export const postActionValidation =
  (actionName: string) =>
  <T extends CanjuxState>(actionFn: JuxStoreActionFn<any, T>) =>
  <P extends { state: WritableDraft<T> }>(params: P): WritableDraft<T> => {
    const result = actionFn(params);

    const validations =
      actionName in actionToValidationsMap
        ? actionToValidationsMap[actionName as keyof ValidationsMap]
        : [];
    for (const validation of validations ?? []) {
      if (typeof validation === 'function') {
        validation({ state: result, recover: false });
      } else {
        validation.validator({ state: result, recover: validation.recover });
      }
    }

    return result;
  };
