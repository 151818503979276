import { MuiGrid, styled } from '@jux/ui/components/common/mui';
import { PROPERTIES_PANEL_BOX_SHADOW_COLOR } from '@jux/ui/theme/palette';
import { colorWithOpacity } from '@jux/ui/utils/colorWithOpacity';

export const PropertiesPanelWrapper = styled(MuiGrid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  position: 'relative',
  width: 280,
  maxHeight: 'inherit',

  borderRadius: theme.drimz.size.borderRadius.main,
  border: '1px solid',
  borderColor: theme.drimz.palette.divider.primary,
  background: theme.drimz.palette.background.default,
  boxShadow: `0px 2px 12px 0px  ${colorWithOpacity(
    PROPERTIES_PANEL_BOX_SHADOW_COLOR,
    0.2
  )}`,
}));
