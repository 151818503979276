import { MouseEventHandler, useCallback, useRef } from 'react';
import { useNodeClicks } from './handlers/useNodeClicks';
import { useNodeDrag } from './handlers/useNodeDrag';
import { useNodeHover } from './handlers/useNodeHover';

/*
Modes:
1. Normal
2. Shift (multi-select)
3. Command/Meta (direct selection)

User actions:
1. Click node
3. Drag node
4. Drop node
5. Hover node
6. Double-click node

Events:
1. MouseDown
2. MouseUp
3. MouseMove
4. MouseEnter
5. MouseLeave
6. DoubleClick
7. KeyDown
8. KeyUp
9. DragStart
10. Drag
11. DragEnd

Behaviors:
1. Add to selection (multi-select)
2. Remove from selection (multi-select)
3. Replace selection (normal/direct selection)
4. Drag node
  - Single node
  - Multiple nodes
5. Drop node
  - On top of another node
  - On top of empty space
6. Hover node
7. Un-hover node
 */

export function useNodeInteractions({
  nodeId,
  onClick,
}: {
  nodeId: string;
  onClick?: MouseEventHandler;
}) {
  const elementRef = useRef<HTMLElement>(null);

  const { handleDoubleClick } = useNodeClicks({
    nodeId,
  });

  useNodeDrag({
    elementRef,
    nodeId,
    onClick,
  });

  const { handleMouseLeave, handleMouseMove } = useNodeHover({
    nodeId,
  });

  return {
    elementRef,
    registerEvents: useCallback(
      () => ({
        onDoubleClick: handleDoubleClick,
        onMouseLeave: handleMouseLeave,
        onMouseMove: handleMouseMove,
      }),
      [handleDoubleClick, handleMouseLeave, handleMouseMove]
    ),
  };
}
