import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { DisplayField } from './DisplayField';

export const Display: FC = () => {
  const { shouldRender } = useSubModule(DDPSubModulesKeys.display);

  if (!shouldRender) return null;
  return (
    <SubModule title="Display" renderInline>
      <DisplayField />
    </SubModule>
  );
};
