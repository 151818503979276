import { FC, memo } from 'react';
import { MuiGrid, Theme } from '@jux/ui/components/common/mui';
import {
  Background,
  Border,
  Color,
  Context,
  Effects,
  Layout,
  Opacity,
  Props,
  Size,
  Text,
} from './modules';
import * as S from './ModulesMapping.style';

export const ModulesMapping: FC = memo(() => (
  <S.ModulesMappingWrapper>
    <MuiGrid
      sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        borderBottom: (theme: Theme) =>
          `1px solid ${theme.drimz.palette.divider.secondary}`,
        zIndex: 3, // there are icons in modules beneath that overlap 1 & 2
      }}
    >
      <Context />
      <Props />
    </MuiGrid>
    <Size />
    <Layout />
    <Text />
    <Background />
    <Opacity />
    <Border />
    <Color />
    <Effects />
  </S.ModulesMappingWrapper>
));
