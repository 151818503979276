import { useCallback } from 'react';
import {
  getSelectedNodeComponentInstancesUsingPropValueCount,
  selectedNodeComponent,
  selectedNodeComponentInstancesCount,
  useStore,
  useStoreActions,
} from '@jux/canjux/core';
import { useTrackEvents } from '@jux/ui/hooks';
import { useSelectedNodeId } from '@jux/ui/components/editor/hooks';
import { ComponentEnumOption, NodeType } from '@jux/data-entities';
import { useDeletePropConfirmationModal } from './useDeletePropConfirmationModal';
import { useDeletePropValueConfirmationModal } from './useDeletePropValueConfirmationModal';
import { useHandleDeleteProp } from './useHandleDeleteProp';

export const useHandleDeletePropValue = () => {
  const {
    propsActions: { deleteComponentPropValue },
  } = useStoreActions();

  const selectedComponentInstancesCount = useStore(
    selectedNodeComponentInstancesCount
  );
  const getInstancesUsingPropsValue = useStore(
    getSelectedNodeComponentInstancesUsingPropValueCount
  );

  const { openDeletePropConfirmationModal } = useDeletePropConfirmationModal();
  const { openDeletePropValueConfirmationModal } =
    useDeletePropValueConfirmationModal();

  const selectedNodeData = useStore(selectedNodeComponent);
  const selectedNodeId = useSelectedNodeId();

  const { deleteProp } = useHandleDeleteProp();

  const { trackPropValueDeletedEvent } = useTrackEvents();

  const deletePropValue = useCallback(
    (propValueToDelete: string, propName: string) => {
      if (
        !selectedNodeId ||
        !selectedNodeData ||
        (selectedNodeData.type !== NodeType.LIBRARY_COMPONENT &&
          selectedNodeData.type !== NodeType.LOCAL_COMPONENT)
      )
        return;

      deleteComponentPropValue({
        componentId: selectedNodeId,
        propName,
        propValueLabel: propValueToDelete,
      });

      trackPropValueDeletedEvent({
        componentUUID: selectedNodeData.id,
        tagName: selectedNodeData.tagName,
        propName,
        deletedPropValueName: propValueToDelete,
      });
    },
    [
      deleteComponentPropValue,
      selectedNodeData,
      selectedNodeId,
      trackPropValueDeletedEvent,
    ]
  );

  const handleDeletePropValue = useCallback(
    ({
      optionLabelToDelete,
      propName,
      defaultValue,
      options,
    }: {
      optionLabelToDelete: string;
      propName: string;
      defaultValue: string;
      options: Array<ComponentEnumOption>;
    }) => {
      const optionsToDelete = options.find(
        (prop) => prop.label === optionLabelToDelete
      );
      if (!optionsToDelete) {
        return;
      }

      // find the first value that is not the current one
      const optionsWithoutCurrentValue = options.filter(
        (prop) => prop.label !== optionLabelToDelete
      );
      const [fallbackValue] = optionsWithoutCurrentValue;

      // in case there is no other value, open the delete prop confirmation modal
      if (fallbackValue === undefined) {
        if (selectedComponentInstancesCount === 0) {
          deleteProp(propName);
        } else {
          openDeletePropConfirmationModal({
            defaultValue,
            propName,
            onConfirm: () => {
              deleteProp(propName);
            },
          });
        }

        return;
      }

      const instancesUsingPropsValue = getInstancesUsingPropsValue({
        propName,
        propValue: optionsToDelete.value,
      });

      if (instancesUsingPropsValue === 0) {
        deletePropValue(optionLabelToDelete, propName);
      } else {
        openDeletePropValueConfirmationModal({
          propName,
          valueToDelete: optionLabelToDelete,
          fallbackOptionLabel: fallbackValue.label,
          onConfirm: () => {
            deletePropValue(optionLabelToDelete, propName);
          },
        });
      }
    },
    [
      deleteProp,
      deletePropValue,
      getInstancesUsingPropsValue,
      openDeletePropConfirmationModal,
      openDeletePropValueConfirmationModal,
      selectedComponentInstancesCount,
    ]
  );

  return {
    handleDeletePropValue,
  };
};
