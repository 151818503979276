import { FC, memo } from 'react';
import { DDPMessage } from './DDPMessage';
import { useDDPContent } from './hooks';
import { InstanceModulesMapping } from './InstanceModulesMapping';
import { LiveModeIsOn } from './LiveModeIsOn';
import { ModulesMapping } from './ModulesMapping';

export const DDPContent: FC = memo(() => {
  const {
    isLive,
    isImmutable,
    isComponentInstance,
    isLogicalSlot,
    isMatrix,
    multipleSelected,
    nonSelected,
  } = useDDPContent();

  if (isLive) return <LiveModeIsOn />;

  if (nonSelected)
    return (
      <DDPMessage text="Select an object from the canvas in order to edit its UI" />
    );

  if (isLogicalSlot)
    return (
      <DDPMessage text="This is a logic layer, select a different object from the canvas in order to edit its UI" />
    );

  if (multipleSelected)
    return (
      <DDPMessage text="Currently, multi-object selection does not support styling options" />
    );

  if (isImmutable)
    return (
      <DDPMessage>
        Selected element is a part of an instance of component.
        <br />
        Go to source component to edit.
      </DDPMessage>
    );

  if (isMatrix)
    return <DDPMessage text="Cannot edit matrix wrapper styles yet ;)" />;

  if (isComponentInstance) return <InstanceModulesMapping />;

  return <ModulesMapping />;
});
