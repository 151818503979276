import { forwardRef } from 'react';
import {
  LoadingButtonWithIcon as Button,
  LoadingButtonWithIconProps as ButtonProps,
} from '@jux/ui/components';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';

type ModuleToggleButtonIcons = Extract<
  ButtonProps['icon'],
  | 'DDP_COLLAPSED_MODULE'
  | 'DDP_EXPANDED_MODULE'
  | 'DDP_UNIFIED_SIDES_SUB_MODULE'
  | 'DDP_SEPARATED_SUB_MODULE'
>;

type ModuleToggleButtonProps = {
  icon: ModuleToggleButtonIcons;
  isSelected?: boolean;
} & Omit<ButtonProps, 'icon'>;

const BUTTON_SIZE = 24;

export const ModuleToggleButton = forwardRef<
  HTMLButtonElement,
  ModuleToggleButtonProps
>(({ isSelected, sx, ...props }, ref) => (
  <Button
    color={(theme) =>
      isSelected
        ? theme.drimz.palette.text.primary
        : theme.drimz.palette.text.secondary
    }
    sx={mergeTyped(
      {
        width: BUTTON_SIZE,
        minWidth: BUTTON_SIZE,
        height: BUTTON_SIZE,

        borderRadius: (theme) => theme.drimz.size.borderRadius.main,

        '&:hover': {
          backgroundColor: (theme) => theme.drimz.palette.background.hover,
        },

        // Don't remove this style by mistake, it's important for keyboard navigation
        '&:focus-visible': {
          backgroundColor: (theme) => theme.drimz.palette.background.hover,
        },

        '&:active': {
          backgroundColor: (theme) => theme.drimz.palette.background.selected,
        },
      },
      sx
    )}
    {...props}
    ref={ref}
  />
));
