import { UseComboboxStateChange, useCombobox } from 'downshift';
import { useCallback, useState, useEffect, useMemo } from 'react';
import { useEffectOnSelectedNodeChange } from '@jux/ui/components/editor/hooks';
import { useEffectOnSelectedNodeStateChange } from '../../../hooks';

import {
  AutocompleteOptionData,
  AutocompleteProps,
} from './Autocomplete.interface';

export const useSelectFieldAutocomplete = ({
  initialOptions,
  selectedOption,
  onSelect,
  isOpen: alwaysOpen = false,
}: {
  initialOptions: Array<AutocompleteOptionData>;
  selectedOption?: string;
  onSelect: AutocompleteProps['onSelect'];
  isOpen?: boolean;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const close = useCallback(() => setIsMenuOpen(false), []);

  const open = useCallback(() => setIsMenuOpen(true), []);

  const [inputValue, setInputValue] = useState(selectedOption || '');

  const [options, setOptions] = useState(initialOptions);

  const initialSelectedItem = useMemo(
    () => options.find((option) => option.label === selectedOption),
    [options, selectedOption]
  );

  const initOptions = useCallback(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  const handleInputValueChange = useCallback(
    ({
      inputValue: newInputValue,
      isOpen,
      type,
    }: UseComboboxStateChange<AutocompleteOptionData>) => {
      // Handle Escape key - clear input value, close menu and reset options
      if (type === useCombobox.stateChangeTypes.InputKeyDownEscape) {
        setInputValue('');
        initOptions();
        return;
      }

      // Handle input value change when the menu is closed
      if (!isOpen) {
        setInputValue(newInputValue || inputValue || '');
        initOptions();
        return;
      }

      // Handle input value change when value is empty
      if (!newInputValue) {
        setInputValue('');
        initOptions();
        return;
      }

      // Handle input value change when menu is open and value is not empty
      const filteredOptions = initialOptions.filter((option) =>
        option.label.toLowerCase().includes(newInputValue.toLowerCase())
      );
      setInputValue(newInputValue);
      setOptions(filteredOptions);
    },
    [initOptions, initialOptions, inputValue, setInputValue]
  );

  const handleSelectedItemChange = useCallback(
    ({ selectedItem }: UseComboboxStateChange<AutocompleteOptionData>) => {
      if (!selectedItem) return;
      // call onSelect with the title or the value if there is no title
      setInputValue(selectedItem.label);
      onSelect?.(selectedItem);
      initOptions();
      close();
    },
    [setInputValue, onSelect, initOptions, close]
  );

  const handleIsOpenChange = useCallback(
    ({
      isOpen,
      inputValue: newInputValue,
      type,
    }: UseComboboxStateChange<AutocompleteOptionData>) => {
      // Handle Escape key - close menu and clear input value
      if (type === useCombobox.stateChangeTypes.InputKeyDownEscape) {
        setInputValue('');
        initOptions();

        // Either clear the input value or close the menu
        if (isOpen || inputValue) {
          open();
          setInputValue('');
        } else {
          close();
        }

        return;
      }

      // Override Downshift default behavior of clearing the input value when the menu closes
      if (!isOpen) {
        setInputValue(newInputValue || selectedOption || '');
        initOptions();
        close();
        return;
      }

      // Just open
      open();
    },
    [close, initOptions, inputValue, open, selectedOption]
  );

  const handleItemToString = useCallback(
    (item: AutocompleteOptionData | null) => item?.label || '',
    []
  );

  const { getMenuProps, getInputProps, getItemProps, highlightedIndex } =
    useCombobox<AutocompleteOptionData>({
      inputValue,
      initialSelectedItem,
      isOpen: alwaysOpen || isMenuOpen,
      items: options,
      onInputValueChange: handleInputValueChange,
      onSelectedItemChange: handleSelectedItemChange,
      itemToString: handleItemToString,
      onIsOpenChange: handleIsOpenChange,
    });

  const handleEffectChanges = useCallback(() => {
    if (!selectedOption) return;

    setInputValue(selectedOption);
  }, [selectedOption, setInputValue]);

  useEffectOnSelectedNodeChange(handleEffectChanges);
  useEffectOnSelectedNodeStateChange(handleEffectChanges);

  const handleInputBlur = useCallback(() => {
    setInputValue(selectedOption || '');
    close();
  }, [close, selectedOption, setInputValue]);

  return {
    getInputProps,
    getItemProps,
    getMenuProps,
    handleInputBlur,
    highlightedIndex,
    isOpen: isMenuOpen,
    options,
  };
};
