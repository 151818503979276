export function popperControls(popperId: string) {
  return {
    open: () => {
      const popper = document?.getElementById(popperId);
      popper?.showPopover();
    },
    close: () => {
      const popper = document?.getElementById(popperId);
      popper?.hidePopover();
    },
  };
}
