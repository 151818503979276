import { forwardRef } from 'react';
import { BoxProps, MuiBox } from '@jux/ui/components/common/mui';

export const FullHeightForm = forwardRef<HTMLFormElement, BoxProps>(
  (props, ref: any) => (
    <MuiBox
      component="form"
      noValidate
      ref={ref}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      {...props}
    />
  )
);
