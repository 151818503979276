import {
  COMPONENT_TAG_NAME,
  ComponentElementType,
  ComponentProps,
} from '@jux/data-entities';
import { forwardRef } from 'react';

export const Textarea = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxTextarea>,
  ComponentProps<typeof COMPONENT_TAG_NAME.JuxTextarea>
>(({ disabled, rootElement: RootElement = 'textarea', ...props }, ref) => (
  <RootElement ref={ref} {...props} disabled={disabled} />
));
