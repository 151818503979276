import { forwardRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { TextField, TextFieldProps } from '@jux/ui/components';

export type StyledTextFieldProps = TextFieldProps & UseFormRegisterReturn;

export const StyledTextField = forwardRef<
  HTMLInputElement,
  StyledTextFieldProps
>(({ inputProps, ...props }, ref) => (
  <TextField
    label={inputProps?.placeholder}
    fullWidth
    type="text"
    autoComplete="off"
    InputLabelProps={{
      focused: true,
      shrink: true,
    }}
    /* Override label behavior to work around overlapping text issue.
       (https://mui.com/material-ui/react-text-field/#shrink) */
    inputProps={{
      ...inputProps,
      placeholder: '',
    }}
    {...props}
    ref={ref}
  />
));
