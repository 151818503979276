import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../base';
import { useSubModule } from '../../../hooks';
import { BackgroundColorField } from './BackgroundColorField';

export const BackgroundColor: FC = () => {
  const { shouldRender } = useSubModule(DDPSubModulesKeys.backgroundColor);

  if (!shouldRender) return null;
  return (
    <SubModule title="Background" hideHeader>
      <BackgroundColorField />
    </SubModule>
  );
};
