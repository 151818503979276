import * as uuid from 'uuid';
import {
  ContextStylesList,
  ComponentConfigWithStates as StylesConfig,
} from '@jux/types';
import {
  VariantsConfig,
  ComponentPropType,
  NodeType,
  ComponentTagNames,
} from '@jux/data-entities';
import { Colors } from '../../common/colors';
import { getJuxSvgElementData } from '../svg';
import { ElementDataInit } from '../../types/ElementDataInit';
import {
  JUX_ASSET_CHECKED_ICON_ID,
  JUX_ASSET_INDETERMINATE_ICON_ID,
} from '../../common/assets';

const CHECKBOX_ELEMENT_VARIANTS_CONFIG: VariantsConfig = [
  {
    variant: 'disabled',
    propType: ComponentPropType.Enum,
    options: [
      { value: true, label: 'True' },
      { value: false, label: 'False' },
    ],
    defaultValue: false,
    isFixed: true,
  },
  {
    variant: 'checked',
    propType: ComponentPropType.Enum,
    options: [
      { value: true, label: 'Checked' },
      { value: false, label: 'Unchecked' },
      { value: 'indeterminate', label: 'Indeterminate' },
    ],
    defaultValue: true,
    isFixed: true,
  },
];

const CHECKBOX_ELEMENT_DEFAULT_STYLES: StylesConfig = {
  root: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '0 10px',
    borderStyle: 'none',
    borderColor: '', // this is set so that DDP would not show browser default #767676 and show empty value instead
    outline: 'none',
    background: 'none',
    color: Colors.Neutrals_0,
    height: '16px',
    width: '16px',
    borderRadius: '4px',
  },
  variants: [
    {
      propsValues: {
        disabled: true,
      },
      styles: {
        cursor: 'default',
      },
    },
    {
      // Default + unchecked
      propsValues: {
        checked: false,
        disabled: false,
      },
      styles: {
        borderColor: Colors.Neutrals_400,
        borderWidth: '1.5px',
        borderStyle: 'solid',
      },
    },
    {
      // Default + unchecked + disabled
      propsValues: {
        checked: false,
        disabled: true,
      },
      styles: {
        borderColor: Colors.Neutrals_300,
        borderWidth: '1.5px',
        borderStyle: 'solid',
      },
    },
    {
      // Default + checked
      propsValues: {
        checked: true,
        disabled: false,
      },
      styles: { backgroundColor: Colors.Brand_500 },
    },
    {
      // Default + checked + disabled
      propsValues: {
        checked: true,
        disabled: true,
      },
      styles: { backgroundColor: Colors.Brand_200 },
    },
    {
      // Default + indeterminate
      propsValues: {
        checked: 'indeterminate',
      },
      styles: {
        backgroundColor: Colors.Brand_500,
        borderStyle: 'none',
        borderColor: '', // this is set so that DDP would not show browser default #767676 and show empty value instead
      },
    },
    {
      //Default + indeterminate + disabled
      propsValues: {
        checked: 'indeterminate',
        disabled: true,
      },
      styles: {
        backgroundColor: Colors.Brand_200,
        borderStyle: 'none',
        borderColor: '', // this is set so that DDP would not show browser default #767676 and show empty value instead
      },
    },
  ],
  states: {
    hover: {
      root: {},
      variants: [
        {
          // Hover + unchecked
          propsValues: {
            checked: false,
            disabled: false,
          },
          styles: { borderColor: Colors.Neutrals_700 },
        },
        {
          // Hover + checked
          propsValues: {
            checked: true,
            disabled: false,
          },
          styles: { backgroundColor: Colors.Brand_600 },
        },
        {
          // Hover + indeterminate
          propsValues: {
            checked: 'indeterminate',
            disabled: false,
          },
          styles: {
            backgroundColor: Colors.Brand_600,
            borderStyle: 'none',
            borderColor: '', // this is set so that DDP would not show browser default #767676 and show empty value instead
          },
        },
      ],
    },
    active: {
      root: {},
      variants: [
        {
          // Active + unchecked
          propsValues: {
            checked: false,
            disabled: false,
          },
          styles: { borderColor: Colors.Neutrals_800 },
        },
        {
          // Active + checked
          propsValues: {
            checked: true,
            disabled: false,
          },
          styles: { backgroundColor: Colors.Brand_700 },
        },
        {
          // Active + indeterminate
          propsValues: {
            checked: 'indeterminate',
            disabled: false,
          },
          styles: {
            backgroundColor: Colors.Brand_700,
            borderStyle: 'none',
            borderColor: '', // this is set so that DDP would not show browser default #767676 and show empty value instead
          },
        },
      ],
    },
  },
};

const getJuxCheckboxChildren = ({
  rootCheckboxId,
}: {
  rootCheckboxId: string;
}) => {
  const checkIconElement = getJuxSvgElementData({
    parentId: rootCheckboxId,
    contextParentId: rootCheckboxId,
    displayName: 'check_icon',
    sourceComponentId: JUX_ASSET_CHECKED_ICON_ID,
    rootStyles: { color: Colors.Neutrals_0, display: 'none' },
  }).root;
  const indeterminateIconElement = getJuxSvgElementData({
    parentId: rootCheckboxId,
    contextParentId: rootCheckboxId,
    displayName: 'indeterminate_icon',
    sourceComponentId: JUX_ASSET_INDETERMINATE_ICON_ID,
    rootStyles: { color: Colors.Neutrals_0, display: 'none' },
  }).root;

  return {
    checkIconElement,
    indeterminateIconElement,
  };
};

const getJuxCheckboxContextStyles = ({
  checkIconContextId,
  indeterminateIconContextId,
}: {
  checkIconContextId: string;
  indeterminateIconContextId: string;
}) => {
  const checkedContextStyles: ContextStylesList = [
    // Default + checked
    {
      contextChildUuid: checkIconContextId,
      condition: {
        propsValues: {
          checked: true,
        },
      },
      styles: {
        display: 'inline',
      },
    },
  ];

  const indeterminateContextStyles: ContextStylesList = [
    {
      contextChildUuid: indeterminateIconContextId,
      condition: {
        propsValues: {
          checked: 'indeterminate',
        },
      },
      styles: {
        display: 'inline',
      },
    },
  ];

  return [...checkedContextStyles, ...indeterminateContextStyles];
};

export const getJuxCheckboxElementData: ElementDataInit<undefined> = () => {
  const rootCheckboxId = uuid.v4();

  const { checkIconElement, indeterminateIconElement } = getJuxCheckboxChildren(
    { rootCheckboxId }
  );

  const contextStyles = getJuxCheckboxContextStyles({
    checkIconContextId: checkIconElement.id,
    indeterminateIconContextId: indeterminateIconElement.id,
  });

  return {
    root: {
      type: NodeType.LOCAL_COMPONENT,
      id: rootCheckboxId,
      scopes: [],
      tagName: ComponentTagNames.JuxCheckbox,
      styles: { ...CHECKBOX_ELEMENT_DEFAULT_STYLES, contextStyles },
      displayName: 'checkbox',
      config: {
        props: {
          // starting values of props
          checked: true,
          disabled: false,
        },
        variants: CHECKBOX_ELEMENT_VARIANTS_CONFIG,
      },
      children: [checkIconElement.id, indeterminateIconElement.id],
    },
    subComponents: [checkIconElement, indeterminateIconElement],
    contextStyles,
  };
};
