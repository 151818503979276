import { useCallback } from 'react';
import { toast } from '@jux/ui/toast';

// TODO: separate the callback function to different hooks
export const useCanvasAPI = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const restoreSourceComponent = useCallback((selectedNodeId: string) => {
    // TODO: reimplement with a store action
    //const selectedNode = getNode(selectedNodeId);
    //
    // if (!selectedNode) throw new Error('node not found');
    // if (selectedNode.data.type !== StorageNodeType.COMPONENT_INSTANCE) {
    //   toast.error('Cannot go to source component, this is not an instance');
    //   return;
    // }
    //
    // const componentUuid = selectedNode.data.componentUuid;
    //
    // // Need to add a new source component node to canvas
    // const juxComponent = juxComponents?.find(
    //   (component) => component.uuid === componentUuid
    // );
    // if (!juxComponent) return;
    //
    // const storageNodes = generateStorageNode({
    //   componentData: {
    //     ...juxComponent.data,
    //     displayName: juxComponent.displayName,
    //   },
    //   dropPosition: getPositionToPutAnObjectOnCenterOfOtherObject(
    //     transform.zoom,
    //     {
    //       x: transform.x,
    //       y: transform.y,
    //       width,
    //       height,
    //     },
    //     // TODO: need to know the width and height of the component
    //     {
    //       width: 0,
    //       height: 0,
    //     }
    //   ),
    //   parentId: null,
    //   sourceComponentUuid: juxComponent.uuid,
    // });
    //
    // setNodeToBeSelectedOnCreation(storageNodes[0].id);
    // addNode(storageNodes);

    toast.success('Component was restored and added into the canvas');
  }, []);

  return {
    restoreSourceComponent,
  };
};
