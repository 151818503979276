import { useCallback, useMemo } from 'react';
import { UseTypographyFieldProps } from '@jux/ui/components/editor/components/panels/DDP/hooks/useTypographyField/useTypographyField.interface';
import {
  useFieldValues,
  useTokensByType,
} from '@jux/ui/components/editor/components/panels/DDP/hooks';
import {
  concatTokenPath,
  DesignTokenData,
  DesignTokenTypeEnum,
  formatToAlias,
  startWithAlias,
} from '@jux/design-tokens';
import { useSetMultiFieldsValues } from '@jux/ui/components/editor/components/panels/DDP/hooks/useSetMultiFieldsValues';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';

import { NO_TOKEN } from '@jux/ui/components/editor/components/panels/DDP/base/fields/common';

const ChildrenFields = {
  fontFamily: 'fontFamily',
  fontSize: 'fontSize',
  fontWeight: 'fontWeight',
  lineHeight: 'lineHeight',
  letterSpacing: 'letterSpacing',
};

export type UseTypographyFieldValueProps = Pick<
  UseTypographyFieldProps,
  'fieldName'
>;
export const useTypographyFieldValue = ({
  fieldName,
}: UseTypographyFieldValueProps) => {
  const { parsedTokens: tokens } = useTokensByType(
    fieldName,
    DesignTokenTypeEnum.typography
  );
  const { setMultiFieldsValues } = useSetMultiFieldsValues();

  // Children fields values
  // FontFamily
  const { value: fontFamilyValue } = useFieldValues(
    DDPPrimitiveFieldsKeys.fontFamily
  );
  // FontWeight
  const { value: fontWeightValue } = useFieldValues(
    DDPPrimitiveFieldsKeys.fontWeight
  );
  // FontSize
  const { value: fontSizeValue } = useFieldValues(
    DDPPrimitiveFieldsKeys.fontSize
  );
  // LetterSpacing
  const { value: letterSpacingValue } = useFieldValues(
    DDPPrimitiveFieldsKeys.letterSpacing
  );
  // LineHieght
  const { value: lineHeightValue } = useFieldValues(
    DDPPrimitiveFieldsKeys.lineHeight
  );

  const parseValue = useCallback(
    (childFieldsValues: any[]): string => {
      for (const { name: tokenPath } of tokens) {
        let isTokenMatch = true;
        for (const childFieldValue of childFieldsValues) {
          if (
            typeof childFieldValue != 'string' ||
            !startWithAlias(childFieldValue, `${tokenPath}.`)
          ) {
            isTokenMatch = false;
          }
        }
        if (isTokenMatch) return formatToAlias(tokenPath);
      }

      return NO_TOKEN;
    },
    [tokens]
  );

  const parsedValue = useMemo(
    () =>
      parseValue([
        fontFamilyValue,
        fontWeightValue,
        fontSizeValue,
        letterSpacingValue,
        lineHeightValue,
      ]),
    [
      fontFamilyValue,
      fontWeightValue,
      fontSizeValue,
      letterSpacingValue,
      lineHeightValue,
      parseValue,
    ]
  );

  const updateStateByTokenValue = useCallback(
    (newToken: DesignTokenData) => {
      setMultiFieldsValues({
        fontFamily: formatToAlias(
          concatTokenPath(newToken.name, ChildrenFields.fontFamily)
        ),
        fontSize: formatToAlias(
          concatTokenPath(newToken.name, ChildrenFields.fontSize)
        ),
        fontWeight: formatToAlias(
          concatTokenPath(newToken.name, ChildrenFields.fontWeight)
        ),
        lineHeight: formatToAlias(
          concatTokenPath(newToken.name, ChildrenFields.lineHeight)
        ),
        letterSpacing: formatToAlias(
          concatTokenPath(newToken.name, ChildrenFields.letterSpacing)
        ),
      });
    },
    [setMultiFieldsValues]
  );

  const detachChildrenFromParent = useCallback(
    (token?: DesignTokenData) => {
      if (
        !token ||
        typeof token.value != 'object' ||
        Array.isArray(token.value)
      )
        return;

      setMultiFieldsValues({
        fontFamily: token.value.fontFamily,
        fontSize: token.value.fontSize,
        fontWeight: token.value.fontWeight,
        lineHeight: token.value.lineHeight,
        letterSpacing: token.value.letterSpacing,
      });
    },
    [setMultiFieldsValues]
  );

  return {
    fontFamilyValue,
    fontWeightValue,
    fontSizeValue,
    lineHeightValue,
    letterSpacingValue,
    parsedValue,
    parseValue,
    detachChildrenFromParent,
    updateStateByTokenValue,
  };
};
