import { createSelector } from 'reselect';
import { CanjuxState } from '@jux/canjux/core';

export const selectUserAssets = createSelector(
  [(state: CanjuxState) => state.assets],
  (assets) =>
    Object.entries(assets)
      .filter(([, { isSystemAsset }]) => !isSystemAsset)
      .sort(([, a], [, b]) => (b?.createdAt || 0) - (a?.createdAt || 0))
);

export const selectSystemAssets = createSelector(
  [(state: CanjuxState) => state.assets],
  (assets) =>
    Object.entries(assets)
      .filter(([, { isSystemAsset }]) => isSystemAsset)
      .sort(([, a], [, b]) => (b?.createdAt || 0) - (a?.createdAt || 0))
);
