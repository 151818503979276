import { useCallback, useMemo, useState } from 'react';
import { useViewportHelper } from '@jux/canjux/core';

const ACTION_DURATION = 200;
const ZOOM_MAGNITUDE = 2;

export const useMagnifier = () => {
  const { zoomIn, zoomOut, zoomTo, fitView, transformZoom } =
    useViewportHelper();
  const [showControls, setShowControls] = useState(false);

  const zoomPercentage = useMemo(
    () => Math.floor(transformZoom * 100),
    [transformZoom]
  );

  const handleZoomIn = useCallback(() => {
    zoomIn({
      duration: ACTION_DURATION,
      scale: ZOOM_MAGNITUDE,
    });
  }, [zoomIn]);

  const handleZoomOut = useCallback(() => {
    zoomOut({
      duration: ACTION_DURATION,
      scale: ZOOM_MAGNITUDE,
    });
  }, [zoomOut]);

  const handleFitView = useCallback(() => {
    fitView({
      duration: ACTION_DURATION,
    });
  }, [fitView]);

  const handleResetView = useCallback(() => {
    zoomTo(1, {
      duration: ACTION_DURATION,
    });
  }, [zoomTo]);

  const handleMouseEnter = useCallback(() => {
    setShowControls(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShowControls(false);
  }, []);

  return {
    zoomPercentage,
    showControls,
    handleZoomIn,
    handleZoomOut,
    handleFitView,
    handleResetView,
    handleMouseEnter,
    handleMouseLeave,
  };
};
