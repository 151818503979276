import { useCallback, useMemo } from 'react';
import { SelectChangeEvent } from '@jux/ui/components/common/mui';
import {
  isSelectedNodeValidInteractiveStateOptions,
  useNodeInteractiveState,
} from '@jux/ui/components/editor/hooks';
import { nodeComponentTagNameByNodeId, useStore } from '@jux/canjux/core';
import { useSetupDDPValues } from '../../../../hooks';
import {
  InteractiveStateOptionsByTagName,
  InteractiveStateProps,
} from './InteractiveState.interface';

export const useInteractiveState = ({
  nodeId = '',
}: {
  nodeId?: string;
}): InteractiveStateProps => {
  const { nodeInteractiveState, setNodeInteractiveState } =
    useNodeInteractiveState(nodeId);

  const { setupDDPValues } = useSetupDDPValues();

  const tagName = useStore(nodeComponentTagNameByNodeId(nodeId));

  const interactiveStateOptions = useMemo(() => {
    if (!tagName) return [];

    const interactiveStateOptionsByTagName =
      InteractiveStateOptionsByTagName[tagName];

    return interactiveStateOptionsByTagName || [];
  }, [tagName]);

  const handleNodeInteractiveStateChange = useCallback(
    (e: SelectChangeEvent) => {
      const { value } = e.target;
      if (!value || !isSelectedNodeValidInteractiveStateOptions(value)) {
        return;
      }

      setupDDPValues(value);
      setNodeInteractiveState(value);
    },
    [setNodeInteractiveState, setupDDPValues]
  );

  return {
    nodeInteractiveState,
    handleNodeInteractiveStateChange,
    options: interactiveStateOptions,
  };
};
