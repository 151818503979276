import { CSSProperties } from 'react';
import { IconsVariantsType } from '@jux/ui/components';

export type SupportedPositionValues = Extract<
  CSSProperties['position'],
  'absolute' | 'relative' | 'static'
>;

export const PositionValue: {
  absolute: Extract<SupportedPositionValues, 'absolute'>;
  relative: Extract<SupportedPositionValues, 'relative'>;
  static: Extract<SupportedPositionValues, 'static'>;
} = {
  absolute: 'absolute',
  relative: 'relative',
  static: 'static',
} as const;

export const positionValueList = [
  PositionValue.absolute,
  PositionValue.relative,
  PositionValue.static,
] as const;

export const iconsToPositionValues: Record<
  SupportedPositionValues,
  IconsVariantsType
> = {
  [PositionValue.absolute]: 'DDP_FIELD_POSITION_ABSOLUTE',
  [PositionValue.relative]: 'DDP_FIELD_POSITION_RELATIVE',
  [PositionValue.static]: 'DDP_FIELD_POSITION_STATIC',
};
