import { LayersPanelItem } from '../getLayersItemsRecursive';

// get all children of an item recursively
export const getChildrenRecursive = (
  item: LayersPanelItem,
  items: Map<string, LayersPanelItem>,
  itemId: string
): Array<LayersPanelItem> => {
  if (!item.children) return [];

  const children = item.children
    .map((childId) => items.get(childId))
    .filter(Boolean) as Array<LayersPanelItem>;

  return children.concat(
    children.flatMap((child) =>
      child ? getChildrenRecursive(child, items, itemId) : []
    )
  );
};
