import { atom, useAtom } from 'jotai';

export enum CanvasMode {
  Live = 'live',
  Edit = 'edit',
}

const canvasModeAtom = atom<CanvasMode>(CanvasMode.Edit);

export const useCanvasModeState = () => {
  const [mode, setMode] = useAtom(canvasModeAtom);

  return {
    mode,
    setMode,
  };
};
