import { CanjuxState } from '@jux/canjux/core';
import { createSelector } from 'reselect';
import { calculateCenterPosition } from './utils';

export const getTargetPositionOnCanvasCenter = createSelector(
  [
    ({ width }: CanjuxState) => width,
    ({ height }: CanjuxState) => height,
    ({ transform }: CanjuxState) => transform.zoom,
    ({ transform }: CanjuxState) => transform.x,
    ({ transform }: CanjuxState) => transform.y,
  ],
  (width, height, zoom, x, y) =>
    (targetDimensions: { width: number; height: number }) =>
      calculateCenterPosition({
        container: {
          width,
          height,
          position: { x, y },
        },
        zoom,
        targetDimensions,
      })
);
