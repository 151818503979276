import {
  useEffect,
  useRef,
  useCallback,
  MouseEventHandler,
  MouseEvent,
} from 'react';

export const useClickAndDoubleClick = <Callback extends MouseEventHandler>({
  onClick,
  onDoubleClick,
  threshold = 300,
}: {
  onClick: Callback;
  onDoubleClick: Callback;
  threshold?: number;
}) => {
  const clickRef = useRef(0);
  const timeoutRef = useRef<any>(null);

  useEffect(() => {
    return () => {
      if (!timeoutRef.current) return;

      clearTimeout(timeoutRef.current);
    };
  }, [threshold]);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      clickRef.current += 1;

      timeoutRef.current = setTimeout(() => {
        if (clickRef.current === 1) {
          onClick(e);
        }

        clickRef.current = 0;
      }, threshold);

      if (clickRef.current === 2) {
        clearTimeout(timeoutRef.current);

        onDoubleClick(e);

        clickRef.current = 0;
      }
    },
    [onClick, onDoubleClick, threshold]
  );

  return { handleClick };
};
