import { useMemo } from 'react';
import {
  DDPSubModulesByModule,
  DDPSubModulesStateKeys,
} from '@jux/ui/components/editor/state';
import { ModulesKeysType } from '../DDP.interface';
import { useShouldRenderSubModules } from './useShouldRenderSubModules';

export const useShouldRenderModuleByKey = (key: ModulesKeysType) => {
  const { shouldRenderSubModule } = useShouldRenderSubModules();

  const shouldRender = useMemo(() => {
    const subModulesKeys = DDPSubModulesByModule[key];

    return Object.values<DDPSubModulesStateKeys>(subModulesKeys).some(
      (subModuleKey) => shouldRenderSubModule(subModuleKey)
    );
  }, [key, shouldRenderSubModule]);

  return {
    shouldRender,
  };
};
