import { useCallback } from 'react';
import { atom, useAtom } from 'jotai';
import { ModulesKeysType } from '../components/panels/DDP/DDP.interface';
import {
  DDPModuleConfig,
  DDPModulesConfig,
} from './useDDPModulesState.interface';

const defaultModulesState: DDPModulesConfig = {
  background: {
    isOpened: true,
  },
  border: {
    isOpened: true,
  },
  color: {
    isOpened: true,
  },
  context: {
    isOpened: true,
    nonCollapsible: true,
  },
  effects: {
    isOpened: true,
  },
  instance: {
    isOpened: true,
    nonCollapsible: true,
    hideTopDivider: true,
  },
  layout: {
    isOpened: true,
  },
  opacity: {
    isOpened: true,
  },
  props: {
    isOpened: true,
    hideTopDivider: true,
  },
  size: {
    isOpened: true,
    hideTopDivider: true,
  },
  text: {
    isOpened: true,
  },
};

const ddpModulesAtom = atom<DDPModulesConfig>(defaultModulesState);

export const useDDPModulesState = () => {
  const [ddpModules, setDDPModules] = useAtom(ddpModulesAtom);

  const updateModuleState = useCallback(
    (moduleKey: ModulesKeysType, state: Partial<DDPModuleConfig>) => {
      setDDPModules((prev) => ({
        ...prev,
        [moduleKey]: {
          ...prev[moduleKey],
          ...state,
        },
      }));
    },
    [setDDPModules]
  );

  return {
    ddpModules,
    updateModuleState,
  };
};
