/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const TokensIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="20"
    height="20"
    attr={{
      viewBox: '0 0 20 20',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M5 7.1547L9.9282 4.3094L14.8564 7.1547V12.8453L9.9282 15.6906L5 12.8453V7.1547ZM9.9282 2L16.8564 6V14L9.9282 18L3 14V6L9.9282 2ZM12.1317 8.7278L9.9282 7.4556L7.72468 8.7278V11.2722L9.9282 12.5444L12.1317 11.2722V8.7278Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
