import React, { JSX } from 'react';
import { ComponentConfig, ThemeConfig } from '@jux/types';
import * as CSS from 'csstype';

export interface StyledOptions {
  name?: string;
  styles: ComponentConfig;
  shouldForwardProp?: (propName: string) => boolean;
}

export type IntrinsicElementsKeys = keyof JSX.IntrinsicElements;

export type Widen<T> = T extends number
  ? `${T}` | T
  : T extends 'true'
  ? boolean | T
  : T extends 'false'
  ? boolean | T
  : T extends `${number}`
  ? number | T
  : T;

export type TransformProps<T> = {
  [K in keyof T]?: Widen<keyof T[K]>;
};

/** Returns an object from the given object assigned with the values of another given object. */
export type Assign<
  T1 = Record<string, never>,
  T2 = Record<string, never>
> = T1 extends any ? Omit<T1, keyof T2> & T2 : never;

export interface SxProp<Theme extends ThemeConfig> {
  sx?: {
    [name in keyof CSS.Properties]?:
      | CSS.Properties[name]
      | ((theme: Theme) => CSS.Properties[name]);
  };
}

/**
 * @desc Utility type for getting props type of React component.
 * It takes `defaultProps` into an account - making props with defaults optional.
 */
export type PropsOf<
  C extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>
> = JSX.LibraryManagedAttributes<C, React.ComponentProps<C>>;

export type DrimzComponent<
  Type,
  SpecificComponentProps = Record<string, never>
> = React.FC<
  Assign<
    Type extends IntrinsicElementsKeys | React.ComponentType<any>
      ? React.ComponentPropsWithRef<Type>
      : never,
    SpecificComponentProps
  >
>;

export interface CreateStyled<Theme extends ThemeConfig> {
  <
    Component extends React.ComponentType<React.ComponentProps<Component>>,
    Options extends StyledOptions
  >(
    component: Component,
    options?: Options
  ): DrimzComponent<
    Component,
    Assign<
      PropsOf<Component>,
      (Options['styles']['variants'] extends Array<any>
        ? Options['styles']['variants'][number] extends undefined
          ? { [K in string]: any }
          : TransformProps<Options['styles']['variants'][number]>
        : { [K in string]: any }) &
        SxProp<Theme>
    >
  >;

  // Tag component
  <Tag extends keyof JSX.IntrinsicElements, Options extends StyledOptions>(
    tag: Tag,
    options?: Options
  ): DrimzComponent<
    Tag,
    Assign<
      PropsOf<Tag>,
      (Options['styles']['variants'] extends Array<any>
        ? Options['styles']['variants'][number] extends undefined
          ? { [K in string]: any }
          : TransformProps<Options['styles']['variants'][number]>
        : { [K in string]: any }) &
        SxProp<Theme>
    >
  >;
}

export default interface CreateDrimz<Theme extends ThemeConfig> {
  styled: CreateStyled<Theme>;
}
