import { createSelector } from 'reselect';
import { CanjuxState, SourceComponentsList } from '@jux/canjux/core';
import { COMPONENT_TAG_NAME, NodeType } from '@jux/data-entities';

export const libraryComponents = createSelector(
  [(state: CanjuxState) => state.components],
  (components): SourceComponentsList => {
    // get only source components that are not svgs
    const componentsWithoutSvg = Object.values(components)
      .filter(
        (componentData) =>
          componentData.type === NodeType.LIBRARY_COMPONENT &&
          componentData.tagName !== COMPONENT_TAG_NAME.JuxSvg
      )
      .sort((a, b) => (a?.updatedAt || 0) - (b?.updatedAt || 0));
    return componentsWithoutSvg as SourceComponentsList;
  }
);
