/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const TextElementPanelIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="28"
    height="34"
    attr={{
      viewBox: '0 0 28 34',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          d: 'M0.864 32.4H5.568L13.344 1.152H3.024L1.488 7.344H0L1.824 0H27.216L25.392 7.344H23.904L25.44 1.152H15.12L7.344 32.4H12.048L11.808 33.504H0.624L0.864 32.4Z',
          fill: 'currentColor',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
