/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const ArrowTargetIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="10"
    height="4"
    attr={{
      viewBox: '0 0 10 4',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M5.6247 0.499756L10 4L0 4L4.37531 0.499755C4.74052 0.207581 5.25948 0.207581 5.6247 0.499756Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
