import * as uuid from 'uuid';
import {
  NodeType,
  ComponentInstanceData,
  INHERITED_FROM_PARENT,
} from '@jux/data-entities';
import { Colors } from '../../common/colors';
import { ComponentConfigWithStates as StylesConfig } from '@jux/types';
import { PLACEHOLDER_PSEUDO_ELEMENT } from '@jux/constants';
import { getJuxInputElementData } from './input';

export const INPUT_ELEMENT_DEFAULT_DISPLAY_NAME = 'input';

export const NAKED_INPUT_ELEMENT_DEFAULT_STYLES: StylesConfig = {
  root: {
    width: 'auto',
    height: '34px',
    padding: '9px 12px',
    [PLACEHOLDER_PSEUDO_ELEMENT]: {
      color: Colors.Neutrals_400,
    },
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Inter',
    color: Colors.Neutrals_600,
    backgroundColor: Colors.Neutrals_0,
    border: 'none',
    display: 'inline-block',
    outline: 'none',
  },
  states: {},
};

export const getJuxInputInstanceElementData = ({
  displayName = INPUT_ELEMENT_DEFAULT_DISPLAY_NAME,
  parentId,
}: {
  displayName?: string;
  parentId: string;
}) => {
  const newInputLocalComponent = getJuxInputElementData({
    styles: NAKED_INPUT_ELEMENT_DEFAULT_STYLES,
  }).root;

  const instance: ComponentInstanceData = {
    type: NodeType.INSTANCE,
    id: uuid.v4(),
    parentId,
    children: [],
    config: {
      props: {
        disabled: INHERITED_FROM_PARENT,
        placeholder: INHERITED_FROM_PARENT,
        value: INHERITED_FROM_PARENT,
      },
    },
    displayName,
    sourceComponentId: newInputLocalComponent.id,
  };

  return {
    instance,
    source: newInputLocalComponent,
  };
};
