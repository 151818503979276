import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { Module } from '../../base';
import { useShouldRenderModuleByKey } from '../../hooks/useShouldRenderModuleByKey';
import { ModulesKeys } from '../../DDP.interface';
import {
  Margin,
  Padding,
  FlexDirectionAndWrap,
  AlignItems,
  JustifyContent,
  Gap,
  Display,
} from './sub-modules';

const MODULE_KEY = ModulesKeys.layout;

export const Layout: FC = () => {
  const { shouldRender } = useShouldRenderModuleByKey(MODULE_KEY);

  if (!shouldRender) return null;

  return (
    <Module moduleKey={MODULE_KEY} title="Layout">
      <Padding />
      <Margin />
      <MuiGrid container pt="8px">
        <Display />
      </MuiGrid>
      <FlexDirectionAndWrap />
      <AlignItems />
      <JustifyContent />
      <Gap />
    </Module>
  );
};
