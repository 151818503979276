import { FC } from 'react';
import { DimensionField } from '../../../../base';
import { usePaddingFields } from './usePaddingFields';

export const PaddingLeftField: FC = () => {
  const { paddingLeftField, isComputed } = usePaddingFields();

  return (
    <DimensionField
      icon="DDP_FIELD_PADDING_LEFT"
      tooltipTitle="Padding left"
      {...paddingLeftField}
      isComputed={isComputed}
    />
  );
};
