import { CanjuxState } from '@jux/canjux/core';

export const getAssetDataById = ({
  assets,
  id,
}: {
  assets: CanjuxState['assets'];
  id?: string;
}) => {
  return id ? assets[id] : undefined;
};
