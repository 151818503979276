import { useCallback } from 'react';
import { atom, useAtom } from 'jotai';

export type OverlaysKeys =
  | 'tooltip'
  | 'popper'
  | 'modal'
  | 'token-drawer'
  | 'layout-content'
  | 'layout-sidebar'
  | 'loading'
  | 'layout-navbar';

const overlaysAtom = atom<Array<OverlaysKeys>>([]);

export const useOverlaysState = () => {
  const [overlays, setOverlays] = useAtom(overlaysAtom);

  const addOverlay = useCallback(
    (overlayKey: OverlaysKeys) => {
      setOverlays((prev) => [...prev, overlayKey]);
    },
    [setOverlays]
  );

  const removeOverlay = useCallback(
    (overlayKey: OverlaysKeys) => {
      setOverlays((prev) => prev.filter((o) => o !== overlayKey));
    },
    [setOverlays]
  );

  return {
    overlays,
    addOverlay,
    removeOverlay,
  };
};
