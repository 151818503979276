import { useMemo } from 'react';
import {
  CANJUX_NODES_CONTAINER,
  DATA_JUX_NODE_ID_ATTRIBUTE,
} from '../constants';
import { storeApi } from '../crdt';
import { NodeChange } from '../store';
import { getElementAbsolutePosition, getPositionOnCanjux } from '../utils';
import { useStoreActions } from './useStoreActions';

/**
 * Custom hook that creates a global mutation observer to track.
 */
export const useGlobalMutationObserver = () => {
  const {
    commonActions: { updateNodesIndicatorsPosition },
  } = useStoreActions();

  /**
   * Calculates and updates the absolute position of nodes in the DOM, where each Node represents a node in canjux.
   *
   * @param nodesElements - An array of nodes to calculate and update the position for.
   * @returns An array of node changes with updated absolute positions.
   * @throws Error if the parent container pane is not found.
   */
  const calculateAndUpdateNodeAbsolutePosition = useMemo(
    () => (nodesElements: Node[]) => {
      const changes: Array<NodeChange<'position_absolute'>> = [];
      const { transform } = storeApi.getState();

      function traverseNodes(node: Node) {
        if (node.nodeType === Node.ELEMENT_NODE) {
          const n = node as HTMLElement;
          const juxNodeId = n.getAttribute(DATA_JUX_NODE_ID_ATTRIBUTE);
          if (!juxNodeId) {
            return;
          }

          const dim = getPositionOnCanjux(
            getElementAbsolutePosition(n),
            transform,
            false
          );

          changes.push({
            id: n.id,
            type: 'position_absolute',
            data: {
              x: dim.x,
              y: dim.y,
            },
          });

          node.childNodes.forEach(
            (child) =>
              (child as HTMLElement).getAttribute?.(
                DATA_JUX_NODE_ID_ATTRIBUTE
              ) && traverseNodes(child)
          );
        }
      }

      nodesElements.forEach(
        (node) =>
          (node as HTMLElement).getAttribute(DATA_JUX_NODE_ID_ATTRIBUTE) &&
          traverseNodes(node)
      );

      return changes;
    },
    []
  );

  return useMemo(() => {
    return new MutationObserver((mutations) => {
      let changes: Array<NodeChange<'position_absolute'>> = [];

      mutations.forEach((entry) => {
        const node =
          entry.type === 'characterData'
            ? entry.target.parentElement
            : (entry.target as HTMLElement);

        const nodeId = node?.getAttribute(DATA_JUX_NODE_ID_ATTRIBUTE);

        switch (entry.type) {
          case 'characterData':
          case 'childList': {
            // TODO: Fix bug deleting while dragging node
            // The nodes container has changed (added / removed child-nodes), so we need to update the of the added nodes.
            if (nodeId) {
              // TODO: check this -
              // we currently have coupling between the logic that provides the list of visible nodes to render in the DOM
              // that relies on the dimensions of each node, and the logic that calculates the dimensions of each node,
              // that relies on the node being rendered on the DOM.
              // This is a problem because the dimensions of a node are not available until the node is rendered on the DOM.
              // which creates some chicken-and-egg problems that makes nodes to not be rendered at certain times.

              // The nodes container has changed, so we need to update the of the added nodes.
              const element = document.getElementById(nodeId);

              if (element) {
                changes = changes.concat(
                  calculateAndUpdateNodeAbsolutePosition([element])
                );
              }
            } else if (
              (entry.target as HTMLElement).id === CANJUX_NODES_CONTAINER
            ) {
              // The nodes container has changed, so we need to update the of the added nodes.
              changes = changes.concat(
                calculateAndUpdateNodeAbsolutePosition(
                  Array.from(entry.addedNodes)
                )
              );
            }

            break;
          }

          case 'attributes': {
            // Only track changes in the position of nodes.
            if (nodeId) {
              changes = changes.concat(
                calculateAndUpdateNodeAbsolutePosition([entry.target])
              );
            }

            break;
          }
        }
      });

      if (changes.length) {
        updateNodesIndicatorsPosition({
          payload: changes.map(({ id, data: { x, y } }) => ({
            nodeId: id,
            position: { x, y },
          })),
        });
      }
    });
  }, [calculateAndUpdateNodeAbsolutePosition, updateNodesIndicatorsPosition]);
};
