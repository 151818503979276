import { SyntheticEvent, useCallback } from 'react';
import { z } from 'zod';
import { useDDPFieldForm } from '@jux/ui/components/editor/components/panels/DDP/hooks';
import {
  useEffectOnSelectedNodeChange,
  useSaveNodeTextContent,
} from '@jux/ui/components/editor/hooks';
import { useDDPFieldsState } from '@jux/ui/components/editor/state';

import {
  PlaceholderMode,
  PlaceholderModeValues,
  selectResolvedComponentProps,
  useStore,
} from '@jux/canjux/core';

const usePlaceholderTextContentField = (
  nodeId: string,
  fieldKey: PlaceholderModeValues
) => {
  const { saveTextContent } = useSaveNodeTextContent(fieldKey);
  const { setFieldsStateByKey } = useDDPFieldsState();

  const resolvedComponentProps = useStore(
    selectResolvedComponentProps({
      id: nodeId,
      onlyVariantsProps: false,
    })
  );

  const initialValue = resolvedComponentProps[fieldKey] || '';

  const { handleSubmit, registerField, setValue, getValue } = useDDPFieldForm({
    fieldName: fieldKey,
    fieldSchema: z.string().optional(),
    initialValue,
  });

  const setFieldValue = useCallback(
    (newValue: string) => {
      setFieldsStateByKey(fieldKey, {
        value: newValue,
        initialValue: newValue,
      });
      setValue(newValue);
    },
    [setFieldsStateByKey, fieldKey, setValue]
  );

  const handleNodeChangeEffect = useCallback(() => {
    if (!initialValue) {
      return;
    }

    const selectedNodeText = initialValue;

    setFieldValue(selectedNodeText);
  }, [initialValue, setFieldValue]);

  useEffectOnSelectedNodeChange(handleNodeChangeEffect);

  const discard = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      const currentValue = getValue();

      if (currentValue !== initialValue) {
        setValue(initialValue);
      }

      event.currentTarget?.blur();
    },
    [getValue, initialValue, setValue]
  );

  const save = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      const newText = getValue();

      const { currentTarget } = event;

      const submit = handleSubmit(
        () => {
          saveTextContent({ nodeId, newText });
          setFieldValue(newText);
          currentTarget.blur();
        },
        () => {
          discard(event);
        }
      );

      return submit(event);
    },
    [discard, getValue, handleSubmit, nodeId, saveTextContent, setFieldValue]
  );

  return {
    save,
    discard,
    registerField,
  };
};

export const usePlaceholderAndTextContent = (nodeId: string) => {
  const {
    save: saveTextValue,
    discard: discardTextValue,
    registerField: registerTextFieldValue,
  } = usePlaceholderTextContentField(nodeId, PlaceholderMode.text);
  const {
    save: savePlaceholderText,
    discard: discardPlaceHolderText,
    registerField: registerPlaceHolderField,
  } = usePlaceholderTextContentField(nodeId, PlaceholderMode.placeholder);

  return {
    saveTextValue,
    savePlaceholderText,
    discardTextValue,
    discardPlaceHolderText,
    registerTextFieldValue,
    registerPlaceHolderField,
  };
};
