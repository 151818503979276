import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import { FieldsOptionsState } from './useDDPFieldsOptionsState.interface';

const fieldsOptionsStateAtom = atom<FieldsOptionsState>(
  {} as unknown as FieldsOptionsState
);

export const useDDPFieldsOptionsState = () => {
  const [fieldsOptionsState, setFieldsOptionsState] = useAtom(
    fieldsOptionsStateAtom
  );

  const getFieldOptionStateByKey = useCallback(
    <Key extends keyof FieldsOptionsState>(key: Key) => fieldsOptionsState[key],
    [fieldsOptionsState]
  );

  const setFieldOptionStateByKey = useCallback(
    <Key extends keyof FieldsOptionsState>(
      key: Key,
      state: Partial<FieldsOptionsState[Key]>
    ) => {
      setFieldsOptionsState(
        (prev) =>
          ({
            ...prev,
            [key]: {
              ...prev[key],
              ...state,
            },
          } as FieldsOptionsState)
      );
    },
    [setFieldsOptionsState]
  );

  return {
    getFieldOptionStateByKey,
    setFieldsOptionsState,
    setFieldOptionStateByKey,
  };
};
