import { useEffect } from 'react';
import { AnalyticsContext, useSetAnalyticsContext } from '../state';

export const useAnalyticsContextEffect = (context: AnalyticsContext) => {
  const setContext = useSetAnalyticsContext();

  useEffect(() => {
    setContext(context);
  }, [context, setContext]);
};
