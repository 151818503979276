import {
  JuxStore,
  JuxStoreActionFn,
  TokenSetsActionsParams,
} from '@jux/canjux/core';
import {
  getCurrentTimestamp,
  getDefaultStructuredTokenSet,
  namingPatternsSchema,
} from '@jux/design-tokens';
import { merge } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { z } from 'zod';
import { getDesignTokensParserContext } from '../../../store-utils/getDesignTokensParserContext';

const designTokensParser = getDesignTokensParserContext();

export const addGroup: JuxStoreActionFn<
  TokenSetsActionsParams['addGroup'],
  JuxStore
> = ({ state, ...params }) => {
  const { description, groupName, groupPath } = z
    .object({
      description: z.string().optional().default(''),
      groupName: namingPatternsSchema,
      groupPath: z.string(),
    })
    .parse(params);

  Object.values(state.tokenSets).forEach((tokenSet) => {
    tokenSet.value = designTokensParser
      // If groupPath is a SupportedTokenType that doesn't
      // exist in the token set yet - we need to add it first,
      // so we merge the default structure with the current
      // value before adding the new group.
      .parse(merge(getDefaultStructuredTokenSet(), cloneDeep(tokenSet.value)))
      .setGroup({
        groupPath,
        groupName,
        description,
      })
      .getRawValueCopy();

    tokenSet.updatedAt = getCurrentTimestamp();
  });

  return state;
};
