/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const ArrowPointsRightIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M5.82319 11.8838C5.53029 11.591 5.53029 11.1161 5.82319 10.8232L8.64641 7.99996L5.82319 5.17674C5.53029 4.88385 5.53029 4.40897 5.82319 4.11608C6.11608 3.82319 6.59095 3.82319 6.88385 4.11608L10.7677 7.99996L6.88385 11.8838C6.59095 12.1767 6.11608 12.1767 5.82319 11.8838Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
