import { useMemo } from 'react';
import type { CanjuxInstance } from '../types';
import { useViewportHelper } from './';

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function useCanjux(): CanjuxInstance {
  const viewportFunctions = useViewportHelper();

  return useMemo(() => {
    return {
      ...viewportFunctions,
    };
  }, [viewportFunctions]);
}
