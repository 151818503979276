import {
  CANJUX_MAIN_WRAPPER,
  getNodesInRect,
  SelectionRect,
  storeApi,
  useStore,
  useStoreActions,
} from '@jux/canjux/core';
import xor from 'lodash/xor';
import React, {
  memo,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useRef,
} from 'react';
import UserSelection from '../../components/UserSelection';
import { getEventPosition } from '../../utils';
import { PaneProps } from './Pane.interface';
import * as S from './Pane.style';
import { wrapHandler } from './Pane.utils';
import { PaneIndicators } from './PaneIndicators';
import { selectCurrentCanvas } from '../../store/wip/selectors/selectCurrentCanvas';

export const Pane = memo<PaneProps>(({ isSelecting, panOnDrag, children }) => {
  const container = useRef<HTMLDivElement | null>(null);
  const {
    commonActions: { resetSelectedNodes, setSelectedNodes, setUserSelection },
  } = useStoreActions();

  const prevSelectedNodes = useRef<string[]>([]);
  const containerBounds = useRef<DOMRect>();
  const userSelectionActive = useStore((s) => s.userSelectionActive);

  const resetUserSelection = useCallback(() => {
    setUserSelection({
      userSelectionActive: false,
      userSelectionRect: null,
    });

    prevSelectedNodes.current = storeApi.getState().selectedNodesStack;
  }, [setUserSelection]);

  const onClick = useCallback(() => {
    // if user currently selecting text inside editable text node, there is no need to deselect that particular node
    if (!storeApi.getState().textNodeUserSelectionActive) {
      resetSelectedNodes();
      setUserSelection({ nodesSelectionActive: true });
    }
  }, [resetSelectedNodes, setUserSelection]);

  const onContextMenu = (event: ReactMouseEvent) => {
    if (Array.isArray(panOnDrag) && panOnDrag?.includes(2)) {
      event.preventDefault();
      return;
    }
  };

  const onMouseDown = useCallback(
    (event: React.MouseEvent): void => {
      const domNode = document.getElementById(CANJUX_MAIN_WRAPPER);
      containerBounds.current = domNode?.getBoundingClientRect();
      const { disableNodesInteraction } = storeApi.getState();

      if (
        disableNodesInteraction ||
        !isSelecting ||
        event.button !== 0 ||
        event.target !== container.current ||
        !containerBounds.current
      ) {
        return;
      }

      resetUserSelection();

      const { x, y } = getEventPosition(event, containerBounds.current);

      const isMultiSelectionActive = event.shiftKey;

      if (!isMultiSelectionActive) {
        resetSelectedNodes();
      }

      setUserSelection({
        userSelectionActive: true,
        userSelectionRect: {
          width: 0,
          height: 0,
          startX: x,
          startY: y,
          x,
          y,
        },
      });
    },
    [isSelecting, resetSelectedNodes, resetUserSelection, setUserSelection]
  );

  const onMouseUp = useCallback(
    (event: ReactMouseEvent) => {
      if (event.button !== 0) {
        return;
      }
      const { userSelectionRect } = storeApi.getState();
      // We only want to trigger click functions when in selection mode if
      // the user did not move the mouse.
      if (
        !userSelectionActive &&
        userSelectionRect &&
        event.target === container.current
      ) {
        onClick?.();
      }

      setUserSelection({
        nodesSelectionActive: prevSelectedNodes.current.length > 0,
      });

      resetUserSelection();
    },
    [onClick, resetUserSelection, setUserSelection, userSelectionActive]
  );

  const onMouseMove = useCallback(
    (event: ReactMouseEvent): void => {
      const { userSelectionRect } = storeApi.getState();
      if (!isSelecting || !containerBounds.current || !userSelectionRect) {
        return;
      }

      // Support multi selection

      setUserSelection({
        userSelectionActive: true,
        nodesSelectionActive: false,
      });

      const mousePos = getEventPosition(event, containerBounds.current);
      const startX = userSelectionRect.startX ?? 0;
      const startY = userSelectionRect.startY ?? 0;

      const nextUserSelectRect: SelectionRect = {
        ...userSelectionRect,
        x: mousePos.x < startX ? mousePos.x : startX,
        y: mousePos.y < startY ? mousePos.y : startY,
        width: Math.abs(mousePos.x - startX),
        height: Math.abs(mousePos.y - startY),
      };

      const {
        canvasNodesDimensions,
        canvasNodesIndicatorsPositions,
        transform,
      } = storeApi.getState();

      const currentCanvas = selectCurrentCanvas(storeApi.getState());
      // All the nodes in the rect should be selected
      const selectedNodeIds = getNodesInRect({
        canvas: currentCanvas,
        canvasNodesDimensionsData: canvasNodesDimensions,
        canvasNodesIndicatorsData: canvasNodesIndicatorsPositions,
        onlyRootNodes: false,
        partially: true,
        transform: transform,
        viewport: nextUserSelectRect,
      });

      const selectedRootNodes = selectedNodeIds.filter((n) =>
        currentCanvas.rootNodesOrder.includes(n)
      );

      const isMultiSelectionActive = event.shiftKey;

      const nodeIds = isMultiSelectionActive
        ? // If user is holding shift key, invert the selection
          xor(prevSelectedNodes.current, selectedRootNodes)
        : selectedRootNodes;

      setSelectedNodes({
        nodeIds,
      });

      setUserSelection({
        userSelectionRect: nextUserSelectRect,
      });
    },
    [isSelecting, setSelectedNodes, setUserSelection]
  );

  const onMouseLeave = useCallback(() => {
    if (userSelectionActive) {
      setUserSelection({
        nodesSelectionActive: prevSelectedNodes.current.length > 0,
      });
    }

    resetUserSelection();
  }, [resetUserSelection, setUserSelection, userSelectionActive]);

  const hasActiveSelection = isSelecting || userSelectionActive;

  return (
    <S.PaneWrapper
      id={'jux__pane'}
      onClick={hasActiveSelection ? undefined : wrapHandler(onClick, container)}
      onContextMenu={wrapHandler(onContextMenu, container)}
      onMouseDown={hasActiveSelection ? onMouseDown : undefined}
      onMouseUp={hasActiveSelection ? onMouseUp : undefined}
      onMouseMove={hasActiveSelection ? onMouseMove : undefined}
      onMouseLeave={hasActiveSelection ? onMouseLeave : undefined}
      ref={container}
    >
      {children}
      <PaneIndicators />
      <UserSelection />
    </S.PaneWrapper>
  );
});

Pane.displayName = 'Pane';
