import { KeyboardEvent } from 'react';
import { insertIntoElement } from '../helpers/insertIntoElement';

export const formatTokenNameKeyPress = <T extends HTMLElement>(
  event: KeyboardEvent<T>,
  element?: T
) => {
  const target = element || (event.target as HTMLElement);

  // On 'Enter' key - blur and focus to force a form update
  if (event.key === 'Enter') {
    target.blur();
    target.focus();
    return;
  }

  const isMeta = event.getModifierState('Meta');

  // if it's a meta key (win, cmd, ctrl) or not an actual char - do nothing
  if (isMeta || event.ctrlKey || event.key.length > 1) return;

  event.preventDefault();

  insertIntoElement(target, event.key);
};
