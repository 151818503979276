import { useEffect } from 'react';
import { selectedNodeContextParentId, useStore } from '@jux/canjux/core';

export const useEffectOnSelectedNodeContextChange = (callback: () => void) => {
  const contextParentId = useStore(selectedNodeContextParentId);

  useEffect(() => {
    callback();
  }, [callback, contextParentId]);
};
