import { JuxStore } from '@jux/canjux/core';

export const isInstanceExistInTree = ({
  components,
  nodeId,
  sourceComponentNodeId,
}: {
  components: JuxStore['components'];
  nodeId: string;
  sourceComponentNodeId: string;
}): boolean => {
  const nodeComponentData = components[nodeId];

  if (nodeComponentData.sourceComponentId === sourceComponentNodeId) {
    return true;
  }

  for (const childNodeId of nodeComponentData.children) {
    if (
      isInstanceExistInTree({
        components,
        nodeId: childNodeId,
        sourceComponentNodeId,
      })
    ) {
      return true;
    }
  }

  return false;
};
