/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const FontWeightDDPIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M2 12H3.88331L4.49994 10.1758H7.50126L8.11669 12H10L7.13445 4H4.86961L2 12ZM6.0345 5.82812H5.96956L4.94624 8.85547H7.05583L6.0345 5.82812Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M10.5 4L13.3656 12H11.8656L9 4H10.5Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
