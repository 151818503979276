import type { D3DragEvent, SubjectPosition } from 'd3';

export enum ResizePosition {
  top,
  bottom,
  left,
  right,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
}

export type ResizeHorizontalPosition =
  | ResizePosition.left
  | ResizePosition.right;

export type ResizeVerticalPosition = ResizePosition.top | ResizePosition.bottom;

export type ControlLinePosition =
  | ResizePosition.bottom
  | ResizePosition.left
  | ResizePosition.right
  | ResizePosition.top;

export enum LineType {
  PARENT = 'parent',
  CHILD = 'child',
}

export type ControlHandlePosition = Array<{
  verticalPosition: ResizeVerticalPosition;
  horizontalPosition: ResizeHorizontalPosition;
}>;

export type ResizeDragEvent = D3DragEvent<
  HTMLDivElement,
  null,
  SubjectPosition
>;
