import { RefObject } from 'react';
import { useEventListener } from 'usehooks-ts';

export const useOnFocusOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: FocusEvent) => void
): void =>
  useEventListener('focusin', (event) => {
    const el = ref?.current;

    // Do nothing if clicking ref's element or descendent elements
    if (!el || el?.contains(event.target as Node)) {
      return;
    }

    handler(event);
  });
