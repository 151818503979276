/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { FC } from 'react';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
import { IconBaseProps } from '../../Icons.interface';

export const TextareaElementPanelIcon: FC<IconBaseProps> = ({
  backgroundColor = 'white',
  ...props
}) => (
  <CustomIcon
    tag="svg"
    width="68"
    height="39"
    attr={{
      viewBox: '0 0 68 39',
    }}
    child={[
      {
        tag: 'rect',
        attr: {
          y: '0.5',
          width: '68',
          height: '38',
          rx: '4',
          fill: 'currentColor',
        },
        child: [],
      },
      {
        tag: 'rect',
        attr: {
          x: '8',
          y: '6.5',
          width: '52',
          height: '6',
          rx: '3',
          fill: backgroundColor,
        },
        child: [],
      },
      {
        tag: 'rect',
        attr: {
          x: '8',
          y: '16.5',
          width: '52',
          height: '6',
          rx: '3',
          fill: backgroundColor,
        },
        child: [],
      },
      {
        tag: 'rect',
        attr: {
          x: '8',
          y: '26.5',
          width: '23',
          height: '6',
          rx: '3',
          fill: backgroundColor,
        },
        child: [],
      },
    ]}
    {...props}
  />
);
