import { FC, useRef } from 'react';
import { useOnKeyEvent } from '@jux/ui/hooks';
import {
  usePanelStateByKey,
  useEffectOnSelectedNodeChange,
} from '@jux/ui/components/editor/hooks';
import { useSubModule } from '../DDP/hooks';
import { DDPSubModulesKeys, PanelsKeys } from '../../../state';
import { PropertiesPanelWrapper } from './PropertiesPanelWrapper';

export const PropertiesPanel: FC = () => {
  const { shouldRender: shouldRenderEditProperties } = useSubModule(
    DDPSubModulesKeys.editProperties
  );
  const {
    shouldRender: shouldRenderPropertiesPanel,
    closePanel: closePropertiesPanel,
  } = usePanelStateByKey(PanelsKeys.properties);

  const shouldRender =
    shouldRenderPropertiesPanel && shouldRenderEditProperties;

  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnKeyEvent(wrapperRef, closePropertiesPanel, 'Escape');
  useEffectOnSelectedNodeChange(closePropertiesPanel);

  if (!shouldRender) return null;

  return (
    <PropertiesPanelWrapper
      wrapperRef={wrapperRef}
      onClose={closePropertiesPanel}
    />
  );
};
