import { XYPosition } from '@jux/data-entities';
import type { Box, CoordinateExtent, Dimensions, JuxRect } from '../../types';

export const getDimensions = (node: HTMLDivElement): Dimensions => ({
  width: node.offsetWidth,
  height: node.offsetHeight,
});

export const clamp = (val: number, min = 0, max = 1): number =>
  Math.min(Math.max(val, min), max);

export const clampPosition = (
  extent: CoordinateExtent,
  position: XYPosition = { x: 0, y: 0 }
) => ({
  x: clamp(position.x, extent[0][0], extent[1][0]),
  y: clamp(position.y, extent[0][1], extent[1][1]),
});

// returns a number between 0 and 1 that represents the velocity of the movement
// when the mouse is close to the edge of the canvas
const calcAutoPanVelocity = (
  value: number,
  min: number,
  max: number
): number => {
  if (value < min) {
    return clamp(Math.abs(value - min), 1, 50) / 50;
  } else if (value > max) {
    return -clamp(Math.abs(value - max), 1, 50) / 50;
  }

  return 0;
};

export const calcAutoPan = (pos: XYPosition, bounds: Dimensions): number[] => {
  // Start movement when the mouse is 5px from the edge
  const xMovement = calcAutoPanVelocity(pos.x, 5, bounds.width - 5) * 20;
  const yMovement = calcAutoPanVelocity(pos.y, 5, bounds.height - 5) * 20;

  return [xMovement, yMovement];
};

export const getBoundsOfBoxes = (box1: Box, box2: Box): Box => ({
  x: Math.min(box1.x, box2.x),
  y: Math.min(box1.y, box2.y),
  x2: Math.max(box1.x2, box2.x2),
  y2: Math.max(box1.y2, box2.y2),
});

export const rectToBox = ({ x, y, width, height }: JuxRect): Box => ({
  x,
  y,
  x2: x + width,
  y2: y + height,
});

export const boxToRect = ({ x, y, x2, y2 }: Box): JuxRect => ({
  x,
  y,
  width: x2 - x,
  height: y2 - y,
});

export const getOverlappingArea = (rectA: JuxRect, rectB: JuxRect): number => {
  const xOverlap = Math.max(
    0,
    Math.min(rectA.x + rectA.width, rectB.x + rectB.width) -
      Math.max(rectA.x, rectB.x)
  );

  const yOverlap = Math.max(
    0,
    Math.min(rectA.y + rectA.height, rectB.y + rectB.height) -
      Math.max(rectA.y, rectB.y)
  );

  return Math.ceil(xOverlap * yOverlap);
};

export const isNumeric = (n: any): n is number => !isNaN(n) && isFinite(n);
