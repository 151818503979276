import { KeyboardEvent, MouseEvent, useCallback } from 'react';
import { toast } from '@jux/ui/toast';
import { useInviteUserByEmailsMutation } from '@jux/ui/data';
import { SegmentEventSourcePage } from '@jux/events/segment/segment.interface';
import { useCurrentOrg } from '@jux/ui/hooks';
import { useTrackInviteMembers } from './useTrackInviteMembers';

const parseStringToArray = (str: string): string[] =>
  str.split(',').map((email) => email.trim());

export const useSaveInviteMembersForm = ({
  sourcePage,
  setErrors,
  getEmailValue,
  setEmailValue,
  closeSubmitModal,
}: {
  sourcePage: SegmentEventSourcePage;
  setErrors: (error: string) => void;
  getEmailValue: () => string;
  setEmailValue: (value: string) => void;
  closeSubmitModal: () => void;
}) => {
  const { id: orgId } = useCurrentOrg();
  const InviteUserByEmails = useInviteUserByEmailsMutation();
  const { trackSendInviteMembers } = useTrackInviteMembers(sourcePage);

  const sendEmail = useCallback(
    (emails: string) => {
      InviteUserByEmails.mutate(
        {
          emails: parseStringToArray(emails),
          org_id: orgId,
        },
        {
          onSuccess: () => {
            setEmailValue('');

            trackSendInviteMembers();
            toast.success('Invitation sent', {
              role: 'alert',
            });

            closeSubmitModal();
          },
          onError: (error) => {
            setErrors(error.response?.data.message || 'Something went wrong');
          },
        }
      );
    },
    [
      InviteUserByEmails,
      closeSubmitModal,
      orgId,
      setEmailValue,
      setErrors,
      trackSendInviteMembers,
    ]
  );

  const handleSubmit = useCallback(
    (event: Event | KeyboardEvent | MouseEvent) => {
      event.preventDefault();

      setErrors('');

      sendEmail(getEmailValue());
    },
    [getEmailValue, sendEmail, setErrors]
  );

  return {
    onSubmit: handleSubmit,
  };
};
