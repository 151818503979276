import {
  ActionsWithoutStateParam,
  JuxStore,
  JuxStoreActionFn,
  SetStateFn,
} from '../store.interface';
import { composeAction } from './composeAction';

/**
 * Creates a map of actions with the state injected.
 */
export const createStoreActions = <
  T extends Record<string, JuxStoreActionFn<any, any>>
>({
  actionsMap,
  setState,
}: {
  actionsMap: T;
  setState: SetStateFn<JuxStore>;
}): ActionsWithoutStateParam<T> =>
  Object.entries(actionsMap).reduce(
    (acc, [key, actionFn]) => ({
      ...acc,
      [key]: composeAction({ actionFn, setState, actionName: key }),
    }),
    {} as ActionsWithoutStateParam<T>
  );
