import { ElementDataInit } from '../../types/ElementDataInit';
import * as uuid from 'uuid';
import { getJuxLabelElementData } from '../label';
import { getJuxSvgElementData } from '../svg';
import { getJuxInputInstanceElementData } from '../input/inputInstance';
import { getJuxDivElementData } from '../div';
import { JUX_ASSET_IMAGE_ICON_ID } from '../../common/assets';
import { JuxComponentData } from '@jux/data-entities';
import { Colors } from '../../common/colors';

const getTextFieldTriggerChildren = ({ parentId }: { parentId: string }) => {
  const contentWrapperElementId = uuid.v4();

  const leadingIconElement = getJuxSvgElementData({
    displayName: 'leading_icon',
    parentId: contentWrapperElementId,
    sourceComponentId: JUX_ASSET_IMAGE_ICON_ID,
    rootStyles: {
      color: Colors.Neutrals_SpanishGrey,
    },
  }).root;

  const { instance: inputElement, source: inputSourceComponent } =
    getJuxInputInstanceElementData({
      displayName: 'input',
      parentId: contentWrapperElementId,
    });

  const trailingIconElement = getJuxSvgElementData({
    displayName: 'trailing_icon',
    parentId: contentWrapperElementId,
    sourceComponentId: JUX_ASSET_IMAGE_ICON_ID,
    rootStyles: {
      color: Colors.Neutrals_SpanishGrey,
    },
  }).root;

  const contentWrapperElement = getJuxDivElementData({
    id: contentWrapperElementId,
    displayName: 'content_wrapper',
    styles: {
      root: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '9px 12px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: `${Colors.Neutrals_400}`,
        borderRadius: '4px',
        alignSelf: 'stretch',
        backgroundColor: `${Colors.Neutrals_0}`,
        width: '100%',
        height: '36px',
        overflow: 'hidden',
      },
      states: {},
    },
    parentId,
    children: [leadingIconElement.id, inputElement.id, trailingIconElement.id],
  }).root;

  return {
    contentWrapperElement,
    inputElementId: inputElement.id,
    subComponents: [
      leadingIconElement,
      inputElement,
      trailingIconElement,
      inputSourceComponent,
    ],
  };
};

export const getTextFieldTriggerElementData: ElementDataInit<undefined> = ({
  contextParentId,
  parentId,
  id = uuid.v4(),
} = {}) => {
  const {
    contentWrapperElement,
    inputElementId,
    subComponents: otherSubComponents,
  } = getTextFieldTriggerChildren({ parentId: id });

  const textFieldLabelTrigger = getJuxLabelElementData({
    id,
    contextParentId,
    parentId,
    labelForId: inputElementId,
    displayName: 'trigger',
    children: [contentWrapperElement.id],
  }).root;

  return {
    root: textFieldLabelTrigger,
    subComponents: ([contentWrapperElement] as Array<JuxComponentData>).concat(
      otherSubComponents || []
    ),
  };
};
