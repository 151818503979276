import { forwardRef } from 'react';
import { LoadingButtonWithIcon as Button } from '@jux/ui/components';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { MagnifierControlIconButtonProps } from './MagnifierControlIconButton.interface';

export const MagnifierControlIconButton = forwardRef<
  HTMLButtonElement,
  MagnifierControlIconButtonProps
>(({ icon, onClick, sx, ...props }, ref) => (
  <Button
    icon={icon}
    iconProps={{
      sx: {
        color: (theme) => theme.drimz.palette.text.primary,
      },
    }}
    onClick={onClick}
    sx={mergeTyped(
      {
        borderRadius: (theme) => theme.drimz.size.borderRadius.small,
        width: (theme) =>
          theme.drimz.size.editor.canvas.panels.magnifier.control.width,
        minWidth: (theme) =>
          theme.drimz.size.editor.canvas.panels.magnifier.control.width,
        '&:hover': {
          backgroundColor: (theme) => theme.drimz.palette.background.secondary,
        },
      },
      sx
    )}
    {...props}
    ref={ref}
  />
));
