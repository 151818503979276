import { FC, useRef } from 'react';
import {
  DDP_FIELD_TOKENS_BOX_BORDER_COLOR,
  DDP_FIELD_TOKENS_BOX_NO_TOKENS_TEXT_COLOR,
} from '@jux/ui/theme/palette';
import { Typography, typographyVariants } from '@jux/ui/components';
import {
  useOnFocusOutside,
  useOnKeyEvent,
  useOnClickOutside,
} from '@jux/ui/hooks';
import { TokenSelectionAutocomplete } from '../autocomplete';
import { TokenSelectionContentProps } from './TokenSelection.interface';
import { TokenSelectionPopperWrapper } from './TokenSelectionPopperWrapper';

export const TokenSelectionPopper: FC<TokenSelectionContentProps> = ({
  parsedTokens,
  parsedSelectedTokenValue,
  onTokenSelection,
  closeTokens,
  renderOption,
  actions,
  hasNoTokens,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnClickOutside({ refOrSelector: [wrapperRef], handler: closeTokens });
  useOnFocusOutside(wrapperRef, closeTokens);
  useOnKeyEvent(wrapperRef, closeTokens, 'Escape');

  return (
    <TokenSelectionPopperWrapper
      sx={{
        ...(hasNoTokens && {
          padding: '9px',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          textAlign: 'center',
        }),
      }}
      ref={wrapperRef}
    >
      {hasNoTokens ? (
        <Typography
          variant="label-s-medium"
          color={DDP_FIELD_TOKENS_BOX_NO_TOKENS_TEXT_COLOR}
        >
          No tokens found
        </Typography>
      ) : (
        <TokenSelectionAutocomplete
          inputName="token"
          isComputed={false}
          inputProps={{
            placeholder: 'Search for token',
            sx: {
              ...typographyVariants['label-s-medium'],
              padding: '8px 12px',
              width: '100%',
              height: (theme) =>
                theme.drimz.size.editor.ddp.field.token.box.search.height,
              color: (theme) => theme.drimz.palette.text.primary,

              '&:placeholder': {
                color: (theme) => theme.drimz.palette.text.secondary,
              },
            },
          }}
          inputWrapperProps={{
            sx: {
              width: '100%',
              borderBottom: '1px solid',
              borderColor: DDP_FIELD_TOKENS_BOX_BORDER_COLOR,
              paddingRight: '7px',
            },
          }}
          value={parsedSelectedTokenValue}
          options={parsedTokens}
          onSelect={onTokenSelection}
          renderOption={renderOption}
          onBlur={() => {}}
          onEnter={() => {}}
          onEscape={closeTokens}
        />
      )}
      {actions}
    </TokenSelectionPopperWrapper>
  );
};
