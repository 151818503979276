import { v4 as uuid } from 'uuid';
import {
  AssetsActionsParams,
  JuxStore,
  JuxStoreActionFn,
} from '../../store.interface';

/**
 * Create a new asset.
 */
export const createAsset: JuxStoreActionFn<
  AssetsActionsParams['createAsset'],
  JuxStore
> = ({ assetData, state }) => {
  const assetId = uuid();
  state.assets[assetId] = assetData;

  return state;
};
