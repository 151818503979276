import { useEffect, useMemo } from 'react';
import { useEffectOnSelectedNodeStateChange } from '@jux/ui/components/editor/components/panels/DDP/hooks/useEffectOnSelectedNodeStateChange';
import {
  useEffectOnSelectedNodeChange,
  useIsSelectedNodeImmutable,
  useSelectedNodeComponent,
  useSelectedNodes,
} from '@jux/ui/components/editor/hooks';
import { NodeType } from '@jux/data-entities';
import { useDDPSubModulesState } from '../../../../state';
import { useLiveOrEdit } from '../../liveOrEdit/useLiveOrEdit';
import { useEffectOnSelectedNodeContextChange } from './useEffectOnSelectedNodeContextChange';
import { useEffectOnSelectedNodeVariantValuesChange } from './useEffectOnSelectedNodeVariantValuesChange';
import { useSetupDDPValues } from './useSetupDDPValues';
import { useEffectOnPlaceholderModeChange } from './useEffectOnPlaceholderModeChange';

export const useDDPContent = () => {
  const selectedNodes = useSelectedNodes();
  const { component: selectedNodeComponent } = useSelectedNodeComponent();
  const isImmutable = useIsSelectedNodeImmutable();

  const { setupDDPValues } = useSetupDDPValues();

  const { subModulesState } = useDDPSubModulesState();
  const { isLive } = useLiveOrEdit();

  const hasSubModules = useMemo(
    () => Object.keys(subModulesState).length > 0,
    [subModulesState]
  );

  const isComponentInstance = selectedNodeComponent?.type === NodeType.INSTANCE;
  const isLogicalSlot = selectedNodeComponent?.type === NodeType.LOGICAL_SLOT;

  // We want to run the setup whenever the contextParent state or props change
  useEffectOnSelectedNodeChange(setupDDPValues);
  useEffectOnSelectedNodeContextChange(setupDDPValues);
  useEffectOnSelectedNodeStateChange(setupDDPValues);
  useEffectOnSelectedNodeVariantValuesChange(setupDDPValues);
  useEffectOnPlaceholderModeChange(setupDDPValues);

  useEffect(() => {
    setupDDPValues();
  }, [setupDDPValues]);

  return {
    isComponentInstance,
    isLogicalSlot,
    isImmutable,
    isLive,
    // isMatrix: selectedNodeComponent?.type === StorageNodeType.VARIANTS_GROUP,
    isMatrix: false,
    nonSelected: !selectedNodes.length || !hasSubModules,
    multipleSelected: selectedNodes.length > 1,
  };
};
