import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { PaddingTopField } from './PaddingTopField';
import { PaddingRightField } from './PaddingRightField';
import { PaddingBottomField } from './PaddingBottomField';
import { PaddingLeftField } from './PaddingLeftField';
import { PaddingField } from './PaddingField';

export const Padding: FC = () => {
  const { shouldRender, isOpen, setIsOpen } = useSubModule(
    DDPPrimitiveFieldsKeys.padding
  );

  if (!shouldRender) return null;
  return (
    <SubModule title="Padding" isOpen={isOpen} setIsOpen={setIsOpen}>
      {isOpen ? (
        <MuiGrid container item flex={1} flexDirection="column" gap="6px">
          <PaddingTopField />
          <PaddingRightField />
          <PaddingBottomField />
          <PaddingLeftField />
        </MuiGrid>
      ) : (
        <PaddingField />
      )}
    </SubModule>
  );
};
