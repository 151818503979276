import { FC } from 'react';
import { ButtonGroupField, GroupButton } from '../../../../../base';
import { useFlexWrapField } from './useFlexWrapField';

export const FlexWrapField: FC = () => {
  const { isFlexWrapSelected, handleFlexWrapClick } = useFlexWrapField();

  return (
    <ButtonGroupField>
      <GroupButton
        icon="DDP_FIELD_FLEX_WRAP"
        isSelected={isFlexWrapSelected}
        onClick={handleFlexWrapClick}
        tooltipTitle="Wrap children"
        allowToggle
      />
    </ButtonGroupField>
  );
};
