import { withPx } from '@jux/ui/utils/css';
import { colorWithOpacity } from '@jux/ui/utils/colorWithOpacity';
import {
  COMMON_MENU_LIST_BOX_SHADOW,
  DDP_FIELD_TOKENS_BOX_SHADOW,
  EDITOR_DDP_COLOR_INDICATOR_BOX_SHADOW,
  MODAL_BOX_SHADOW_COLOR,
} from '../palette';
import { Colors } from './base-theme-colors';

const BOX_SHADOW_SPREAD_RADIUS = 10;

export const baseThemeShadow = (colors: Colors) => ({
  common: {
    menuList: {
      spreadRadius: BOX_SHADOW_SPREAD_RADIUS,
      boxShadow: `0px 2px ${withPx(
        BOX_SHADOW_SPREAD_RADIUS
      )} 0px ${COMMON_MENU_LIST_BOX_SHADOW}`,
    },
  },
  editor: {
    navbar: {
      menuTab: {
        content: {
          spreadRadius: BOX_SHADOW_SPREAD_RADIUS,
          boxShadow: `0px 4px ${withPx(BOX_SHADOW_SPREAD_RADIUS)} 0px ${
            colors.primaryShadow
          }`,
        },
      },
    },
    panels: {
      ddp: {
        baseField: {
          tokens: {
            boxShadow: `0px 2px 8px 0px ${DDP_FIELD_TOKENS_BOX_SHADOW}`,
          },
        },
        colorField: {
          indicator: `inset 0 0 2px 0 ${EDITOR_DDP_COLOR_INDICATOR_BOX_SHADOW}`,
        },
        assetsPopper: {
          boxShadow: `0px 4px 10px 0px ${colorWithOpacity(
            MODAL_BOX_SHADOW_COLOR,
            0.2
          )}`,
        },
      },
    },
  },
  tokens: {
    poppers: {
      boxShadow: `0px 2px 8px 0px  ${colorWithOpacity(
        MODAL_BOX_SHADOW_COLOR,
        0.1
      )}`,
    },
  },
});
