/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from './core/CustomIcon';

export const DimensionIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'rect',
        attr: {
          x: '3',
          y: '3',
          width: '10',
          height: '3',
          rx: '1',
          fill: 'currentColor',
        },
        child: [],
      },
      {
        tag: 'rect',
        attr: {
          x: '3',
          y: '10',
          width: '10',
          height: '3',
          rx: '1',
          fill: 'currentColor',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
