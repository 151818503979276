import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import {
  DDPFieldsStateKeys,
  DDPFieldsState,
} from './useDDPFieldsState.interface';

const subModuleStateAtom = atom<DDPFieldsState>(
  {} as unknown as DDPFieldsState
);

export const useDDPFieldsState = () => {
  const [subModuleState, setFieldsState] = useAtom(subModuleStateAtom);

  const getFieldsStateByKey = useCallback(
    <Key extends DDPFieldsStateKeys>(key: Key) => subModuleState[key],
    [subModuleState]
  );

  const setFieldsStateByKey = useCallback(
    <Key extends DDPFieldsStateKeys>(
      key: Key,
      state: Partial<DDPFieldsState[Key]>
    ) => {
      setFieldsState((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          ...state,
        },
      }));
    },
    [setFieldsState]
  );

  return {
    getFieldsStateByKey,
    setFieldsState,
    setFieldsStateByKey,
  };
};
