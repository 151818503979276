'use client';

import { useRef } from 'react';
import {
  selectCurrentCanvas,
  selectLayersPanelItems,
  storeApi,
} from '@jux/canjux/core';
import { useSignalSelector } from '@jux/ui/components/editor/components/panels/layers/state/useSignalSelector';
import {
  getLayersItemsRecursive,
  LayersPanelItem,
} from './getLayersItemsRecursive';

export const useLayersPanelItems = () => {
  const layersRerenderTimestamp = useSignalSelector(
    (s) => s.layersRerenderTimestamp
  );

  const timestampRef = useRef(layersRerenderTimestamp);

  const itemsRef = useRef<Map<string, LayersPanelItem>>(new Map());

  if (timestampRef.current !== layersRerenderTimestamp) {
    const currentCanvas = selectCurrentCanvas(storeApi.getState());

    itemsRef.current = getLayersItemsRecursive({
      nodes: selectLayersPanelItems(storeApi.getState()),
      rootNodeIds: currentCanvas?.rootNodesOrder,
    });
  }

  return itemsRef.current;
};
