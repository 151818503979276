import { FC } from 'react';
import { LayersPanelItem } from '@jux/ui/components/editor/components/panels/layers/state/getLayersItemsRecursive';
import { Typography } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import {
  ObjectNavigatorHeaderIcon,
  ObjectNavigatorHeaderStickyWrapper,
} from '../layout';
import { useLayersPanelStateValue, useLayersPanelExpansion } from '../state';

export const ObjectNavigatorHeader: FC<{
  items: Map<string, LayersPanelItem>;
}> = ({ items }) => {
  const { shouldShowGlobalExpanders } = useLayersPanelStateValue();
  const { collapseAll, expandAll, isAllRootsCollapsed } =
    useLayersPanelExpansion({ items });

  return (
    <ObjectNavigatorHeaderStickyWrapper>
      <MuiGrid item container justifyContent="space-between">
        <MuiGrid item>
          <Typography variant="label-s-bold">Object Navigator</Typography>
        </MuiGrid>
        <MuiGrid
          item
          display="inline-flex"
          alignItems="center"
          sx={{ paddingInlineEnd: '0.5px' }}
        >
          {shouldShowGlobalExpanders && (
            <ObjectNavigatorHeaderIcon
              variant={
                isAllRootsCollapsed
                  ? 'LAYERS_PANEL_EXPAND_ALL'
                  : 'LAYERS_PANEL_COLLAPSE_ALL'
              }
              onClick={isAllRootsCollapsed ? expandAll : collapseAll}
            />
          )}
        </MuiGrid>
      </MuiGrid>
    </ObjectNavigatorHeaderStickyWrapper>
  );
};
