/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const FontSizeDDPIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M12.9697 6.53033C13.2626 6.82322 13.7374 6.82322 14.0303 6.53033C14.3232 6.23744 14.3232 5.76256 14.0303 5.46967L12.5303 3.96967C12.3897 3.82902 12.1989 3.75 12 3.75C11.8011 3.75 11.6103 3.82902 11.4697 3.96967L9.96967 5.46967C9.67678 5.76256 9.67678 6.23744 9.96967 6.53033C10.2626 6.82322 10.7374 6.82322 11.0303 6.53033L11.25 6.31066V11.5C11.25 11.9142 11.5858 12.25 12 12.25C12.4142 12.25 12.75 11.9142 12.75 11.5V6.31066L12.9697 6.53033ZM2 12H3.88331L4.49994 10.1758H7.50126L8.11669 12H10L7.13445 4H4.86961L2 12ZM6.0345 5.82812H5.96956L4.94624 8.85547H7.05583L6.0345 5.82812Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
