import { FC } from 'react';
import { IconBaseProps } from '../../Icons.interface';

export const IconButtonElementPanelIcon: FC<IconBaseProps> = ({
  backgroundColor,
  ...props
}) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.5" width="32" height="32" rx="4" fill="currentColor" />
    <path
      d="M7.51471 17.9142C6.73366 17.1332 6.73366 15.8668 7.51471 15.0858L14.5858 8.01471C15.3668 7.23366 16.6332 7.23366 17.4142 8.01471L24.4853 15.0858C25.2663 15.8668 25.2663 17.1332 24.4853 17.9142L17.4142 24.9853C16.6332 25.7663 15.3668 25.7663 14.5858 24.9853L7.51471 17.9142Z"
      fill={backgroundColor}
    />
  </svg>
);
