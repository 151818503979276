import { MuiGrid, styled } from '@jux/ui/components/common/mui';
import {
  EDITOR_DDP_FIELD_BORDER_COLOR,
  EDITOR_DDP_FIELD_HOVER_BORDER_COLOR,
  EDITOR_DDP_FIELD_FOCUS_BORDER_COLOR,
  EDITOR_DDP_FIELD_HOVER_BACKGROUND,
  EDITOR_DDP_CHILD_FIELDS_INACTIVE_COLOR,
} from '@jux/ui/theme/palette';
import { tokenSelectionWrapperClassName } from '../tokensSelection';

export const Field = styled(MuiGrid, {
  shouldForwardProp: (prop) => prop !== 'isFieldDisabled',
  label: 'Field',
})<{ isFieldDisabled: boolean }>(({ theme, isFieldDisabled }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  gap: '4px',
  border: `1px solid ${EDITOR_DDP_FIELD_BORDER_COLOR}`,
  borderRadius: theme.drimz.size.borderRadius.small,
  transition: theme.drimz.transitions.all,
  color: isFieldDisabled
    ? EDITOR_DDP_CHILD_FIELDS_INACTIVE_COLOR
    : theme.drimz.palette.text.secondary,

  [`& .${tokenSelectionWrapperClassName}`]: {
    width: 0,
  },

  '&:hover': {
    borderColor: isFieldDisabled
      ? EDITOR_DDP_FIELD_BORDER_COLOR // when disabled, keep the idle border color
      : EDITOR_DDP_FIELD_HOVER_BORDER_COLOR,

    [`& .${tokenSelectionWrapperClassName}`]: {
      width: 'auto',
    },

    // When hovered, make nested focusable buttons visible
    '& button[tabIndex]': {
      opacity: '1',
    },
  },

  '&:focus, &:focus-within': {
    borderColor: isFieldDisabled
      ? EDITOR_DDP_CHILD_FIELDS_INACTIVE_COLOR
      : EDITOR_DDP_FIELD_FOCUS_BORDER_COLOR,

    [`& .${tokenSelectionWrapperClassName}`]: {
      width: 'auto',
    },

    // When focused, make nested focusable buttons visible
    '& button[tabIndex]': {
      opacity: '1',
    },
  },
}));

export const ContentWrapper = styled(MuiGrid, {
  label: 'FieldContentWrapper',
  shouldForwardProp: (prop) => prop !== 'isTokenized',
})<{
  isTokenized?: boolean;
}>(({ isTokenized }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flex: 1,
  paddingLeft: isTokenized ? 0 : '8px',
}));

export const ContentValueLabel = styled('label', {
  shouldForwardProp: (prop) =>
    prop !== 'isTokenized' &&
    prop !== 'fieldClassName' &&
    prop !== 'isFieldDisabled',
})<{
  isTokenized?: boolean;
  isFieldDisabled?: boolean;
  fieldClassName: string;
}>(({ theme, isTokenized, isFieldDisabled, fieldClassName }) => ({
  display: 'flex',
  alignItems: 'center',
  transition: `${theme.drimz.transitions.all}, margin 0s`,
  backgroundColor: theme.drimz.palette.transparent,

  borderRadius: theme.drimz.size.borderRadius.small,
  height: theme.drimz.size.editor.ddp.field.content.height,
  width: '100%',
  overflow: 'hidden',
  flexWrap: 'nowrap',

  '&, & > [role="button"]': {
    // For components such as `<MuiGrid role="button">` (in `<TokenFieldContent>`)
    outline: 'none',
  },

  [`.${fieldClassName}:hover &`]: {
    backgroundColor:
      isTokenized && !isFieldDisabled
        ? EDITOR_DDP_FIELD_HOVER_BACKGROUND
        : theme.drimz.palette.transparent,
  },

  [`.${fieldClassName} & > [role="button"]:focus-visible`]: {
    // For components such as `<MuiGrid role="button">` (in `<TokenFieldContent>`)
    backgroundColor: isFieldDisabled
      ? theme.drimz.palette.transparent
      : EDITOR_DDP_FIELD_HOVER_BACKGROUND,
  },
}));
