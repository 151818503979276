export const PanelsKeys = {
  // TODO: support more panels
  // DDP: 'DDP',
  // objectNavigator: 'objectNavigator',
  properties: 'properties',
} as const;

export type PanelsKeysType = keyof typeof PanelsKeys;

export type PanelState = {
  shouldRender: boolean;
};

export type PanelsState = Record<PanelsKeysType, PanelState>;
