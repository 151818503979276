import { ElementDataInit } from '../../types/ElementDataInit';
import * as uuid from 'uuid';
import { getJuxLabelElementData } from '../label';
import { getJuxTextareaInstanceElementData } from '../textarea/textareaInstance';
import { getJuxDivElementData } from '../div';
import { JuxComponentData } from '@jux/data-entities';
import { Colors } from '../../common/colors';

const getTextareaFieldTriggerChildren = ({
  parentId,
}: {
  parentId: string;
}) => {
  const contentWrapperElementId = uuid.v4();

  const { instance: textareaElement, source: textareaSourceComponent } =
    getJuxTextareaInstanceElementData({
      displayName: 'textarea',
      parentId: contentWrapperElementId,
    });

  const contentWrapperElement = getJuxDivElementData({
    id: contentWrapperElementId,
    displayName: 'content_wrapper',
    styles: {
      root: {
        border: `1px solid ${Colors.Neutrals_400}`,
        borderRadius: '4px',
        color: Colors.Neutrals_600,
        fontSize: '14px',
        fontWeight: 500,
        gap: '8px',
        height: '194px',
        outline: 'none',
        overflow: 'hidden',
        width: '320px',
      },
      states: {
        'focus-visible': {
          root: {},
          variants: [
            {
              propsValues: {
                disabled: false,
              },
              styles: {
                border: `1px solid ${Colors.Brand_500}`,
              },
            },
          ],
        },
      },
      variants: [],
    },
    parentId,
    children: [textareaElement.id],
  }).root;

  return {
    contentWrapperElement,
    textareaElementId: textareaElement.id,
    subComponents: [textareaElement, textareaSourceComponent],
  };
};

export const getTextareaFieldTriggerElementData: ElementDataInit<undefined> = ({
  contextParentId,
  parentId,
  id = uuid.v4(),
} = {}) => {
  const {
    contentWrapperElement,
    textareaElementId,
    subComponents: otherSubComponents,
  } = getTextareaFieldTriggerChildren({ parentId: id });

  const textFieldLabelTrigger = getJuxLabelElementData({
    id,
    contextParentId,
    parentId,
    labelForId: textareaElementId,
    displayName: 'trigger',
    children: [contentWrapperElement.id],
  }).root;

  return {
    root: textFieldLabelTrigger,
    subComponents: ([contentWrapperElement] as Array<JuxComponentData>).concat(
      otherSubComponents || []
    ),
  };
};
