import * as uuid from 'uuid';
import {
  NodeType,
  ComponentPropType,
  COMPONENT_TAG_NAME,
  JuxComponentData,
} from '@jux/data-entities';
import { Colors } from '../../common/colors';
import { ElementDataInit } from '../../types/ElementDataInit';
import { getTextLabelElement } from '../common/textLabel';
import { getHelperTextElement } from '../common/helperText';
import { getTextFieldTriggerElementData } from './textFieldTrigger';
import { ComponentConfigWithStates as StylesConfig } from '@jux/types';

export const TEXT_FIELD_ELEMENT_DEFAULT_DISPLAY_NAME = 'Text field';

export const TEXT_FIELD_ELEMENT_DEFAULT_ROOT_STYLES: StylesConfig = {
  root: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '6px',
  },
  variants: [],
  states: {},
};

export const getJuxTextFieldChildren = ({
  rootTextFieldId,
}: {
  rootTextFieldId: string;
}) => {
  const labelElement = getTextLabelElement({
    parentId: rootTextFieldId,
    contextParentId: rootTextFieldId,
  }).root;

  const {
    root: textFieldTriggerElement,
    subComponents: textFieldTriggerSubComponents,
  } = getTextFieldTriggerElementData({
    parentId: rootTextFieldId,
    contextParentId: rootTextFieldId,
  });

  const helperTextElement = getHelperTextElement({
    parentId: rootTextFieldId,
    contextParentId: rootTextFieldId,
  }).root;

  return {
    labelElement,
    textFieldTriggerElement,
    helperTextElement,
    subComponents: (
      [
        labelElement,
        textFieldTriggerElement,
        helperTextElement,
      ] as Array<JuxComponentData>
    ).concat(textFieldTriggerSubComponents || []),
  };
};

export const getJuxTextFieldElementData: ElementDataInit<undefined> = ({
  parentId,
} = {}) => {
  const rootTextFieldId = uuid.v4();

  const {
    labelElement,
    textFieldTriggerElement,
    helperTextElement,
    subComponents,
  } = getJuxTextFieldChildren({ rootTextFieldId });

  return {
    root: {
      tagName: COMPONENT_TAG_NAME.JuxTextField,
      type: NodeType.LOCAL_COMPONENT,
      id: rootTextFieldId,
      parentId,
      scopes: [],
      children: [
        labelElement.id,
        textFieldTriggerElement.id,
        helperTextElement.id,
      ],
      styles: {
        ...TEXT_FIELD_ELEMENT_DEFAULT_ROOT_STYLES,
        contextStyles: [
          {
            contextChildUuid: textFieldTriggerElement.children[0], // content wrapper
            condition: {
              propsValues: { disabled: true },
            },
            styles: {
              borderColor: Colors.Neutrals_200,
            },
          },
          {
            contextChildUuid: textFieldTriggerElement.children[0], // content wrapper
            condition: {
              state: 'focus-within',
              propsValues: { disabled: false },
            },
            styles: {
              borderColor: Colors.Brand_500,
            },
          },
          {
            contextChildUuid: textFieldTriggerElement.children[0], // content wrapper
            condition: {
              state: 'hover',
              propsValues: { disabled: false },
            },
            styles: {
              borderColor: Colors.Neutrals_600,
            },
          },
        ],
      },
      config: {
        props: {
          disabled: false,
          placeholder: 'Placeholder',
          value: '',
        },
        variants: [
          {
            variant: 'disabled',
            propType: ComponentPropType.Enum,
            options: [
              { value: true, label: 'True' },
              { value: false, label: 'False' },
            ],
            defaultValue: false,
            isFixed: true,
          },
        ],
      },
      displayName: TEXT_FIELD_ELEMENT_DEFAULT_DISPLAY_NAME,
    },
    subComponents,
  };
};
