import {
  ChangeEvent,
  ComponentType,
  forwardRef,
  useCallback,
  useState,
} from 'react';
import {
  COMPONENT_TAG_NAME,
  ComponentElementType,
  ComponentProps,
} from '@jux/data-entities';

type InputComponentType = {
  Component: ComponentType<ComponentProps<typeof COMPONENT_TAG_NAME.JuxInput>>;
};

type InputLiveModeProps = InputComponentType &
  ComponentProps<typeof COMPONENT_TAG_NAME.JuxInput>;

export const InputLiveMode = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxInput>,
  InputLiveModeProps
>(({ Component, onChange, value, ...props }, ref) => {
  const [val, setVal] = useState(value);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);

      setVal(e.target.value);
    },
    [onChange]
  );

  return <Component {...props} ref={ref} onChange={handleChange} value={val} />;
});
