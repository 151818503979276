import { Panel as CanjuxPanel } from '@jux/canjux/core';
import { withPx } from '@jux/ui/utils/css';
import { styled } from '@jux/ui/components/common/mui';

export const Panel = styled(CanjuxPanel)(() => ({}));

export const StyledPanelForProperties = styled(CanjuxPanel, {
  shouldForwardProp: (prop) => prop !== 'ddpPanelWidth',
})<{
  ddpPanelWidth: number;
}>(({ theme, ddpPanelWidth }) => ({
  marginRight: `calc(${withPx(ddpPanelWidth)} + ${withPx(
    theme.drimz.size.editor.properties.margin
  )})`,
  maxHeight: `calc(100vh - ${withPx(
    theme.drimz.size.editor.navbar.height
  )} - ${withPx(theme.drimz.size.editor.properties.margin)} - ${withPx(
    theme.drimz.size.editor.properties.margin
  )})`,
  marginTop: withPx(
    theme.drimz.size.editor.properties.margin +
      theme.drimz.size.editor.navbar.height
  ),
}));
