import { FC } from 'react';
import { IconButton, Typography } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';

export const PropertiesPanelHeader: FC<{
  onClose: () => void;
}> = ({ onClose }) => (
  <MuiGrid
    container
    item
    sx={{
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '14px 20px',
      borderBottom: '1px solid',
      borderColor: (theme) => theme.drimz.palette.divider.primary,
      position: 'sticky',
      top: 0,
      zIndex: 1,
      background: (theme) => theme.drimz.palette.background.default,
    }}
  >
    <MuiGrid item>
      <Typography variant="label-s-bold">Edit properties</Typography>
    </MuiGrid>
    <MuiGrid item display="flex">
      <IconButton icon="CLOSE" variant="icon" onClick={onClose} />
    </MuiGrid>
  </MuiGrid>
);
