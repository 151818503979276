import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { BorderColorField } from './BorderColorField';

export const BorderColor: FC = () => {
  const { shouldRender } = useSubModule(DDPSubModulesKeys.borderColor);

  if (!shouldRender) return null;
  return (
    <SubModule title="Color" hideHeader>
      <BorderColorField />
    </SubModule>
  );
};
