import { LayersPanelItem } from '../getLayersItemsRecursive';

export const isNextSiblingItem = (
  firstItem: LayersPanelItem | undefined,
  secondItem: LayersPanelItem | undefined
): boolean => {
  if (
    !firstItem ||
    firstItem.relativeIndex === undefined ||
    !secondItem ||
    secondItem.relativeIndex === undefined
  )
    return false;

  // both dragged and hovered item
  const isItemsOnTheSameLevel =
    firstItem.parentId === secondItem.parentId &&
    firstItem.indentLevel === secondItem.indentLevel;

  return (
    isItemsOnTheSameLevel &&
    firstItem.relativeIndex + 1 === secondItem.relativeIndex
  );
};
