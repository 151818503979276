import { useMemo } from 'react';
import { ComponentTagName } from '@jux/data-entities';
import { IconsProps } from '@jux/ui/components/common';
import { useTheme } from '@jux/ui/components/common/mui';

type ElementData = {
  icon: Partial<IconsProps> & Pick<IconsProps, 'variant'>;
  displayName: string;
  tagName: ComponentTagName;
};

export const useElementsTab = () => {
  const appTheme = useTheme();

  return useMemo(
    (): Array<ElementData> => [
      {
        icon: {
          variant: 'ELEMENTS_PANEL_BUTTON' as const,
          size: 70,
          iconBaseProps: {
            textColor: appTheme.drimz.palette.primary.main,
          },
        },
        displayName: 'Button',
        tagName: 'JuxButton',
      },
      {
        icon: {
          variant: 'ELEMENTS_PANEL_INPUT' as const,
          size: 90,
          iconBaseProps: {
            textColor: appTheme.drimz.palette.primary.main,
          },
        },
        displayName: 'Input',
        tagName: 'JuxInput',
      },
      {
        icon: {
          variant: 'ELEMENTS_PANEL_TEXT_FIELD' as const,
          size: 90,
          iconBaseProps: {
            textColor: appTheme.drimz.palette.primary.main,
          },
        },
        displayName: 'Text field',
        tagName: 'JuxTextField',
      },
      {
        icon: {
          variant: 'ELEMENTS_PANEL_CHECKBOX' as const,
          size: 32,
          iconBaseProps: {
            textColor: appTheme.drimz.palette.primary.main,
          },
        },
        displayName: 'Checkbox',
        tagName: 'JuxCheckbox',
      },
      {
        icon: {
          variant: 'ELEMENTS_PANEL_CHECKBOX_FIELD' as const,
          height: 33,
          width: 84,
          iconBaseProps: {
            textColor: appTheme.drimz.palette.primary.main,
          },
        },
        displayName: 'Checkbox Field',
        tagName: 'JuxCheckboxField',
      },
      {
        icon: {
          variant: 'ELEMENTS_PANEL_TOGGLE' as const,
          size: 42,
          iconBaseProps: {
            textColor: appTheme.drimz.palette.primary.main,
          },
        },
        displayName: 'Toggle',
        tagName: 'JuxToggle',
      },
      {
        icon: {
          variant: 'ELEMENTS_PANEL_RADIO' as const,
          size: 32,
        },
        displayName: 'Radio button',
        tagName: 'JuxRadio',
      },
      {
        icon: {
          variant: 'ELEMENTS_PANEL_CHIP' as const,
          size: 48,
        },
        displayName: 'Chip',
        tagName: 'JuxChip',
      },
      {
        icon: {
          variant: 'ELEMENTS_PANEL_ICON_BUTTON' as const,
          size: 48,
          iconBaseProps: {
            backgroundColor: appTheme.drimz.palette.primary.main,
          },
        },
        displayName: 'IconButton',
        tagName: 'JuxIconButton',
      },
      {
        icon: {
          variant: 'ELEMENTS_PANEL_DIVIDER' as const,
          size: 48,
          iconBaseProps: {
            backgroundColor: appTheme.drimz.palette.primary.main,
          },
        },
        displayName: 'Divider',
        tagName: 'JuxDivider',
      },
      {
        icon: {
          variant: 'ELEMENTS_PANEL_TEXTAREA' as const,
          size: 48,
          iconBaseProps: {
            backgroundColor: appTheme.drimz.palette.primary.main,
          },
        },
        displayName: 'Textarea',
        tagName: 'JuxTextarea',
      },
      {
        icon: {
          variant: 'ELEMENTS_PANEL_TEXTAREA_FIELD' as const,
          size: 48,
          iconBaseProps: {
            backgroundColor: appTheme.drimz.palette.primary.main,
          },
        },
        displayName: 'Textarea Field',
        tagName: 'JuxTextareaField',
      },
    ],
    [appTheme.drimz.palette.primary.main]
  );
};
