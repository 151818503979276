import { parseFontFamilyTokenValue } from '@jux/ui/components/tokens/token-drawer/forms/helpers';
import { TypographyTokenFormProps } from '@jux/ui/components/tokens/token-drawer/forms/types';

export const parseTypographyTokenValue = (
  value: TypographyTokenFormProps['initialValues']['value']
) => {
  if (value?.fontFamily) {
    value.fontFamily = parseFontFamilyTokenValue(value?.fontFamily);
  }
  return value;
};
