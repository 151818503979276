import {
  CanjuxState,
  CommonActionsParams,
  JuxStoreActionFn,
} from '@jux/canjux/core';

/**
 * Set transform
 */
export const setTransform: JuxStoreActionFn<
  CommonActionsParams['setTransform'],
  CanjuxState
> = ({ transform, state }) => {
  state.transform = transform;

  return state;
};
