import {
  IconsVariantsType,
  ICONS_VARIANT_BY_TAG_NAME,
  isSupportedComponentTagName,
  IconsSupportedComponentTagNameType,
} from '@jux/ui/components';

import { NodeType } from '@jux/data-entities';
export const getIconsVariant = ({
  objectType,
  tagName,
  isInstanceToSourceComponent,
}: {
  objectType: NodeType;
  tagName: string;
  isInstanceToSourceComponent?: boolean;
}): IconsVariantsType => {
  switch (objectType) {
    case NodeType.ELEMENT:
      return ICONS_VARIANT_BY_TAG_NAME[
        tagName as IconsSupportedComponentTagNameType
      ];
    case NodeType.LOGICAL_SLOT:
      return 'LAYERS_PANEL_LOGICAL_SLOT';
    case NodeType.LOCAL_COMPONENT:
    case NodeType.LIBRARY_COMPONENT:
    case NodeType.SOURCE_COMPONENT:
      return 'LAYERS_PANEL_ITEM_COMPONENT';
    case NodeType.INSTANCE:
      if (
        !isInstanceToSourceComponent &&
        isSupportedComponentTagName(tagName)
      ) {
        return ICONS_VARIANT_BY_TAG_NAME[tagName];
      }
      return 'LAYERS_PANEL_ITEM_COMPONENT_INSTANCE';
    case NodeType.VARIANTS_GROUP:
      return 'LAYERS_PANEL_ITEM_COMPONENT';
  }
};
