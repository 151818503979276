import { Icons, IconsProps } from '@jux/ui/components';
import { styled } from '@jux/ui/components/common/mui';
import {
  LAYERS_PANEL_OBJECT_NAVIGATOR_HEADER_ICON_HOVER_COLOR,
  LAYERS_PANEL_OBJECT_NAVIGATOR_HEADER_ICON_IDLE_COLOR,
} from '@jux/ui/theme/palette';

const DEFAULT_ICON_SIZE = 16;

export const ObjectNavigatorHeaderIcon = styled((props: IconsProps) => (
  <Icons size={DEFAULT_ICON_SIZE} {...props} />
))`
  color: ${LAYERS_PANEL_OBJECT_NAVIGATOR_HEADER_ICON_IDLE_COLOR};
  &:hover {
    color: ${LAYERS_PANEL_OBJECT_NAVIGATOR_HEADER_ICON_HOVER_COLOR};
  }
`;
