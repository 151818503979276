import { useCallback, useMemo, useRef } from 'react';
import {
  DesignTokenData,
  DesignTokenValue,
  SupportedTokenTypes,
} from '@jux/design-tokens';
import { TokensPopperProps } from '@jux/ui/components/editor/components/panels/DDP/hooks';
import { tokenFormByType } from '@jux/ui/components/tokens/token-drawer/forms/tokenFormByType';
import {
  OnTokenDrawerChange,
  useTokenDrawer,
} from '@jux/ui/components/tokens/token-drawer/useTokenDrawer';
import { useTokenDrawerSideEffects } from '@jux/ui/components/tokens/token-drawer/useTokenDrawerSideEffects';
import { useTokens, useTrackEvents } from '@jux/ui/hooks';
import { useTokenSetState } from '@jux/ui/state';

export function useCreateTokenFormWithTypeAndInitialValue(
  tokenType: SupportedTokenTypes
) {
  const { close: closeDrawer, open } = useTokenDrawer();
  const {
    groupPathOptionsByType,
    existingCoreTokenPaths,
    existingTokenPaths,
    valuesMap,
    tokenSetDesignTokens,
  } = useTokens();
  const { isCoreTokenSet } = useTokenSetState();
  const { onSave } = useTokenDrawerSideEffects();

  const CreateTokenForm = useMemo(
    () => tokenFormByType[tokenType],
    [tokenType]
  );

  const initialValuesRef = useRef();
  const { trackTokenCreatedEvent } = useTrackEvents();

  const openCreateTokenDrawer = useCallback(
    ({
      initialTokenValue,
      tokenSetId,
      onCreateNewTokenSaved,
      onTokenDrawerValueChange,
      onCancelButton,
      closeTokensPopper,
    }: {
      initialTokenValue?: DesignTokenValue;
      tokenSetId: string;
      onCreateNewTokenSaved?: (token: DesignTokenData) => void | Promise<void>;
      onTokenDrawerValueChange?: OnTokenDrawerChange;
      onCancelButton?: (
        initialValues: DesignTokenValue
      ) => void | Promise<void>;
      closeTokensPopper?: TokensPopperProps['closeTokens'];
    }) => {
      initialValuesRef.current = initialTokenValue as any;

      open({
        content: (
          <CreateTokenForm
            initialValues={{
              tokenSetId,
              // using any because the value is different for each type, so each form should handle it 🦊
              value: initialTokenValue as any,
              groupPath: tokenType,
            }}
            groupPathOptions={groupPathOptionsByType[tokenType]}
            existingTokenPaths={existingTokenPaths}
            existingCoreTokenPaths={existingCoreTokenPaths}
            valuesMap={valuesMap}
            tokens={tokenSetDesignTokens}
            isCoreTokenSet={isCoreTokenSet}
          />
        ),
        handlers: {
          onSave: (token) => {
            onSave(`New ${tokenType} token created successfully`);

            if (!token) return;

            onCreateNewTokenSaved?.(token);
            trackTokenCreatedEvent(tokenType);
          },
          onChange: onTokenDrawerValueChange,
          onCancel: () => {
            onCancelButton?.(initialValuesRef.current);
            closeDrawer();
            closeTokensPopper?.();
          },
          onClose: () => {
            closeDrawer();
          },
        },
      });
    },
    [
      CreateTokenForm,
      closeDrawer,
      existingTokenPaths,
      groupPathOptionsByType,
      existingCoreTokenPaths,
      isCoreTokenSet,
      onSave,
      open,
      tokenSetDesignTokens,
      tokenType,
      trackTokenCreatedEvent,
      valuesMap,
    ]
  );

  return {
    openCreateTokenDrawer,
  };
}
