import { BaseSyntheticEvent, KeyboardEvent, useCallback } from 'react';
import {
  useDDPFieldForm,
  useEffectOnSelectedNodeStateChange,
} from '@jux/ui/components/editor/components/panels/DDP/hooks';
import { useEffectOnSelectedNodeChange } from '@jux/ui/components/editor/hooks';
import { DesignTokenData } from '@jux/design-tokens';
import { handleDimensionInputArrows } from '../handleDimensionInputArrows.utils';
import { UseColorFieldInputsProps } from './useColorField.interface';
import {
  alphaFieldSchema,
  composeRGBA,
  composeRgbaWithNewAlpha,
  parseAlphaWithPercentSign,
  hexColorFieldSchema,
  parseAlpha,
  isValidColorTextValue,
} from './useColorField.utils';
import { ALPHA_FIELD_NAME } from './useColorField';

// TODO: separate each input to its own hook
export const useColorFieldInputs = ({
  parsedValue,
  fieldName,
  saveChanges,
  parseValue,
}: UseColorFieldInputsProps) => {
  const initialHexValue = isValidColorTextValue(parsedValue.hexValue)
    ? parsedValue.hexValue
    : parsedValue.hexValue.slice(1); // Remove the leading '#',

  const {
    handleSubmit: submitHexValue,
    registerField: hexValueInputRegister,
    setValue: setHexFieldValue,
  } = useDDPFieldForm({
    fieldName,
    initialValue: initialHexValue,
    fieldSchema: hexColorFieldSchema,
  });

  const {
    handleSubmit: submitAlphaValue,
    registerField: alphaValueInputRegister,
    setValue: setAlphaFieldValue,
  } = useDDPFieldForm({
    fieldName: `${fieldName}-${ALPHA_FIELD_NAME}`,
    initialValue: parseAlphaWithPercentSign(parsedValue.alpha),
    fieldSchema: alphaFieldSchema,
  });

  const updateFieldStateByValue = useCallback(
    (value: string) => {
      const { hexValue, alpha } = parseValue(value);

      setHexFieldValue(hexValue.slice(1));
      setAlphaFieldValue(parseAlphaWithPercentSign(alpha));
    },
    [parseValue, setHexFieldValue, setAlphaFieldValue]
  );

  const updateStateByTokenValue = useCallback(
    (newToken: DesignTokenData) => {
      updateFieldStateByValue(newToken.value?.toString() || '');
    },
    [updateFieldStateByValue]
  );

  const handleEffectChanges = useCallback(() => {
    setHexFieldValue(initialHexValue);
    setAlphaFieldValue(parseAlphaWithPercentSign(parsedValue.alpha));
  }, [
    setHexFieldValue,
    initialHexValue,
    setAlphaFieldValue,
    parsedValue.alpha,
  ]);

  useEffectOnSelectedNodeChange(handleEffectChanges);
  useEffectOnSelectedNodeStateChange(handleEffectChanges);

  // Hex field

  const saveHexOnValid = useCallback(
    (newHex: string | { [key: string]: string }) => {
      const hex =
        typeof newHex === 'string' ? newHex : Object.values(newHex)[0];

      const newRgba = composeRGBA(
        hex,
        parseAlphaWithPercentSign(parsedValue.alpha)
      );
      saveChanges(newRgba);
    },
    [saveChanges, parsedValue.alpha]
  );

  const discardHexChanges = useCallback(() => {
    setHexFieldValue(initialHexValue);
  }, [setHexFieldValue, initialHexValue]);

  const handleSubmitHex = useCallback(
    (e: BaseSyntheticEvent) => {
      if (!e) return;

      const hex = e.target.value;
      if (isValidColorTextValue(hex)) {
        saveChanges(hex);
        setAlphaFieldValue('100');
        return;
      }

      const submitHexCallback = submitHexValue(
        saveHexOnValid,
        discardHexChanges
      );
      submitHexCallback(e);
    },
    [
      submitHexValue,
      saveHexOnValid,
      discardHexChanges,
      saveChanges,
      setAlphaFieldValue,
    ]
  );

  // Alpha field

  const saveAlphaOnValid = useCallback(
    (newAlpha: string | { [key: string]: string }) => {
      const val =
        typeof newAlpha === 'string' ? newAlpha : Object.values(newAlpha)[0];

      let alphaFromField = parseAlpha(val);

      if (isNaN(alphaFromField)) return;
      if (alphaFromField < 0) alphaFromField = 0;
      if (alphaFromField > 100) alphaFromField = 100;

      const newRgba = composeRgbaWithNewAlpha(
        parsedValue.rgbaColor,
        alphaFromField
      );

      saveChanges(newRgba);
    },
    [saveChanges, parsedValue]
  );

  const discardAlphaChanges = useCallback(() => {
    setAlphaFieldValue(parseAlphaWithPercentSign(parsedValue.alpha));
  }, [setAlphaFieldValue, parsedValue]);

  const handleSubmitAlpha = useCallback(
    (e: BaseSyntheticEvent) => {
      if (!e) return;

      const val = parseAlphaWithPercentSign(e.target.value);

      e.target.value = val;

      const hasValueChanged =
        val !== parseAlphaWithPercentSign(parsedValue.alpha);

      if (!hasValueChanged) return;
      setAlphaFieldValue(val);

      const submitAlphaCallback = submitAlphaValue(
        saveAlphaOnValid,
        discardAlphaChanges
      );
      submitAlphaCallback(e);
    },
    [
      submitAlphaValue,
      discardAlphaChanges,
      saveAlphaOnValid,
      parsedValue,
      setAlphaFieldValue,
    ]
  );

  const handleArrowsAlphaField = useCallback(
    (addition: number) => {
      let newValue = parsedValue.alpha + addition;
      if (newValue < 0) {
        newValue = 0;
      }
      if (newValue > 100) {
        newValue = 100;
      }
      if (newValue === parsedValue.alpha) return;

      saveAlphaOnValid(parseAlphaWithPercentSign(newValue));
    },
    [saveAlphaOnValid, parsedValue]
  );

  const handleAlphaKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      handleDimensionInputArrows(e, handleArrowsAlphaField);
    },
    [handleArrowsAlphaField]
  );

  return {
    hexValueInputRegister,
    alphaValueInputRegister,

    hexInputProps: {
      onEnter: handleSubmitHex,
      onBlur: handleSubmitHex,
      onSubmit: handleSubmitHex,
      onEscape: discardHexChanges,
    },

    alphaInputProps: {
      onEnter: handleSubmitAlpha,
      onBlur: handleSubmitAlpha,
      onSubmit: handleSubmitAlpha,
      onKeyDown: handleAlphaKeyDown,
      onEscape: discardAlphaChanges,
    },

    updateStateByTokenValue,
  };
};
