import { withPx } from '@jux/ui/utils/css/withPx';

export const LAYOUT_NAVBAR_HEIGHT = 48;
const DRAWER_WIDTH = 292;
const DDP_WIDTH = DRAWER_WIDTH;
const DDP_MIN_WIDTH = 250;
const DDP_MAX_WIDTH = 400;
const LAYERS_PANEL_WIDTH = DRAWER_WIDTH;
const LAYERS_PANEL_MIN_WIDTH = 250;
const LAYERS_PANEL_MAX_WIDTH = 400;
const BUTTON_MEDIUM_HEIGHT = 28;
const BUTTON_BIG_HEIGHT = 36;
const NAVBAR_BUTTON_HEIGHT = BUTTON_MEDIUM_HEIGHT;
const DDP_FIELD_HEIGHT = 30;
const DDP_FIELD_ICON_WRAPPER_SIZE = DDP_FIELD_HEIGHT;
const DDP_GROUP_BUTTON_SIZE = DDP_FIELD_HEIGHT;
const DDP_PADDING_RIGHT = '8px';
const DDP_MODULE_HEADER_HEIGHT = 48;
const DDP_MODULE_HEADER_SMALL_HEIGHT = 40;
const DDP_MODULE_HEADER_PADDING_RIGHT = DDP_PADDING_RIGHT;
const DDP_SUB_MODULE_HEADER_HEIGHT = 32;
const DDP_SUB_MODULE_HEADER_PADDING_RIGHT = DDP_PADDING_RIGHT;
const DDP_RELATIVE_TO_HEIGHT = 32;

const BORDER_HEIGHT = 1;

export const baseThemeSize = {
  borderRadius: {
    main: '4px',
    small: '2px',
    tiny: '1px',
  },
  button: {
    medium: {
      height: BUTTON_MEDIUM_HEIGHT,
    },
    big: {
      height: BUTTON_BIG_HEIGHT,
    },
  },
  layout: {
    navbar: {
      height: LAYOUT_NAVBAR_HEIGHT,
    },
    sidebar: {
      width: LAYOUT_NAVBAR_HEIGHT,
    },
    tokens: {
      spacing: {
        tokenGroupPaddingTop: '16px',
        subGroup: '16px',
        group: '28px',
        mainPadding: '24px',
      },
      group: {
        header: {
          height: 28,
        },
      },
      set: {
        height: 48,
        minWidth: 150,
      },
      tabs: {
        border: {
          height: BORDER_HEIGHT,
        },
      },
    },
    tokenDrawer: {
      container: {
        maxWidth: DRAWER_WIDTH,
        minWidth: DRAWER_WIDTH,
        height: `calc(100vh - ${withPx(LAYOUT_NAVBAR_HEIGHT + BORDER_HEIGHT)})`,
        top: withPx(LAYOUT_NAVBAR_HEIGHT + BORDER_HEIGHT),
      },
      header: {
        height: LAYOUT_NAVBAR_HEIGHT,
      },
      field: {
        display: 'flex',
        mt: '20px',
        mx: '20px',
      },
    },
  },
  editor: {
    navbar: {
      height: LAYOUT_NAVBAR_HEIGHT,
      logo: {
        height: NAVBAR_BUTTON_HEIGHT,
        width: NAVBAR_BUTTON_HEIGHT,
      },
      menuTab: {
        content: {
          minWidth: 296,
          padding: '20px',
          itemsContainer: {
            // each popper have different max height due to the different content
            // some popper have tabs and some don't
            itemsList: {
              elements: {
                maxHeight: 491,
              },
              components: {
                maxHeight: 441,
              },
              assets: {
                maxHeight: 441,
              },
            },
          },
        },
      },
    },
    canvas: {
      panels: {
        magnifier: {
          control: {
            width: 24,
          },
        },
      },
    },
    navigator: {
      initialWidth: 260,
      minWidth: 160,
      maxWidth: 400,
      versions: {
        popoverWidth: 180,
        selectHeight: 50,
      },
    },
    ddp: {
      width: DDP_WIDTH,
      minWidth: DDP_MIN_WIDTH,
      maxWidth: DDP_MAX_WIDTH,
      subModule: {
        header: {
          height: DDP_SUB_MODULE_HEADER_HEIGHT,
          paddingRight: DDP_SUB_MODULE_HEADER_PADDING_RIGHT,
        },
      },
      module: {
        header: {
          height: DDP_MODULE_HEADER_HEIGHT,
          smallHeight: DDP_MODULE_HEADER_SMALL_HEIGHT,
          paddingRight: DDP_MODULE_HEADER_PADDING_RIGHT,
        },
      },
      field: {
        height: DDP_FIELD_HEIGHT,
        content: {
          height: 24,
        },
        icon: {
          wrapper: {
            width: DDP_FIELD_ICON_WRAPPER_SIZE,
            height: DDP_FIELD_ICON_WRAPPER_SIZE,
          },
        },
        input: {
          width: 39,
        },
        unit: {
          width: 32,
          height: 24,

          option: {
            width: 28,
            height: 20,
          },
        },
        token: {
          box: {
            search: {
              height: DDP_FIELD_HEIGHT,
            },
            option: {
              height: DDP_FIELD_HEIGHT,
            },
          },
          button: {
            width: DDP_FIELD_HEIGHT,
            height: DDP_FIELD_HEIGHT,

            iconWrapper: {
              width: 24,
              height: 24,
            },
          },
        },
      },
      relativeTo: {
        height: DDP_RELATIVE_TO_HEIGHT,
      },
      groupButton: {
        width: DDP_GROUP_BUTTON_SIZE,
        height: DDP_GROUP_BUTTON_SIZE,
      },
      textContent: {
        height: DDP_FIELD_HEIGHT,
      },
      selectField: {
        height: DDP_FIELD_HEIGHT,
      },
    },
    layers: {
      width: LAYERS_PANEL_WIDTH,
      minWidth: LAYERS_PANEL_MIN_WIDTH,
      maxWidth: LAYERS_PANEL_MAX_WIDTH,
      objectNavigator: {
        item: {
          height: 32,
        },
      },
    },
    properties: {
      margin: 10,
    },
  },
  common: {
    autocomplete: {
      options: {
        maxHeight: 200,
      },
    },
    toast: {
      maxWidth: 369,
    },
  },
} as const;
