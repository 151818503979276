import { storeApi } from '@jux/canjux/core';
import { XYPosition } from '@jux/data-entities';

export const getSnapAdjustedPositionIfEnabled = (position: XYPosition) => {
  const { snapToGrid, snapGrid } = storeApi.getState();

  if (snapToGrid) {
    return {
      x: snapGrid.x * Math.round(position.x / snapGrid.x),
      y: snapGrid.y * Math.round(position.y / snapGrid.y),
    };
  }

  return position;
};
