import { useCallback } from 'react';
import { selectHasTextPropAndTagName, useStore } from '@jux/canjux/core';
import { TEXT_CONTENT_PROP_NAME } from '@jux/types';

export const useSaveNodeTextContent = (propName = TEXT_CONTENT_PROP_NAME) => {
  const selectHasTextPropAndTagNameByNodeId = useStore(
    selectHasTextPropAndTagName
  );
  const { setComponentPropValueChoice } = useStore(
    (state) => state.propsActions
  );

  const saveTextContent = useCallback(
    ({ nodeId, newText }: { nodeId: string; newText: string }) => {
      const { hasTextProp, tagName } =
        selectHasTextPropAndTagNameByNodeId(nodeId);

      if (!hasTextProp) {
        throw new Error(
          `Text content is not supported for this element type - ${tagName}`
        );
      }

      setComponentPropValueChoice({
        componentId: nodeId,
        propName,
        propValue: newText,
      });
    },
    [propName, selectHasTextPropAndTagNameByNodeId, setComponentPropValueChoice]
  );

  return { saveTextContent };
};
