import { createSelector } from 'reselect';
import { JuxStore } from '@jux/canjux/core';
import { NodeType } from '@jux/data-entities';

export const selectOrgComponentsNames = createSelector(
  [(state: JuxStore) => state.components],
  (components): string[] =>
    Object.values(components)
      .filter(
        (component) =>
          component.type === NodeType.LOCAL_COMPONENT ||
          component.type === NodeType.LIBRARY_COMPONENT
      )
      .map((component) => component?.displayName || '')
);
