import { useEffect } from 'react';
import {
  selectedNodeComponentVariantsPropsValues,
  useStore,
} from '@jux/canjux/core';

export const useEffectOnSelectedNodeVariantValuesChange = (
  callback: () => void
) => {
  const selectedNodeVariantValues = useStore(
    selectedNodeComponentVariantsPropsValues
  );

  useEffect(() => {
    callback();
  }, [callback, selectedNodeVariantValues]);
};
