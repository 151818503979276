/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const FolderLockedIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
      fill: 'none',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M9.27639 2.55279C9.107 2.214 8.76074 2 8.38197 2H2C1.44772 2 1 2.44772 1 3V8.88353L2.20781 8.84496L2.66562 7.57538C3.28515 5.85735 5.71485 5.85736 6.33438 7.57538L6.79219 8.84496L8.1411 8.88804C9.96649 8.94635 10.7173 11.2571 9.2748 12.3772L8.20884 13.205L8.43938 14H14C14.5523 14 15 13.5523 15 13V5C15 4.44772 14.5523 4 14 4H10.618C10.2393 4 9.893 3.786 9.72361 3.44721L9.27639 2.55279Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M2.70794 12.6634C2.70787 12.5875 2.67319 12.5142 2.61125 12.4661L0.767828 11.0347C0.582893 10.8911 0.679152 10.5948 0.913175 10.5874L3.24591 10.5127C3.34831 10.5094 3.43835 10.444 3.47311 10.3476L4.26482 8.15206C4.34425 7.9318 4.65575 7.9318 4.73518 8.15206L5.52689 10.3476C5.56165 10.444 5.65169 10.5094 5.75409 10.5127L8.08682 10.5872C8.23587 10.5919 8.32903 10.7138 8.32927 10.8388C8.32946 10.9102 8.29939 10.9825 8.23217 11.0347L6.38874 12.4661C6.32679 12.5142 6.29211 12.5875 6.29206 12.6634C6.29206 12.6866 6.29529 12.71 6.30196 12.733L6.95196 14.9748C7.01716 15.1997 6.76515 15.3828 6.57143 15.2513L4.64041 13.9404C4.55565 13.8829 4.44435 13.8829 4.35958 13.9404L2.42857 15.2513C2.23484 15.3828 1.98284 15.1997 2.04804 14.9748L2.69804 12.733C2.70471 12.71 2.70794 12.6866 2.70794 12.6634Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
