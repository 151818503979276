import { FC, ReactNode } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { Typography } from '@jux/ui/components/';

type TextOrChildrenType =
  | { text: string; children?: never }
  | { text?: never; children: ReactNode };

export const DDPMessage: FC<TextOrChildrenType> = ({ text, children }) => (
  <MuiGrid
    container
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height="100%"
    maxWidth="80%"
  >
    <MuiGrid>
      <Typography
        variant="label-s-regular"
        pt={4}
        pb={1}
        px={2}
        textAlign="center"
        whiteSpace="pre-wrap"
      >
        <span>{children || text}</span>
      </Typography>
    </MuiGrid>
  </MuiGrid>
);
