import { useCallback } from 'react';
import { isNumber } from 'lodash';
import { SegmentEvent } from '@jux/events/segment/segment.events';
import { isAlias } from '@jux/design-tokens';
import { ComponentTagName } from '@jux/data-entities';
import { CommonKeysValueType } from '@jux/canjux/core';
import { useAnalytics } from './useAnalytics';

export const useTrackEvents = () => {
  const { trackEvent } = useAnalytics();

  const trackFieldUnitChangeEvent = useCallback(
    (fieldName: string, unitValue: string) => {
      trackEvent({
        eventName: SegmentEvent.DDP_FIELD_UNIT_SELECTED,
        properties: {
          fieldName,
          unit: unitValue,
        },
      });
    },
    [trackEvent]
  );

  const trackTokenDetachedEvent = useCallback(
    (fieldName: string) => {
      trackEvent({
        eventName: SegmentEvent.DDP_FIELD_TOKEN_DETACHED,
        properties: {
          fieldName,
        },
      });
    },
    [trackEvent]
  );

  const trackModuleToggleOpenEvent = useCallback(
    (moduleKey: string, isOpened: boolean) => {
      trackEvent({
        eventName: SegmentEvent.DDP_MODULE_TOGGLE_OPEN,
        properties: {
          moduleKey,
          isOpened,
        },
      });
    },
    [trackEvent]
  );

  const trackFieldValueChangeEvent = useCallback(
    (fieldName: string, value: any) => {
      const fieldValueType = isAlias(value)
        ? 'token'
        : isNumber(value)
        ? 'number'
        : 'string';

      trackEvent({
        eventName: SegmentEvent.DDP_VALUE_CHANGE,
        properties: {
          fieldName,
          fieldValueType,
        },
      });
    },
    [trackEvent]
  );

  const trackPropCreatedEvent = useCallback(
    ({
      componentUUID,
      tagName,
      propName,
    }: {
      componentUUID: string;
      tagName: ComponentTagName;
      propName: string;
    }) => {
      trackEvent({
        eventName: SegmentEvent.NEW_PROP_CREATED,
        properties: {
          componentUUID,
          tagName,
          createdPropName: propName,
        },
      });
    },
    [trackEvent]
  );
  const trackPropValueCreatedEvent = useCallback(
    ({
      componentUUID,
      tagName,
      propName,
      newPropValue,
    }: {
      componentUUID: string;
      tagName: ComponentTagName;
      propName: string;
      newPropValue: string;
    }) => {
      trackEvent({
        eventName: SegmentEvent.NEW_PROP_VALUE_CREATED,
        properties: {
          componentUUID,
          tagName,
          propName,
          newPropValueName: newPropValue,
        },
      });
    },
    [trackEvent]
  );
  const trackPropRenamedEvent = useCallback(
    ({
      componentUUID,
      tagName,
      oldPropName,
      newPropName,
    }: {
      componentUUID: string;
      tagName: ComponentTagName;
      oldPropName: string;
      newPropName: string;
    }) => {
      trackEvent({
        eventName: SegmentEvent.PROP_RENAMED,
        properties: {
          componentUUID,
          tagName,
          oldPropName,
          newPropName,
        },
      });
    },
    [trackEvent]
  );
  const trackPropValueRenamedEvent = useCallback(
    ({
      componentUUID,
      tagName,
      propName,
      oldPropValueName,
      newPropValueName,
    }: {
      componentUUID: string;
      tagName: ComponentTagName;
      propName: string;
      oldPropValueName: string;
      newPropValueName: string;
    }) => {
      trackEvent({
        eventName: SegmentEvent.PROP_VALUE_RENAMED,
        properties: {
          componentUUID,
          tagName,
          propName,
          oldPropValueName,
          newPropValueName,
        },
      });
    },
    [trackEvent]
  );
  const trackPropDeletedEvent = useCallback(
    ({
      componentUUID,
      tagName,
      deletedPropName,
    }: {
      componentUUID: string;
      tagName: ComponentTagName;
      deletedPropName: string;
    }) => {
      trackEvent({
        eventName: SegmentEvent.PROP_DELETED,
        properties: {
          componentUUID,
          tagName,
          deletedPropName,
        },
      });
    },
    [trackEvent]
  );
  const trackPropValueDeletedEvent = useCallback(
    ({
      componentUUID,
      tagName,
      propName,
      deletedPropValueName,
    }: {
      componentUUID: string;
      tagName: ComponentTagName;
      propName: string;
      deletedPropValueName: string;
    }) => {
      trackEvent({
        eventName: SegmentEvent.PROP_VALUE_DELETED,
        properties: {
          componentUUID,
          tagName,
          propName,
          deletedPropValueName,
        },
      });
    },
    [trackEvent]
  );

  const trackLayersRenameEvent = useCallback(
    (layerName: string, indentLevel: number) => {
      trackEvent({
        eventName: SegmentEvent.LAYERS_RENAME,
        properties: {
          text: layerName,
          level: indentLevel,
        },
      });
    },
    [trackEvent]
  );

  const trackObjectAddedToCanvasEvent = useCallback(
    ({
      tagName,
      componentUUID,
    }: {
      tagName: ComponentTagName;
      componentUUID?: string;
    }) => {
      trackEvent({
        eventName: SegmentEvent.OBJECT_ADDED_TO_CANVAS,
        properties: {
          tagName,
          componentUUID,
        },
      });
    },
    [trackEvent]
  );

  const trackTokenCreatedEvent = useCallback(
    (tokenType: string) => {
      trackEvent({
        eventName: SegmentEvent.TOKEN_CREATED,
        properties: {
          tokenType,
        },
      });
    },
    [trackEvent]
  );

  const trackTokenUpdatedEvent = useCallback(
    (tokenType: string) => {
      trackEvent({
        eventName: SegmentEvent.TOKEN_UPDATED,
        properties: {
          tokenType,
        },
      });
    },
    [trackEvent]
  );

  const trackTextNodeDoubleClickEvent = useCallback(
    ({ isEditMode, clickedOn }: { isEditMode: boolean; clickedOn: string }) => {
      trackEvent({
        eventName: SegmentEvent.TEXT_NODE_DOUBLE_CLICK,
        properties: {
          isEditMode,
          clickedOn,
        },
      });
    },
    [trackEvent]
  );

  const trackAddComponentToLibraryEvent = useCallback(
    ({ componentUUID }: { componentUUID: string }) => {
      trackEvent({
        eventName: SegmentEvent.ADD_COMPONENT_TO_LIBRARY,
        properties: {
          componentUUID,
        },
      });
    },
    [trackEvent]
  );

  const trackCreateAssetEvent = useCallback(
    ({ assetName }: { assetName: string }) => {
      trackEvent({
        eventName: SegmentEvent.CREATE_ASSET,
        properties: {
          assetName,
        },
      });
    },
    [trackEvent]
  );

  const trackOpenSidebar = useCallback(() => {
    trackEvent({
      eventName: SegmentEvent.OPEN_SIDEBAR,
    });
  }, [trackEvent]);

  const trackTokenSidebarClick = useCallback(() => {
    trackEvent({
      eventName: SegmentEvent.TOKEN_SIDEBAR_CLICK,
    });
  }, [trackEvent]);

  const trackEditorSidebarClick = useCallback(
    (canvasId: string) => {
      trackEvent({
        eventName: SegmentEvent.EDITOR_SIDEBAR_CLICK,
        properties: {
          canvasId,
        },
      });
    },
    [trackEvent]
  );

  const trackNavigationClick = useCallback(
    ({ name }: { name: 'components' | 'elements' | 'assets' }) => {
      const eventMap = {
        components: SegmentEvent.NAVIGATION_COMPONENTS_CLICKED,
        elements: SegmentEvent.NAVIGATION_ELEMENTS_CLICKED,
        assets: SegmentEvent.NAVIGATION_ASSETS_CLICKED,
      };
      trackEvent({
        eventName: eventMap[name],
      });
    },
    [trackEvent]
  );

  const trackAddToLibraryClick = useCallback(() => {
    trackEvent({
      eventName: SegmentEvent.ADD_TO_LIBRARY_CLICKED,
    });
  }, [trackEvent]);

  const trackModeClick = useCallback(
    ({ mode }: { mode: 'live' | 'edit' }) => {
      trackEvent({
        eventName:
          mode === 'live'
            ? SegmentEvent.LIVE_MODE_CLICKED
            : SegmentEvent.EDIT_MODE_CLICKED,
      });
    },
    [trackEvent]
  );

  const trackContextMenuClick = useCallback(
    ({
      name,
    }: {
      name: 'change_theme' | 'howto' | 'feedback' | 'shortcuts' | 'logout';
    }) => {
      const eventMap = {
        change_theme: SegmentEvent.CONTEXT_MENU_CHANGE_THEME_CLICKED,
        feedback: SegmentEvent.CONTEXT_MENU_FEEDBACK_CLICKED,
        howto: SegmentEvent.CONTEXT_MENU_HOW_TO_CLICKED,
        logout: SegmentEvent.CONTEXT_MENU_LOGOUT_CLICKED,
        shortcuts: SegmentEvent.CONTEXT_MENU_SHORTCUTS_CLICKED,
      };
      trackEvent({
        eventName: eventMap[name],
      });
    },
    [trackEvent]
  );

  const trackKeyboardShortcut = useCallback(
    ({
      shortcut,
      hotKey,
    }: {
      shortcut:
        | SegmentEvent.KBS_COPY
        | SegmentEvent.KBS_WRAP_WITH_DIV
        | SegmentEvent.KBS_DESELECT
        | SegmentEvent.KBS_DRILL_IN
        | SegmentEvent.KBS_DRILL_UP
        | SegmentEvent.KBS_UNDO
        | SegmentEvent.KBS_REDO
        | SegmentEvent.KBS_PASTE
        | SegmentEvent.KBS_DELETE;
      hotKey: CommonKeysValueType;
    }) => {
      trackEvent({
        eventName: SegmentEvent.KBS_SHORTCUT,
        properties: {
          shortcut,
          hotKey,
        },
      });
    },
    [trackEvent]
  );

  const trackRestoreSourceComponent = useCallback(() => {
    trackEvent({
      eventName: SegmentEvent.RESTORE_SOURCE_COMPONENT,
    });
  }, [trackEvent]);

  const trackGoToSourceComponent = useCallback(() => {
    trackEvent({
      eventName: SegmentEvent.GO_TO_SOURCE_COMPONENT,
    });
  }, [trackEvent]);

  const trackComponentResize = useCallback(() => {
    trackEvent({
      eventName: SegmentEvent.COMPONENT_RESIZED,
    });
  }, [trackEvent]);

  const trackUpdateComponentDIsplayName = useCallback(
    (position: 'canvas' | 'layers') => {
      trackEvent({
        eventName: SegmentEvent.UPDATE_COMPONENT_DISPLAY_NAME,
        properties: {
          position,
        },
      });
    },
    [trackEvent]
  );

  const trackFeedbackSent = useCallback(
    (feedback: string) => {
      trackEvent({
        eventName: SegmentEvent.FEEDBACK_SENT,
        properties: {
          feedback,
        },
      });
    },
    [trackEvent]
  );

  const trackFeedbackCancel = useCallback(() => {
    trackEvent({
      eventName: SegmentEvent.FEEDBACK_CANCEL,
    });
  }, [trackEvent]);

  const trackThemeChanged = useCallback(
    ({ themeId, themeName }: { themeId: string; themeName: string }) => {
      trackEvent({
        eventName: SegmentEvent.THEME_CHANGED,
        properties: {
          themeId,
          themeName,
        },
      });
    },
    [trackEvent]
  );

  return {
    trackAddComponentToLibraryEvent,
    trackAddToLibraryClick,
    trackComponentResize,
    trackContextMenuClick,
    trackCreateAssetEvent,
    trackEditorSidebarClick,
    trackFeedbackCancel,
    trackFeedbackSent,
    trackFieldUnitChangeEvent,
    trackFieldValueChangeEvent,
    trackGoToSourceComponent,
    trackKeyboardShortcut,
    trackLayersRenameEvent,
    trackModeClick,
    trackModuleToggleOpenEvent,
    trackNavigationClick,
    trackObjectAddedToCanvasEvent,
    trackOpenSidebar,
    trackPropCreatedEvent,
    trackPropDeletedEvent,
    trackPropRenamedEvent,
    trackPropValueCreatedEvent,
    trackPropValueDeletedEvent,
    trackPropValueRenamedEvent,
    trackRestoreSourceComponent,
    trackTextNodeDoubleClickEvent,
    trackThemeChanged,
    trackTokenCreatedEvent,
    trackTokenDetachedEvent,
    trackTokenSidebarClick,
    trackTokenUpdatedEvent,
    trackUpdateComponentDIsplayName,
  };
};
