import { ElementDataInit } from '../../types/ElementDataInit';
import { getJuxTextElementData } from '../text';
import { Colors } from '../../common/colors';
import { StylesObject } from '@jux/types';

const JUX_HELPER_TEXT_DEFAULT_CONTENT = 'Helper text';

const JUX_HELPER_TEXT_DEFAULT_STYLES: StylesObject = {
  fontFamily: 'Inter',
  fontWeight: '400',
  fontStyle: 'normal',
  fontSize: '12px',
  lineHeight: '14px',
  display: 'inline-block',
  color: Colors.Neutrals_400,
  width: 'auto',
  height: 'auto',
};
export const getHelperTextElement: ElementDataInit<undefined> = ({
  parentId,
  contextParentId,
} = {}) => {
  return getJuxTextElementData({
    parentId,
    contextParentId,
    rootStyles: JUX_HELPER_TEXT_DEFAULT_STYLES,
    text: JUX_HELPER_TEXT_DEFAULT_CONTENT,
    displayName: 'helper_text',
  });
};
