import {
  CommonActionsParams,
  CanjuxState,
  JuxStoreActionFn,
} from '@jux/canjux/core';

export const setUserSelection: JuxStoreActionFn<
  CommonActionsParams['setUserSelection'],
  CanjuxState
> = ({
  userSelectionActive,
  userSelectionRect,
  nodesSelectionActive,
  textNodeUserSelectionActive,
  state,
}) => {
  if (userSelectionActive !== undefined) {
    state.userSelectionActive = userSelectionActive;
  }

  if (userSelectionRect !== undefined) {
    state.userSelectionRect = userSelectionRect;
  }

  if (nodesSelectionActive !== undefined) {
    state.nodesSelectionActive = nodesSelectionActive;
  }

  if (textNodeUserSelectionActive !== undefined) {
    state.textNodeUserSelectionActive = textNodeUserSelectionActive;
  }

  return state;
};
