import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { useSettingsState } from '@jux/ui/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { WidthField } from './WidthField';
import { MaxWidthField } from './MaxWidthField';
import { MinWidthField } from './MinWidthField';

export const Width: FC = () => {
  const {
    settings: { ddpMinMaxSupport },
  } = useSettingsState();
  const { shouldRender } = useSubModule(DDPSubModulesKeys.width);

  if (!shouldRender) return null;
  return (
    <SubModule title="Width">
      <WidthField />
      {ddpMinMaxSupport && (
        <>
          <MaxWidthField />
          <MinWidthField />
        </>
      )}
    </SubModule>
  );
};
