// States order in the shape of { state: index }
export const CSS_INTERACTIVE_STATES_ORDER = ['hover', 'focus', 'active'].reduce(
  (acc, state, index) => {
    acc[state] = index;
    return acc;
  },
  {} as Record<string, number>
);

// Sort states according to their order
// If one of the states is not in the order list, preserve original order
export const sortStates = ([a]: [string, unknown], [b]: [string, unknown]) => {
  const aIndex = CSS_INTERACTIVE_STATES_ORDER[a];
  const bIndex = CSS_INTERACTIVE_STATES_ORDER[b];

  if (aIndex === undefined || bIndex === undefined) {
    return 0;
  }

  return aIndex - bIndex;
};
