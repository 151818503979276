import { FC, RefObject } from 'react';
import { PropertiesPanelHeader } from './PropertiesPanelHeader';
import { PropertiesPanelContent } from './PropertiesPanelContent';
import * as S from './PropertiesPanelWrapper.style';

export const PropertiesPanelWrapper: FC<{
  wrapperRef: RefObject<HTMLDivElement>;
  onClose: () => void;
}> = ({ wrapperRef, onClose }) => (
  <S.PropertiesPanelWrapper ref={wrapperRef}>
    <PropertiesPanelHeader onClose={onClose} />
    <PropertiesPanelContent />
  </S.PropertiesPanelWrapper>
);
