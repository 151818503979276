import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { JustifyContentField } from './JustifyContentField';

export const JustifyContent: FC = () => {
  const { shouldRender } = useSubModule(DDPSubModulesKeys.justifyContent);

  if (!shouldRender) return null;
  return (
    <SubModule title="Justify content">
      <JustifyContentField />
    </SubModule>
  );
};
