import { MuiGrid, styled, Theme } from '@jux/ui/components/common/mui';

export const optionWrapperStyles = (theme: Theme, isSelected?: boolean) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '8px',
  borderRadius: theme.drimz.size.borderRadius.tiny,
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: theme.drimz.palette.background.hover,
  },
  ...(isSelected && {
    backgroundColor: theme.drimz.palette.background.secondary,

    '&:hover': {
      backgroundColor: theme.drimz.palette.background.secondary,
    },
  }),
});

// TODO: currently used for dimension and select fields - maybe we should rename it to something more generic
export const AutoCompleteOptionWrapper = styled(MuiGrid, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
  ...optionWrapperStyles(theme, isSelected),
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  gap: '8px',
}));
