import { FC } from 'react';
import {
  ColorToken,
  DimensionToken,
  FontFamilyToken,
  TypographyToken,
} from './cardContentByType';
import {
  isTokenCardContentColor,
  isTokenCardContentDimension,
  isTokenCardContentFontFamily,
  isTokenCardContentTypography,
  TokenCardContentProps,
} from './TokenCardContent.interface';

export const TokenCardContent: FC<TokenCardContentProps> = ({
  value,
  type,
  aliasName,
  isAlias,
}) => {
  const aliasProps = {
    isAlias,
    aliasName,
  };

  if (isTokenCardContentColor(type, value)) {
    return <ColorToken value={value} aliasProps={aliasProps} />;
  }

  if (isTokenCardContentDimension(type, value)) {
    return <DimensionToken value={value} aliasProps={aliasProps} />;
  }

  if (isTokenCardContentFontFamily(type, value)) {
    return <FontFamilyToken value={value} aliasProps={aliasProps} />;
  }

  if (isTokenCardContentTypography(type, value)) {
    return <TypographyToken value={value} aliasProps={aliasProps} />;
  }

  return null;
};
