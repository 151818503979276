import { JuxStore } from '@jux/canjux/core';

export const getNodeChildrenRecursive = ({
  components,
  nodeId,
}: {
  components: JuxStore['components'];
  nodeId: string;
}) => {
  const children: Array<string> = [];

  // We need to collect all children and sub-children of the node and delete them as well
  const collectChildren = (id: string) => {
    children.push(id);

    const childNode = components[id];
    childNode.children.forEach((childId) => {
      collectChildren(childId);
    });
  };

  components[nodeId].children.forEach((childId) => collectChildren(childId));

  return children;
};
