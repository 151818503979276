import { DesignTokenTypeEnum, isAlias } from '@jux/design-tokens';
import { formatAliasDisplay, TypographyTokenValue } from '..';

const TYPOGRAPHY_FALLBACK_TEXT: Record<keyof TypographyTokenValue, string> = {
  fontFamily: 'default font-family',
  fontSize: 'default font-size',
  fontWeight: 'default font-weight',
  lineHeight: 'default line-height',
  letterSpacing: 'default letter-spacing',
} as const;

const ORDERED_PROPS = [
  'fontFamily',
  'fontWeight',
  'fontSize',
  'lineHeight',
  'letterSpacing',
] as const;

const formatPropValueDisplay = (value: string | undefined) => {
  if (isAlias(value)) {
    // return only the last part which is the token name
    return formatAliasDisplay(value);
  }
  return value;
};

export const formatTypographyTokenValueToString = (
  typographyTokenValue: TypographyTokenValue
) =>
  ORDERED_PROPS.map((propName) => {
    const propValue = typographyTokenValue[propName];
    return formatPropValueDisplay(
      // fontFamily is either an array of strings, or an alias string
      propName === DesignTokenTypeEnum.fontFamily && Array.isArray(propValue)
        ? propValue[0]
        : propValue ||
            TYPOGRAPHY_FALLBACK_TEXT[propName as keyof TypographyTokenValue]
    );
  }).join(', ');
