import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { PropertyField } from './PropertyField';
import {
  useParsedProperties,
  useHandleAddPropValue,
  useHandleDeleteProp,
  useHandleDeletePropValue,
  useHandleRenamePropName,
  useHandleRenamePropValue,
} from './hooks';

export const PropertiesFields: FC = () => {
  const { parsedProperties, propertiesNames, hasProperties } =
    useParsedProperties();

  const { handleDeleteProp } = useHandleDeleteProp();
  const { handleDeletePropValue } = useHandleDeletePropValue();
  const { handleAddPropValue } = useHandleAddPropValue();
  const { handleRenamePropName } = useHandleRenamePropName();
  const { handleRenamePropValue } = useHandleRenamePropValue();

  if (!hasProperties) return null;

  return (
    <MuiGrid container item gap="16px" padding="24px 20px 0 20px">
      {parsedProperties.map(
        ({ title: propName, options, isFixed, defaultValue }) => (
          <PropertyField
            key={propName}
            title={propName}
            valueOptions={options}
            disableEditing={isFixed}
            propertiesNames={propertiesNames}
            onEditProp={(newPropName) =>
              handleRenamePropName(propName, newPropName)
            }
            onEditPropValue={(newPropValueName) =>
              handleRenamePropValue(propName, newPropValueName)
            }
            onAddPropValue={() =>
              handleAddPropValue(
                propName,
                options.map((o) => o.label)
              )
            }
            onDeletePropValue={(optionLabelToDelete) =>
              handleDeletePropValue({
                optionLabelToDelete,
                propName,
                defaultValue,
                options,
              })
            }
            onDeleteProp={() => {
              handleDeleteProp({
                defaultValue,
                propName,
              });
            }}
          />
        )
      )}
    </MuiGrid>
  );
};
