import { useCallback } from 'react';
import {
  selectedNodeComponent,
  useStore,
  useStoreActions,
} from '@jux/canjux/core';
import { useTrackEvents } from '@jux/ui/hooks';
import { useSelectedNodeId } from '@jux/ui/components/editor/hooks';
import { NodeType } from '@jux/data-entities';

export const useHandleRenamePropName = () => {
  const {
    propsActions: { renameComponentProp },
  } = useStoreActions();

  const selectedNodeData = useStore(selectedNodeComponent);
  const selectedNodeId = useSelectedNodeId();

  const { trackPropRenamedEvent } = useTrackEvents();

  const renamePropName = useCallback(
    (propName: string, newPropName: string): boolean => {
      if (
        !selectedNodeId ||
        !selectedNodeData ||
        (selectedNodeData.type !== NodeType.LIBRARY_COMPONENT &&
          selectedNodeData.type !== NodeType.LOCAL_COMPONENT)
      )
        return false;

      try {
        renameComponentProp({
          componentId: selectedNodeId,
          propName,
          newPropName,
        });

        trackPropRenamedEvent({
          componentUUID: selectedNodeData.id,
          tagName: selectedNodeData.tagName,
          oldPropName: propName,
          newPropName,
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    [
      renameComponentProp,
      selectedNodeData,
      selectedNodeId,
      trackPropRenamedEvent,
    ]
  );

  return {
    handleRenamePropName: renamePropName,
  };
};
