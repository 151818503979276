import { CanjuxState } from '@jux/canjux/core';
import { getSelectedNodeId } from './getSelectedNodeId';
import { getNodeById } from './getNodeById';
import { getCurrentCanvasData } from './getCurrentCanvasData';

export const getSelectedNode = ({
  canvases,
  currentCanvasName,
  selectedNodesStack,
}: {
  canvases: CanjuxState['canvases'];
  currentCanvasName: CanjuxState['currentCanvasName'];
  selectedNodesStack: CanjuxState['selectedNodesStack'];
}) => {
  const selectedNodeId = getSelectedNodeId(selectedNodesStack);
  const currentCanvasData = getCurrentCanvasData({
    canvases,
    currentCanvasName,
  });

  if (!currentCanvasData) return undefined;

  const { nodes } = currentCanvasData;

  return selectedNodeId
    ? getNodeById({ nodes, id: selectedNodeId })
    : undefined;
};
