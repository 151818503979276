import { ComponentConfig, StyleVariant } from '@jux/types';
import deepmerge from 'deepmerge';

// Combine variants that have the same propsValues
export const dedupeVariants = <T extends ComponentConfig>({
  variants = [],
  ...styles
}: T): ComponentConfig => {
  if (!variants.length) return styles;

  return {
    ...styles,
    variants: Object.values(
      variants.reduce((acc, variant) => {
        const key = Object.entries(variant.propsValues)
          .map(([prop, value]) => `${prop}:${value}`)
          .sort()
          .join(',');

        if (!acc[key]) {
          acc[key] = variant;
        } else {
          acc[key] = deepmerge(acc[key], variant);
        }

        return acc;
      }, {} as Record<string, StyleVariant>)
    ),
  };
};
