import { toFirstUpperCase } from '../toFirstUpperCase';

export const convertAssetNameToComponentName = (fileName: string) => {
  const indexOfDot = fileName.lastIndexOf('.');
  const hasDot = indexOfDot > 0;

  const baseName = hasDot ? fileName.substring(0, indexOfDot) : fileName;

  const componentName = baseName
    .trim()
    // remove starting numbers
    .replace(/^(\d|_)+/, '')
    // remove all illegal characters
    .replace(/[^a-zA-Z0-9_]+/g, '');

  // Capitalize the first letter
  return toFirstUpperCase(componentName);
};
