import { useCallback } from 'react';
import { CANVAS_ITEMS } from '@jux/ui/components/editor/hooks';
import { ComponentTagName } from '@jux/data-entities';
import { ELEMENTS_INITIAL_SIZE } from '@jux/elements';
import { useAddCanvasItem, useGetTargetCenterPosition } from '../../../hooks';

export const useAddElementToCanvasOnClick = () => {
  const { addItemToCanvas } = useAddCanvasItem();
  const { getTargetCenterPositionByDimensions } = useGetTargetCenterPosition();

  const handleAddElementToCanvasOnClick = useCallback(
    (tagName: ComponentTagName) => {
      const { width: elementWidth, height: elementHeight } =
        tagName in ELEMENTS_INITIAL_SIZE
          ? ELEMENTS_INITIAL_SIZE[tagName]
          : { width: 100, height: 100 };
      const centerPosition = getTargetCenterPositionByDimensions({
        width: elementWidth,
        height: elementHeight,
      });

      addItemToCanvas({
        item: {
          tagName,
          type: CANVAS_ITEMS.Element,
        },
        dropPosition: centerPosition,
      });
    },
    [addItemToCanvas, getTargetCenterPositionByDimensions]
  );

  return { handleAddElementToCanvasOnClick };
};
