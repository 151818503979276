import { useMemo } from 'react';
import { ComponentConfigWithStates, StylesState } from '@jux/types';
import { calculateStyles } from '@jux/calculate-styles';

/**
 * Utility hook that injects state styles into the component's
 * root and variants, and overrides styles when a state is set.
 * @param styles
 * @param state
 */
export const useCalculatedStyles = (
  styles: ComponentConfigWithStates,
  state?: StylesState
) =>
  useMemo(
    () => calculateStyles(styles || { root: {}, states: {} }, state),
    [state, styles]
  );
