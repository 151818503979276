import { createSelector } from 'reselect';
import { CanjuxState } from '@jux/canjux/core';
import { getCurrentCanvasData, getNodeById } from './utils';

export const getNode = createSelector(
  [
    (state: CanjuxState) => state.canvases,
    (state: CanjuxState) => state.currentCanvasName,
  ],
  (canvases, currentCanvasName) => (id: string) => {
    const { nodes } = getCurrentCanvasData({
      canvases,
      currentCanvasName,
    });

    return getNodeById({ nodes, id });
  }
);
