import { MuiGrid, styled } from '@jux/ui/components/common/mui';

export const DDPAssetsPopperWrapper = styled(MuiGrid)(({ theme }) => ({
  width: '228px',
  maxHeight: '586px',
  backgroundColor: theme.drimz.palette.background.default,
  boxShadow: theme.drimz.shadow.editor.panels.ddp.assetsPopper.boxShadow,
  display: 'flex',
  flexDirection: 'column',
}));

export const DDPAssetsPopperItems = styled(MuiGrid)(() => ({
  padding: '16px 20px 16px',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  overflowX: 'hidden',
}));
