import { atom, useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';

export enum AssetsTabs {
  userAssets = 'userAssets',
  systemAssets = 'systemAssets',
}

const assetsTabsAtom = atom<AssetsTabs>(AssetsTabs.systemAssets);

export const useAssetsTabs = (initialTab?: AssetsTabs) => {
  const [activeTab, setActiveTab] = useAtom(assetsTabsAtom);

  const setActiveUserAssetsTab = useCallback(() => {
    setActiveTab(AssetsTabs.userAssets);
  }, [setActiveTab]);

  const setActiveSystemAssetsTab = useCallback(() => {
    setActiveTab(AssetsTabs.systemAssets);
  }, [setActiveTab]);

  useEffect(() => {
    if (initialTab) {
      setActiveTab(initialTab);
    }
  }, [initialTab, setActiveTab]);

  return {
    activeTab,
    isUserAssetsTab: activeTab === AssetsTabs.userAssets,
    isSystemAssetsTab: activeTab === AssetsTabs.systemAssets,
    setActiveUserAssetsTab,
    setActiveSystemAssetsTab,
  };
};
