import { KeyboardEvent } from 'react';
import { formatTokenNameKeyPress } from './formatTokenNameKeyPress';

export const handleTokenNameInputKeyDown = (
  e: KeyboardEvent<HTMLInputElement>
) => {
  const { currentTarget } = e;

  const inputElement = currentTarget.getElementsByTagName('input')[0];

  formatTokenNameKeyPress(e, inputElement);
};
