import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { BorderStyleField } from './BorderStyleField';

export const BorderStyle: FC = () => {
  const { shouldRender } = useSubModule(DDPSubModulesKeys.borderStyle);

  if (!shouldRender) return null;
  return (
    <SubModule title="Style" hideHeader>
      <BorderStyleField />
    </SubModule>
  );
};
