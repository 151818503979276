import { z } from 'zod';
import { fontFamilyTokenInputSchema } from '@jux/ui/trpc/validations';
import { FontFamilyFallbackMap } from '@jux/ui/components/tokens/token-drawer/forms/helpers/useFontFamilyOptions';

// Format the font family token value for display or for saving in the token set, including fallbacks, removing whitespaces, etc.
// Example: { value: "Inter", fallbacks: ["Arial", "sans-serif"] } -> "Inter, Arial, sans-serif"
export const formatFontFamilyTokenValue = ({
  value,
  fallbacks,
}: {
  value: z.infer<typeof fontFamilyTokenInputSchema>['value'] | undefined;
  fallbacks: FontFamilyFallbackMap[string];
}): string =>
  [value, ...fallbacks] // if includes spaces, add "" so that the browser will understand it
    .map((s) => (s && s.trim().includes(' ') ? `"${s}"` : s || '').trim())
    .filter(Boolean)
    .join(', ');
