import { DragEventHandler, FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { DropZoneGridWrapper } from './DropZoneIndication.style';

const DRAG_ABOVE_SIZE_WITH_DRAG_INSIDE = 2;
const DRAG_BELOW_SIZE_WITH_DRAG_INSIDE = 2;

const DRAG_INSIDE_SIZE = 1;
const DRAG_INSIDE_SIZE_WITH_EITHER_ABOVE_OR_BELOW = 8;
const DRAG_INSIDE_SIZE_WITH_BOTH_ABOVE_AND_BELOW = 6;

const DRAG_ABOVE_SIZE = 1;
const DRAG_BELOW_SIZE = 1;

export const DropZoneIndication: FC<{
  allowDropAbove: boolean;
  allowDropInside: boolean;
  allowDropBelow: boolean;
  onDragAbove: DragEventHandler<HTMLElement>;
  onDragBelow: DragEventHandler<HTMLElement>;
  onDragInside: DragEventHandler<HTMLElement>;
  onAbortDragInside: DragEventHandler<HTMLElement>;
}> = ({
  allowDropAbove,
  allowDropInside,
  allowDropBelow,
  onDragAbove,
  onDragBelow,
  onDragInside,
  onAbortDragInside,
}) => (
  <DropZoneGridWrapper>
    {allowDropInside ? (
      <>
        {allowDropAbove && (
          <MuiGrid
            item
            flex={DRAG_ABOVE_SIZE_WITH_DRAG_INSIDE}
            onDragOver={onDragAbove}
          />
        )}
        <MuiGrid
          item
          flex={
            allowDropBelow && allowDropAbove
              ? DRAG_INSIDE_SIZE_WITH_BOTH_ABOVE_AND_BELOW
              : allowDropBelow || allowDropAbove
              ? DRAG_INSIDE_SIZE_WITH_EITHER_ABOVE_OR_BELOW
              : DRAG_INSIDE_SIZE
          }
          onDragOver={onDragInside}
          onDragLeave={onAbortDragInside}
        />
        {allowDropBelow && (
          <MuiGrid
            item
            flex={DRAG_BELOW_SIZE_WITH_DRAG_INSIDE}
            onDragOver={onDragBelow}
          />
        )}
      </>
    ) : (
      <>
        {allowDropAbove && (
          <MuiGrid item flex={DRAG_ABOVE_SIZE} onDragOver={onDragAbove} />
        )}
        {allowDropBelow && (
          <MuiGrid item flex={DRAG_BELOW_SIZE} onDragOver={onDragBelow} />
        )}
      </>
    )}
  </DropZoneGridWrapper>
);
