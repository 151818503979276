/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';

const { styled } = createDrimz();

type IconButton_Icon_root_Props = PropsOf<typeof JuxReactElements.JuxSvg>;

const IconButton_Icon_root: FC<IconButton_Icon_root_Props> = forwardRef(
  (props, ref) => <JuxReactElements.JuxSvg {...props} ref={ref} />
);

IconButton_Icon_root.displayName = 'IconButton_Icon_root';

IconButton_Icon_root.defaultProps = {
  rootElement: styled('svg', {
    styles: { root: { color: 'rgba(26,26,26,1)' } },
  }) as IconButton_Icon_root_Props['rootElement'],
};

export type IconButton_Icon_Props = IconButton_Icon_root_Props;

export const IconButton_Icon: FC<IconButton_Icon_Props> = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxSvg>,
  IconButton_Icon_Props
>((props, ref) => <IconButton_Icon_root {...props} ref={ref} />);

IconButton_Icon.displayName = 'IconButton_Icon';
IconButton_Icon.defaultProps = {
  content:
    '<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M13 4.25C13 3.97386 12.7761 3.75 12.5 3.75H3.5C3.22386 3.75 3 3.97386 3 4.25V5.25C3 5.52614 3.22386 5.75 3.5 5.75L7 5.75V13.25C7 13.5261 7.22386 13.75 7.5 13.75H8.5C8.77614 13.75 9 13.5261 9 13.25V5.75L12.5 5.75C12.7761 5.75 13 5.52614 13 5.25V4.25Z" fill="currentColor"></path>\n</svg>\n',
};

type IconButton_root_Props = PropsOf<typeof JuxReactElements.JuxButton> & {
  disabled: true | false;
  hierarchy: 'primary' | 'secondary';
};

const IconButton_root: FC<IconButton_root_Props> = forwardRef((props, ref) => (
  <JuxReactElements.JuxButton {...props} ref={ref} />
));

IconButton_root.displayName = 'IconButton_root';

IconButton_root.defaultProps = {
  rootElement: styled('button', {
    styles: {
      root: {
        gap: '8px',
        color: '#FFFFFF',
        width: 'auto',
        cursor: 'pointer',
        height: '36px',
        display: 'flex',
        padding: '8px 16px',
        fontSize: '14px',
        fontStyle: 'normal',
        alignItems: 'center',
        fontFamily: 'Inter',
        fontWeight: '500',
        lineHeight: '20px',
        borderColor: '',
        borderStyle: 'none',
        borderRadius: '4px',
        flexDirection: 'row',
        justifyContent: 'center',
        '&:focus': { outline: 'none' },
        '&:active': { outline: 'none' },
        '&:focus-visible': {
          borderRadius: '8px',
          outlineColor: '#3851DD',
          outlineStyle: 'solid',
          outlineWidth: '2px',
          outlineOffset: '2px',
        },
      },
      variants: [
        {
          propsValues: { disabled: false, hierarchy: 'primary' },
          styles: {
            width: '28px',
            height: '28px',
            padding: '0px 0px 0px 0px',
            borderColor: '{color.core.grays_0}',
            borderStyle: 'Solid',
            borderWidth: '1px',
            borderRadius: '2px',
            backgroundColor: '{color.core.grays_0}',
            '[data-jux-context="ec4e22a0-16eb-4573-806b-6202811f151a"]': {
              color: '{color.core.grays_800}',
            },
            '&:hover': {
              borderColor: '{color.core.grays_200}',
              backgroundColor: '{color.core.grays_0}',
            },
            '&:active': {
              borderColor: '{color.core.grays_300}',
              backgroundColor: '{color.core.grays_0}',
            },
          },
        },
        {
          propsValues: { disabled: false, hierarchy: 'secondary' },
          styles: {
            width: '28px',
            height: '28px',
            padding: '0px 0px 0px 0px',
            borderRadius: '2px',
            backgroundColor: '{color.core.grays_0}',
            '[data-jux-context="25344194-a41e-4916-bccd-4886a49979e9"]': {
              height: '16px',
            },
            '&:hover': {
              width: '28px',
              height: '28px',
              padding: '0px 0px 0px 0px',
              backgroundColor: '{color.core.grays_200}',
            },
            '&:active': {
              width: '28px',
              height: '28px',
              padding: '0px 0px 0px 0px',
              borderColor: '{color.core.grays_300}',
              borderStyle: 'Solid',
              borderWidth: '1px',
              backgroundColor: '{color.core.grays_200}',
              '[data-jux-context="25344194-a41e-4916-bccd-4886a49979e9"]': {
                color: '{color.core.grays_800}',
              },
            },
          },
        },
        {
          propsValues: { disabled: true, hierarchy: 'primary' },
          styles: {
            width: '28px',
            cursor: 'default',
            height: '28px',
            padding: '0px 0px 0px 0px',
            backgroundColor: '{color.core.grays_0}',
            '[data-jux-context="25344194-a41e-4916-bccd-4886a49979e9"]': {
              color: '{color.core.grays_300}',
            },
          },
        },
        {
          propsValues: { disabled: true, hierarchy: 'secondary' },
          styles: {
            width: '28px',
            cursor: 'default',
            height: '28px',
            padding: '0px 0px 0px 0px',
            backgroundColor: '{color.core.grays_0}',
            '[data-jux-context="25344194-a41e-4916-bccd-4886a49979e9"]': {
              color: '{color.core.grays_300}',
            },
          },
        },
      ],
    },
  }) as IconButton_root_Props['rootElement'],
};

export type IconButton_Props = IconButton_root_Props & {
  IconButton_Icon_Props?: IconButton_Icon_Props;
};

export const IconButton: FC<IconButton_Props> = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxButton>,
  IconButton_Props
>(({ IconButton_Icon_Props, ...props }, ref) => (
  <IconButton_root
    {...props}
    ref={ref}
    data-jux-context="c169c249-12de-49e8-b5dd-689982dba7c1"
  >
    {props.children || (
      <>
        <IconButton_Icon
          {...IconButton_Icon_Props}
          data-jux-context="f8629381-a8e5-4766-94c7-ef8b1396587c"
        />
      </>
    )}
  </IconButton_root>
));

IconButton.displayName = 'IconButton';
IconButton.defaultProps = { disabled: false, hierarchy: 'primary' };
