import { MuiGrid, styled } from '@jux/ui/components/common/mui';

export const ModulesMappingWrapper = styled(MuiGrid)`
  padding-bottom: 272px;
  height: 100%;
  width: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;

  div:first-of-type .module-header-opened {
    padding-top: 10px;
    align-items: end;
  }

  .module-header-opened {
    position: relative;
    top: 4px;
    height: 40px;
  }

  /* class names are generated by moduleKey in Module.tsx */
  /* stronger specificity to avoid !important */
  .instance > .module-header-opened.module-header-opened {
    align-items: center;
  }

  .props > .module-header-opened.module-header-opened {
    height: 48px;
    position: static;
    align-items: center;
    padding-top: unset;
  }
`;
