import { createSelector } from 'reselect';
import { CanjuxState, getRootNodeOfNode } from '@jux/canjux/core';
import { getResolvedSourceComponentData } from './utils/getResolvedSourceComponentData';
import { NodeType } from '@jux/data-entities';
import equal from 'fast-deep-equal';

export const selectIsNodeInLibrary = (id: string) =>
  createSelector(
    [
      (state: CanjuxState) =>
        getResolvedSourceComponentData({
          component: state.components[id],
          components: state.components,
        }),
      (state: CanjuxState) => state.components,
    ],
    (sourceComponent, components): boolean => {
      if (!sourceComponent) return false;

      const rootNode = getRootNodeOfNode({
        components,
        nodeId: sourceComponent.id,
      });

      return rootNode.type === NodeType.LIBRARY_COMPONENT;
    },
    {
      memoizeOptions: {
        equalityCheck: equal,
        resultEqualityCheck: equal,
      },
    }
  );
