import { useMemo } from 'react';
import {
  nodeComponentByNodeId,
  selectedNodeComponent,
  storeApi,
  useStore,
} from '@jux/canjux/core';
import { findFirstRelativeToParent } from './findFirstRelativeToParent';

type RelativeToComponent = {
  componentName: string;
  componentId: string;
  isRelativeToRoot: boolean;
};

const FALLBACK_RELATIVE_TO_COMPONENT: RelativeToComponent = {
  componentName: '',
  componentId: '',
  isRelativeToRoot: false,
};

export const useRelativeToComponent = () => {
  const selectedComponent = useStore(selectedNodeComponent);

  const relativeToComponent = useMemo((): RelativeToComponent => {
    if (!selectedComponent) return FALLBACK_RELATIVE_TO_COMPONENT;

    // find first element until reaching root that has a parent with 'position: relative / absolute'
    // if no such element is found, return root

    // get parent id
    const { parentId } = selectedComponent;

    // if no parent id = is root
    if (!parentId) {
      return {
        componentId: '',
        componentName: 'parent',
        isRelativeToRoot: true,
      };
    }

    const relativeToParentId = findFirstRelativeToParent(parentId);

    if (!relativeToParentId) return FALLBACK_RELATIVE_TO_COMPONENT;

    const relativeTo = nodeComponentByNodeId(relativeToParentId)(
      storeApi.getState()
    );

    if (!relativeTo) return FALLBACK_RELATIVE_TO_COMPONENT;

    return {
      componentId: relativeTo.id,
      componentName: relativeTo.displayName ?? '',
      isRelativeToRoot: false,
    };
  }, [selectedComponent]);

  return {
    relativeToComponent,
  };
};
