import { useState } from 'react';
import { AutocompleteOptionData } from '../../base/fields/autocomplete';

export const useSelectFieldValue = ({
  rawValue,
  initSelectionOptions,
}: {
  rawValue: AutocompleteOptionData;
  initSelectionOptions: Array<AutocompleteOptionData>;
}) => {
  const [options, setOptions] =
    useState<Array<AutocompleteOptionData>>(initSelectionOptions);
  const [selectedOption, setSelectedOption] =
    useState<AutocompleteOptionData>(rawValue);

  return {
    options,
    setOptions,

    selectedOption,
    setSelectedOption,
  };
};
