import colorString from 'color-string';
import {
  HTMLElement,
  Node,
  NodeType as HTMLNodeType,
  parse,
} from 'node-html-parser';
import { AssetData } from '@jux/data-entities';

const COLOR_ATTRIBUTES = ['color', 'stroke', 'fill', 'bgcolor', 'borderColor'];
export const MIXED_COLOR_INDICATOR = null;
export const NO_COLOR_INDICATOR = undefined;

export const getRGBColor = (color?: string) => {
  if (!color) return;

  const parsedColor = colorString.get.rgb(color);

  if (!parsedColor) return;

  return `rgba(${parsedColor.join(',')})`;
};

export const parseHTMLElement = (element: string) => parse(element);

const getHTMLElementColors = (element: HTMLElement) =>
  COLOR_ATTRIBUTES.map((attr) => getRGBColor(element.getAttribute(attr)));

export const getAssetElementColor = (node: Node): string | undefined | null => {
  if (node.nodeType === HTMLNodeType.ELEMENT_NODE) {
    const element = node as HTMLElement;
    const colors = [
      ...new Set([
        ...Array.from(element.childNodes).map(getAssetElementColor),
        ...getHTMLElementColors(element),
      ]),
    ].filter((color) => color !== NO_COLOR_INDICATOR);

    // no defined color
    if (colors.length === 0) return NO_COLOR_INDICATOR;

    // mixed color (null) / particular color
    if (colors.length === 1) return colors[0];

    // mixed color (more than one color)
    return MIXED_COLOR_INDICATOR;
  }

  return undefined;
};

const replaceAssetElementFillColor = (node: Node) => {
  if (node.nodeType === HTMLNodeType.ELEMENT_NODE) {
    const element = node as HTMLElement;
    for (const attr of COLOR_ATTRIBUTES) {
      if (element.hasAttribute(attr) && element.getAttribute(attr) !== 'none') {
        element.setAttribute(attr, 'currentColor');
      }
    }
  }

  node.childNodes.forEach(replaceAssetElementFillColor);
};

export const removeClassAttribute = (node: Node) => {
  if (node.nodeType === HTMLNodeType.ELEMENT_NODE) {
    const element = node as HTMLElement;
    element.removeAttribute('class');
  }

  node.childNodes.forEach(removeClassAttribute);
};

const searchRootAssetSVGElement = (element: Node): HTMLElement | undefined => {
  if (element.nodeType === HTMLNodeType.ELEMENT_NODE) {
    if ((element as HTMLElement).rawTagName === 'svg') {
      return element as HTMLElement;
    }
  }

  return element.childNodes
    .map((n) => searchRootAssetSVGElement(n))
    .find((n) => n);
};

const replaceAssetWidthAndHeight = (element: HTMLElement) => {
  if (element.hasAttribute('viewBox')) {
    const viewBox = element.getAttribute('viewBox')?.match(/\d+/g);

    if (!element.hasAttribute('width') && viewBox?.[2]) {
      element.setAttribute('width', viewBox[2]);
    }

    if (!element.hasAttribute('height') && viewBox?.[3]) {
      element.setAttribute('height', viewBox[3]);
    }
  }
};

export const serializeAssetElement = (
  element: HTMLElement,
  {
    hasSingleFillColor = false,
  }: {
    hasSingleFillColor?: boolean;
  } = {}
) => {
  if (hasSingleFillColor) {
    replaceAssetElementFillColor(element);
  }

  removeClassAttribute(element);

  const rootElement = searchRootAssetSVGElement(element);

  // It is necessary to determine a certain width and height if not explicitly defined by the user
  if (rootElement) replaceAssetWidthAndHeight(rootElement);

  return element.toString();
};

export const initAssetData = ({
  assetName,
  assetContent,
}: {
  assetName: string;
  assetContent: string;
}) => {
  // parse the asset content
  const parsedSvgAsset = parseHTMLElement(assetContent);

  // get the fill color of the asset
  const assetColor = getAssetElementColor(parsedSvgAsset);

  // convert element as a string
  const serializedContent = serializeAssetElement(parsedSvgAsset, {
    hasSingleFillColor: Boolean(assetColor),
  });

  const rootStyles = assetColor ? { color: assetColor } : {};

  const date = new Date().getTime();

  const assetData: AssetData = {
    name: assetName,
    content: serializedContent,
    hasMixedColors: assetColor === MIXED_COLOR_INDICATOR,
    initialStyles: rootStyles,
    createdAt: date,
  };

  return { assetData };
};
