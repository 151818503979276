import { BaseSyntheticEvent, KeyboardEvent, useCallback } from 'react';
import { DesignTokenData } from '@jux/design-tokens';
import { useTrackEvents } from '@jux/ui/hooks';
import { useEffectOnSelectedNodeChange } from '@jux/ui/components/editor/hooks';
import { compareStrValues } from '@jux/ui/utils/compareStrValues';
import { useEffectOnSelectedNodeStateChange } from '../useEffectOnSelectedNodeStateChange';
import { useDDPFieldForm } from '../useDDPFieldForm';
import { UnitType } from '../useUnitState';
import { handleDimensionInputArrows } from '../handleDimensionInputArrows.utils';
import { UseDimensionFieldInputsProps } from './useDimensionField.interface';
import {
  adjustValueByAmount,
  combineValueWithUnit,
  isMixedMultiDirectionValue,
  parseFromField,
  roundFloatValue,
} from './useDimensionField.utils';

export const useDimensionFieldInputs = ({
  parsedValue,
  initialValue,
  fieldName,
  saveChanges: setFieldValue,
  fieldSchema,
  parseValue,
  setUnit,
  unit,
  parseUnit,
  validateField,
  value,
  options,
}: UseDimensionFieldInputsProps) => {
  const { handleSubmit, registerField, setValue, getValue } = useDDPFieldForm({
    fieldName,
    initialValue: parsedValue,
    fieldSchema,
  });

  const { trackFieldUnitChangeEvent } = useTrackEvents();

  const updateStateByValue = useCallback(
    (newValue: string) => {
      const { value: parsedVal, unit: parsedUnit } = parseValue(newValue);

      setValue(parsedVal);
      setUnit(parsedUnit);
    },
    [parseValue, setUnit, setValue]
  );

  const updateStateByTokenValue = useCallback(
    (newToken: DesignTokenData) => {
      updateStateByValue(newToken.value?.toString() || '');
    },
    [updateStateByValue]
  );

  const handleEffectChanges = useCallback(() => {
    setValue(parsedValue);
  }, [parsedValue, setValue]);

  useEffectOnSelectedNodeChange(handleEffectChanges);
  useEffectOnSelectedNodeStateChange(handleEffectChanges);

  const saveChanges = useCallback(
    (newValue: any, newUnit: UnitType) => {
      // TODO: only support two decimal numbers after point
      setFieldValue(combineValueWithUnit(roundFloatValue(newValue), newUnit));
    },
    [setFieldValue]
  );

  const hasValueChanged = useCallback(
    (newValue: string, newUnit: UnitType) =>
      !compareStrValues(
        combineValueWithUnit(roundFloatValue(newValue), newUnit),
        value
      ),
    [value]
  );

  const discardChanges = useCallback(() => {
    updateStateByValue(value);
  }, [updateStateByValue, value]);

  const saveOnValid = useCallback(
    (newValue: string | { [key: string]: string }) => {
      const val =
        typeof newValue === 'string' ? newValue : Object.values(newValue)[0];

      const finalUnit = parseUnit(val, unit);

      // if value didn't change, don't save
      if (!hasValueChanged(val, finalUnit)) return;

      if (unit !== finalUnit) {
        setUnit(finalUnit);
      }
      saveChanges(val, finalUnit);
    },
    [hasValueChanged, parseUnit, saveChanges, setUnit, unit]
  );

  const handleChanges = useCallback(
    (e: BaseSyntheticEvent) => {
      if (!e) return;

      const val = roundFloatValue(parseFromField(e.target.value)).toString();

      const shouldValidate =
        hasValueChanged(val, parseUnit(val, unit)) &&
        !isMixedMultiDirectionValue(val);

      e.target.value = val;

      const submitCallback = shouldValidate
        ? handleSubmit(saveOnValid, discardChanges)
        : () => null;

      submitCallback(e);
    },
    [
      hasValueChanged,
      parseUnit,
      unit,
      handleSubmit,
      saveOnValid,
      discardChanges,
    ]
  );

  const handleUnitChange = useCallback(
    (newUnit: UnitType) => {
      const newValue = getValue();
      const finalUnit = parseUnit(newValue, newUnit);

      setUnit(finalUnit);
      saveChanges(newValue, finalUnit);
      trackFieldUnitChangeEvent(fieldName, finalUnit as string);
    },
    [
      fieldName,
      getValue,
      parseUnit,
      saveChanges,
      setUnit,
      trackFieldUnitChangeEvent,
    ]
  );

  const handleRevertToInitial = useCallback(() => {
    const { value: parsedInitialValue } = parseValue(initialValue);
    const { success } = validateField(parsedInitialValue);

    if (success) {
      setValue(parsedInitialValue);
      saveOnValid(parsedInitialValue);
    }
  }, [initialValue, parseValue, saveOnValid, setValue, validateField]);

  const handleArrows = useCallback(
    (addition: number) => {
      const oldValue = getValue();
      const { success, value: newValue } = adjustValueByAmount(
        oldValue,
        addition,
        options
      );

      if (!success) return;

      setValue(newValue);
      saveOnValid(newValue ?? oldValue);
    },
    [getValue, options, saveOnValid, setValue]
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      handleDimensionInputArrows(e, handleArrows);
    },
    [handleArrows]
  );

  return {
    fieldValue: getValue(),
    setValue,
    registerField,
    handleSubmit: handleChanges,
    handleBlur: handleChanges,
    handleEnter: handleChanges,
    handleEscape: discardChanges,
    handleUnitChange,
    handleRevertToInitial,
    handleKeyDown,
    updateStateByTokenValue: updateStateByTokenValue,
  };
};
