import { createSelector } from 'reselect';
import { CanjuxState } from '../../store';
import { getSourceComponentById } from './utils';

export const selectSourceComponent = createSelector(
  [(state: CanjuxState) => state.components],
  (components) => (id: string) =>
    getSourceComponentById({
      id,
      components,
    })
);
