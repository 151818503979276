import { useCallback, useMemo } from 'react';
import { Transform } from '@jux/canjux/core';
import { useCanvasOptionsLocalStorage } from './useCanvasOptionsLocalStorage';
import { getCurrentCanvas } from './getCurrentCanvas';

export const useUserDefaultCanvasOptions = () => {
  const { canvasesViewPorts, setCanvasOptions } =
    useCanvasOptionsLocalStorage();
  const currentCanvas = getCurrentCanvas();

  const fitView = useMemo(
    () => !canvasesViewPorts?.[currentCanvas?.id],
    [canvasesViewPorts, currentCanvas?.id]
  );

  const setDefaultViewport = useCallback(
    ({
      transform: { x, y, zoom },
      canvasId,
    }: {
      transform: Transform;
      canvasId: string;
    }) => {
      setCanvasOptions({
        id: canvasId,
        viewport: {
          x,
          y,
          zoom,
          timestamp: Date.now(),
        },
      });
    },
    [setCanvasOptions]
  );

  return {
    fitView,
    defaultViewport: canvasesViewPorts?.[currentCanvas?.id],
    setDefaultViewport,
  };
};
