import { styled } from '@jux/ui/components/common/mui';
import { OptionTypography } from './OptionTypography';
import { HIGHLIGHTED_OPTION_STYLE } from './UnitSelection.style';

export const Option = styled(OptionTypography, {
  shouldForwardProp: (prop) => prop !== 'isHighlighted',
})<{
  isHighlighted?: boolean;
}>(({ isHighlighted }) => ({
  '&:hover': HIGHLIGHTED_OPTION_STYLE,
  ...(isHighlighted && HIGHLIGHTED_OPTION_STYLE),
}));
