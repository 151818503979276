import { FC } from 'react';
import { DimensionField } from '../../../../base';
import { usePaddingFields } from './usePaddingFields';

export const PaddingBottomField: FC = () => {
  const { paddingBottomField, isComputed } = usePaddingFields();

  return (
    <DimensionField
      icon="DDP_FIELD_PADDING_BOTTOM"
      tooltipTitle="Padding bottom"
      {...paddingBottomField}
      isComputed={isComputed}
    />
  );
};
