import { FC } from 'react';
import { useStore } from '@jux/canjux/core';
import {
  IconButton,
  ModalWrapper,
  SelectField,
  Typography,
} from '@jux/ui/components';
import { MuiGrid, MuiMenuItem } from '@jux/ui/components/common/mui';

export type ThemeSwitcherProps = {
  onChange: (theme: { id: string; name: string }) => void;
  onClose: () => void;
};

export const ThemeSwitcher: FC<ThemeSwitcherProps> = ({
  onChange,
  onClose,
}) => {
  const { editorTokenSetId, options } = useStore((s) => ({
    editorTokenSetId: s.editorTokenSetId,
    options: Object.values(s.tokenSets).map(({ id, name }) => ({
      id,
      name,
    })),
  }));

  return (
    <ModalWrapper onClose={onClose}>
      <MuiGrid item container>
        <Typography
          variant="h2"
          color={(theme) => theme.drimz.palette.text.primary}
        >
          Swap token set to change the theme of the components
        </Typography>
      </MuiGrid>
      <MuiGrid
        item
        container
        marginTop="24px"
        gap="16px"
        flexDirection="column"
      >
        <MuiGrid item container>
          <SelectField
            name="theme"
            type="text"
            value={editorTokenSetId}
            onChange={(e) => {
              const selectedTheme = options.find(
                ({ id }) => id === e.target.value
              );
              if (selectedTheme) {
                onChange(selectedTheme);
              }
            }}
            MenuProps={{
              disablePortal: true,
            }}
          >
            {options.map(({ id, name }) => (
              <MuiMenuItem key={id} value={id}>
                {name}
              </MuiMenuItem>
            ))}
          </SelectField>
        </MuiGrid>
        <MuiGrid item alignSelf="flex-end">
          <IconButton
            fullWidth
            onClick={onClose}
            tabIndex={1}
            variant="primary"
            size="big"
          >
            Close
          </IconButton>
        </MuiGrid>
      </MuiGrid>
    </ModalWrapper>
  );
};
