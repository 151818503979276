import { FC } from 'react';
import { Typography } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';

const TEXT_WIDTH = 42;

export const MagnifierZoomView: FC<{
  zoomPercentage: number;
  showControls: boolean;
}> = ({ zoomPercentage, showControls }) => (
  <MuiGrid item display="flex" justifyContent="center">
    <Typography
      variant="label-s-bold"
      sx={{
        cursor: 'default',
        userSelect: 'none',
        textAlign: 'center',
        minWidth: TEXT_WIDTH,
        width: TEXT_WIDTH,
        color: (theme) =>
          showControls
            ? theme.drimz.palette.text.primary
            : theme.drimz.palette.text.secondary,
        transition: (theme) => theme.drimz.transitions.all,
        borderRadius: (theme) => theme.drimz.size.borderRadius.main,
        backgroundColor: (theme) => theme.drimz.palette.transparent,
        py: 2,
        px: '10px',
      }}
    >
      {zoomPercentage}%
    </Typography>
  </MuiGrid>
);
