import { AbstractImageIcon } from './AbstractImageIcon';
import { AddIcon } from './AddIcon';
import { DeleteIcon } from './DeleteIcon';
import { AliasIcon } from './AliasIcon';
import { AliasInsideIcon } from './AliasInsideIcon';
import { ArrowBackIcon } from './ArrowBackIcon';
import { ArrowPointsDownIcon } from './ArrowPointsDownIcon';
import { ArrowPointsLeftIcon } from './ArrowPointsLeftIcon';
import { ArrowPointsRightIcon } from './ArrowPointsRightIcon';
import { ArrowPointsUpIcon } from './ArrowPointsUpIcon';
import { AssetsIcon } from './AssetsIcon';
import { AtomIcon } from './AtomIcon';
import { BorderIcon } from './BorderIcon';
import { CheckmarkIcon } from './CheckmarkIcon';
import { ClearIcon } from './ClearIcon';
import { CloseIcon } from './CloseIcon';
import { CloseSmallIcon } from './CloseSmallIcon';
import { ColorIcon } from './ColorIcon';
import { ComponentsIcon } from './ComponentsIcon';
import { CompositeIcon } from './CompositeIcon';
import { DimensionIcon } from './DimensionIcon';
import { DocumentationIcon } from './DocumentationIcon';
import { DDPAlignHorizontalIcon } from './DDPAlignHorizontalIcon';
import { DDPAlignVerticalIcon } from './DDPAlignVerticalIcon';
import { DDPIcons } from './DDP';
import { DotsThreeHorizontalIcon } from './DotsThreeHorizontalIcon';
import { DotsThreeVerticalIcon } from './DotsThreeVerticalIcon';
import { DropdownArrowIcon } from './DropdownArrowIcon';
import { DoneIcon } from './DoneIcon';
import { elements } from './elements';
import { elementsPanel } from './elementsPanel';
import { ElementsIcon } from './ElementsIcon';
import { FitViewIcon } from './FitViewIcon';
import { FolderIcon } from './FolderIcon';
import { FolderLockedIcon } from './FolderLockedIcon';
import { FontFamilyIcon } from './FontFamilyIcon';
import { InviteUserIcon } from './InviteUserIcon';
import { JUXLongLogoIcon } from './JUXLongLogoIcon';
import { JUXShortLogoIcon } from './JUXShortLogoIcon';
import { layersCustomIcons } from './layers';
import { objects } from './objects';
import { ResetViewIcon } from './ResetViewIcon';
import { SearchIcon } from './SearchIcon';
import { SpacingIcon } from './SpacingIcon';
import { TokensIcon } from './TokensIcon';
import { tooltipIcons } from './tooltip';
import { TypographyIcon } from './TypographyIcon';
import { UploadIcon } from './UploadIcon';
import { ZoomInViewIcon } from './ZoomInViewIcon';
import { ZoomOutViewIcon } from './ZoomOutViewIcon';
import { EditIcon } from './EditIcon';
import { StatusFailIcon } from './StatusFailIcon';
import { StatusSuccessIcon } from './StatusSuccessIcon';

export const CUSTOM_ICONS = {
  AbstractImageIcon,
  AddIcon,
  DeleteIcon,
  AliasIcon,
  AliasInsideIcon,
  ArrowBackIcon,
  ArrowPointsDownIcon,
  ArrowPointsLeftIcon,
  ArrowPointsRightIcon,
  ArrowPointsUpIcon,
  AssetsIcon,
  AtomIcon,
  BorderIcon,
  CheckmarkIcon,
  ClearIcon,
  CloseIcon,
  CloseSmallIcon,
  ColorIcon,
  ComponentsIcon,
  CompositeIcon,
  DimensionIcon,
  DocumentationIcon,
  DDPAlignHorizontalIcon,
  DDPAlignVerticalIcon,
  ...DDPIcons,
  DotsThreeHorizontalIcon,
  DotsThreeVerticalIcon,
  DropdownArrowIcon,
  DoneIcon,
  EditIcon,
  ...elements,
  ...elementsPanel,
  ElementsIcon,
  FitViewIcon,
  FolderIcon,
  FolderLockedIcon,
  FontFamilyIcon,
  InviteUserIcon,
  JUXLongLogoIcon,
  JUXShortLogoIcon,
  ...layersCustomIcons,
  ...objects,
  ResetViewIcon,
  SearchIcon,
  SpacingIcon,
  TokensIcon,
  ...tooltipIcons,
  TypographyIcon,
  UploadIcon,
  ZoomInViewIcon,
  ZoomOutViewIcon,
  StatusFailIcon,
  StatusSuccessIcon,
};
