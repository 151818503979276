import { ReactNode } from 'react';
// eslint-disable-next-line no-restricted-imports
import { toast as reactToastify, ToastOptions } from 'react-toastify';
import { ToastType } from './toast.interface';
import { getToastOptions } from './getToastOptions';
import { Toast } from './Toast';

export const toast = {
  success: (message: ReactNode, options?: ToastOptions) => {
    reactToastify.success(
      <Toast message={message} type={ToastType.Success} />,
      { ...getToastOptions(ToastType.Success), ...options }
    );
  },
  error: (message: ReactNode, options?: ToastOptions) => {
    reactToastify.error(<Toast message={message} type={ToastType.Error} />, {
      ...getToastOptions(ToastType.Error),
      ...options,
    });
  },
  action: (message: ReactNode, options?: ToastOptions) => {
    reactToastify.success(
      ({ closeToast }) => (
        <Toast
          message={message}
          type={ToastType.Action}
          closeToast={closeToast}
        />
      ),
      { ...getToastOptions(ToastType.Action), ...options }
    );
  },
};
