const HTML_MIME_TYPE = 'text/html';

export const readClipboardRawData = async ({
  type,
  clipboardItems,
}: {
  type: string;
  clipboardItems: ClipboardItems;
}) =>
  (
    await clipboardItems
      .find((clipboardItem) =>
        clipboardItem.types.find((clipboardType) => clipboardType === type)
      )
      ?.getType(type)
  )?.text();

export const serializeHTMLClipboardData = <T extends Record<string, any>>(
  data: T
): ClipboardItem => {
  const blob = new Blob(
    [
      // creating a comment element, so it will be stored as it is without any escape of characters
      `<!--${JSON.stringify(data)}-->`,
    ],
    {
      type: HTML_MIME_TYPE,
    }
  );

  return new ClipboardItem({
    [blob.type]: blob,
  });
};

export const deserializeHTMLClipboardData = <T>(
  rawData: string
): T | undefined => {
  const comment = rawData.match(/<!--(.*?)-->/)?.[1];

  if (!comment) return undefined;

  try {
    return JSON.parse(comment);
  } catch {
    return undefined;
  }
};
