import { DragEventHandler, FC, memo, MouseEventHandler } from 'react';
import { NodeType } from '@jux/data-entities';
import { ItemLogicalSlotSeparator } from '@jux/ui/components/editor/components/panels/layers/ObjectNavigator';
import { DropPosition, DropPositionType } from '../../types';
import { Expander, ExpanderProps } from './Expander';
import {
  ObjectNavigatorItemName,
  ObjectNavigatorItemNameProps,
} from './ObjectNavigatorItemName';
import { ObjectNavigatorItemTypeIcon } from './ObjectNavigatorItemTypeIcon';
import {
  ObjectNavigatorItemWrapper,
  ObjectNavigatorItemWrapperProps,
} from './ObjectNavigatorItemWrapper';
import { DroppableLine } from './DroppableLine';
import { useReorderLayers } from './useReorderLayers';
import { DropZoneIndication } from './DropZoneIndication';
import * as S from './ObjectNavigatorItem.style';
import { ObjectNavigatorItemHiddenIcon } from './ObjectNavigatorItemHiddenIcon';

export type ObjectNavigatorItemProps = Pick<
  ObjectNavigatorItemWrapperProps,
  | 'indentLevel'
  | 'isHovered'
  | 'isHidden'
  | 'isLibraryComponent'
  | 'hasAncestorLibraryComponent'
  | 'hasAncestorSelected'
  | 'isSelected'
  | 'onMouseEnter'
  | 'onMouseLeave'
  | 'onDragStart'
  | 'onDragEnd'
  | 'onDrop'
  | 'onDragOver'
  | 'onClick'
> &
  Pick<ExpanderProps, 'isExpanded'> & {
    objectType: NodeType;
    hasExpander: boolean;
    label: string;
    onExpanderClick: ExpanderProps['onClick'];
    tagName: string;
    onLabelBlur: ObjectNavigatorItemNameProps['onBlur'];
    onLabelDoubleClick: MouseEventHandler<HTMLElement>;
    isEditing: boolean;
    isParentHidden: boolean;
    droppable: boolean;
    draggable: boolean;
    dropPosition?: DropPositionType;
    onDragAbove: DragEventHandler<HTMLElement>;
    onDragBelow: DragEventHandler<HTMLElement>;
    onDragInside: DragEventHandler<HTMLElement>;
    onAbortDragInside: DragEventHandler<HTMLElement>;
    showDroppableLine: boolean;
    isDraggedInside: boolean;
    allowDropAbove: boolean;
    allowDropInside: boolean;
    allowDropBelow: boolean;
    hasDragStarted?: boolean;
    isInstanceToSourceComponent?: boolean;
  };

export const ObjectNavigatorItem: FC<ObjectNavigatorItemProps> = memo(
  ({
    hasExpander,
    indentLevel,
    objectType,
    isExpanded,
    isHovered,
    isSelected,
    isLibraryComponent,
    isInstanceToSourceComponent,
    hasAncestorLibraryComponent,
    hasAncestorSelected,
    label,
    onMouseEnter,
    onMouseLeave,
    onDragStart,
    onDrop,
    onDragOver,
    onDragEnd,
    onClick,
    onExpanderClick,
    tagName,
    onLabelBlur,
    onLabelDoubleClick,
    isEditing,
    isParentHidden,
    isHidden,
    droppable,
    draggable,
    dropPosition,
    onDragAbove,
    onDragBelow,
    onDragInside,
    onAbortDragInside,
    showDroppableLine,
    isDraggedInside,
    allowDropAbove,
    allowDropInside,
    allowDropBelow,
    hasDragStarted,
  }) => {
    const { hoveredOnRef, dragPreviewRef } = useReorderLayers();

    const isLogicalSlot = objectType === NodeType.LOGICAL_SLOT;
    return (
      <ObjectNavigatorItemWrapper
        container
        item
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onDragOver={draggable ? onDragOver : undefined}
        onDragStart={draggable ? onDragStart : undefined}
        onDrop={droppable ? onDrop : undefined}
        onDragEnd={onDragEnd}
        onClick={onClick}
        indentLevel={indentLevel}
        isHovered={isHovered}
        isDraggedInside={isDraggedInside}
        isSelected={isSelected}
        isHidden={isHidden || isParentHidden}
        isLibraryComponent={isLibraryComponent}
        isLogicalSlot={isLogicalSlot}
        hasAncestorLibraryComponent={hasAncestorLibraryComponent}
        hasAncestorSelected={hasAncestorSelected}
        ref={draggable ? hoveredOnRef : undefined}
      >
        <S.DragPreview ref={dragPreviewRef} />
        {droppable && hasDragStarted && (
          <DropZoneIndication
            allowDropAbove={allowDropAbove}
            allowDropInside={allowDropInside}
            allowDropBelow={allowDropBelow}
            onDragAbove={onDragAbove}
            onDragBelow={onDragBelow}
            onDragInside={onDragInside}
            onAbortDragInside={onAbortDragInside}
          />
        )}
        <S.NameIconContainer item>
          <Expander
            isExpanded={isExpanded}
            onClick={onExpanderClick}
            showExpanderIcon={hasExpander}
          />
          <ObjectNavigatorItemTypeIcon
            objectType={objectType}
            tagName={tagName}
            isInstanceToSourceComponent={isInstanceToSourceComponent}
          />
          <ObjectNavigatorItemName
            text={label}
            isEditing={isEditing}
            onBlur={onLabelBlur}
            onDoubleClick={onLabelDoubleClick}
            objectType={objectType}
          />
        </S.NameIconContainer>
        {isLogicalSlot && <ItemLogicalSlotSeparator />}
        {isHidden && !isLogicalSlot && <ObjectNavigatorItemHiddenIcon />}
        {droppable &&
          showDroppableLine &&
          dropPosition &&
          (dropPosition === DropPosition.above ||
            dropPosition === DropPosition.below) && (
            <DroppableLine
              dropPosition={dropPosition}
              indentLevel={indentLevel}
            />
          )}
      </ObjectNavigatorItemWrapper>
    );
  }
);
