import { z } from 'zod';
import {
  COLOR_TOKEN_OPACITY_MAX,
  COLOR_TOKEN_OPACITY_MIN,
  COLOR_TOKEN_OPACITY_RANGE_MESSAGE,
} from '@jux/design-tokens';
import {
  colorTokenInputSchema,
  REQUIRED_FIELD_MESSAGE,
} from '@jux/ui/trpc/validations';

export const colorTokenFormSchema = colorTokenInputSchema.extend({
  value: z.object({
    color: z
      .string()
      .min(1, REQUIRED_FIELD_MESSAGE)
      .regex(
        /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
        'Value must be a valid hex color'
      ),
    opacity: z
      .number({ invalid_type_error: COLOR_TOKEN_OPACITY_RANGE_MESSAGE })
      .int()
      .min(COLOR_TOKEN_OPACITY_MIN, COLOR_TOKEN_OPACITY_RANGE_MESSAGE)
      .max(COLOR_TOKEN_OPACITY_MAX, COLOR_TOKEN_OPACITY_RANGE_MESSAGE),
    colorPickerRgba: z.string().optional(),
  }),
});
