import { ReactNode } from 'react';

export enum ToastType {
  Success = 'success',
  Error = 'error',
  Action = 'action',
}

export type ToastComponentProps =
  | {
      message: ReactNode;
      type: ToastType.Error | ToastType.Success;
      closeToast?: never;
    }
  | {
      message: ReactNode;
      type: ToastType.Action;
      closeToast?: () => void;
    };
