import { EnumPropValue } from '@jux/types';
import type { Draft as WritableDraft } from 'mutative';
import { JuxStore } from '@jux/canjux/core';

export const replacePropValueInAllInstances = ({
  componentId,
  propName,
  propValue,
  newPropValue,
  components,
}: {
  componentId: string;
  propName: string;
  propValue: EnumPropValue;
  newPropValue: EnumPropValue;
  components: WritableDraft<JuxStore['components']>;
}) => {
  const instances = Object.values(components).filter(
    (component) => component?.sourceComponentId === componentId
  );

  for (const instance of instances) {
    if (
      propName in instance.config.props &&
      instance.config.props[propName] === propValue
    ) {
      instance.config.props[propName] = newPropValue;
    }
  }
};
