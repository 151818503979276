import { EnumPropValue } from '@jux/types';

export const INHERITED_FROM_PARENT = 'FROM_PARENT';

export enum ComponentPropType {
  Enum = 'ENUM',
  Boolean = 'BOOLEAN',
}

/**
 * Enum prop type
 */

export type ComponentEnumOption<T = EnumPropValue> = {
  value: T;
  label: string;
};

export type ComponentEnumProp<T = EnumPropValue> = {
  propType: ComponentPropType.Enum;
  variant: string;
  options: Array<ComponentEnumOption<T>>;
  defaultValue: T;
  // displayName is optional and is used in the UI prop selector
  displayName?: string;
  isFixed?: boolean;
};

/**
 * Boolean prop type
 */
export type ComponentBooleanProp = {
  propType: ComponentPropType.Boolean;
  defaultValue: boolean;
  displayName?: string;
};

export type ComponentProp = ComponentEnumProp;
