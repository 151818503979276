import { UserProvider } from '@auth0/nextjs-auth0/client';
import { CacheProvider } from '@emotion/react';
import {
  Hydrate,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import Head from 'next/head';
import Script from 'next/script';
import { FC, useState } from 'react';
import logger from '@jux/ui-logger';
import { AppLayoutProvider, ErrorBoundary } from '@jux/ui/components';
import { HydrateAtoms, JotaiProvider, jotaiStore } from '@jux/ui/jotai';
import { api } from '@jux/ui/trpc/client/api';
import createEmotionCache, {
  EnhancedAppProps,
} from '@jux/ui/utils/CreateEmotionCache';

// css imports
import 'react-toastify/dist/ReactToastify.css';
import '@fontsource/nunito-sans';
import '../canjux.style.css';

const clientSideEmotionCache = createEmotionCache();

const FrontendService: FC<EnhancedAppProps> = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error: AxiosError) => {
            logger.error(`Error: ${error.message}`);
            // https://react-query.tanstack.com/reference/setLogger
          },
        }),
      })
  );

  // const GTM_KEY = process.env.NEXT_PUBLIC_GTM_KEY;
  const isStaging =
    typeof window !== 'undefined'
      ? window.location.hostname.includes('staging')
      : false;

  const theme =
    typeof window !== 'undefined'
      ? JSON.parse(localStorage.getItem('settings'))?.theme
      : null;
  const isDarkTheme = theme === 'dark';

  return (
    <>
      <ErrorBoundary>
        <CacheProvider value={emotionCache}>
          {/* <Script id={'google_tag_manager'} strategy="lazyOnload">
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${GTM_KEY}');
            `}
          </Script> */}
          <Script
            src="https://kit.fontawesome.com/c0c753c6b3.js"
            crossOrigin="anonymous"
          />
          <Head>
            <title>JUX</title>
            <meta
              name="viewport"
              content="initial-scale=1, width=device-width"
            />
            <link
              rel="icon"
              href={
                isStaging
                  ? '/favicon-staging.ico'
                  : isDarkTheme
                  ? '/favicon-dark-theme.ico'
                  : '/favicon.ico'
              }
            />
          </Head>
          {/*<noscript>*/}
          {/*  <iframe*/}
          {/*    src={`https://www.googletagmanager.com/ns.html?id=${GTM_KEY}`}*/}
          {/*    height="0"*/}
          {/*    width="0"*/}
          {/*    style={{ display: 'none', visibility: 'hidden' }}*/}
          {/*  ></iframe>*/}
          {/*</noscript>*/}

          <UserProvider>
            <QueryClientProvider client={queryClient}>
              <JotaiProvider store={jotaiStore}>
                <Hydrate state={pageProps.dehydratedState}>
                  <HydrateAtoms queryClient={queryClient}>
                    <AppLayoutProvider>
                      <Component {...pageProps} />
                    </AppLayoutProvider>
                  </HydrateAtoms>
                </Hydrate>
              </JotaiProvider>
            </QueryClientProvider>
          </UserProvider>
        </CacheProvider>
      </ErrorBoundary>
    </>
  );
};

export default api.withTRPC(FrontendService);
