import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  MuiTable,
  MuiTableBody,
  MuiTableCell,
  MuiTableHead,
  MuiTableRow,
} from '@jux/ui/components/common/mui';
import { TableDataProps } from './Table.interface';
import { useTable } from './useTable';

/**
 * if you want to sort by a column, you need to pass the orderBy property to the header object
 * and you need to pass a value property to the cell object
 *
 * orderBy: {
 *  direction: OrderByDirection,
 *  fieldType: OrderByFieldType
 * }
 *
 * value: SupportedValueTypes;
 *
 */
export const Table: FC<TableDataProps> = ({
  headers,
  rows: originalRows,
  tableHeadProps,
  tableHeadRowProps,
  tableBodyProps,
  ...tableProps
}) => {
  const { rows } = useTable({
    headers,
    rows: originalRows,
  });

  return (
    <MuiTable {...tableProps}>
      <MuiTableHead {...tableHeadProps}>
        <MuiTableRow {...tableHeadRowProps}>
          {headers.map(({ header, orderBy, ...props }) => (
            <MuiTableCell key={uuidv4()} {...props}>
              {header}
            </MuiTableCell>
          ))}
        </MuiTableRow>
      </MuiTableHead>
      <MuiTableBody {...tableBodyProps}>
        {rows.map(({ cells, ...props }) => (
          <MuiTableRow key={uuidv4()} {...props}>
            {cells.map(({ cell, ...cellsProps }) => (
              <MuiTableCell key={uuidv4()} {...cellsProps}>
                {cell}
              </MuiTableCell>
            ))}
          </MuiTableRow>
        ))}
      </MuiTableBody>
    </MuiTable>
  );
};
