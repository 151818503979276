export const pasteTextOnly = (text: string) => {
  const selection = window.getSelection();
  if (!selection) return;

  const range = selection.getRangeAt(0);
  range.deleteContents();

  const textNode = document.createTextNode(text);
  range.insertNode(textNode);
  range.collapse(false);
  selection.removeAllRanges();
  selection.addRange(range);
};
