/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';

// TODO: Check why this doesn't work when using <CustomIcon> (2nd <path> is not rendered)
export const AliasInsideIcon: IconType = (props: IconBaseProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    stroke="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9602 2.76777C10.9839 1.79146 9.40096 1.79146 8.42465 2.76777C8.22938 2.96303 8.22938 3.27961 8.42465 3.47487C8.61991 3.67014 8.93649 3.67014 9.13175 3.47487C9.71754 2.88909 10.6673 2.88909 11.2531 3.47487C11.8389 4.06066 11.8389 5.01041 11.2531 5.59619L9.13175 7.71751C8.7396 8.10966 8.18407 8.23993 7.68219 8.10591C7.41539 8.03466 7.14136 8.19318 7.07011 8.45997C6.99886 8.72677 7.15739 9.0008 7.42418 9.07205C8.25676 9.29439 9.18409 9.07939 9.83886 8.42462L11.9602 6.3033C12.9365 5.32699 12.9365 3.74408 11.9602 2.76777ZM7.04578 6.62203C7.31257 6.69327 7.5866 6.53475 7.65785 6.26796C7.7291 6.00117 7.57058 5.72713 7.30379 5.65588C6.4712 5.43354 5.54387 5.64854 4.8891 6.30331L2.76778 8.42463C1.79147 9.40094 1.79147 10.9839 2.76778 11.9602C3.74409 12.9365 5.327 12.9365 6.30332 11.9602C6.49858 11.7649 6.49858 11.4483 6.30332 11.2531C6.10805 11.0578 5.79147 11.0578 5.59621 11.2531C5.01042 11.8388 4.06067 11.8388 3.47489 11.2531C2.8891 10.6673 2.8891 9.71752 3.47489 9.13174L5.59621 7.01042C5.98836 6.61827 6.54389 6.488 7.04578 6.62203Z"
      fill="currentColor"
    />
    <path
      d="M13.5 13.5L11 11M13.5 13.5L13.5 11.5M13.5 13.5L11.5 13.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
