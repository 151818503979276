import { CanjuxState, resolvedProps, storeApi } from '@jux/canjux/core';
import { DEFAULT_STATE, StylesState } from '@jux/types';
import { isMatch } from 'lodash';
import { createSelector } from 'reselect';
import { getNodeComponentById } from './utils';

export const getResolvedContextStylesSelector = createSelector(
  [
    (state: CanjuxState) => state.components,
    (state: CanjuxState) => state.assets,
  ],
  (components, assets) =>
    ({
      id,
      parentContextId,
      contextState = 'default',
    }: {
      // the id of the component to get the styles for
      id: string;
      // the identifier of the context in which the component is currently at
      parentContextId: string;
      // the interactive state of the context
      contextState?: StylesState;
    }) => {
      const { styles: contextParentStyles } =
        getNodeComponentById({ components, id: parentContextId }) || {};

      const { contextStyles } = contextParentStyles || {};
      const nodeContextProps = resolvedProps({
        assets,
        components,
        id: parentContextId,
        onlyVariantsProps: true,
      });

      return (
        contextStyles
          ?.filter(
            // getting all the context styles associated with the current context child
            (contextStyle) => {
              if (contextStyle.contextChildUuid !== id) return false;
              if (!contextStyle.condition) return true;

              const isConditionStateSupported =
                !contextStyle.condition.state ||
                contextStyle.condition.state === DEFAULT_STATE || // Default is inherited by all other states
                contextStyle.condition?.state === contextState;
              const isConditionPropsValuesSupported =
                !contextStyle.condition.propsValues ||
                isMatch(nodeContextProps, contextStyle.condition.propsValues);
              return (
                isConditionStateSupported && isConditionPropsValuesSupported
              );
            }
          )
          // sorting the styles based on the number of props in a condition, so the more specific conditions they will be applied first
          .sort(
            (contextStyleA, contextStyleB) =>
              Object.keys(contextStyleB.condition ?? {}).length -
              Object.keys(contextStyleA.condition ?? {}).length
          )
          // fetching only the styles
          .map(({ styles }) => styles)
      );
    }
);

export const getResolvedContextStyles = () =>
  getResolvedContextStylesSelector(storeApi.getState());
