import { FC, useRef } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import {
  Floors,
  useMaxHeightRelativeToRef,
  useMaxWidthRelativeToRef,
  useOverlaysZIndex,
} from '@jux/ui/hooks';
import { withPx } from '@jux/ui/utils/css';
import { TokenSetsTabs } from './tokensSets/tokenSetsTabs/TokenSetsTabs';
import { TokenTypeNav, TokenSet } from './tokensSets';

const TOKEN_TYPES_WIDTH = 230;

export const TokenSetsLayout: FC = () => {
  const tokenSetsTabWrapperRef = useRef<HTMLDivElement>(null);
  const tokenTypesWrapperRef = useRef<HTMLDivElement>(null);

  const currentTokenSetTypeGroupsMaxHeight = useMaxHeightRelativeToRef({
    relativeRef: tokenSetsTabWrapperRef,
    spacing: 0,
  });
  const tokenSetsWrapperWidth = useMaxWidthRelativeToRef({
    relativeRef: tokenTypesWrapperRef,
    spacing: 0,
  });

  const navbarZIndex = useOverlaysZIndex({
    id: 'layout-navbar',
    floor: Floors.innerNavbar,
  });
  const tokenTypeZIndex = useOverlaysZIndex({
    id: 'layout-navbar',
    floor: Floors.tokenType,
  });
  const contentZIndex = useOverlaysZIndex({
    id: 'layout-content',
    floor: Floors.content,
  });

  return (
    <MuiGrid container width="100%" height="100%" flexDirection="row" flex={1}>
      <MuiGrid
        item
        ref={tokenTypesWrapperRef}
        sx={{
          height: (theme) =>
            `calc(100vh - ${withPx(theme.drimz.size.layout.navbar.height)})`,
          width: TOKEN_TYPES_WIDTH,
          position: 'sticky',
          top: (theme) =>
            theme.drimz.size.layout.navbar.height +
            theme.drimz.size.layout.tokens.tabs.border.height,
          zIndex: tokenTypeZIndex,
          borderRight: 1,
          borderColor: (theme) => theme.drimz.palette.divider.primary,
          background: (theme) => theme.drimz.palette.background.navigation,
        }}
      >
        <TokenTypeNav />
      </MuiGrid>
      <MuiGrid
        container
        item
        sx={{
          flexDirection: 'column',
          width: tokenSetsWrapperWidth,
        }}
      >
        <MuiGrid
          container
          ref={tokenSetsTabWrapperRef}
          sx={{
            position: 'sticky',
            top: (theme) =>
              theme.drimz.size.layout.navbar.height +
              theme.drimz.size.layout.tokens.tabs.border.height,
            backgroundColor: (theme) =>
              theme.drimz.palette.background.navigation,
            zIndex: navbarZIndex,
          }}
        >
          <TokenSetsTabs />
        </MuiGrid>
        <MuiGrid
          item
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            maxHeight: currentTokenSetTypeGroupsMaxHeight,
            overflowY: 'auto',
            overflowX: 'hidden',
            zIndex: contentZIndex,
            padding: (theme) =>
              theme.drimz.size.layout.tokens.spacing.mainPadding,
          }}
        >
          <TokenSet />
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
};
