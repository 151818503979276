import { DEFAULT_BOX_SHADOW_COLOR } from '../theme/palette';
import { colorWithOpacity } from './colorWithOpacity';

export type BoxShadow = {
  color: string | undefined;
  offsetX: string;
  offsetY: string;
  blurRadius: string;
  spreadRadius: string;
  inset?: boolean;
};

const INSET = 'inset';

const VALUES_REGEX = /,(?![^(]*\))/;
const PARTS_REGEX = /\s(?![^(]*\))/;
const LENGTH_REGEX = /^[0-9]+[a-zA-Z%]+$/;

const FALLBACK_INSET = false;
const FALLBACK_OFFSET_X = '0';
const FALLBACK_OFFSET_Y = '0';
const FALLBACK_BLUR_RADIUS = '0';
const FALLBACK_SPREAD_RADIUS = '0';
const FALLBACK_SHADOW_COLOR = colorWithOpacity(DEFAULT_BOX_SHADOW_COLOR, 0.15);

const isLength = (v: string) => LENGTH_REGEX.test(v);

const parseValue = (str: string): BoxShadow => {
  const parts = str.split(PARTS_REGEX);
  const inset = parts.includes(INSET);
  const last = parts.slice(-1)[0];
  const color = !isLength(last) ? last : FALLBACK_SHADOW_COLOR;

  const numbers = parts.filter((n) => n !== INSET && n !== color);
  const [offsetX, offsetY, blurRadius, spreadRadius] = numbers;

  return {
    inset,
    offsetX,
    offsetY,
    blurRadius,
    spreadRadius,
    color,
  };
};

const validTextBoxShadowValues = [
  'none',
  'inherit',
  'initial',
  'revert',
  'revert-layer',
  'unset',
] as const;

const isInvalidBoxShadowTextValue = (value: string) =>
  !validTextBoxShadowValues.includes(
    value as typeof validTextBoxShadowValues[number]
  );

export const parseBoxShadow = (str: string | undefined) =>
  str && isInvalidBoxShadowTextValue(str)
    ? str
        .split(VALUES_REGEX)
        .map((s) => s.trim())
        .map(parseValue)
    : undefined;

const stringify = (shadow: BoxShadow) => {
  const inset = shadow.inset ?? FALLBACK_INSET;
  const offsetX = shadow.offsetX ?? FALLBACK_OFFSET_X;
  const offsetY = shadow.offsetY ?? FALLBACK_OFFSET_Y;
  const blurRadius = shadow.blurRadius ?? FALLBACK_BLUR_RADIUS;
  const spreadRadius = shadow.spreadRadius ?? FALLBACK_SPREAD_RADIUS;
  // color is OR due to the fact that the DEFAULT_SHADOW_COLOR is '' and not undefined
  const color = shadow.color || FALLBACK_SHADOW_COLOR;

  return [
    inset ? INSET : null,
    offsetX,
    offsetY,
    blurRadius,
    spreadRadius,
    color,
  ]
    .filter(Boolean)
    .map((s) => ('' + s).trim())
    .join(' ');
};

export const stringifyShadowsValues = (shadows: Array<BoxShadow>) =>
  shadows.filter(Boolean).map(stringify).join(', ');
