import { MuiGrid, styled } from '@jux/ui/components/common/mui';
import { DDP_FIELD_TOKENS_BOX_BORDER_COLOR } from '@jux/ui/theme/palette';

export const TokenSelectionPopperWrapper = styled(MuiGrid)(({ theme }) => ({
  position: 'absolute',
  width: 'calc(100% + 2px)',
  top: '100%',
  left: -1,
  zIndex: 10,
  backgroundColor: theme.drimz.palette.background.default,
  border: '1px solid',
  borderColor: DDP_FIELD_TOKENS_BOX_BORDER_COLOR,
  borderRadius: theme.drimz.size.borderRadius.small,
  boxShadow: theme.drimz.shadow.editor.panels.ddp.baseField.tokens.boxShadow,
}));
