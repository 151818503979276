/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const AlignItemsFlexStartColumnDDPIcon: IconType = (
  props: IconBaseProps
) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M5 13.5C5 13.7761 4.77614 14 4.5 14C4.22386 14 4 13.7761 4 13.5V2.5C4 2.22386 4.22386 2 4.5 2C4.77614 2 5 2.22386 5 2.5L5 13.5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M7 6C7 5.44772 7.44772 5 8 5C8.55229 5 9 5.44772 9 6L9 10C9 10.5523 8.55229 11 8 11C7.44772 11 7 10.5523 7 10V6Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M11 5C10.4477 5 10 5.44772 10 6V10C10 10.5523 10.4477 11 11 11C11.5523 11 12 10.5523 12 10V6C12 5.44772 11.5523 5 11 5Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
