import {
  JuxStore,
  JuxStoreActionFn,
  PropsActionsParams,
} from '../../store.interface';
import { INHERITED_FROM_PARENT, NodeType } from '@jux/data-entities';
import { setRootComponentUpdateTime } from '../utils';
import { getContextParentByNodeId } from '@jux/canjux/core';

/**
 * Update a component prop value.
 */
export const setComponentPropValueChoice: JuxStoreActionFn<
  PropsActionsParams['setComponentPropValueChoice'],
  JuxStore
> = ({ componentId, propName, propValue, state }) => {
  if (!componentId) return state;

  const component = state.components[componentId];
  if (!component) return state;

  if (
    propName in component.config.props &&
    component.config.props[propName] === INHERITED_FROM_PARENT
  ) {
    // we need to set the new value at the parent context level
    const parentContextNodeId = getContextParentByNodeId({
      id: componentId,
      components: state.components,
    });
    if (!parentContextNodeId) {
      throw new Error('Parent context node not found');
    }

    const parentContextComponent = state.components[parentContextNodeId];
    if (!parentContextComponent) {
      throw new Error('Parent context component not found');
    }

    parentContextComponent.config.props[propName] = propValue;
    return state;
  }

  switch (component.type) {
    case NodeType.VARIANTS_GROUP:
      return state;
    case NodeType.ELEMENT:
    case NodeType.LOCAL_COMPONENT:
    case NodeType.LIBRARY_COMPONENT:
    case NodeType.INSTANCE:
      // Adding new prop value this way because of immer
      component.config.props = {
        ...component.config.props,
        [propName]: propValue,
      };

      setRootComponentUpdateTime({
        id: componentId,
        components: state.components,
      });

      break;
  }

  // Set the node that we updated to trigger a layers update after it is rerendered.
  state.nodesTriggeringLayersUpdate.add(componentId);

  return state;
};
