import {
  CanjuxState,
  CommonActionsParams,
  JuxStoreActionFn,
  LineType,
} from '@jux/canjux/core';
import { setHoveredNodeInternal } from '../helpers/setHoveredNodeInternal';

/**
 * Set hovered node
 */
export const setHoveredNode: JuxStoreActionFn<
  CommonActionsParams['setHoveredNode'],
  CanjuxState
> = ({
  nodeId,
  isHovered,
  lineType = LineType.PARENT,
  affectChildren = false,
  state,
}) => {
  return setHoveredNodeInternal({
    nodeId,
    isHovered,
    lineType,
    affectChildren,
    state,
  });
};
