import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { BorderWidthField } from './BorderWidthField';

export const BorderWidth: FC = () => {
  const { shouldRender } = useSubModule(DDPSubModulesKeys.borderWidth);

  if (!shouldRender) return null;
  return (
    <SubModule title="Width" hideHeader>
      <BorderWidthField />
    </SubModule>
  );
};
