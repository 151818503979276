import { FC, useCallback, useMemo } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import {
  ButtonGroupField,
  GroupButton,
  InnerSubModuleHeader,
} from '../../../../base';
import { useShadowsFields } from './useShadowsFields';

export const ShadowInsetField: FC = () => {
  const { value, initialValue, saveChanges } = useShadowsFields();
  const { inset } = value || {};
  const { inset: initialInset } = initialValue || {};

  const setFieldValue = useCallback(
    (newValue: boolean) => {
      saveChanges({ inset: newValue });
    },
    [saveChanges]
  );

  const isInset = useMemo(
    () => Boolean(inset ?? initialInset),
    [inset, initialInset]
  );
  const isNormal = useMemo(() => !isInset, [isInset]);

  const handleInsetClick = useCallback(() => {
    setFieldValue(true);
  }, [setFieldValue]);

  const handleNormalClick = useCallback(() => {
    setFieldValue(false);
  }, [setFieldValue]);

  return (
    <MuiGrid container item alignItems="center" justifyContent="space-between">
      <InnerSubModuleHeader>Drop/inner shadow</InnerSubModuleHeader>

      <ButtonGroupField>
        <GroupButton
          icon="DDP_FIELD_BOX_SHADOW_INSET_FALSE"
          isSelected={isNormal}
          onClick={handleNormalClick}
          tooltipTitle="Normal"
        />
        <GroupButton
          icon="DDP_FIELD_BOX_SHADOW_INSET_TRUE"
          isSelected={isInset}
          onClick={handleInsetClick}
          tooltipTitle="Inset"
        />
      </ButtonGroupField>
    </MuiGrid>
  );
};
