import { useCallback, useMemo } from 'react';
import { UnitType, useUnitState } from '../useUnitState';
import { DimensionFieldOptions } from './useDimensionField.interface';
import {
  changeToProperUnit,
  dimensionFieldSchema,
  isValidDimensionTextValue,
  parseIntoField,
} from './useDimensionField.utils';

export const useDimensionFieldValue = ({
  rawValue,
  options: {
    supportNoneValue,
    supportMixedValue,
    supportAutoValue,
    supportNegativeValue,
  },
}: {
  rawValue: string;
  options: DimensionFieldOptions;
}) => {
  const { unit, setUnit } = useUnitState();

  const parseValue = useCallback(
    (newValue: string) => parseIntoField(newValue, supportNoneValue),
    [supportNoneValue]
  );

  const parseUnit = useCallback(
    (newValue: string, newUnit: UnitType): UnitType =>
      changeToProperUnit(newValue, newUnit, supportNoneValue),
    [supportNoneValue]
  );

  const parsedValue = useMemo(() => {
    const { value: parsedVal, unit: parsedUnit } = parseValue(rawValue);

    // TODO: remove this line - memo/selector should not have side-effects
    setUnit(parsedUnit);

    return parsedVal;
  }, [parseValue, rawValue, setUnit]);

  const activateUnit = useMemo(
    () => !isValidDimensionTextValue(parsedValue, supportNoneValue),
    [supportNoneValue, parsedValue]
  );

  const fieldSchema = useMemo(
    () =>
      dimensionFieldSchema(
        supportNoneValue,
        supportMixedValue,
        supportAutoValue,
        supportNegativeValue
      ),
    [
      supportAutoValue,
      supportMixedValue,
      supportNegativeValue,
      supportNoneValue,
    ]
  );

  const validateField = useCallback(
    (val: string) =>
      dimensionFieldSchema(
        supportNoneValue,
        supportMixedValue,
        supportAutoValue,
        supportNegativeValue
      ).safeParse(val),
    [
      supportNoneValue,
      supportMixedValue,
      supportAutoValue,
      supportNegativeValue,
    ]
  );

  return {
    unit,
    setUnit,
    parsedValue,
    rawValue,
    activateUnit,
    fieldSchema,
    parseValue,
    parseUnit,
    validateField,
  };
};
