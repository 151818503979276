import { useCallback, useMemo } from 'react';
import {
  DDPPrimitiveFieldsKeys,
  useDDPSubModulesState,
} from '@jux/ui/components/editor/state';
import { useFieldValues, useSetFieldValue } from '../../../../hooks';
import { parseDisplay } from './DisplayField.utils';
import { DisplayValue } from './DisplayField.interface';

const FIELD_KEY = DDPPrimitiveFieldsKeys.display;
export const useDisplayField = () => {
  const { value, initialValue } = useFieldValues(FIELD_KEY);
  const { saveValue: setFieldValue } = useSetFieldValue(FIELD_KEY);

  // TODO: remove this when liveblocks is ready
  const { setSubModulesState } = useDDPSubModulesState();

  const parsedDisplay = useMemo(
    () => parseDisplay(value ?? initialValue),
    [initialValue, value]
  );

  const saveChanges = useCallback(
    (newDisplayValue: string | undefined) => {
      if (!newDisplayValue) {
        return;
      }

      const parsedDisplayValue = parseDisplay(newDisplayValue);
      if (!parsedDisplayValue) {
        return;
      }

      // TODO: remove this when liveblocks is ready
      const isDisplayFlex = parsedDisplayValue === DisplayValue.flex;

      setSubModulesState((prev) => ({
        ...prev,
        flexDirectionAndWrapping: {
          shouldRender: isDisplayFlex,
        },
        alignItems: {
          shouldRender: isDisplayFlex,
        },
        justifyContent: {
          shouldRender: isDisplayFlex,
        },
        gap: {
          shouldRender: isDisplayFlex,
        },
      }));
      setFieldValue(parsedDisplayValue);
    },
    [setFieldValue, setSubModulesState]
  );

  return {
    selectedDisplay: parsedDisplay,
    handleDisplayChange: saveChanges,
  };
};
