/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const RowGapDDPIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M12.75 4C12.75 3.58579 12.4142 3.25 12 3.25C11.5858 3.25 11.25 3.58579 11.25 4V5.25L4.75 5.25V4C4.75 3.58579 4.41421 3.25 4 3.25C3.58579 3.25 3.25 3.58579 3.25 4V6C3.25 6.41421 3.58579 6.75 4 6.75L12 6.75C12.4142 6.75 12.75 6.41421 12.75 6V4Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M12.75 10C12.75 9.58579 12.4142 9.25 12 9.25L4 9.25C3.58579 9.25 3.25 9.58579 3.25 10V12C3.25 12.4142 3.58579 12.75 4 12.75C4.41421 12.75 4.75 12.4142 4.75 12L4.75 10.75L11.25 10.75V12C11.25 12.4142 11.5858 12.75 12 12.75C12.4142 12.75 12.75 12.4142 12.75 12V10Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
