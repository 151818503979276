import { DATA_JUX_NODE_ID_ATTRIBUTE, storeApi } from '@jux/canjux/core';

/**
 * @returns the path to the canvas root node, starting from the provided nodeId
 */
export function extractPathToRoot(nodeId: string): Array<string> {
  const path: Array<string> = [];

  const components = storeApi.getState().components;
  let currentNode = components[nodeId];

  while (currentNode.parentId) {
    path.push(currentNode.parentId);

    currentNode = components[currentNode.parentId];
  }

  return path;
}

export function isEditableTextNodeExistInPosition(x: number, y: number) {
  const element = document.elementFromPoint(x, y);

  return Boolean(element?.getAttribute('data-text-editable'));
}

export function isNodeExistInPosition(x: number, y: number) {
  const element = document.elementFromPoint(x, y);

  return Boolean(element?.getAttribute(DATA_JUX_NODE_ID_ATTRIBUTE));
}
