import type { D3DragEvent, SubjectPosition } from 'd3';

import type { JuxRect, Transform } from './utils';

export type Viewport = Transform;

export enum PanOnScrollMode {
  Free = 'free',
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export type PanelPosition =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right'
  | 'center-left'
  | 'center-right';

export type UseDragEvent = D3DragEvent<HTMLDivElement, null, SubjectPosition>;

export type SelectionRect = JuxRect & {
  startX: number;
  startY: number;
};
