import { ReactNode } from 'react';
import { Floors } from '@jux/ui/hooks';

export enum PopperStates {
  opened = 'opened',
  closed = 'closed',
}

export const PoppersKey = {
  components: 'components',
  assets: 'assets',
  ddpAssets: 'ddpAssets',
  elements: 'elements',
  menu: 'menu',
  groupHeaderActions: 'groupHeaderActions',
  tokenSetActions: 'tokenSetActions',
  canvasActions: 'canvasActions',
} as const;

export type PoppersKeys = keyof typeof PoppersKey;

export type PopperStateByKey = Partial<
  Record<PoppersKeys, keyof typeof PopperStates>
>;

export type PopperInnerKey = string | null | undefined;

export enum PopperActions {
  open = 'open',
  close = 'close',
}

export type PopperAction = [PoppersKeys, PopperActions];

export type PopperHandlersRecord = Record<string, () => void> | undefined;

type DefaultPopperHandlers = {
  onClose: () => void;
};

type OptionalPopperHandlers = undefined;

export type PopperHandlers<T extends PopperHandlersRecord = undefined> =
  T extends OptionalPopperHandlers
    ? DefaultPopperHandlers
    : T & DefaultPopperHandlers;

export type PopperContent = ReactNode;

export type PopperPosition = {
  top?: number;
  left?: number;
  transform?: string;
};

export type PopperPositioning = 'bottom-left' | 'bottom-right';

export type PopperDirection = 'rtl' | 'ltr';

export type PopperStyles = {
  offset?: PopperPosition;
  positioning?: PopperPositioning;
  direction?: PopperDirection;
  floor?: Floors;
};

export type PopperSourceElement = HTMLElement | null;

export type UpdatePopperArgs<PH extends PopperHandlersRecord = undefined> = {
  action: PopperAction;
  content?: PopperContent;
  handlers?: PopperHandlers<PH>;
  styles?: PopperStyles;
  sourceElement?: PopperSourceElement;
  previouslyOpenedPopperKey?: PoppersKeys | null;
  innerKey?: PopperInnerKey;
};
