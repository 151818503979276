import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { useSettingsState } from '@jux/ui/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { MaxHeightField } from './MaxHeightField';
import { MinHeightField } from './MinHeightField';
import { HeightField } from './HeightField';

export const Height: FC = () => {
  const {
    settings: { ddpMinMaxSupport },
  } = useSettingsState();
  const { shouldRender } = useSubModule(DDPSubModulesKeys.height);

  if (!shouldRender) return null;
  return (
    <SubModule title="Height">
      <HeightField />
      {ddpMinMaxSupport && (
        <>
          <MaxHeightField />
          <MinHeightField />
        </>
      )}
    </SubModule>
  );
};
