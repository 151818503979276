import { Inject, Injectable } from '@nestjs/common';
import { ConfigService } from '@nestjs/config';
import Analytics from 'analytics-node';
import { WINSTON_MODULE_PROVIDER } from 'nest-winston';
import { Logger } from 'winston';
import { SegmentProperties } from './segment.interface';
import { SegmentEvent } from './segment.events';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class SegmentFactory {
  private readonly segmentApiKey: string;
  private readonly segment: Analytics;

  constructor(
    private configService: ConfigService,
    @Inject(WINSTON_MODULE_PROVIDER) private readonly logger: Logger
  ) {
    this.segmentApiKey = configService.getOrThrow<string>(
      'NEXT_PUBLIC_SEGMENT_KEY'
    );

    this.segment = new Analytics(this.segmentApiKey, {
      flushAt: process.env.NODE_ENV === 'production' ? 20 : 1,
    });
  }

  async trackEvent(
    userId: number,
    eventName: SegmentEvent,
    properties: SegmentProperties = {}
  ) {
    await this.segment.track({
      userId,
      anonymousId: !userId ? uuidv4() : undefined,
      event: eventName,
      properties,
    });
  }

  async page(
    userId: number,
    pageName: string,
    properties: SegmentProperties = {}
  ) {
    await this.segment.page({
      userId,
      anonymousId: !userId ? uuidv4() : undefined,
      name: pageName,
      properties,
    });
  }

  async identify(userId: number, traits: SegmentProperties) {
    await this.segment.identify({
      userId,
      traits,
      anonymousId: !userId ? uuidv4() : undefined,
    });
  }

  async group(userId: number, groupId: string, traits: SegmentProperties) {
    await this.segment.group({
      userId,
      groupId,
      traits,
      anonymousId: !userId ? uuidv4() : undefined,
    });
  }
}
