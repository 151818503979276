import capitalize from 'lodash/capitalize';
import { useCallback } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import { useTokenSetState } from '@jux/ui/state';

export const useCreateGroup = () => {
  const { currentTokenType } = useTokenSetState();

  const {
    tokenSetsActions: { addGroup },
  } = useStoreActions();

  const createGroup = useCallback(
    ({
      newGroupName,
      groupPath,
    }: {
      newGroupName: string;
      groupPath: string;
    }) => {
      addGroup({
        groupName: newGroupName,
        groupPath,
        description: `${capitalize(
          currentTokenType
        )} group ${newGroupName} inside ${groupPath}`,
      });
    },
    [addGroup, currentTokenType]
  );

  return {
    createGroup,
  };
};
