export function addStorageNode<T>(
  state: Array<T>,
  item: T,
  targetIndex: number
) {
  if (state.indexOf(item) === -1) {
    state.splice(targetIndex, 0, item);
  }
}

export function removeStorageNode<T>(
  state: Array<T>,
  item: T,
  options?: { targetIndex?: number }
) {
  const currentIndex = state.indexOf(item);

  if (currentIndex !== -1 && currentIndex !== options?.targetIndex) {
    state.splice(currentIndex, 1);
  }
}

export function reorderStorageNode<T>(
  state: Array<T>,
  item: T,
  targetIndex: number
) {
  const currentIndex = state.indexOf(item);
  if (targetIndex === currentIndex) return false;

  removeStorageNode(state, item, { targetIndex });

  // We're reducing the index by 1 because we removed the item from the array so the length is smaller
  // in case the targetIndex is 0, we don't want to subtract 1
  // in case the currentIndex is bigger than the targetIndex, we don't want to subtract 1
  const tIndex =
    !targetIndex || currentIndex > targetIndex ? targetIndex : targetIndex - 1;

  addStorageNode(state, item, tIndex);
  return true;
}
