import {
  isNodeSelected,
  selectHoveredNodesStack,
  selectNodeChildren,
  selectNodeParent,
  selectNodeProperties,
  selectNodeSiblings,
  selectSelectedNodeIds,
  storeApi,
  useStoreActions,
} from '@jux/canjux/core';
import { NodeProperties } from '@jux/data-entities';
import { MouseEventHandler, useCallback } from 'react';
import { isDirectSelection } from '../utils/isDirectSelection';
import { isMultiSelection } from '../utils/isMultiSelection';
import { isSiblingOrParentOfSelectedNodes } from '../utils/isSiblingOrParentOfSelectedNodes';

export const useNodeHover = ({ nodeId }: { nodeId: string }) => {
  const {
    commonActions: { setHoveredNode },
  } = useStoreActions();

  const getNodeProperties = useCallback(
    () => selectNodeProperties(nodeId)(storeApi.getState()),
    [nodeId]
  );
  const getNodeChildren = useCallback(
    () => selectNodeChildren(nodeId)(storeApi.getState()),
    [nodeId]
  );
  const getNodeParent = useCallback(
    (id: string) => selectNodeParent(id)(storeApi.getState()),
    []
  );
  const getIsNodeSelected = useCallback(
    (id: string) => isNodeSelected(id)(storeApi.getState()),
    []
  );
  const getNodeSiblings = useCallback(
    () => selectNodeSiblings(nodeId)(storeApi.getState()),
    [nodeId]
  );
  const getSelectedNodes = () => selectSelectedNodeIds(storeApi.getState());
  const getHoveredNodesStack = () =>
    selectHoveredNodesStack(storeApi.getState());

  const shouldHover = useCallback(
    ({
      isDirectSelectionActive,
      isMultiSelectionActive,
      isSelected,
      nodeChildren,
      nodeProperties,
      nodeSiblings,
      parentId,
      selectedNodes,
    }: {
      isDirectSelectionActive: boolean;
      isMultiSelectionActive: boolean;
      isSelected: boolean;
      nodeChildren: string[] | undefined;
      nodeProperties: NodeProperties | undefined;
      nodeSiblings: string[];
      parentId?: string;
      selectedNodes: string[];
    }) => {
      // return true only in case:
      // 1. node is root node and is not selected
      // 2. node is sibling or parent of selected node

      if (!nodeProperties?.isSelectable) {
        return false;
      }

      if ((isDirectSelectionActive || isMultiSelectionActive) && !isSelected) {
        return true;
      }

      if (
        isSiblingOrParentOfSelectedNodes({
          nodeSiblings,
          selectedNodes,
          nodeChildren,
        })
      ) {
        return true;
      }

      // Hover only in case the node is a root node and it's not selected
      return !parentId && !isSelected;
    },
    []
  );

  const getNodeToHover = useCallback(() => {
    let currentNodeId: string | undefined = nodeId;

    while (currentNodeId) {
      const nodeParent = getNodeParent(currentNodeId);
      const isCurrentSelected = getIsNodeSelected(currentNodeId);
      const isParentSelected = Boolean(
        nodeParent?.parentId && getIsNodeSelected(nodeParent.parentId)
      );

      if (!isCurrentSelected && (!nodeParent?.parentId || isParentSelected)) {
        return { id: currentNodeId, parentId: nodeParent.parentId };
      } else {
        currentNodeId = nodeParent?.parentId;
      }
    }

    return undefined;
  }, [getIsNodeSelected, getNodeParent, nodeId]);

  const handleMouseLeave: MouseEventHandler = useCallback(() => {
    const { parentId } = getNodeParent(nodeId);
    // Just unhover the node
    setHoveredNode({ nodeId, isHovered: false, affectChildren: !parentId });
  }, [getNodeParent, nodeId, setHoveredNode]);

  const handleMouseMove: MouseEventHandler = useCallback(
    (event) => {
      const state = storeApi.getState();

      if (state.disableNodesInteraction || state.userSelectionActive) {
        return;
      }

      const nodeProperties = getNodeProperties();

      // In case we're dragging a node, we want to disable any hover as the hover will be determined by the intersection function
      if (!nodeProperties || nodeProperties.isDragged) {
        event.stopPropagation();
        return;
      }

      const isSelected = getIsNodeSelected(nodeId);
      const { parentId, parentComponent } = getNodeParent(nodeId);

      // If current node is selected, and we hover on top of it, we need to unhover its parent
      if (isSelected) {
        if (parentId && parentComponent) {
          // Parent is hovered, so unhover
          setHoveredNode({
            nodeId: parentId,
            isHovered: false,
            affectChildren: !parentComponent.parentId,
          });
          // We need to stop its propagation as the parent will receive mouseover and will re-trigger its hover
          event.stopPropagation();
        }
      }

      const nodeSiblings = getNodeSiblings();
      const nodeChildren = getNodeChildren();
      const selectedNodes = getSelectedNodes();
      const isDirectSelectionActive = isDirectSelection(event);
      const isMultiSelectionActive = isMultiSelection(event);

      if (
        shouldHover({
          isDirectSelectionActive,
          isMultiSelectionActive,
          isSelected,
          nodeChildren,
          nodeProperties,
          nodeSiblings,
          parentId,
          selectedNodes,
        })
      ) {
        // In most cases, when we're entering a node and node is a child, we are essentially still hovering the parent, so we need to unhover it
        if (parentId && parentComponent) {
          const hoveredNodesStack = getHoveredNodesStack();
          if (hoveredNodesStack.find((h) => h.id === parentId)) {
            // Parent is hovered, so unhover
            setHoveredNode({
              nodeId: parentId,
              isHovered: false,
              affectChildren: true,
            });
          }
        }
        setHoveredNode({
          nodeId: nodeId,
          isHovered: true,
          affectChildren: !parentId,
        });
        event.stopPropagation();
      }
      // if this node shouldn't be hovered, we need to check if we should hover one of its ancestors
      else {
        const hoverNode = getNodeToHover();

        if (hoverNode && !hoverNode.parentId) {
          setHoveredNode({
            nodeId: hoverNode.id,
            isHovered: true,
            affectChildren: !hoverNode.parentId,
          });
          event.stopPropagation();
        }
      }
    },
    [
      getIsNodeSelected,
      getNodeChildren,
      getNodeParent,
      getNodeProperties,
      getNodeSiblings,
      getNodeToHover,
      nodeId,
      setHoveredNode,
      shouldHover,
    ]
  );

  return { handleMouseLeave, handleMouseMove };
};
