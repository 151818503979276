import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

/**
 * Convert date to string that represent the time passed between the current time and the date given.
 * For example, lets say we provided the date 01/01/23 00:00 and the current date is 01/01/23 00:05, the result
 * will be "5 minutes ago"
 * If date is undefined will return 'a few seconds ago'.
 */
export const formatTimeDistance = (date: number | undefined) =>
  dayjs(date ? new Date(date) : undefined).fromNow();
