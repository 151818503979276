import {
  CommonActionsParams,
  JuxStore,
  JuxStoreActionFn,
  getDefaultCanvas,
} from '@jux/canjux/core';

/**
 * Delete a canvas by it's name
 */
export const deleteCanvas: JuxStoreActionFn<
  CommonActionsParams['deleteCanvas'],
  JuxStore
> = ({ canvasName, state }) => {
  const totalCanvases = Object.keys(state.canvases).length;
  if (totalCanvases === 1) {
    throw new Error('You cannot delete your only canvas');
  }
  const currentCanvas = state.canvases[state.currentCanvasName];
  if (currentCanvas.name === canvasName) {
    const defaultCanvas = getDefaultCanvas(state.canvases);
    state.currentCanvasName = defaultCanvas.name;
  }

  delete state.canvases[canvasName];

  return state;
};
