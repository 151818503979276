import { AssetData } from '@jux/data-entities';

export const getAssetDimensions = (
  assetData: AssetData
): {
  width: number;
  height: number;
} => {
  if (!assetData?.content) return { width: 0, height: 0 };

  const parser = new DOMParser();
  const svg = parser.parseFromString(assetData.content, 'image/svg+xml');
  const width = svg.documentElement.getAttribute('width');
  const height = svg.documentElement.getAttribute('height');

  return {
    width: width ? parseInt(width, 10) : 0,
    height: height ? parseInt(height, 10) : 0,
  };
};
