import { ButtonElementIcon } from './ButtonElementIcon';
import { CheckboxElementIcon } from './CheckboxElementIcon';
import { ChipElementIcon } from './ChipElementIcon';
import { DivElementIcon } from './DivElementIcon';
import { InputElementIcon } from './InputElementIcon';
import { RadioElementIcon } from './RadioElementIcon';
import { SvgElementIcon } from './SvgElementIcon';
import { TextElementIcon } from './TextElementIcon';
import { ToggleElementIcon } from './ToggleElementIcon';

export const elements = {
  ButtonElementIcon,
  CheckboxElementIcon,
  ChipElementIcon,
  DivElementIcon,
  InputElementIcon,
  RadioElementIcon,
  SvgElementIcon,
  TextElementIcon,
  ToggleElementIcon,
};
