import { FC } from 'react';

import {
  KeyboardPopper,
  KeyboardHeader,
  KeyboardCategory,
  KeyboardListItem,
  Subcategory,
} from '../imported/components';

export const KEYBOARD_SHORTCUT_POPOVER_ID = 'keyboard-shortcut-popover';
const CLOSE_ICON = `<svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.198 3.80199C11.9098 3.51372 11.4424 3.51372 11.1541 3.80199L8.00003 6.95607L4.84594 3.80199C4.55767 3.51372 4.09029 3.51372 3.80202 3.80199C3.51375 4.09026 3.51375 4.55764 3.80202 4.84591L6.95611 8L3.80202 11.1541C3.51375 11.4424 3.51375 11.9097 3.80202 12.198C4.09029 12.4863 4.55767 12.4863 4.84594 12.198L8.00003 9.04392L11.1541 12.198C11.4424 12.4863 11.9098 12.4863 12.198 12.198C12.4863 11.9097 12.4863 11.4424 12.198 11.1541L9.04395 8L12.198 4.84591C12.4863 4.55764 12.4863 4.09026 12.198 3.80199Z" fill="currentColor"/>
</svg>
`;

const BORDER_COLOR = '#E0E0E0';

const KeyboardShortcutItem = ({
  name,
  firstKey,
  secondKey,
}: {
  name: string;
  firstKey: string;
  secondKey: string;
}) => {
  return (
    <KeyboardListItem
      KeyboardListItem_text_Props={{ text: name }}
      KeyboardListItem_div_Props={{
        ShortcutKey_1_Props: {
          ShortcutKey_text_Props: {
            text: firstKey,
          },
        },
        KeyboardListItem_div_text_Props: { text: '+' },
        ShortcutKey_2_Props: {
          ShortcutKey_text_Props: {
            text: secondKey,
          },
        },
      }}
    />
  );
};

export const KeyboardPopover: FC<{
  onClick: () => void;
  modifierText: string;
}> = ({ onClick, modifierText }) => {
  return (
    <KeyboardPopper>
      <KeyboardHeader
        style={{
          borderBottom: `1px solid ${BORDER_COLOR}`,
        }}
        KeyboardHeader_text_Props={{ text: 'Keyboard shortcuts' }}
        IconButton_Props={{
          disabled: false,
          hierarchy: 'primary',
          IconButton_Icon_Props: {
            content: CLOSE_ICON,
            onClick,
          },
        }}
      />
      <KeyboardCategory>
        <Subcategory Subcategory_text_Props={{ text: 'Essential' }} />
        <KeyboardShortcutItem
          name="Copy"
          firstKey={modifierText}
          secondKey="c"
        />
        <KeyboardShortcutItem
          name="Paste"
          firstKey={modifierText}
          secondKey="v"
        />
        {/* TODO: remove once it works and has another label for 3 keys
        <KeyboardShortcutItem
          name="Undo"
          firstKey={modifierText}
          secondKey="z"
        />
        <KeyboardShortcutItem
          name="Redo"
          firstKey={modifierText}
          secondKey="z"
        /> */}
        <KeyboardShortcutItem
          name="Duplicate source"
          firstKey={modifierText}
          secondKey="d"
        />
        <KeyboardShortcutItem
          name="Wrap with div"
          firstKey="shift"
          secondKey="d"
        />
      </KeyboardCategory>

      <div style={{ border: `0.5px solid ${BORDER_COLOR}` }} />

      <KeyboardCategory
        Subcategory_Props={{ Subcategory_text_Props: { text: 'Selection' } }}
        KeyboardListItem_1_Props={{
          KeyboardListItem_text_Props: {
            text: 'Deep select',
          },
          KeyboardListItem_div_Props: {
            ShortcutKey_1_Props: {
              ShortcutKey_text_Props: {
                text: modifierText,
              },
            },
            KeyboardListItem_div_text_Props: {
              text: '+',
            },
            ShortcutKey_2_Props: {
              ShortcutKey_text_Props: {
                text: 'click',
              },
            },
          },
        }}
        KeyboardListItem_2_Props={{
          KeyboardListItem_text_Props: {
            text: 'Select none',
          },
          KeyboardListItem_div_Props: {
            ShortcutKey_1_Props: {
              ShortcutKey_text_Props: {
                text: 'esc',
              },
            },
            KeyboardListItem_div_text_Props: {
              style: { display: 'none' },
            },
            ShortcutKey_2_Props: {
              style: { display: 'none' },
            },
          },
        }}
        KeyboardListItem_3_Props={{
          KeyboardListItem_text_Props: {
            text: 'Select children',
          },
          KeyboardListItem_div_Props: {
            ShortcutKey_1_Props: {
              ShortcutKey_text_Props: {
                text: 'enter',
              },
            },
            KeyboardListItem_div_text_Props: {
              style: { display: 'none' },
            },
            ShortcutKey_2_Props: {
              style: { display: 'none' },
            },
          },
        }}
        KeyboardListItem_4_Props={{
          KeyboardListItem_text_Props: {
            text: 'Select parent',
          },
          KeyboardListItem_div_Props: {
            ShortcutKey_1_Props: {
              ShortcutKey_text_Props: {
                text: 'shift',
              },
            },
            KeyboardListItem_div_text_Props: {
              text: '+',
            },
            ShortcutKey_2_Props: {
              ShortcutKey_text_Props: {
                text: 'enter',
              },
            },
          },
        }}
      />
    </KeyboardPopper>
  );
};
