/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const FlexWrapDDPIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M6.95588 4C6.95588 3.58579 7.29167 3.25 7.70588 3.25C9.97358 3.25 11.75 5.16968 11.75 7.45946C11.75 9.74924 9.97358 11.6689 7.70588 11.6689H5.81066L6.44925 12.3075C6.74214 12.6004 6.74214 13.0753 6.44925 13.3682C6.15636 13.6611 5.68148 13.6611 5.38859 13.3682L3.46967 11.4492C3.17678 11.1564 3.17678 10.6815 3.46967 10.3886L5.38859 8.46967C5.68148 8.17678 6.15636 8.17678 6.44925 8.46967C6.74214 8.76256 6.74214 9.23744 6.44925 9.53033L5.81066 10.1689H7.70588C9.07677 10.1689 10.25 8.99089 10.25 7.45946C10.25 5.92803 9.07677 4.75 7.70588 4.75C7.29167 4.75 6.95588 4.41421 6.95588 4Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
