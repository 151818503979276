import { memo, useRef, FC } from 'react';
import cc from 'classcat';
import { useStore } from '@jux/canjux/core';

import { BackgroundProps, BackgroundVariant } from './Background.interface';
import { DotPattern, LinePattern } from './Patterns';
import { v4 } from 'uuid';

const defaultColor = {
  [BackgroundVariant.Dots]: '#91919a',
  [BackgroundVariant.Lines]: '#eee',
  [BackgroundVariant.Cross]: '#e2e2e2',
};

const defaultSize = {
  [BackgroundVariant.Dots]: 1,
  [BackgroundVariant.Lines]: 1,
  [BackgroundVariant.Cross]: 6,
};

const ZOOM_THRESHOLD_TO_SHOW_BACKGROUND = 4.0;
const ZOOM_THRESHOLD_TO_DARKEN_BACKGROUND = 6.0;
const BACKGROUND_OPACITY_LEVEL_1 = '30%';
const BACKGROUND_OPACITY_LEVEL_2 = '50%';
const PATTERN_ID = `pattern-${v4()}`;

export const Background: FC<BackgroundProps> = ({
  id,
  variant = BackgroundVariant.Lines,
  // only used for dots and cross
  gap = 20,
  // only used for lines and cross
  size,
  lineWidth = 1,
  offset = 2,
  color,
  style,
  className,
}) => {
  const ref = useRef<SVGSVGElement>(null);
  const transform = useStore((s) => s.transform);
  const patternColor = color || defaultColor[variant];
  const patternSize = size || defaultSize[variant];
  const isDots = variant === BackgroundVariant.Dots;
  const isCross = variant === BackgroundVariant.Cross;
  const gapXY: [number, number] = Array.isArray(gap) ? gap : [gap, gap];
  const scaledGap: [number, number] = [
    gapXY[0] * transform.zoom || 1,
    gapXY[1] * transform.zoom || 1,
  ];
  const scaledSize = patternSize * transform.zoom;

  const patternDimensions: [number, number] = isCross
    ? [scaledSize, scaledSize]
    : scaledGap;

  const patternOffset = isDots
    ? [scaledSize / offset, scaledSize / offset]
    : [patternDimensions[0] / offset, patternDimensions[1] / offset];

  if (transform.zoom < ZOOM_THRESHOLD_TO_SHOW_BACKGROUND) {
    return null;
  }

  return (
    <svg
      className={cc(['canjux__background', className])}
      style={{
        ...style,
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity:
          transform.zoom < ZOOM_THRESHOLD_TO_DARKEN_BACKGROUND
            ? BACKGROUND_OPACITY_LEVEL_1
            : BACKGROUND_OPACITY_LEVEL_2,
        top: 0,
        left: 0,
      }}
      ref={ref}
      data-testid="rf__background"
    >
      <pattern
        id={PATTERN_ID + id}
        x={transform.x % scaledGap[0]}
        y={transform.y % scaledGap[1]}
        width={scaledGap[0]}
        height={scaledGap[1]}
        patternUnits="userSpaceOnUse"
        patternTransform={`translate(-${patternOffset[0]},-${patternOffset[1]})`}
      >
        {isDots ? (
          <DotPattern color={patternColor} radius={scaledSize / offset} />
        ) : (
          <LinePattern
            dimensions={patternDimensions}
            color={patternColor}
            lineWidth={lineWidth}
          />
        )}
      </pattern>
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill={`url(#${PATTERN_ID + id})`}
      />
    </svg>
  );
};

Background.displayName = 'Background';

export default memo(Background);
