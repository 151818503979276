import { useCallback } from 'react';
import { useZodForm } from '@jux/ui/hooks/useZodForm';
import { createPath } from '@jux/ui/utils/tokensPath';

/**
 * @description This hook is used to validate that the token name is unique,
 * i.e. that it doesn't already exist in the group path.
 */
export const useValidateTokenName = ({
  form,
  existingTokenPaths,
}: {
  form: ReturnType<typeof useZodForm>;
  existingTokenPaths: Array<string>;
}) =>
  useCallback(() => {
    if (
      createPath([form.getValues().groupPath, form.getValues().tokenName]) !==
        createPath([
          form.getValues().previousGroupPath,
          form.getValues().previousTokenName,
        ]) &&
      existingTokenPaths.includes(
        createPath([form.getValues().groupPath, form.getValues().tokenName])
      )
    ) {
      form.setError('tokenName', {
        message: 'Token name already exists',
      });

      return false;
    }

    return true;
  }, [existingTokenPaths, form]);
