import logger from '@jux/ui-logger';
import { SupportedStyles } from '../utils';
import {
  DDPPrimitiveFieldsKeys,
  DDPPrimitiveFieldsState,
} from './useDDPFieldsState.interface';

const isFieldStateValid = (
  fieldState: any
): fieldState is DDPPrimitiveFieldsState => {
  const isValid = Object.values(DDPPrimitiveFieldsKeys).every((key) => {
    const field = fieldState[key];

    if (!field) {
      return false;
    }

    const fieldKeys = Object.keys(field);

    return fieldKeys.includes('value') && fieldKeys.includes('initialValue');
  });

  return isValid;
};

export const getDDPFieldsStateFromStyles = (
  styles: SupportedStyles,
  initialStyles: SupportedStyles
): DDPPrimitiveFieldsState | undefined => {
  const fieldState = Object.values(DDPPrimitiveFieldsKeys).reduce(
    (acc, p) => ({
      ...acc,
      [p]: { value: styles[p], initialValue: initialStyles[p] },
    }),
    {}
  );

  const isValid = isFieldStateValid(fieldState);

  if (!isValid) {
    logger.warn(`couldn't create DDPFieldsState, fieldState:`, fieldState);

    return;
  }

  return fieldState;
};
