import { useEffect } from 'react';
import { OverlaysKeys, useOverlaysState } from '@jux/ui/state';

export enum Floors {
  content,
  drawer,
  innerPoppers,
  innerNavbar,
  navbar,
  sidebar,
  sidebarPopper,
  poppers,
  tokenDrawer,
  modals,
  dialogs,
  tooltip,
  tokenType,
  loading,
}

const BASE_Z_INDEX_BY_FLOOR: Record<Floors, number> = {
  [Floors.content]: 100,
  [Floors.drawer]: 1000,
  [Floors.innerPoppers]: 1000,
  [Floors.tokenType]: 2000,
  [Floors.innerNavbar]: 3000,
  [Floors.navbar]: 5000,
  [Floors.tokenDrawer]: 5000,
  [Floors.sidebar]: 6000,
  [Floors.poppers]: 6000,
  [Floors.sidebarPopper]: 6500,
  [Floors.modals]: 10000,
  [Floors.dialogs]: 10000,
  [Floors.tooltip]: 15000,
  [Floors.loading]: 20000,
};
const Z_INDEX_STEP = 1;

export const useOverlaysZIndex = ({
  id,
  floor,
}: {
  id: OverlaysKeys;
  floor: Floors;
}) => {
  const { overlays, addOverlay, removeOverlay } = useOverlaysState();

  const overlayIndex = overlays.indexOf(id);
  const zIndex = BASE_Z_INDEX_BY_FLOOR[floor] + overlayIndex * Z_INDEX_STEP;

  useEffect(() => {
    addOverlay(id);

    return () => {
      removeOverlay(id);
    };
  }, [id, addOverlay, removeOverlay]);

  return zIndex;
};
