import { styled } from '@jux/ui/components/common/mui';
import {
  EDITOR_DDP_FIELD_BORDER_COLOR,
  EDITOR_DDP_FIELD_FOCUS_BORDER_COLOR,
  EDITOR_DDP_FIELD_HOVER_BORDER_COLOR,
} from '@jux/ui/theme/palette';
import { Input } from '../../../../base';

export const TextContentLabel = styled('label')(() => ({
  '&:hover input': { borderColor: EDITOR_DDP_FIELD_HOVER_BORDER_COLOR },
  '& input:focus': { borderColor: EDITOR_DDP_FIELD_FOCUS_BORDER_COLOR },
}));

export const TextContentInput = styled(Input)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.drimz.size.borderRadius.tiny,
  border: `1px solid`,
  borderColor: EDITOR_DDP_FIELD_BORDER_COLOR,
  padding: '6.5px 8px',
  height: theme.drimz.size.editor.ddp.textContent.height,
}));
