import { FC } from 'react';
import { DimensionField } from '../../../../base';
import { useConstraintsFields } from './useConstraintsFields';

export const RightField: FC = () => {
  const { rightFieldProps } = useConstraintsFields();

  return (
    <DimensionField
      icon="DDP_FIELD_CONSTRAINTS_RIGHT"
      tooltipTitle="Right"
      {...rightFieldProps}
    />
  );
};
