import { MuiGrid, styled } from '@jux/ui/components/common/mui';
import { withPx } from '@jux/ui/utils/css';
import { DropPosition, DropPositionType } from '../../types';
import { calcIndent } from './helpers/calcIndent.utils';

const BASE_INDENT = 4;
const ARROW_WIDTH = 16;
const GAP = 8;
const BASE_PADDING = BASE_INDENT + ARROW_WIDTH + GAP;

const LINE_HEIGHT = 2;

export const LineWrapper = styled(MuiGrid, {
  shouldForwardProp: (prop: string) =>
    !['indentLevel', 'dropPosition'].includes(prop),
})<{
  indentLevel: number;
  dropPosition: DropPositionType;
}>(({ indentLevel, dropPosition, theme }) => {
  const leftIndent = withPx(
    calcIndent({
      depth: indentLevel,
      basePadding: BASE_PADDING,
    })
  );

  const isAbove = dropPosition === DropPosition.above;
  const isBelow = dropPosition === DropPosition.below;

  const top = isAbove ? 0 : isBelow ? '100%' : 0;

  const marginTop = withPx(
    isAbove ? -LINE_HEIGHT / 2 : isBelow ? LINE_HEIGHT / 2 : 0
  );

  return {
    width: `calc(100% - ${leftIndent})`,
    height: LINE_HEIGHT,
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    pointerEvents: 'none',
    zIndex: 1,
    left: leftIndent,
    top,
    marginTop,
  };
});
