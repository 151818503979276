import { FC } from 'react';
import { Icons, IconsProps } from '@jux/ui/components';
import { NodeType } from '@jux/data-entities';
import { getIconsVariant } from './helpers';

const DEFAULT_ICON_SIZE = 16;

export const ObjectNavigatorItemTypeIcon: FC<
  Omit<IconsProps, 'variant'> & {
    objectType: NodeType;
    tagName: string;
    isInstanceToSourceComponent?: boolean;
  }
> = ({ objectType, tagName, isInstanceToSourceComponent, ...props }) => (
  <Icons
    size={DEFAULT_ICON_SIZE}
    {...props}
    variant={getIconsVariant({
      objectType,
      tagName,
      isInstanceToSourceComponent,
    })}
  />
);
