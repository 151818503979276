import { useCallback } from 'react';
import {
  CanvasMode,
  useCanvasModeState,
} from '@jux/ui/components/editor/state';

export const useCanvasLiveOrEdit = () => {
  const { mode, setMode } = useCanvasModeState();

  const setLive = useCallback(() => {
    setMode(CanvasMode.Live);
  }, [setMode]);

  const setEdit = useCallback(() => {
    setMode(CanvasMode.Edit);
  }, [setMode]);

  return {
    isLive: mode === CanvasMode.Live,
    isEdit: mode === CanvasMode.Edit,
    setLive,
    setEdit,
  };
};
