/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const JustifyContentFlexEndRowDDPIcon: IconType = (
  props: IconBaseProps
) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M11 2.5C11 2.22386 11.2239 2 11.5 2C11.7761 2 12 2.22386 12 2.5V13.5C12 13.7761 11.7761 14 11.5 14C11.2239 14 11 13.7761 11 13.5V2.5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M9 10C9 10.5523 8.55229 11 8 11C7.44772 11 7 10.5523 7 10L7 6C7 5.44772 7.44772 5 8 5C8.55228 5 9 5.44772 9 6L9 10Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M5 11C5.55229 11 6 10.5523 6 10L6 6C6 5.44772 5.55228 5 5 5C4.44771 5 4 5.44772 4 6V10C4 10.5523 4.44772 11 5 11Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
