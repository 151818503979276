import { atom, useAtomValue, useSetAtom } from 'jotai';

export type AnalyticsContext = object;

const analyticsContextAtom = atom<AnalyticsContext | undefined>(
  undefined as unknown as object
);

export const useSetAnalyticsContext = () => useSetAtom(analyticsContextAtom);

export const useAnalyticsContextValue = () =>
  useAtomValue(analyticsContextAtom);
