import { FC } from 'react';
import { DimensionField } from '../../../../base';
import { useMarginFields } from './useMarginFields';

export const MarginLeftField: FC = () => {
  const { marginLeftField, isComputed } = useMarginFields();

  return (
    <DimensionField
      icon="DDP_FIELD_MARGIN_LEFT"
      tooltipTitle="Margin left"
      {...marginLeftField}
      isComputed={isComputed}
    />
  );
};
