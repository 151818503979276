import { useCallback } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import { useSelectedNodeId } from '@jux/ui/components/editor/hooks';

export const useCreateComponent = () => {
  const selectedNodeId = useSelectedNodeId();
  const {
    componentActions: { createComponentFromElement },
  } = useStoreActions();

  const handleCreateComponent = useCallback(async () => {
    if (!selectedNodeId) return;

    return createComponentFromElement({
      nodeId: selectedNodeId,
    });
  }, [createComponentFromElement, selectedNodeId]);

  return {
    handleCreateComponent,
  };
};
