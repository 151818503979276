import { useCallback } from 'react';
import {
  useSelectedComponentsTabsState,
  ComponentsTabs,
} from './useSelectedComponentsTabsState';

export const useSelectedComponentsTabs = () => {
  const { selectedComponentsTab, setSelectedComponentsTabs } =
    useSelectedComponentsTabsState();

  const setLocalComponents = useCallback(() => {
    setSelectedComponentsTabs(ComponentsTabs.local);
  }, [setSelectedComponentsTabs]);

  const setLibraryComponents = useCallback(() => {
    setSelectedComponentsTabs(ComponentsTabs.library);
  }, [setSelectedComponentsTabs]);

  return {
    isLocalComponents: selectedComponentsTab === ComponentsTabs.local,
    isLibraryComponents: selectedComponentsTab === ComponentsTabs.library,
    setLocalComponents,
    setLibraryComponents,
  };
};
