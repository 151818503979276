import { storeApi } from '@jux/canjux/core';
import { DesignTokensParser } from '@jux/design-tokens';

// This is used by token form handlers to return updated token data according to the editor/DDP usage
// TODO: Check if this is still needed
export const getUpdatedTokenData = ({
  tokenPath,
  tokenSetId,
}: {
  tokenPath: string;
  tokenSetId?: string;
}) => {
  const { tokenSets } = storeApi.getState();
  const tokenSet = Object.values(tokenSets).find(
    (d) => !tokenSetId || d.id === tokenSetId
  )?.value;

  return {
    name: tokenPath,
    value: new DesignTokensParser(tokenSet || {}).getTokenRawValue(
      tokenPath,
      true
    ),
  };
};
