import { RefObject, useCallback } from 'react';
import { PoppersKey, usePopper } from '@jux/ui/components';
import { ElementsPopper } from '../components';

export const useElementsPopper = () => {
  const { open, isOpen } = usePopper(PoppersKey.elements);

  const openElementsPopper = useCallback(
    (sourceElementRef: RefObject<HTMLElement>) => {
      open({
        content: <ElementsPopper />,
        sourceElement: sourceElementRef.current,
      });
    },
    [open]
  );

  return {
    openElementsPopper,
    isElementPopperOpen: isOpen(),
  };
};
