import { IoAlertCircleOutline, IoWarningOutline } from 'react-icons/io5';

import { CUSTOM_ICONS } from './custom';

export const IconsVariants = {
  ALERT: IoAlertCircleOutline,
  WARN: IoWarningOutline,

  ARROW_POINTS_DOWN: CUSTOM_ICONS.ArrowPointsDownIcon,
  ARROW_POINTS_UP: CUSTOM_ICONS.ArrowPointsUpIcon,
  ARROW_POINTS_LEFT: CUSTOM_ICONS.ArrowPointsLeftIcon,
  ARROW_POINTS_RIGHT: CUSTOM_ICONS.ArrowPointsRightIcon,

  STATUS_FAIL: CUSTOM_ICONS.StatusFailIcon,
  STATUS_SUCCESS: CUSTOM_ICONS.StatusSuccessIcon,

  ADD: CUSTOM_ICONS.AddIcon,
  DELETE: CUSTOM_ICONS.DeleteIcon,
  CLOSE: CUSTOM_ICONS.CloseIcon,
  CLOSE_SMALL: CUSTOM_ICONS.CloseSmallIcon,
  DOTS_MENU: CUSTOM_ICONS.DotsThreeVerticalIcon,
  DOTS_MENU_HORIZONTAL: CUSTOM_ICONS.DotsThreeHorizontalIcon,
  EDIT: CUSTOM_ICONS.EditIcon,
  CLEAR: CUSTOM_ICONS.ClearIcon,
  SEARCH: CUSTOM_ICONS.SearchIcon,
  CHECKMARK: CUSTOM_ICONS.CheckmarkIcon,
  DROPDOWN_ARROW: CUSTOM_ICONS.DropdownArrowIcon,
  MUI_DROPDOWN_ARROW: CUSTOM_ICONS.MuiSelectArrowDDPIcon,

  ABSTRACT_IMAGE: CUSTOM_ICONS.AbstractImageIcon,

  UPLOAD: CUSTOM_ICONS.UploadIcon,

  // Navbar
  INVITE_USER: CUSTOM_ICONS.InviteUserIcon,
  DOCUMENTATION: CUSTOM_ICONS.DocumentationIcon,
  DONE: CUSTOM_ICONS.DoneIcon,
  ARROW_BACK: CUSTOM_ICONS.ArrowBackIcon,

  // Sidebar
  TOKENS: CUSTOM_ICONS.TokensIcon,

  // Tokens management
  FOLDER: CUSTOM_ICONS.FolderIcon,
  FOLDER_LOCKED: CUSTOM_ICONS.FolderLockedIcon,

  // Tokens
  ATOM: CUSTOM_ICONS.AtomIcon,
  ALIAS: CUSTOM_ICONS.AliasIcon,
  ALIAS_INSIDE: CUSTOM_ICONS.AliasInsideIcon,
  COMPOSITE: CUSTOM_ICONS.CompositeIcon,

  // Tokens types
  COLOR: CUSTOM_ICONS.ColorIcon,
  TYPOGRAPHY: CUSTOM_ICONS.TypographyIcon,
  DIMENSION: CUSTOM_ICONS.DimensionIcon,
  FONT_FAMILY: CUSTOM_ICONS.FontFamilyIcon,
  BORDER: CUSTOM_ICONS.BorderIcon,

  // Magnifier
  ZOOM_IN_VIEW: CUSTOM_ICONS.ZoomInViewIcon,
  ZOOM_OUT_VIEW: CUSTOM_ICONS.ZoomOutViewIcon,

  RESET_VIEW: CUSTOM_ICONS.ResetViewIcon,
  FIT_VIEW: CUSTOM_ICONS.FitViewIcon,

  // LOGOs
  JUX_LONG_LOGO: CUSTOM_ICONS.JUXLongLogoIcon,
  JUX_SHORT_LOGO: CUSTOM_ICONS.JUXShortLogoIcon,

  // Navigator - Tabs
  ELEMENTS: CUSTOM_ICONS.ElementsIcon,
  COMPONENTS: CUSTOM_ICONS.ComponentsIcon,
  ASSETS: CUSTOM_ICONS.AssetsIcon,

  // Elements Panel
  ELEMENTS_PANEL_BUTTON: CUSTOM_ICONS.ButtonElementPanelIcon,
  ELEMENTS_PANEL_CHECKBOX: CUSTOM_ICONS.CheckboxElementPanelIcon,
  ELEMENTS_PANEL_CHECKBOX_FIELD: CUSTOM_ICONS.CheckboxFieldElementPanelIcon,
  ELEMENTS_PANEL_CHIP: CUSTOM_ICONS.ChipElementPanelIcon,
  ELEMENTS_PANEL_DIV: CUSTOM_ICONS.DivElementPanelIcon,
  ELEMENTS_PANEL_DIVIDER: CUSTOM_ICONS.DividerElementPanelIcon,
  ELEMENTS_PANEL_ICON_BUTTON: CUSTOM_ICONS.IconButtonElementPanelIcon,
  ELEMENTS_PANEL_INPUT: CUSTOM_ICONS.InputElementPanelIcon,
  ELEMENTS_PANEL_TEXT_FIELD: CUSTOM_ICONS.TextFieldElementPanelIcon,
  ELEMENTS_PANEL_RADIO: CUSTOM_ICONS.RadioElementPanelIcon,
  ELEMENTS_PANEL_TEXT: CUSTOM_ICONS.TextElementPanelIcon,
  ELEMENTS_PANEL_TEXTAREA: CUSTOM_ICONS.TextareaElementPanelIcon,
  ELEMENTS_PANEL_TEXTAREA_FIELD: CUSTOM_ICONS.TextareaFieldElementPanelIcon,
  ELEMENTS_PANEL_TOGGLE: CUSTOM_ICONS.ToggleElementPanelIcon,

  // Elements Objects
  BUTTON_ELEMENT: CUSTOM_ICONS.ButtonElementIcon,
  CHECKBOX_ELEMENT: CUSTOM_ICONS.CheckboxElementIcon,
  CHIP_ELEMENT: CUSTOM_ICONS.ChipElementIcon,
  DIV_ELEMENT: CUSTOM_ICONS.DivElementIcon,
  INPUT_ELEMENT: CUSTOM_ICONS.InputElementIcon,
  RADIO_ELEMENT: CUSTOM_ICONS.RadioElementIcon,
  SVG_ELEMENT: CUSTOM_ICONS.SvgElementIcon,
  TEXT_ELEMENT: CUSTOM_ICONS.TextElementIcon,
  TOGGLE_ELEMENT: CUSTOM_ICONS.ToggleElementIcon,

  // Objects
  COMPONENT_OBJECT: CUSTOM_ICONS.ComponentObjectIcon,
  COMPONENT_INSTANCE_OBJECT: CUSTOM_ICONS.ComponentInstanceObjectIcon,

  // DDP
  DDP_COLLAPSED_MODULE: CUSTOM_ICONS.CollapsedModuleDDPIcon,
  DDP_EXPANDED_MODULE: CUSTOM_ICONS.ExpandedModuleDDPIcon,
  DDP_UNIFIED_SIDES_SUB_MODULE: CUSTOM_ICONS.UnifiedSidesSubModuleDDPIcon,
  DDP_SEPARATED_SUB_MODULE: CUSTOM_ICONS.SeparatedSidesSubModuleDDPIcon,
  DDP_TOKEN: CUSTOM_ICONS.TokenDDPIcon,
  DDP_DETACH_TOKEN: CUSTOM_ICONS.DetachTokenDDPIcon,

  // DDP - Fields
  DDP_FIELD_WIDTH: CUSTOM_ICONS.WidthDDPIcon,
  DDP_FIELD_MIN_WIDTH: CUSTOM_ICONS.MinWidthDDPIcon,
  DDP_FIELD_MAX_WIDTH: CUSTOM_ICONS.MaxWidthDDPIcon,
  DDP_FIELD_HEIGHT: CUSTOM_ICONS.HeightDDPIcon,
  DDP_FIELD_MIN_HEIGHT: CUSTOM_ICONS.MinHeightDDPIcon,
  DDP_FIELD_MAX_HEIGHT: CUSTOM_ICONS.MaxHeightDDPIcon,
  DDP_FIELD_PADDING_ALL: CUSTOM_ICONS.PaddingAllDDPIcon,
  DDP_FIELD_PADDING_TOP: CUSTOM_ICONS.PaddingTopDDPIcon,
  DDP_FIELD_PADDING_RIGHT: CUSTOM_ICONS.PaddingRightDDPIcon,
  DDP_FIELD_PADDING_BOTTOM: CUSTOM_ICONS.PaddingBottomDDPIcon,
  DDP_FIELD_PADDING_LEFT: CUSTOM_ICONS.PaddingLeftDDPIcon,
  DDP_FIELD_MARGIN_ALL: CUSTOM_ICONS.MarginAllDDPIcon,
  DDP_FIELD_MARGIN_TOP: CUSTOM_ICONS.MarginTopDDPIcon,
  DDP_FIELD_MARGIN_RIGHT: CUSTOM_ICONS.MarginRightDDPIcon,
  DDP_FIELD_MARGIN_BOTTOM: CUSTOM_ICONS.MarginBottomDDPIcon,
  DDP_FIELD_MARGIN_LEFT: CUSTOM_ICONS.MarginLeftDDPIcon,
  DDP_FIELD_OPACITY: CUSTOM_ICONS.OpacityDDPIcon,
  DDP_FIELD_TYPOGRAPHY: CUSTOM_ICONS.TypographyDDPIcon,
  DDP_FIELD_FONT_FAMILY: CUSTOM_ICONS.FontFamilyDDPIcon,
  DDP_FIELD_FONT_SIZE: CUSTOM_ICONS.FontSizeDDPIcon,
  DDP_FIELD_FONT_WEIGHT: CUSTOM_ICONS.FontWeightDDPIcon,
  DDP_FIELD_LETTER_SPACING: CUSTOM_ICONS.LetterSpacingDDPIcon,
  DDP_FIELD_LINE_HEIGHT: CUSTOM_ICONS.LineHeightDDPIcon,
  DDP_FIELD_TEXT_ALIGN_LEFT: CUSTOM_ICONS.AlignLeftDDPIcon,
  DDP_FIELD_TEXT_ALIGN_CENTER: CUSTOM_ICONS.AlignCenterDDPIcon,
  DDP_FIELD_TEXT_ALIGN_RIGHT: CUSTOM_ICONS.AlignRightDDPIcon,
  DDP_FIELD_TEXT_ALIGN_JUSTIFY: CUSTOM_ICONS.AlignJustifyDDPIcon,
  DDP_FIELD_LTR_DIRECTION: CUSTOM_ICONS.LTRDirectionDDPIcon,
  DDP_FIELD_RTL_DIRECTION: CUSTOM_ICONS.RTLDirectionDDPIcon,
  DDP_FIELD_TEXT_COLOR: CUSTOM_ICONS.TextColorDDPIcon,
  DDP_FIELD_TEXT_MIXED_COLOR: CUSTOM_ICONS.TextMixedColorDDPIcon,
  DDP_FIELD_DISPLAY_BLOCK: CUSTOM_ICONS.DisplayBlockDDPIcon,
  DDP_FIELD_DISPLAY_FLEX: CUSTOM_ICONS.DisplayFlexDDPIcon,
  DDP_FIELD_DISPLAY_INLINE: CUSTOM_ICONS.DisplayInlineDDPIcon,
  DDP_FIELD_DISPLAY_INLINE_BLOCK: CUSTOM_ICONS.DisplayInlineBlockDDPIcon,
  DDP_FIELD_DISPLAY_INLINE_FLEX: CUSTOM_ICONS.DisplayInlineFlexDDPIcon,
  DDP_FIELD_DISPLAY_NONE: CUSTOM_ICONS.DisplayNoneDDPIcon,
  DDP_FIELD_FLEX_DIRECTION_COLUMN: CUSTOM_ICONS.FlexDirectionColumnDDPIcon,
  DDP_FIELD_FLEX_DIRECTION_ROW: CUSTOM_ICONS.FlexDirectionRowDDPIcon,
  DDP_FIELD_FLEX_DIRECTION_REVERSE: CUSTOM_ICONS.FlexDirectionReverseDDPIcon,
  DDP_FIELD_FLEX_WRAP: CUSTOM_ICONS.FlexWrapDDPIcon,
  DDP_FIELD_ALIGN_ITEMS_FLEX_START_COLUMN:
    CUSTOM_ICONS.AlignItemsFlexStartColumnDDPIcon,
  DDP_FIELD_ALIGN_ITEMS_CENTER_COLUMN:
    CUSTOM_ICONS.AlignItemsCenterColumnDDPIcon,
  DDP_FIELD_ALIGN_ITEMS_FLEX_END_COLUMN:
    CUSTOM_ICONS.AlignItemsFlexEndColumnDDPIcon,
  DDP_FIELD_ALIGN_ITEMS_STRETCH_COLUMN:
    CUSTOM_ICONS.AlignItemsStretchColumnDDPIcon,
  DDP_FIELD_ALIGN_ITEMS_BASELINE_COLUMN:
    CUSTOM_ICONS.AlignItemsBaselineColumnDDPIcon,
  DDP_FIELD_JUSTIFY_CONTENT_FLEX_START_COLUMN:
    CUSTOM_ICONS.JustifyContentFlexStartColumnDDPIcon,
  DDP_FIELD_JUSTIFY_CONTENT_CENTER_COLUMN:
    CUSTOM_ICONS.JustifyContentCenterColumnDDPIcon,
  DDP_FIELD_JUSTIFY_CONTENT_FLEX_END_COLUMN:
    CUSTOM_ICONS.JustifyContentFlexEndColumnDDPIcon,
  DDP_FIELD_JUSTIFY_CONTENT_SPACE_AROUND_COLUMN:
    CUSTOM_ICONS.JustifyContentSpaceAroundColumnDDPIcon,
  DDP_FIELD_JUSTIFY_CONTENT_SPACE_BETWEEN_COLUMN:
    CUSTOM_ICONS.JustifyContentSpaceBetweenColumnDDPIcon,
  DDP_FIELD_ALIGN_ITEMS_FLEX_START_ROW:
    CUSTOM_ICONS.AlignItemsFlexStartRowDDPIcon,
  DDP_FIELD_ALIGN_ITEMS_CENTER_ROW: CUSTOM_ICONS.AlignItemsCenterRowDDPIcon,
  DDP_FIELD_ALIGN_ITEMS_FLEX_END_ROW: CUSTOM_ICONS.AlignItemsFlexEndRowDDPIcon,
  DDP_FIELD_ALIGN_ITEMS_STRETCH_ROW: CUSTOM_ICONS.AlignItemsStretchRowDDPIcon,
  DDP_FIELD_ALIGN_ITEMS_BASELINE_ROW: CUSTOM_ICONS.AlignItemsBaselineRowDDPIcon,
  DDP_FIELD_JUSTIFY_CONTENT_FLEX_START_ROW:
    CUSTOM_ICONS.JustifyContentFlexStartRowDDPIcon,
  DDP_FIELD_JUSTIFY_CONTENT_CENTER_ROW:
    CUSTOM_ICONS.JustifyContentCenterRowDDPIcon,
  DDP_FIELD_JUSTIFY_CONTENT_FLEX_END_ROW:
    CUSTOM_ICONS.JustifyContentFlexEndRowDDPIcon,
  DDP_FIELD_JUSTIFY_CONTENT_SPACE_AROUND_ROW:
    CUSTOM_ICONS.JustifyContentSpaceAroundRowDDPIcon,
  DDP_FIELD_JUSTIFY_CONTENT_SPACE_BETWEEN_ROW:
    CUSTOM_ICONS.JustifyContentSpaceBetweenRowDDPIcon,
  DDP_FIELD_ROW_GAP: CUSTOM_ICONS.RowGapDDPIcon,
  DDP_FIELD_COLUMN_GAP: CUSTOM_ICONS.ColumnGapDDPIcon,
  DDP_FIELD_BORDER_RADIUS: CUSTOM_ICONS.BorderRadiusAllDDPIcon,
  DDP_FIELD_BORDER_WIDTH: CUSTOM_ICONS.BorderWidthDDPIcon,
  DDP_FIELD_BORDER_STYLE: CUSTOM_ICONS.BorderStyleDDPIcon,
  DDP_FIELD_COLOR: CUSTOM_ICONS.TextColorDDPIcon,
  DDP_FIELD_BOX_SHADOW_SPREAD: CUSTOM_ICONS.BoxShadowSpreadDDPIcon,
  DDP_FIELD_BOX_SHADOW_X: CUSTOM_ICONS.BoxShadowXDDPIcon,
  DDP_FIELD_BOX_SHADOW_Y: CUSTOM_ICONS.BoxShadowYDDPIcon,
  DDP_FIELD_BOX_SHADOW_BLUR: CUSTOM_ICONS.BoxShadowBlurDDPIcon,
  DDP_FIELD_BOX_SHADOW_INSET_TRUE: CUSTOM_ICONS.BoxShadowInsetTrueDDPIcon,
  DDP_FIELD_BOX_SHADOW_INSET_FALSE: CUSTOM_ICONS.BoxShadowInsetFalseDDPIcon,
  DDP_FIELD_BOX_SHADOW: CUSTOM_ICONS.BoxShadowDDPIcon,
  DDP_FIELD_NONE_COLOR_INDICATOR: CUSTOM_ICONS.NoneColorIndicatorDDPIcon,
  DDP_FIELD_POSITION_ABSOLUTE: CUSTOM_ICONS.PositionAbsoluteDDPIcon,
  DDP_FIELD_POSITION_RELATIVE: CUSTOM_ICONS.PositionRelativeDDPIcon,
  DDP_FIELD_POSITION_STATIC: CUSTOM_ICONS.PositionStaticDDPIcon,
  DDP_FIELD_RELATIVE_TO: CUSTOM_ICONS.RelativeToDDPIcon,
  DDP_FIELD_CONSTRAINTS_TOP: CUSTOM_ICONS.ConstraintsTopDDPIcon,
  DDP_FIELD_CONSTRAINTS_RIGHT: CUSTOM_ICONS.ConstraintsRightDDPIcon,
  DDP_FIELD_CONSTRAINTS_BOTTOM: CUSTOM_ICONS.ConstraintsBottomDDPIcon,
  DDP_FIELD_CONSTRAINTS_LEFT: CUSTOM_ICONS.ConstraintsLeftDDPIcon,
  DDP_FIELD_CONSTRAINTS_ALL: CUSTOM_ICONS.ConstraintsAllDDPIcon,
  DDP_FIELD_Z_INDEX: CUSTOM_ICONS.ZIndexDDPIcon,

  // Layers Panel
  LAYERS_PANEL_COLLAPSE_ALL: CUSTOM_ICONS.ContractIcon,
  LAYERS_PANEL_EXPAND_ALL: CUSTOM_ICONS.ExpandIcon,
  LAYERS_PANEL_DROP_ARROW_CLOSED: CUSTOM_ICONS.DropArrowClosedIcon,
  LAYERS_PANEL_DROP_ARROW_OPENED: CUSTOM_ICONS.DropArrowOpenedIcon,
  LAYERS_PANEL_ITEM_ASSET: CUSTOM_ICONS.AssetLayerIcon,
  LAYERS_PANEL_ITEM_CHECKBOX: CUSTOM_ICONS.CheckboxLayerIcon,
  LAYERS_PANEL_ITEM_CHIP: CUSTOM_ICONS.ChipLayerIcon,
  LAYERS_PANEL_ITEM_COMPONENT: CUSTOM_ICONS.ComponentObjectIcon,
  LAYERS_PANEL_ITEM_COMPONENT_INSTANCE:
    CUSTOM_ICONS.ComponentInstanceObjectIcon,
  LAYERS_PANEL_ITEM_INSTANCE: CUSTOM_ICONS.InstanceLayerIcon,
  LAYERS_PANEL_ITEM_RADIO_BUTTON: CUSTOM_ICONS.RadioLayerIcon,
  LAYERS_PANEL_ITEM_SINGLE_VARIANT: CUSTOM_ICONS.SingleVariantLayerIcon,
  LAYERS_PANEL_ITEM_TOGGLE: CUSTOM_ICONS.ToggleLayerIcon,
  LAYERS_PANEL_ITEM_TOOLTIP: CUSTOM_ICONS.TooltipLayerIcon,
  LAYERS_PANEL_ITEM_HIDDEN: CUSTOM_ICONS.HiddenLayerIcon,
  LAYERS_PANEL_LOGICAL_SLOT: CUSTOM_ICONS.LogicalSlotLayerIcon,

  // Tooltip
  TOOLTIP_ARROW_TARGET_ICON: CUSTOM_ICONS.ArrowTargetIcon,
};
