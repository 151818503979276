import { useCallback } from 'react';
import { useAnalytics } from '@jux/ui/hooks';
import { SegmentEvent } from '@jux/events/segment/segment.events';
import { SegmentEventSourcePage } from '@jux/events/segment/segment.interface';

export const useTrackInviteMembers = (sourcePage: SegmentEventSourcePage) => {
  const { trackEvent } = useAnalytics();

  const isSourcePageDashboard = sourcePage === 'dashboard';

  const trackOpenInviteMembers = useCallback(() => {
    trackEvent({
      eventName: SegmentEvent.CLICK,
      properties: {
        elementPath:
          sourcePage === 'dashboard'
            ? 'dashboard/sidebar/invite'
            : 'editor/navbar/invite',
        clickSourcePage: sourcePage,
      },
    });
  }, [sourcePage, trackEvent]);

  const trackSendInviteMembers = useCallback(() => {
    trackEvent({
      eventName: SegmentEvent.CLICK,
      properties: {
        elementPath:
          sourcePage === 'dashboard'
            ? 'dashboard/sidebar/invite/sent'
            : 'editor/navbar/invite/sent',
        clickSourcePage: sourcePage,
      },
    });
  }, [sourcePage, trackEvent]);

  return {
    trackOpenInviteMembers,
    trackSendInviteMembers,
    isSourcePageDashboard,
  };
};
