import { ComponentProps, forwardRef } from 'react';
import { Icons } from '@jux/ui/components';
import { styled } from '@jux/ui/components/common/mui';

const NoneColorIndicatorWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.drimz.palette.background.default,
  border: '1px solid',
  borderColor: theme.drimz.palette.divider.primary,
  borderRadius: theme.drimz.size.borderRadius.small,
  width: '12px',
  height: '12px',
  cursor: 'pointer',
  padding: '0',
  position: 'relative',
  overflow: 'hidden',
}));

export const NoneColorIndicator = forwardRef<
  HTMLDivElement,
  ComponentProps<typeof NoneColorIndicatorWrapper>
>((props, ref) => (
  <NoneColorIndicatorWrapper ref={ref} {...props}>
    <Icons
      variant="DDP_FIELD_NONE_COLOR_INDICATOR"
      size={10}
      sx={{
        color: (theme) => theme.drimz.palette.text.secondary,
      }}
    />
  </NoneColorIndicatorWrapper>
));
