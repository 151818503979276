import { FC, PropsWithChildren } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';

export const CardContentValueWrapper: FC<PropsWithChildren> = ({
  children,
}) => (
  <MuiGrid
    container
    item
    alignItems="center"
    sx={{
      maxWidth: 'fit-content',
      '&.MuiGrid-root': {
        maxWidth: '100%',
      },
    }}
    wrap="nowrap"
  >
    {children}
  </MuiGrid>
);
