const addImportant = (value: string) =>
  value.endsWith('!important') || value === '' ? value : `${value} !important`;

// returning as T | undefined -
// so we can use it in the styled components and with the proper css types (e.g. 'auto' | 'full-width'...)
export function withImportant<T extends string | number>(
  value: T | undefined
): T | undefined {
  if (value === undefined) {
    return undefined;
  }

  if (typeof value === 'string') {
    return addImportant(value) as T;
  }

  return addImportant(value.toString()) as T;
}
