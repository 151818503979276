import { FC, MouseEvent, useCallback } from 'react';
import * as UI from '@jux/ui-core';
import { PATHS } from '@jux/ui/utils/paths';

export const ImportTokensButton: FC<{
  label: string;
}> = ({ label }) => {
  const handleImportTokens = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.open(PATHS.IMPORT_TOKENS, '_blank');
  }, []);

  return (
    <UI.JuxButton
      onClick={handleImportTokens}
      hierarchy="Primary"
      disabled={false}
      size="medium"
      label={label}
      style={{
        width: '100%',
      }}
    />
  );
};
