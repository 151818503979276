import { useCallback } from 'react';
import {
  selectedNodeComponentVariantsPropsValues,
  useStore,
} from '@jux/canjux/core';
import {
  useNodeInteractiveState,
  useSaveSelectedNodeDDPChanges,
  useSelectedNodeId,
  useSelectedNodeInteractiveState,
} from '@jux/ui/components/editor/hooks';
import {
  DDPFieldsStateKeys,
  useDDPFieldsState,
} from '@jux/ui/components/editor/state';
import { useTrackEvents } from '@jux/ui/hooks';

export const useSetMultiFieldsValues = () => {
  const selectedNodeId = useSelectedNodeId();
  const { selectedNodeInteractiveState } = useSelectedNodeInteractiveState();
  const { getNodeInteractiveStateById } = useNodeInteractiveState();
  const { setFieldsStateByKey } = useDDPFieldsState();
  const { saveStyleChanges } = useSaveSelectedNodeDDPChanges();
  const selectedNodeVariantsValues = useStore(
    selectedNodeComponentVariantsPropsValues
  );
  const { trackFieldValueChangeEvent } = useTrackEvents();

  const setMultiFieldsValues = useCallback(
    (newValues: { [key in DDPFieldsStateKeys]?: any }) => {
      if (!selectedNodeId || !selectedNodeInteractiveState) return;

      Object.keys(newValues).forEach((key) => {
        setFieldsStateByKey(key as DDPFieldsStateKeys, {
          value: newValues[key as DDPFieldsStateKeys],
        });
        trackFieldValueChangeEvent(key, newValues[key as DDPFieldsStateKeys]);
      });

      saveStyleChanges({
        nodeId: selectedNodeId,
        nodeInteractiveState: selectedNodeInteractiveState,
        variantsEditingContext: selectedNodeVariantsValues || {},
        newStyle: newValues,
      });
    },
    [
      saveStyleChanges,
      selectedNodeId,
      selectedNodeInteractiveState,
      selectedNodeVariantsValues,
      setFieldsStateByKey,
      trackFieldValueChangeEvent,
    ]
  );

  const setMultiFieldsValuesForNodeId = useCallback(
    (id: string, newValues: { [key in DDPFieldsStateKeys]?: any }) => {
      Object.keys(newValues).forEach((key) => {
        setFieldsStateByKey(key as DDPFieldsStateKeys, {
          value: newValues[key as DDPFieldsStateKeys],
        });
      });

      // TODO: use the editing context of the node id that was specified !
      saveStyleChanges({
        nodeId: id,
        nodeInteractiveState: getNodeInteractiveStateById(id),
        variantsEditingContext: selectedNodeVariantsValues || {}, // TODO: get the variants values of the node id
        newStyle: newValues,
      });
    },
    [
      saveStyleChanges,
      getNodeInteractiveStateById,
      selectedNodeVariantsValues,
      setFieldsStateByKey,
    ]
  );

  return {
    setMultiFieldsValues,
    setMultiFieldsValuesForNodeId,
  };
};
