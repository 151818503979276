import { useEffect } from 'react';

// TODO: add a better way to reset the body's margin and padding
export const useSetIframeBodyStyle = (
  iframeBodyElement: Window['document']['body'] | undefined
) => {
  useEffect(() => {
    if (!iframeBodyElement) return;

    iframeBodyElement.style.margin = '0';
    iframeBodyElement.style.padding = '0';
  }, [iframeBodyElement]);
};
