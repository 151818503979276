import {
  OrderByDirection,
  OrderByFieldType,
  TableDataProps,
} from './Table.interface';

export const useTable = ({
  headers,
  rows,
}: {
  headers: TableDataProps['headers'];
  rows: TableDataProps['rows'];
}) => {
  let orderBy: {
    index: number;
    direction: OrderByDirection;
    fieldType: OrderByFieldType;
  };
  for (let i = 0; i < headers.length; i++) {
    const { orderBy: originalOrderBy } = headers[i];

    if (originalOrderBy) {
      const { direction = OrderByDirection.ASC, fieldType } = originalOrderBy;

      orderBy = {
        index: i,
        direction,
        fieldType,
      };
      break;
    }
  }

  const orderByRows = () => {
    const { index, direction, fieldType } = orderBy;
    const sortedRows = [...rows];

    sortedRows.sort((a, b) => {
      const aCell = a.cells[index].value;
      const bCell = b.cells[index].value;

      if (aCell === undefined || bCell === undefined) return 0;

      switch (fieldType) {
        case OrderByFieldType.NUMBER:
          if (aCell < bCell) {
            return direction === OrderByDirection.ASC ? -1 : 1;
          }
          if (aCell > bCell) {
            return direction === OrderByDirection.ASC ? 1 : -1;
          }
          return 0;

        case OrderByFieldType.STRING:
          if (aCell < bCell) {
            return direction === OrderByDirection.ASC ? -1 : 1;
          }
          if (aCell > bCell) {
            return direction === OrderByDirection.ASC ? 1 : -1;
          }
          return 0;

        case OrderByFieldType.DATE:
          if (aCell < bCell) {
            return direction === OrderByDirection.ASC ? -1 : 1;
          }
          if (aCell > bCell) {
            return direction === OrderByDirection.ASC ? 1 : -1;
          }
          return 0;
      }

      return 0;
    });

    return sortedRows;
  };

  return {
    rows: orderByRows(),
  };
};
