import * as uuid from 'uuid';
import {
  ComponentPropType,
  NodeType,
  COMPONENT_TAG_NAME,
} from '@jux/data-entities';
import { getJuxTextElementData } from './text';
import {
  StylesObject,
  ComponentConfigWithStates as StylesConfig,
} from '@jux/types';
import { Colors } from '../common/colors';
import { ElementDataInit } from '../types/ElementDataInit';

const CHIP_ELEMENT_DEFAULT_STYLES: StylesConfig = {
  root: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '24px',
    width: 'auto',
    padding: '4px 12px',
    gap: '4px',
    borderRadius: '24px',
    borderWidth: '1px',
    borderStyle: 'solid',
    background: Colors.Neutrals_0,
    borderColor: Colors.Neutrals_300,
    outline: 'none',
  },
  variants: [
    {
      propsValues: {
        selected: true,
        disabled: false,
      },
      styles: {
        borderColor: Colors.Brand_500,
        opacity: 1,
      },
    },
    {
      propsValues: {
        selected: true,
        disabled: true,
      },
      styles: {
        borderColor: Colors.Brand_500,
        opacity: 0.5,
        cursor: 'default',
      },
    },
    {
      propsValues: {
        selected: false,
        disabled: true,
      },
      styles: {
        opacity: 0.5,
        cursor: 'default',
      },
    },
    {
      propsValues: {
        selected: false,
        disabled: false,
      },
      styles: {
        opacity: 1,
      },
    },
  ],
  states: {
    hover: {
      root: {
        backgroundColor: Colors.Neutrals_100,
      },
    },
    active: {
      root: {
        backgroundColor: Colors.Neutrals_100,
      },
      variants: [
        {
          propsValues: {
            selected: true,
            disabled: false,
          },
          styles: {
            borderColor: Colors.Brand_700,
          },
        },
        {
          propsValues: {
            selected: false,
            disabled: false,
          },
          styles: {
            borderColor: Colors.Neutrals_500,
          },
        },
      ],
    },
  },
};

const JUX_CHIP_LABEL_DEFAULT_STYLES: StylesObject = {
  fontFamily: 'Inter',
  fontWeight: '400',
  fontStyle: 'normal',
  fontSize: '12px',
  lineHeight: '16px',
  display: 'inline-block',
  color: Colors.Neutrals_600,
};

export const CHIP_ELEMENT_DEFAULT_DISPLAY_NAME = 'chip';

const JUX_CHECKBOX_LABEL_DEFAULT_CONTENT = 'This is a chip';

export const getJuxChipElementData: ElementDataInit<undefined> = ({
  contextParentId,
  parentId,
} = {}) => {
  const rootChipId = uuid.v4();

  const chipLabel = getJuxTextElementData({
    contextParentId: rootChipId,
    parentId: rootChipId,
    rootStyles: JUX_CHIP_LABEL_DEFAULT_STYLES,
    text: JUX_CHECKBOX_LABEL_DEFAULT_CONTENT,
    displayName: 'label',
  }).root;

  return {
    root: {
      type: NodeType.LOCAL_COMPONENT,
      id: rootChipId,
      parentId,
      scopes: [],
      displayName: CHIP_ELEMENT_DEFAULT_DISPLAY_NAME,
      tagName: COMPONENT_TAG_NAME.JuxChip,
      styles: CHIP_ELEMENT_DEFAULT_STYLES,
      config: {
        contextParentId,
        props: {
          selected: false,
          disabled: false,
        },
        variants: [
          {
            variant: 'disabled',
            propType: ComponentPropType.Enum,
            options: [
              {
                value: true,
                label: 'True',
              },
              {
                value: false,
                label: 'False',
              },
            ],
            defaultValue: false,
            isFixed: true,
          },
          {
            variant: 'selected',
            propType: ComponentPropType.Enum,
            options: [
              {
                value: true,
                label: 'Selected',
              },
              {
                value: false,
                label: 'Not selected',
              },
            ],
            defaultValue: false,
            isFixed: true,
          },
        ],
      },
      children: [chipLabel.id],
    },
    subComponents: [chipLabel],
  };
};
