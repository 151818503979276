import { useAtom } from 'jotai';
import { RESET, atomWithReset } from 'jotai/utils';
import { useCallback } from 'react';

const selectedTokenPathAtom = atomWithReset<string | null>(null);

export const useSelectedTokenState = () => {
  const [selectedTokenPath, setSelectedTokenPath] = useAtom(
    selectedTokenPathAtom
  );

  const isTokenSelected = useCallback(
    (tokenPath: string) => tokenPath === selectedTokenPath,
    [selectedTokenPath]
  );

  const deselectToken = useCallback(
    () => setSelectedTokenPath(RESET),
    [setSelectedTokenPath]
  );

  const setSelectedToken = useCallback(
    (tokenPath: string) => setSelectedTokenPath(tokenPath),
    [setSelectedTokenPath]
  );

  return {
    deselectToken,
    isTokenSelected,
    setSelectedToken,
  };
};
