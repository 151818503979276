import { formatTokenName } from '../formatters';

export const insertInputValue = (element: HTMLInputElement, str: string) => {
  const selectionStart = element.selectionStart || 0;
  const selectionEnd = element.selectionEnd || 0;

  element.setRangeText(
    formatTokenName(str),
    selectionStart,
    selectionEnd,
    'end'
  );
};
