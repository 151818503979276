import {
  CanjuxState,
  CommonActionsParams,
  createComponentInstanceCanvasNode,
  getValidComponentPlacement,
  JuxStoreActionFn,
  setLayersData,
} from '@jux/canjux/core';
import { CanvasData, JuxComponentData, NodeType } from '@jux/data-entities';
import intersection from 'lodash/intersection';
import type { Draft as WritableDraft } from 'mutative';
import { getSourceComponentById } from '../selectors/utils';
import { setSelectedNodes } from './setSelectedNodes';
import { isAncestorNode, isSiblingAncestorNode } from './utils';
import { duplicateNode } from './utils/duplicateNode';

const SOURCE_COMPONENT_TYPES = [
  NodeType.LOCAL_COMPONENT,
  NodeType.LIBRARY_COMPONENT,
];

const pasteNode = ({
  propsOverrides,
  sourceComponent,
  state,
  targetNodeId,
}: {
  propsOverrides?: Record<string, any>;
  sourceComponent: JuxComponentData;
  state: WritableDraft<CanjuxState>;
  targetNodeId?: string;
}) => {
  // Getting the right placement of a the new component on the canvas
  // TODO: need to provide the component׳s dimensions in order to calculate more accurate position
  const { width, height, transform } = state;
  const currentCanvas = state.canvases[state.currentCanvasName];

  // getting the right component placement
  const {
    targetNodeId: parentId,
    targetChildIndex,
    position,
  } = getValidComponentPlacement({
    sourceComponentId: sourceComponent.id,
    targetNodeId,
    components: state.components,
    canvas: currentCanvas,
    transform,
    canvasDimensions: { width, height },
    canvasNodesDimensions: state.canvasNodesDimensions,
  });

  let nodeId: string | null = '';

  // In case of a source component, we want to create an instance of it
  if (SOURCE_COMPONENT_TYPES.includes(sourceComponent.type)) {
    nodeId = createComponentInstanceCanvasNode({
      canvasName: currentCanvas.name,
      componentId: sourceComponent.id,
      propsOverrides: propsOverrides || {},
      parentId,
      targetIndex: targetChildIndex,
      position,
      state,
    });
  }
  // In case of an element, we want creating an identical element
  else if (NodeType.ELEMENT === sourceComponent.type) {
    nodeId = duplicateNode({
      sourceComponentId: sourceComponent.id,
      targetCanvasName: currentCanvas.name,
      targetNodeId: parentId,
      targetIndex: targetChildIndex,
      position,
      state,
    });
  }

  return nodeId;
};

const getTargetNodeIds = ({
  canvas,
  componentId,
  components,
  intersectingNodes,
  selectedNodesStack,
}: {
  canvas: CanvasData;
  componentId: string;
  components: CanjuxState['components'];
  // Selected nodes that are included in the copied data
  intersectingNodes: string[];
  selectedNodesStack: string[];
}) => {
  return intersectingNodes.length
    ? // If it's a full intersection we try to paste each item into its own parent
      intersectingNodes.length === selectedNodesStack.length &&
      components[componentId].parentId
      ? [components[componentId].parentId as string]
      : // Partial intersection, so we paste each item as root node
        []
    : // If the node is either a child of a selected node or sibling of a selected node
      selectedNodesStack.filter(
        (selectedNodeId) =>
          canvas.nodes[selectedNodeId]?.properties.isContainer ||
          isAncestorNode({
            components,
            childId: componentId,
            ancestorId: selectedNodeId,
          }) ||
          isSiblingAncestorNode({
            components,
            siblingId: selectedNodeId,
            ancestorId: components[componentId]?.parentId,
          })
      );
};

export const pasteCopiedNodes: JuxStoreActionFn<
  CommonActionsParams['pasteCopiedNodes'],
  CanjuxState
> = ({ data, state }) => {
  const { components, currentCanvasName, canvases, selectedNodesStack } = state;
  const canvas = canvases[currentCanvasName];

  // Selected nodes that are included in the copied data
  const intersectingNodes = intersection(
    selectedNodesStack,
    data.map(({ componentId }) => componentId)
  );

  // Keep track of the pasted node ids, so we can select them afterwords
  const pastedNodeIds = [];

  for (const { componentId, propsOverrides } of data) {
    /* In case of an instance we want to know what it refers to (element or a source component)
   and create a new instance from it׳s reference if it is a source component
   or creating a cloned element if it׳s reference is an element */
    const sourceComponent = getSourceComponentById({
      id: componentId,
      components: state.components,
    });

    if (!sourceComponent) return state;

    const targetNodeIds = getTargetNodeIds({
      canvas,
      componentId,
      components,
      intersectingNodes,
      selectedNodesStack,
    });

    // If we have a target node, paste the node as a child of the target node
    if (targetNodeIds.length) {
      for (const targetNodeId of targetNodeIds) {
        if (!targetNodeId) {
          continue;
        }

        const { parentId } = components[componentId];

        // If the target node is the copied node itself,
        // or if the target node is the parent,
        // or if the target node is a sibling,
        // paste it as a child of the copied node's parent
        const shouldTargetParent =
          targetNodeId === componentId ||
          targetNodeId === parentId ||
          isSiblingAncestorNode({
            components,
            siblingId: targetNodeId,
            ancestorId: parentId,
          });

        pastedNodeIds.push(
          pasteNode({
            propsOverrides,
            sourceComponent,
            state,
            targetNodeId: shouldTargetParent ? parentId : targetNodeId,
          })
        );
      }
    } else {
      pastedNodeIds.push(
        pasteNode({
          propsOverrides,
          sourceComponent,
          state,
        })
      );
    }
  }

  setSelectedNodes({
    nodeIds: pastedNodeIds.filter(Boolean) as string[],
    state,
  });

  setLayersData(state);

  return state;
};
