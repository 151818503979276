import { CanjuxState } from '@jux/canjux/core';
import { createSelector } from 'reselect';

export const selectNodeParent = (id: string) =>
  createSelector(
    [
      (state: CanjuxState) => state.components[id]?.parentId,
      (state: CanjuxState) =>
        state.components[id]?.parentId
          ? state.components[state.components[id].parentId as string]
          : undefined,
    ],
    (parentId, parentComponent) => {
      if (!parentId || !parentComponent) {
        return { parentId: undefined, parentComponent: undefined };
      }

      return {
        parentId,
        parentComponent,
      };
    }
  );
