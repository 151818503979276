import { MuiGrid, styled } from '@jux/ui/components/common/mui';
import {
  EDITOR_DDP_FIELD_UNIT_COLOR_OPEN,
  EDITOR_DDP_FIELD_UNIT_HOVER_OPEN,
} from '@jux/ui/theme/palette';
import { withImportant } from '@jux/ui/utils/css';

export * from './AutocompleteNoOptionsTypography';

const AUTOCOMPLETE_OPTIONS_Z_INDEX = 10;

export const HIGHLIGHTED_OPTION_STYLE = {
  color: withImportant(EDITOR_DDP_FIELD_UNIT_COLOR_OPEN),
  backgroundColor: withImportant(EDITOR_DDP_FIELD_UNIT_HOVER_OPEN),
};

export const AutocompleteOptions = styled(MuiGrid, {
  shouldForwardProp: (prop) => prop !== 'hidden',
})<{ hidden?: boolean }>(({ theme, hidden }) => ({
  width: '100%',
  display: hidden ? 'none' : 'flex',
  flexDirection: 'column',
  maxHeight: theme.drimz.size.common.autocomplete.options.maxHeight,
  overflow: 'auto',
  zIndex: AUTOCOMPLETE_OPTIONS_Z_INDEX,
  padding: '3px',
}));

export const AutocompleteOptionWrapper = styled(MuiGrid)(({ theme }) => ({
  cursor: 'pointer',
  backgroundColor: withImportant(theme.drimz.palette.transparent),
}));

export const AutocompleteInputLabel = styled('label')(() => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'flex',
  flexGrow: 1,
}));
