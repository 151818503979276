import { CanjuxState } from '@jux/canjux/core';
import { JuxComponentData, NodeType } from '@jux/data-entities';

export const getResolvedSourceComponentData = ({
  component,
  components,
}: {
  component: JuxComponentData;
  components: CanjuxState['components'];
}): JuxComponentData => {
  let lastAncestor = component;

  while (
    lastAncestor?.sourceComponentId &&
    lastAncestor.type === NodeType.INSTANCE
  ) {
    lastAncestor = components[lastAncestor.sourceComponentId];
  }

  return lastAncestor;
};
