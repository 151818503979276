import { AssetData } from '@jux/data-entities';

const JUX_ASSET_INDETERMINATE_ICON_SVG = `
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 8C4 7.44772 4.44772 7 5 7H11C11.5523 7 12 7.44772 12 8C12 8.55228 11.5523 9 11 9H5C4.44772 9 4 8.55228 4 8Z" fill="currentColor"/>
</svg>
`;

export const JUX_ASSET_INDETERMINATE_ICON_ID =
  'jux-asset-indeterminate-icon' as const;

export const JUX_ASSET_INDETERMINATE_ICON: AssetData = {
  name: 'IndeterminateIcon',
  content: JUX_ASSET_INDETERMINATE_ICON_SVG,
  isSystemAsset: true,
};
