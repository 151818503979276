import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { AutocompleteProps } from './Autocomplete.interface';
import * as S from './Autocomplete.style';
import { HIGHLIGHTED_OPTION_STYLE } from './Autocomplete.style';
import { AutocompleteInput } from './AutocompleteInput';
import { useSelectFieldAutocomplete } from './useSelectFieldAutocomplete';

export const SelectFieldAutocomplete: FC<AutocompleteProps> = ({
  value,
  options: initialOptions,
  inputName,
  hideSearchIcon,
  isComputed,
  renderOption,
  onSelect,
  inputProps,
  inputWrapperProps,
  wrapperProps,
  optionsWrapperProps,
}) => {
  const {
    getMenuProps,
    getInputProps,
    getItemProps,
    handleInputBlur,
    highlightedIndex,
    isOpen,
    options,
  } = useSelectFieldAutocomplete({
    initialOptions,
    selectedOption: value,
    onSelect,
  });

  return (
    <MuiGrid container {...wrapperProps}>
      <AutocompleteInput
        wrapperProps={inputWrapperProps}
        isComputed={isComputed}
        name={inputName}
        hideSearchIcon={hideSearchIcon}
        {...inputProps}
        {...getInputProps()}
        onBlur={handleInputBlur}
        showValue
      />
      <S.AutocompleteOptions
        {...optionsWrapperProps}
        {...getMenuProps()}
        hidden={!isOpen}
      >
        {options.length > 0 ? (
          options.map((option, index) => (
            <S.AutocompleteOptionWrapper
              item
              container
              key={`${option.label}${index}`}
              sx={{
                ...(highlightedIndex === index && HIGHLIGHTED_OPTION_STYLE),
              }}
              {...getItemProps({
                item: option,
                index,
              })}
            >
              {renderOption({
                option: option,
                isSelected: option.label === value,
              })}
            </S.AutocompleteOptionWrapper>
          ))
        ) : (
          <MuiGrid item container>
            <S.AutocompleteNoOptionsTypography>
              Nothing found
            </S.AutocompleteNoOptionsTypography>
          </MuiGrid>
        )}
      </S.AutocompleteOptions>
    </MuiGrid>
  );
};
