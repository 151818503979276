import {
  CanjuxState,
  CommonActionsParams,
  JuxStoreActionFn,
  setLayersData,
} from '@jux/canjux/core';

/**
 * Trigger layers update
 */
export const triggerLayersUpdate: JuxStoreActionFn<
  CommonActionsParams['triggerLayersUpdate'],
  CanjuxState
> = ({ state, nodeId }) => {
  if (nodeId && state.nodesTriggeringLayersUpdate.has(nodeId)) {
    state.nodesTriggeringLayersUpdate.delete(nodeId);
  }

  setLayersData(state);

  return state;
};
