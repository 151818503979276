import { JuxStore } from '@jux/canjux/core';

// recursive function to find the children list of a component
// and grandchildren, great-grandchildren, etc.
// and add them to an array of children ids
export const recursivelyFindComponentChildrenToAddToCanvas = ({
  children,
  components,
}: {
  children: Array<string>;
  components: JuxStore['components'];
}): Array<string> => {
  const childrenIds: Array<string> = [];

  children.forEach((childId) => {
    childrenIds.push(childId);

    const childComponent = components[childId];

    if (childComponent.children.length) {
      childrenIds.push(
        ...recursivelyFindComponentChildrenToAddToCanvas({
          children: childComponent.children,
          components,
        })
      );
    }
  });

  return childrenIds;
};
