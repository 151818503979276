import { useCallback } from 'react';
import {
  NodeInteractiveState,
  useSetupDDPModuleState,
} from '@jux/ui/components/editor/hooks';

export const useSetupDDPValues = () => {
  const { setupDDPValuesStateBySelectedNodeId } = useSetupDDPModuleState();

  const setupDDPValues = useCallback(
    (nodeState?: NodeInteractiveState) => {
      setupDDPValuesStateBySelectedNodeId({
        nodeState,
      });
    },
    [setupDDPValuesStateBySelectedNodeId]
  );

  return { setupDDPValues };
};
