import {
  CanjuxState,
  CommonActionsParams,
  JuxStoreActionFn,
  setLayersData,
} from '@jux/canjux/core';
import { deleteNode } from './deleteNode';

export const deleteSelectedNodes: JuxStoreActionFn<
  CommonActionsParams['deleteSelectedNodes'],
  CanjuxState
> = ({ state }) => {
  for (const nodeId of state.selectedNodesStack) {
    deleteNode({ nodeId, state });
  }

  setLayersData(state);

  return state;
};
