import { createSelector } from 'reselect';
import { CanjuxState, getRootNodeOfNode } from '@jux/canjux/core';
import { NodeType } from '@jux/data-entities';

const getContextParentOfInstance = ({
  id,
  components,
}: {
  id: string;
  components: CanjuxState['components'];
}): string | undefined => {
  // Find the first level instance that points to a component
  let firstLevelInstance = components[id];
  while (firstLevelInstance?.sourceComponentId) {
    const nextLevelInstance = components[firstLevelInstance.sourceComponentId];

    if (nextLevelInstance?.type === NodeType.INSTANCE) {
      firstLevelInstance = nextLevelInstance;
    } else {
      break;
    }
  }

  // Find the distance from the this node's source component to it's parent context node
  let distanceFromParentContext = 0;
  while (firstLevelInstance?.parentId) {
    distanceFromParentContext++;
    firstLevelInstance = components[firstLevelInstance?.parentId];
  }

  // Apply the same distance to go up over the actual node tree (might be all instances)
  let parentContext = components[id];
  while (distanceFromParentContext > 0 && parentContext?.parentId) {
    parentContext = components[parentContext.parentId];
    distanceFromParentContext--;
  }
  return parentContext?.id;
};

export const getContextParentByNodeId = ({
  id,
  components,
}: {
  id?: string;
  components: CanjuxState['components'];
}): string | undefined => {
  if (!id) return undefined;

  const component = components[id];
  if (!component) return undefined;

  switch (component.type) {
    case NodeType.INSTANCE:
      return getContextParentOfInstance({
        id,
        components,
      });
    case NodeType.ELEMENT:
      const rootNodeComponent = getRootNodeOfNode({
        components,
        nodeId: component.id,
      });
      if (rootNodeComponent.id === component.id) return undefined;

      if (
        rootNodeComponent.type !== NodeType.LOCAL_COMPONENT &&
        rootNodeComponent.type !== NodeType.LIBRARY_COMPONENT
      ) {
        return undefined;
      }

      return rootNodeComponent.id;
    default:
      return undefined;
  }
};

export const getContextParentId = createSelector(
  [(state: CanjuxState) => state.components],
  (components) => (id: string) => {
    return getContextParentByNodeId({ id, components });
  }
);
