import { useCallback } from 'react';
import { useStoreActions } from '../useStoreActions';

export const useDuplicateSelectedNodes = () => {
  const {
    componentActions: { duplicateSelectedNodes },
  } = useStoreActions();

  const handleDuplicateSelectedNodes = useCallback(() => {
    duplicateSelectedNodes();
  }, [duplicateSelectedNodes]);

  return {
    handleDuplicateSelectedNodes,
  };
};
