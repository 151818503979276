import {
  ComponentActionsParams,
  CREATE_NEW_CANVAS_NODE_DISTANCE,
  getDefaultNodePropertiesByTagName,
  getRootNodeOfNode,
  JuxStore,
  JuxStoreActionFn,
  recursivelyFindComponentChildrenToAddToCanvas,
  setLayersData,
} from '@jux/canjux/core';
import { NodeData, NodeType } from '@jux/data-entities';
import { getResolvedSourceComponentData } from '../selectors/utils/getResolvedSourceComponentData';

/**
 * Restore source component to the current canvas.
 */
export const restoreSourceComponent: JuxStoreActionFn<
  ComponentActionsParams['restoreSourceComponent'],
  JuxStore
> = ({ instanceNodeId: nodeId, state }) => {
  const currentCanvas = state.canvases[state.currentCanvasName];
  const instanceComponent = state.components[nodeId];

  // if the node is not an instance
  if (!instanceComponent || instanceComponent.type !== NodeType.INSTANCE) {
    return state;
  }

  // find the source component node recursively
  const sourceComponent = getResolvedSourceComponentData({
    component: instanceComponent,
    components: state.components,
  });
  // Do not restore object that is not a source component
  if (
    sourceComponent.type !== NodeType.LOCAL_COMPONENT &&
    sourceComponent.type !== NodeType.LIBRARY_COMPONENT
  ) {
    return state;
  }

  // Set default variant
  for (const variant of sourceComponent.config?.variants ?? []) {
    sourceComponent.config.props[variant.variant] = variant.defaultValue;
  }

  const rootNode = getRootNodeOfNode({
    nodeId,
    components: state.components,
  });
  const rootNodeData = currentCanvas.nodes[rootNode.id];

  // Set new source node position
  const position = {
    x:
      rootNodeData.position.x +
      state.canvasNodesDimensions[rootNode.id]?.width +
      CREATE_NEW_CANVAS_NODE_DISTANCE,
    y: rootNodeData.position.y,
  };

  // create a new node for the source component
  const sourceNode: NodeData = {
    position,
    properties: getDefaultNodePropertiesByTagName({
      tagName: sourceComponent.tagName,
      type: sourceComponent.type,
    }),
  };

  // add the source component node to the canvas
  currentCanvas.nodes[sourceComponent.id] = sourceNode;

  // add the source component to the root nodes order list
  currentCanvas.rootNodesOrder.unshift(sourceComponent.id);

  // recursively find the source component's children ids
  const childrenIds = recursivelyFindComponentChildrenToAddToCanvas({
    children: sourceComponent.children,
    components: state.components,
  });

  // create a new node for each child component and add it to the canvas
  childrenIds.forEach((childId) => {
    const childComponent = state.components[childId];

    currentCanvas.nodes[childId] = {
      position: { x: 0, y: 0 },
      properties: getDefaultNodePropertiesByTagName({
        tagName: childComponent.tagName,
        type: childComponent.type,
      }),
    };
  });

  setLayersData(state);

  return state;
};
