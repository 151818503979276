import { FC, forwardRef, PropsWithChildren } from 'react';
import * as I from '../imported/components';

export type JuxButtonProps = PropsWithChildren<
  I.JuxButton_Props & {
    label?: string;
  }
>;

export const JuxButton: FC<JuxButtonProps> = forwardRef<
  HTMLButtonElement,
  JuxButtonProps
>((props, ref) => (
  <I.JuxButton
    JuxButton_text_Props={props.label ? { text: props.label } : {}}
    {...props}
    ref={ref}
  />
));
