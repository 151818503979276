import { ThemeOptions } from '@jux/ui/components/common/mui';
import { lightDrimzPalette } from '../base';
import { componentsColors } from '../base/base-theme-components-colors';
import { baseThemeSize } from '../base/base-theme-size';
import { baseThemeShadow } from '../base/base-theme-shadow';
import { transitions } from '../base/base-theme-transitions';
import { lightColors } from './light-theme-colors';

export const lightThemeOptions: ThemeOptions = {
  components: {
    ...componentsColors(lightDrimzPalette),
  },
  drimz: {
    size: baseThemeSize,
    shadow: baseThemeShadow(lightColors),
    palette: lightDrimzPalette,
    transitions,
  },
  palette: {
    mode: 'light',
    // must keep both primary & text in order to keep theme color affect working properly
    primary: lightDrimzPalette.primary,
    text: lightDrimzPalette.text,
  },
};
