import { JuxStore } from '@jux/canjux/core';

export const findOneOfNodesInTree = ({
  components,
  rootNodeId,
  idsToSearch,
}: {
  components: JuxStore['components'];
  rootNodeId: string;
  idsToSearch: string[];
}): string | null => {
  if (idsToSearch.includes(rootNodeId)) {
    return rootNodeId;
  }
  const nodeComponentData = components[rootNodeId];

  for (const childId of nodeComponentData.children) {
    if (idsToSearch.includes(childId)) {
      return childId;
    }

    // search recursively in children
    const foundId = findOneOfNodesInTree({
      components,
      rootNodeId: childId,
      idsToSearch,
    });
    if (foundId) {
      return foundId;
    }
  }

  return null;
};
