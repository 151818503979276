import { styled } from '@jux/ui/components/common/mui';

export const CanvasContainerPaneWrapper = styled('div')<{
  isDragging: boolean;
}>(({ isDragging }) => ({
  zIndex: 1,
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  cursor: isDragging ? 'grabbing' : 'initial',
  overflow: 'hidden',
}));
