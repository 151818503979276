import { FC } from 'react';
import { bindTrigger, MuiGrid } from '@jux/ui/components/common/mui';
import {
  ChromePicker,
  ChromePickerPropsWithColor,
  Popover,
  Tooltip,
} from '@jux/ui/components';
import { ColorFieldProps } from '@jux/ui/components/editor/components/panels/DDP/base';
import { ColorIndicator, ColorIndicatorButtonWrapper } from './ColorIndicator';
import { NoneColorIndicator } from './NoneColorIndicator';

export type ColorPickerPopupProps = {
  color: string;
  handleColorPickerChange: ChromePickerPropsWithColor['onChange'];
  isTokenized?: boolean;
  tokenHexValue: string;
  isNoneValue?: boolean;
} & Pick<ColorFieldProps, 'popupState'>;

export const ColorPickerPopup: FC<ColorPickerPopupProps> = ({
  popupState,
  color,
  handleColorPickerChange,
  isTokenized,
  tokenHexValue,
  isNoneValue,
}) => (
  <MuiGrid display="inline-flex">
    <Tooltip content="Color picker" visible={isTokenized}>
      <div>
        <ColorIndicatorButtonWrapper
          {...(isTokenized ? {} : bindTrigger(popupState))}
        >
          {isNoneValue ? (
            <NoneColorIndicator />
          ) : (
            <ColorIndicator
              color={color}
              isTokenized={isTokenized}
              tokenHexValue={tokenHexValue}
            />
          )}
        </ColorIndicatorButtonWrapper>
      </div>
    </Tooltip>
    <Popover
      popupState={popupState}
      hideBackdrop
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: {
          ml: -2,
        },
      }}
    >
      <ChromePicker color={color} onChange={handleColorPickerChange} />
    </Popover>
  </MuiGrid>
);
