/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';

const { styled } = createDrimz();

type List_item_button_label_root_Props = PropsOf<
  typeof JuxReactElements.JuxText
>;

const List_item_button_label_root: FC<List_item_button_label_root_Props> =
  forwardRef((props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />);

List_item_button_label_root.displayName = 'List_item_button_label_root';

List_item_button_label_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        display: 'inline-block',
        fontSize: '14px',
        fontStyle: 'normal',
        fontFamily: 'Inter',
        fontWeight: '500',
        lineHeight: '20px',
      },
    },
  }) as List_item_button_label_root_Props['rootElement'],
};

export type List_item_button_label_Props = List_item_button_label_root_Props;

export const List_item_button_label: FC<List_item_button_label_Props> =
  forwardRef<
    ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>,
    List_item_button_label_Props
  >((props, ref) => <List_item_button_label_root {...props} ref={ref} />);

List_item_button_label.displayName = 'List_item_button_label';
List_item_button_label.defaultProps = { text: 'List item' };

import { IconButton, type IconButton_Props } from '../';

type List_item_div_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;

const List_item_div_root: FC<List_item_div_root_Props> = forwardRef(
  (props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />
);

List_item_div_root.displayName = 'List_item_div_root';

List_item_div_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: { padding: 0, width: 'auto', height: 'auto', display: 'flex' },
    },
  }) as List_item_div_root_Props['rootElement'],
};

export type List_item_div_Props = List_item_div_root_Props & {
  IconButton_Props?: IconButton_Props;
};

export const List_item_div: FC<List_item_div_Props> = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>,
  List_item_div_Props
>(({ IconButton_Props, ...props }, ref) => (
  <List_item_div_root
    {...props}
    ref={ref}
    data-jux-context="d7fd9f68-c079-4d57-b551-dd51a51fb393"
  >
    {props.children || (
      <>
        <IconButton disabled={false} size={'small'} {...IconButton_Props} />
      </>
    )}
  </List_item_div_root>
));

List_item_div.displayName = 'List_item_div';
List_item_div.defaultProps = {};

type List_item_root_Props = PropsOf<typeof JuxReactElements.JuxButton> & {
  disabled: true | false;
  Selected: 'true' | 'false';
};

const List_item_root: FC<List_item_root_Props> = forwardRef((props, ref) => (
  <JuxReactElements.JuxButton {...props} ref={ref} />
));

List_item_root.displayName = 'List_item_root';

List_item_root.defaultProps = {
  rootElement: styled('button', {
    styles: {
      root: {
        gap: '8px',
        color: '#FFFFFF',
        width: 'auto',
        cursor: 'pointer',
        height: '36px',
        display: 'flex',
        padding: '8px 16px',
        fontSize: '14px',
        fontStyle: 'normal',
        alignItems: 'center',
        fontFamily: 'Inter',
        fontWeight: '500',
        lineHeight: '20px',
        borderColor: '',
        borderStyle: 'none',
        borderRadius: '4px',
        flexDirection: 'row',
        justifyContent: 'center',
        '&:focus': { outline: 'none' },
        '&:active': { outline: 'none' },
        '&:focus-visible': {
          borderRadius: '8px',
          outlineColor: '#3851DD',
          outlineStyle: 'solid',
          outlineWidth: '2px',
          outlineOffset: '2px',
        },
      },
      variants: [
        {
          propsValues: { disabled: false, Selected: 'true' },
          styles: {
            width: '338px',
            display: 'flex',
            borderRadius: '2px',
            justifyContent: 'space-between',
            backgroundColor: '{color.core.grays_800}',
            height: '32px',
            alignItems: 'center',
            padding: '8px 8px 8px 8px',
            '[data-jux-context="9eecdb51-6168-4e4d-9460-24621c071f26"]': {
              fontSize: '14px',
              fontFamily: 'Inter, sans-serif',
              fontWeight: '400',
              lineHeight: '20px',
              letterSpacing: '',
              color: '{color.core.grays_200}',
            },
            '[data-jux-context="d7fd9f68-c079-4d57-b551-dd51a51fb393"]': {
              display: 'none',
            },
            '&:hover': {
              backgroundColor: '{color.core.grays_650}',
              width: '338px',
              padding: '8px 8px 8px 8px',
              '[data-jux-context="d7fd9f68-c079-4d57-b551-dd51a51fb393"]': {
                display: 'block',
              },
              '[data-jux-context="9eecdb51-6168-4e4d-9460-24621c071f26"]': {
                color: '{color.core.grays_0}',
              },
            },
            '&:active': {
              gap: '8px',
              width: '338px',
              display: 'flex',
              borderRadius: '2px',
              justifyContent: 'space-between',
              backgroundColor: '{color.core.grays_650}',
              '[data-jux-context="9eecdb51-6168-4e4d-9460-24621c071f26"]': {
                fontWeight: '400',
                color: '{color.core.grays_0}',
              },
            },
          },
        },
        {
          propsValues: { disabled: true, Selected: 'true' },
          styles: { cursor: 'default', backgroundColor: '#98A6F9' },
        },
        {
          propsValues: { disabled: false, Selected: 'false' },
          styles: {
            width: '338px',
            display: 'flex',
            borderRadius: '2px',
            justifyContent: 'space-between',
            backgroundColor: '{color.core.grays_800}',
            height: '32px',
            alignItems: 'center',
            padding: '8px 8px 8px 8px',
            '[data-jux-context="9eecdb51-6168-4e4d-9460-24621c071f26"]': {
              fontSize: '14px',
              fontFamily: 'Inter, sans-serif',
              fontWeight: '400',
              lineHeight: '20px',
              letterSpacing: '',
              color: '{color.core.grays_200}',
            },
            '[data-jux-context="d7fd9f68-c079-4d57-b551-dd51a51fb393"]': {
              display: 'none',
            },
            '&:hover': {
              backgroundColor: '{color.core.grays_650}',
              width: '338px',
              padding: '8px 8px 8px 8px',
              '[data-jux-context="d7fd9f68-c079-4d57-b551-dd51a51fb393"]': {
                display: 'block',
              },
              '[data-jux-context="9eecdb51-6168-4e4d-9460-24621c071f26"]': {
                color: '{color.core.grays_0}',
              },
            },
            '&:active': {
              gap: '8px',
              width: '338px',
              display: 'flex',
              borderRadius: '2px',
              justifyContent: 'space-between',
              backgroundColor: '{color.core.grays_650}',
              '[data-jux-context="9eecdb51-6168-4e4d-9460-24621c071f26"]': {
                fontWeight: '400',
                color: '{color.core.grays_0}',
              },
            },
          },
        },
        {
          propsValues: { disabled: true, Selected: 'false' },
          styles: { cursor: 'default', backgroundColor: '#98A6F9' },
        },
      ],
    },
  }) as List_item_root_Props['rootElement'],
};

export type List_item_Props = List_item_root_Props & {
  List_item_button_label_Props?: List_item_button_label_Props;
  List_item_div_Props?: List_item_div_Props;
};

export const List_item: FC<List_item_Props> = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxButton>,
  List_item_Props
>(({ List_item_button_label_Props, List_item_div_Props, ...props }, ref) => (
  <List_item_root
    {...props}
    ref={ref}
    data-jux-context="53cb5a1e-42dc-4969-a598-e129539bc40c"
  >
    {props.children || (
      <>
        <List_item_button_label
          {...List_item_button_label_Props}
          data-jux-context="9eecdb51-6168-4e4d-9460-24621c071f26"
        />
        <List_item_div
          {...List_item_div_Props}
          data-jux-context="d7fd9f68-c079-4d57-b551-dd51a51fb393"
        />
      </>
    )}
  </List_item_root>
));

List_item.displayName = 'List_item';
List_item.defaultProps = { disabled: false, Selected: 'true' };
