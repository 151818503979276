import {
  CanjuxState,
  CommonActionsParams,
  JuxStoreActionFn,
} from '@jux/canjux/core';

/**
 * Set imported old components
 */
export const setImportedOldComponents: JuxStoreActionFn<
  CommonActionsParams['setImportedOldComponents'],
  CanjuxState
> = ({ state }) => {
  state.importedOldComponents = true;
  return state;
};
