import { useCallback, useMemo } from 'react';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import { useFieldValues, useSetMultiFieldsValues } from '../../../../../hooks';
import { DisplayValue } from '../../display/DisplayField.interface';
import {
  isFlexDirectionReversed,
  isSelectedFlexDirection,
  parseFlexDirection,
  toggleFlexDirection,
  toggleReverseFlexDirection,
} from './FlexDirectionField.utils';
import {
  SupportedFlexDirectionValues,
  FlexDirectionValue,
} from './FlexDirectionField.interface';

export const useFlexDirectionField = () => {
  const { value, initialValue } = useFieldValues(
    DDPPrimitiveFieldsKeys.flexDirection
  );
  const { setMultiFieldsValues } = useSetMultiFieldsValues();

  const parsedSelectedFlexDirection = useMemo(
    () => parseFlexDirection(value ?? initialValue),
    [initialValue, value]
  );

  const isReverseSelected = useMemo(
    () => isFlexDirectionReversed(parsedSelectedFlexDirection),
    [parsedSelectedFlexDirection]
  );

  const saveChanges = useCallback(
    (newFlexDirectionValue: string | undefined) => {
      if (!newFlexDirectionValue) return;
      const newParsedFlexDirection = parseFlexDirection(newFlexDirectionValue);
      if (!newParsedFlexDirection) return;

      setMultiFieldsValues({
        [DDPPrimitiveFieldsKeys.flexDirection]: newParsedFlexDirection,
        [DDPPrimitiveFieldsKeys.display]: DisplayValue.flex,
      });
    },
    [setMultiFieldsValues]
  );

  const isFlexDirectionSelected = useCallback(
    (flexDirectionValue: SupportedFlexDirectionValues) => {
      if (!parsedSelectedFlexDirection) return false;

      return isSelectedFlexDirection(
        flexDirectionValue,
        parsedSelectedFlexDirection
      );
    },
    [parsedSelectedFlexDirection]
  );

  const handleFlexDirectionClick = useCallback(
    (flexDirectionValue: SupportedFlexDirectionValues) => {
      const toggledFlexDirection = toggleFlexDirection(
        flexDirectionValue,
        isReverseSelected
      );

      saveChanges(toggledFlexDirection);
    },
    [isReverseSelected, saveChanges]
  );

  const isColumnSelected = useMemo(
    () => isFlexDirectionSelected(FlexDirectionValue.column),
    [isFlexDirectionSelected]
  );
  const isRowSelected = useMemo(
    () => isFlexDirectionSelected(FlexDirectionValue.row),
    [isFlexDirectionSelected]
  );

  const handleColumnClick = useCallback(
    () => handleFlexDirectionClick(FlexDirectionValue.column),
    [handleFlexDirectionClick]
  );

  const handleRowClick = useCallback(
    () => handleFlexDirectionClick(FlexDirectionValue.row),
    [handleFlexDirectionClick]
  );

  const handleReverseClick = useCallback(() => {
    const toggledFlexDirection = toggleReverseFlexDirection(
      parsedSelectedFlexDirection
    );

    saveChanges(toggledFlexDirection);
  }, [parsedSelectedFlexDirection, saveChanges]);

  return {
    isColumnSelected,
    handleColumnClick,
    isRowSelected,
    handleRowClick,
    isReverseSelected,
    handleReverseClick,
  };
};
