import * as uuid from 'uuid';
import { ComponentConfigWithStates as StylesConfig } from '@jux/types';
import { NodeType } from '@jux/data-entities';
import { COMPONENT_TAG_NAME } from '@jux/data-entities';
import { Colors } from '../common/colors';
import { ElementDataInit } from '../types/ElementDataInit';

export const DIV_ELEMENT_DEFAULT_ROOT_STYLES: StylesConfig = {
  root: {
    width: 'auto',
    height: 'auto',
    backgroundColor: Colors.Neutrals_0,
    display: 'flex',
    padding: '80px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'none',
    borderColor: '', // this is set so that DDP would not show browser default #767676 and show empty value instead
  },
  states: {},
};

export const DIV_ELEMENT_DEFAULT_DISPLAY_NAME = 'div';

export const getJuxDivElementData: ElementDataInit<{
  id?: string;
  styles?: StylesConfig;
  children?: any;
  displayName?: string;
}> = ({
  id = uuid.v4(),
  styles = DIV_ELEMENT_DEFAULT_ROOT_STYLES,
  children = [],
  displayName = DIV_ELEMENT_DEFAULT_DISPLAY_NAME,
  contextParentId,
  parentId,
} = {}) => {
  const rootDivId = id;
  return {
    root: {
      tagName: COMPONENT_TAG_NAME.JuxDiv,
      type: NodeType.ELEMENT,
      id: rootDivId,
      parentId,
      scopes: [],
      styles,
      config: {
        contextParentId,
        contextId: rootDivId,
        props: {},
        variants: [],
      },
      displayName,
      children, // only set id's not components because they are not supplied
    },
  };
};
