import { isEmpty, reverse } from 'lodash';
import { sort as semverSort } from 'semver';
import {
  Component,
  ComponentVersion,
} from '../prisma/components-service.prisma';

export type ComponentInfo = Pick<
  Component,
  'name' | 'space' | 'uuid' | 'updatedAt' | 'orgId'
> & {
  versions: Array<string>;
  versionsWithDate: Array<{ version: string; updatedAt: Date }>;
  latest: string;
  latestHasUnpublishedChanges: boolean;
};

export type ComponentWithVersions = Component & {
  versions?: ComponentVersion[];
};

export const getComponentInfo = (
  component: ComponentWithVersions
): ComponentInfo => {
  if (!component.versions) {
    component.versions = [];
  }

  const componentVersionsWithDate = component.versions.map(
    ({ version, updatedAt }) => ({
      version,
      updatedAt,
    })
  );

  const componentVersionsSemverSort = reverse(
    semverSort(component.versions.map((v) => v.version))
  );

  // Sort component versions by semver
  const componentVersions = componentVersionsWithDate?.sort(
    (a, b) =>
      componentVersionsSemverSort.indexOf(a.version) -
      componentVersionsSemverSort.indexOf(b.version)
  );

  const latestVersion =
    componentVersions.length > 0 ? componentVersions[0].version : '';

  const latestVersionEntity = component.versions.find(
    (v) => v.version === latestVersion
  );

  return {
    latest: latestVersion,
    latestHasUnpublishedChanges: !isEmpty(
      latestVersionEntity?.versionMetadata.unpublishedChanges
    ),
    name: component.name,
    orgId: component.orgId,
    space: component.space,
    updatedAt: latestVersionEntity?.updatedAt || component.updatedAt,
    uuid: component.uuid,
    versions: componentVersionsSemverSort as string[],
    versionsWithDate: componentVersionsWithDate,
  };
};
