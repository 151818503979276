import { CommonKeysValueType, useRegisterHotKey } from '@jux/canjux/core';
import { useCallback, useRef, useState } from 'react';
import { useEventListener } from 'usehooks-ts';

export const useIsKeyPressed = ({ key }: { key: CommonKeysValueType }) => {
  const [isPressed, setIsPressed] = useState(false);

  const documentRef = useRef<Document>(document);

  const onVisibilityChange = useCallback(() => {
    if (!documentRef.current.hidden || !documentRef.current.hasFocus()) {
      setIsPressed(false);
    }
  }, []);

  useEventListener('visibilitychange', onVisibilityChange, documentRef);
  useEventListener('blur', onVisibilityChange);

  useRegisterHotKey({
    action: key,
    callback: useCallback((e) => {
      setIsPressed(e.type === 'keydown');
    }, []),
    options: {
      keydown: true,
      keyup: true,
    },
  });

  return isPressed;
};
