import * as uuid from 'uuid';
import {
  NodeType,
  ComponentInstanceData,
  INHERITED_FROM_PARENT,
} from '@jux/data-entities';
import { ComponentConfigWithStates as StylesConfig } from '@jux/types';
import { TEXTAREA_DISABLED_STYLES, getJuxTextareaElementData } from '../';

export const TEXTAREA_ELEMENT_DEFAULT_DISPLAY_NAME = 'textarea';

export const NAKED_TEXTAREA_ELEMENT_DEFAULT_STYLES: StylesConfig = {
  root: {
    border: 'none',
    fontFamily: 'Inter',
    height: 'auto',
    minHeight: '194px',
    minWidth: '320px',
    outline: 'none',
    padding: '9px 12px',
    width: 'auto',
  },
  states: {},
  variants: [
    {
      propsValues: {
        disabled: true,
      },
      styles: TEXTAREA_DISABLED_STYLES,
    },
    {
      propsValues: {
        resizable: false,
      },
      styles: {
        resize: 'none',
      },
    },
  ],
};

export const getJuxTextareaInstanceElementData = ({
  displayName = TEXTAREA_ELEMENT_DEFAULT_DISPLAY_NAME,
  parentId,
}: {
  displayName?: string;
  parentId: string;
}) => {
  const newTextareaLocalComponent = getJuxTextareaElementData({
    styles: NAKED_TEXTAREA_ELEMENT_DEFAULT_STYLES,
  }).root;

  const instance: ComponentInstanceData = {
    type: NodeType.INSTANCE,
    id: uuid.v4(),
    parentId,
    children: [],
    config: {
      props: {
        disabled: INHERITED_FROM_PARENT,
        placeholder: INHERITED_FROM_PARENT,
        value: INHERITED_FROM_PARENT,
        resizable: true,
      },
    },
    displayName,
    sourceComponentId: newTextareaLocalComponent.id,
  };

  return {
    instance,
    source: newTextareaLocalComponent,
  };
};
