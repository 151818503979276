import { useCallback, useMemo } from 'react';
import { colorFieldSchema, parseIntoField } from './useColorField.utils';

export const useColorFieldValue = (rawValue: string) => {
  const parseValue = useCallback(
    (fieldValue: string) => parseIntoField(fieldValue),
    []
  );

  const parsedValue = useMemo(() => {
    const parsedVal = parseValue(rawValue);

    return parsedVal;
  }, [rawValue, parseValue]);

  return {
    parsedValue,
    rawValue,
    fieldSchema: colorFieldSchema,
    parseValue,
  };
};
