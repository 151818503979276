import { useCallback, useEffect, useMemo } from 'react';
import { KeepStateOptions, SetValueConfig } from 'react-hook-form';
import { z } from 'zod';
import {
  ALIAS_DESELECTION_TEXT,
  ALIAS_PLACEHOLDER_TEXT,
} from '@jux/ui/components/tokens/token-drawer/forms/helpers';
import { TokenFormProps } from '@jux/ui/components/tokens/token-drawer/forms/types';
import { useZodForm } from '@jux/ui/hooks/useZodForm';
import { bulkUpsertTokenInputSchema } from '@jux/ui/trpc/validations';
import { CORE } from '@jux/types';
import { StyledSelectFieldOptionsProps } from '../../base';

const KEEP_STATE_OPTIONS: KeepStateOptions = {
  keepDefaultValues: true,
  keepDirty: true,
  keepIsSubmitted: true,
  keepTouched: true,
  keepIsValid: true,
  keepSubmitCount: true,
  keepDirtyValues: true,
  keepErrors: true,
  keepValues: true,
};

const SET_FIELD_VALUE_OPTIONS: SetValueConfig = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true,
};

const THIS_SET = 'This set';

export type GetValueFn = (
  valuePath: string
) => z.infer<typeof bulkUpsertTokenInputSchema>['value'];

export const useFormValueAlias = <
  T extends ReturnType<typeof useZodForm>,
  P extends z.ZodType
>({
  form,
  getValue,
  existingTokenPaths,
  existingCoreTokenPaths,
  isCoreTokenSet,
}: {
  form: T;
  getValue: GetValueFn;
  existingTokenPaths: TokenFormProps<P>['existingTokenPaths'];
  existingCoreTokenPaths: TokenFormProps<P>['existingCoreTokenPaths'];
  isCoreTokenSet: boolean;
}) => {
  const aliasValue = form.watch('alias');

  const isAliasToken = Boolean(
    aliasValue &&
      aliasValue !== ALIAS_DESELECTION_TEXT &&
      aliasValue !== ALIAS_PLACEHOLDER_TEXT
  );

  const parseAliasOptions = useCallback(
    (tokenPaths: Array<string>) =>
      isAliasToken ? [ALIAS_DESELECTION_TEXT, ...tokenPaths] : tokenPaths,
    [isAliasToken]
  );

  const hasCoreTokens = Boolean(existingCoreTokenPaths.length);
  const hasSetTokens = Boolean(existingTokenPaths.length);

  const groupedAliasOptions = useMemo(
    /*
    hasCoreGroup:
    {
      Core: [...]
    }

    hasCoreGroup && isAliasToken:
    {
      Core: [ALIAS_DESELECTION_TEXT, ...],
    }

    hasSetGroup:
    {
      'This set': [...]
    }

    hasSetGroup && isAliasToken:
    {
      'This set': [ALIAS_DESELECTION_TEXT, ...],
    }

    hasCoreGroup && hasSetGroup:
    {
      Core: [...],
      'This set': [...],
    }

    hasCoreGroup && hasSetGroup && isAliasToken:
    {
      Core: [ALIAS_DESELECTION_TEXT, ...],
      'This set': [...],
    }
   */
    () => ({
      ...(hasCoreTokens && {
        [CORE]: parseAliasOptions(existingCoreTokenPaths).map((p) => ({
          label: p,
          value: p,
        })),
      }),
      ...(hasSetTokens && {
        [THIS_SET]: (hasCoreTokens
          ? existingTokenPaths
          : parseAliasOptions(existingTokenPaths)
        ).map((p) => ({
          label: p,
          value: p,
        })),
      }),
    }),
    [
      existingCoreTokenPaths,
      existingTokenPaths,
      hasCoreTokens,
      hasSetTokens,
      parseAliasOptions,
    ]
  );

  const hasAliases = useMemo(
    () => hasCoreTokens || hasSetTokens,
    [hasCoreTokens, hasSetTokens]
  );

  const aliasOptionsProps = useMemo((): StyledSelectFieldOptionsProps => {
    if (isCoreTokenSet) {
      return {
        options: parseAliasOptions(existingTokenPaths),
      };
    }

    return {
      groupedOptions: groupedAliasOptions,
    } as StyledSelectFieldOptionsProps;
  }, [
    existingTokenPaths,
    groupedAliasOptions,
    isCoreTokenSet,
    parseAliasOptions,
  ]);

  // effect to reset form values whenever alias is changed
  useEffect(() => {
    // when deselected - reset the alias field
    if (aliasValue === ALIAS_DESELECTION_TEXT) {
      // just setting values doesn't work without the reset call,
      // and neither the reset call works without calling setValue per field
      form.setValue('alias', '', SET_FIELD_VALUE_OPTIONS);
      // reset while keeping previous state, so the alias values would remain untouched
      form.reset({ alias: '' }, KEEP_STATE_OPTIONS);
    }
    // otherwise, when alias is selected - reset the value field
    else if (aliasValue && isAliasToken) {
      const value = getValue(aliasValue);

      // set the value field to the value of the selected alias
      form.setValue('value', value, SET_FIELD_VALUE_OPTIONS);

      // reset while keeping previous state, otherwise it doesn't work properly
      form.reset({ value }, KEEP_STATE_OPTIONS);
    }
  }, [aliasValue, form, isAliasToken, getValue]);

  return {
    aliasOptionsProps,
    hasAliases,
    aliasValue:
      !aliasValue || aliasValue === ALIAS_DESELECTION_TEXT
        ? ALIAS_PLACEHOLDER_TEXT
        : aliasValue,
    isAliasToken,
  };
};
