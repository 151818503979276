import { useAutofocus } from '@jux/ui/hooks';
import { selectAllText } from '@jux/ui/utils/textSelections';
import debounce from 'lodash/debounce';
import { TextValidatorFunction } from '@jux/ui/utils/textValidators';
import {
  FocusEvent,
  FormEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { mergeRefs } from 'react-merge-refs';
import { toast } from '@jux/ui/toast';
import { useRegisterHotKey } from '../../hooks/useHotkeys/useRegisterHotKeys';
import { keyboardActionKeys } from '../../hooks/useHotkeys/keys';
import { useAcceptTextChange } from '../../hooks/useHotkeys/useAcceptTextChange';

/** Allow forwarding callback handlers of paragraph type elements, because some of them are being handled internally */
type UseEditableTextElementInput = Partial<
  Pick<JSX.IntrinsicElements['p'], 'onBlur' | 'onInput' | 'onFocus'>
> & {
  text: string;
  autoFocus?: boolean;
  debounceWait: number;
  validators?: Array<TextValidatorFunction>;
  onTextChange?: (newText: string) => void;
  onDimensionsChange?: (width: number, height: number) => void;
};

export const useEditableTextElement = ({
  text,
  autoFocus,
  debounceWait,
  validators,
  onTextChange,
  onDimensionsChange,
  onBlur,
  onInput,
  onFocus,
}: UseEditableTextElementInput) => {
  const [initialText, setInitialText] = useState(text);
  const elementRef = useRef<HTMLParagraphElement>(null);

  const handleChangeTextWithDebounce = useMemo(
    () =>
      onTextChange
        ? debounce(() => {
            if (!elementRef?.current) return;

            onTextChange(elementRef.current.innerText);
          }, debounceWait)
        : undefined,
    [debounceWait, onTextChange]
  );

  useAutofocus({
    ref: elementRef,
    shouldAutoFocus: autoFocus,
  });

  // TODO: move to a common util
  const isTextValid = useCallback(
    (textChange: string) => {
      if (!validators) return true;

      const trimmedTextChange = textChange.trim();

      return validators.every((validate) => {
        const { isValid, message } = validate(trimmedTextChange);

        if (!isValid && message) {
          toast.error(message);
        }

        return isValid;
      });
    },
    [validators]
  );

  const discardChanges = useCallback(() => {
    if (!elementRef.current) return;

    elementRef.current.textContent = initialText;
  }, [initialText]);

  const submitChangesRef = useAcceptTextChange({
    callback: () => {
      if (!elementRef.current) return;
      if (!isTextValid(elementRef.current.innerText)) {
        discardChanges();
      }
      elementRef.current.blur();
    },
    options: {
      enableOnContentEditable: true,
      keydown: true,
    },
    dependencies: [isTextValid, discardChanges],
  });

  const discardChangesRef = useRegisterHotKey({
    action: keyboardActionKeys.DISCARD_CHANGES,
    callback: () => {
      if (!elementRef.current) return;

      elementRef.current.blur();
    },
    options: {
      enableOnContentEditable: true,
      keydown: true,
    },
  });

  const handleInput = useCallback(
    (e: FormEvent<HTMLParagraphElement>) => {
      const target = e.target as HTMLParagraphElement;

      handleChangeTextWithDebounce?.();
      onDimensionsChange?.(target.clientWidth, target.clientHeight);
      onInput?.(e);
    },
    [handleChangeTextWithDebounce, onDimensionsChange, onInput]
  );

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLParagraphElement>) => {
      selectAllText(e.target);
      onFocus?.(e);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLParagraphElement>) => {
      const target = e.target;
      const currText = isTextValid(target.innerText)
        ? target.innerText
        : initialText;

      target.scrollLeft = 0;

      // update initial text and listeners to the text last change
      setInitialText(currText);
      onTextChange?.(currText);
      onDimensionsChange?.(target.clientWidth, target.clientHeight);

      onBlur?.(e);
    },
    [initialText, isTextValid, onBlur, onDimensionsChange, onTextChange]
  );

  return {
    ref: mergeRefs([elementRef, submitChangesRef, discardChangesRef]),
    children: initialText,
    onBlur: handleBlur,
    onInput: handleInput,
    onFocus: handleFocus,
  };
};
