import {
  type ComponentConfig,
  type ComponentConfigWithStates,
  DEFAULT_STATE,
  type StylesState,
  type StyleVariantsList,
  ContextStyle,
} from '@jux/types';
import deepmerge from 'deepmerge';
import { getContextChildSelector } from './getContextChildSelector';

const isContextStyleMatchingState = ({
  contextStyle,
  currentState,
}: {
  contextStyle: ContextStyle;
  currentState: string;
}) => {
  const conditionState = contextStyle?.condition?.state;
  if (!conditionState || conditionState === DEFAULT_STATE) {
    return true;
  }

  return currentState === conditionState;
};

const getVariantsWithContextStylesByState = (
  { states, ...styles }: ComponentConfigWithStates,
  state: NonNullable<StylesState>
): StyleVariantsList =>
  (styles.variants || [])?.concat(
    states[state]?.variants || [],
    (styles.contextStyles || [])
      .filter((contextStyle) =>
        isContextStyleMatchingState({ contextStyle, currentState: state })
      )
      .map((contextStyle) => ({
        propsValues: contextStyle.condition?.propsValues || {},
        styles: {
          [getContextChildSelector(contextStyle.contextChildUuid)]:
            contextStyle.styles,
        },
      }))
  );

const getDefaultVariantsWithContextStyles = (
  styles: ComponentConfig
): StyleVariantsList =>
  (styles.variants || []).concat(
    (styles.contextStyles || [])
      .filter(
        (contextStyle) =>
          !contextStyle.condition?.state ||
          contextStyle.condition?.state === DEFAULT_STATE
      )
      .map((contextStyle) => ({
        propsValues: contextStyle.condition?.propsValues || {},
        styles: {
          [getContextChildSelector(contextStyle.contextChildUuid)]:
            contextStyle.styles,
        },
      }))
  );

/**
 * Returns the styles for the specified state.
 * If the state is not found, the default styles are returned.
 * @param styles {ComponentConfigWithStates} - The component's config with states.
 * @param state {NonNullable<StylesState>} - The state to set
 */
export const getStateOverrideStyles = (
  styles: ComponentConfigWithStates,
  state: NonNullable<StylesState>
): ComponentConfig => {
  const { states, ...stylesWithoutStates } = styles;

  const stateStyles = state in states ? states[state] : undefined;

  // Check if there are context styles for the specified state
  const contextStyles = stylesWithoutStates.contextStyles?.filter(
    (contextStyle) =>
      isContextStyleMatchingState({ contextStyle, currentState: state })
  );

  if (!stateStyles && !contextStyles?.length) {
    // No state styles found
    return stylesWithoutStates;
  }

  const rootStyles = stateStyles
    ? deepmerge(stylesWithoutStates.root, stateStyles.root || {})
    : stylesWithoutStates.root;

  let variantsStyles: StyleVariantsList;
  if (state === DEFAULT_STATE) {
    variantsStyles = getDefaultVariantsWithContextStyles(stylesWithoutStates);
  } else {
    variantsStyles = getVariantsWithContextStylesByState(styles, state);
  }

  return {
    root: rootStyles,
    variants: variantsStyles,
    // context styles are already merged into the variants styles
  };
};
