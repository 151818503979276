import { useCallback, useMemo } from 'react';
import {
  DDPSubModulesStateKeys,
  useDDPSubModulesState,
} from '@jux/ui/components/editor/state';
import { useShouldRenderSubModules } from './useShouldRenderSubModules';

export const useSubModule = <Key extends DDPSubModulesStateKeys>(key: Key) => {
  const { getSubModulesStateByKey, setSubModulesStateByKey } =
    useDDPSubModulesState();
  const { shouldRenderSubModule } = useShouldRenderSubModules();

  const subModuleState = useMemo(
    () => getSubModulesStateByKey(key),
    [getSubModulesStateByKey, key]
  );

  const shouldRender = useMemo(
    () => shouldRenderSubModule(key),
    [shouldRenderSubModule, key]
  );

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      setSubModulesStateByKey(key, { isOpen });
    },
    [key, setSubModulesStateByKey]
  );

  return {
    isOpen: subModuleState?.isOpen,
    setIsOpen,
    shouldRender,
  };
};
