import type { Draft as WritableDraft } from 'mutative';
import { ContextStyle, EnumPropValue, StyleVariant } from '@jux/types';
import { JuxStore } from '@jux/canjux/core';

export const updateStylesDeleteProp = ({
  variantsStyles,
  propName,
  defaultValue,
}: {
  variantsStyles: WritableDraft<StyleVariant>[];
  propName: string;
  defaultValue: EnumPropValue;
}) => {
  const filteredVariantStyles = variantsStyles.filter((variantStyles) =>
    // if it's not the "default value" of this prop, remove it.
    // keep styles that don't have this prop in their condition
    propName in variantStyles.propsValues
      ? variantStyles.propsValues[propName] === defaultValue
      : true
  );

  // remove the default value of the deleted prop from the condition
  for (const variant of filteredVariantStyles) {
    if (propName in variant.propsValues) {
      delete variant.propsValues[propName];
    }
  }

  return filteredVariantStyles;
};

export const updateContextStylesDeleteProp = ({
  contextStyles,
  propName,
  defaultValue,
}: {
  contextStyles: WritableDraft<ContextStyle>[];
  propName: string;
  defaultValue: EnumPropValue;
}) => {
  const filteredContexts = contextStyles.filter((contextStyle) => {
    const contextStylesCondition = contextStyle?.condition?.propsValues;
    // keep context styles that don't have this prop in their condition
    // or context styles that have it but with the default value of this prop
    return contextStylesCondition && propName in contextStylesCondition
      ? contextStylesCondition[propName] === defaultValue
      : true;
  });

  // remove the default value of the deleted prop from the condition
  for (const contextStyle of filteredContexts) {
    const conditionProps = contextStyle?.condition?.propsValues;

    const propToDeleteExists = conditionProps && propName in conditionProps;
    if (propToDeleteExists) {
      delete conditionProps[propName];
    }
  }

  return filteredContexts;
};

export const deletePropInAllInstances = ({
  componentId,
  propName,
  components,
}: {
  componentId: string;
  propName: string;
  components: WritableDraft<JuxStore['components']>;
}) => {
  const instances = Object.values(components).filter(
    (component) => component?.sourceComponentId === componentId
  );

  for (const instance of instances) {
    delete instance.config.props[propName];
  }
};
