import { useCallback, useMemo } from 'react';
import capitalize from 'lodash/capitalize';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import {
  useDimensionField,
  useFieldValues,
  useSetFieldValue,
} from '../../../../hooks';
import { combineGapValue, parseGapValue } from './GapField.utils';

const FIELD_KEY = DDPPrimitiveFieldsKeys.gap;

export const useGapField = () => {
  const { value, initialValue } = useFieldValues(FIELD_KEY);
  const { saveValue: setFieldValue } = useSetFieldValue(FIELD_KEY);

  const { columnGap: columnGapValue, rowGap: rowGapValue } = useMemo(
    () => parseGapValue(value),
    [value]
  );

  const { columnGap: columnGapInitialValue, rowGap: rowGapInitialValue } =
    useMemo(() => parseGapValue(initialValue), [initialValue]);

  const handleSaveChanges = useCallback(
    ({
      rowGap: newRowGap,
      columnGap: newColumnGap,
    }: {
      rowGap?: string;
      columnGap?: string;
    }) => {
      if (newRowGap) {
        const parsedNewGap = combineGapValue({
          rowGap: newRowGap,
          columnGap: columnGapValue ?? columnGapInitialValue,
        });
        setFieldValue(parsedNewGap);
        return;
      }
      if (newColumnGap) {
        const parsedNewGap = combineGapValue({
          rowGap: rowGapValue ?? rowGapInitialValue,
          columnGap: newColumnGap,
        });
        setFieldValue(parsedNewGap);
        return;
      }
    },
    [
      columnGapInitialValue,
      columnGapValue,
      rowGapInitialValue,
      rowGapValue,
      setFieldValue,
    ]
  );

  const handleRowGapSaveChanges = useCallback(
    (newRowGapValue: string | undefined) => {
      handleSaveChanges({ rowGap: newRowGapValue });
    },
    [handleSaveChanges]
  );
  const handleColumnGapSaveChanges = useCallback(
    (newColumnGapValue: string | undefined) => {
      handleSaveChanges({ columnGap: newColumnGapValue });
    },
    [handleSaveChanges]
  );

  const rowGapProps = useDimensionField({
    value: rowGapValue,
    initialValue: rowGapInitialValue,
    saveChanges: handleRowGapSaveChanges,
    fieldName: `rows${capitalize(FIELD_KEY)}`,
  });

  const columnGapProps = useDimensionField({
    value: columnGapValue,
    initialValue: columnGapInitialValue,
    saveChanges: handleColumnGapSaveChanges,
    fieldName: `columns${capitalize(FIELD_KEY)}`,
  });

  return {
    rowGapProps,
    columnGapProps,
  };
};
