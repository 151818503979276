import type { Draft as WritableDraft } from 'mutative';
import { JuxStore } from '@jux/canjux/core';
import logger from '@jux/ui-logger';
import { StateValidator } from './validator.interface';

export const validateNodesComponents: StateValidator = ({
  state,
  recover = false,
}: {
  state: WritableDraft<JuxStore>;
  recover?: boolean;
}) => {
  for (const canvas of Object.values(state.canvases)) {
    const nodeIds = Object.keys(canvas.nodes);
    const componentIds = Object.keys(state.components);

    const missingComponents = nodeIds.filter(
      (nodeId) => !componentIds.includes(nodeId)
    );

    for (const nodeId of missingComponents) {
      if (recover) {
        logger.info(
          `data recovery: removing bad node ${nodeId} that does not have a component`
        );

        // delete node, if this node is listed as somebody's child, the parent will recover on it's own
        delete canvas.nodes[nodeId];
      } else {
        throw new Error(`Node ${nodeId} does not have a component`);
      }
    }
  }
};
