import { useCallback } from 'react';
import { useStore } from '@jux/canjux/core';

export const useUpdateComponentVariantsEditingContext = () => {
  const { updateComponentVariantsEditingContext } = useStore(
    (state) => state.ddpActions
  );

  return useCallback(
    ({
      id,
      propName,
      propValue,
    }: {
      id: string;
      propName: string;
      propValue: string;
    }) => {
      updateComponentVariantsEditingContext({
        nodeId: id,
        propName,
        propValue,
      });
    },
    [updateComponentVariantsEditingContext]
  );
};
