import {
  COMPONENT_TAG_NAME,
  ComponentElementType,
  ComponentProps,
} from '@jux/data-entities';
import { forwardRef } from 'react';

export const IconButton = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxIconButton>,
  ComponentProps<typeof COMPONENT_TAG_NAME.JuxIconButton>
>(({ children, rootElement: RootElement = 'button', ...props }, ref) => (
  <RootElement ref={ref} {...props}>
    {children}
  </RootElement>
));
