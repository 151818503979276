/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from './core/CustomIcon';

export const CloseIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M12.198 3.80199C11.9098 3.51372 11.4424 3.51372 11.1541 3.80199L8.00003 6.95607L4.84594 3.80199C4.55767 3.51372 4.09029 3.51372 3.80202 3.80199C3.51375 4.09026 3.51375 4.55764 3.80202 4.84591L6.95611 8L3.80202 11.1541C3.51375 11.4424 3.51375 11.9097 3.80202 12.198C4.09029 12.4863 4.55767 12.4863 4.84594 12.198L8.00003 9.04392L11.1541 12.198C11.4424 12.4863 11.9098 12.4863 12.198 12.198C12.4863 11.9097 12.4863 11.4424 12.198 11.1541L9.04395 8L12.198 4.84591C12.4863 4.55764 12.4863 4.09026 12.198 3.80199Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
