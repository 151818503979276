import { FC, useCallback } from 'react';
import { IconButton } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { useCreateNewProperty, useParsedProperties } from './hooks';

export const PropertiesActions: FC = () => {
  const { createNewProperty } = useCreateNewProperty();
  const { hasProperties } = useParsedProperties();

  const handleOnClick = useCallback(() => {
    createNewProperty();
  }, [createNewProperty]);

  return (
    <MuiGrid
      container
      item
      sx={{
        padding: hasProperties ? '16px 20px 24px 20px' : '24px 20px',
        position: 'sticky',
        zIndex: 1,
        bottom: 0,
        backgroundColor: (theme) => theme.drimz.palette.background.default,
      }}
    >
      <IconButton
        fullWidth
        variant="secondary"
        icon="ADD"
        onClick={handleOnClick}
      >
        Add new property
      </IconButton>
    </MuiGrid>
  );
};
