import { getContextParentByNodeId, JuxStore } from '@jux/canjux/core';
import { ComponentSourceData } from '@jux/data-entities';

export const getContextParentOfNode = ({
  components,
  nodeId,
}: {
  components: JuxStore['components'];
  nodeId: string;
}) => {
  const contextParentId = getContextParentByNodeId({ id: nodeId, components });

  return contextParentId
    ? (components[contextParentId] as ComponentSourceData)
    : undefined;
};
