import { FC } from 'react';
import { ModulesKeys } from '../../DDP.interface';
import { Module } from '../../base';
import { useShouldRenderModuleByKey } from '../../hooks/useShouldRenderModuleByKey';
import { Shadow } from './sub-modules';

const MODULE_KEY = ModulesKeys.effects;

export const Effects: FC = () => {
  const { shouldRender } = useShouldRenderModuleByKey(MODULE_KEY);

  if (!shouldRender) return null;
  return (
    <Module moduleKey={MODULE_KEY} title="Effects">
      <Shadow />
    </Module>
  );
};
