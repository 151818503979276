import { useCallback, useMemo } from 'react';
import { SegmentEvent } from '@jux/events/segment/segment.events';
import { SegmentTrackEvents } from '@jux/events/segment/segment.interface';
import { useAnalyticsContextValue, useUserIdentityState } from '@jux/ui/state';
import { api } from '../trpc/client/api';

export const useAnalytics = () => {
  const { anonymousId, userIdentity } = useUserIdentityState();
  const context = useAnalyticsContextValue();

  const userId = useMemo(() => userIdentity?.userId, [userIdentity?.userId]);

  const orgId = useMemo(
    () => userIdentity?.org?.id?.toString(),
    [userIdentity?.org?.id]
  );

  const orgName = useMemo(
    () => userIdentity?.org?.name,
    [userIdentity?.org?.name]
  );

  // analytics data
  const trackEventMutation = api.analytics.track.useMutation();
  const pageEventMutation = api.analytics.page.useMutation();
  const identifyEventMutation = api.analytics.identify.useMutation();
  const groupEventMutation = api.analytics.group.useMutation();

  // custom track function to keep a closed list of events
  const trackEvent = useCallback(
    ({
      eventName,
      properties,
    }: {
      eventName: SegmentEvent;
      properties?: SegmentTrackEvents;
    }) => {
      trackEventMutation.mutateAsync({
        event: eventName,
        userId,
        anonymousId,
        properties: {
          ...properties,
          ...context,
          org_id: orgId,
          user_id: userId,
        },
      });
    },
    // we do need to re-create the function when the analytics mutation function changes too
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [anonymousId, context, orgId, userId]
  );

  // custom page loaded event
  const pageLoadedEvent = useCallback(
    (pageName: string) => {
      pageEventMutation.mutateAsync({
        anonymousId,
        pageName,
        properties: {
          org_id: orgId,
        },
      });
    },
    // we do need to re-create the function when the analytics mutation function changes too
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [anonymousId, orgId]
  );

  // custom identify event
  const identifyEvent = useCallback(() => {
    if (!userIdentity || !userId) return;

    identifyEventMutation.mutateAsync({
      userId,
      traits: userIdentity.traits,
    });
    // we do need to re-create the function when the analytics mutation function changes too
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIdentity]);

  // custom group event
  const groupEvent = useCallback(() => {
    if (!userId || !orgId || !orgName) return;

    groupEventMutation.mutateAsync({
      userId,
      groupId: orgId,
      groupName: orgName,
    });
    // we do need to re-create the function when the analytics mutation function changes too
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, orgId, orgName]);

  return {
    trackEvent,
    pageLoadedEvent,
    identifyEvent,
    groupEvent,
  };
};
