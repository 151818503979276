import { FC, useCallback, useRef } from 'react';
import * as UI from '@jux/ui-core';
import {
  KeyboardShortcutPopover,
  Tooltip,
  useMenuPopper,
  useOpenInviteMembersModal,
} from '@jux/ui/components';
import { useTheme } from '@jux/ui/components/common/mui';
import { useToggleSidebar, useTrackEvents } from '@jux/ui/hooks';
import { LiveOrEdit } from '../panels';
import { MiddleSlot } from './components';
import {
  useAddElementToCanvasOnClick,
  useAssetsPopper,
  useComponentsPopper,
  useElementsPopper,
} from './hooks';

export const Navigation: FC = () => {
  const theme = useTheme();

  const elementsRef = useRef<HTMLDivElement | null>(null);
  const { openElementsPopper, isElementPopperOpen } = useElementsPopper();

  const componentsRef = useRef<HTMLDivElement | null>(null);
  const { openComponentsPopper, isComponentsPopperOpen } =
    useComponentsPopper();

  const assetsRef = useRef<HTMLDivElement | null>(null);
  const { openAssetsPopper, isAssetsPopperOpen } = useAssetsPopper();

  const divIconButtonRef = useRef<HTMLButtonElement>(null);
  const textIconButtonRef = useRef<HTMLButtonElement>(null);
  const inviteUsersIconButtonRef = useRef<HTMLButtonElement>(null);
  const menuVerticalIconButtonRef = useRef<HTMLButtonElement>(null);

  const { open: openSidebar } = useToggleSidebar();

  const { handleAddElementToCanvasOnClick } = useAddElementToCanvasOnClick();
  const { openInviteMembersModal } = useOpenInviteMembersModal('editor');

  const { openMenuPopper } = useMenuPopper();

  const { trackOpenSidebar, trackNavigationClick } = useTrackEvents();

  const handleDivIconClick = useCallback(() => {
    handleAddElementToCanvasOnClick('JuxDiv');
  }, [handleAddElementToCanvasOnClick]);

  const handleTextIconClick = useCallback(() => {
    handleAddElementToCanvasOnClick('JuxText');
  }, [handleAddElementToCanvasOnClick]);

  const handleInviteUsersIconClick = useCallback(() => {
    openInviteMembersModal();
  }, [openInviteMembersModal]);

  const handleLogoClick = useCallback(() => {
    openSidebar();
    trackOpenSidebar();
  }, [openSidebar, trackOpenSidebar]);

  const handleMenuVerticalIconClick = useCallback(() => {
    openMenuPopper({ sourceElementRef: menuVerticalIconButtonRef });
  }, [openMenuPopper]);

  const handleElementsClick = useCallback(() => {
    openElementsPopper(elementsRef);
    trackNavigationClick({ name: 'elements' });
  }, [openElementsPopper, trackNavigationClick]);

  const handleComponentsClick = useCallback(() => {
    openComponentsPopper(componentsRef);
    trackNavigationClick({ name: 'components' });
  }, [openComponentsPopper, trackNavigationClick]);

  const handleAssetsClick = useCallback(() => {
    openAssetsPopper(assetsRef);
    trackNavigationClick({ name: 'assets' });
  }, [openAssetsPopper, trackNavigationClick]);

  return (
    <>
      <UI.Navigation
        divIconButtonRef={divIconButtonRef}
        textIconButtonRef={textIconButtonRef}
        inviteUsersIconButtonRef={inviteUsersIconButtonRef}
        menuVerticalIconButtonRef={menuVerticalIconButtonRef}
        onDivIconClick={handleDivIconClick}
        onInviteUsersIconClick={handleInviteUsersIconClick}
        onLogoClick={handleLogoClick}
        onMenuVerticalIconClick={handleMenuVerticalIconClick}
        onTextIconClick={handleTextIconClick}
        elementsRef={elementsRef}
        onElementsClick={handleElementsClick}
        isElementsOpen={isElementPopperOpen}
        componentsRef={componentsRef}
        onComponentsClick={handleComponentsClick}
        isComponentsOpen={isComponentsPopperOpen}
        assetsRef={assetsRef}
        onAssetsClick={handleAssetsClick}
        isAssetsOpen={isAssetsPopperOpen}
        middleSlot={<MiddleSlot />}
        editLiveSwitchSlot={<LiveOrEdit />}
      />
      <KeyboardShortcutPopover offset={theme.drimz.size.editor.ddp.width} />
      <Tooltip content="Div" reference={divIconButtonRef} />
      <Tooltip content="Text" reference={textIconButtonRef} />
      <Tooltip
        content="Invite new members"
        reference={inviteUsersIconButtonRef}
      />
      <Tooltip
        content="Knowledge base and more..."
        reference={menuVerticalIconButtonRef}
      />
    </>
  );
};
