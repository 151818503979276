import { ComponentConfig, ContextStyle } from '@jux/types';
import deepmerge from 'deepmerge';

// Combine contextStyles that have the same propsValues
export const dedupeContextStyles = <T extends ComponentConfig>(
  styles: T
): T => {
  const { contextStyles = [] } = styles;

  if (!contextStyles.length) return styles;

  return {
    ...styles,
    contextStyles: Object.values(
      contextStyles.reduce((acc, contextStyle) => {
        const key =
          `${contextStyle.contextChildUuid}:` +
          (contextStyle.condition?.state
            ? `${contextStyle.condition.state}:`
            : '') +
          Object.entries(contextStyle.condition?.propsValues || {})
            .map(([prop, value]) => `${prop}:${value}`)
            .sort()
            .join(',');

        if (!acc[key]) {
          acc[key] = contextStyle;
        } else {
          acc[key] = deepmerge(acc[key], contextStyle);
        }

        return acc;
      }, {} as Record<string, ContextStyle>)
    ),
  };
};
