import type { Draft as WritableDraft } from 'mutative';
import { JuxStore } from '@jux/canjux/core';
import { addStorageNode } from '../../../store.changes.utils';
import { createInstanceNode } from './createInstanceNode';

/**
 * Recursively creates instance nodes for a given component and its children on the new parent.
 * @param componentId - The ID of the component.
 * @param parentId - The ID of the parent component.
 * @param targetIndex - The index at which the instance node should be inserted.
 * @param state - The draft of the store.
 * @returns The ID of the newly created instance node.
 */
const recursiveCreateInstanceNodes = ({
  componentId,
  parentId,
  state,
}: {
  componentId: string;
  parentId: string;
  state: WritableDraft<JuxStore>;
}): string => {
  const { components } = state;

  const component = components[componentId];

  const newInstanceId = createInstanceNode({
    sourceComponentId: componentId,
    parentId,
    state,
  });

  for (const childId of component.children) {
    components[newInstanceId].children.push(
      recursiveCreateInstanceNodes({
        componentId: childId,
        parentId: newInstanceId,
        state,
      })
    );
  }

  return newInstanceId;
};

/**
 * Adds instance nodes tree of an inserted node to all instances of the root component.
 * This function updates other using components + canvases.
 * @param sourceNodeId - The ID of the source root node - tree of nodes.
 * @param targetNodeId - The ID of the target node.
 * @param targetIndex - The index of insertion on the target node children.
 * @param state - The draft of the store.
 */
export const addInstanceNodes = ({
  sourceNodeId,
  targetNodeId,
  targetIndex,
  state,
}: {
  sourceNodeId: string;
  targetIndex: number;
  targetNodeId: string;
  state: WritableDraft<JuxStore>;
}) => {
  const { components } = state;

  const parentFirstGradeInstances = Object.values(components).filter(
    (component) => component?.sourceComponentId === targetNodeId
  );

  for (const parentInstance of parentFirstGradeInstances) {
    // we create a component tree of instances of the source node and put them under each of the instances of the target node
    const newInstanceId = recursiveCreateInstanceNodes({
      componentId: sourceNodeId,
      parentId: parentInstance.id,
      state,
    });

    addStorageNode(parentInstance.children, newInstanceId, targetIndex);

    // set 2nd level and deeper nested instances
    addInstanceNodes({
      sourceNodeId: newInstanceId,
      targetNodeId: parentInstance.id,
      targetIndex,
      state,
    });
  }
};
