import { useCallback, useEffect, useState } from 'react';
import { useOnScroll, useOnWindowResize } from '@jux/ui/hooks';
import { calculatePositionByElement } from '../utils';
import {
  PopperSourceElement,
  PopperStyles,
} from '../base-hooks/usePopperState.interface';

export const usePopperPositionStyles = ({
  sourceElement,
  styles,
}: {
  sourceElement: PopperSourceElement;
  styles: PopperStyles;
}) => {
  const [, setShouldRecalculate] = useState(false);

  // calculation is cheaper than using memo
  const calculatedPosition = calculatePositionByElement({
    element: sourceElement,
    positioning: styles?.positioning,
    offset: styles?.offset,
    direction: styles?.direction,
  });

  const setPositionStyles = useCallback(() => {
    setShouldRecalculate((prev) => !prev);
  }, []);

  useOnWindowResize(setPositionStyles);
  useOnScroll(setPositionStyles);

  useEffect(() => {
    setPositionStyles();
  }, [setPositionStyles]);

  return calculatedPosition;
};
