import { useEffect } from 'react';
import { useStore, useStoreActions } from '@jux/canjux/core';
import { LIVEBLOCKS_READY_CLASS } from '@jux/constants';

export const useRepairStateOnConnection = () => {
  const {
    commonActions: { repairState, triggerLayersUpdate },
  } = useStoreActions();
  const { liveblocksStatus, isStorageLoading } = useStore((s) => ({
    liveblocksStatus: s.liveblocks.status,
    isStorageLoading: s.liveblocks.isStorageLoading,
  }));

  useEffect(() => {
    if (!isStorageLoading && liveblocksStatus === 'connected') {
      repairState();
      triggerLayersUpdate({});
      document.body.classList.add(LIVEBLOCKS_READY_CLASS);
    }
  }, [isStorageLoading, liveblocksStatus, repairState, triggerLayersUpdate]);
};
