import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { TooltipTypography, Typography } from '@jux/ui/components';
import { TokenFieldContentWrapper } from '../tokenFieldContent';
import { ColorIndicatorDiv } from './ColorIndicator';

export const ColorTokenFieldContent: FC<{
  rgbaColor: string;
  tokenDisplayName: string;
  tokenHexValue: string;
  openTokens?: () => void;
}> = ({ rgbaColor, openTokens, tokenDisplayName, tokenHexValue }) => (
  <TokenFieldContentWrapper onClick={openTokens}>
    <MuiGrid item flex={0}>
      <ColorIndicatorDiv
        color={rgbaColor}
        tokenHexValue={tokenHexValue}
        isTokenized
      />
    </MuiGrid>
    <TooltipTypography
      variant="label-s-regular"
      flex={1}
      tooltipContent={(hasContentOverflow) =>
        hasContentOverflow ? tokenDisplayName : undefined
      }
    >
      {tokenDisplayName}
    </TooltipTypography>
    <Typography
      variant="label-s-medium"
      color={(theme) => theme.drimz.palette.text.secondary}
      flex={0}
    >
      {tokenHexValue}
    </Typography>
  </TokenFieldContentWrapper>
);
