import { MuiGrid, styled } from '@jux/ui/components/common/mui';

export const DragPreview = styled(MuiGrid)(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  opacity: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
}));

export const NameIconContainer = styled(MuiGrid)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '4px',
  flexWrap: 'nowrap',
}));
