import { useState, RefObject } from 'react';
import { useEventListener } from 'usehooks-ts';

export function useFocusWithin<T extends HTMLElement>(ref: RefObject<T>) {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocusIn = () => setIsFocused(true);
  const handleFocusOut = () => setIsFocused(false);

  useEventListener('focusin', handleFocusIn, ref);
  useEventListener('focusout', handleFocusOut, ref);

  return isFocused;
}
