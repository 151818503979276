import { CanjuxState } from '@jux/canjux/core';
import { createSelector } from 'reselect';
import { getSelectedNodeComponent } from './utils';
import { getResolvedSourceComponentData } from './utils/getResolvedSourceComponentData';

export const selectedNodeSourceComponent = createSelector(
  [
    (state: CanjuxState) => state.components,
    (state: CanjuxState) => state.selectedNodesStack,
  ],
  (components, selectedNodesStack) => {
    const component = getSelectedNodeComponent({
      components,
      selectedNodesStack,
    });
    if (!component) {
      return undefined;
    }

    return getResolvedSourceComponentData({
      component,
      components,
    });
  }
);
