import {
  DDPActionsParams,
  CanjuxState,
  JuxStoreActionFn,
} from '@jux/canjux/core';

/**
 * Set placeholder mode for elements with placeholders
 */
export const setPlaceholderMode: JuxStoreActionFn<
  DDPActionsParams['setPlaceholderMode'],
  CanjuxState
> = ({ nodeId, mode, state }) => {
  state.placeholderMode[nodeId] = mode;
  return state;
};
