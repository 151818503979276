import { FC } from 'react';
import {
  EDITOR_DDP_INACTIVE_FIELD_VALUE_COLOR,
  SELECT_FIELD_BOX_BORDER_COLOR,
} from '@jux/ui/theme/palette';
import { TooltipTypography } from '@jux/ui/components';
import { TokenFieldContent } from '../tokenFieldContent';
import { SelectFieldAutocomplete } from '../autocomplete';
import { SelectFieldContentProps } from './SelectField.interface';

export const SelectFieldContent: FC<SelectFieldContentProps> = ({
  isFieldDisabled,
  value,
  rawValue,
  autocompleteProps,
  htmlForTarget,
  tokensProps,
}) => {
  const { tokensPopperProps, isTokenized, tokenDisplayName, tokenParsedValue } =
    tokensProps ?? {};
  const { openTokens } = tokensPopperProps ?? {};

  if (isTokenized && tokenDisplayName) {
    return (
      <TokenFieldContent
        isFieldDisabled={isFieldDisabled}
        onClick={openTokens}
        tokenName={tokenDisplayName}
      >
        <TooltipTypography
          variant="label-xs-medium"
          color={(theme) => theme.drimz.palette.text.secondary}
        >
          {tokenParsedValue?.toString() ?? ''}
        </TooltipTypography>
      </TokenFieldContent>
    );
  }

  if (isFieldDisabled) {
    return (
      <TooltipTypography
        variant="label-s-regular"
        sx={{ color: EDITOR_DDP_INACTIVE_FIELD_VALUE_COLOR }}
      >
        {rawValue}
      </TooltipTypography>
    );
  }

  return (
    <SelectFieldAutocomplete
      value={value}
      hideSearchIcon
      optionsWrapperProps={{
        sx: {
          position: 'absolute',
          top: '100%',
          left: 0,
          transform: 'translateY(1px)',

          backgroundColor: (theme) => theme.drimz.palette.background.default,
          border: '1px solid',
          borderColor: SELECT_FIELD_BOX_BORDER_COLOR,
          borderRadius: (theme) => theme.drimz.size.borderRadius.main,
        },
      }}
      inputProps={{
        sx: {
          width: 'min-content',
          flexGrow: 1,
        },
      }}
      {...autocompleteProps}
      inputName={htmlForTarget}
    />
  );
};
