import { FC, useCallback, useMemo } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import { DesignTokenTypeEnum, formatToAlias } from '@jux/design-tokens';
import { CORE } from '@jux/types';
import {
  FieldContainer,
  StyledSelectField,
  StyledTextField,
  TITLE_CREATE_NEW_TOKEN,
  TITLE_EDIT_TOKEN,
  TokenDrawerFormLayout,
  TokenDrawerIconLabel,
  TokenNameField,
} from '@jux/ui/components/tokens/token-drawer/base';
import {
  ALIAS_PLACEHOLDER_TEXT,
  DIMENSION_PATH_PREFIX,
  formatAliasPath,
  formatGroupPath,
  GetValueFn,
  useFormValueAlias,
  useInitialValues,
  useRegisterFieldFns,
  useValidateTokenName,
} from '@jux/ui/components/tokens/token-drawer/forms/helpers';
import { TokenFormProps } from '@jux/ui/components/tokens/token-drawer/forms/types';
import { useTokenDrawer } from '@jux/ui/components/tokens/token-drawer/useTokenDrawer';
import { getUpdatedTokenData } from '@jux/ui/components/tokens/token-drawer/utils/getUpdatedTokenData';
import { useZodForm } from '@jux/ui/hooks/useZodForm';
import { dimensionTokenInputSchema } from '@jux/ui/trpc/validations';
import { createPath } from '@jux/ui/utils/tokensPath';

const validateDimensionInitialValue = <T extends object>(initialValues: T) => {
  const initialValue = (initialValues as { value: any }).value;

  let value = initialValue?.split(' ')[0] ?? '';

  if (typeof value === 'string' && Number.isNaN(parseInt(value))) {
    value = '0px';
  }

  return {
    ...initialValues,
    value,
  };
};

export const DimensionTokenForm: FC<
  TokenFormProps<typeof dimensionTokenInputSchema>
> = ({
  initialValues,
  groupPathOptions,
  existingTokenPaths = [],
  existingCoreTokenPaths = [],
  valuesMap,
  isCoreTokenSet,
}) => {
  const {
    tokenSetsActions: { setToken },
  } = useStoreActions();

  const { handlers } = useTokenDrawer();
  const validatedInitialValues = validateDimensionInitialValue(initialValues);

  const form = useZodForm({
    schema: dimensionTokenInputSchema,
    defaultValues: validatedInitialValues,
  });

  const [
    registerTokenNameField,
    registerGroupPathField,
    registerAliasField,
    registerValueField,
    registerDescriptionField,
  ] = useRegisterFieldFns(form, [
    'tokenName',
    'groupPath',
    {
      alias: {
        onChange: (e) =>
          handlers.onChange?.({
            newValue: formatToAlias(e.target.value),
          }),
      },
    },
    {
      value: {
        onChange: async () => {
          /**
           * Checking if the 'value' field is valid before triggering the onChange callback.
           */
          if (!handlers.onChange || !(await form.trigger('value'))) return;

          handlers.onChange({
            newValue: form.getValues().value,
          });
        },
      },
    },
    'description',
  ]);

  // using controlled fields to support aliases.
  // each field has a default/initial value, but if the user has changed the value,
  // we want to use the value they have changed it to, rather than the default value.
  const [value] = useInitialValues(['value'], {
    form,
    initialValues: validatedInitialValues,
  });

  const getValue: GetValueFn = useCallback(
    (valuePath) => valuesMap[valuePath],
    [valuesMap]
  );

  const existingDimensionTokens = useMemo(() => {
    if (isCoreTokenSet) {
      return existingCoreTokenPaths.filter((p) =>
        p.startsWith(createPath([CORE, DIMENSION_PATH_PREFIX]))
      );
    }

    return existingTokenPaths.filter((p) =>
      p.startsWith(DIMENSION_PATH_PREFIX)
    );
  }, [existingCoreTokenPaths, existingTokenPaths, isCoreTokenSet]);

  const existingCoreDimensionTokens = useMemo(
    () =>
      existingCoreTokenPaths.filter((p) =>
        p.startsWith(createPath([CORE, DIMENSION_PATH_PREFIX]))
      ),
    [existingCoreTokenPaths]
  );

  const { aliasOptionsProps, hasAliases, aliasValue, isAliasToken } =
    useFormValueAlias<typeof form, typeof dimensionTokenInputSchema>({
      form,
      getValue,
      existingTokenPaths: existingDimensionTokens,
      existingCoreTokenPaths: existingCoreDimensionTokens,
      isCoreTokenSet,
    });

  const validateTokenName = useValidateTokenName({
    form,
    existingTokenPaths: existingDimensionTokens,
  });

  const handleSubmit = useMemo(
    () =>
      form.handleSubmit((values) => {
        if (validateTokenName()) {
          setToken({
            data: {
              ...values,
              alias: formatAliasPath(values.alias),
              isCore: isCoreTokenSet,
            },
            type: DesignTokenTypeEnum.dimension,
          });

          handlers.onSave(
            getUpdatedTokenData({
              tokenPath: createPath([values.groupPath, values.tokenName]),
              tokenSetId: values.tokenSetId,
            })
          );
        }
      }, validateTokenName),
    [form, validateTokenName, setToken, isCoreTokenSet, handlers]
  );

  return (
    <TokenDrawerFormLayout
      title={
        validatedInitialValues.tokenName
          ? TITLE_EDIT_TOKEN
          : TITLE_CREATE_NEW_TOKEN
      }
      onSubmit={handleSubmit}
    >
      <TokenDrawerIconLabel iconVariant="DIMENSION" labelText="Dimension" />
      <FieldContainer error={form.formState.errors.tokenName?.message}>
        <TokenNameField
          error={Boolean(form.formState.errors.tokenName)}
          inputRef={registerTokenNameField().ref}
          {...registerTokenNameField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.groupPath?.message}>
        <StyledSelectField
          placeholder="Folder"
          defaultValue={validatedInitialValues.groupPath}
          renderValue={(item) => formatGroupPath(item, isCoreTokenSet)}
          error={Boolean(form.formState.errors.groupPath)}
          inputRef={registerGroupPathField().ref}
          options={groupPathOptions}
          disableMenuPortal
          {...registerGroupPathField()}
        />
      </FieldContainer>
      <FieldContainer>
        <StyledSelectField
          disabled={!hasAliases}
          placeholder={ALIAS_PLACEHOLDER_TEXT}
          value={aliasValue}
          renderValue={(item) => formatGroupPath(item, isCoreTokenSet)}
          error={Boolean(form.formState.errors.alias)}
          inputRef={registerAliasField().ref}
          disableMenuPortal
          {...aliasOptionsProps}
          {...registerAliasField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.value?.message}>
        <StyledTextField
          disabled={isAliasToken}
          value={value}
          inputProps={{
            placeholder: 'Dimension value',
          }}
          error={Boolean(form.formState.errors.value)}
          inputRef={registerValueField().ref}
          {...registerValueField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.description?.message}>
        <StyledTextField
          inputProps={{
            placeholder: 'Token description',
          }}
          error={Boolean(form.formState.errors.description)}
          inputRef={registerDescriptionField().ref}
          {...registerDescriptionField()}
        />
      </FieldContainer>
    </TokenDrawerFormLayout>
  );
};
