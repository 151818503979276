import { FC } from 'react';
import { Icons, Typography } from '@jux/ui/components';
import { AcceptUserInviteForm } from './AcceptUserInviteForm';
import * as S from './Invite.style';

export const AcceptInvite: FC<{
  orgName: string;
  inviteToken: string;
}> = ({ orgName, inviteToken }) => (
  <S.InviteCard>
    <Icons
      variant="JUX_LONG_LOGO"
      size={80}
      sx={{
        color: (theme) => theme.drimz.palette.text.primary,
        height: 20,
      }}
    />
    <Typography
      variant="h1"
      sx={{
        py: '40px',
        color: (theme) => theme.drimz.palette.text.primary,
      }}
    >
      Welcome to JUX!
      <br />
      You’ve been invited to join {orgName} team
    </Typography>

    <AcceptUserInviteForm inviteToken={inviteToken} />
  </S.InviteCard>
);
