import { FC } from 'react';
import {
  selectedNodeDirectSource,
  selectedNodeVariants,
  useStore,
} from '@jux/canjux/core';
import { Instance, Props } from './modules';
import * as S from './ModulesMapping.style';

export const InstanceModulesMapping: FC = () => {
  const hasVariants = Boolean(useStore(selectedNodeVariants)?.length);
  const selectedInstanceNodeDirectSource = useStore(selectedNodeDirectSource);

  return (
    <S.ModulesMappingWrapper>
      <Instance />
      {hasVariants && (
        <Props
          sourceTagName={selectedInstanceNodeDirectSource?.tagName}
          hasInteractiveState={false}
          hasEditProperties={false}
        />
      )}
    </S.ModulesMappingWrapper>
  );
};
