import { FC } from 'react';
import { Icons, Typography } from '@jux/ui/components';
import * as S from './Invite.style';

export const InviteErrorCard: FC<{ error: string }> = ({ error }) => (
  <S.InviteCard
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      padding: '40px',
    }}
  >
    <Icons
      variant="WARN"
      size={26}
      sx={{
        color: (theme) => theme.drimz.palette.text.primary,
      }}
    />
    <Typography
      variant="label-s-regular"
      color={(theme) => theme.drimz.palette.text.primary}
      textAlign="center"
    >
      {error}
    </Typography>
  </S.InviteCard>
);
