import { AssetData } from '@jux/data-entities';

const JUX_ASSET_LINK_ICON_SVG = `
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.472 5.52119C10.6674 5.7163 10.6677 6.03289 10.4725 6.22829L6.23504 10.472C6.03992 10.6674 5.72334 10.6677 5.52793 10.4726C5.33253 10.2774 5.33229 9.96086 5.52741 9.76546L9.76491 5.52171C9.96003 5.3263 10.2766 5.32607 10.472 5.52119Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.17236 6.58395C5.36762 6.77921 5.36762 7.09579 5.17236 7.29105L3.40361 9.0598C2.93462 9.52879 2.67114 10.1649 2.67114 10.8281C2.67114 11.4914 2.93462 12.1275 3.40361 12.5964C3.87259 13.0654 4.50868 13.3289 5.17193 13.3289C5.50033 13.3289 5.82553 13.2642 6.12894 13.1385C6.43234 13.0129 6.70803 12.8287 6.94025 12.5964L8.709 10.8277C8.90426 10.6324 9.22084 10.6324 9.41611 10.8277C9.61137 11.023 9.61137 11.3395 9.41611 11.5348L7.64736 13.3036C7.32228 13.6286 6.93635 13.8865 6.51162 14.0624C6.08688 14.2384 5.63166 14.3289 5.17193 14.3289C4.24346 14.3289 3.35302 13.9601 2.6965 13.3036C2.03997 12.647 1.67114 11.7566 1.67114 10.8281C1.67114 9.89966 2.03997 9.00922 2.6965 8.3527L4.46525 6.58395C4.66051 6.38868 4.97709 6.38868 5.17236 6.58395Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.3527 2.69645C9.00922 2.03993 9.89966 1.6711 10.8281 1.6711C11.7566 1.6711 12.647 2.03993 13.3036 2.69645C13.9601 3.35298 14.3289 4.24342 14.3289 5.17188C14.3289 6.10035 13.9601 6.99079 13.3036 7.64731L11.5348 9.41606C11.3395 9.61132 11.023 9.61132 10.8277 9.41606C10.6324 9.2208 10.6324 8.90421 10.8277 8.70895L12.5964 6.9402C13.0654 6.47121 13.3289 5.83513 13.3289 5.17188C13.3289 4.50863 13.0654 3.87255 12.5964 3.40356C12.1275 2.93457 11.4914 2.6711 10.8281 2.6711C10.1649 2.6711 9.52879 2.93457 9.0598 3.40356L7.29105 5.17231C7.09579 5.36757 6.77921 5.36757 6.58395 5.17231C6.38868 4.97705 6.38868 4.66046 6.58395 4.4652L8.3527 2.69645Z" fill="currentColor"/>
 </svg>
`;

export const JUX_ASSET_LINK_ICON_ID = 'jux-asset-link-icon' as const;

export const JUX_ASSET_LINK_ICON: AssetData = {
  name: 'LinkIcon',
  content: JUX_ASSET_LINK_ICON_SVG,
  isSystemAsset: true,
};
