import {
  COMPONENT_TAG_NAME,
  ComponentElementType,
  ComponentProps,
} from '@jux/data-entities';
import {
  ChangeEvent,
  MouseEvent,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { mergeRefs } from 'react-merge-refs';

export const Radio = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxRadio>,
  ComponentProps<typeof COMPONENT_TAG_NAME.JuxRadio>
>(
  (
    {
      children,
      disabled,
      onChange,
      onClick,
      value,
      checked: checkedValue,
      name,
      inputRef,
      rootElement: RootElement = 'button',
      ...props
    },
    forwardedRef
  ) => {
    const isDisabled = disabled || false;
    const isControlled = useMemo(
      () => checkedValue !== undefined,
      [checkedValue]
    );

    const [internalState, setInternalState] = useState(checkedValue);

    const checked = useMemo(
      () => (isControlled ? checkedValue : internalState),
      [checkedValue, internalState, isControlled]
    );

    const innerRef = useRef<HTMLInputElement>(null);
    const combinedRef = mergeRefs([innerRef, inputRef || null]);

    const setCheckedInput = (isChecked: boolean) => {
      if (innerRef.current?.checked !== isChecked) {
        innerRef.current?.click?.();
      }
    };

    // sync checked state with input
    useEffect(() => {
      if (isControlled) return;

      setCheckedInput(Boolean(checked));
      if (onChange && innerRef.current) {
        onChange({
          type: 'change',
          target: innerRef.current,
        } as ChangeEvent<HTMLInputElement>);
      }
    }, [checked, isControlled, onChange]);

    const handleButtonClick = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        onClick?.(e);

        if (isControlled) {
          setCheckedInput(!checked);
          return;
        }
        setInternalState(!checked);
      },
      [checked, isControlled, onClick]
    );

    const handleInputChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (isControlled) {
          onChange?.(e);
          return;
        }
        setInternalState(e.target.checked);
      },
      [isControlled, onChange]
    );

    return (
      <RootElement
        {...props}
        ref={forwardedRef}
        type="button"
        role="radio"
        onClick={handleButtonClick}
        checked={checked || false}
        aria-checked={checked || false}
        disabled={isDisabled}
        aria-disabled={isDisabled}
      >
        <input
          type="radio"
          style={{ display: 'none' }}
          aria-hidden={true}
          tabIndex={-1}
          ref={combinedRef}
          name={name}
          onChange={handleInputChange}
          checked={checked}
          disabled={isDisabled}
          value={value}
        />
        {children}
      </RootElement>
    );
  }
);
