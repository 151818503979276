import { CanjuxState } from '@jux/canjux/core';
import { createSelector } from 'reselect';

export const selectNodeSiblings = (id: string) =>
  createSelector([(state: CanjuxState) => state.components], (components) => {
    const component = components[id];

    const parentNode = component?.parentId
      ? components[component.parentId]
      : undefined;
    if (!parentNode) return [];

    return parentNode.children.filter((childId) => childId !== id);
  });
