import { RefObject } from 'react';
import { useEventListener } from 'usehooks-ts';

export const useOnKeyEvent = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: KeyboardEvent) => void,
  // If key is not provided, the handler will be called on any key press
  key?: string,
  keyboardEvent: 'keydown' | 'keyup' = 'keydown'
): void =>
  useEventListener(keyboardEvent, (event) => {
    const el = ref?.current;

    // Handle only the relevant key press from inside the element
    if (
      !el ||
      !el?.contains(event.target as Node) ||
      (key && key !== event.key)
    ) {
      return;
    }

    // Prevent the default action to stop scrolling when space is pressed
    event.preventDefault();

    handler(event);
  });
