import { CSSProperties } from 'react';
import { canjuxElementSelector } from '../../constants';

const DEFAULT_COMPUTED_STYLES = {};

export const getNodeComputedStyles = (id: string): Partial<CSSProperties> => {
  if (!id) {
    return DEFAULT_COMPUTED_STYLES;
  }

  const selector = canjuxElementSelector(id);
  const element = document.querySelector(selector);
  if (!element) {
    return DEFAULT_COMPUTED_STYLES;
  }

  const computedStyles = getComputedStyle(
    element as HTMLElement
  ) as CSSProperties;

  return computedStyles || DEFAULT_COMPUTED_STYLES;
};
