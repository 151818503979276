import { useCallback } from 'react';
import { useCanvasLiveOrEdit } from '@jux/ui/components/editor/hooks';
import { useStoreActions } from '@jux/canjux/core';
import { useTrackEvents } from '@jux/ui/hooks';

export const useLiveOrEdit = () => {
  const { isLive, isEdit, setEdit, setLive } = useCanvasLiveOrEdit();
  const {
    commonActions: { resetSelectedNodes },
  } = useStoreActions();
  const { trackModeClick } = useTrackEvents();
  const handleEditClick = useCallback(() => {
    setEdit();
    trackModeClick({ mode: 'edit' });
  }, [setEdit, trackModeClick]);

  const handleLiveClick = useCallback(() => {
    setLive();
    trackModeClick({ mode: 'live' });
    resetSelectedNodes();
  }, [setLive, trackModeClick, resetSelectedNodes]);

  return {
    isLive,
    isEdit,
    handleEditClick,
    handleLiveClick,
  };
};
