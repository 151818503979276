import { FC, PropsWithChildren } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Grid as MuiGrid } from '@mui/material';

export const Tabs: FC<PropsWithChildren> = ({ children }) => (
  <MuiGrid
    container
    item
    sx={{
      borderBottom: '1px solid',
      borderColor: (theme) => theme.drimz.palette.divider.secondary,
      padding: '0 20px',
      gap: '16px',
    }}
  >
    {children}
  </MuiGrid>
);
