/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconType, IconBaseProps } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const ButtonElementIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M5 7C4.44772 7 4 7.44772 4 8C4 8.55229 4.44772 9 5 9H11C11.5523 9 12 8.55229 12 8C12 7.44772 11.5523 7 11 7H5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M3 4C1.89543 4 1 4.89543 1 6V10C1 11.1046 1.89543 12 3 12H13C14.1046 12 15 11.1046 15 10V6C15 4.89543 14.1046 4 13 4H3ZM13 5.5H3C2.72386 5.5 2.5 5.72386 2.5 6V10C2.5 10.2761 2.72386 10.5 3 10.5H13C13.2761 10.5 13.5 10.2761 13.5 10V6C13.5 5.72386 13.2761 5.5 13 5.5Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
