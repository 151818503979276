/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const AliasIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M12.6672 3.47487C11.6909 2.49856 10.108 2.49856 9.13165 3.47487C8.93639 3.67014 8.93639 3.98672 9.13165 4.18198C9.32691 4.37724 9.64349 4.37724 9.83875 4.18198C10.4245 3.59619 11.3743 3.59619 11.9601 4.18198C12.5459 4.76777 12.5459 5.71752 11.9601 6.3033L9.83875 8.42462C9.4466 8.81677 8.89107 8.94704 8.38919 8.81301C8.1224 8.74177 7.84836 8.90029 7.77711 9.16708C7.70587 9.43387 7.86439 9.70791 8.13118 9.77916C8.96376 10.0015 9.89109 9.7865 10.5459 9.13173L12.6672 7.01041C13.6435 6.0341 13.6435 4.45118 12.6672 3.47487ZM7.75278 7.32913C8.01957 7.40038 8.2936 7.24186 8.36485 6.97507C8.4361 6.70827 8.27758 6.43424 8.01079 6.36299C7.1782 6.14065 6.25087 6.35565 5.5961 7.01042L3.47478 9.13174C2.49847 10.108 2.49847 11.691 3.47478 12.6673C4.45109 13.6436 6.03401 13.6436 7.01032 12.6673C7.20558 12.472 7.20558 12.1554 7.01032 11.9602C6.81506 11.7649 6.49847 11.7649 6.30321 11.9602C5.71742 12.546 4.76768 12.546 4.18189 11.9602C3.5961 11.3744 3.5961 10.4246 4.18189 9.83884L6.30321 7.71752C6.69536 7.32538 7.25089 7.1951 7.75278 7.32913Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
