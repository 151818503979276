import { FC } from 'react';
import { Icons } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { NAVIGATOR_IS_HIDDEN_ICON_COLOR } from '@jux/ui/theme/palette';

export const ObjectNavigatorItemHiddenIcon: FC = () => (
  <MuiGrid item>
    <Icons
      variant="LAYERS_PANEL_ITEM_HIDDEN"
      color={NAVIGATOR_IS_HIDDEN_ICON_COLOR}
    />
  </MuiGrid>
);
