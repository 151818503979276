import { FC } from 'react';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
import { IconBaseProps } from '../../Icons.interface';

export const TextFieldElementPanelIcon: FC<IconBaseProps> = ({
  textColor,
  ...props
}) => (
  <CustomIcon
    tag="svg"
    width="120"
    height="101"
    attr={{
      viewBox: '0 0 120 101',
      fill: 'none',
    }}
    child={[
      {
        tag: 'rect',
        attr: {
          x: '0.5',
          y: '0.5',
          width: '119',
          height: '100',
          rx: '3.5',
          fill: textColor || 'currentColor',
        },
        child: [],
      },
      {
        tag: 'rect',
        attr: {
          x: '0.5',
          y: '0.5',
          width: '119',
          height: '100',
          rx: '3.5',
          stroke: 'currentColor',
        },
        child: [],
      },
      {
        tag: 'rect',
        attr: {
          x: '26',
          y: '21.5',
          width: '52',
          height: '7',
          rx: '2',
          fill: 'white',
        },
        child: [],
      },
      {
        tag: 'rect',
        attr: {
          x: '26',
          y: '34.5',
          width: '68',
          height: '32',
          rx: '4',
          fill: 'white',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          d: 'M36 58.5C34.8954 58.5 34 57.6046 34 56.5L34 44.5C34 43.3954 34.8954 42.5 36 42.5L38 42.5C39.1046 42.5 40 43.3954 40 44.5L40 56.5C40 57.6046 39.1046 58.5 38 58.5H36Z',
          fill: '#1F1F1F',
        },
        child: [],
      },
      {
        tag: 'rect',
        attr: {
          x: '26',
          y: '72.5',
          width: '35',
          height: '7',
          rx: '2',
          fill: 'white',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
