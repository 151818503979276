import { createStyled } from '../styled';
import CreateDrimz from '../styled/styled.interface';
import { ThemeConfig } from '@jux/types';

export function createDrimz<Theme extends ThemeConfig>(): CreateDrimz<Theme> {
  // const root = globalThis.document || null;
  return {
    styled: createStyled<Theme>(),
  };
}
