import {
  CommonActionsParams,
  JuxStore,
  JuxStoreActionFn,
} from '../store.interface';
import { ComponentTagNames } from '@jux/data-entities';
import { getResolvedSourceComponentData } from '../selectors/utils/getResolvedSourceComponentData';

export const setEditModeInTextNode: JuxStoreActionFn<
  CommonActionsParams['setEditModeInTextNode'],
  JuxStore
> = ({ nodeId, editable, state }) => {
  const component = state.components[nodeId];
  if (!component) return state;

  const sourceComponent = getResolvedSourceComponentData({
    component,
    components: state.components,
  });

  // check if the node is of type text and set its properties
  if (ComponentTagNames.JuxText === sourceComponent.tagName) {
    // adding the node to be in edit mode
    if (editable) {
      if (!state.textEditingNodeStack.includes(nodeId)) {
        state.textEditingNodeStack.push(nodeId);
      }
    } else {
      // removing the node from edit mode
      state.textEditingNodeStack = state.textEditingNodeStack.filter(
        (id) => id !== nodeId
      );
    }
  }

  return state;
};
