import React, { FC } from 'react';
import { MultiSelectionBox } from '../../components/MultiSelectionBox';
import { useStore } from '../../hooks';
import * as S from './PaneIndicators.style';

export const PaneIndicators: FC = () => {
  const transform = useStore((state) => state.transform);

  return (
    <S.PaneIndicatorsWrapper
      // All indicators will be rendered here - node resizer & node toolbar
      id={'jux_pane_indicators'}
      {...transform}
    >
      <MultiSelectionBox />
    </S.PaneIndicatorsWrapper>
  );
};
