import {
  CanvasNodesDimensions,
  CanvasNodesIndicatorsPositions,
} from '@jux/canjux/core';

export const getNodeRect = ({
  canvasNodesDimensionsData,
  canvasNodesIndicatorsData,
  nodeId,
}: {
  canvasNodesDimensionsData: CanvasNodesDimensions;
  canvasNodesIndicatorsData: CanvasNodesIndicatorsPositions;
  nodeId: string;
}) => {
  const positionAbsolute = canvasNodesIndicatorsData[nodeId]?.positionAbsolute;
  const dimensions = canvasNodesDimensionsData[nodeId];

  return {
    x: positionAbsolute?.x || 0,
    y: positionAbsolute?.y || 0,
    width: dimensions?.width || 0,
    height: dimensions?.height || 0,
  };
};
