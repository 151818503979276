/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const BorderRadiusAllDDPIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M3.5 6C3.5 4.61929 4.61929 3.5 6 3.5C6.41421 3.5 6.75 3.16421 6.75 2.75C6.75 2.33579 6.41421 2 6 2C3.79086 2 2 3.79086 2 6C2 6.41421 2.33579 6.75 2.75 6.75C3.16421 6.75 3.5 6.41421 3.5 6Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M10 3.5C11.3807 3.5 12.5 4.61929 12.5 6C12.5 6.41421 12.8358 6.75 13.25 6.75C13.6642 6.75 14 6.41421 14 6C14 3.79086 12.2091 2 10 2C9.58579 2 9.25 2.33579 9.25 2.75C9.25 3.16421 9.58579 3.5 10 3.5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 9.58579 12.8358 9.25 13.25 9.25C13.6642 9.25 14 9.58579 14 10C14 12.2091 12.2091 14 10 14C9.58579 14 9.25 13.6642 9.25 13.25C9.25 12.8358 9.58579 12.5 10 12.5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M6 12.5C4.61929 12.5 3.5 11.3807 3.5 10C3.5 9.58579 3.16421 9.25 2.75 9.25C2.33579 9.25 2 9.58579 2 10C2 12.2091 3.79086 14 6 14C6.41421 14 6.75 13.6642 6.75 13.25C6.75 12.8358 6.41421 12.5 6 12.5Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
