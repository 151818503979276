// eslint-disable-next-line no-restricted-imports
import { Slide, toast, ToastOptions } from 'react-toastify';
import { ToastType } from './toast.interface';

const AUTO_CLOSE = 1800;

const defaultToastOptions: ToastOptions = {
  position: toast.POSITION.BOTTOM_CENTER,
  hideProgressBar: true,
  pauseOnHover: false,
  transition: Slide,
  icon: false,
  rtl: false,
  draggable: false,
  closeButton: false,
  closeOnClick: true,
  autoClose: AUTO_CLOSE,
};

export const getToastOptions = (type: ToastType): ToastOptions => {
  switch (type) {
    case ToastType.Success: {
      return defaultToastOptions;
    }
    case ToastType.Error: {
      return defaultToastOptions;
    }
    case ToastType.Action: {
      return {
        ...defaultToastOptions,
        autoClose: false,
        closeOnClick: false,
      };
    }
  }
};
