import { DesignTokenTypeEnum } from '@jux/design-tokens';

export const ALIAS_PLACEHOLDER_TEXT = 'Alias';
export const ALIAS_DESELECTION_TEXT = 'No alias';

export const STROKE_STYLE_PLACEHOLDER_TEXT = 'Stroke style';
export const COLOR_PATH_PREFIX = `${DesignTokenTypeEnum.color}.`;
export const DIMENSION_PATH_PREFIX = `${DesignTokenTypeEnum.dimension}.`;
export const FONT_FAMILY_PATH_PREFIX = `${DesignTokenTypeEnum.fontFamily}.`;
export const TYPOGRAPHY_PATH_PREFIX = `${DesignTokenTypeEnum.typography}.`;
export const BORDER_PATH_PREFIX = `${DesignTokenTypeEnum.border}.`;
