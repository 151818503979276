import { MuiGrid, styled } from '@jux/ui/components/common/mui';

export const LayersPanelLayout = styled(MuiGrid)(({ theme }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: theme.drimz.palette.background.navigation,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflowX: 'hidden',
  overflowY: 'auto',
}));
