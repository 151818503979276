import {
  JuxStore,
  JuxStoreActionFn,
  TokenSetsActionsParams,
  toRecordBy,
} from '@jux/canjux/core';
import { z } from 'zod';

export const setEditorTokenSet: JuxStoreActionFn<
  TokenSetsActionsParams['setEditorTokenSet'],
  JuxStore
> = ({ state, ...params }) => {
  const { tokenSetId } = z
    .object({
      tokenSetId: z.string(),
    })
    .parse(params);

  const tokenSetsById = toRecordBy(state.tokenSets, 'id');

  if (tokenSetId in tokenSetsById) {
    state.editorTokenSetId = tokenSetId;
  }

  return state;
};
