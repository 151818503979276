import { atom, useAtom } from 'jotai';
import { useMemo } from 'react';
import {
  ComponentConfigData,
  ComponentTagName,
  ComponentTagNames,
} from '@jux/data-entities';

const nodesLiveModeStateAtom = atom<
  Record<string, ComponentConfigData['props']>
>({});

export const useNodeLiveModeProps = (
  nodeId: string,
  tagName: ComponentTagName
) => {
  const [nodesLiveModeState, setNodesLiveModeState] = useAtom(
    nodesLiveModeStateAtom
  );

  const nodesLiveModeProps = useMemo(() => {
    if (nodesLiveModeState[nodeId]) return nodesLiveModeState[nodeId];
    let newLiveModeProps = {};

    switch (tagName) {
      case ComponentTagNames.JuxCheckbox:
      case ComponentTagNames.JuxCheckboxField:
        newLiveModeProps = {
          checked: nodesLiveModeState[nodeId]?.checked ?? true,
          onCheckedChange: () => {
            setNodesLiveModeState((prev) => ({
              ...prev,
              [nodeId]: {
                ...prev[nodeId],
                checked: !prev[nodeId]?.checked,
              },
            }));
          },
        };
        break;
      default:
        break;
    }
    setNodesLiveModeState((prev) => ({
      ...prev,
      [nodeId]: newLiveModeProps,
    }));
    return newLiveModeProps;
  }, [setNodesLiveModeState, tagName, nodeId, nodesLiveModeState]);

  return {
    nodesLiveModeProps,
  };
};
