import {
  CanjuxState,
  CommonActionsParams,
  JuxStoreActionFn,
} from '@jux/canjux/core';

/**
 * Set node dragging end
 */
export const setNodeDraggingEnd: JuxStoreActionFn<
  CommonActionsParams['setNodeDraggingEnd'],
  CanjuxState
> = ({ nodeId, state }) => {
  state.canvases[state.currentCanvasName].nodes[nodeId].properties.isDragged =
    false;
  state.dragNodeStarted = false;

  return state;
};
