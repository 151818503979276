import { createSelector } from 'reselect';
import { CanjuxState, SourceComponentsList } from '@jux/canjux/core';
import { COMPONENT_TAG_NAME, NodeType } from '@jux/data-entities';

export const localComponents = createSelector(
  [(state: CanjuxState) => state.components],
  (components): SourceComponentsList => {
    // get only source components that are not svgs
    const componentsWithoutSvg = Object.values(components)
      .filter(
        (component) =>
          component.type === NodeType.LOCAL_COMPONENT &&
          component.tagName !== COMPONENT_TAG_NAME.JuxSvg
      )
      .sort((a, b) => (b?.updatedAt || 0) - (a?.updatedAt || 0));
    return componentsWithoutSvg as SourceComponentsList;
  }
);
