import { FC, MouseEvent, useCallback } from 'react';
import { IconButton } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';

export const CreateNewTokenSetButton: FC<{
  createTokenSet: () => void;
  width: number;
}> = ({ createTokenSet, width }) => {
  const handleCreateTokenSetClick = useCallback(
    (e: MouseEvent) => {
      // this specific button requires to be clicked on the wrapper
      // due to the fact we handling click on wrapper too, we need to stop propagation
      e.stopPropagation();
      createTokenSet();
    },
    [createTokenSet]
  );

  return (
    <MuiGrid display="flex" height="100%" alignItems="center">
      <IconButton
        variant="icon"
        icon="ADD"
        onClick={handleCreateTokenSetClick}
        allowClickFromWrapper
        sx={{
          width,
        }}
        wrapperProps={{
          onClick: createTokenSet,
          sx: {
            padding: '10px',
            height: (theme) => theme.drimz.size.layout.tokens.set.height,
            alignItems: 'center',
          },
        }}
      />
    </MuiGrid>
  );
};
