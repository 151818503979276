import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useCallback } from 'react';
import { usePersistLocalStorageDataEffect } from '@jux/ui/hooks';
import { CanvasData } from '@jux/data-entities';

const LOCAL_STORAGE_KEY = 'canvasOptions';

type Viewport = {
  x: number;
  y: number;
  zoom: number;
  timestamp: number;
};

type CanvasesViewports = Record<CanvasData['id'], Viewport>;

type CanvasOptionParams = { id: CanvasData['id']; viewport: Viewport };

const defaultCanvasOptions: CanvasesViewports = {};

/* 
todo: Stuff that must be synched like localstorage->atom->store should just be localstorage->store without the atom
https://zustand.docs.pmnd.rs/integrations/persisting-store-data
*/
const canvasesViewportsOptionsAtom = atomWithStorage<CanvasesViewports>(
  LOCAL_STORAGE_KEY,
  defaultCanvasOptions
);

export const useCanvasOptionsLocalStorage = () => {
  const [canvasOptionsState, setCanvasOptionsState] = useAtom(
    canvasesViewportsOptionsAtom
  );

  usePersistLocalStorageDataEffect({
    key: LOCAL_STORAGE_KEY,
    defaultData: defaultCanvasOptions,
    setData: setCanvasOptionsState as any, // some issue with jotai types
  });

  const setCanvasOptions = useCallback(
    ({ id, viewport }: CanvasOptionParams) => {
      setCanvasOptionsState((prev) => ({
        ...prev,
        [id]: viewport,
      }));
    },
    [setCanvasOptionsState]
  );

  const deleteCanvasViewport = useCallback(
    (canvasId: string) => {
      delete canvasOptionsState[canvasId];
      setCanvasOptionsState(canvasOptionsState);
    },
    [canvasOptionsState, setCanvasOptionsState]
  );

  return {
    canvasesViewPorts: canvasOptionsState,
    setCanvasOptions,
    deleteCanvasViewport,
  };
};
