import {
  JuxStore,
  JuxStoreActionFn,
  TokenSetsActionsParams,
} from '@jux/canjux/core';
import { getCurrentTimestamp } from '@jux/design-tokens';
import cloneDeep from 'lodash/cloneDeep';
import { z } from 'zod';
import { getDesignTokensParserContext } from '../../../store-utils/getDesignTokensParserContext';

const designTokensParser = getDesignTokensParserContext();

export const renameToken: JuxStoreActionFn<
  TokenSetsActionsParams['renameToken'],
  JuxStore
> = ({ state, ...params }) => {
  const { newPath, oldPath } = z
    .object({
      newPath: z.string(),
      oldPath: z.string(),
    })
    .parse(params);

  Object.values(state.tokenSets).forEach((tokenSet) => {
    tokenSet.value = designTokensParser
      .parse(cloneDeep(tokenSet.value))
      .renameToken({
        oldPath,
        newPath,
      })
      .getRawValueCopy();

    tokenSet.updatedAt = getCurrentTimestamp();
  });

  return state;
};
