import { useEffect } from 'react';

// Calls action with value whenever value changes
export const useEffectStoreUpdater = <T>(
  value: T | undefined,
  storeAction: (param: T) => void
) => {
  useEffect(() => {
    if (typeof value !== 'undefined') {
      storeAction(value);
    }
  }, [storeAction, value]);
};
