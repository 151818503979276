export enum FontWeight {
  Thin = '100',
  ExtraLight = '200',
  Light = '300',
  Regular = '400',
  Medium = '500',
  SemiBold = '600',
  Bold = '700',
  ExtraBold = '800',
  Black = '900',
}

export const FontWeightsValues: { [key: string]: string } = {
  [FontWeight.Thin]: 'Thin',
  [FontWeight.ExtraLight]: 'Extra Light',
  [FontWeight.Light]: 'Light',
  [FontWeight.Regular]: 'Regular',
  [FontWeight.Medium]: 'Medium',
  [FontWeight.SemiBold]: 'Semi Bold',
  [FontWeight.Bold]: 'Bold',
  [FontWeight.ExtraBold]: 'Extra Bold',
  [FontWeight.Black]: 'Black',
};

/**
 * TODO: this logic should take into account the actual supported weights per font
 */
export const fontWeightOptions = Object.keys(FontWeightsValues);

/**
 * @description
 * Renders the font weight option label
 * @example
 * renderFontWeightOption('400') => '400 - Regular'
 * renderFontWeightOption('foo') => ''
 */
export const renderFontWeightOption = (value: string) => {
  if (
    !value ||
    !(value in FontWeightsValues) ||
    typeof FontWeightsValues[value] === 'undefined'
  ) {
    return '';
  }

  const key = FontWeightsValues[value].replaceAll(
    /\s/g,
    ''
  ) as keyof typeof FontWeight;

  return key in FontWeight
    ? `${FontWeightsValues[value]} - ${FontWeight[key]}`
    : FontWeightsValues[value];
};
