import { keyboardActionKeys, useIsKeyPressed } from '@jux/canjux/core';
import { FC, memo } from 'react';
import { useGlobalKeyHandler } from '../../hooks';
import { CanvasContainerPane } from '../CanvasContainerPane';
import { Pane } from '../Pane';
import { CanvasRendererProps } from './CanvasRenderer.interface';

export const CanvasRenderer: FC<CanvasRendererProps> = memo(
  ({
    children,
    selectionOnDrag,
    zoomOnScroll,
    zoomOnPinch,
    panOnScroll,
    panOnScrollSpeed,
    panOnScrollMode,
    zoomOnDoubleClick,
    panOnDrag,
    defaultViewport,
    translateExtent,
  }) => {
    const panActivationKeyPressed = useIsKeyPressed({
      key: keyboardActionKeys.ACTIVATE_CANVAS_PAN,
    });

    const isSelecting = Boolean(selectionOnDrag && panOnDrag !== true);

    useGlobalKeyHandler();

    return (
      <CanvasContainerPane
        zoomOnScroll={zoomOnScroll}
        zoomOnPinch={zoomOnPinch}
        panOnScroll={panOnScroll}
        panOnScrollSpeed={panOnScrollSpeed}
        panOnScrollMode={panOnScrollMode}
        zoomOnDoubleClick={zoomOnDoubleClick}
        panOnDrag={panActivationKeyPressed || panOnDrag}
        defaultViewport={defaultViewport}
        translateExtent={translateExtent}
      >
        <Pane panOnDrag={panOnDrag} isSelecting={isSelecting}>
          {children}
        </Pane>
      </CanvasContainerPane>
    );
  }
);

CanvasRenderer.displayName = 'CanvasRenderer';
