import { createSelector } from 'reselect';
import { CanjuxState, getContextParentByNodeId } from '@jux/canjux/core';
import { getSelectedNodeId } from './utils';
import { NodeType } from '@jux/data-entities';

export const selectedNodeHasContextParent = createSelector(
  [
    (state: CanjuxState) => state.components,
    (state: CanjuxState) => state.selectedNodesStack,
  ],
  (components, selectedNodesStack) => {
    const selectedNodeId = getSelectedNodeId(selectedNodesStack);
    if (
      !selectedNodeId ||
      components[selectedNodeId]?.type === NodeType.INSTANCE
    ) {
      return false;
    }

    const selectedNodeContextId = getContextParentByNodeId({
      components,
      id: getSelectedNodeId(selectedNodesStack),
    });
    return Boolean(selectedNodeContextId);
  }
);
