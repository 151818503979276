import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { ShadowXField } from './ShadowXField';
import { ShadowYField } from './ShadowYField';
import { ShadowSpreadField } from './ShadowSpreadField';
import { ShadowColorField } from './ShadowColorField';
import { ShadowBlurField } from './ShadowBlurField';
import { ShadowInsetField } from './ShadowInsetField';

export const Shadow: FC = () => {
  const { shouldRender } = useSubModule(DDPSubModulesKeys.boxShadow);

  if (!shouldRender) return null;
  return (
    <SubModule title="Shadow">
      <ShadowXField />
      <ShadowYField />
      <ShadowSpreadField />
      <ShadowBlurField />
      <ShadowColorField />
      <ShadowInsetField />
    </SubModule>
  );
};
