import { CanjuxState } from '@jux/canjux/core';
import { createSelector } from 'reselect';
import { ComponentTagName, NodeType } from '@jux/data-entities';
import { getResolvedSourceComponentData } from './utils/getResolvedSourceComponentData';

export type NodeInfo = {
  type: NodeType;
  parentId: string | undefined;
  tagName?: ComponentTagName;
  displayName?: string;
};

export const selectNodeIdGeneralInfo = (id: string) =>
  createSelector(
    [(state: CanjuxState) => state.components],
    (components): NodeInfo => {
      const component = components[id];
      const sourceComponent = getResolvedSourceComponentData({
        component: components[id],
        components,
      });

      return {
        type: component.type,
        parentId: component.parentId,
        tagName: sourceComponent.tagName,
        displayName: component.displayName,
      };
    }
  );
