import { createSelector } from 'reselect';
import { CanjuxState } from '@jux/canjux/core';
import { getSelectedNode } from './utils';

export const isSelectedNodeImmutable = createSelector(
  [
    (state: CanjuxState) => state.canvases,
    (state: CanjuxState) => state.currentCanvasName,
    (state: CanjuxState) => state.selectedNodesStack,
  ],
  (canvases, currentCanvasName, selectedNodesStack) =>
    getSelectedNode({
      canvases,
      currentCanvasName,
      selectedNodesStack,
    })?.properties.isImmutable ?? false
);
