import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { Module } from '../../base';
import { useShouldRenderModuleByKey } from '../../hooks/useShouldRenderModuleByKey';
import { ModulesKeys } from '../../DDP.interface';
import {
  Constraints,
  Height,
  Position,
  RelativeTo,
  Width,
  ZIndex,
} from './sub-modules';

const MODULE_KEY = ModulesKeys.size;

export const Size: FC = () => {
  const { shouldRender } = useShouldRenderModuleByKey(MODULE_KEY);

  if (!shouldRender) return null;

  return (
    <Module moduleKey={MODULE_KEY} title="Size">
      <Width />
      <Height />
      <MuiGrid container pt="8px">
        <Position />
      </MuiGrid>
      <RelativeTo />
      <Constraints />
      <ZIndex />
    </Module>
  );
};
