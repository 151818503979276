import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useStore, useStoreActions } from '@jux/canjux/core';

export const useInitTokenSetsData = () => {
  const {
    tokenSetsActions: { initTokenSetsData },
  } = useStoreActions();

  const { isLiveblocksStorageLoading, liveblocksStatus, tokenSets } = useStore(
    (s) => ({
      isLiveblocksStorageLoading: s.liveblocks.isStorageLoading,
      liveblocksStatus: s.liveblocks.status,
      tokenSets: s.tokenSets,
    })
  );

  useEffect(() => {
    if (
      liveblocksStatus === 'connected' &&
      !isLiveblocksStorageLoading &&
      isEmpty(tokenSets)
    ) {
      initTokenSetsData();
    }
  }, [
    initTokenSetsData,
    isLiveblocksStorageLoading,
    liveblocksStatus,
    tokenSets,
  ]);
};
