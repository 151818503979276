import { NodeType } from '@jux/data-entities';
import { LayersPanelItem } from '../getLayersItemsRecursive';
import { getChildrenRecursive } from './getChildrenRecursive.utils';

// find if one of the dragged item children (recursively) is an instance of the hovered item root component
export const isCurrentItemRootAComponentAndDraggedItemTreeHasInstance = (
  draggedItem: LayersPanelItem,
  currentItem: LayersPanelItem,
  items: Map<string, LayersPanelItem>
): boolean => {
  if (draggedItem.id === currentItem.id || !draggedItem || !currentItem)
    return true;

  const targetRootIsComponent =
    currentItem.rootNodeType === NodeType.LIBRARY_COMPONENT ||
    currentItem.rootNodeType === NodeType.LOCAL_COMPONENT;
  if (!targetRootIsComponent) {
    return false;
  }

  const draggedItemWithChildren = [draggedItem].concat(
    getChildrenRecursive(draggedItem, items, draggedItem.id)
  );

  return draggedItemWithChildren.some(
    (child) =>
      child.objectType === NodeType.INSTANCE &&
      child.sourceComponentId &&
      // currentItem.path should start with the root id. check if one of the children is an instance
      // of the current item root node id
      currentItem.path.startsWith(child.sourceComponentId)
  );
};
