import { RefObject, useCallback } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { useStoreApi } from '@jux/canjux/core';
import { useAddCanvasItem } from './useAddCanvasItem';
import { CANVAS_ITEMS, CanvasItemValue } from './useCanvasItem.interface';

export const useCanvasDropItem = (layoutRef: RefObject<HTMLDivElement>) => {
  const storeApi = useStoreApi();
  const { transform } = storeApi.getState();

  const { addItemToCanvas } = useAddCanvasItem();

  const dropHandler = useCallback(
    (item: CanvasItemValue, monitor: DropTargetMonitor<CanvasItemValue>) => {
      const position = monitor.getClientOffset();

      if (!layoutRef.current || !position) return;

      const layoutBounds = layoutRef.current.getBoundingClientRect();

      const dropPosition = {
        x: (position.x - transform.x - layoutBounds.x) / transform.zoom,
        y: (position.y - transform.y - layoutBounds.y) / transform.zoom,
      };

      addItemToCanvas({ item, dropPosition });
    },
    [addItemToCanvas, layoutRef, transform.x, transform.y, transform.zoom]
  );

  const [, dropRef] = useDrop({
    accept: Object.values(CANVAS_ITEMS),
    drop: dropHandler,
  });

  return {
    dropRef: dropRef as unknown as RefObject<HTMLDivElement>,
  };
};
