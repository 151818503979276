import { useRef } from 'react';
import { useResizeObserver } from 'usehooks-ts';

export const useOnWindowResize = (callback: () => void) => {
  const documentBodyRef = useRef(document.body);

  useResizeObserver({
    ref: documentBodyRef,
    onResize: callback,
  });
};
