import {
  CanvasNodesDimensions,
  CanvasNodesIndicatorsPositions,
  getNodePosition,
  getOverlappingArea,
  JuxRect,
  Transform,
} from '@jux/canjux/core';
import { CanvasData, XYPosition } from '@jux/data-entities';

export const getNodesInRect = ({
  canvas,
  canvasNodesDimensionsData,
  canvasNodesIndicatorsData,
  nodeOrigin = { x: 0, y: 0 },
  onlyRootNodes = true,
  partially = true,
  transform,
  viewport,
}: {
  canvas: CanvasData;
  canvasNodesDimensionsData: CanvasNodesDimensions;
  canvasNodesIndicatorsData: CanvasNodesIndicatorsPositions;
  nodeOrigin?: XYPosition;
  onlyRootNodes?: boolean;
  partially?: boolean;
  transform: Transform;
  viewport: JuxRect;
}): Array<string> => {
  const visibleNodesIds: Array<string> = [];
  const nodes = onlyRootNodes
    ? canvas.rootNodesOrder
    : Object.keys(canvas.nodes);

  const paneRect = {
    x: (viewport.x - transform.x) / transform.zoom,
    y: (viewport.y - transform.y) / transform.zoom,
    width: viewport.width / transform.zoom,
    height: viewport.height / transform.zoom,
  };

  nodes.forEach((nodeId) => {
    const node = canvas.nodes[nodeId];
    const nodeDimensions = canvasNodesDimensionsData[nodeId];
    const nodeIndicatorsData = canvasNodesIndicatorsData[nodeId];

    if (
      !node ||
      !nodeDimensions ||
      !nodeIndicatorsData ||
      node.properties.isHidden
    ) {
      return;
    }

    const { positionAbsolute } = getNodePosition({
      nodeDimensions,
      nodeOrigin,
      nodePosition: node.position,
      nodePositionAbsolute: nodeIndicatorsData.positionAbsolute,
    });

    const nodeRect = {
      x: positionAbsolute.x,
      y: positionAbsolute.y,
      width: nodeDimensions.width || 0,
      height: nodeDimensions.height || 0,
    };

    const overlappingArea = getOverlappingArea(paneRect, nodeRect);
    const notInitialized =
      typeof nodeDimensions.width === 'undefined' ||
      typeof nodeDimensions.height === 'undefined' ||
      nodeDimensions.width === null ||
      nodeDimensions.height === null;

    const partiallyVisible = partially && overlappingArea > 0;
    const area = (nodeDimensions.width || 0) * (nodeDimensions.height || 0);

    const isVisible =
      notInitialized || partiallyVisible || overlappingArea >= area;

    if (isVisible) {
      visibleNodesIds.push(nodeId);
    }
  });

  return visibleNodesIds;
};
