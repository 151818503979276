import { useEffect } from 'react';
import { useSelectedNodeId } from './useSelectedNodeId';

export const useEffectOnSelectedNodeChange = (callback: () => void) => {
  const selectedNodeId = useSelectedNodeId();

  useEffect(() => {
    callback();
  }, [callback, selectedNodeId]);
};
