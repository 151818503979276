import { FC } from 'react';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import { DimensionField } from '../../../../base';
import {
  useFieldValues,
  useDimensionField,
  useSetFieldValue,
} from '../../../../hooks';

const FIELD_KEY = DDPPrimitiveFieldsKeys.borderRadius;

export const BorderRadiusField: FC = () => {
  const { value, initialValue } = useFieldValues(FIELD_KEY);
  const { saveValue } = useSetFieldValue(FIELD_KEY);

  const props = useDimensionField({
    value,
    initialValue,
    saveChanges: saveValue,
    fieldName: FIELD_KEY,
  });

  return (
    <DimensionField
      icon="DDP_FIELD_BORDER_RADIUS"
      tooltipTitle="Border radius"
      {...props}
    />
  );
};
