import { createSelector } from 'reselect';
import { CanjuxState } from '@jux/canjux/core';
import { getSelectedNodeComponent } from './utils';
import { ComponentTagNames, NodeType } from '@jux/data-entities';

type BooleanType = {
  isLibraryComponent: boolean;
  isElement: boolean;
  isLocalComponent: boolean;
  isDiv: boolean;
};

const defaultBooleanType: BooleanType = {
  isLibraryComponent: false,
  isElement: false,
  isLocalComponent: false,
  isDiv: false,
};

export const selectedNodeComponentBooleanType = createSelector(
  [
    (state: CanjuxState) => state.components,
    (state: CanjuxState) => state.selectedNodesStack,
  ],
  (components, selectedNodesStack): BooleanType => {
    const component = getSelectedNodeComponent({
      components,
      selectedNodesStack,
    });

    if (!component) return defaultBooleanType;

    const isLibraryComponent = component.type === NodeType.LIBRARY_COMPONENT;
    const isElement = component.type === NodeType.ELEMENT;
    const isLocalComponent = component.type === NodeType.LOCAL_COMPONENT;
    const isDiv = component.tagName === ComponentTagNames.JuxDiv;

    return {
      isLibraryComponent,
      isElement,
      isLocalComponent,
      isDiv,
    };
  }
);
