import { HotkeyCallback, useHotkeys } from 'react-hotkeys-hook';
import { useCallback, MutableRefObject } from 'react';
import type {
  OptionsOrDependencyArray,
  RefType,
} from 'react-hotkeys-hook/dist/types';
import { CommonKeysValueType } from './keys';

export interface HotKeyItem {
  action: CommonKeysValueType;
  callback: (...args: any) => void;
  options?: OptionsOrDependencyArray | undefined;
  dependencies?: OptionsOrDependencyArray | undefined;
  shouldPreventDefault?: boolean;
}

export const useRegisterHotKey = ({
  action: key,
  callback,
  options,
  dependencies,
  shouldPreventDefault = false,
}: HotKeyItem): MutableRefObject<RefType<HTMLElement>> => {
  const handleCallback: HotkeyCallback = useCallback(
    (e) => {
      if (shouldPreventDefault) {
        e.preventDefault();
      }
      callback(e);
    },
    [callback, shouldPreventDefault]
  );

  return useHotkeys(key, handleCallback, options, dependencies);
};
