import { useCallback, useMemo } from 'react';
import {
  selectedNodeComponent,
  useStore,
  useStoreActions,
} from '@jux/canjux/core';
import { useSelectedNodeId } from '@jux/ui/components/editor/hooks';
import { useTrackEvents } from '@jux/ui/hooks';
import { NodeType } from '@jux/data-entities';
import { generatePropName, generatePropValueName } from '../utils';
import { useParsedProperties } from './useParsedProperties';

export const useCreateNewProperty = () => {
  const {
    propsActions: { createComponentProp },
  } = useStoreActions();
  const selectedNodeId = useSelectedNodeId();
  const selectedNodeData = useStore(selectedNodeComponent);
  const { trackPropCreatedEvent } = useTrackEvents();

  const { parsedProperties } = useParsedProperties();

  const allPropNames = useMemo(
    () => parsedProperties?.map((p) => p.title),
    [parsedProperties]
  );

  const createNewProperty = useCallback(() => {
    if (
      !selectedNodeId ||
      !selectedNodeData ||
      (selectedNodeData.type !== NodeType.LOCAL_COMPONENT &&
        selectedNodeData.type !== NodeType.LIBRARY_COMPONENT)
    ) {
      return;
    }

    const newPropName = generatePropName(allPropNames ?? []);

    const newValueName = generatePropValueName([]);

    createComponentProp({
      componentId: selectedNodeId,
      propName: newPropName,
      propValue: newValueName,
    });

    trackPropCreatedEvent({
      componentUUID: selectedNodeData.id,
      tagName: selectedNodeData.tagName,
      propName: newPropName,
    });
  }, [
    allPropNames,
    createComponentProp,
    selectedNodeData,
    selectedNodeId,
    trackPropCreatedEvent,
  ]);

  return {
    createNewProperty,
  };
};
