import { useMemo } from 'react';
import { selectedNodeComponentVariants, useStore } from '@jux/canjux/core';

export const useParsedProperties = () => {
  const properties = useStore(selectedNodeComponentVariants);

  const parsedProperties = useMemo(
    () =>
      properties?.map(({ variant, isFixed, options, defaultValue }) => {
        return {
          title: variant,
          options,
          isFixed,
          defaultValue: defaultValue as string,
        };
      }) ?? [],
    [properties]
  );

  const propertiesNames = useMemo(
    () => parsedProperties?.map((p) => p.title),
    [parsedProperties]
  );

  return {
    parsedProperties,
    propertiesNames,
    hasProperties: Boolean(parsedProperties.length),
  };
};
