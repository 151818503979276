import { FC } from 'react';
import { DimensionField } from '../../../../base';
import { useConstraintsFields } from './useConstraintsFields';

export const LeftField: FC = () => {
  const { leftFieldProps } = useConstraintsFields();

  return (
    <DimensionField
      icon="DDP_FIELD_CONSTRAINTS_LEFT"
      tooltipTitle="Left"
      {...leftFieldProps}
    />
  );
};
