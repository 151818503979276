import { createSelector } from 'reselect';
import { JuxStore } from '@jux/canjux/core';

export const selectCurrentCanvas = createSelector(
  [
    (state: JuxStore) => state.canvases,
    (state: JuxStore) => state.currentCanvasName,
  ],
  (canvases, currentCanvasName) => canvases[currentCanvasName]
);
