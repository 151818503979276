import { useMemo } from 'react';
import { useStore } from '@jux/canjux/core';

export interface CanvasListItem {
  id: string;
  name: string;
  createdAt: string;
}

export const useGetCanvasList = (): { canvasList: Array<CanvasListItem> } => {
  const canvases = useStore((s) => s.canvases);

  const canvasList = useMemo(() => {
    return Object.entries(canvases).map(([name, { id, createdAt }]) => ({
      id,
      name,
      createdAt,
    }));
  }, [canvases]);

  return {
    canvasList,
  };
};
