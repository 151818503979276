import { createSelector } from 'reselect';
import { CanjuxState, getContextParentByNodeId } from '@jux/canjux/core';
import { getSelectedNodeId } from './utils';

export const selectedNodeContextParentVariants = createSelector(
  [
    (state: CanjuxState) => state.components,
    (state: CanjuxState) => state.selectedNodesStack,
  ],
  (components, selectedNodesStack) => {
    const selectedNodeContextId = getContextParentByNodeId({
      components,
      id: getSelectedNodeId(selectedNodesStack),
    });
    if (!selectedNodeContextId) return undefined;

    const selectedNodeContextComponent = components[selectedNodeContextId];
    return selectedNodeContextComponent?.config?.variants;
  }
);
