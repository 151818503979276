import { capitalize } from 'lodash';
import { SelectFieldOptionRenderer } from '@jux/ui/components/editor/components/panels/DDP/base';
import { useParseFieldValue } from '../useParseFieldValue';
import { useFieldTokens } from '../useFieldTokens';
import {
  UseSelectFieldProps,
  UseSelectFieldReturnProps,
} from './useSelectField.interface';
import { useSelectFieldValue } from './useSelectFieldValue';
import { useSelectFieldInputs } from './useSelectFieldInputs';
import { getFirstValue } from './useSelectField.utils';

export const useSelectField = ({
  fieldName,
  value,
  initialValue,
  saveChanges: setFieldValue,
  initSelectionOptions,
  tokenType,
  parentFieldTokenType,
}: UseSelectFieldProps): UseSelectFieldReturnProps => {
  const { rawValue } = useParseFieldValue({
    fieldName,
    value,
    initialValue,
    tokenType,
    parentFieldTokenType,
  });

  const { options, setOptions, selectedOption, setSelectedOption } =
    useSelectFieldValue({
      rawValue: { label: rawValue, value: rawValue },
      initSelectionOptions,
    });

  const {
    handleBlur,
    handleEnter,
    handleEscape,
    handleOnSelect,
    updateStateByTokenValue,
  } = useSelectFieldInputs({
    parsedValue: rawValue,
    initialValue,
    saveChanges: setFieldValue,
    initSelectionOptions,
    selectedOption,
    setOptions,
    setSelectedOption,
  });

  const {
    isTokenized,
    tokens,
    selectedToken,
    tokenDisplayName,
    tokenParsedValue,
    handleCreateNewTokenSaved,
    handleTokenSelection,
    handleTokenDetach,
    tokensPopper,
  } = useFieldTokens({
    fieldName,
    setStateByTokenValue: updateStateByTokenValue,
    setFieldValue,
    value,
    tokenType,
    parentFieldTokenType,
  });

  const onTokenDrawerCancel = (initialVal: string) => {
    setFieldValue(initialVal);
  };

  const setFieldValueOnTokenDrawerChange = ({
    newValue,
  }: {
    newValue: string;
    subFieldName?: string;
  }) => {
    setFieldValue(newValue);
  };

  return {
    value: capitalize(getFirstValue(selectedOption?.value ?? '')),
    rawValue: capitalize(getFirstValue(rawValue ?? '')),
    setValue: setFieldValue,

    autocompleteProps: {
      options,
      onSelect: handleOnSelect,
      onBlur: handleBlur,
      onEnter: handleEnter,
      onEscape: handleEscape,
      inputName: fieldName,
      renderOption: SelectFieldOptionRenderer,
    },

    tokensProps: tokenType
      ? {
          tokenType,
          tokens,
          isTokenized,
          selectedToken,
          tokenDisplayName,
          tokenParsedValue,
          onTokenSelection: handleTokenSelection,
          onTokenDetach: handleTokenDetach,
          tokensPopperProps: tokensPopper,
          tokenDrawerProps: {
            initialValue: rawValue,
            onValueChange: setFieldValueOnTokenDrawerChange,
            onSave: handleCreateNewTokenSaved,
            onCancelButton: onTokenDrawerCancel,
          },
        }
      : undefined,
  };
};
