import * as uuid from 'uuid';
import {
  VariantsConfig,
  ComponentPropType,
  NodeType,
  ComponentTagNames,
  JuxComponentData,
} from '@jux/data-entities';

import { ElementDataInit } from '../../types/ElementDataInit';
import { getJuxLabelElementData } from '../label';
import { getHelperTextWithLabelElement } from '../common/helperTextAndLabel';
import { ComponentConfigWithStates as StylesConfig } from '@jux/types';
import { getJuxCheckboxInstanceElementData } from './checkboxInstance';

const CHECKBOX_FIELD_ELEMENT_DEFAULT_DISPLAY_NAME = 'checkbox_field';

const CHECKBOX_FIELD_ELEMENT_VARIANTS_CONFIG: VariantsConfig = [
  {
    variant: 'disabled',
    propType: ComponentPropType.Enum,
    options: [
      { value: true, label: 'True' },
      { value: false, label: 'False' },
    ],
    defaultValue: false,
    isFixed: true,
  },
  {
    variant: 'checked',
    propType: ComponentPropType.Enum,
    options: [
      { value: true, label: 'Checked' },
      { value: false, label: 'Unchecked' },
      { value: 'indeterminate', label: 'Indeterminate' },
    ],
    defaultValue: true,
    isFixed: true,
  },
];

const CHECKBOX_FIELD_ELEMENT_DEFAULT_STYLES: StylesConfig = {
  root: {
    cursor: 'default',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '0 10px',
    borderStyle: 'none',
    borderColor: '', // this is set so that DDP would not show browser default #767676 and show empty value instead
    outline: 'none',
    background: 'none',
    width: 'auto',
    height: 'auto',
  },
  variants: [
    {
      propsValues: {
        disabled: true,
      },
      styles: {
        cursor: 'default',
      },
    },
  ],
  states: {
    // TODO: make states work in editor without having to include such empty objects
    hover: {
      root: {},
      variants: [],
    },
    // TODO: make states work in editor without having to include such empty objects
    active: {
      root: {},
      variants: [],
    },
  },
};

const getJuxCheckboxChildren = ({
  rootCheckboxId,
}: {
  rootCheckboxId: string;
}) => {
  const { root: checkboxInstance, subComponents: CheckboxSubComponents } =
    getJuxCheckboxInstanceElementData({
      parentId: rootCheckboxId,
    });

  const checkboxLabelTrigger = getJuxLabelElementData({
    contextParentId: rootCheckboxId,
    parentId: rootCheckboxId,
    labelForId: checkboxInstance.id,
    displayName: 'trigger',
  }).root;
  const { root: helperText, subComponents: helperTextSubComponents } =
    getHelperTextWithLabelElement({
      contextParentId: rootCheckboxId,
      parentId: checkboxLabelTrigger.id,
    });
  checkboxLabelTrigger.children = [helperText.id];

  return {
    checkboxInstance,
    checkboxLabelTrigger,
    subComponents: (
      [
        checkboxInstance,
        checkboxLabelTrigger,
        helperText,
      ] as Array<JuxComponentData>
    ).concat(CheckboxSubComponents || [], helperTextSubComponents || []),
  };
};

export const getJuxCheckboxFieldElementData: ElementDataInit<undefined> = ({
  contextParentId,
} = {}) => {
  const rootCheckboxId = uuid.v4();

  const { checkboxInstance, checkboxLabelTrigger, subComponents } =
    getJuxCheckboxChildren({ rootCheckboxId });

  return {
    root: {
      type: NodeType.LOCAL_COMPONENT,
      id: rootCheckboxId,
      scopes: [],
      tagName: ComponentTagNames.JuxCheckboxField,
      styles: CHECKBOX_FIELD_ELEMENT_DEFAULT_STYLES,
      displayName: CHECKBOX_FIELD_ELEMENT_DEFAULT_DISPLAY_NAME,
      config: {
        contextParentId,
        props: {
          // starting values of props
          checked: true,
          disabled: false,
          hierarchy: 'primary',
        },
        variants: CHECKBOX_FIELD_ELEMENT_VARIANTS_CONFIG,
      },
      children: [checkboxInstance.id, checkboxLabelTrigger.id],
    },
    subComponents,
  };
};
