import { useState, useEffect } from 'react';

const DEFAULT_DELAY = 250;

export const useDelayLoadingEffect = (
  dependencies: Array<any>,
  delay = DEFAULT_DELAY
) => {
  const [isLoading, setIsLoading] = useState(false);
  // this is a hack to make a small delay before the tokens are rendered
  // in order to give the user a better experience when switching between token sets
  // (an indication that something is happening)
  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, delay);
    // we want to run this effect when the dependencies change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, ...dependencies]);

  return isLoading;
};
