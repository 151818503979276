import { v4 as uuidv4 } from 'uuid';
import { useCallback } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import { generateName } from '@jux/ui/utils/generateName';
import { useTokenSetState } from '@jux/ui/state';
import { CORE_TOKEN_SET_NAME } from '@jux/types';

export const useCreateTokenSet = () => {
  const { setCurrentTokenSet, tokenSets } = useTokenSetState();

  const {
    tokenSetsActions: { addFirstTokenSetAfterCore, addTokenSet },
  } = useStoreActions();

  const createTokenSet = useCallback(async () => {
    const tokenSetNames = Object.values(tokenSets)
      ?.map((tokenSet) => tokenSet?.name ?? '')
      .filter(Boolean);
    const generatedTokenSetName = generateName({
      baseName: 'new_set',
      separator: '_',
      namesArray: tokenSetNames,
    });

    const isSingle = Object.keys(tokenSets).length === 1;
    const hasCore = Boolean(tokenSets[CORE_TOKEN_SET_NAME]);

    const shouldAddFirstTokenSetAfterCore = isSingle && hasCore;

    if (shouldAddFirstTokenSetAfterCore) {
      const tokenSetId = Object.values(tokenSets)[0]?.id;
      if (!tokenSetId) return;

      addFirstTokenSetAfterCore({
        name: generatedTokenSetName,
      });

      setCurrentTokenSet({
        key: tokenSetId,
        id: tokenSetId,
      });

      return;
    }

    const tokenSetId = uuidv4();

    addTokenSet({
      name: generatedTokenSetName,
      tokenSetId,
    });

    setCurrentTokenSet({
      key: tokenSetId,
      id: tokenSetId,
    });
  }, [addFirstTokenSetAfterCore, addTokenSet, setCurrentTokenSet, tokenSets]);

  return {
    createTokenSet,
  };
};
