import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import {
  useSetFieldValue,
  useFieldValues,
  useMultiDirectionFields,
} from '../../../../hooks';

const FIELD_KEY = DDPPrimitiveFieldsKeys.padding;

export const usePaddingFields = () => {
  const { value, initialValue } = useFieldValues(FIELD_KEY);
  const { saveValue: setPadding } = useSetFieldValue(FIELD_KEY);

  const {
    rawAllDirectionsValue: rawPaddingValue,
    parsedRawAllDirectionsValue: parsedRawPaddingValue,
    allDirectionsField: paddingField,
    topField: paddingTopField,
    rightField: paddingRightField,
    bottomField: paddingBottomField,
    leftField: paddingLeftField,
    isComputed,
  } = useMultiDirectionFields({
    value,
    initialValue,
    fieldName: FIELD_KEY,
    saveChanges: setPadding,
  });

  return {
    paddingField,
    paddingTopField,
    paddingRightField,
    paddingBottomField,
    paddingLeftField,
    rawPaddingValue,
    parsedRawPaddingValue,
    isComputed,
  };
};
