import { CanjuxState } from '@jux/canjux/core';
import type { Draft as WritableDraft } from 'mutative';

export const setRootComponentUpdateTime = ({
  components,
  id,
}: {
  components: WritableDraft<CanjuxState['components']>;
  id: string;
}) => {
  const component = components[id];
  let ancestor = component;
  while (ancestor?.parentId) {
    ancestor = components[ancestor.parentId];
  }

  if (ancestor?.updatedAt) {
    ancestor.updatedAt = new Date().getTime();
  }
};
