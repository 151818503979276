import * as uuid from 'uuid';
import {
  NodeType,
  ComponentPropType,
  COMPONENT_TAG_NAME,
} from '@jux/data-entities';
import { Colors } from '../../common/colors';
import { ElementDataInit } from '../../types/ElementDataInit';
import { PLACEHOLDER_PSEUDO_ELEMENT } from '@jux/constants';
import {
  ComponentConfigWithStates as StylesConfig,
  StylesObject,
} from '@jux/types';

export const TEXTAREA_ELEMENT_DEFAULT_DISPLAY_NAME = 'textarea';

export const TEXTAREA_DISABLED_STYLES: StylesObject = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: Colors.Neutrals_200,
  color: Colors.Neutrals_400,
  backgroundColor: 'white',
};

export const TEXTAREA_ELEMENT_DEFAULT_ROOT_STYLES: StylesConfig = {
  root: {
    [PLACEHOLDER_PSEUDO_ELEMENT]: {
      color: Colors.Neutrals_400,
    },
    border: `1px solid ${Colors.Neutrals_400}`,
    borderRadius: '4px',
    color: Colors.Neutrals_600,
    display: 'inline-block',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    height: '194px',
    outline: 'none',
    padding: '9px 12px',
    width: '320px',
  },
  states: {
    hover: {
      root: {},
      variants: [
        {
          propsValues: {
            disabled: false,
          },
          styles: {
            borderColor: Colors.Neutrals_600,
          },
        },
      ],
    },
    'focus-visible': {
      root: {},
      variants: [
        {
          propsValues: {
            disabled: false,
          },
          styles: {
            border: `1px solid ${Colors.Brand_500}`,
          },
        },
      ],
    },
  },
  variants: [
    {
      propsValues: {
        disabled: true,
      },
      styles: TEXTAREA_DISABLED_STYLES,
    },
    {
      propsValues: {
        resizable: false,
      },
      styles: {
        resize: 'none',
      },
    },
  ],
};

export const getJuxTextareaElementData: ElementDataInit<{
  styles?: StylesConfig;
}> = ({
  contextParentId,
  parentId,
  styles = TEXTAREA_ELEMENT_DEFAULT_ROOT_STYLES,
} = {}) => {
  const id = uuid.v4();
  return {
    root: {
      tagName: COMPONENT_TAG_NAME.JuxTextarea,
      type: NodeType.LOCAL_COMPONENT,
      id: id,
      parentId,
      scopes: [],
      children: [],
      styles,
      config: {
        contextParentId,
        props: {
          disabled: false,
          placeholder: 'Placeholder',
          value: 'Value',
          resizable: true,
        },
        variants: [
          {
            variant: 'disabled',
            propType: ComponentPropType.Enum,
            options: [
              { value: true, label: 'True' },
              { value: false, label: 'False' },
            ],
            defaultValue: false,
            isFixed: true,
          },
          {
            variant: 'resizable',
            propType: ComponentPropType.Enum,
            options: [
              { value: true, label: 'True' },
              { value: false, label: 'False' },
            ],
            defaultValue: true,
            isFixed: true,
          },
        ],
      },
      displayName: TEXTAREA_ELEMENT_DEFAULT_DISPLAY_NAME,
    },
  };
};
