/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';

const { styled } = createDrimz();

type ShortcutKey_text_root_Props = PropsOf<typeof JuxReactElements.JuxText>;

const ShortcutKey_text_root: FC<ShortcutKey_text_root_Props> = forwardRef(
  (props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />
);

ShortcutKey_text_root.displayName = 'ShortcutKey_text_root';

ShortcutKey_text_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        color: '{color.core.grays_800}',
        width: 'auto',
        height: 'auto',
        display: 'inline-block',
        fontSize: '{typography.core.12_regular.fontSize}',
        fontFamily: '{typography.core.12_regular.fontFamily}',
        fontWeight: '{typography.core.12_regular.fontWeight}',
        lineHeight: '{typography.core.12_regular.lineHeight}',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        letterSpacing: '{typography.core.12_regular.letterSpacing}',
      },
    },
  }) as ShortcutKey_text_root_Props['rootElement'],
};

export type ShortcutKey_text_Props = ShortcutKey_text_root_Props;

export const ShortcutKey_text: FC<ShortcutKey_text_Props> = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>,
  ShortcutKey_text_Props
>((props, ref) => <ShortcutKey_text_root {...props} ref={ref} />);

ShortcutKey_text.displayName = 'ShortcutKey_text';
ShortcutKey_text.defaultProps = { text: 'label' };

type ShortcutKey_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;

const ShortcutKey_root: FC<ShortcutKey_root_Props> = forwardRef(
  (props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />
);

ShortcutKey_root.displayName = 'ShortcutKey_root';

ShortcutKey_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '2px 4px 2px 4px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        borderRadius: '2px',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '{color.core.grays_100}',
      },
      variants: [
        {
          propsValues: {},
          styles: {
            '[data-jux-context="df8ba136-f9fe-41b4-a740-125de8050101"]': {
              color: '{color.core.grays_800}',
              fontSize: '12px',
              fontFamily: 'Inter, sans-serif',
              fontWeight: '400',
              lineHeight: '12px',
              letterSpacing: '',
            },
          },
        },
      ],
    },
  }) as ShortcutKey_root_Props['rootElement'],
};

export type ShortcutKey_Props = ShortcutKey_root_Props & {
  ShortcutKey_text_Props?: ShortcutKey_text_Props;
};

export const ShortcutKey: FC<ShortcutKey_Props> = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>,
  ShortcutKey_Props
>(({ ShortcutKey_text_Props, ...props }, ref) => (
  <ShortcutKey_root
    {...props}
    ref={ref}
    data-jux-context="f8e5ff89-f507-476d-abbb-16d687e42674"
  >
    {props.children || (
      <>
        <ShortcutKey_text
          {...ShortcutKey_text_Props}
          data-jux-context="df8ba136-f9fe-41b4-a740-125de8050101"
        />
      </>
    )}
  </ShortcutKey_root>
));

ShortcutKey.displayName = 'ShortcutKey';
ShortcutKey.defaultProps = {};
