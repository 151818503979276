export const removeIllegalChars = (str: string) => str.replace(/[{}$]/g, '');
export const formatWhitespace = (str: string) => str.replace(/\s/g, '_');
export const formatCase = (str: string) => str.toLowerCase();

export const FORMATTERS = {
  removeIllegalChars,
  formatWhitespace,
  formatCase,
} as const;

type Formatter = keyof typeof FORMATTERS;

export const formatName = (str: string, formatters?: Array<Formatter>) =>
  formatters?.reduce((acc, formatter) => FORMATTERS[formatter](acc), str) ||
  str;

// to make sure we're generating a unique name
export const generateName = ({
  baseName,
  namesArray,
  separator = ' ',
  options,
}: {
  baseName: string;
  namesArray?: Array<string>;
  separator?: string;
  options?: {
    formatters?: Array<Formatter>;
  };
}): string => {
  const formatters = options?.formatters || [];
  let i = 0;
  let nameIsNotValid = true;
  let name = '';

  while (nameIsNotValid) {
    i++;
    name = formatName(`${baseName}${separator}${i}`, formatters);

    // if there are no names in the array, we can use the name
    if (!namesArray) return name;

    // if there are names in the array, we need to check if the name is already in use
    let nameAlreadyExists = false;
    for (const nameInArray of namesArray) {
      if (name === nameInArray) {
        nameAlreadyExists = true;
      }
    }

    nameIsNotValid = nameAlreadyExists;
  }

  return name;
};
