import { useCallback, useRef, useState } from 'react';
import { useImportFiles, FilesImportInputRef } from '@jux/ui/components';
import { useUploadAssets } from './useUploadAssets';

export const useUploadAsset = () => {
  const [, setIsImporting] = useState(false);
  const { uploadAssets } = useUploadAssets();

  const isDoneImporting = useCallback(() => {
    setIsImporting(false);
  }, []);

  const filesImportHandler = useCallback(
    async (files: FileList) => {
      isDoneImporting();

      return uploadAssets(files);
    },
    [isDoneImporting, uploadAssets]
  );
  const inputRef = useRef<FilesImportInputRef>(null);

  const { importFiles } = useImportFiles(filesImportHandler);

  const handleImportButtonClick = useCallback(() => {
    setIsImporting(true);
    inputRef.current?.click();
  }, []);

  return {
    inputRef,
    importFiles,
    handleImportButtonClick,
    isDoneImporting,
  };
};
