import { CanjuxState } from '@jux/canjux/core';
import type { Draft as WritableDraft } from 'mutative';

export const isSiblingAncestorNode = ({
  siblingId,
  ancestorId,
  components,
}: {
  siblingId?: string;
  ancestorId?: string;
  components: WritableDraft<CanjuxState>['components'];
}) => {
  if (!ancestorId || !siblingId) {
    return false;
  }

  const ancestor = components[ancestorId];
  const sibling = components[siblingId];

  if (!ancestor?.id || !sibling?.parentId) {
    return false;
  }

  return ancestor?.id === sibling.parentId;
};
