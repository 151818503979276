import { isMac, Viewport } from '@jux/canjux/core';

export const viewChanged = (
  prevViewport: Viewport,
  eventViewport: any
): boolean =>
  prevViewport.x !== eventViewport.x ||
  prevViewport.y !== eventViewport.y ||
  prevViewport.zoom !== eventViewport.k;

export const eventToFlowTransform = (eventViewport: any): Viewport => ({
  x: eventViewport.x,
  y: eventViewport.y,
  zoom: eventViewport.k,
});

export const isZoomScrollEvent = (event: any) =>
  Boolean(
    isMac()
      ? // on Mac the user can zoom-scroll using metaKey + wheel,
        // but pinch-zoom (trackpad) always triggers event with
        // `ctrlKey=true`, regardless of actual keys pressed
        event.metaKey || event.ctrlKey
      : event.ctrlKey
  );
