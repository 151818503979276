import { formatTokenName } from '../formatters';

export const insertInnerText = (element: HTMLElement, str: string) => {
  const selection = window.getSelection();

  if (!selection?.rangeCount) return;

  selection.deleteFromDocument();

  selection
    .getRangeAt(0)
    .insertNode(document.createTextNode(formatTokenName(str)));

  selection.collapseToEnd();
};
