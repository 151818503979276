import { JuxStoreActionFn, SetStateFn, JuxStore } from '../store.interface';

/**
 * Wraps an action function to inject the current state.
 */
export const injectActionState =
  <State extends JuxStore>(setState: SetStateFn<State>) =>
  <Params extends Record<string, any>>(fn: JuxStoreActionFn<Params, State>) =>
  (params: Params) =>
    setState((state) => fn({ ...params, state }));
