export const calculateCenterPosition = ({
  container,
  zoom,
  targetDimensions,
}: {
  container: {
    width: number;
    height: number;
    position: {
      x: number;
      y: number;
    };
  };
  zoom: number;
  targetDimensions: {
    width: number;
    height: number;
  };
}) => ({
  x:
    (container.position.x * -1 + container.width / 2) / zoom -
    targetDimensions.width / 2,
  y:
    (container.position.y * -1 + container.height / 2) / zoom -
    targetDimensions.height / 2,
});
