import { FC, useCallback, useRef } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { AssetData } from '@jux/data-entities';
import { AssetsTabs } from '@jux/ui/components';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { CustomAssetSelect } from './CustomAssetSelect';
import { useDDPAssetsPopper } from './useDDPAssetsPopper';

export const AssetContent: FC<{
  selectedAssetData: AssetData | undefined;
  selectedAssetId: string | undefined;
  handleReplaceAsset: (assetId: string) => void;
}> = ({ selectedAssetId, selectedAssetData, handleReplaceAsset }) => {
  const { shouldRender } = useSubModule(DDPSubModulesKeys.assetContent);

  const { closeDDPAssetsPopper, openDDPAssetsPopper, isDDPAssetsPopperOpen } =
    useDDPAssetsPopper();

  const customSelectRef = useRef<HTMLDivElement>(null);

  const handleAssetClick = useCallback(
    async (assetId: string) => {
      if (!assetId) return;

      await handleReplaceAsset(assetId);

      closeDDPAssetsPopper();
    },
    [closeDDPAssetsPopper, handleReplaceAsset]
  );

  const handleOpenDDPAssetsPopper = useCallback(() => {
    openDDPAssetsPopper({
      sourceElementRef: customSelectRef,
      selectedAssetId,
      selectedAssetType: selectedAssetData?.isSystemAsset
        ? AssetsTabs.systemAssets
        : AssetsTabs.userAssets,
      handleAssetClick,
    });
  }, [
    selectedAssetData?.isSystemAsset,
    handleAssetClick,
    openDDPAssetsPopper,
    selectedAssetId,
  ]);

  if (!shouldRender) return null;

  return (
    <SubModule title="Asset content" renderInline>
      <CustomAssetSelect
        onClick={handleOpenDDPAssetsPopper}
        ref={customSelectRef}
        isOpen={isDDPAssetsPopperOpen}
        selectedAssetData={selectedAssetData}
      />
    </SubModule>
  );
};
