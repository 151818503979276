import { useStore } from './useStore';

export const useStoreActions = () =>
  useStore((state) => ({
    assetsActions: state.assetsActions,
    commonActions: state.commonActions,
    componentActions: state.componentActions,
    ddpActions: state.ddpActions,
    propsActions: state.propsActions,
    tokenSetsActions: state.tokenSetsActions,
  }));
