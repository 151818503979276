import { useCallback } from 'react';

import {
  canvasDimensions,
  useStoreActions,
  useViewportHelper,
  storeApi,
  selectCurrentCanvas,
} from '@jux/canjux/core';

export const useGoToSourceComponent = () => {
  const {
    commonActions: { setSelectedNodes },
  } = useStoreActions();
  const { setCenter } = useViewportHelper();

  const goToSourceComponent = useCallback(
    (sourceComponentNodeId: string) => {
      const currentCanvas = selectCurrentCanvas(storeApi.getState());
      const sourceComponent = currentCanvas.nodes[sourceComponentNodeId];

      const canvasDimensionsData = canvasDimensions(storeApi.getState());

      const { position } = sourceComponent;

      const { canvasNodesDimensions } = storeApi.getState();
      const { height, width } = canvasNodesDimensions[sourceComponentNodeId];
      const { zoom } = canvasDimensionsData;

      setSelectedNodes({ nodeIds: [sourceComponentNodeId] });
      setCenter(position.x + width / 2, position.y + height / 2, { zoom });
    },
    [setCenter, setSelectedNodes]
  );

  return {
    goToSourceComponent,
  };
};
