import { FC } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useStore } from '@jux/canjux/core';
import {
  BackgroundHead,
  Canvas,
  FpsCounter,
  Layout,
  Loading,
  Modal,
  Navigation,
  Popper,
  Sidebar,
  useCanvas,
} from '@jux/ui/components';
import {
  useEnterRoomEffect,
  useGetCanvasData,
  useRepairStateOnConnection,
  useSelectCanvasByRoute,
} from '@jux/ui/components/editor/hooks';
import { useAnalyticsContextEffect } from '@jux/ui/hooks';
import { useSettingsState } from '@jux/ui/state';
import { CANJUX_BACKGROUND_COLOR } from '@jux/ui/theme/palette';
import { TokenDrawer } from '../tokens/token-drawer/base';

export const EditorLayout: FC = () => {
  const { canvas } = useGetCanvasData();

  const { isLoading } = useStore((s) => ({
    isLoading: s.liveblocks.isStorageLoading,
  }));

  useEnterRoomEffect(canvas.roomId);

  useRepairStateOnConnection();

  useAnalyticsContextEffect(canvas);

  useSelectCanvasByRoute();

  const canvasProps = useCanvas();

  const {
    settings: { fpsCounter },
  } = useSettingsState();

  return (
    <>
      <Loading visible={isLoading} />
      <DndProvider backend={HTML5Backend}>
        {fpsCounter && <FpsCounter />}
        <TokenDrawer />
        <Modal />
        <Popper />
        <BackgroundHead backgroundColor={CANJUX_BACKGROUND_COLOR} />
        <Layout
          sidebar={<Sidebar />}
          navbar={<Navigation />}
          content={<Canvas {...canvasProps} />}
          contentFullscreen
        />
      </DndProvider>
    </>
  );
};
