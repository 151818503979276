import { createSelector } from 'reselect';
import { CanjuxState, getContextParentByNodeId } from '@jux/canjux/core';
import { getSelectedNodeId } from './utils';

export const selectedNodeContextParentDisplayName = createSelector(
  [
    (state: CanjuxState) => state.components,
    (state: CanjuxState) => state.selectedNodesStack,
  ],
  (components, selectedNodesStack) => {
    const selectedNodeContextId = getContextParentByNodeId({
      components,
      id: getSelectedNodeId(selectedNodesStack),
    });

    if (!selectedNodeContextId) return '';

    const selectedNodeContextComponent = components[selectedNodeContextId];
    if (!selectedNodeContextComponent) return '';

    return (
      selectedNodeContextComponent.displayName ||
      selectedNodeContextComponent.tagName ||
      ''
    );
  }
);
