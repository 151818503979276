import { FC } from 'react';
import { z } from 'zod';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import {
  useFieldValues,
  useSetFieldValue,
  useNumericField,
  SupportedNumericBaseUnits,
} from '../../../../hooks';
import { NumericField } from '../../../../base';

const opacityFieldSchema = z
  .string()
  .regex(/^(100|[1-9]?[0-9])([.]\d+)?%?$/)
  .optional();

const FIELD_KEY = DDPPrimitiveFieldsKeys.opacity;

export const OpacityField: FC = () => {
  const { value, initialValue } = useFieldValues(FIELD_KEY);
  const { saveValue: setFieldValue } = useSetFieldValue(FIELD_KEY);

  const props = useNumericField({
    value,
    initialValue,
    saveChanges: setFieldValue,
    fieldName: FIELD_KEY,
    fieldSchema: opacityFieldSchema,
    options: {
      baseUnit: SupportedNumericBaseUnits.Percentage,
      fallbackValue: '1',
      defaultValue: '100%',
      saveAsDecimal: true,
      min: 0,
      max: 100,
    },
  });

  return (
    <NumericField icon="DDP_FIELD_OPACITY" tooltipTitle="Opacity" {...props} />
  );
};
