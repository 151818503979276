import {
  ComponentConfig,
  type ComponentConfigWithStates,
  type StylesState,
} from '@jux/types';
import { dedupeVariants } from './dedupeVariants';
import { getStateOverrideStyles } from './getStateOverrideStyles';
import { getStylesWithStateSelectors } from './getStylesWithStateSelectors';
import { dedupeContextStyles } from './dedupeContextStyles';

/**
 * Returns an updated component config with the state styles applied to root and variants.
 * @param styles - The component's config with states.
 * @param state - The state to set
 */
export const calculateStyles = (
  styles: ComponentConfigWithStates,
  state?: StylesState
): Omit<ComponentConfig, 'contextStyles'> => {
  const clonedStyles = dedupeContextStyles(structuredClone(styles));

  const result = dedupeVariants(
    state
      ? // return the styles with the state overrides,
        // without extrapolating the root and variants from the states
        getStateOverrideStyles(clonedStyles, state)
      : // return the styles with states extrapolated into root and variants
        getStylesWithStateSelectors(clonedStyles)
  );

  delete result.contextStyles;

  return result;
};
