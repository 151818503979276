import {
  ResizePosition,
  ResizeHorizontalPosition,
  ResizeVerticalPosition,
} from './node-resizer.interface';

type GetDirectionParams = {
  width: number;
  prevWidth: number;
  height: number;
  prevHeight: number;
  invertX: boolean;
  invertY: boolean;
};

// returns an array of two numbers (0, 1 or -1) representing the direction of the resize
// 0 = no change, 1 = increase, -1 = decrease
export function getDirection({
  width,
  prevWidth,
  height,
  prevHeight,
  invertX,
  invertY,
}: GetDirectionParams) {
  const deltaWidth = width - prevWidth;
  const deltaHeight = height - prevHeight;

  const direction = [
    deltaWidth > 0 ? 1 : deltaWidth < 0 ? -1 : 0,
    deltaHeight > 0 ? 1 : deltaHeight < 0 ? -1 : 0,
  ];

  if (deltaWidth && invertX) {
    direction[0] = direction[0] * -1;
  }

  if (deltaHeight && invertY) {
    direction[1] = direction[1] * -1;
  }
  return direction;
}

export function handleDragEnd({ trackDragEnd }: { trackDragEnd: () => void }) {
  trackDragEnd();
}

export const isControlPositionHorizontal = (
  controlPosition: ResizePosition
): boolean =>
  controlPosition === ResizePosition.left ||
  controlPosition === ResizePosition.right;

export const isControlPositionVertical = (
  controlPosition: ResizePosition
): boolean =>
  controlPosition === ResizePosition.top ||
  controlPosition === ResizePosition.bottom;

export const isControlPositionEnableX = (
  controlPosition: ResizePosition
): boolean =>
  controlPosition === ResizePosition.left ||
  controlPosition === ResizePosition.right ||
  controlPosition === ResizePosition.topLeft ||
  controlPosition === ResizePosition.bottomLeft ||
  controlPosition === ResizePosition.topRight ||
  controlPosition === ResizePosition.bottomRight;

export const isControlPositionEnableY = (
  controlPosition: ResizePosition
): boolean =>
  controlPosition === ResizePosition.top ||
  controlPosition === ResizePosition.bottom ||
  controlPosition === ResizePosition.topLeft ||
  controlPosition === ResizePosition.bottomLeft ||
  controlPosition === ResizePosition.topRight ||
  controlPosition === ResizePosition.bottomRight;

export const isControlPositionInvertX = (
  controlPosition: ResizePosition
): boolean =>
  controlPosition === ResizePosition.left ||
  controlPosition === ResizePosition.bottomLeft ||
  controlPosition === ResizePosition.topLeft;

export const isControlPositionInvertY = (
  controlPosition: ResizePosition
): boolean =>
  controlPosition === ResizePosition.top ||
  controlPosition === ResizePosition.topLeft ||
  controlPosition === ResizePosition.topRight;

export const mergeControlPosition = ({
  horizontal,
  vertical,
}: {
  vertical: ResizeVerticalPosition;
  horizontal: ResizeHorizontalPosition;
}): ResizePosition => {
  if (horizontal === ResizePosition.left) {
    if (vertical === ResizePosition.top) {
      return ResizePosition.topLeft;
    } else {
      return ResizePosition.bottomLeft;
    }
  } else {
    if (vertical === ResizePosition.top) {
      return ResizePosition.topRight;
    } else {
      return ResizePosition.bottomRight;
    }
  }
};
