import { useMemo } from 'react';
import {
  DDPFieldsStateKeys,
  useDDPFieldsState,
} from '@jux/ui/components/editor/state';

export const useFieldValues = <Key extends DDPFieldsStateKeys>(key: Key) => {
  const { getFieldsStateByKey } = useDDPFieldsState();

  return useMemo(() => getFieldsStateByKey(key), [getFieldsStateByKey, key]);
};
