import { ComponentTagNames } from '@jux/data-entities';
import { convertAssetNameToComponentName } from '@jux/ui/utils/assets';
import {
  AssetsActionsParams,
  JuxStore,
  JuxStoreActionFn,
} from '../../store.interface';
import { setRootComponentUpdateTime } from '../utils';
import { setLayersData } from '../../helpers';

/**
 * Replace asset content.
 */
export const replaceAssetContent: JuxStoreActionFn<
  AssetsActionsParams['replaceAssetContent'],
  JuxStore
> = ({ newAssetId, componentId, state }) => {
  const component = state.components[componentId];

  if (
    !(newAssetId in state.assets) ||
    component?.tagName !== ComponentTagNames.JuxSvg
  ) {
    return state;
  }

  // in case we're trying to replace an initial jux asset (that is provided by the system inside the props content)
  delete component.config.props.content;

  component.sourceComponentId = newAssetId;
  component.displayName = convertAssetNameToComponentName(
    state.assets[newAssetId].name ?? ''
  );

  setRootComponentUpdateTime({
    id: componentId,
    components: state.components,
  });

  setLayersData(state);

  return state;
};
