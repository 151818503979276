import { EditableTypography, Typography } from '@jux/ui/components';
import { ComponentProps } from 'react';
import { textValidators } from '@jux/ui/utils/textValidators';
import { selectOrgComponentsNames, storeApi } from '@jux/canjux/core';

export type NodeNameLabelProps = {
  label: string;
  color: string;
  isEditing?: boolean;
  maxWidth: number;
} & Pick<
  ComponentProps<typeof Typography>,
  /** enabling the editing state to cause re-render of this component in a controlled manner */
  | 'onDoubleClick'
  /** disabling the editing state to cause re-render of this component in a controlled manner */
  | 'onBlur'
>;

const getOrgComponentNames = () =>
  selectOrgComponentsNames(storeApi.getState());

export const NodeToolbarLabel = ({
  label,
  color,
  isEditing = false,
  maxWidth,
  onDoubleClick,
  onBlur,
}: NodeNameLabelProps) => {
  const typographyProps: ComponentProps<typeof Typography> = {
    color,
    variant: 'label-xs-medium',
    sx: {
      maxWidth,
      overflow: 'hidden',
      textOverflow: isEditing ? 'auto' : 'ellipsis',
      whiteSpace: 'nowrap',
    },
  };

  const nameAlreadyExistsValidator = textValidators.nameAlreadyExistsValidator({
    namesArray: getOrgComponentNames,
    errorMessages: {
      nameAlreadyExists: 'Component name already exists',
      namesArrayUndefined: 'org components names are not loaded',
    },
  });

  return isEditing ? (
    <EditableTypography
      {...typographyProps}
      tooltipProps={{
        disabled: true,
      }}
      customValidators={[nameAlreadyExistsValidator]}
      label={label}
      onBlur={onBlur}
      autoFocus
      data-text-editable
    />
  ) : (
    <Typography {...typographyProps} onDoubleClick={onDoubleClick}>
      {label}
    </Typography>
  );
};
