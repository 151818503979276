import {
  CanjuxState,
  ComponentActionsParams,
  getValidComponentPlacement,
  JuxStoreActionFn,
  setLayersData,
} from '@jux/canjux/core';
import { duplicateNode } from './utils/duplicateNode';

export const duplicateSelectedNodes: JuxStoreActionFn<
  ComponentActionsParams['duplicateSelectedNodes'],
  CanjuxState
> = ({ state }) => {
  for (const componentId of state.selectedNodesStack) {
    const sourceComponent = state.components[componentId];

    if (!sourceComponent) return state;

    // Getting the right placement of a the new component on the canvas
    // TODO: need to provide the component׳s dimensions in order to calculate more accurate position
    const { width, height, transform } = state;

    const targetNodeId = state.components[componentId].parentId;

    const currentCanvas = state.canvases[state.currentCanvasName];
    // getting the right component placement
    const { targetChildIndex, position } = getValidComponentPlacement({
      canvas: currentCanvas,
      canvasDimensions: { width, height },
      canvasNodesDimensions: state.canvasNodesDimensions,
      components: state.components,
      sourceComponentId: sourceComponent.id,
      targetNodeId,
      transform,
    });

    duplicateNode({
      position,
      sourceComponentId: sourceComponent.id,
      state,
      targetCanvasName: currentCanvas.name,
      targetIndex: targetChildIndex,
      targetNodeId,
    });
  }

  setLayersData(state);

  return state;
};
