// state should be more free, and not coupled to a specific contract of the values that it get

import { merge } from 'lodash';

// for faster and easier development, that's why we use 'any' here
export type BaseFieldState = {
  initialValue: any;
  value: any;
};

// css key based fields
export type DDPPrimitiveFieldsState = {
  //  Background fields
  backgroundColor: BaseFieldState;

  // Border fields
  borderColor: BaseFieldState;
  borderRadius: BaseFieldState;
  borderStyle: BaseFieldState;
  borderWidth: BaseFieldState;

  // Effects fields
  boxShadow: BaseFieldState;

  // Layout fields
  padding: BaseFieldState;
  margin: BaseFieldState;
  display: BaseFieldState;
  flexDirection: BaseFieldState;
  flexWrap: BaseFieldState;
  alignItems: BaseFieldState;
  justifyContent: BaseFieldState;
  gap: BaseFieldState;

  // Opacity fields
  opacity: BaseFieldState;

  // Size fields
  // width sub-module
  width: BaseFieldState;
  minWidth: BaseFieldState;
  maxWidth: BaseFieldState;

  // height sub-module
  height: BaseFieldState;
  minHeight: BaseFieldState;
  maxHeight: BaseFieldState;

  // position sub-module
  position: BaseFieldState;

  // constraints sub-module
  top: BaseFieldState;
  right: BaseFieldState;
  bottom: BaseFieldState;
  left: BaseFieldState;

  // zIndex sub-module
  zIndex: BaseFieldState;

  // Text fields
  // typography
  fontFamily: BaseFieldState;
  fontWeight: BaseFieldState;
  fontSize: BaseFieldState;
  lineHeight: BaseFieldState;
  letterSpacing: BaseFieldState;

  // color
  color: BaseFieldState;

  // align / direction
  textAlign: BaseFieldState;
  direction: BaseFieldState;
};

export type PrimitiveFieldsKeys = keyof DDPPrimitiveFieldsState;

export const DDPPrimitiveFieldsKeys: {
  [key in keyof DDPPrimitiveFieldsState]: key;
} = {
  // background
  backgroundColor: 'backgroundColor',

  // border
  borderColor: 'borderColor',
  borderRadius: 'borderRadius',
  borderStyle: 'borderStyle',
  borderWidth: 'borderWidth',

  // effects
  boxShadow: 'boxShadow',

  // layout
  padding: 'padding',
  margin: 'margin',
  display: 'display',
  flexDirection: 'flexDirection',
  flexWrap: 'flexWrap',
  alignItems: 'alignItems',
  justifyContent: 'justifyContent',
  gap: 'gap',

  // opacity
  opacity: 'opacity',

  // size
  width: 'width',
  minWidth: 'minWidth',
  maxWidth: 'maxWidth',
  height: 'height',
  minHeight: 'minHeight',
  maxHeight: 'maxHeight',
  position: 'position',
  top: 'top',
  right: 'right',
  bottom: 'bottom',
  left: 'left',
  zIndex: 'zIndex',

  // text
  fontFamily: 'fontFamily',
  fontWeight: 'fontWeight',
  fontSize: 'fontSize',
  lineHeight: 'lineHeight',
  letterSpacing: 'letterSpacing',
  color: 'color',
  textAlign: 'textAlign',
  direction: 'direction',
};

type CompositeTokenFields = {
  typography: BaseFieldState;
};

export const DDPCompositeFieldsKeys: {
  [key in keyof CompositeTokenFields]: key;
} = {
  typography: 'typography',
};

type OtherTokenFields = {
  // Props fields
  textContent: BaseFieldState;
  value: BaseFieldState;
  placeholder: BaseFieldState;
};

export const DDPOtherFieldsKeys: {
  [key in keyof OtherTokenFields]: key;
} = {
  // props
  textContent: 'textContent',
  value: 'value',
  placeholder: 'placeholder',
};

export const DDPFieldsKeys = merge(
  {},
  DDPPrimitiveFieldsKeys,
  DDPCompositeFieldsKeys,
  DDPOtherFieldsKeys
);

export type DDPFieldsState = DDPPrimitiveFieldsState &
  CompositeTokenFields &
  OtherTokenFields;

export type DDPFieldsStateKeys = keyof DDPFieldsState;
