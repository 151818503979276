export const Colors = {
  Brand_200: '#98A6F9',
  Brand_500: '#3851DD',
  Brand_600: '#2740CD',
  Brand_700: '#1B32B8',
  Brand_800: '#1328A0',
  Neutrals_0: '#FFFFFF',
  Neutrals_100: '#F8F8F8',
  Neutrals_200: '#E6E6E6',
  Neutrals_300: '#C8C8C8',
  Neutrals_SpanishGrey: '#9E9E9E',
  Neutrals_400: '#9C9C9C',
  Neutrals_500: '#6A6A6A',
  Neutrals_600: '#3B3B3B',
  Neutrals_700: '#1A1A1A',
  Neutrals_800: '#070707',
  Neutrals_1000: '#000000',
} as const;
