import { formatToAlias, isAlias } from '@jux/design-tokens';
import {
  ALIAS_DESELECTION_TEXT,
  ALIAS_PLACEHOLDER_TEXT,
} from '@jux/ui/components/tokens/token-drawer/forms/helpers';

export const formatAliasPath = (item?: string) => {
  if (!item) return '';
  if (item === ALIAS_DESELECTION_TEXT || item === ALIAS_PLACEHOLDER_TEXT) {
    return '';
  }
  if (isAlias(item)) {
    return item;
  }
  return formatToAlias(item);
};
