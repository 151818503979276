import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { ActionButton } from '../../../editor/components/ActionButton';

export const TokenSetActionsPopper: FC<{
  onDeleteSet: () => void;
  showDeleteSet: boolean;
  onCreateGroup: () => void;
}> = ({ onDeleteSet, showDeleteSet, onCreateGroup }) => (
  <MuiGrid
    sx={{
      width: '100%',
      position: 'relative',
      padding: '3px',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: (theme) => theme.drimz.palette.background.default,
      borderRadius: (theme) => theme.drimz.size.borderRadius.small,
      border: 1,
      borderColor: (theme) => theme.drimz.palette.divider.primary,
      boxShadow: (theme) => theme.drimz.shadow.tokens.poppers.boxShadow,
    }}
  >
    <ActionButton onClick={onCreateGroup} icon="ADD">
      Create group
    </ActionButton>
    {showDeleteSet && (
      <ActionButton onClick={onDeleteSet} icon="DELETE">
        Delete token set
      </ActionButton>
    )}
  </MuiGrid>
);
