import { FC } from 'react';
import {
  IconButton,
  EditableTypography,
  ChipsInput,
  addChipClassName,
} from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { toFirstUpperCase } from '@jux/ui/utils/toFirstUpperCase';
import { PropertyFieldProps } from './PropertyField.interface';
import { propertyNameValidators } from './utils';

const MAX_LENGTH = 17;
const PROPERTY_HEADER_HEIGHT = 28;
const deletePropertyIconButtonClassName = 'delete-property-icon-button';

export const PropertyField: FC<PropertyFieldProps> = ({
  onAddPropValue,
  disableEditing,
  onDeleteProp,
  onDeletePropValue,
  onEditProp,
  onEditPropValue,
  title,
  valueOptions,
  propertiesNames,
}) => (
  <MuiGrid
    container
    item
    alignItems="center"
    sx={{
      '&:hover, &:focus, &:focus-within': {
        [`& .${deletePropertyIconButtonClassName}`]: {
          display: 'flex',
        },

        [`& .${addChipClassName}`]: {
          display: 'flex',
        },
      },
    }}
  >
    <MuiGrid
      item
      container
      alignItems="center"
      justifyContent="space-between"
      height={PROPERTY_HEADER_HEIGHT}
    >
      <EditableTypography
        label={toFirstUpperCase(title)}
        customValidators={propertyNameValidators({
          maxLength: MAX_LENGTH,
          propertiesNames,
        })}
        variant="label-s-medium"
        color={(theme) => theme.drimz.palette.text.secondary}
        onSaveChanges={onEditProp}
        disableEditing={disableEditing}
        maxLength={MAX_LENGTH}
      />
      {!disableEditing && (
        <MuiGrid item display="flex">
          <IconButton
            className={deletePropertyIconButtonClassName}
            icon="CLOSE"
            variant="icon"
            onClick={onDeleteProp}
            sx={{
              display: 'none',
            }}
          />
        </MuiGrid>
      )}
    </MuiGrid>
    <MuiGrid container item>
      <ChipsInput
        values={valueOptions}
        onAdd={onAddPropValue}
        onEditChipValue={onEditPropValue}
        onDelete={onDeletePropValue}
        disableEditing={disableEditing}
        maxLength={MAX_LENGTH}
        hideAddWithoutHover
      />
    </MuiGrid>
  </MuiGrid>
);
