import { DesignTokenTypeEnum, SupportedTokenTypes } from '@jux/design-tokens';
import { IconsVariantsType } from '@jux/ui/components';

export const tokenTypeNavItems: Array<{
  icon: IconsVariantsType;
  title: string;
  type: SupportedTokenTypes;
}> = [
  {
    icon: 'COLOR',
    title: 'Color',
    type: DesignTokenTypeEnum.color,
  },
  {
    icon: 'FONT_FAMILY',
    title: 'Font family',
    type: DesignTokenTypeEnum.fontFamily,
  },
  {
    icon: 'TYPOGRAPHY',
    title: 'Typography',
    type: DesignTokenTypeEnum.typography,
  },
  {
    icon: 'DIMENSION',
    title: 'Dimension',
    type: DesignTokenTypeEnum.dimension,
  },
  {
    icon: 'BORDER',
    title: 'Border',
    type: DesignTokenTypeEnum.border,
  },
];
