import { Organization } from '@jux/data-entities';
import { useGetUserOrganizationListQuery } from '@jux/ui/data';

type UserHasOrganizations = {
  userOrganizations: Array<Organization>;
  hasOrganizations: true;
};
type UserHasNoOrganizations = {
  userOrganizations: [];
  hasOrganizations: false;
};

export const useUserOrganizationsQuery = () => {
  const organizationsQuery = useGetUserOrganizationListQuery();

  let userOrganizationsStatus: UserHasOrganizations | UserHasNoOrganizations = {
    userOrganizations: [],
    hasOrganizations: false,
  };
  // check if the user has organizations
  if (organizationsQuery.data && organizationsQuery.data.length > 0) {
    userOrganizationsStatus = {
      userOrganizations: organizationsQuery.data,
      hasOrganizations: true,
    };
  }

  return {
    ...userOrganizationsStatus,
    isLoading: organizationsQuery.isLoading,
    error: organizationsQuery.isError
      ? organizationsQuery.error.message
      : undefined,
  };
};
