import { FC, useRef, useState } from 'react';
import { Background, Canjux } from '@jux/canjux/core';
import { ResizablePanel } from '@jux/ui/components';
import { useTheme } from '@jux/ui/components/common/mui';
import { CANVAS_BACKGROUND_COLOR } from '@jux/ui/theme/palette';
import { useCanvasDropItem } from '../../hooks';
import { DDP, LayersPanel, Magnifier, PropertiesPanel } from '../panels';
import { CanvasProps } from './Canvas.interface';
import * as S from './Canvas.style';
import { Layout } from './Layout';

export const Canvas: FC<CanvasProps> = ({
  defaultViewport,
  isLive,
  fitView,
}) => {
  const theme = useTheme();
  const canvasLayoutRef = useRef<HTMLDivElement>(null);
  const { dropRef: canjuxRef } = useCanvasDropItem(canvasLayoutRef);

  const [ddpPanelWidth, setDDPPanelWidth] = useState<number>(
    theme.drimz.size.editor.ddp.width
  );

  const [layersPanelWidth, setLayerPanelWidth] = useState<number>(
    theme.drimz.size.editor.layers.width
  );

  return (
    <Layout ref={canvasLayoutRef}>
      <Canjux
        ref={canjuxRef}
        minZoom={0.2}
        maxZoom={20}
        defaultViewport={defaultViewport}
        disableNodesInteraction={isLive}
        snapToGrid
        snapGrid={{ x: 1, y: 1 }}
        fitView={fitView}
      >
        <Background color={CANVAS_BACKGROUND_COLOR} size={100} gap={[1, 1]} />

        <S.StyledPanelForProperties
          position="top-right"
          ddpPanelWidth={ddpPanelWidth}
          sx={{
            zIndex: 6,
          }}
        >
          <PropertiesPanel />
        </S.StyledPanelForProperties>

        <S.Panel position="bottom-center" sx={{ marginBottom: '4px' }}>
          <Magnifier />
        </S.Panel>

        <ResizablePanel
          position="top-right"
          fullHeight
          width={ddpPanelWidth}
          setWidth={setDDPPanelWidth}
          minWidth={theme.drimz.size.editor.ddp.minWidth}
          maxWidth={theme.drimz.size.editor.ddp.maxWidth}
          enableResizeFrom={{
            left: true,
          }}
        >
          <DDP />
        </ResizablePanel>

        <ResizablePanel
          position="top-left"
          fullHeight
          width={layersPanelWidth}
          setWidth={setLayerPanelWidth}
          minWidth={theme.drimz.size.editor.layers.minWidth}
          maxWidth={theme.drimz.size.editor.layers.maxWidth}
          enableResizeFrom={{
            right: true,
          }}
        >
          <LayersPanel />
        </ResizablePanel>
      </Canjux>
    </Layout>
  );
};
