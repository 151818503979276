import {
  CommonActionsParams,
  JuxStore,
  JuxStoreActionFn,
} from '@jux/canjux/core';

/**
 * Update a node's dimensions
 */
export const updateNodesDimensions: JuxStoreActionFn<
  CommonActionsParams['updateNodesDimensions'],
  JuxStore
> = ({ payload, state }) => {
  payload.forEach(({ nodeId, dimensions }) => {
    if (!state.canvasNodesDimensions[nodeId]) {
      state.canvasNodesDimensions[nodeId] = dimensions;
    } else {
      state.canvasNodesDimensions[nodeId].height = dimensions.height;
      state.canvasNodesDimensions[nodeId].width = dimensions.width;
    }
  });

  return state;
};
