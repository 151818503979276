import { forwardRef, PropsWithChildren } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';

export const Layout = forwardRef<HTMLDivElement, PropsWithChildren>(
  ({ children }, ref) => (
    <MuiGrid id={'jux__layout'} ref={ref} width="100%" height="100%">
      {children}
    </MuiGrid>
  )
);
