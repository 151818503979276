import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { TextColorField } from '@jux/ui/components/editor/components/panels/DDP/modules/text/sub-modules/textColor/TextColorField';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';

export const TextColor: FC = () => {
  const { shouldRender } = useSubModule(DDPSubModulesKeys.textColor);

  if (!shouldRender) return null;
  return (
    <SubModule title="Text Color">
      <TextColorField />
    </SubModule>
  );
};
