import {
  COMPONENT_TAG_NAME,
  ComponentElementType,
  ComponentProps,
} from '@jux/data-entities';
import { forwardRef } from 'react';

export const Div = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>,
  ComponentProps<typeof COMPONENT_TAG_NAME.JuxDiv>
>(({ rootElement: RootElement = 'div', ...props }, ref) => (
  <RootElement ref={ref} {...props} />
));
