import { PropsWithChildren, forwardRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
} from '@mui/material';
import { bindDialog, PopupStateType } from '@jux/ui/components/common/mui';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { Floors, useOverlaysZIndex } from '@jux/ui/hooks';
import { Backdrop, BackdropProps } from './Backdrop';

type DialogProps = PropsWithChildren<{
  popupState: PopupStateType;
  backDropProps?: BackdropProps;
}> &
  Partial<MuiDialogProps>;

export const Dialog = forwardRef<HTMLDivElement, DialogProps>(
  (
    { children, sx, popupState, hideBackdrop, backDropProps, ...props },
    ref
  ) => {
    const zIndex = useOverlaysZIndex({
      id: 'modal',
      floor: Floors.dialogs,
    });

    return (
      <MuiDialog
        sx={mergeTyped(
          {
            zIndex,
          },
          sx
        )}
        ref={ref}
        {...bindDialog(popupState)}
        hideBackdrop
        {...props}
      >
        {children}
        <Backdrop
          transparent={hideBackdrop}
          open={popupState.isOpen}
          onClick={popupState.close}
          zIndex={-1}
          {...backDropProps}
        />
      </MuiDialog>
    );
  }
);
