/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const DeleteIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'rect',
        attr: {
          x: '3',
          y: '7',
          width: '10',
          height: '2',
          rx: '1',
          fill: 'currentColor',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
