import { NodeProperties } from '@jux/data-entities';
import {
  CommonActionsParams,
  JuxStore,
  JuxStoreActionFn,
} from '../store.interface';

/**
 * Update a node properties.
 */
export const updateNodeProperties: JuxStoreActionFn<
  CommonActionsParams['updateNodeProperties'],
  JuxStore
> = ({ canvasName, nodeId, properties, state }) => {
  // Update each node properties
  Object.entries(properties).forEach(([key, value]) => {
    state.canvases[canvasName].nodes[nodeId].properties[
      key as keyof NodeProperties
    ] = value;
  });

  return state;
};
