import { useCallback, useMemo } from 'react';
import createDebounce from 'lodash/debounce';
import { LayersPanelItem } from '@jux/ui/components/editor/components/panels/layers/state/getLayersItemsRecursive';
import { DropPosition } from '../types';
import { useLayersPanelExpansion } from './useLayersPanelExpansion';
import { useLayersPanelStateValue } from './useLayersPanelStateValue';
import { useReorderLayersPanel } from './useReorderLayersPanel';

const TIMEOUT = 1000;
const debounce = (callback: (...args: Array<any>) => void) =>
  createDebounce(callback, TIMEOUT);

export const useDropZoneIndication = ({
  items,
}: {
  items: Map<string, LayersPanelItem>;
}) => {
  const {
    reorderHoveredItem,
    reorderDraggedItem,
    reorderHoveredItemDropZonePosition: dropZonePosition,
  } = useLayersPanelStateValue();
  const { setReorderHoveredItemDropZonePosition } = useReorderLayersPanel();
  const { expandItem } = useLayersPanelExpansion({ items });

  const debouncedExpandItem = useMemo(() => debounce(expandItem), [expandItem]);

  const handleDragAbove = useCallback(() => {
    if (dropZonePosition === DropPosition.above) return;

    setReorderHoveredItemDropZonePosition(DropPosition.above);
  }, [dropZonePosition, setReorderHoveredItemDropZonePosition]);

  const handleDragBelow = useCallback(() => {
    if (dropZonePosition === DropPosition.below) return;

    setReorderHoveredItemDropZonePosition(DropPosition.below);
  }, [dropZonePosition, setReorderHoveredItemDropZonePosition]);

  const handleDragInside = useCallback(() => {
    if (
      dropZonePosition === DropPosition.inside ||
      !reorderHoveredItem ||
      !reorderHoveredItem.isContainer ||
      reorderHoveredItem.id === reorderDraggedItem?.id
    )
      return;

    setReorderHoveredItemDropZonePosition(DropPosition.inside);

    if (!reorderHoveredItem) return;

    // after 1s expand the item
    if (!reorderHoveredItem.isExpanded) {
      debouncedExpandItem(reorderHoveredItem.path);
    }
  }, [
    dropZonePosition,
    reorderHoveredItem,
    reorderDraggedItem?.id,
    setReorderHoveredItemDropZonePosition,
    debouncedExpandItem,
  ]);

  const handleAbortDragInside = useCallback(() => {
    debouncedExpandItem.cancel();
  }, [debouncedExpandItem]);

  return {
    handleDragAbove,
    handleDragBelow,
    handleDragInside,
    handleAbortDragInside,
  };
};
