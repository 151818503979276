import { CSSProperties } from 'react';
import { CompositeTokens } from '@jux/types';
import { colorWithOpacity as convertColorToSupportDDP } from '@jux/ui/utils/colorWithOpacity';

const backgroundModule = (styles: Partial<CSSProperties>) =>
  ({
    backgroundColor: convertColorToSupportDDP(styles.backgroundColor),
  } as const);

const borderModule = (styles: Partial<CSSProperties>) =>
  ({
    borderColor: convertColorToSupportDDP(styles.borderColor),
    borderRadius: styles.borderRadius,
    borderStyle: styles.borderStyle,
    borderWidth: styles.borderWidth,
  } as const);

const effectsModule = (styles: Partial<CSSProperties>) =>
  ({
    boxShadow: styles.boxShadow,
  } as const);

const layoutModule = (styles: Partial<CSSProperties>) =>
  ({
    // padding
    padding: styles.padding,

    // margin
    margin: styles.margin,

    // display
    display: styles.display,
    flexDirection: styles.flexDirection,
    flexWrap: styles.flexWrap,
    alignItems: styles.alignItems,
    justifyContent: styles.justifyContent,
    gap: styles.gap,
  } as const);

const opacityModule = (styles: Partial<CSSProperties>) =>
  ({
    // opacity
    opacity: styles.opacity,
  } as const);

const sizeModule = (styles: Partial<CSSProperties>) =>
  ({
    // width
    width: styles.width,
    minWidth: styles.minWidth,
    maxWidth: styles.maxWidth,

    // height
    height: styles.height,
    minHeight: styles.minHeight,
    maxHeight: styles.maxHeight,

    // position
    position: styles.position,

    // relative constraints
    top: styles.top,
    right: styles.right,
    bottom: styles.bottom,
    left: styles.left,

    // zIndex
    zIndex: styles.zIndex,
  } as const);

const textModule = (styles: Partial<CSSProperties & CompositeTokens>) =>
  ({
    // typography
    fontFamily: styles.fontFamily,
    fontWeight: styles.fontWeight,
    fontSize: styles.fontSize,
    lineHeight: styles.lineHeight,
    letterSpacing: styles.letterSpacing,

    // color
    color: convertColorToSupportDDP(styles.color),

    // text alignment
    textAlign: styles.textAlign,

    // text direction
    direction: styles.direction,
  } as const);

export const getDDPSupportedStyles = (styles: Partial<CSSProperties> = {}) =>
  ({
    ...backgroundModule(styles),
    ...borderModule(styles),
    ...effectsModule(styles),
    ...layoutModule(styles),
    ...opacityModule(styles),
    ...sizeModule(styles),
    ...textModule(styles),
  } as const);

export type SupportedStyles = ReturnType<typeof getDDPSupportedStyles>;

export const isKeyExistInSupportedStyles = (
  key: string
): key is keyof SupportedStyles =>
  Object.keys(getDDPSupportedStyles()).includes(key);
