import { DesignTokenTypeEnum } from '@jux/design-tokens';
import { ColorTokenForm } from '@jux/ui/components/tokens/token-drawer/forms/colorTokenForm/ColorTokenForm';
import { DimensionTokenForm } from '@jux/ui/components/tokens/token-drawer/forms/DimensionTokenForm';
import { FontFamilyTokenForm } from '@jux/ui/components/tokens/token-drawer/forms/FontFamilyTokenForm';
import { TypographyTokenForm } from '@jux/ui/components/tokens/token-drawer/forms/TypographyTokenForm';
import { BorderTokenForm } from '@jux/ui/components/tokens/token-drawer/forms/BorderTokenForm';

export const tokenFormByType = {
  [DesignTokenTypeEnum.color]: ColorTokenForm,
  [DesignTokenTypeEnum.dimension]: DimensionTokenForm,
  [DesignTokenTypeEnum.fontFamily]: FontFamilyTokenForm,
  [DesignTokenTypeEnum.typography]: TypographyTokenForm,
  [DesignTokenTypeEnum.border]: BorderTokenForm,
} as const;
