import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { EDITOR_DDP_MODULE_BORDER_COLOR } from '@jux/ui/theme/palette';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { useModuleOpened } from '../../hooks';
import { ModuleToggleButton } from '../ModuleToggleButton';
import { ModuleHeader } from './ModuleHeader';
import { ModuleProps } from './Module.interface';

export const Module: FC<ModuleProps> = ({
  moduleKey,
  title,
  children,
  sx,
  hideHeader,
  headerChildren,
  headerSize = 'normal',
}) => {
  const { isOpened, toggleOpen, nonCollapsible, hideTopDivider } =
    useModuleOpened(moduleKey);

  const hasHeaderChildren = Boolean(headerChildren); // = render inline

  const paddingTop = hideHeader ? '16px' : '0';
  const paddingBottom = hideHeader
    ? '8px'
    : hasHeaderChildren
    ? '0'
    : isOpened
    ? '24px'
    : '0';

  const borderTopSize = hideTopDivider ? '0px' : '1px';

  return (
    <MuiGrid
      container
      item
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      paddingInline={'20px'}
      width="100%"
      className={moduleKey}
      sx={mergeTyped(
        {
          borderTop: `${borderTopSize} solid`,
          borderColor: EDITOR_DDP_MODULE_BORDER_COLOR,
        },
        sx
      )}
    >
      {!hideHeader && (
        <ModuleHeader
          title={title}
          headerSize={headerSize}
          className={`module-header${isOpened && '-opened'}`}
        >
          {headerChildren ??
            (!nonCollapsible && (
              <ModuleToggleButton
                icon={isOpened ? 'DDP_EXPANDED_MODULE' : 'DDP_COLLAPSED_MODULE'}
                onClick={toggleOpen}
              />
            ))}
        </ModuleHeader>
      )}

      {isOpened && (
        <MuiGrid container flexDirection="column" gap="8px">
          {children}
        </MuiGrid>
      )}
    </MuiGrid>
  );
};
