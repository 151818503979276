export const generateStylesForSelector = ({
  selector,
  key,
  value,
}: {
  selector: string;
  key: string;
  value: string;
}) => {
  return {
    [selector]: {
      [key]: value,
    },
  };
};
