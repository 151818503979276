import {
  TextValidatorFunction,
  textValidators,
} from '@jux/ui/utils/textValidators';

const validateCharacters: TextValidatorFunction = (value) => ({
  isValid: value.match(/^[-a-zA-Z0-9]*$/) !== null,
  message: 'Property name should not contain special characters or spaces',
});

const validateFirstCharacterIsALetter: TextValidatorFunction = (value) => ({
  // custom validator to make sure the name start with a letter
  isValid: value.match(/^[a-zA-Z]/) !== null,
  message: 'Property name should start with a letter',
});

const validateMaxLength: (maxLength: number) => TextValidatorFunction =
  (maxLength) => (value) => ({
    isValid: value.length <= maxLength,
    message: `Property name should be less than ${maxLength} characters`,
  });

export const propertyNameValidators = ({
  maxLength,
  propertiesNames,
}: {
  maxLength: number;
  propertiesNames: Array<string>;
}): Array<TextValidatorFunction> => [
  validateCharacters,
  validateFirstCharacterIsALetter,
  validateMaxLength(maxLength),
  textValidators.nameAlreadyExistsValidator({
    namesArray: propertiesNames,
    errorMessages: {
      nameAlreadyExists: 'Property name already exists',
      namesArrayUndefined: 'Properties names are undefined',
    },
  }),
];
