import { SyntheticEvent, useCallback } from 'react';
import { z } from 'zod';
import { useDDPFieldForm } from '@jux/ui/components/editor/components/panels/DDP/hooks';
import {
  useEffectOnSelectedNodeChange,
  useSelectedNodeComponent,
  useSaveNodeTextContent,
  useSelectedNodeId,
} from '@jux/ui/components/editor/hooks';
import {
  DDPOtherFieldsKeys,
  useDDPFieldsState,
} from '@jux/ui/components/editor/state';
import { TEXT_CONTENT_PROP_NAME } from '@jux/types';

const FIELD_KEY = DDPOtherFieldsKeys.textContent;

export const useTextContent = () => {
  const { saveTextContent } = useSaveNodeTextContent();
  const { setFieldsStateByKey } = useDDPFieldsState();

  const selectedNodeId = useSelectedNodeId();
  const { component: selectedNodeComponent } = useSelectedNodeComponent();

  const initialValue =
    selectedNodeComponent?.config.props[TEXT_CONTENT_PROP_NAME] || '';

  const { handleSubmit, registerField, setValue, getValue } = useDDPFieldForm({
    fieldName: FIELD_KEY,
    fieldSchema: z.string().optional(),
    initialValue,
  });

  const setFieldValue = useCallback(
    (newValue: string) => {
      setFieldsStateByKey(FIELD_KEY, {
        value: newValue,
        initialValue: newValue,
      });
      setValue(newValue);
    },
    [setValue, setFieldsStateByKey]
  );

  const handleNodeChangeEffect = useCallback(() => {
    if (!selectedNodeId || !initialValue) {
      return;
    }

    const selectedNodeText = initialValue;

    setFieldValue(selectedNodeText);
  }, [initialValue, selectedNodeId, setFieldValue]);

  useEffectOnSelectedNodeChange(handleNodeChangeEffect);

  const discard = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      if (!selectedNodeId) {
        return;
      }

      const currentValue = getValue();

      if (currentValue !== initialValue) {
        setValue(initialValue);
      }

      event.currentTarget?.blur();
    },
    [getValue, initialValue, selectedNodeId, setValue]
  );

  const save = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      if (!selectedNodeId) {
        return;
      }

      const newText = getValue();

      const { currentTarget } = event;

      const submit = handleSubmit(
        () => {
          saveTextContent({ nodeId: selectedNodeId, newText });
          setFieldValue(newText);
          currentTarget.blur();
        },
        () => {
          discard(event);
        }
      );

      return submit(event);
    },
    [
      discard,
      getValue,
      handleSubmit,
      selectedNodeId,
      saveTextContent,
      setFieldValue,
    ]
  );

  return {
    id: FIELD_KEY,
    save,
    discard,
    registerField,
  };
};
