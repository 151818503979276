import { ThemeOptions } from '@jux/ui/components/common/mui';
import { darkDrimzPalette } from '../base';
import { componentsColors } from '../base/base-theme-components-colors';
import { baseThemeSize } from '../base/base-theme-size';
import { baseThemeShadow } from '../base/base-theme-shadow';
import { transitions } from '../base/base-theme-transitions';
import { darkColors } from './dark-theme-colors';

export const darkThemeOptions: ThemeOptions = {
  components: {
    ...componentsColors(darkDrimzPalette),
  },
  drimz: {
    size: baseThemeSize,
    shadow: baseThemeShadow(darkColors),
    palette: darkDrimzPalette,
    transitions,
  },
  palette: {
    mode: 'dark',
    // must keep both primary & text in order to keep theme color affect working properly
    primary: darkDrimzPalette.primary,
    text: darkDrimzPalette.text,
  },
};
