import * as uuid from 'uuid';
import {
  NodeType,
  ComponentPropType,
  COMPONENT_TAG_NAME,
  JuxComponentData,
} from '@jux/data-entities';
import { ElementDataInit } from '../../types/ElementDataInit';
import { getTextLabelElement } from '../common/textLabel';
import { getHelperTextElement } from '../common/helperText';
import { getTextareaFieldTriggerElementData } from './textareaFieldTrigger';
import { Colors } from '../../common/colors';

export const TEXTAREA_FIELD_ELEMENT_DEFAULT_DISPLAY_NAME = 'Textarea field';

export const getJuxTextareaFieldChildren = ({
  rootTextareaFieldId,
}: {
  rootTextareaFieldId: string;
}) => {
  const labelElement = getTextLabelElement({
    parentId: rootTextareaFieldId,
    contextParentId: rootTextareaFieldId,
  }).root;

  const {
    root: textareaFieldTriggerElement,
    subComponents: textareaFieldTriggerSubComponents,
  } = getTextareaFieldTriggerElementData({
    parentId: rootTextareaFieldId,
    contextParentId: rootTextareaFieldId,
  });

  const helperTextElement = getHelperTextElement({
    parentId: rootTextareaFieldId,
    contextParentId: rootTextareaFieldId,
  }).root;

  return {
    labelElement,
    textareaFieldTriggerElement,
    helperTextElement,
    subComponents: (
      [
        labelElement,
        textareaFieldTriggerElement,
        helperTextElement,
      ] as Array<JuxComponentData>
    ).concat(textareaFieldTriggerSubComponents || []),
  };
};

const DEFAULT_STYLES = {
  root: {},
  states: {
    hover: { root: {} },
    'focus-visible': { root: {} },
  },
};

export const getJuxTextareaFieldElementData: ElementDataInit<undefined> = ({
  parentId,
} = {}) => {
  const rootTextareaFieldId = uuid.v4();

  const {
    labelElement,
    textareaFieldTriggerElement,
    helperTextElement,
    subComponents,
  } = getJuxTextareaFieldChildren({ rootTextareaFieldId });

  const textAreaWrapper = textareaFieldTriggerElement.children[0];

  return {
    root: {
      tagName: COMPONENT_TAG_NAME.JuxTextareaField,
      type: NodeType.LOCAL_COMPONENT,
      id: rootTextareaFieldId,
      parentId,
      scopes: [],
      children: [
        labelElement.id,
        textareaFieldTriggerElement.id,
        helperTextElement.id,
      ],
      styles: {
        ...DEFAULT_STYLES,
        contextStyles: [
          {
            contextChildUuid: textAreaWrapper,
            condition: {
              propsValues: { disabled: true },
            },
            styles: {
              borderColor: Colors.Neutrals_200,
            },
          },
          {
            contextChildUuid: textAreaWrapper,
            condition: {
              state: 'focus-within',
              propsValues: { disabled: false },
            },
            styles: {
              borderColor: Colors.Brand_500,
            },
          },
          {
            contextChildUuid: textAreaWrapper,
            condition: {
              state: 'hover',
              propsValues: { disabled: false },
            },
            styles: {
              borderColor: Colors.Neutrals_600,
            },
          },
        ],
      },
      config: {
        props: {
          disabled: false,
          placeholder: 'Placeholder',
          value: '',
        },
        variants: [
          {
            variant: 'disabled',
            propType: ComponentPropType.Enum,
            options: [
              { value: true, label: 'True' },
              { value: false, label: 'False' },
            ],
            defaultValue: false,
            isFixed: true,
          },
        ],
      },
      displayName: TEXTAREA_FIELD_ELEMENT_DEFAULT_DISPLAY_NAME,
    },
    subComponents,
  };
};
