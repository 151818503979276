import { CanjuxState, Storage } from '@jux/canjux/core';
import DrimzAPI from '@jux/ui/lib/api';
import { createClient } from '@liveblocks/client';
import { createRoomContext } from '@liveblocks/react';
import { StoreApi, UseBoundStore } from 'zustand';
import { createStore } from '../store';

export const liveblocksClient = createClient({
  authEndpoint: async (roomId: string) => {
    const result = await DrimzAPI.post('/api/auth/liveblocks', {
      roomId,
    });

    // Return token
    return result.data;
  },
  throttle: 16, // 60 FPS
  lostConnectionTimeout: 3000, // 3 seconds
});

export type Presence = {
  cursor: { x: number; y: number } | null;
  selectedNodeIds: string[];
};

export type UserInfo = {
  name: string;
};

export type UserMeta = {
  id: string;
  info: UserInfo;
};

export const storeApi = createStore(liveblocksClient);

export const signalStore = storeApi as UseBoundStore<StoreApi<CanjuxState>>;

export const {
  RoomProvider,
  useMyPresence,
  useRoom,
  suspense: { useStorage, useMap, useSelf },
  /* ...all the other hooks you’re using... */
} = createRoomContext<
  Presence,
  Storage,
  UserMeta
  /* RoomEvent, ThreadMetadata */
>(liveblocksClient);
