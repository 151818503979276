import { FC } from 'react';
import { useStore } from '@jux/canjux/core';
import { Loading, Modal, Popper, Sidebar } from '@jux/ui/components';
import {
  useEnterRoomEffect,
  useGetCanvasData,
} from '@jux/ui/components/editor/hooks';
import { Layout } from '../layouts/Layout';
import { NavigationToken } from './NavigationToken';
import { TokenDrawer } from './token-drawer/base';
import { TokenSetsLayout } from './TokenSetsLayout';

export const TokensLayout: FC = () => {
  const { canvas } = useGetCanvasData();

  const { isLoading } = useStore((s) => ({
    isLoading: s.liveblocks.isStorageLoading,
  }));

  useEnterRoomEffect(canvas.roomId);

  return (
    <>
      <Loading visible={isLoading} />
      <TokenDrawer hideBackdrop />
      <Modal />
      <Popper />
      <Layout
        sidebar={<Sidebar />}
        navbar={<NavigationToken />}
        content={<TokenSetsLayout />}
      />
    </>
  );
};
