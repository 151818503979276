import { FC, useRef } from 'react';
import { BaseFieldLayout } from '../common';
import { SelectFieldOptionRenderer } from '../select';
import { TokenFieldContent } from '../tokenFieldContent';
import { TypographyFieldProps } from './TypographyField.interface';

export const DDP_TYPOGRAPHY_FIELD_NAME_PREFIX = 'ddp-typography-field-';
export const DDP_TYPOGRAPHY_FIELD_CLASS_NAME = 'ddp-typography-field';

export const TypographyField: FC<TypographyFieldProps> = ({
  icon,
  tooltipTitle,
  parsedValue,
  isComputed,
  tokensProps,
}) => {
  const fieldRef = useRef<HTMLDivElement>(null);

  return (
    <BaseFieldLayout
      icon={icon}
      tooltipTitle={tooltipTitle}
      className={DDP_TYPOGRAPHY_FIELD_CLASS_NAME}
      htmlFor={DDP_TYPOGRAPHY_FIELD_NAME_PREFIX}
      tokensProps={tokensProps}
      isComputed={isComputed}
      renderTokenOption={SelectFieldOptionRenderer}
      fieldRef={fieldRef}
    >
      <TokenFieldContent
        tokenName={tokensProps?.tokenDisplayName ?? parsedValue}
        onClick={tokensProps?.tokensPopperProps?.openTokens}
        isComputed={isComputed}
      />
    </BaseFieldLayout>
  );
};
