import { AssetData } from '@jux/data-entities';

const JUX_ASSET_CHECKED_ICON_SVG = `
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9685 4.91438C12.292 5.17313 12.3444 5.6451 12.0857 5.96855L8.08565 10.9685C7.95238 11.1351 7.75448 11.2371 7.54148 11.2489C7.32847 11.2607 7.12052 11.1812 6.96967 11.0304L4.46967 8.53036C4.17678 8.23746 4.17678 7.76259 4.46967 7.4697C4.76256 7.1768 5.23744 7.1768 5.53033 7.4697L7.43781 9.37718L10.9143 5.03151C11.1731 4.70806 11.6451 4.65562 11.9685 4.91438Z" fill="currentColor"/>
</svg>
`;

export const JUX_ASSET_CHECKED_ICON_ID = 'jux-asset-checked-icon' as const;

export const JUX_ASSET_CHECKED_ICON: AssetData = {
  name: 'CheckedIcon',
  content: JUX_ASSET_CHECKED_ICON_SVG,
  isSystemAsset: true,
};
