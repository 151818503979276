/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const SeparatedSidesSubModuleDDPIcon: IconType = (
  props: IconBaseProps
) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M3.75 5C3.33579 5 3 5.33579 3 5.75V10.25C3 10.6642 3.33579 11 3.75 11V11C4.16421 11 4.5 10.6642 4.5 10.25V5.75C4.5 5.33579 4.16421 5 3.75 5V5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M5 3.75C5 4.16421 5.33579 4.5 5.75 4.5H10.25C10.6642 4.5 11 4.16421 11 3.75V3.75C11 3.33579 10.6642 3 10.25 3H5.75C5.33579 3 5 3.33579 5 3.75V3.75Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M13 5.75C13 5.33579 12.6642 5 12.25 5V5C11.8358 5 11.5 5.33579 11.5 5.75V10.25C11.5 10.6642 11.8358 11 12.25 11V11C12.6642 11 13 10.6642 13 10.25V5.75Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M10.25 13C10.6642 13 11 12.6642 11 12.25V12.25C11 11.8358 10.6642 11.5 10.25 11.5H5.75C5.33579 11.5 5 11.8358 5 12.25V12.25C5 12.6642 5.33579 13 5.75 13H10.25Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
