import { createSelector } from 'reselect';
import { CanjuxState } from '@jux/canjux/core';
import { getSelectedNodeInstances } from './utils';
import { EnumPropValue } from '@jux/types';

export const getSelectedNodeComponentInstancesUsingPropValueCount =
  createSelector(
    [
      (state: CanjuxState) => state.components,
      (state: CanjuxState) => state.selectedNodesStack,
    ],
    (components, selectedNodesStack) =>
      ({
        propName,
        propValue,
      }: {
        propName: string;
        propValue: EnumPropValue;
      }) => {
        // find all components instances of the selected node
        const instancesOfSelectedNode = getSelectedNodeInstances({
          components,
          selectedNodesStack,
        });

        // find all components instances using the provided prop-value
        const instancesOfSelectedNodeUsingPropValue =
          instancesOfSelectedNode?.filter(
            (instance) => instance.config.props[propName] === propValue
          );

        return instancesOfSelectedNodeUsingPropValue.length;
      }
  );
