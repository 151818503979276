import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import {
  DDPSubModulesStateKeys,
  DDPSubModulesState,
} from './useDDPSubModulesState.interface';

const subModulesStateAtom = atom<DDPSubModulesState>(
  {} as unknown as DDPSubModulesState
);

export const useDDPSubModulesState = () => {
  const [subModulesState, setSubModulesState] = useAtom(subModulesStateAtom);

  const getSubModulesStateByKey = useCallback(
    <Key extends DDPSubModulesStateKeys>(key: Key) => subModulesState[key],
    [subModulesState]
  );

  const setSubModulesStateByKey = useCallback(
    (
      key: DDPSubModulesStateKeys,
      state: Partial<DDPSubModulesState[typeof key]>
    ) => {
      setSubModulesState((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          ...state,
        },
      }));
    },
    [setSubModulesState]
  );

  const shouldRenderSubModule = useCallback(
    (key: DDPSubModulesStateKeys) => {
      const shouldRender = getSubModulesStateByKey(key)?.shouldRender;
      if (typeof shouldRender === 'function') {
        return shouldRender();
      }
      return shouldRender;
    },
    [getSubModulesStateByKey]
  );

  return {
    subModulesState,
    getSubModulesStateByKey,
    setSubModulesState,
    setSubModulesStateByKey,
    shouldRenderSubModule,
  };
};
