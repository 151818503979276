import { FC, PropsWithChildren } from 'react';
import { ThemeProvider } from '@jux/dui';
import { useCurrentOrgTheme } from '@jux/ui/components/editor/hooks';

/**
 * Providing access to the theme across the entire layout.
 * Some components on navigation as well as the body of the
 * page render JUX components that need access to the theme.
 */
export const CurrentOrgThemeProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const theme = useCurrentOrgTheme();

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
