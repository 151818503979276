import { JuxStore } from '@jux/canjux/core';

export function isChildOfNode(
  state: JuxStore,
  targetNodeId: string,
  parentId: string
): boolean {
  const targetNodeComponentData = state.components[targetNodeId];
  if (!targetNodeComponentData.parentId) {
    return false;
  }

  if (targetNodeComponentData.parentId === parentId) {
    return true;
  }

  return isChildOfNode(state, targetNodeComponentData.parentId, parentId);
}
