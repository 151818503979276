const INDENT_STEP = 20;
const BASE_PADDING = 4;

export const calcIndent = ({
  depth,
  indentStep = INDENT_STEP,
  basePadding = BASE_PADDING,
}: {
  depth: number;
  indentStep?: number;
  basePadding?: number;
}) => depth * indentStep + basePadding;
