import * as CSS from 'csstype';
import { CSSProperties } from 'react';

export type EnumPropValue = string | boolean;

export type VariantsValues = {
  [variant: string]: EnumPropValue;
};

/**
 Component states
 */
export const DEFAULT_STATE = 'default';
export type DefaultState = typeof DEFAULT_STATE;
export type ElementState =
  | 'hover'
  | 'active'
  | 'focus'
  | 'focus-visible'
  | 'focus-within';
export type CheckboxState = 'disabled' | 'checked' | 'unchecked'; // TODO: rename this to a name that fits both Checkbox, Radio and Toggle
export type CssState = ElementState | CheckboxState;

export type StyleStates =
  | '&:hover'
  | '&:active'
  | '&:focus'
  | '&:focus-visible'
  | '&:focus-within'
  | '&:disabled'
  | '&:checked';

export type CSSStyles = {
  [Property in keyof CSSProperties]?:
    | CSSProperties[Property]
    | string
    | CSSStyles;
};

export type CSSPseudosStyles = {
  [Pseudo in CSS.Pseudos | `&${CSS.Pseudos}`]?: CSSStyles;
};

export type CompositeTokens = {
  typography?: string;
};

export type CustomSelectorsStyles = {
  [selector in string]?: StylesObject | string;
};

export type StylesObject =
  | (CSSStyles & CSSPseudosStyles & CompositeTokens)
  | CustomSelectorsStyles;

export type StyleStatesObject = {
  [state in StyleStates]?: StylesObject;
};

// Record of all the props and values + styles object
export type StyleVariant = {
  propsValues: VariantsValues;
  styles: StylesObject | StyleStatesObject;
};

export type StyleVariantsList = Array<StyleVariant>;

export type ContextStyle = {
  contextChildUuid: string;
  styles: StylesObject;
  condition?: Partial<{
    state: ElementState | DefaultState;
    propsValues: VariantsValues;
  }>;
};

export type ContextStylesList = Array<ContextStyle>;

export type ComponentConfig = {
  root: StylesObject;
  variants?: StyleVariantsList;
  contextStyles?: ContextStylesList;
};

export type ComponentStatesStyles = {
  [state: string]: ComponentConfig;
};

export type ComponentConfigWithStates = ComponentConfig & {
  states: ComponentStatesStyles;
};

export interface ColorsPalette {
  [colorName: string]: CSS.Properties['color'] | ColorsPalette;
}

export interface Dimensions {
  [dimensionName: string]: CSS.Properties['width'] | Dimensions;
}

export interface FontFamilies {
  [fontFamilyName: string]: CSS.Properties['fontFamily'] | FontFamilies;
}

export interface Typographies {
  [typographyName: string]: CSS.Properties[keyof CSS.Properties] | Typographies;
}

export interface Borders {
  [borderName: string]: CSS.Properties[keyof CSS.Properties] | Borders;
}

export type ThemeConfigTokens = {
  border: Borders;
  color: ColorsPalette;
  dimension: Dimensions;
  fontFamily: FontFamilies;
  typography: Typographies;
};

export interface ThemeConfig {
  tokenSet: ThemeConfigTokens & {
    core: ThemeConfigTokens;
  };
}

// TODO: remove redundant union types
export type StylesState = CssState | DefaultState | '' | undefined;
