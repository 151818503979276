import { FC } from 'react';
import { MenuList, Tooltip, Typography } from '@jux/ui/components';
import { MuiBox, MuiMenuItem } from '@jux/ui/components/common/mui';

export const TokenCardActionsMenu: FC<{
  onDeleteToken: () => void;
  hasAlias: boolean;
  isTokenCardHovered?: boolean;
}> = ({ hasAlias, isTokenCardHovered, onDeleteToken }) => (
  <MenuList
    isVisible={isTokenCardHovered}
    optionsButtonProps={{
      variant: 'icon',
      disableTooltip: true,
    }}
    paperProps={{
      sx: {
        marginTop: '-1px',
      },
    }}
    popperPlacement="bottom-end"
    stopClickPropagation
  >
    <Tooltip content={hasAlias ? 'This token has one or more aliases' : ''}>
      <MuiBox>
        <MuiMenuItem onClick={onDeleteToken} disabled={hasAlias} disableRipple>
          <Typography variant="body-regular">Delete</Typography>
        </MuiMenuItem>
      </MuiBox>
    </Tooltip>
  </MenuList>
);
