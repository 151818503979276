import {
  JuxStore,
  JuxStoreActionFn,
  PropsActionsParams,
} from '../../store.interface';
import { ComponentPropType, NodeType } from '@jux/data-entities';
import { addNewPropToAllIntstances } from '../utils/addNewPropToAllIntstances';

/**
 * Create a new property with a first value for the component
 */
export const createComponentProp: JuxStoreActionFn<
  PropsActionsParams['createComponentProp'],
  JuxStore
> = ({ componentId, propName, propValue, state }) => {
  if (!componentId) return state;

  const component = state.components[componentId];
  if (!component || !component.config.variants) return state;

  switch (component.type) {
    case NodeType.ELEMENT:
    case NodeType.LOCAL_COMPONENT:
    case NodeType.LIBRARY_COMPONENT:
      component.config.variants.push({
        propType: ComponentPropType.Enum,
        variant: propName,
        options: [
          {
            value: propValue,
            label: propValue.toString(),
          },
        ],
        defaultValue: propValue,
        displayName: propName,
      });

      // Adding the new prop, doing it this way because of immer
      component.config.props = {
        ...component.config.props,
        [propName]: propValue,
      };

      addNewPropToAllIntstances({
        componentId,
        propName,
        propValue,
        components: state.components,
      });

      component.updatedAt = new Date().getTime();

      break;
    case NodeType.INSTANCE:
      throw new Error('Cannot add prop for component instance');
  }

  return state;
};
