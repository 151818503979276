import { VariantsPropsMapping } from './Typography.interface';

export const typographyVariants: VariantsPropsMapping = {
  h1: {
    fontSize: 28,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: 0,
  },
  h2: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: 0,
  },
  'body-regular': {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: 0,
  },
  'body-semi': {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: 0.1,
  },
  'body-bold': {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: 0,
  },
  'label-s-regular': {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: 0,
  },
  'label-s-medium': {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: 0,
  },
  'label-s-bold': {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: 0,
  },
  'label-xs-medium': {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: '14px',
    letterSpacing: 0.1,
  },
  'label-xs-regular': {
    fontSize: 11,
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: 0.1,
  },

  // Rogue variants
  isComingSoon: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1,
    letterSpacing: 0,
  },
  unitOptionTypography: {
    fontSize: 10,
    fontWeight: 600,
    lineHeight: 1,
    letterSpacing: 0,
  },
  autoCompleteColorOptionTypography: {
    fontSize: 11,
    fontWeight: '500',
    lineHeight: '16px',
    letterSpacing: 0,
  },
  elementDisplayNameTypography: {
    fontSize: 9,
    letterSpacing: 1,
    fontWeight: 400,
    lineHeight: 'normal',
  },
  kickerActionButtonTypography: {
    fontSize: 9,
    letterSpacing: 1,
    fontWeight: 400,
    lineHeight: 'normal',
  },
  componentDisplayNameTypography: {
    fontSize: 11,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: 0,
  },
  editorSidebarsSectionTypography: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: 0,
  },
  userNameAvatarTypography: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: 0,
  },
  dialogPopoverCardTypography: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: 0,
  },
  liveOrEditButtonTypography: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: 0.12,
  },
  filesImportButtonTypography: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 0.2,
  },
  createActionButtonTypography: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.3,
    letterSpacing: 0,
  },
  unitDimensionFieldTokenTypography: {
    fontWeight: 600,
    fontSize: 10,
    lineHeight: 1,
    letterSpacing: 0,
  },
  useGroupedOptionsTypography: {
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: 0,
  },
};
