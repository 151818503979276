/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const ConstraintsAllDDPIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M5.75 8C5.75 8.41421 5.41421 8.75 5 8.75H3.5V12.5H7.25V11C7.25 10.5858 7.58579 10.25 8 10.25C8.41421 10.25 8.75 10.5858 8.75 11V12.5H12.5V8.75H11C10.5858 8.75 10.25 8.41421 10.25 8C10.25 7.58579 10.5858 7.25 11 7.25H12.5V3.5H8.75V5C8.75 5.41421 8.41421 5.75 8 5.75C7.58579 5.75 7.25 5.41421 7.25 5V3.5H3.5V7.25H5C5.41421 7.25 5.75 7.58579 5.75 8ZM3 2C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V3C14 2.44772 13.5523 2 13 2H3Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
