/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';

const { styled } = createDrimz();

type Import_button_button_label_root_Props = PropsOf<
  typeof JuxReactElements.JuxText
>;

const Import_button_button_label_root: FC<Import_button_button_label_root_Props> =
  forwardRef((props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />);

Import_button_button_label_root.displayName = 'Import_button_button_label_root';

Import_button_button_label_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        display: 'inline-block',
        fontSize: '14px',
        fontStyle: 'normal',
        fontFamily: 'Inter',
        fontWeight: '500',
        lineHeight: '20px',
      },
    },
  }) as Import_button_button_label_root_Props['rootElement'],
};

export type Import_button_button_label_Props =
  Import_button_button_label_root_Props;

export const Import_button_button_label: FC<Import_button_button_label_Props> =
  forwardRef<
    ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>,
    Import_button_button_label_Props
  >((props, ref) => <Import_button_button_label_root {...props} ref={ref} />);

Import_button_button_label.displayName = 'Import_button_button_label';
Import_button_button_label.defaultProps = { text: 'Share feedback' };

type Import_button_root_Props = PropsOf<typeof JuxReactElements.JuxButton> & {
  disabled: true | false;
  hierarchy: 'primary' | 'secondary';
  Size: 'big';
};

const Import_button_root: FC<Import_button_root_Props> = forwardRef(
  (props, ref) => <JuxReactElements.JuxButton {...props} ref={ref} />
);

Import_button_root.displayName = 'Import_button_root';

Import_button_root.defaultProps = {
  rootElement: styled('button', {
    styles: {
      root: {
        gap: '8px',
        color: '#FFFFFF',
        width: 'auto',
        cursor: 'pointer',
        height: '36px',
        display: 'flex',
        padding: '8px 16px',
        fontSize: '14px',
        fontStyle: 'normal',
        alignItems: 'center',
        fontFamily: 'Inter',
        fontWeight: '500',
        lineHeight: '20px',
        borderColor: '',
        borderStyle: 'none',
        borderRadius: '4px',
        flexDirection: 'row',
        justifyContent: 'center',
        '&:focus': { outline: 'none' },
        '&:active': { outline: 'none' },
        '&:focus-visible': {
          borderRadius: '8px',
          outlineColor: '#3851DD',
          outlineStyle: 'solid',
          outlineWidth: '2px',
          outlineOffset: '2px',
        },
      },
      variants: [
        {
          propsValues: { disabled: false, hierarchy: 'primary', Size: 'big' },
          styles: {
            height: '36px',
            padding: '10px 10px 10px 10px',
            borderRadius: '2px',
            backgroundColor: '{color.core.grays_800}',
            '[data-jux-context="f4041409-30bc-4a84-9b96-da5515927c0b"]': {
              fontSize: '{typography.core.12_regular.fontSize}',
              fontFamily: '{typography.core.12_regular.fontFamily}',
              fontWeight: '{typography.core.12_regular.fontWeight}',
              lineHeight: '{typography.core.12_regular.lineHeight}',
              letterSpacing: '{typography.core.12_regular.letterSpacing}',
            },
            '&:hover': { backgroundColor: '{color.core.grays_900}' },
            '&:active': { backgroundColor: '{color.core.grays_800}' },
          },
        },
        {
          styles: {
            backgroundColor: '#9C9C9C',
            '&:hover': { backgroundColor: '#6A6A6A' },
            '&:active': { backgroundColor: '#3B3B3B' },
          },
          propsValues: { disabled: false, hierarchy: 'secondary', Size: 'big' },
        },
        {
          propsValues: { disabled: true, hierarchy: 'primary', Size: 'big' },
          styles: { cursor: 'default', backgroundColor: '#98A6F9' },
        },
        {
          propsValues: { disabled: true, hierarchy: 'secondary', Size: 'big' },
          styles: { cursor: 'default', backgroundColor: '#C8C8C8' },
        },
      ],
    },
  }) as Import_button_root_Props['rootElement'],
};

export type Import_button_Props = Import_button_root_Props & {
  Import_button_button_label_Props?: Import_button_button_label_Props;
};

export const Import_button: FC<Import_button_Props> = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxButton>,
  Import_button_Props
>(({ Import_button_button_label_Props, ...props }, ref) => (
  <Import_button_root
    {...props}
    ref={ref}
    data-jux-context="e56cf046-27a8-4b36-b756-d469815da17e"
  >
    {props.children || (
      <>
        <Import_button_button_label
          {...Import_button_button_label_Props}
          data-jux-context="f4041409-30bc-4a84-9b96-da5515927c0b"
        />
      </>
    )}
  </Import_button_root>
));

Import_button.displayName = 'Import_button';
Import_button.defaultProps = {
  disabled: false,
  hierarchy: 'primary',
  Size: 'big',
};
