import { FC, useMemo } from 'react';
import {
  DDPPrimitiveFieldsKeys,
  DDPSubModulesKeys,
} from '@jux/ui/components/editor/state';
import { toFirstUpperCase } from '@jux/ui/utils/toFirstUpperCase';
import { SubModule } from '../../../../base';
import { useFieldValues, useSubModule } from '../../../../hooks';
import { parsePosition } from '../position/PositionField.utils';
import { TopField } from './TopField';
import { RightField } from './RightField';
import { BottomField } from './BottomField';
import { LeftField } from './LeftField';
import { AllConstraintsField } from './AllConstraintsField';

export const Constraints: FC = () => {
  const { shouldRender, isOpen, setIsOpen } = useSubModule(
    DDPSubModulesKeys.constraints
  );
  const { value, initialValue } = useFieldValues(
    DDPPrimitiveFieldsKeys.position
  );
  const position = useMemo(
    () => parsePosition(value || initialValue),
    [initialValue, value]
  );

  if (!shouldRender) return null;
  return (
    <SubModule
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={toFirstUpperCase(`${position} constraints`)}
    >
      {isOpen ? (
        <>
          <TopField />
          <RightField />
          <BottomField />
          <LeftField />
        </>
      ) : (
        <AllConstraintsField />
      )}
    </SubModule>
  );
};
