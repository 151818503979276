import {
  ComponentType,
  MouseEvent,
  forwardRef,
  useCallback,
  useState,
} from 'react';
import {
  COMPONENT_TAG_NAME,
  ComponentElementType,
  ComponentProps,
} from '@jux/data-entities';

type ChipComponentType = {
  Component: ComponentType<ComponentProps<typeof COMPONENT_TAG_NAME.JuxChip>>;
};

type ChipLiveModeProps = ChipComponentType &
  ComponentProps<typeof COMPONENT_TAG_NAME.JuxChip>;

export const ChipLiveMode = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxChip>,
  ChipLiveModeProps
>(({ children, Component, onClick, selected, ...props }, ref) => {
  const [isSelected, setIsSelected] = useState(selected);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onClick?.(e);

      setIsSelected(!isSelected);
    },
    [isSelected, onClick]
  );

  return (
    <Component {...props} ref={ref} onClick={handleClick} selected={isSelected}>
      {children}
    </Component>
  );
});
