'use client';

import dynamic from 'next/dynamic';

import Head from 'next/head';
import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { TEST_ID_MAIN_LOADING } from '@jux/constants';
import { Floors, useOverlaysZIndex } from '@jux/ui/hooks';
import { FocusElementForTrap, FocusTrap } from '../common/focusTrap';
import * as animationData from './both-sides.json';

const LottieWithNoSSR = dynamic(
  () => import('lottie-react').then((module) => module.default),
  { ssr: false }
);

export const Loading: FC<{
  minHeight?: string | number;
  visible?: boolean;
}> = ({ minHeight = '100vh', visible = true }) => {
  const loadingZIndex = useOverlaysZIndex({
    id: 'loading',
    floor: Floors.loading,
  });

  if (!visible) {
    return null;
  }

  return (
    <>
      <Head>
        <title>Loading... - JUX</title>
      </Head>
      <FocusTrap>
        <MuiGrid
          container
          spacing={0}
          minHeight={minHeight}
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: (theme) => theme.drimz.palette.background.default,
            zIndex: loadingZIndex,
          }}
          data-testid={TEST_ID_MAIN_LOADING}
        >
          <FocusElementForTrap tabIndex={1} />

          <LottieWithNoSSR animationData={animationData} height={24} />
        </MuiGrid>
      </FocusTrap>
    </>
  );
};
