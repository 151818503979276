import * as uuid from 'uuid';
import {
  ComponentConfigWithStates as StylesConfig,
  ContextStylesList,
  StylesObject,
} from '@jux/types';
import { getJuxTextElementData } from './text';
import { getJuxDivElementData } from './div';
import {
  COMPONENT_TAG_NAME,
  ComponentPropType,
  VariantsConfig,
  NodeType,
} from '@jux/data-entities';
import { Colors } from '../common/colors';
import { ElementDataInit } from '../types/ElementDataInit';

const JUX_TOGGLE_LABEL_DEFAULT_CONTENT = 'label';
const JUX_TOGGLE_HELPER_TEXT_DEFAULT_CONTENT = 'Helper text';
const TRANSITION_TIMING = '0.3s cubic-bezier(0.72, 0.06, 0.32, 0.95)';

export const TOGGLE_ELEMENT_DEFAULT_DISPLAY_NAME = 'toggle';

const TOGGLE_ELEMENT_VARIANTS_CONFIG: VariantsConfig = [
  {
    variant: 'disabled',
    propType: ComponentPropType.Enum,
    options: [
      { value: true, label: 'True' },
      { value: false, label: 'False' },
    ],
    isFixed: true,
    defaultValue: false,
  },
  {
    variant: 'checked',
    propType: ComponentPropType.Enum,
    options: [
      { value: true, label: 'Checked' },
      { value: false, label: 'Unchecked' },
      { value: 'indeterminate', label: 'Indeterminate' },
    ],
    isFixed: true,
    defaultValue: true,
  },
];

export const TOGGLE_ELEMENT_DEFAULT_STYLES: StylesConfig = {
  root: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '0 10px',
    borderStyle: 'none',
    borderColor: '', // this is set so that DDP would not show browser default #767676 and show empty value instead
    outline: 'none',
    background: 'none',
    color: 'white',
    width: 'auto',
    height: 'auto',
    // Input styles (included by the base element itself, not in the element data)
    '& > input[type=checkbox]': {
      transform: 'translateX(-100%)',
      position: 'absolute',
      pointerEvents: 'none',
      opacity: 0,
    },
  },
  variants: [
    {
      propsValues: {
        disabled: true,
      },
      styles: {
        cursor: 'default',
      },
    },
  ],
  states: {
    // TODO: make states work in editor without having to include such empty objects
    hover: {
      root: {},
      variants: [],
    },
    // TODO: make states work in editor without having to include such empty objects
    active: {
      root: {},
      variants: [],
    },
    'focus-visible': {
      root: {
        '& > input[type=checkbox] + div': {
          outlineColor: Colors.Brand_500,
          outlineWidth: '2px',
          outlineStyle: 'solid',
          outlineOffset: '2px',
        },
      },
    },
  },
};

const JUX_TOGGLE_LABEL_DEFAULT_STYLES: StylesObject = {
  fontFamily: 'Inter',
  fontWeight: '500',
  fontStyle: 'normal',
  fontSize: '14px',
  lineHeight: '17px',
  display: 'inline-block',
  color: Colors.Neutrals_500,
  width: 'auto',
  height: 'auto',
};

const JUX_TOGGLE_HELPER_TEXT_DEFAULT_STYLES: StylesObject = {
  fontFamily: 'Inter',
  fontWeight: '400',
  fontStyle: 'normal',
  fontSize: '12px',
  lineHeight: '14px',
  display: 'inline-block',
  color: Colors.Neutrals_400,
  width: 'auto',
  height: 'auto',
};

const getJuxToggleChildren = ({ rootToggleId }: { rootToggleId: string }) => {
  const knobElement = getJuxDivElementData({
    contextParentId: rootToggleId,
    styles: {
      root: {
        backgroundColor: Colors.Neutrals_0,
        position: 'absolute',
        borderRadius: '20px',
        boxShadow: '0px 1px 2px 0px #00000033',
        transition: `all ${TRANSITION_TIMING}`,
        borderStyle: 'solid',
        borderWidth: '1px',
        width: 'auto',
        height: 'auto',
      },
      states: {},
    },
  }).root;

  const toggleContainerElement = getJuxDivElementData({
    contextParentId: rootToggleId,
    displayName: 'toggle_container',
    parentId: rootToggleId,
    styles: {
      root: {
        color: 'currentColor',
        display: 'block',
        height: '24px',
        width: '42px',
        minWidth: '42px',
        borderRadius: '24px',
        transition: `background ${TRANSITION_TIMING}`,
        position: 'relative',
      },
      states: {},
    },
    children: [knobElement.id],
  }).root;
  knobElement.parentId = toggleContainerElement.id;

  const toggleLabel = getJuxTextElementData({
    contextParentId: rootToggleId,
    rootStyles: JUX_TOGGLE_LABEL_DEFAULT_STYLES,
    text: JUX_TOGGLE_LABEL_DEFAULT_CONTENT,
    displayName: 'label',
  }).root;

  const toggleHelperText = getJuxTextElementData({
    contextParentId: rootToggleId,
    rootStyles: JUX_TOGGLE_HELPER_TEXT_DEFAULT_STYLES,
    text: JUX_TOGGLE_HELPER_TEXT_DEFAULT_CONTENT,
    displayName: 'helper_text',
  }).root;

  const textWrapperElement = getJuxDivElementData({
    parentId: rootToggleId,
    contextParentId: rootToggleId,
    displayName: 'text_wrapper',
    styles: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '4px 0',
        paddingTop: '3px',
        width: 'auto',
        height: 'auto',
      },
      states: {},
    },
    children: [toggleLabel.id, toggleHelperText.id],
  }).root;
  toggleLabel.parentId = textWrapperElement.id;
  toggleHelperText.parentId = textWrapperElement.id;

  return {
    knobElement,
    toggleContainerElement,
    textWrapperElement,
    toggleLabel,
    toggleHelperText,
  };
};

const getJuxToggleContextStyles = ({
  knobContextUuid,
  toggleContainerContextUuid,
}: {
  knobContextUuid: string;
  toggleContainerContextUuid: string;
}) => {
  const uncheckedContextStyles: ContextStylesList = [
    // Default + unchecked
    {
      contextChildUuid: toggleContainerContextUuid,
      condition: {
        propsValues: {
          checked: false,
          disabled: false,
        },
      },
      styles: {
        backgroundColor: Colors.Neutrals_400,
      },
    },
    {
      contextChildUuid: knobContextUuid,
      condition: {
        propsValues: {
          checked: false,
          disabled: false,
        },
      },
      styles: {
        height: '20px',
        width: '20px',
        top: '2px',
        left: '2px',
        borderColor: Colors.Neutrals_400,
      },
    },

    // Default + unchecked + disabled
    {
      contextChildUuid: toggleContainerContextUuid,
      condition: {
        propsValues: {
          checked: false,
          disabled: true,
        },
      },
      styles: {
        backgroundColor: Colors.Neutrals_300,
      },
    },
    {
      contextChildUuid: knobContextUuid,
      condition: {
        propsValues: {
          checked: false,
          disabled: true,
        },
      },
      styles: {
        height: '20px',
        width: '20px',
        top: '2px',
        left: '2px',
        borderColor: Colors.Neutrals_300,
      },
    },

    // Hover + unchecked
    {
      contextChildUuid: toggleContainerContextUuid,
      condition: {
        state: 'hover',
        propsValues: {
          checked: false,
          disabled: false,
        },
      },
      styles: {
        backgroundColor: Colors.Neutrals_500,
      },
    },
    {
      contextChildUuid: knobContextUuid,
      condition: {
        state: 'hover',
        propsValues: {
          checked: false,
          disabled: false,
        },
      },
      styles: {
        borderColor: Colors.Neutrals_500,
      },
    },

    // Active + unchecked
    {
      contextChildUuid: toggleContainerContextUuid,
      condition: {
        state: 'active',
        propsValues: {
          checked: false,
          disabled: false,
        },
      },
      styles: {
        backgroundColor: Colors.Neutrals_600,
      },
    },
    {
      contextChildUuid: knobContextUuid,
      condition: {
        state: 'active',
        propsValues: {
          checked: false,
          disabled: false,
        },
      },
      styles: {
        borderColor: Colors.Neutrals_600,
      },
    },
  ];

  const checkedContextStyles: ContextStylesList = [
    // Default + checked
    {
      contextChildUuid: toggleContainerContextUuid,
      condition: {
        propsValues: {
          checked: true,
        },
      },
      styles: {
        backgroundColor: Colors.Brand_500,
      },
    },
    {
      contextChildUuid: knobContextUuid,
      condition: {
        propsValues: {
          checked: true,
          disabled: false,
        },
      },
      styles: {
        height: '20px',
        width: '20px',
        top: '2px',
        left: '20px',
        borderColor: Colors.Brand_600,
      },
    },

    // Default + checked + disabled
    {
      contextChildUuid: toggleContainerContextUuid,
      condition: {
        propsValues: {
          checked: true,
          disabled: true,
        },
      },
      styles: { backgroundColor: Colors.Brand_200 },
    },
    {
      contextChildUuid: knobContextUuid,
      condition: {
        propsValues: {
          checked: true,
          disabled: true,
        },
      },
      styles: {
        height: '20px',
        width: '20px',
        top: '2px',
        left: '20px',
        borderColor: Colors.Brand_200,
      },
    },

    // Hover + checked
    {
      contextChildUuid: toggleContainerContextUuid,
      condition: {
        state: 'hover',
        propsValues: {
          checked: true,
          disabled: false,
        },
      },
      styles: {
        backgroundColor: Colors.Brand_600,
      },
    },
    {
      contextChildUuid: knobContextUuid,
      condition: {
        state: 'hover',
        propsValues: {
          checked: true,
          disabled: false,
        },
      },
      styles: {
        borderColor: Colors.Brand_700,
      },
    },

    // Active + checked
    {
      contextChildUuid: toggleContainerContextUuid,
      condition: {
        state: 'active',
        propsValues: {
          checked: true,
          disabled: false,
        },
      },
      styles: {
        backgroundColor: Colors.Brand_700,
      },
    },
    {
      contextChildUuid: knobContextUuid,
      condition: {
        state: 'active',
        propsValues: {
          checked: true,
          disabled: false,
        },
      },
      styles: {
        borderColor: Colors.Brand_800,
      },
    },
  ];

  const indeterminateContextStyles: ContextStylesList = [
    // Default + indeterminate
    {
      contextChildUuid: toggleContainerContextUuid,
      condition: {
        propsValues: {
          checked: 'indeterminate',
        },
      },
      styles: {
        backgroundColor: Colors.Brand_500,
      },
    },
    {
      contextChildUuid: knobContextUuid,
      condition: {
        propsValues: {
          checked: 'indeterminate',
        },
      },
      styles: {
        height: '2px',
        width: '16px',
        top: '11px',
        left: '13px',
      },
    },

    // Default + indeterminate + disabled
    {
      contextChildUuid: toggleContainerContextUuid,
      condition: {
        propsValues: {
          checked: 'indeterminate',
          disabled: true,
        },
      },
      styles: {
        backgroundColor: Colors.Brand_200,
      },
    },

    // Hover + indeterminate
    {
      contextChildUuid: toggleContainerContextUuid,
      condition: {
        state: 'hover',
        propsValues: {
          checked: 'indeterminate',
          disabled: false,
        },
      },
      styles: {
        backgroundColor: Colors.Brand_600,
      },
    },

    // Active + indeterminate
    {
      contextChildUuid: toggleContainerContextUuid,
      condition: {
        state: 'active',
        propsValues: {
          checked: 'indeterminate',
          disabled: false,
        },
      },
      styles: {
        backgroundColor: Colors.Brand_700,
      },
    },
  ];

  return [
    ...uncheckedContextStyles,
    ...checkedContextStyles,
    ...indeterminateContextStyles,
  ];
};

export const getJuxToggleElementData: ElementDataInit<undefined> = ({
  contextParentId,
} = {}) => {
  const rootToggleId = uuid.v4();

  const {
    knobElement,
    toggleContainerElement,
    textWrapperElement,
    toggleLabel,
    toggleHelperText,
  } = getJuxToggleChildren({ rootToggleId });

  const contextStyles = getJuxToggleContextStyles({
    knobContextUuid: knobElement.id,
    toggleContainerContextUuid: toggleContainerElement.id,
  });

  return {
    root: {
      type: NodeType.LOCAL_COMPONENT,
      id: rootToggleId,
      scopes: [],
      displayName: TOGGLE_ELEMENT_DEFAULT_DISPLAY_NAME,
      tagName: COMPONENT_TAG_NAME.JuxToggle,
      styles: { ...TOGGLE_ELEMENT_DEFAULT_STYLES, contextStyles },
      config: {
        contextParentId,
        props: {
          // starting values of props
          checked: true,
          disabled: false,
        },
        variants: TOGGLE_ELEMENT_VARIANTS_CONFIG,
      },
      children: [toggleContainerElement.id, textWrapperElement.id],
    },
    subComponents: [
      toggleContainerElement,
      textWrapperElement,
      toggleLabel,
      toggleHelperText,
      knobElement,
    ],
  };
};
