import { FC, MouseEvent, useRef, useState } from 'react';
import {
  LeftNavContent_div as LeftNavContentDiv,
  List_action as ListAction,
  List_item as ListItem,
} from 'libs/shared/ui-core/src/lib/imported/components';
import { EditableTypography, TypographyElement } from '@jux/ui/components';
import { booleanAsString } from '@jux/ui-core';

const SIDEBAR_SELECTED_BACKGROUND_COLOR = '#272727';

const EXPAND_ICON = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 4C9 3.44772 8.55229 3 8 3C7.44772 3 7 3.44772 7 4V7H4C3.44772 7 3 7.44772 3 8C3 8.55229 3.44772 9 4 9H7V12C7 12.5523 7.44771 13 8 13C8.55228 13 9 12.5523 9 12V9H12C12.5523 9 13 8.55229 13 8C13 7.44772 12.5523 7 12 7H9V4Z" fill="currentColor"/>
</svg>
`;

const THREE_DOT_ICON = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.75 9.5C3.7165 9.5 4.5 8.7165 4.5 7.75C4.5 6.7835 3.7165 6 2.75 6C1.7835 6 1 6.7835 1 7.75C1 8.7165 1.7835 9.5 2.75 9.5Z" fill="currentColor"/>
<path d="M8.25 9.5C9.2165 9.5 10 8.7165 10 7.75C10 6.7835 9.2165 6 8.25 6C7.2835 6 6.5 6.7835 6.5 7.75C6.5 8.7165 7.2835 9.5 8.25 9.5Z" fill="currentColor"/>
<path d="M13.75 9.5C14.7165 9.5 15.5 8.7165 15.5 7.75C15.5 6.7835 14.7165 6 13.75 6C12.7835 6 12 6.7835 12 7.75C12 8.7165 12.7835 9.5 13.75 9.5Z" fill="currentColor"/>
</svg>`;

export interface ICanvasListItem {
  id: string;
  isSelected: boolean;
  isRenameModeOn: boolean;
  name: string;
  onCanvasItemClick: (e: MouseEvent<HTMLButtonElement>) => void;
  onDiscardRename: () => void;
  onThreeDotClick: (e: MouseEvent<HTMLButtonElement>) => void;
  onRenameSubmit: (newName: string) => boolean;
}

interface SideBarItemProps {
  canvasItems: Array<ICanvasListItem>;
  isCanvasCreationMode: boolean;
  onDiscardCreation: () => void;
  onNewCanvasClick: () => void;
  onSaveNewCanvasName: (text: string) => boolean;
}

const CanvasListItem: FC<ICanvasListItem> = ({
  isSelected,
  name,
  onCanvasItemClick,
  onDiscardRename,
  onThreeDotClick,
  onRenameSubmit,
  isRenameModeOn,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const editRef = useRef<TypographyElement>(null);

  return isRenameModeOn ? (
    <EditableTypography
      label={name}
      variant="body-regular"
      ref={editRef}
      onBlur={(e) => onRenameSubmit(e.target.textContent ?? name)}
      onSaveChanges={(text) => onRenameSubmit(text)}
      onDiscardChanges={onDiscardRename}
      autoFocus
      maxWidth="290px"
      sx={{
        color: 'white',
        height: '32px',
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
      }}
    />
  ) : (
    <ListItem
      Selected={booleanAsString(isSelected)}
      disabled={false}
      onClick={onCanvasItemClick}
      {...(isSelected && {
        style: { backgroundColor: SIDEBAR_SELECTED_BACKGROUND_COLOR },
      })}
      List_item_div_Props={{
        IconButton_Props: {
          disabled: false,
          hierarchy: 'primary',
          onMouseEnter: handleMouseEnter,
          onMouseLeave: handleMouseLeave,
          onClick: (e) => {
            onThreeDotClick?.(e);
            e.stopPropagation();
          },
          style: {
            position: 'relative',
            backgroundColor: isHovered ? '#303030' : 'transparent',
            border: 'none',
            color: isHovered ? 'white' : '#9e9e9e',
          },
          IconButton_Icon_Props: {
            content: THREE_DOT_ICON,
            style: {
              color: 'inherit',
            },
          },
        },
      }}
      List_item_button_label_Props={{
        text: name,
      }}
    />
  );
};

export const SideBarItems: FC<SideBarItemProps> = ({
  canvasItems,
  onNewCanvasClick,
  isCanvasCreationMode,
  onSaveNewCanvasName,
  onDiscardCreation,
}) => {
  const editableTypographyRef = useRef<TypographyElement>(null);
  return (
    <>
      <LeftNavContentDiv
        LeftNavContent_div_text_Props={{
          text: 'Canvases',
        }}
      />
      {canvasItems.map((canvasItem) => {
        return <CanvasListItem key={canvasItem.id} {...canvasItem} />;
      })}
      {isCanvasCreationMode ? (
        <EditableTypography
          label="New canvas"
          variant="body-regular"
          ref={editableTypographyRef}
          onSaveChanges={(text) => onSaveNewCanvasName(text)}
          onDiscardChanges={onDiscardCreation}
          autoFocus
          sx={{
            color: 'white',
            height: '32px',
            padding: '8px',
            display: 'flex',
            alignItems: 'center',
          }}
        />
      ) : null}
      <ListAction
        disabled={false}
        hierarchy="primary"
        onClick={onNewCanvasClick}
        List_action_Expand_Props={{
          content: EXPAND_ICON,
        }}
      />
    </>
  );
};
