import { atom, useAtom } from 'jotai';
import { RESET, atomWithReset, useResetAtom } from 'jotai/utils';
import {
  ModalActions,
  ModalContent,
  ModalHandlers,
  ModalState,
  ModalStates,
  UpdateModalArgs,
} from './useModalState.interface';

export const modalStateAtom = atomWithReset<ModalState>('closed');

export const modalContentAtom = atomWithReset<ModalContent>(null);

export const modalHandlersAtom = atomWithReset<ModalHandlers>({
  onClose: () => {},
});

export const modalAtom = atom(
  (get) => ({
    state: get(modalStateAtom),
    content: get(modalContentAtom),
    handlers: get(modalHandlersAtom),
  }),
  (_get, set, update: UpdateModalArgs | typeof RESET) => {
    // handle reset
    if (update === RESET) {
      set(modalStateAtom, RESET);
      set(modalHandlersAtom, RESET);
      /*
       * due to the built-in transitions, and to avoid flickers/bugs,
       * we could wait a bit before resetting the content,
       * or use some observable + intermediate "closing/opening" states,
       * but instead - we simply update it only when opening the drawer.
       * // set(drawerContentAtom, RESET);
       */
      return;
    }

    const { action, content, handlers } = update;

    // handle open action
    if (action === ModalActions.open) {
      // set/update content when opening
      if (content) {
        set(modalContentAtom, content);
      }
      // set/update handlers when opening
      if (handlers) {
        set(modalHandlersAtom, handlers);
      }
      // open drawer
      set(modalStateAtom, ModalStates.opened);
    }
    // handle close action
    else {
      set(modalStateAtom, ModalStates.closed);
    }
  }
);

export const useModalState = () => {
  const [{ state, content, handlers }, setState] = useAtom(modalAtom);

  const reset = useResetAtom(modalAtom);

  return {
    state,
    content,
    handlers,
    setState,
    reset,
  };
};
