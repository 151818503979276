import {
  CommonActionsParams,
  JuxStore,
  JuxStoreActionFn,
} from '../store.interface';

export const setEditModeInNodeToolbarLabel: JuxStoreActionFn<
  CommonActionsParams['setEditModeInNodeToolbarLabel'],
  JuxStore
> = ({ nodeId, editable, state }) => {
  state.nodeIdLabelInEditMode = editable ? nodeId : null;

  return state;
};
