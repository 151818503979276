import { useCallback } from 'react';
import {
  selectResolvedComponentProps,
  selectSourceComponent,
  storeApi,
  useStore,
} from '@jux/canjux/core';
import { ComponentConfigData } from '@jux/data-entities';
import logger from '@jux/ui-logger';
import {
  deserializeHTMLClipboardData,
  readClipboardRawData,
  serializeHTMLClipboardData,
} from '../utils';

const TEXT_PLAIN_NODE_TYPE = 'text/plain';
const HTML_NODE_TYPE = 'text/html';

type NodesClipboardData = {
  /** The source canvas id that the copied node originated from */
  canvasName: string;
  /** The node & component unique identifier that is being copied */
  id: string;
  /** If copied node was an instance and had props overrides */
  propsOverrides?: ComponentConfigData['props'];
}[];

export const useClipboardStore = () => {
  const getSourceComponentById = useStore(selectSourceComponent);

  /**
   * Store details of a node to be copied to the clipboard.
   * @param nodeId the identifier of the node to be copied.
   */
  const writeToClipboard = useCallback(
    async ({
      nodeIds,
      canvasName,
    }: {
      nodeIds: string[];
      canvasName: string;
    }) => {
      const items: NodesClipboardData = [];

      for (const id of nodeIds) {
        // resolving the source component id and saving it on clipboard
        const sourceComponent = getSourceComponentById(id);
        const propsOverrides = selectResolvedComponentProps({
          id,
          onlyVariantsProps: false, // we want to include other props like text content
        })(storeApi.getState());

        if (!sourceComponent) {
          return;
        }

        items.push({
          canvasName,
          id: sourceComponent.id,
          propsOverrides,
        });
      }

      if (!items.length) {
        return;
      }

      try {
        // Write the data to the clipboard
        await navigator.clipboard.write([
          serializeHTMLClipboardData<NodesClipboardData>(items),
        ]);
      } catch (error) {
        logger.error(error || 'Failed to copy to clipboard');
      }
    },
    [getSourceComponentById]
  );

  const readClipboardData = useCallback(async () => {
    const clipboardItems = await navigator.clipboard.read();

    const htmlClipboardData = await readClipboardRawData({
      type: HTML_NODE_TYPE,
      clipboardItems,
    });

    if (htmlClipboardData) {
      const nodes =
        deserializeHTMLClipboardData<NodesClipboardData>(htmlClipboardData);

      if (nodes) {
        return { nodes };
      }
    }

    // trying to parse text clipboard data
    const textClipboardData = await readClipboardRawData({
      type: TEXT_PLAIN_NODE_TYPE,
      clipboardItems,
    });

    return textClipboardData ? { text: textClipboardData } : {};
  }, []);

  return {
    writeToClipboard,
    readClipboardData,
  };
};
