import { useCallback } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import { useEditTokenForm } from '@jux/ui/components/tokens/token-drawer/forms/createEditHooks';
import { useTokenSetState } from '@jux/ui/state';
import { createPath } from '@jux/ui/utils/tokensPath';
import { useSelectedTokenState } from '../state/useSelectedTokenState';

export const useDeleteToken = () => {
  const { isTokenSelected, deselectToken } = useSelectedTokenState();
  const { closeEditTokenForm } = useEditTokenForm();
  const { currentTokenSetId } = useTokenSetState();
  const {
    tokenSetsActions: { deleteTokenOrGroup },
  } = useStoreActions();

  const deleteToken = useCallback(
    ({ tokenName, groupPath }: { tokenName: string; groupPath: string }) => {
      if (!currentTokenSetId) return;

      const deletePath = createPath([groupPath, tokenName]);

      deleteTokenOrGroup({
        path: deletePath,
      });

      if (isTokenSelected(deletePath)) {
        deselectToken();
        closeEditTokenForm();
      }
    },
    [
      closeEditTokenForm,
      currentTokenSetId,
      deleteTokenOrGroup,
      deselectToken,
      isTokenSelected,
    ]
  );

  return {
    deleteToken,
  };
};
