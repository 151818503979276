/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const DDPAlignHorizontalIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M4 3C4 2.44772 3.55228 2 3 2H2C1.44772 2 1 2.44772 1 3V13C1 13.5523 1.44772 14 2 14H3C3.55229 14 4 13.5523 4 13L4 3Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M8.5 2C9.05228 2 9.5 2.44772 9.5 3V13C9.5 13.5523 9.05229 14 8.5 14H7.5C6.94772 14 6.5 13.5523 6.5 13V3C6.5 2.44772 6.94771 2 7.5 2H8.5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M14 2C14.5523 2 15 2.44772 15 3V13C15 13.5523 14.5523 14 14 14H13C12.4477 14 12 13.5523 12 13V3C12 2.44772 12.4477 2 13 2H14Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
