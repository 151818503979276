import { useCallback, useMemo } from 'react';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import { useFieldValues, useSetMultiFieldsValues } from '../../../../hooks';
import { DisplayValue } from '../display/DisplayField.interface';
import { useFlexDirectionField } from '../flexDirectionAndWrapping/flexDirection/useFlexDirectionField';
import {
  JustifyContentValue,
  SupportedJustifyContentValues,
} from './JustifyContentField.interface';
import { parseJustifyContent } from './JustifyContentField.utils';

export const useJustifyContentField = () => {
  const { value, initialValue } = useFieldValues(
    DDPPrimitiveFieldsKeys.justifyContent
  );
  const { setMultiFieldsValues } = useSetMultiFieldsValues();

  const { isColumnSelected: isFlexDirectionIsColumn } = useFlexDirectionField();

  const parsedJustifyContent = useMemo(
    () => parseJustifyContent(value ?? initialValue),
    [initialValue, value]
  );

  const isFlexStartSelected = useMemo(
    () => parsedJustifyContent === JustifyContentValue.flexStart,
    [parsedJustifyContent]
  );

  const isCenterSelected = useMemo(
    () => parsedJustifyContent === JustifyContentValue.center,
    [parsedJustifyContent]
  );

  const isFlexEndSelected = useMemo(
    () => parsedJustifyContent === JustifyContentValue.flexEnd,
    [parsedJustifyContent]
  );

  const isSpaceAroundSelected = useMemo(
    () => parsedJustifyContent === JustifyContentValue.spaceAround,
    [parsedJustifyContent]
  );

  const isSpaceBetweenSelected = useMemo(
    () => parsedJustifyContent === JustifyContentValue.spaceBetween,
    [parsedJustifyContent]
  );

  const saveChanges = useCallback(
    (newJustifyContentValue: SupportedJustifyContentValues | undefined) => {
      setMultiFieldsValues({
        [DDPPrimitiveFieldsKeys.justifyContent]: newJustifyContentValue,
        [DDPPrimitiveFieldsKeys.display]: DisplayValue.flex,
      });
    },
    [setMultiFieldsValues]
  );

  const handleCenterClick = useCallback(() => {
    saveChanges(JustifyContentValue.center);
  }, [saveChanges]);

  const handleFlexStartClick = useCallback(() => {
    saveChanges(JustifyContentValue.flexStart);
  }, [saveChanges]);

  const handleFlexEndClick = useCallback(() => {
    saveChanges(JustifyContentValue.flexEnd);
  }, [saveChanges]);

  const handleSpaceAroundClick = useCallback(() => {
    saveChanges(JustifyContentValue.spaceAround);
  }, [saveChanges]);

  const handleSpaceBetweenClick = useCallback(() => {
    saveChanges(JustifyContentValue.spaceBetween);
  }, [saveChanges]);

  return {
    isCenterSelected,
    isFlexEndSelected,
    isFlexStartSelected,
    isSpaceAroundSelected,
    isSpaceBetweenSelected,
    handleCenterClick,
    handleFlexStartClick,
    handleFlexEndClick,
    handleSpaceAroundClick,
    handleSpaceBetweenClick,
    isFlexDirectionIsColumn,
  };
};
