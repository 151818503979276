import { Global, Module } from '@nestjs/common';
import { ConfigModule } from '@nestjs/config';
import { SegmentFactory } from './segment.factory';

@Global()
@Module({
  imports: [ConfigModule],
  providers: [SegmentFactory],
  exports: [SegmentFactory],
})
export class SegmentModule {}
