import { keyboardActionKeys } from './keys';
import { HotKeyItem, useRegisterHotKey } from './useRegisterHotKeys';

export const useAcceptTextChange = ({
  callback,
  options,
  dependencies,
}: Omit<HotKeyItem, 'action'>) =>
  useRegisterHotKey({
    action: keyboardActionKeys.ACCEPT_TEXT_CHANGE,
    callback,
    options,
    dependencies,
  });
