/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from './core/CustomIcon';
export const BorderIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          d: 'M3 7C3 6.44772 3.44772 6 4 6H12C12.5523 6 13 6.44772 13 7C13 7.55228 12.5523 8 12 8H4C3.44772 8 3 7.55228 3 7Z',
          fill: 'currentColor',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          d: 'M3.5 3C3.22386 3 3 3.22386 3 3.5C3 3.77614 3.22386 4 3.5 4H12.5C12.7761 4 13 3.77614 13 3.5C13 3.22386 12.7761 3 12.5 3H3.5Z',
          fill: 'currentColor',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          d: 'M3 11C3 10.4477 3.44772 10 4 10H12C12.5523 10 13 10.4477 13 11V12C13 12.5523 12.5523 13 12 13H4C3.44772 13 3 12.5523 3 12V11Z',
          fill: 'currentColor',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
