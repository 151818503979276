import { useState, useRef } from 'react';

export const useMenuList = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    if (!anchorRef) return;
    setOpen(false);
  };

  return { open, anchorRef, handleClose, handleToggle };
};
