import { ZoomBehavior, Selection as D3Selection, zoomIdentity } from 'd3';
import { getD3Transition } from './graph';

export const getPositionToPutAnObjectOnCenterOfOtherObject = ({
  zoom,
  targetObject,
  object,
}: {
  zoom: number;
  targetObject: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  object: {
    width: number;
    height: number;
  };
}) => ({
  x: (targetObject.x * -1 + targetObject.width / 2) / zoom - object.width / 2,
  y: (targetObject.y * -1 + targetObject.height / 2) / zoom - object.height / 2,
});

export type SetCenterOptions = {
  d3Zoom: ZoomBehavior<Element, unknown>;
  d3Selection: D3Selection<Element, unknown, null, undefined>;
  x: number;
  y: number;
  width: number;
  height: number;
  zoom?: number;
  maxZoom: number;
  duration?: number;
  scale?: number;
};

export const setSpaceCenter = ({
  d3Zoom,
  d3Selection,
  x,
  y,
  width,
  height,
  zoom,
  maxZoom,
  duration,
}: SetCenterOptions) => {
  const nextZoom = typeof zoom !== 'undefined' ? zoom : maxZoom;
  const centerX = width / 2 - x * nextZoom;
  const centerY = height / 2 - y * nextZoom;
  const newTransform = zoomIdentity.translate(centerX, centerY).scale(nextZoom);

  d3Zoom.transform(getD3Transition(d3Selection, duration), newTransform);
};

export const getTargetRectCenter = (
  zoom: number,
  targetRect: {
    x: number;
    y: number;
    width: number;
    height: number;
  },
  rect: {
    width: number;
    height: number;
  }
) => ({
  x: (targetRect.x * -1 + targetRect.width / 2) / zoom - rect.width / 2,
  y: (targetRect.y * -1 + targetRect.height / 2) / zoom - rect.height / 2,
});
