import type { Draft as WritableDraft } from 'mutative';
import { ContextStyle, EnumPropValue, StyleVariant } from '@jux/types';
import { ComponentProp } from '@jux/data-entities';

export const duplicateStylesForNewPropValue = (
  variantsStyles: WritableDraft<StyleVariant>[],
  variantConfig: ComponentProp,
  addedPropName: string,
  addedPropValue: EnumPropValue
) => {
  for (const variant of variantsStyles) {
    const variantContainsDefaultPropValue =
      addedPropName in variant.propsValues &&
      variant.propsValues[addedPropName] === variantConfig.defaultValue;

    // If not found, there aren't any specific styles for this prop value so we duplicate nothing
    // It's ok that this function will not duplicate anything in this case
    if (variantContainsDefaultPropValue) {
      // Duplicate a new condition with the styles of the default variant of this prop
      const newPropsValues = { ...variant.propsValues };
      newPropsValues[addedPropName] = addedPropValue;

      variantsStyles.push({
        styles: variant.styles,
        propsValues: newPropsValues,
      });
    }
  }
};

export const duplicateContextStylesForNewPropValue = (
  contextStyles: WritableDraft<ContextStyle>[],
  variantConfig: ComponentProp,
  propName: string,
  addedPropValue: EnumPropValue
) => {
  for (const contextStyle of contextStyles) {
    const contextConditionProps = contextStyle?.condition?.propsValues;

    const conditionContainsDefaultPropValue =
      contextConditionProps &&
      propName in contextConditionProps &&
      contextConditionProps[propName] === variantConfig.defaultValue;

    // If not found, there aren't any specific styles for this prop value so we duplicate nothing
    // It's ok that this function will not duplicate anything in this case
    if (conditionContainsDefaultPropValue) {
      // TODO: fix this logic, it doesn't actually duplicate nested objects
      // Duplicate a new condition
      const newPropsValues = { ...contextConditionProps };
      newPropsValues[propName] = addedPropValue;

      contextStyles.push({
        ...contextStyle,
        condition: { ...contextStyle.condition, propsValues: newPropsValues },
      });
    }
  }
};
