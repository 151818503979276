import { HttpException } from '@nestjs/common';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { IInviteUsersByEmails } from '@jux/data-entities';
import DrimzAPI from '@jux/ui/lib/api';

export function useInviteUserByEmailsMutation() {
  return useMutation<boolean, AxiosError<HttpException>, IInviteUsersByEmails>(
    (data) =>
      DrimzAPI.post(`/api/organizations/${data.org_id}/invite`, {
        emails: data.emails,
      }).then((resp: AxiosResponse<boolean>) => resp.data)
  );
}
