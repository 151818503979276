import { styled } from '@jux/ui/components/common/mui';
import { LAYERS_PANEL_HEADER_BORDER_COLOR } from '@jux/ui/theme/palette';

export const ObjectNavigatorHeaderStickyWrapper = styled('div')(
  ({ theme }) => ({
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.drimz.palette.background.navigation,
    borderBottom: `1px solid`,
    borderColor: LAYERS_PANEL_HEADER_BORDER_COLOR,
    height: '49px',
    padding: '16px 24px 0px 24px',
  })
);
