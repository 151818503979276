import { z } from 'zod';
import { CompositeTokenTypes } from '@jux/design-tokens';
import { BaseUseFieldsProps } from '../useFields.interface';
import { NumericFieldProps } from '../../base/fields/numeric/NumericField.interface';

export type NumericFieldValue = string;

export enum SupportedNumericBaseUnits {
  Percentage = '%',
  None = '',
}

export type NumericFieldOptions = {
  baseUnit?: SupportedNumericBaseUnits;
  defaultValue?: string;
  fallbackValue?: string;
  saveAsDecimal?: boolean;
  min?: number | undefined;
  max?: number | undefined;
};

export type UseNumericFieldProps = BaseUseFieldsProps<string> & {
  fieldName: string;
  parentFieldTokenType?: CompositeTokenTypes;
  fieldSchema: z.ZodOptional<z.ZodString>;
  options?: NumericFieldOptions;
};

export type UseNumericFieldInputsProps = UseNumericFieldProps & {
  parsedValue: NumericFieldValue;
  fieldSchema: z.ZodType;
  parseValue: (newValue: string) => NumericFieldValue;
  validateField: (
    val: string
  ) => z.SafeParseReturnType<string | undefined, string | undefined>;
  options: NumericFieldOptions;
};

export type UseNumericFieldReturnProps = Omit<NumericFieldProps, 'icon'> & {
  setValue: (value: string) => void;
};
