export const getItemAncestorPaths = ({
  id,
  path,
}: {
  id: string;
  path: string;
}) => {
  const regex = new RegExp(/[^.]+/g);
  const matches: string[] = [];
  let match: RegExpExecArray | null;

  while ((match = regex.exec(path)) !== null) {
    if (match[0] === id) continue;

    matches.push(match[0]);
  }

  for (let i = 1; i < matches.length; i++) {
    matches[i] = matches[i - 1] + '.' + matches[i];
  }

  return matches;
};
