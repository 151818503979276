/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { FC } from 'react';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
import { IconBaseProps } from '../../Icons.interface';

export const DivElementPanelIcon: FC<IconBaseProps> = ({
  textColor,
  ...props
}) => (
  <CustomIcon
    tag="svg"
    width="80"
    height="51"
    attr={{
      viewBox: '0 0 80 51',
    }}
    child={[
      {
        tag: 'rect',
        attr: {
          width: '21',
          height: '11',
          fill: 'currentColor',
        },
        child: [],
      },
      {
        tag: 'rect',
        attr: {
          x: '0.5',
          y: '10.5',
          width: '79',
          height: '40',
          stroke: 'currentColor',
          strokeWidth: '1',
          fill: 'transparent',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          d: 'M4.8892 9H2.56889V2.45455H4.90838C5.56676 2.45455 6.13352 2.58558 6.60866 2.84766C7.08381 3.1076 7.44922 3.48153 7.7049 3.96946C7.96271 4.45739 8.09162 5.04119 8.09162 5.72088C8.09162 6.4027 7.96271 6.98864 7.7049 7.47869C7.44922 7.96875 7.08168 8.34482 6.60227 8.60689C6.125 8.86896 5.55398 9 4.8892 9ZM3.95277 7.81428H4.83168C5.24077 7.81428 5.58487 7.74183 5.86399 7.59695C6.14524 7.44993 6.35618 7.22301 6.4968 6.91619C6.63956 6.60724 6.71094 6.20881 6.71094 5.72088C6.71094 5.23722 6.63956 4.84197 6.4968 4.53516C6.35618 4.22834 6.14631 4.00249 5.86719 3.8576C5.58807 3.71271 5.24396 3.64027 4.83487 3.64027H3.95277V7.81428ZM10.0912 9V4.09091H11.4527V9H10.0912ZM10.7751 3.4581C10.5727 3.4581 10.3991 3.39098 10.2542 3.25675C10.1114 3.12038 10.04 2.95739 10.04 2.76776C10.04 2.58026 10.1114 2.41939 10.2542 2.28516C10.3991 2.14879 10.5727 2.08061 10.7751 2.08061C10.9775 2.08061 11.1501 2.14879 11.2929 2.28516C11.4378 2.41939 11.5102 2.58026 11.5102 2.76776C11.5102 2.95739 11.4378 3.12038 11.2929 3.25675C11.1501 3.39098 10.9775 3.4581 10.7751 3.4581ZM18.12 4.09091L16.4038 9H14.8697L13.1534 4.09091H14.5916L15.6112 7.60334H15.6623L16.6786 4.09091H18.12Z',
          fill: textColor || 'currentColor',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
