import { NodeProperties, XYPosition } from '@jux/data-entities';

const getNodesZIndex = ({
  isSelected,
  isDragged,
  isRoot,
}: {
  isSelected: boolean;
  isDragged: boolean;
  isRoot: boolean;
}) => {
  if (isDragged) {
    return 1000;
  }

  if (isRoot) {
    return isSelected ? 1 : 0;
  }

  return undefined;
};

const getNodeTop = ({ isRoot }: { isRoot: boolean }) => {
  if (isRoot) {
    return 'auto';
  }

  return undefined;
};

const getNodeRight = ({ isRoot }: { isRoot: boolean }) => {
  if (isRoot) {
    return 'auto';
  }

  return undefined;
};

const getNodeBottom = ({ isRoot }: { isRoot: boolean }) => {
  if (isRoot) {
    return 'auto';
  }

  return undefined;
};

const getNodeLeft = ({ isRoot }: { isRoot: boolean }) => {
  if (isRoot) {
    return 'auto';
  }

  return undefined;
};

export const getCanvasNodeElementStyle = ({
  isDragged,
  isLive,
  nodeParentId,
  positionX,
  positionY,
  isSelected,
  textNodeInEditMode,
  isRoot,
}: {
  isDragged: NodeProperties['isDragged'];
  isLive: boolean;
  isSelected: boolean;
  nodeParentId?: string | null;
  positionX: XYPosition['x'];
  positionY: XYPosition['y'];
  textNodeInEditMode: boolean;
  isRoot: boolean;
}) => ({
  boxSizing: 'border-box',
  cursor: textNodeInEditMode ? undefined : isLive ? '' : 'default',
  // If this node has a parentId, then it's not a root node -
  // so we don't set top and left, because it should be positioned by its parent
  outline: (isDragged || textNodeInEditMode) && !isLive ? 'none' : undefined,
  pointerEvents: isLive || textNodeInEditMode ? 'all' : 'none',
  position: nodeParentId && !isDragged ? undefined : 'absolute',
  transform:
    nodeParentId && !isDragged
      ? undefined
      : `translate3d(${positionX}px, ${positionY}px, 0)`,
  transformOrigin: textNodeInEditMode ? undefined : '0 0',
  /*
   * It is necessary to set the transition in edit mode, so that the user experience while working on the DDP will be immediate.
   * Additionally, it is hard to catch changes on the DOM when transition is active.
   */
  transition: isLive ? undefined : 'none',
  userSelect: isLive || textNodeInEditMode ? undefined : 'none',

  // due to the support of position we want the canvas to control the values of the position
  // so we set the values to auto
  top: getNodeTop({ isRoot }),
  right: getNodeRight({ isRoot }),
  bottom: getNodeBottom({ isRoot }),
  left: getNodeLeft({ isRoot }),

  zIndex: getNodesZIndex({
    isSelected,
    isDragged,
    isRoot,
  }),
});
