/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const ClearIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M12.198 3.80214C11.9098 3.51387 11.4424 3.51387 11.1541 3.80214L8.00002 6.95623L4.84593 3.80214C4.55766 3.51387 4.09028 3.51387 3.80201 3.80214C3.51374 4.09041 3.51374 4.55779 3.80201 4.84606L6.95609 8.00015L3.80201 11.1542C3.51374 11.4425 3.51374 11.9099 3.80201 12.1982C4.09028 12.4864 4.55766 12.4864 4.84593 12.1982L8.00002 9.04408L11.1541 12.1982C11.4424 12.4864 11.9098 12.4864 12.198 12.1982C12.4863 11.9099 12.4863 11.4425 12.198 11.1542L9.04394 8.00015L12.198 4.84606C12.4863 4.55779 12.4863 4.09041 12.198 3.80214Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
