import { useDrag } from 'react-dnd';
import { CanvasItemType, CanvasItemValue } from './useCanvasItem.interface';

export const useDragItem = <T extends CanvasItemType = CanvasItemType>(
  type: T,
  item: CanvasItemValue<T>
) => {
  const [{ isDragging }, dragRef, dragPreviewRef] = useDrag({
    type,
    item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return { dragRef, dragPreviewRef, isDragging };
};
