import { JuxStore } from '@jux/canjux/core';

export const getRootNodeOfNode = ({
  components,
  nodeId,
}: {
  components: JuxStore['components'];
  nodeId: string;
}) => {
  let nodeComponentData = components[nodeId];
  while (nodeComponentData?.parentId) {
    nodeComponentData = components[nodeComponentData.parentId];
  }

  return nodeComponentData;
};
