import { TOKEN_DRAWER_BLACKLIST_AREA_ATTRIBUTE_KEY } from './getTokenDrawerBlacklistAreaAttributes';

export const isInsidePreventCloseElement = (element: Element) => {
  let currentElement: Element | null = element;

  while (currentElement) {
    if (
      currentElement.hasAttribute(TOKEN_DRAWER_BLACKLIST_AREA_ATTRIBUTE_KEY)
    ) {
      return true;
    }

    currentElement = currentElement.parentElement;
  }

  return false;
};
