import * as CSS from 'csstype';
import deepmerge from 'deepmerge';
import {
  PROP_EDITING_MODE_ANY,
  VariantsEditingContext,
} from '@jux/canjux/core';
import {
  ComponentConfigWithStates,
  VariantsValues,
  DEFAULT_STATE,
} from '@jux/types';
import { NodeInteractiveState } from '@jux/ui/components/editor/hooks';

// TODO: split this logic into smaller functions
export const stylesBySelectedNodeState = ({
  currentStyles,
  nodeInteractiveState,
  variantsEditingContext,
  newCss,
}: {
  currentStyles: ComponentConfigWithStates;
  nodeInteractiveState: NodeInteractiveState;
  variantsEditingContext: VariantsEditingContext;
  newCss: CSS.Properties;
}): ComponentConfigWithStates => {
  const clonedStyles = structuredClone(currentStyles);
  const isDefaultState =
    !nodeInteractiveState || nodeInteractiveState === DEFAULT_STATE;

  const finalPropsContext: VariantsValues = {};
  for (const [prop, propValue] of Object.entries(variantsEditingContext)) {
    // Keep only props that are not set to any
    if (propValue !== PROP_EDITING_MODE_ANY) {
      finalPropsContext[prop] = propValue;
    }
  }

  if (Object.keys(finalPropsContext).length === 0) {
    if (isDefaultState) {
      clonedStyles.root = deepmerge(clonedStyles.root, newCss);
    } else {
      clonedStyles.states[nodeInteractiveState] = {
        ...clonedStyles.states[nodeInteractiveState],
        root: {
          ...clonedStyles.states[nodeInteractiveState]?.root,
          ...newCss,
        },
      };
    }

    return clonedStyles;
  }

  const variants = isDefaultState
    ? clonedStyles.variants || []
    : clonedStyles.states[nodeInteractiveState]?.variants || [];

  // Try to find the current variant:
  let isVariantStylesExist = false;

  for (let variantIndex = 0; variantIndex < variants.length; variantIndex++) {
    const existingVariantProps = variants[variantIndex].propsValues;
    if (
      Object.keys(existingVariantProps).length !==
      Object.keys(finalPropsContext).length
    ) {
      continue;
    }

    const isCurrentVariant = Object.entries(existingVariantProps).every(
      ([propName, propValue]) =>
        propName in finalPropsContext &&
        finalPropsContext[propName] === propValue
    );
    if (isCurrentVariant) {
      isVariantStylesExist = true;

      variants[variantIndex].styles = deepmerge(
        variants[variantIndex].styles,
        newCss
      );

      // We found our variant, no need to continue the loop
      break;
    }
  }

  if (!isVariantStylesExist) {
    // variant does not exist, add it
    variants.push({ propsValues: finalPropsContext, styles: newCss });
  }

  if (isDefaultState) {
    clonedStyles.variants = variants;
  } else {
    clonedStyles.states[nodeInteractiveState] = {
      ...clonedStyles.states[nodeInteractiveState],
      variants,
    };
  }

  return clonedStyles;
};

export const withInteractiveState = ({
  nodeInteractiveState,
  styles,
}: {
  nodeInteractiveState: NodeInteractiveState;
  styles: CSS.Properties;
}): CSS.Properties =>
  nodeInteractiveState && nodeInteractiveState !== DEFAULT_STATE
    ? { [`&:${nodeInteractiveState}`]: styles }
    : styles;
