import { JuxStore } from '@jux/canjux/core';
import { ComponentInstanceData, NodeType } from '@jux/data-entities';
import type { Draft as WritableDraft } from 'mutative';
import { v4 as uuidv4 } from 'uuid';

import { defaultInstanceNodeData } from '../../constants/defaultNodeData';

// TODO: need to add optional props here
export const createInstanceNode = ({
  children = [],
  newUuid,
  parentId,
  sourceComponentId,
  state,
}: {
  children?: string[];
  newUuid?: string;
  parentId: string;
  sourceComponentId: string;
  state: WritableDraft<JuxStore>;
}): string => {
  const { components, canvases } = state;

  const newNodeId = newUuid ? newUuid : uuidv4();
  const newReferenceNode: ComponentInstanceData = {
    id: newNodeId,
    type: NodeType.INSTANCE,
    sourceComponentId,
    parentId,
    config: { props: {} },
    children,
  };

  components[newReferenceNode.id] = newReferenceNode;

  components[newReferenceNode.id] = newReferenceNode;

  if (parentId) {
    // TODO: Do we need to update all the canvases here
    //  or just the current one being edited by the user?
    for (const canvas of Object.values(canvases)) {
      if (parentId in canvas.nodes) {
        // TODO: take node data from the source component
        canvas.nodes[newReferenceNode.id] = defaultInstanceNodeData;
      }
    }
  }

  return newReferenceNode.id;
};
