import { useCallback } from 'react';
import { AddToLibraryModal } from '@jux/ui-core';
import { useModal } from '../base-hooks';
import { UseConfirmationModalProps } from '../useConfirmationModal/ConfirmationModal.interface';

export const useAddToLibraryModal = () => {
  const { open, close } = useModal<UseConfirmationModalProps>();

  const openAddToLibraryModal = useCallback(
    ({
      componentName,
      dependenciesNames,
      dependenciesCount,
      componentsToAddCount,
      onConfirm,
      onCancel,
    }: {
      componentName: string;
      dependenciesNames: string;
      dependenciesCount: number;
      componentsToAddCount: number;
      onConfirm: () => void;
      onCancel?: () => void;
    }) => {
      const handleCancel = () => {
        onCancel?.();
        close();
      };

      const handleConfirm = () => {
        onConfirm?.();
        close();
      };

      open({
        handlers: {
          onConfirm,
          onClose: close,
          onCancel: onCancel || close,
        },
        content: (
          <AddToLibraryModal
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            componentName={componentName}
            dependenciesNames={dependenciesNames}
            dependenciesCount={dependenciesCount}
            componentsToAddCount={componentsToAddCount}
          />
        ),
      });
    },
    [close, open]
  );

  return {
    openAddToLibraryModal,
  };
};
