import {
  CanjuxState,
  JuxStoreActionFn,
  TokenSetsActionsParams,
} from '@jux/canjux/core';
import {
  getCurrentTimestamp,
  getDefaultStructuredTokenSet,
} from '@jux/design-tokens';
import { CORE_TOKEN_SET_NAME } from '@jux/types';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';
import { getDesignTokensParserContext } from '../../../store-utils/getDesignTokensParserContext';

const designTokensParser = getDesignTokensParserContext();

export const initTokenSetsData: JuxStoreActionFn<
  TokenSetsActionsParams['initTokenSetsData'],
  CanjuxState
> = ({ state }) => {
  if (!isEmpty(state.tokenSets)) return state;

  state.tokenSets = {
    [CORE_TOKEN_SET_NAME]: {
      createdAt: getCurrentTimestamp(),
      id: uuidv4(),
      name: CORE_TOKEN_SET_NAME,
      updatedAt: getCurrentTimestamp(),
      value: designTokensParser
        .parse(getDefaultStructuredTokenSet())
        .getRawValueCopy(),
    },
  };

  return state;
};
