import {
  CoordinateExtent,
  FitViewOptions,
  infiniteExtent,
  NodeOrigin,
  SelectionRect,
  SnapGrid,
  StateSlice,
  Transform,
} from '@jux/canjux/core';
import { XYPosition } from '@jux/data-entities';
import { Selection as D3Selection, ZoomBehavior, zoomIdentity } from 'd3';

export type CanvasState = {
  setTranslateExtent: (translateExtent: CoordinateExtent) => void;

  /* CANVAS RELATED BEHAVIORS */
  // Whether the user is currently selecting nodes
  userSelectionActive: boolean;

  // Represents the current selection rectangle
  userSelectionRect: SelectionRect | null;

  // Whether the user is currently selecting nodes
  nodesSelectionActive: boolean;

  // Should we allow interacting with nodes (for live mode)
  disableNodesInteraction: boolean;

  // Whether the user is currently dragging the canvas
  paneDragging: boolean;

  // Whether to pan the canvas when dragging a node
  autoPanOnNodeDrag: boolean;

  // The width and height of the canvas
  width: number;
  height: number;

  // Whether the user is currently selecting nodes using keyboard shortcuts
  multiSelectionActive: boolean;

  // Whether we should fit the view of the canvas (relative to nodes) on canvas initialization
  fitViewOnInit: boolean;
  fitViewOnInitDone: boolean;
  fitViewOnInitOptions: FitViewOptions | undefined;

  // node origin is the origin of the node (top left, center, bottom left, etc). It is used for bounding box calculations
  nodeOrigin: NodeOrigin;

  translateExtent: CoordinateExtent;

  /* ZOOM RELATED */
  /* Transform is the current transform relative to the initial viewport, including depth (zoom)
   * This is represented as [relativeX, relativeY, relativeZoomLevel] */
  transform: Transform;
  d3Zoom: ZoomBehavior<Element, unknown> | null;
  d3Selection: D3Selection<Element, unknown, null, undefined> | null;
  d3ZoomHandler: ((this: Element, event: any, d: unknown) => void) | undefined;
  containerPaneElement: HTMLDivElement | null;

  // The minimum and maximum zoom level
  minZoom: number;
  maxZoom: number;

  /* ZOOM RELATED FUNCTIONS */
  setMinZoom: (minZoom: number) => void;
  setMaxZoom: (maxZoom: number) => void;

  /* CANVAS RELATED FUNCTIONS */
  panBy: (delta: XYPosition) => void;

  snapGrid: SnapGrid;
  snapToGrid: boolean;
};

export type CanvasActions = {
  setTranslateExtent: (translateExtent: CoordinateExtent) => void;
};

export type CanvasSlice = CanvasState & CanvasActions;

export const createCanvasSlice: StateSlice<CanvasSlice> = (set, get) => ({
  disableNodesInteraction: false,
  userSelectionActive: false,
  userSelectionRect: null,
  nodesSelectionActive: false,
  paneDragging: false,
  autoPanOnNodeDrag: true,
  width: 0,
  height: 0,

  multiSelectionActive: true,

  fitViewOnInit: false,
  fitViewOnInitDone: false,
  fitViewOnInitOptions: undefined,

  nodeOrigin: { x: 0, y: 0 },

  translateExtent: infiniteExtent,

  snapToGrid: true,
  snapGrid: {
    x: 10,
    y: 10,
  },

  /* ZOOM RELATED */
  minZoom: 0.5,
  maxZoom: 2,
  transform: {
    x: 0,
    y: 0,
    zoom: 1,
  },
  d3Zoom: null,
  d3Selection: null,
  d3ZoomHandler: undefined,
  containerPaneElement: null,

  setTranslateExtent: (translateExtent: CoordinateExtent) => {
    get().d3Zoom?.translateExtent(translateExtent);

    set({ translateExtent });
  },

  /* ZOOM RELATED FUNCTIONS */
  setMinZoom: (minZoom) => {
    const { d3Zoom, maxZoom } = get();
    d3Zoom?.scaleExtent([minZoom, maxZoom]);

    set({ minZoom });
  },
  setMaxZoom: (maxZoom) => {
    const { d3Zoom, minZoom } = get();
    d3Zoom?.scaleExtent([minZoom, maxZoom]);

    set({ maxZoom });
  },
  panBy: (delta) => {
    const { transform, width, height, d3Zoom, d3Selection, translateExtent } =
      get();
    if (!d3Zoom || !d3Selection || (!delta.x && !delta.y)) {
      return;
    }

    const nextTransform = zoomIdentity
      .translate(transform.x + delta.x, transform.y + delta.y)
      .scale(transform.zoom);

    const extent: CoordinateExtent = [
      [0, 0],
      [width, height],
    ];

    const constrainedTransform = d3Zoom?.constrain()(
      nextTransform,
      extent,
      translateExtent
    );

    d3Zoom.transform(d3Selection, constrainedTransform);
  },
});
