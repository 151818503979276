import { FC, useCallback, useMemo } from 'react';
import { CreateActionButton } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { useCreateTokenForm } from '@jux/ui/components/tokens/token-drawer/forms/createEditHooks';
import { useTokens } from '@jux/ui/hooks';
import { generateName } from '@jux/ui/utils/generateName';
import {
  useCreateGroup,
  useDeleteToken,
  useEditToken,
  useRenameToken,
} from '../hooks';
import { useSelectedTokenState } from '../state';
import { CreateNewTokenCard } from './CreateNewTokenCard';
import { TokenGroupContentWrapper } from './TokenGroupContentWrapper.style';
import { TokenGroups } from './TokenGroups';
import { TokenCard } from './tokenCard/TokenCard';

export const TokenSet: FC = () => {
  const { tokenSetDataByType } = useTokens();
  const { isTokenSelected } = useSelectedTokenState();
  const { createGroup } = useCreateGroup();
  const { editToken } = useEditToken();
  const { renameToken } = useRenameToken();
  const { deleteToken } = useDeleteToken();

  const { groups, tokens, path } = tokenSetDataByType ?? {};
  const hasGroupData = Boolean(groups?.length);
  const groupPath = path ?? '';

  const groupNames = useMemo(
    () => groups?.map((group) => group.name) ?? [],
    [groups]
  );

  const showCreateFirstGroup = !hasGroupData;

  const handleCreateGroup = useCallback(() => {
    const generatedName = generateName({
      baseName: 'new_group',
      namesArray: groupNames,
      separator: '_',
    });

    createGroup({ newGroupName: generatedName, groupPath });
  }, [createGroup, groupNames, groupPath]);

  const existingTokenNamesInGroup = useMemo(
    () => tokens?.map((token) => token.name) ?? [],
    [tokens]
  );

  const { openCreateTokenForm } = useCreateTokenForm();

  return (
    <MuiGrid container gap="28px">
      {showCreateFirstGroup && (
        <MuiGrid container item>
          <CreateActionButton
            label="Create your first group"
            icon="ADD"
            onClick={handleCreateGroup}
          />
        </MuiGrid>
      )}

      {hasGroupData && (
        <MuiGrid container item>
          <TokenGroups groups={groups ?? []} rootPath={groupPath} />
        </MuiGrid>
      )}

      <TokenGroupContentWrapper
        sx={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '24px',
        }}
      >
        {tokens?.map((token) => (
          <TokenCard
            key={token.path}
            {...token}
            isSelected={isTokenSelected(token.path)}
            onEditToken={() => editToken(token)}
            onRenameToken={(newName) =>
              renameToken({ newName, path: token.path })
            }
            existingTokenNamesInGroup={existingTokenNamesInGroup}
            onDeleteToken={() =>
              deleteToken({ tokenName: token.name, groupPath })
            }
          />
        ))}

        <CreateNewTokenCard
          onClick={() => openCreateTokenForm({ groupPath })}
        />
      </TokenGroupContentWrapper>
    </MuiGrid>
  );
};
