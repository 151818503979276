import { useCallback, useMemo } from 'react';
import {
  selectNodeInstances,
  useStore,
  useStoreActions,
} from '@jux/canjux/core';

export const useDeleteAsset = (assetId: string) => {
  const componentInstances = useStore(selectNodeInstances(assetId));

  const {
    assetsActions: { deleteAsset },
  } = useStoreActions();

  const isDeletable = useMemo(
    () => componentInstances.length === 0,
    [componentInstances]
  );

  const handleAssetDelete = useCallback(() => {
    if (!isDeletable) return;

    deleteAsset({ assetId });
  }, [assetId, deleteAsset, isDeletable]);

  return {
    isDeletable,
    handleAssetDelete,
  };
};
