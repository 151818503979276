import {
  CommonActionsParams,
  JuxStore,
  JuxStoreActionFn,
} from '@jux/canjux/core';

/**
 * Update a node's position
 */
export const updateNodePosition: JuxStoreActionFn<
  CommonActionsParams['updateNodePosition'],
  JuxStore
> = ({ payload, state }) => {
  if (!payload.length) {
    return state;
  }

  for (const { nodeId, position } of payload) {
    state.canvases[state.currentCanvasName].nodes[nodeId].position = position;
  }

  return state;
};
