import { useCallback } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import { useTokenSetState } from '@jux/ui/state';

export const useDeleteGroup = () => {
  const { currentTokenSetId } = useTokenSetState();
  const {
    tokenSetsActions: { deleteTokenOrGroup },
  } = useStoreActions();

  const deleteGroup = useCallback(
    (groupPath: string) => {
      if (!currentTokenSetId) return;

      deleteTokenOrGroup({
        path: groupPath,
      });
    },
    [currentTokenSetId, deleteTokenOrGroup]
  );

  return {
    deleteGroup,
  };
};
