import { FC, PropsWithChildren } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { IconButtonProps, IconButton } from '@jux/ui/components';
import {
  MuiClickAwayListener,
  MuiGrow,
  MuiMenuList,
  MuiPaper,
  MuiPopper,
  PaperProps,
  PopperProps,
} from '@jux/ui/components/common/mui';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { useMenuList } from './useMenuList';

type MenuListProps = {
  variant?: IconButtonProps['variant'];
  size?: IconButtonProps['size'];
  optionsButtonProps?: Exclude<IconButtonProps, 'variant' | 'size'>;
  popperPlacement?: PopperProps['placement'];
  popperProps?: Partial<PopperProps>;
  paperProps?: PaperProps;
  stopClickPropagation?: boolean;
  isVisible?: boolean;
};

export const MenuList: FC<PropsWithChildren<MenuListProps>> = ({
  children,
  variant = 'icon',
  size = 'medium',
  optionsButtonProps,
  popperPlacement,
  popperProps,
  paperProps,
  stopClickPropagation,
  isVisible = true,
}) => {
  const { open, anchorRef, handleClose, handleToggle } = useMenuList();

  const {
    ref: optionsButtonRef,
    iconProps: optionsButtonIconProps,
    tooltipTitle,
    wrapperProps,
    ...optionsButtonOtherProps
  } = optionsButtonProps || {};

  const { sx: popperSx, ...popperOtherProps } = popperProps || {};

  return (
    <>
      {(isVisible || open) && (
        <IconButton
          variant={variant}
          size={size}
          // TODO: create wrapper around Button instead of using this prop
          wrapperProps={{
            ...mergeTyped(
              {
                item: true,
                display: 'contents',
              },
              wrapperProps
            ),
          }}
          tooltipTitle={open ? undefined : tooltipTitle ?? 'Options'}
          onClick={(e) => {
            if (stopClickPropagation) {
              e.stopPropagation();
            }

            handleToggle();
          }}
          icon="DOTS_MENU_HORIZONTAL"
          ref={mergeRefs([anchorRef, optionsButtonRef])}
          {...optionsButtonOtherProps}
        />
      )}
      <MuiPopper
        sx={mergeTyped(
          {
            zIndex: 1,
            minWidth: anchorRef.current?.clientWidth,
          },
          popperSx
        )}
        open={open}
        anchorEl={anchorRef.current}
        placement={popperPlacement ?? 'bottom'}
        role={undefined}
        transition
        disablePortal
        {...popperOtherProps}
      >
        {({ TransitionProps }) => (
          <MuiGrow {...TransitionProps}>
            <MuiPaper
              {...paperProps}
              sx={mergeTyped(
                {
                  background: (theme) => theme.drimz.palette.background.default,
                  borderRadius: (theme) => theme.drimz.size.borderRadius.small,
                  overflow: 'hidden',
                  minWidth: 128,
                  border: '1px solid',
                  borderColor: (theme) => theme.drimz.palette.divider.primary,
                  boxShadow: (theme) => theme.drimz.shadow.common.menuList,
                },
                paperProps?.sx
              )}
            >
              <MuiClickAwayListener onClickAway={handleClose}>
                <MuiMenuList
                  onClick={(e) => {
                    if (stopClickPropagation) {
                      e.stopPropagation();
                    }

                    handleClose();
                  }}
                  autoFocusItem
                  sx={{
                    m: 0.5,
                  }}
                >
                  {children}
                </MuiMenuList>
              </MuiClickAwayListener>
            </MuiPaper>
          </MuiGrow>
        )}
      </MuiPopper>
    </>
  );
};
