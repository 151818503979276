import { useCallback, useMemo } from 'react';
import deepmerge from 'deepmerge';
import { PanelsKeysType, PanelState, usePanelsState } from '../state';

export const usePanelStateByKey = (panelKey: PanelsKeysType) => {
  const [panelsState, setPanelsState] = usePanelsState();

  const updatePanelState = useCallback(
    (panel: PanelsKeysType, state: Partial<PanelState>) => {
      setPanelsState((prev) => ({
        ...prev,
        [panel]: deepmerge(prev[panel], state),
      }));
    },
    [setPanelsState]
  );

  const { shouldRender } = useMemo(
    () => panelsState[panelKey],
    [panelKey, panelsState]
  );

  const toggleShouldRenderPanel = useCallback(
    (panel: PanelsKeysType) => {
      setPanelsState((prev) => ({
        ...prev,
        [panel]: {
          ...prev[panel],
          shouldRender: !prev[panel].shouldRender,
        },
      }));
    },
    [setPanelsState]
  );

  const togglePanel = useCallback(
    () => toggleShouldRenderPanel(panelKey),
    [panelKey, toggleShouldRenderPanel]
  );

  const openPanel = useCallback(
    () => updatePanelState(panelKey, { shouldRender: true }),
    [panelKey, updatePanelState]
  );

  const closePanel = useCallback(
    () => updatePanelState(panelKey, { shouldRender: false }),
    [panelKey, updatePanelState]
  );

  return {
    shouldRender,
    togglePanel,
    openPanel,
    closePanel,
  };
};
