import { useCallback } from 'react';
import { useModal } from '../base-hooks';
import { ThemeSwitcher, ThemeSwitcherProps } from './ThemeSwitcher';

export const useThemeSwitcherModal = () => {
  const { open, close } = useModal();

  const openThemeSwitcherModal = useCallback(
    ({ onChange }: Pick<ThemeSwitcherProps, 'onChange'>) => {
      open({
        handlers: {
          onClose: close,
        },
        content: <ThemeSwitcher onChange={onChange} onClose={close} />,
      });
    },
    [close, open]
  );

  return {
    openThemeSwitcherModal,
  };
};
