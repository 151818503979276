import { forwardRef } from 'react';
import {
  COMPONENT_TAG_NAME,
  ComponentProps,
  ComponentTagName,
} from '@jux/data-entities';
import { HOC } from '@jux/ui/hocs/HOC.interface';
import { ToggleLiveMode } from './ToggleLiveMode';
import { RadioLiveMode } from './RadioLiveMode';
import { ChipLiveMode } from './ChipLiveMode';
import { InputLiveMode } from './InputLiveMode';
import { TextareaLiveMode } from './TextareaLiveMode';

export const liveModeWrapperHOC: HOC<{
  tagName: ComponentTagName;
}> = (Component, options) =>
  forwardRef<HTMLElement, ComponentProps>((props, ref) => {
    switch (options?.tagName) {
      case COMPONENT_TAG_NAME.JuxToggle:
        return (
          <ToggleLiveMode {...(props as any)} Component={Component} ref={ref} />
        );
      case COMPONENT_TAG_NAME.JuxRadio:
        return (
          <RadioLiveMode {...(props as any)} Component={Component} ref={ref} />
        );
      case COMPONENT_TAG_NAME.JuxChip:
        return (
          <ChipLiveMode {...(props as any)} Component={Component} ref={ref} />
        );
      case COMPONENT_TAG_NAME.JuxInput:
        return (
          <InputLiveMode {...(props as any)} Component={Component} ref={ref} />
        );
      case COMPONENT_TAG_NAME.JuxTextarea:
        return (
          <TextareaLiveMode
            {...(props as any)}
            Component={Component}
            ref={ref}
          />
        );
      default:
        // TODO: add more cases
        return <Component {...(props as any)} ref={ref} />;
    }
  }) as any;
