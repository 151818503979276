/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const MaxWidthDDPIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M2 5.25C2.41421 5.25 2.75 5.58579 2.75 6V10C2.75 10.4142 2.41421 10.75 2 10.75C1.58579 10.75 1.25 10.4142 1.25 10V6C1.25 5.58579 1.58579 5.25 2 5.25ZM4.5 5.25C4.91421 5.25 5.25 5.58579 5.25 6V7.25H10.75V6C10.75 5.58579 11.0858 5.25 11.5 5.25C11.9142 5.25 12.25 5.58579 12.25 6V10C12.25 10.4142 11.9142 10.75 11.5 10.75C11.0858 10.75 10.75 10.4142 10.75 10V8.75H5.25V10C5.25 10.4142 4.91421 10.75 4.5 10.75C4.08579 10.75 3.75 10.4142 3.75 10V6C3.75 5.58579 4.08579 5.25 4.5 5.25ZM14 5.25C14.4142 5.25 14.75 5.58579 14.75 6V10C14.75 10.4142 14.4142 10.75 14 10.75C13.5858 10.75 13.25 10.4142 13.25 10V6C13.25 5.58579 13.5858 5.25 14 5.25Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
