import {
  ComponentActionsParams,
  JuxStore,
  JuxStoreActionFn,
} from '../store.interface';
import type { Draft as WritableDraft } from 'mutative';
import { CanjuxState } from '@jux/canjux/core';

const recursiveDelete = ({
  state,
  componentId,
}: {
  state: WritableDraft<JuxStore>;
  componentId: string;
}) => {
  const nodeComponentData = state.components[componentId];
  for (const child of nodeComponentData.children) {
    recursiveDelete({ state, componentId: child });
  }
  delete state.components[componentId];
};
/**
 * Delete a node, its component data, and its children.
 */
export const deleteComponent: JuxStoreActionFn<
  ComponentActionsParams['deleteComponent'],
  CanjuxState
> = ({ componentId, state }) => {
  recursiveDelete({ state, componentId });
  return state;
};
