import { useCallback, useEffect, useRef } from 'react';
import { storeApi, useStore } from '@jux/canjux/core';
import { LayersPanelItem } from '@jux/ui/components/editor/components/panels/layers/state/getLayersItemsRecursive';
import { getItemAncestorPaths } from './helpers';
import { useSetLayersPanelState } from './useSetLayersPanelState';

// This hook is used to expand the layers panel to the clicked node on canvas
export const useExpandToLayerOnCanvasNodeClick = ({
  items,
}: {
  items: Map<string, LayersPanelItem>;
}) => {
  const setState = useSetLayersPanelState();

  const selectedNodesStackTimestamp = useStore(
    (state) => state.selectedNodesStackTimestamp
  );

  const timestampRef = useRef(selectedNodesStackTimestamp);

  const expandToNodeId = useCallback(
    (nodeId: string) => {
      const item = items.get(nodeId);

      if (!item) return;

      const ancestorPaths = getItemAncestorPaths(item);

      if (ancestorPaths.length === 0) return;

      setState((prev) => ({
        ...prev,
        expandedPaths: new Set([...prev.expandedPaths, ...ancestorPaths]),
      }));
    },
    [items, setState]
  );

  // TODO: Use store callback/event instead of this effect
  useEffect(() => {
    if (selectedNodesStackTimestamp !== timestampRef.current) {
      timestampRef.current = selectedNodesStackTimestamp;
      const { selectedNodesStack } = storeApi.getState();
      for (const nodeId of selectedNodesStack) {
        expandToNodeId(nodeId);
      }
    }
  }, [expandToNodeId, selectedNodesStackTimestamp]);
};
