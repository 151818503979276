/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const FontFamilyDDPIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M3 13L4.80425 3H12L11.6816 4.74316H6.78361L6.35908 7.12598H9.84552L9.52712 8.86914H6.04068L5.29776 13H3Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
