import { CanvasData } from '@jux/data-entities';
import { v4 as uuidv4 } from 'uuid';

export const createCanvasData = (name = 'default'): CanvasData => ({
  id: uuidv4(),
  createdAt: new Date().toISOString(),
  name,
  nodes: {},
  rootNodesOrder: [],
});
