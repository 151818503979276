/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconType, IconBaseProps } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const CheckboxElementIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M11.3687 6.61872C11.7104 6.27701 11.7104 5.72299 11.3687 5.38128C11.027 5.03957 10.473 5.03957 10.1313 5.38128L7.25 8.26256L6.36872 7.38128C6.02701 7.03957 5.47299 7.03957 5.13128 7.38128C4.78957 7.72299 4.78957 8.27701 5.13128 8.61872L7.25 10.7374L11.3687 6.61872Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M2 3C2 2.44772 2.44772 2 3 2H13C13.5523 2 14 2.44772 14 3V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V3ZM3.5 12.5V3.5H12.5V12.5H3.5Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
