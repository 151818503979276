import { useState, useCallback } from 'react';

export const useTokensPopper = () => {
  const [showTokens, setShowTokens] = useState(false);

  const closeTokens = useCallback(() => {
    setShowTokens(false);
  }, [setShowTokens]);

  const openTokens = useCallback(() => {
    setShowTokens(true);
  }, []);

  return {
    isTokensOpen: showTokens,
    isTokensClose: !showTokens,
    closeTokens,
    openTokens,
  };
};

export type TokensPopperProps = ReturnType<typeof useTokensPopper>;
