import { atom, useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';

const nodesStateAtom = atom<Record<string, boolean | undefined>>({});

// On by default, unless explicitly set to false
export const useNodeParentContextState = (nodeId = '') => {
  const [parentContextState, setParentContextState] = useAtom(nodesStateAtom);

  const isNodeParentContextOn = useMemo(
    () =>
      // if nodeId is not in the parentContextState, it is on by default
      nodeId in parentContextState ? parentContextState[nodeId] === true : true,
    [nodeId, parentContextState]
  );

  const getIsNodeParentContextOnById = useCallback(
    (id: string) =>
      // if nodeId is not in the parentContextState, it is on by default
      id in parentContextState ? parentContextState[id] === true : true,
    [parentContextState]
  );

  const setNodeParentContextOn = useCallback(
    (value: boolean) =>
      setParentContextState((prev) => ({ ...prev, [nodeId]: value })),
    [nodeId, setParentContextState]
  );

  return {
    isNodeParentContextOn,
    setNodeParentContextOn,
    getIsNodeParentContextOnById,
  };
};
