/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const JustifyContentSpaceBetweenRowDDPIcon: IconType = (
  props: IconBaseProps
) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M2.5 2C2.22386 2 2 2.22386 2 2.5V13.5C2 13.7761 2.22386 14 2.5 14C2.77614 14 3 13.7761 3 13.5V2.5C3 2.22386 2.77614 2 2.5 2Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M13.5 2C13.2239 2 13 2.22386 13 2.5V13.5C13 13.7761 13.2239 14 13.5 14C13.7761 14 14 13.7761 14 13.5V2.5C14 2.22386 13.7761 2 13.5 2Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M12 10C12 10.5523 11.5523 11 11 11C10.4477 11 10 10.5523 10 10L10 6C10 5.44772 10.4477 5 11 5C11.5523 5 12 5.44772 12 6L12 10Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M5 11C5.55228 11 6 10.5523 6 10L6 6C6 5.44772 5.55228 5 5 5C4.44772 5 4 5.44772 4 6L4 10C4 10.5523 4.44772 11 5 11Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
