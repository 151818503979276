import { DragEventHandler, MouseEventHandler } from 'react';
import { MuiGrid, styled } from '@jux/ui/components/common/mui';
import { getObjectNavigatorItemStyle } from './helpers';

const HIDDEN_ITEM_OPACITY = 0.6;

export type ObjectNavigatorItemWrapperProps = {
  indentLevel: number;
  isHovered: boolean;
  isHidden: boolean;
  isSelected: boolean;
  isDraggedInside: boolean;
  isLibraryComponent: boolean;
  hasAncestorLibraryComponent: boolean;
  hasAncestorSelected: boolean;
  isLogicalSlot: boolean;
  onClick: MouseEventHandler<HTMLElement>;
  onMouseEnter: MouseEventHandler<HTMLElement>;
  onMouseLeave: MouseEventHandler<HTMLElement>;
  onDragOver?: DragEventHandler<HTMLElement>;
  onDragStart?: DragEventHandler<HTMLElement>;
  onDragEnd?: DragEventHandler<HTMLElement>;
  onDrop?: DragEventHandler<HTMLElement>;
};

export const ObjectNavigatorItemWrapper = styled(MuiGrid, {
  shouldForwardProp: (prop: string) =>
    ![
      'indentLevel',
      'isHovered',
      'isSelected',
      'isDraggedInside',
      'isHidden',
      'isLibraryComponent',
      'hasAncestorLibraryComponent',
      'hasAncestorSelected',
      'isLogicalSlot',
    ].includes(prop),
})<ObjectNavigatorItemWrapperProps>(
  ({
    indentLevel,
    hasAncestorSelected,
    hasAncestorLibraryComponent,
    isHovered,
    isSelected,
    isDraggedInside,
    isHidden,
    isLibraryComponent,
    isLogicalSlot,
    theme,
  }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '4px',
    flexWrap: 'nowrap',
    cursor: 'default',
    userSelect: 'none',
    border: '1px solid',

    opacity: isHidden ? HIDDEN_ITEM_OPACITY : 1,
    height: theme.drimz.size.editor.layers.objectNavigator.item.height,

    ...getObjectNavigatorItemStyle({
      indentLevel,
      isSelected,
      hasAncestorSelected,
      isHovered,
      isLibraryComponent,
      isLogicalSlot,
      isDraggedInside,
      hasAncestorLibraryComponent,
    }),
  })
);
