import { useCallback } from 'react';
import { toast } from '@jux/ui/toast';
import { useTokenDrawer } from '@jux/ui/components/tokens/token-drawer/useTokenDrawer';

export const useTokenDrawerSideEffects = () => {
  const { close } = useTokenDrawer();

  const onSave = useCallback(
    (toastMessage: string) => {
      close();
      toast.success(toastMessage);
    },
    [close]
  );

  return { onSave };
};
