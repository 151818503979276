import { round } from 'lodash';
import logger from '@jux/ui-logger';
import {
  NumericFieldOptions,
  NumericFieldValue,
  SupportedNumericBaseUnits,
} from './useNumericField.interface';

export const DEFAULT_OPTIONS: Required<NumericFieldOptions> = {
  baseUnit: SupportedNumericBaseUnits.None,
  saveAsDecimal: false,
  min: undefined as unknown as number, // we need to set this to undefined to avoid the default value of 0
  max: undefined as unknown as number, // we need to set this to undefined to avoid the default value of 0
  defaultValue: '0',
  fallbackValue: '0',
  // supportTokens: true // TODO: add support for disabling tokens
};

const isNone = (baseUnit: SupportedNumericBaseUnits) =>
  baseUnit === SupportedNumericBaseUnits.None;

/**
 * Parse the number value from the numeric field.
 * Rounding the value to the nearest whole number.
 * @param number - e.g. 0% | 100% | 50 | 12.2343 | 12.5123
 * @returns number - e.g. 0 | 100 | 50 | 12 | 13
 */
export const parseNumber = (number: string | number): number =>
  round(parseFloat(String(number)));

/**
 * Get numeric value with the base unit
 * @param number - e.g. 0 | 100 | 50 | 12 | 13
 * @param baseUnit - e.g. '%' | ''
 * @returns string - e.g. '0%' | '100' | '50' | '12%' | '13%'
 */
export const parseNumberWithBaseUnit = (
  number: string | number,
  options: NumericFieldOptions
): string => {
  const { baseUnit } = {
    ...DEFAULT_OPTIONS,
    ...options,
  };

  return `${parseNumber(number)}${baseUnit}`;
};

const convertDecimalToNumber = (value: number): number => value * 100;

const convertNumberToDecimal = (value: number): number => value / 100;

/**
 * Parse a valid numeric css value into the field to a value between 0-100 with % sign
 * @param value - e.g. 100% | 1% | 1 | 0.1
 * @returns string - e.g. '100%' | '0.1%' | '1%'
 */
export const parseIntoField = (
  value: string | number,
  options: NumericFieldOptions
): NumericFieldValue => {
  const { baseUnit, defaultValue, saveAsDecimal, fallbackValue } = {
    ...DEFAULT_OPTIONS,
    ...options,
  };

  if (!value) {
    return defaultValue;
  }

  const val = String(value);

  // if the base unit is not None & the value is already in unit we don't need to convert it
  if (!isNone(baseUnit) && val.includes(baseUnit)) {
    return val;
  }

  // if the value is a decimal we need to convert it to percent
  // e.g. 0.1 -> 10%

  const parsedValue = parseFloat(val);

  if (isNaN(parsedValue)) {
    logger.warn('Numeric value is not a number');
    return fallbackValue;
  }

  return parseNumberWithBaseUnit(
    saveAsDecimal ? convertDecimalToNumber(parsedValue) : parsedValue,
    options
  );
};

/**
 * parse the numeric value from the field to savable css value
 * e.g. 100% -> '1', 0.1% -> '0.001', 1% -> '0.01'
 * @param value - e.g. 100% | 0.1% | 1%
 * @returns string - e.g. '1' | '0.001' | '0.01'
 */
export const parseFromField = (
  value: NumericFieldValue,
  options: NumericFieldOptions
): string => {
  const { min, max, fallbackValue, saveAsDecimal } = {
    ...DEFAULT_OPTIONS,
    ...options,
  };

  if (!value) {
    logger.warn('Numeric value is empty');
    return fallbackValue;
  }

  let numberFromField = parseNumber(value);

  if (isNaN(numberFromField)) {
    logger.warn('Numeric value is not a number');
    return fallbackValue;
  }

  if (min !== undefined && numberFromField < min) numberFromField = min;
  if (max !== undefined && numberFromField > max) numberFromField = max;

  if (saveAsDecimal) {
    return convertNumberToDecimal(numberFromField).toString();
  }

  return numberFromField.toString();
};
