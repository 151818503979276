import { CanjuxState } from '@jux/canjux/core';
import { getNodeComponentById } from './getNodeComponentById';
import { getResolvedSourceComponentData } from './getResolvedSourceComponentData';

export const getSourceComponentById = ({
  id,
  components,
}: {
  id?: string;
  components: CanjuxState['components'];
}) => {
  const component = getNodeComponentById({ id, components });
  if (!component) return undefined;

  return getResolvedSourceComponentData({
    component,
    components,
  });
};
