import { useCallback, useMemo } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import { DesignTokenTypeEnum } from '@jux/design-tokens';
import { CORE } from '@jux/types';
import { usePopupState } from '@jux/ui/components/common/mui';
import { colorTokenFormSchema } from '@jux/ui/components/tokens/token-drawer/forms/colorTokenForm/ColorTokenForm.interface';
import {
  colorTokenValueParser,
  parseColorPickerValueToFormValue,
  parseFormValueToColorPickerValue,
} from '@jux/ui/components/tokens/token-drawer/forms/colorTokenForm/ColorTokenForm.utils';
import {
  COLOR_PATH_PREFIX,
  formatAliasPath,
  GetValueFn,
  useFormValueAlias,
  useRegisterFieldFns,
  useValidateTokenName,
} from '@jux/ui/components/tokens/token-drawer/forms/helpers';
import { TokenFormProps } from '@jux/ui/components/tokens/token-drawer/forms/types';
import { useTokenDrawer } from '@jux/ui/components/tokens/token-drawer/useTokenDrawer';
import { getUpdatedTokenData } from '@jux/ui/components/tokens/token-drawer/utils/getUpdatedTokenData';
import { Floors, useOverlaysZIndex } from '@jux/ui/hooks';
import { useZodForm } from '@jux/ui/hooks/useZodForm';
import { colorTokenInputSchema } from '@jux/ui/trpc/validations';
import { createPath } from '@jux/ui/utils/tokensPath';

export const useColorTokenForm = ({
  initialValues,
  existingTokenPaths = [],
  existingCoreTokenPaths = [],
  valuesMap,
  isCoreTokenSet,
}: TokenFormProps<typeof colorTokenFormSchema>) => {
  const {
    tokenSetsActions: { setToken },
  } = useStoreActions();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'color-token-form-picker-popover',
  });

  const colorPickerPopperZIndex = useOverlaysZIndex({
    id: 'popper',
    floor: Floors.poppers,
  });

  const form = useZodForm({
    schema: colorTokenFormSchema,
    defaultValues: initialValues,
  });

  const { handlers } = useTokenDrawer();

  const handleColorChange = (newHex: string) => {
    const newColorPickerRgba = parseFormValueToColorPickerValue(
      newHex,
      form.getValues().value.opacity
    );

    form.setValue('value.colorPickerRgba', newColorPickerRgba);

    if (newColorPickerRgba)
      handlers.onChange?.({
        newValue: newColorPickerRgba,
      });
  };

  const handleOpacityChange = (newOpacity: number) => {
    const newColorPickerRgba = parseFormValueToColorPickerValue(
      form.getValues().value.color,
      newOpacity
    );

    form.setValue('value.colorPickerRgba', newColorPickerRgba);

    if (newColorPickerRgba)
      handlers.onChange?.({
        newValue: newColorPickerRgba,
      });
  };

  const [
    registerTokenNameField,
    registerGroupPathField,
    registerAliasField,
    registerValueColorField,
    registerValueOpacityField,
    registerDescriptionField,
  ] = useRegisterFieldFns(form, [
    'tokenName',
    'groupPath',
    'alias',
    {
      'value.color': {
        maxLength: 6,
        onChange: (e) => handleColorChange(e.target.value),
      },
    },
    {
      'value.opacity': {
        valueAsNumber: true,
        maxLength: 3,
        onChange: (e) => handleOpacityChange(e.target.value),
      },
    },
    'description',
  ]);

  // need to have controlled fields to support aliases.
  // but we're not using useInitialValues() here,
  // because it doesn't play well with the color picker.
  const [colorValue, opacityValue, colorPickerRgbaValue] = [
    form.getValues().value.color,
    form.getValues().value.opacity,
    form.getValues().value.colorPickerRgba,
  ];

  const getValue: GetValueFn = useCallback(
    (valuePath) =>
      colorTokenValueParser.stringToObject.parse(valuesMap[valuePath]),
    [valuesMap]
  );

  const existingColorTokens = useMemo(() => {
    if (isCoreTokenSet) {
      return existingCoreTokenPaths.filter((p) =>
        p.startsWith(createPath([CORE, COLOR_PATH_PREFIX]))
      );
    }

    return existingTokenPaths.filter((p) => p.startsWith(COLOR_PATH_PREFIX));
  }, [existingCoreTokenPaths, existingTokenPaths, isCoreTokenSet]);

  const existingCoreColorTokens = useMemo(
    () =>
      existingCoreTokenPaths.filter((p) =>
        p.startsWith(createPath([CORE, COLOR_PATH_PREFIX]))
      ),
    [existingCoreTokenPaths]
  );

  const { aliasOptionsProps, aliasValue, isAliasToken, hasAliases } =
    useFormValueAlias<typeof form, typeof colorTokenInputSchema>({
      form,
      getValue,
      existingTokenPaths: existingColorTokens,
      existingCoreTokenPaths: existingCoreColorTokens,
      isCoreTokenSet,
    });

  const validateTokenName = useValidateTokenName({
    form,
    existingTokenPaths: existingColorTokens,
  });

  const handleSubmit = useMemo(
    () =>
      form.handleSubmit((values) => {
        if (validateTokenName()) {
          setToken({
            data: {
              ...values,
              alias: formatAliasPath(values.alias),
              // sending the value in our own format
              isCore: isCoreTokenSet,
              value: colorTokenValueParser.objectToString.parse(values.value),
            },
            type: DesignTokenTypeEnum.color,
          });

          handlers.onSave(
            getUpdatedTokenData({
              tokenPath: createPath([values.groupPath, values.tokenName]),
              tokenSetId: values.tokenSetId,
            })
          );
        }
      }, validateTokenName),
    [form, validateTokenName, setToken, isCoreTokenSet, handlers]
  );

  const handleColorPickerChange = useCallback(
    (newColorPickerRgba: string, isChangeComplete: boolean) => {
      // we are not using 'colorTokenValueParser.stringToObject.parse' here,
      // because it doesn't work for rgba(0, 0, 0, 100) colors in its regexp
      if (isChangeComplete) {
        const { color, opacity } =
          parseColorPickerValueToFormValue(newColorPickerRgba);

        form.setValue('value.colorPickerRgba', newColorPickerRgba);
        form.setValue('value.color', color);
        form.setValue('value.opacity', parseFloat(opacity.toFixed(2)));
      }

      handlers.onChange?.({
        newValue: newColorPickerRgba,
      });
    },
    [form, handlers]
  );

  return {
    form,
    handleSubmit,
    registerTokenNameField,
    registerGroupPathField,
    registerAliasField,
    registerValueColorField,
    registerValueOpacityField,
    registerDescriptionField,
    popupState,
    handleColorPickerChange,
    aliasValue,
    aliasOptionsProps,
    hasAliases,
    isAliasToken,
    colorValue,
    opacityValue,
    colorPickerRgbaValue,
    colorPickerPopperZIndex,
  };
};
