import type { Draft as WritableDraft } from 'mutative';
import { JuxStore } from '@jux/canjux/core';
import { JuxComponentData } from '@jux/data-entities';

export const findInstancesOfInstances = ({
  sourceNodeId,
  components,
}: {
  sourceNodeId: string;
  components: WritableDraft<JuxStore['components']>;
}) => {
  const instances: WritableDraft<JuxComponentData>[] = [];
  const componentInstances = Object.values(components).filter(
    (component) => component?.sourceComponentId === sourceNodeId
  );
  for (const instance of componentInstances) {
    instances.push(
      instance,
      ...findInstancesOfInstances({ sourceNodeId: instance.id, components })
    );
  }
  return instances;
};
