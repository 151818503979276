import { TokenSetData } from '@jux/data-entities';
import { getDefaultStructuredTokenSet } from '@jux/design-tokens';
import { CORE, CORE_TOKEN_SET_NAME } from '@jux/types';
import { create, type Draft as WritableDraft } from 'mutative';
import cloneDeep from 'lodash/cloneDeep';
import { getDesignTokensParserContext } from '../../../store-utils/getDesignTokensParserContext';

const designTokensParser = getDesignTokensParserContext();

export const convertToCoreTokenSet = (
  tokenSet: WritableDraft<TokenSetData>
) => {
  return create(tokenSet, (draft) => {
    draft.name = CORE_TOKEN_SET_NAME;
    draft.value = designTokensParser
      .parse({
        ...getDefaultStructuredTokenSet(),
        [CORE]: cloneDeep(draft.value.core),
      })
      .getRawValueCopy();

    return draft;
  });
};
