import { forwardRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import {
  SelectField,
  GroupedOptions,
  useGroupedOptions,
  typographyVariants,
} from '@jux/ui/components';
import { MuiMenuItem, SelectProps } from '@jux/ui/components/common/mui';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';

export type StyledSelectFieldOptionsProps =
  | {
      groupedOptions?: never;
      options: Array<string>;
    }
  | {
      groupedOptions: GroupedOptions;
      options?: never;
    };

type StyledSelectFieldProps = SelectProps<string> &
  UseFormRegisterReturn &
  StyledSelectFieldOptionsProps & {
    disableMenuPortal?: boolean;
  };

export const StyledSelectField = forwardRef<
  HTMLSelectElement,
  StyledSelectFieldProps
>(
  (
    { options, groupedOptions, disableMenuPortal, MenuProps, ...props },
    ref
  ) => {
    const renderedGroupedOptions = useGroupedOptions({
      groupedOptions,
      renderValue: props.renderValue,
    });

    return (
      <SelectField
        fullWidth
        MenuProps={mergeTyped(
          {
            /* It is necessary to disable the portal in order to prevent the `<TokenDrawer />`'s menu
            from being closed when clicking on the portal element, which might be outside of the form */
            disablePortal: disableMenuPortal,
          },
          MenuProps
        )}
        {...props}
        sx={{
          // error
          '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: (theme) => theme.drimz.palette.error.main,
            },
            '& .MuiInputBase-input': {
              color: (theme) => theme.drimz.palette.error.main,
            },
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: (theme) => theme.drimz.palette.text.disabled,
            },
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: (theme) => theme.drimz.palette.text.secondary,
            },
          },
          '& .MuiInputBase-input': {
            pl: '8px',
            ...typographyVariants['label-s-regular'],
          },
          '& .MuiSelect-select .notranslate::after': {
            content: `"${props.placeholder}"`,
            opacity: 1,
          },
        }}
        ref={ref}
      >
        {renderedGroupedOptions ||
          options?.map((option) => (
            <MuiMenuItem key={option} value={option}>
              {props.renderValue ? props.renderValue(option) : option}
            </MuiMenuItem>
          ))}
      </SelectField>
    );
  }
);
