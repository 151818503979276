import { CanvasData, NodeType } from '@jux/data-entities';
import { CanjuxState } from '../../store';
import { calculateCenterPosition } from '../selectors/utils';
import { getRootNodeOfNode } from './getRootNodeOfNode';
import { isInstanceExistInTree } from './isInstanceExistInTree';
import { getLocalComponentDependencies } from './getLocalComponentDependencies';
import { CREATE_NEW_CANVAS_NODE_DISTANCE } from '../../../constants';

type ComponentPlacement = {
  targetNodeId?: string;
  targetChildIndex?: number;
  position?: { x: number; y: number };
};

const getRootNodeValidPlacement = ({
  originalTargetNodeId,
  components,
  canvasNodesDimensions,
  canvas,
  transform,
  canvasDimensions: { width, height },
}: {
  originalTargetNodeId: string | undefined;
  components: CanjuxState['components'];
  canvasNodesDimensions: CanjuxState['canvasNodesDimensions'];
  canvas: CanvasData;
  transform: CanjuxState['transform'];
  canvasDimensions: { width: number; height: number };
}): ComponentPlacement => {
  const resultPlacement: ComponentPlacement = {
    targetNodeId: undefined,
    targetChildIndex: 0,
    position: calculateCenterPosition({
      container: {
        width,
        height,
        position: {
          x: transform.x,
          y: transform.y,
        },
      },
      zoom: transform.zoom,
      // TODO: calculate the right dimensions
      targetDimensions: { width: 0, height: 0 },
    }),
  };

  if (originalTargetNodeId) {
    // selected target is invalid and no valid parent target node was found.
    // Placing the new node as root node, 80px to the right of the root node of the intended target
    const rootNodeComponent = getRootNodeOfNode({
      components,
      nodeId: originalTargetNodeId,
    });
    const rootNodeWidth =
      canvasNodesDimensions[rootNodeComponent.id]?.width || 0;
    const rootNodePosition = canvas.nodes[rootNodeComponent.id]?.position;

    if (rootNodePosition?.x) {
      resultPlacement.position = {
        x:
          rootNodePosition?.x + rootNodeWidth + CREATE_NEW_CANVAS_NODE_DISTANCE,
        y: rootNodePosition?.y,
      };
    }
  }

  return resultPlacement;
};

/**
 * Get the valid placement for a component on the canvas.
 * If the target node is invalid, the parentId will be set as undefined - root canvas node.
 * Invalid target nodes are:
 * 1. target node is an instance
 * 2. target node root is a component and the new source has an instance of it in its tree
 * This function determines the position by whether the target node is a component or the canvas itself.
 */
export const getValidComponentPlacement = ({
  sourceComponentId,
  targetNodeId,
  components,
  canvas,
  transform,
  canvasDimensions: { width, height },
  canvasNodesDimensions,
}: {
  sourceComponentId?: string;
  targetNodeId?: string;
  components: CanjuxState['components'];
  canvas: CanvasData;
  transform: CanjuxState['transform'];
  canvasDimensions: { width: number; height: number };
  canvasNodesDimensions: CanjuxState['canvasNodesDimensions'];
}): ComponentPlacement => {
  const targetNode = targetNodeId ? canvas.nodes[targetNodeId] : undefined;

  // When duplicating a component we have no target node and want to place it in relation to the original
  if (!targetNode) {
    return getRootNodeValidPlacement({
      originalTargetNodeId: sourceComponentId,
      components,
      canvasNodesDimensions,
      canvas,
      transform,
      canvasDimensions: { width, height },
    });
  }

  const targetNodeComponent = targetNodeId
    ? components[targetNodeId]
    : undefined;
  const isTargetContainer = targetNode?.properties?.isContainer;

  const parentOfParentComponent = targetNodeComponent?.parentId
    ? components[targetNodeComponent?.parentId]
    : undefined;
  const isParentOfParentContainer = parentOfParentComponent
    ? canvas.nodes[parentOfParentComponent.id]?.properties.isContainer
    : false;

  const resultPlacement: ComponentPlacement = {
    targetNodeId,
    targetChildIndex: targetNodeId
      ? targetNodeComponent?.children.length || 0
      : 0,
    position: {
      x: 0,
      y: 0,
    },
  };

  const isOriginalTargetAlsoTheSource = sourceComponentId === targetNodeId;

  // If the user hit copy and then paste on the same node
  if (targetNodeId && isOriginalTargetAlsoTheSource) {
    // If target has a parent that is a container, add it to the parent and not to the target
    if (parentOfParentComponent && isParentOfParentContainer) {
      return {
        targetNodeId: parentOfParentComponent.id,
        targetChildIndex:
          parentOfParentComponent.children.indexOf(targetNodeId) + 1,
        position: {
          x: 0,
          y: 0,
        },
      };
    } else if (isTargetContainer && targetNodeComponent) {
      // If the target is a container and parent is not, put its copy under itself
      return {
        targetNodeId,
        targetChildIndex:
          targetNodeComponent?.children.indexOf(targetNodeId) + 1,
        position: {
          x: 0,
          y: 0,
        },
      };
    } else {
      resultPlacement.targetNodeId = undefined;
    }
  }

  // If the target is not a valid placement position, check if it's parent is
  if (resultPlacement.targetNodeId && !isTargetContainer) {
    if (parentOfParentComponent && isParentOfParentContainer) {
      // Parent of parent is a container, so the parent id should be the parent of parent,
      // and the parent children index should be the index of the target node in the parent of parent children
      resultPlacement.targetChildIndex =
        parentOfParentComponent.children.indexOf(resultPlacement.targetNodeId) +
        1;
      resultPlacement.targetNodeId = parentOfParentComponent.id;
    } else {
      resultPlacement.targetNodeId = undefined;
    }
  }

  // If the source is a component, check that an instance of it can be placed in the target node
  if (resultPlacement.targetNodeId && sourceComponentId) {
    // Check if the target node is a component and the source component has an instance of it in its tree
    const sourceComponentType = components[sourceComponentId]?.type;
    const targetRootNode = getRootNodeOfNode({
      components,
      nodeId: resultPlacement.targetNodeId,
    });
    const isTargetRootNodeAComponent =
      targetRootNode.type === NodeType.LIBRARY_COMPONENT ||
      targetRootNode.type === NodeType.LOCAL_COMPONENT;

    // Do not allow inserting an instance node into its own source component
    // Check if target node is inside a source component, and if the inserted node has in his tree a node
    // that is a component instance of the root node.
    // Note: we have to check this with the internal nodes because we don't want to stop on
    //       the first instance, we want to catch instance inside instance, etc...
    if (
      isTargetRootNodeAComponent &&
      (sourceComponentId === targetRootNode.id ||
        isInstanceExistInTree({
          components,
          nodeId: sourceComponentId,
          sourceComponentNodeId: targetRootNode.id,
        }))
    ) {
      resultPlacement.targetNodeId = undefined;
    }

    // Do not allow adding a component to a library component if it has local component dependencies
    if (targetRootNode.type === NodeType.LIBRARY_COMPONENT) {
      const hasLocalComponentDependencies =
        sourceComponentType === NodeType.LOCAL_COMPONENT ||
        getLocalComponentDependencies({
          componentId: sourceComponentId,
          components: components,
        }).length > 0;
      if (hasLocalComponentDependencies) {
        resultPlacement.targetNodeId = undefined;
      }
    }
  }

  // If no parent id, get the center of the screen
  if (!resultPlacement.targetNodeId) {
    return getRootNodeValidPlacement({
      originalTargetNodeId: targetNodeId,
      components,
      canvasNodesDimensions,
      canvas,
      transform,
      canvasDimensions: { width, height },
    });
  }

  return resultPlacement;
};
