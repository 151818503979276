/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const SearchIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M11.1692 9.76183C12.4584 7.82008 12.2472 5.17614 10.5355 3.46447C8.58291 1.51184 5.41709 1.51184 3.46447 3.46447C1.51184 5.41709 1.51184 8.58291 3.46447 10.5355C5.17614 12.2472 7.82008 12.4584 9.76183 11.1692C9.7826 11.1945 9.80479 11.219 9.82843 11.2426L11.9497 13.364C12.3403 13.7545 12.9734 13.7545 13.364 13.364C13.7545 12.9734 13.7545 12.3403 13.364 11.9497L11.2426 9.82843C11.219 9.80479 11.1945 9.7826 11.1692 9.76183ZM9.12132 4.87868C10.2929 6.05025 10.2929 7.94975 9.12132 9.12132C7.94975 10.2929 6.05025 10.2929 4.87868 9.12132C3.70711 7.94975 3.70711 6.05025 4.87868 4.87868C6.05025 3.70711 7.94975 3.70711 9.12132 4.87868Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
