import { FC } from 'react';
import { LoadingButtonWithIcon as Button } from '@jux/ui/components';
import {
  EDITOR_DDP_FIELD_BORDER_COLOR,
  EDITOR_DDP_FIELD_HOVER_BORDER_COLOR,
  EDITOR_DDP_FIELD_FOCUS_BORDER_COLOR,
  EDITOR_DDP_CHILD_FIELDS_INACTIVE_COLOR,
} from '@jux/ui/theme/palette';
import { withImportant } from '@jux/ui/utils/css';
import { RevertToInitialWithIconProps } from './RevertToInitialWithIcon.interface';

export const RevertToInitialWithIcon: FC<RevertToInitialWithIconProps> = ({
  icon,
  isComputed,
  isFieldDisabled,
  tooltipTitle,
  fieldClassName,
}) => (
  <Button
    icon={icon}
    tooltipTitle={tooltipTitle}
    color={(theme) => theme.drimz.palette.text.secondary}
    iconProps={{
      sx: {
        color:
          isFieldDisabled || isComputed
            ? EDITOR_DDP_CHILD_FIELDS_INACTIVE_COLOR
            : 'currentColor',
      },
    }}
    sx={{
      cursor: 'auto',
      borderRight: `1px solid`,
      borderRadius: (theme) => theme.drimz.size.borderRadius.tiny,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      width: (theme) => theme.drimz.size.editor.ddp.field.icon.wrapper.width,
      maxWidth: (theme) => theme.drimz.size.editor.ddp.field.icon.wrapper.width,
      minWidth: (theme) => theme.drimz.size.editor.ddp.field.icon.wrapper.width,
      height: (theme) => theme.drimz.size.editor.ddp.field.icon.wrapper.height,

      backgroundColor: (theme) =>
        withImportant(theme.drimz.palette.transparent),

      borderColor: EDITOR_DDP_FIELD_BORDER_COLOR,

      [`.${fieldClassName}:hover &`]: {
        borderColor: isFieldDisabled
          ? EDITOR_DDP_FIELD_BORDER_COLOR
          : EDITOR_DDP_FIELD_HOVER_BORDER_COLOR,
      },
      [`.${fieldClassName}:focus-within &`]: {
        borderColor: isFieldDisabled
          ? EDITOR_DDP_FIELD_BORDER_COLOR
          : EDITOR_DDP_FIELD_FOCUS_BORDER_COLOR,
      },
    }}
  />
);
