import {
  getTokenSetsByCreatedAt,
  JuxStore,
  JuxStoreActionFn,
  convertToCoreTokenSet,
  TokenSetsActionsParams,
  toRecordBy,
} from '@jux/canjux/core';

export const deleteTokenSet: JuxStoreActionFn<
  TokenSetsActionsParams['deleteTokenSet'],
  JuxStore
> = ({ state, tokenSetId }) => {
  const tokenSetsById = toRecordBy(state.tokenSets, 'id');
  const tokenSetsList = Object.values(tokenSetsById);

  if (!(tokenSetId in tokenSetsById)) {
    throw new Error(`Token set with id ${tokenSetId} does not exist`);
  }

  if (!tokenSetsList.length) {
    throw new Error('No token sets to delete');
  }

  const isLastTokenSet =
    tokenSetsList.length === 1 && tokenSetsList[0]?.id === tokenSetId;

  // If the last token set is being deleted, we need to convert it to the initial default core token set
  if (isLastTokenSet) {
    const coreTokenSet = convertToCoreTokenSet(tokenSetsList[0]);

    state.tokenSets[coreTokenSet.name] = coreTokenSet;
  }

  // Get the name of the token set to delete
  const { name } = tokenSetsById[tokenSetId];

  delete state.tokenSets[name];

  if (state.editorTokenSetId === tokenSetId) {
    state.editorTokenSetId = getTokenSetsByCreatedAt(state.tokenSets)[0].id;
  }

  return state;
};
