import { FC } from 'react';
import { ModalWrapper, Typography } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { SubmitModalContentProps } from './SubmitModal.interface';

export const SubmitModalContent: FC<SubmitModalContentProps> = ({
  title,
  onClose,
  children,
}) => (
  <ModalWrapper onClose={onClose}>
    <MuiGrid item container>
      <Typography
        variant="h2"
        color={(theme) => theme.drimz.palette.text.primary}
      >
        {title}
      </Typography>
    </MuiGrid>
    {children && (
      <MuiGrid item container marginTop="24px">
        {children}
      </MuiGrid>
    )}
  </ModalWrapper>
);
