import { useCallback } from 'react';
import { useSelectedNodeId } from '@jux/ui/components/editor/hooks';

export const useSetSelectedElementStylesOnDOM = () => {
  const selectedNodeId = useSelectedNodeId();

  const setSelectedNodeDomStyleDirectly = useCallback(
    (key: string, value: string) => {
      if (selectedNodeId) {
        const element = document.getElementById(selectedNodeId);
        if (element?.style) {
          element.style[key] = value;
        }
      }
    },
    [selectedNodeId]
  );

  return setSelectedNodeDomStyleDirectly;
};
