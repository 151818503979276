import {
  CanvasNodesDimensions,
  CanvasNodesIndicatorsPositions,
  getNodeChildrenRecursive,
  getNodeRect,
  getNodesInRect,
  getOverlappingArea,
  JuxStore,
  Transform,
} from '@jux/canjux/core';
import { CanvasData } from '@jux/data-entities';

export const getIntersectingNode = ({
  canvas,
  components,
  canvasNodesDimensionsData,
  canvasNodesIndicatorsData,
  height,
  nodeIds,
  transform,
  width,
  pointerPosition,
}: {
  canvas: CanvasData;
  components: JuxStore['components'];
  canvasNodesDimensionsData: CanvasNodesDimensions;
  canvasNodesIndicatorsData: CanvasNodesIndicatorsPositions;
  height: number;
  nodeIds: string[];
  transform: Transform;
  width: number;
  pointerPosition: { x: number; y: number };
}): string[] => {
  const pointerRect = {
    ...pointerPosition,
    height: 1,
    width: 1,
  };

  const childNodes = nodeIds.flatMap((nodeId) =>
    getNodeChildrenRecursive({ components, nodeId })
  );

  const visibleNodes = getNodesInRect({
    canvas,
    canvasNodesDimensionsData,
    canvasNodesIndicatorsData,
    onlyRootNodes: false,
    partially: true,
    transform,
    viewport: { x: 0, y: 0, width, height },
  });

  return visibleNodes.filter((visibleNodeId) => {
    const properties = canvas.nodes[visibleNodeId].properties;
    const dimensions = canvasNodesDimensionsData[visibleNodeId];
    // We don't want to consider the current node as intersecting in the following cases:
    // 1. Target node is itself
    // 2. Target node is hidden
    // 3. Target node doesn't have a rect (width or height is null or 0)
    // 4. Target node is a children or sub-children of the dragged node
    // 5. Target node is either input / text / svg
    // 6. Target node is an internal node only - for instance children nodes
    if (
      nodeIds.includes(visibleNodeId) ||
      childNodes.includes(visibleNodeId) ||
      dimensions.height === 0 ||
      dimensions.width === 0 ||
      properties.isHidden ||
      !properties.isContainer
    ) {
      return false;
    }

    const currNodeRect = getNodeRect({
      canvasNodesDimensionsData,
      canvasNodesIndicatorsData,
      nodeId: visibleNodeId,
    });

    const overlappingArea = getOverlappingArea(currNodeRect, pointerRect);

    return overlappingArea > 0;
  });
};
