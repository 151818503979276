import { z } from 'zod';

const canvasNameSchema = z
  .string()
  .min(1, 'Canvas name must be at least one character')
  .max(50, 'Canvas name cannot exceed 50 characters');

const validateCanvasName = (canvasName: string) =>
  canvasNameSchema.parse(canvasName);

export const getValidCanvasName = (canvasName: string) =>
  validateCanvasName(canvasName.trim());
