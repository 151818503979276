import { forwardRef, useMemo } from 'react';
import { Icons } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { EDITOR_DDP_COLOR_FIELD_AUTOCOMPLETE_INPUT_ICON_COLOR } from '@jux/ui/theme/palette';
import { Input } from '../input';
import { AutocompleteInputProps } from './AutocompleteInput.interface';
import * as S from './Autocomplete.style';

export const AutocompleteInput = forwardRef<
  HTMLInputElement,
  AutocompleteInputProps
>(
  (
    {
      onClear,
      isHovered,
      isFocused,
      hideSearchIcon,
      isComputed,
      value,
      wrapperProps,
      showValue,
      ...props
    },
    ref
  ) => {
    const isEmpty = useMemo(() => !value, [value]);

    const shouldShowClearIcon = useMemo(
      () => (isHovered || isFocused) && !isEmpty && !hideSearchIcon,
      [hideSearchIcon, isEmpty, isFocused, isHovered]
    );

    const shouldShowSearchIcon = useMemo(
      () => isEmpty && !hideSearchIcon,
      [hideSearchIcon, isEmpty]
    );

    return (
      <S.AutocompleteInputLabel {...wrapperProps}>
        <MuiGrid item display="flex" flex={1} alignItems="center">
          <Input
            isComputed={isComputed}
            value={showValue ? value : undefined}
            ref={ref}
            {...props}
          />
          {shouldShowClearIcon && (
            <Icons
              variant="CLEAR"
              onClick={onClear}
              sx={{
                color: EDITOR_DDP_COLOR_FIELD_AUTOCOMPLETE_INPUT_ICON_COLOR,
                cursor: 'pointer',
              }}
            />
          )}
        </MuiGrid>
        {shouldShowSearchIcon && (
          <MuiGrid item display="flex" flex={0} alignItems="center">
            <Icons
              variant="SEARCH"
              sx={{
                color: EDITOR_DDP_COLOR_FIELD_AUTOCOMPLETE_INPUT_ICON_COLOR,
              }}
            />
          </MuiGrid>
        )}
      </S.AutocompleteInputLabel>
    );
  }
);
