import { CanjuxState, useStoreApi } from '@jux/canjux/core';
import type { WithLiveblocks } from '@liveblocks/zustand';
import { useEffect } from 'react';

// updates with values in store that don't have a dedicated setter function
export const useDirectStoreUpdater = (
  // We are omitting the liveblocks key because it is automatically synced with liveblocks, so to prevent it from being overwritten we omit it
  key: keyof Omit<WithLiveblocks<CanjuxState>, 'liveblocks'>,
  value: Omit<WithLiveblocks<CanjuxState>, 'liveblocks'>[keyof Omit<
    WithLiveblocks<CanjuxState>,
    'liveblocks'
  >]
) => {
  const { setState } = useStoreApi();
  useEffect(() => {
    if (typeof value !== 'undefined') {
      setState({ [key]: value });
    }
  }, [key, setState, value]);
};
