import { trimLowerCaseStr } from '@jux/ui/utils/trimLowerCaseStr';
import {
  SupportedFlexWrapValues,
  FlexWrapValue,
} from './FlexWrapField.interface';

export const parseFlexWrap = (
  value: string
): SupportedFlexWrapValues | undefined => {
  switch (trimLowerCaseStr(value)) {
    case 'wrap':
      return FlexWrapValue.wrap;
    case 'nowrap':
      return FlexWrapValue.nowrap;
  }

  return undefined;
};

export const isFlexWrap = (value: SupportedFlexWrapValues | undefined) =>
  value === FlexWrapValue.wrap;

export const toggleFlexWrap = (value: SupportedFlexWrapValues | undefined) => {
  switch (trimLowerCaseStr(value)) {
    case FlexWrapValue.nowrap:
      return FlexWrapValue.wrap;
    case FlexWrapValue.wrap:
      return FlexWrapValue.nowrap;
  }

  return undefined;
};
