import { JuxStore, toRecordBy } from '@jux/canjux/core';
import {
  DesignTokenTypeEnum,
  getDefaultStructuredTokenSet,
  SupportedTokenTypes,
} from '@jux/design-tokens';
import { ThemeConfig } from '@jux/types';
import { getDesignTokensParserContext } from '../../../store-utils/getDesignTokensParserContext';
import { getGroupedTokens } from './getGroupedTokens';

type GroupedTokens = ReturnType<typeof getGroupedTokens>[SupportedTokenTypes];

const buildTokens = (groupsTokens: GroupedTokens['tokens']) =>
  groupsTokens.reduce((acc, token) => {
    return {
      ...acc,
      [token.name]: token.value,
    };
  }, {});

const recursivelyRunOnTreeAndPickTokens = (group: GroupedTokens) => {
  const groups: GroupedTokens['groups'] = group.groups.reduce((acc, g) => {
    return {
      ...acc,
      ...recursivelyRunOnTreeAndPickTokens(g),
    };
  }, {} as GroupedTokens['groups']);

  const tokens = buildTokens(group.tokens);

  return {
    [group.name]: {
      ...tokens,
      ...groups,
    },
  };
};

const getThemeByTokenType = (groupsByType: GroupedTokens) => {
  const tokens = buildTokens(groupsByType.tokens);

  return {
    ...tokens,
    ...groupsByType.groups.reduce((acc, tokenGroup) => {
      return {
        ...acc,
        ...recursivelyRunOnTreeAndPickTokens(tokenGroup),
      };
    }, {}),
  };
};

const designTokensParser = getDesignTokensParserContext();

export const getThemeByTokenSetId = ({
  tokenSetId,
  tokenSets,
}: {
  tokenSetId: string;
  tokenSets: JuxStore['tokenSets'];
}): ThemeConfig => {
  const tokenSetsById = toRecordBy(tokenSets, 'id');

  const tokenSet = tokenSetsById[tokenSetId];

  if (!tokenSet) {
    return {
      tokenSet: getDefaultStructuredTokenSet(),
    };
  }

  const parser = designTokensParser.parse(tokenSet.value);

  const aliasesMap = parser.getAliasesMap();
  const valuesMap = parser.getValuesMap();

  const groupsByType = getGroupedTokens({
    aliasesMap,
    valuesMap,
    tokens: tokenSet.value,
    isCoreTokenSet: false,
    parser,
  });
  const coreGroupsByType = getGroupedTokens({
    aliasesMap,
    valuesMap,
    tokens: tokenSet.value.core,
    isCoreTokenSet: true,
    parser,
  });

  const color = getThemeByTokenType(groupsByType[DesignTokenTypeEnum.color]);
  const coreColor = getThemeByTokenType(
    coreGroupsByType[DesignTokenTypeEnum.color]
  );

  const dimension = getThemeByTokenType(
    groupsByType[DesignTokenTypeEnum.dimension]
  );
  const coreDimension = getThemeByTokenType(
    coreGroupsByType[DesignTokenTypeEnum.dimension]
  );

  const fontFamily = getThemeByTokenType(
    groupsByType[DesignTokenTypeEnum.fontFamily]
  );
  const coreFontFamily = getThemeByTokenType(
    coreGroupsByType[DesignTokenTypeEnum.fontFamily]
  );

  const typography = getThemeByTokenType(
    groupsByType[DesignTokenTypeEnum.typography]
  );
  const coreTypography = getThemeByTokenType(
    coreGroupsByType[DesignTokenTypeEnum.typography]
  );

  const border = getThemeByTokenType(groupsByType[DesignTokenTypeEnum.border]);
  const coreBorder = getThemeByTokenType(
    coreGroupsByType[DesignTokenTypeEnum.border]
  );

  return {
    tokenSet: {
      border,
      color,
      core: {
        border: coreBorder,
        color: coreColor,
        dimension: coreDimension,
        fontFamily: coreFontFamily,
        typography: coreTypography,
      },
      dimension,
      fontFamily,
      typography,
    },
  };
};
