import { FC, PropsWithChildren, useRef } from 'react';
import { useMaxWidthRelativeToRef } from '@jux/ui/hooks';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { RevertToInitialWithIcon } from '../revertToInitialWithIcon';
import { TokenSelection } from '../tokensSelection';
import { BaseFieldLayoutProps } from './BaseFieldLayout.interface';
import * as S from './BaseFieldLayout.style';

export const BaseFieldLayout: FC<PropsWithChildren<BaseFieldLayoutProps>> = ({
  children,
  className,
  icon,
  isComputed,
  isFieldDisabled,
  htmlFor,
  tooltipTitle,
  tokensProps,
  renderTokenOption,
  fieldRef,
}) => {
  const { isTokenized, tokenType } = tokensProps ?? {};

  const iconWrapperRef = useRef<HTMLDivElement>(null);

  const contentWrapperMaxAvailableWidth =
    useMaxWidthRelativeToRef<HTMLDivElement>({
      relativeRef: iconWrapperRef,
      spacing: 4,
    });

  return (
    <S.Field
      container
      className={className}
      ref={fieldRef}
      isFieldDisabled={isFieldDisabled ?? false}
    >
      <MuiGrid
        height={(theme) =>
          theme.drimz.size.editor.ddp.field.icon.wrapper.height
        }
        ref={iconWrapperRef}
      >
        <RevertToInitialWithIcon
          icon={icon}
          isComputed={isComputed ?? false}
          isFieldDisabled={isFieldDisabled ?? false}
          tooltipTitle={tooltipTitle}
          fieldClassName={className}
        />
      </MuiGrid>

      <S.ContentWrapper
        isTokenized={isTokenized}
        width={contentWrapperMaxAvailableWidth}
      >
        <S.ContentValueLabel
          htmlFor={htmlFor}
          isTokenized={isTokenized}
          fieldClassName={className}
          isFieldDisabled={isFieldDisabled}
        >
          {children}
        </S.ContentValueLabel>

        {/* Includes the selection button and the list of available tokens */}
        {!isFieldDisabled && tokenType && renderTokenOption && (
          <TokenSelection
            anchorEl={fieldRef.current}
            renderOption={renderTokenOption}
            tokensProps={tokensProps}
          />
        )}
      </S.ContentWrapper>
    </S.Field>
  );
};
