import { FocusEvent, useCallback } from 'react';
import { useStore, selectIsNodeToolbarLabelInEditMode } from '@jux/canjux/core';
import { useStoreActions } from '../../../hooks';
import { useUpdateNodeDisplayName } from '@jux/ui/components/editor/hooks';
import { TypographyElement } from '@jux/ui/components';
import { useTrackEvents } from '@jux/ui/hooks';

export const useNodeToolbarLabelEditing = (nodeId: string) => {
  const isNodeToolbarLabelEditing = useStore(
    selectIsNodeToolbarLabelInEditMode(nodeId)
  );

  const updateNodeDisplayName = useUpdateNodeDisplayName();

  const {
    commonActions: { setEditModeInNodeToolbarLabel },
  } = useStoreActions();
  const { trackUpdateComponentDIsplayName } = useTrackEvents();

  const handleNodeToolbarLabelDoubleClick = useCallback(() => {
    setEditModeInNodeToolbarLabel({ nodeId, editable: true });
  }, [nodeId, setEditModeInNodeToolbarLabel]);

  const handleNodeToolbarLabelBlur = useCallback(
    (event: FocusEvent<TypographyElement>) => {
      updateNodeDisplayName({ nodeId, value: event.target.innerText });
      trackUpdateComponentDIsplayName('canvas');
      setEditModeInNodeToolbarLabel({ nodeId, editable: false });
    },
    [
      nodeId,
      setEditModeInNodeToolbarLabel,
      trackUpdateComponentDIsplayName,
      updateNodeDisplayName,
    ]
  );

  return {
    handleNodeToolbarLabelDoubleClick,
    handleNodeToolbarLabelBlur,
    isNodeToolbarLabelEditing,
  };
};
