import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { useLiveOrEdit } from './useLiveOrEdit';
import { LiveOrEditButton } from './LiveOrEditButton';

export const LiveOrEdit: FC = () => {
  const { isEdit, isLive, handleEditClick, handleLiveClick } = useLiveOrEdit();

  return (
    <MuiGrid>
      <LiveOrEditButton
        onClick={handleEditClick}
        disabled={isEdit}
        sx={{
          borderRadius: (theme) =>
            `${theme.drimz.size.borderRadius.small} 0 0 ${theme.drimz.size.borderRadius.small}`,
        }}
      >
        Edit
      </LiveOrEditButton>
      <LiveOrEditButton
        onClick={handleLiveClick}
        disabled={isLive}
        sx={{
          borderRadius: (theme) =>
            `0 ${theme.drimz.size.borderRadius.small} ${theme.drimz.size.borderRadius.small} 0`,
          position: 'relative',
          right: '1px',
        }}
      >
        Live
      </LiveOrEditButton>
    </MuiGrid>
  );
};
