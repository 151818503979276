import { FC } from 'react';
import { z } from 'zod';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import { NumericField } from '../../../../base';
import {
  useFieldValues,
  useNumericField,
  useSetFieldValue,
} from '../../../../hooks';

const zIndexFieldSchema = z.string().regex(/^\d+$/).optional();

const FIELD_KEY = DDPPrimitiveFieldsKeys.zIndex;

export const ZIndexField: FC = () => {
  const { value, initialValue } = useFieldValues(FIELD_KEY);
  const { saveValue: setFieldValue } = useSetFieldValue(FIELD_KEY);

  const props = useNumericField({
    value,
    initialValue,
    saveChanges: setFieldValue,
    fieldName: FIELD_KEY,
    fieldSchema: zIndexFieldSchema,
  });

  return (
    <NumericField icon="DDP_FIELD_Z_INDEX" tooltipTitle="Z index" {...props} />
  );
};
