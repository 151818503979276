import { FC, useCallback, useMemo } from 'react';
import { toFirstUpperCase } from '@jux/ui/utils/toFirstUpperCase';
import { SelectField } from '@jux/ui/components';
import { MuiMenuItem, SelectChangeEvent } from '@jux/ui/components/common/mui';
import { SubModule } from '@jux/ui/components/editor/components/panels/DDP/base';
import {
  nodeComponentByNodeId,
  PROP_EDITING_MODE_ANY,
  selectResolvedComponentProps,
  useStore,
} from '@jux/canjux/core';
import { useSetVariantPropsValues } from '@jux/ui/components/editor/components/panels/DDP/hooks';
import { ComponentEnumProp } from '@jux/data-entities';
import { NodeType } from '@jux/data-entities';

type EnumPropFieldProps<T extends boolean | string = boolean | string> = {
  displayName: string;
  nodeId: string;
  options: ComponentEnumProp<T>['options'];
  propName: string;
};

export const EnumPropField: FC<EnumPropFieldProps> = ({
  displayName,
  nodeId,
  options,
  propName,
}) => {
  const nodeComponentData = useStore(nodeComponentByNodeId(nodeId));
  const resolvedComponentProps = useStore(
    selectResolvedComponentProps({
      id: nodeId,
      onlyVariantsProps: false,
    })
  );
  const { setPropValue, setPropEditingModeAny } = useSetVariantPropsValues();

  const allowOptionAny = useMemo(
    () => nodeComponentData?.type === NodeType.VARIANTS_GROUP,
    [nodeComponentData?.type]
  );

  const selectedValue = useMemo(() => {
    const propCurrentValue = resolvedComponentProps[propName];
    return options.find((o) => o.value === propCurrentValue)?.label || '';
  }, [options, propName, resolvedComponentProps]);

  const handleChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      const propValue = options.find((o) => o.label === e.target.value)?.value;

      if (e.target.value === PROP_EDITING_MODE_ANY) {
        setPropEditingModeAny({
          nodeId,
          propName,
        });
      }
      if (propValue === undefined) {
        return;
      }

      setPropValue({
        nodeId,
        propName,
        propValue,
      });
    },
    [nodeId, options, propName, setPropEditingModeAny, setPropValue]
  );

  return (
    <SubModule title={toFirstUpperCase(displayName)} renderInline>
      <SelectField
        type="text"
        name={propName}
        value={selectedValue}
        onChange={handleChange}
      >
        {options.map(({ label }) => (
          <MuiMenuItem key={label} value={label}>
            {label}
          </MuiMenuItem>
        ))}
        {allowOptionAny && (
          <MuiMenuItem
            key={PROP_EDITING_MODE_ANY}
            value={PROP_EDITING_MODE_ANY}
          >
            {PROP_EDITING_MODE_ANY}
          </MuiMenuItem>
        )}
      </SelectField>
    </SubModule>
  );
};
