import { FC, PropsWithChildren, ReactNode } from 'react';
import { PopperDirection } from '@jux/ui/components';
import * as S from './NavigationPopper.style';

export const NavigationPopper: FC<
  PropsWithChildren<{
    header?: ReactNode;
    popperDirection?: PopperDirection;
    renderInMaxHeight?: boolean;
    testId?: string;
    maxHeight: number;
  }>
> = ({
  children,
  header,
  maxHeight,
  popperDirection = 'ltr',
  renderInMaxHeight,
  testId,
}) => (
  <S.Wrapper data-testid={testId} popperDirection={popperDirection}>
    <S.WrapperContent renderInMaxHeight={renderInMaxHeight}>
      {header}

      <S.Content maxHeight={maxHeight}>{children}</S.Content>
    </S.WrapperContent>
  </S.Wrapper>
);
