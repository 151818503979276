import { CSSProperties, useEffect, useRef } from 'react';

const PREVIEW_CONTAINER_ID = 'component-preview-container';

const toStyleString = (style: CSSProperties) =>
  Object.entries(style)
    .map(
      ([k, v]) =>
        `${k.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`)}:${v}`
    )
    .join(';');

export const usePreviewPortal = () => {
  const previewPortalContainer = useRef(
    document.getElementById(PREVIEW_CONTAINER_ID) ||
      document.createElement('div')
  );

  useEffect(() => {
    let container = document.getElementById(PREVIEW_CONTAINER_ID);

    if (!container || container !== previewPortalContainer.current) {
      container = previewPortalContainer.current;

      container.setAttribute('id', PREVIEW_CONTAINER_ID);

      container.setAttribute(
        'style',
        toStyleString({
          position: 'absolute',
          zIndex: '-1',
          top: '0px',
          left: '0px',
          minWidth: '100vw',
          minHeight: '100vh',
          opacity: '0',
          transform: 'translateX(-100%)',
        })
      );

      document.body.appendChild(container);
    }

    return () => container?.remove();
  }, []);

  return {
    previewPortalContainer: previewPortalContainer.current,
  };
};
