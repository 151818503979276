import { useSelectedNodeId } from '@jux/ui/components/editor/hooks';
import { useNodeInteractiveState } from './useNodeInteractiveState';

// TODO: maybe rename this hook, maybe something not 'state' based
export const useSelectedNodeInteractiveState = () => {
  const selectedNodeId = useSelectedNodeId();
  const {
    nodeInteractiveState,
    setNodeInteractiveState,
    resetNodeInteractiveState,
  } = useNodeInteractiveState(selectedNodeId || '');

  return {
    selectedNodeInteractiveState: nodeInteractiveState,
    setSelectedNodeInteractiveStateAtom: setNodeInteractiveState,
    resetNodeState: resetNodeInteractiveState,
  };
};
