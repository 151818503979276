/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const AlignItemsCenterRowDDPIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M7 12C7 12.5523 6.55229 13 6 13C5.44772 13 5 12.5523 5 12L5 9H2.5C2.22386 9 2 8.77614 2 8.5C2 8.22386 2.22386 8 2.5 8H5L5 4C5 3.44772 5.44771 3 6 3C6.55228 3 7 3.44772 7 4L7 8L9 8V6C9 5.44772 9.44771 5 10 5C10.5523 5 11 5.44772 11 6L11 8H13.5C13.7761 8 14 8.22386 14 8.5C14 8.77614 13.7761 9 13.5 9H11V10C11 10.5523 10.5523 11 10 11C9.44772 11 9 10.5523 9 10V9L7 9L7 12Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
