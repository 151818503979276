import { getCanjuxElementById, getDataJuxNodeId } from '@jux/canjux/core';

const getPositionStyle = (id: string): string | undefined => {
  const nodeElement = getCanjuxElementById(id);

  if (!nodeElement) return undefined;

  const styles = getComputedStyle(nodeElement);
  return styles.position;
};

const recursivelyFindFirstRelativeToParent = (
  parentId: string
): string | undefined => {
  const positionStyle = getPositionStyle(parentId);
  // if parent has position relative or absolute, return parent
  if (positionStyle === 'relative' || positionStyle === 'absolute')
    return parentId;

  const nodeElement = getCanjuxElementById(parentId);

  if (!nodeElement?.parentNode) return undefined;

  const newParentId = getDataJuxNodeId(nodeElement.parentNode as HTMLElement);

  if (!newParentId) return undefined;

  // else, repeat with parent id until reaching root
  return recursivelyFindFirstRelativeToParent(newParentId);
};

export const findFirstRelativeToParent = recursivelyFindFirstRelativeToParent;
