import { MuiGrid, styled } from '@jux/ui/components/common/mui';

export const DropZoneGridWrapper = styled(MuiGrid)(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  flexDirection: 'column',
}));
