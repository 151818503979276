/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const JustifyContentCenterColumnDDPIcon: IconType = (
  props: IconBaseProps
) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M5 5C5 5.55228 5.44772 6 6 6L10 6C10.5523 6 11 5.55228 11 5C11 4.44771 10.5523 4 10 4H6C5.44772 4 5 4.44772 5 5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M13.25 7.25C13.6642 7.25 14 7.58579 14 8C14 8.41421 13.6642 8.75 13.25 8.75L2.75 8.75C2.33579 8.75 2 8.41421 2 8C2 7.58579 2.33579 7.25 2.75 7.25L13.25 7.25Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M6 12C5.44772 12 5 11.5523 5 11C5 10.4477 5.44772 10 6 10L10 10C10.5523 10 11 10.4477 11 11C11 11.5523 10.5523 12 10 12L6 12Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
