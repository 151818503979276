import { useMemo } from 'react';
import { getThemeByTokenSetId, useStore } from '@jux/canjux/core';
import { useCurrentOrgEditorTokenSetId } from '@jux/ui/hooks';

export const useCurrentOrgTheme = () => {
  const { currentOrgTokenSetId: tokenSetId } = useCurrentOrgEditorTokenSetId();
  const tokenSets = useStore((s) => s.tokenSets);

  return useMemo(
    () =>
      getThemeByTokenSetId({
        tokenSetId,
        tokenSets,
      }),
    [tokenSetId, tokenSets]
  );
};
