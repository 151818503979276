import { RefObject, useEffect, useMemo, useState } from 'react';

export const useShowLoadingUntilElementIsReady = ({
  isElementInView,
  elementRef,
  showLoadingThreshold,
}: {
  isElementInView: boolean;
  elementRef: RefObject<unknown>;
  showLoadingThreshold: number;
}) => {
  const [isLoadingTimeoutReached, setIsLoadingTimeoutReached] = useState(false);

  // Show loading only if the component preview is not yet rendered and the loading timeout is reached
  const showLoading = useMemo(
    () => !elementRef.current && isLoadingTimeoutReached,
    [elementRef, isLoadingTimeoutReached]
  );

  useEffect(() => {
    if (!isElementInView) return;

    // When component in view, start the loading display timeout
    const timeoutId = setTimeout(
      () => setIsLoadingTimeoutReached(true),
      showLoadingThreshold
    );

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isElementInView, showLoadingThreshold]);

  return {
    showLoading,
  };
};
