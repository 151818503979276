import { withPx } from '@jux/ui/utils/css';
import { calcIndent } from './calcIndent.utils';
import { GetColorsProps, getColors } from './getColors';

const getIndentStyle = (depth: number) => ({
  paddingInlineStart: withPx(calcIndent({ depth })),
});

export const getObjectNavigatorItemStyle = ({
  indentLevel,
  isSelected,
  hasAncestorSelected,
  isHovered,
  hasAncestorLibraryComponent,
  isLibraryComponent,
  isLogicalSlot,
  isDraggedInside,
}: {
  indentLevel: number;
} & GetColorsProps) => ({
  minWidth: 'auto',
  paddingInlineEnd: '24px',

  ...getIndentStyle(indentLevel),
  ...getColors({
    isSelected,
    hasAncestorSelected,
    isHovered,
    isLibraryComponent,
    isLogicalSlot,
    hasAncestorLibraryComponent,
    isDraggedInside,
  }),
});
