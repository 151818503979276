/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const DisplayInlineFlexDDPIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M3 13C2.44772 13 2 12.5523 2 12V4C2 3.44772 2.44772 3 3 3H6C6.55229 3 7 3.44771 7 4L7 12C7 12.5523 6.55229 13 6 13H3ZM5.5 11.5H3.5L3.5 4.5H5.5L5.5 11.5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          d: 'M10 13C9.44772 13 9 12.5523 9 12L9 4C9 3.44772 9.44772 3 10 3H13C13.5523 3 14 3.44771 14 4V12C14 12.5523 13.5523 13 13 13H10ZM12.5 11.5H10.5V4.5H12.5V11.5Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
