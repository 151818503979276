import { FC } from 'react';
import Link from 'next/link';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { Typography, Icons } from '@jux/ui/components';

export const Error: FC<{ error?: string }> = ({ error }) => (
  <MuiGrid
    width="100%"
    height="100vh"
    alignItems="center"
    justifyContent="center"
    display="flex"
    flexDirection="column"
  >
    <Icons
      variant="ALERT"
      sx={{ color: (theme) => theme.drimz?.palette.error.main }}
      size={36}
    />
    {error ?? (
      <>
        <Typography
          variant="body-bold"
          color={(theme) => theme.drimz?.palette.error.main}
          mt={1}
        >
          Error has occurred, please contact our support or try again later.
        </Typography>
        <Link href="mailto:ask@jux.io">
          <Typography
            color={(theme) => theme.drimz?.palette.error.main}
            variant="body-semi"
            sx={{ textDecoration: 'underline' }}
          >
            ask@jux.io
          </Typography>
        </Link>
      </>
    )}
  </MuiGrid>
);
