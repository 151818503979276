import { UserTraits } from '@segment/analytics-next';
import { atom, useAtom, useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { v4 as uuid } from 'uuid';
import { Organization } from '@jux/data-entities';

type UserIdentity = {
  userId: string;
  traits: UserTraits;
  org: Organization;
};

const userIdentityAtom = atom<UserIdentity | undefined>(
  undefined as unknown as UserIdentity // this is a must in order to pass build => https://github.com/pmndrs/jotai/issues/838
);
const LOCAL_STORAGE_KEY = 'anonymousId';

const anonymousIdAtom = atomWithStorage(LOCAL_STORAGE_KEY, uuid());

export const useUserIdentityState = () => {
  const [userIdentity, setUserIdentity] = useAtom(userIdentityAtom);
  const anonymousId = useAtomValue(anonymousIdAtom);

  return {
    anonymousId,
    userIdentity,
    setUserIdentity,
  };
};
