// eslint-disable-next-line no-restricted-imports
import styled from '@mui/styled-engine-sc';
import {
  COMMON_TOGGLE_SWITCH_DISABLED_BG_COLOR,
  COMMON_TOGGLE_SWITCH_FOCUS_BORDER_COLOR,
  COMMON_TOGGLE_SWITCH_INDETERMINATE_BG_COLOR,
  COMMON_TOGGLE_SWITCH_KNOB_BG_COLOR,
  COMMON_TOGGLE_SWITCH_OFF_BG_COLOR,
  COMMON_TOGGLE_SWITCH_ON_BG_COLOR,
} from '@jux/ui/theme/palette';
import { ToggleSwitchProps } from './ToggleSwitch.interface';

const TRANSITION_STYLES = {
  transition: 'all 0.3s cubic-bezier(0.72, 0.06, 0.32, 0.95)',
};

const OUTLINE_STYLES = {
  outlineOffset: '2px',
  outlineWidth: '1px',
  outlineColor: 'transparent',
  outlineStyle: 'solid',
};

export const ToggleSwitchKnob = styled('div')((/*{ theme }*/) => ({
  ...TRANSITION_STYLES,
  width: '10px',
  height: '10px',
  borderRadius: '10px',
  position: 'absolute',
  backgroundColor: COMMON_TOGGLE_SWITCH_KNOB_BG_COLOR,
}));

export const ToggleSwitchWrapper = styled('button').withConfig({
  shouldForwardProp: (prop) => prop !== 'checked' && prop !== 'disabled',
})<Pick<ToggleSwitchProps, 'checked' | 'disabled'>>(
  ({ checked, disabled }) => ({
    width: '24px',
    height: '12px',
    position: 'relative',
    cursor: 'pointer',
    userSelect: 'none',
    border: 'none',
    borderRadius: '10px',
    overflow: 'hidden',
    input: {
      visibility: 'hidden',
    },
    ...TRANSITION_STYLES,
    ...OUTLINE_STYLES,
    '&:focus-visible': {
      outlineColor: COMMON_TOGGLE_SWITCH_FOCUS_BORDER_COLOR,
    },
    ...(checked === true && {
      backgroundColor: COMMON_TOGGLE_SWITCH_ON_BG_COLOR,
      [`${ToggleSwitchKnob}`]: {
        left: 'calc(100% - 11px)',
        top: '1px',
      },
    }),
    ...(checked === false && {
      backgroundColor: COMMON_TOGGLE_SWITCH_OFF_BG_COLOR,
      [`${ToggleSwitchKnob}`]: {
        left: '1px',
        top: '1px',
      },
    }),
    ...(checked === 'indeterminate' && {
      backgroundColor: COMMON_TOGGLE_SWITCH_INDETERMINATE_BG_COLOR,
      [`${ToggleSwitchKnob}`]: {
        height: '2px',
        width: '8px',
        left: '8px',
        top: '5px',
      },
    }),
    ...(disabled && {
      backgroundColor: COMMON_TOGGLE_SWITCH_DISABLED_BG_COLOR,
      cursor: 'not-allowed',
      '&:hover': {},
      '&:active': {},
    }),
  })
);
