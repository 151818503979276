import { ContextStylesList } from '@jux/types';

// Clone context styles of the source component to the new component
// and update the contextChildUuid to the new component id
export const getClonedContextStyles = ({
  sourceChildUuid,
  sourceContextStyles,
  targetChildUuid,
}: {
  sourceChildUuid: string;
  sourceContextStyles?: ContextStylesList;
  targetChildUuid: string;
}) => {
  const styles: ContextStylesList = [];

  if (sourceContextStyles?.length) {
    styles.push(...sourceContextStyles);

    const relevantContextStyles = sourceContextStyles.filter(
      (contextStyle) => contextStyle.contextChildUuid === sourceChildUuid
    );

    for (const contextStyle of relevantContextStyles) {
      styles.push({
        ...contextStyle,
        contextChildUuid: targetChildUuid,
      });
    }
  }

  return styles;
};
