import { zoomIdentity } from 'd3';
import { useCallback } from 'react';
import {
  getD3Transition,
  getRectOfNodes,
  getTransformForBounds,
  rendererPointToPoint,
  setSpaceCenter,
} from '../../utils';
import { useStore, useStoreApi } from '../useStore';
import {
  GetViewportPositionFromCanjuxPosition,
  ViewportHelperFunctions,
} from './useViewportHelper.interface';

export const useViewportHelper = (): ViewportHelperFunctions => {
  const storeApi = useStoreApi();
  const {
    d3Zoom,
    d3Selection,
    transformX,
    transformY,
    transformZoom,
    width,
    height,
    minZoom,
    maxZoom,
  } = useStore((s) => ({
    d3Zoom: s.d3Zoom,
    d3Selection: s.d3Selection,
    transform: s.transform,
    transformX: s.transform.x,
    transformY: s.transform.y,
    transformZoom: s.transform.zoom,
    width: s.width,
    height: s.height,
    minZoom: s.minZoom,
    maxZoom: s.maxZoom,
  }));

  const zoomIn = useCallback<ViewportHelperFunctions['zoomIn']>(
    (options) => {
      if (d3Zoom && d3Selection) {
        d3Zoom.scaleBy(
          getD3Transition(d3Selection, options?.duration),
          options?.scale ?? 1.2
        );
      }
    },
    [d3Selection, d3Zoom]
  );

  const zoomOut = useCallback<ViewportHelperFunctions['zoomOut']>(
    (options) => {
      if (d3Selection && d3Zoom) {
        d3Zoom.scaleBy(
          getD3Transition(d3Selection, options?.duration),
          1 / (options?.scale ?? 1.2)
        );
      }
    },
    [d3Selection, d3Zoom]
  );

  const zoomTo = useCallback<ViewportHelperFunctions['zoomTo']>(
    (zoomLevel, options) => {
      if (d3Selection && d3Zoom) {
        d3Zoom.scaleTo(
          getD3Transition(d3Selection, options?.duration),
          zoomLevel
        );
      }
    },
    [d3Selection, d3Zoom]
  );

  const setViewport = useCallback<ViewportHelperFunctions['setViewport']>(
    (newTransform, options) => {
      if (d3Selection && d3Zoom) {
        const nextTransform = zoomIdentity
          .translate(newTransform.x ?? transformX, newTransform.y ?? transformY)
          .scale(newTransform.zoom ?? transformZoom);
        d3Zoom.transform(
          getD3Transition(d3Selection, options?.duration),
          nextTransform
        );
      }
    },
    [d3Selection, d3Zoom, transformX, transformY, transformZoom]
  );

  const setCenter = useCallback<ViewportHelperFunctions['setCenter']>(
    (x, y, options) => {
      if (d3Selection && d3Zoom) {
        setSpaceCenter({
          d3Zoom,
          d3Selection,
          x,
          y,
          maxZoom,
          width,
          height,
          ...options,
        });
      }
    },
    [d3Selection, d3Zoom, height, maxZoom, width]
  );

  const fitBounds = useCallback<ViewportHelperFunctions['fitBounds']>(
    (bounds, options) => {
      if (d3Zoom && d3Selection) {
        const { x, y, zoom } = getTransformForBounds(
          bounds,
          width,
          height,
          minZoom,
          maxZoom,
          options?.padding ?? 0.1
        );
        const newTransform = zoomIdentity.translate(x, y).scale(zoom);

        d3Zoom.transform(
          getD3Transition(d3Selection, options?.duration),
          newTransform
        );
      }
    },
    [d3Selection, d3Zoom, height, maxZoom, minZoom, width]
  );

  const getViewportPositionFromCanjuxPosition =
    useCallback<GetViewportPositionFromCanjuxPosition>(
      (position) =>
        rendererPointToPoint(position, {
          x: transformX,
          y: transformY,
          zoom: transformZoom,
        }),
      [transformX, transformY, transformZoom]
    );

  const fitView = useCallback<ViewportHelperFunctions['fitView']>(
    (options) => {
      const {
        canvasNodesDimensions,
        canvases,
        currentCanvasName,
        components,
        fitViewOnInitDone,
        fitViewOnInit,
        nodeOrigin,
      } = storeApi.getState();
      const currentCanvas = canvases[currentCanvasName];
      const isInitialFitView =
        options.initial && !fitViewOnInitDone && fitViewOnInit;
      const d3initialized = d3Zoom && d3Selection;

      if (!d3initialized || (!isInitialFitView && options.initial)) {
        return false;
      }

      const { nodes, rootNodesOrder } = currentCanvas;

      // Make sure the node is valid and has dimensions
      const visibleStorageNodes = Object.entries(nodes)
        // Must have component data
        .filter(([id]) => id in components)
        // Must be a root node
        .filter(([id]) => rootNodesOrder.includes(id))
        // Must have dimensions
        .filter(
          ([id]) =>
            canvasNodesDimensions[id]?.width &&
            canvasNodesDimensions[id]?.height
        )
        // Must be visible on the canvas
        .filter(([id, { properties }]) => {
          const isVisible = options.includeHiddenNodes || !properties.isHidden;

          return isVisible && options.nodes?.length
            ? options.nodes.some((nodeId) => nodeId === id)
            : isVisible;
        });

      const nodesInitialized = visibleStorageNodes.every(
        ([id]) =>
          canvasNodesDimensions[id]?.width && canvasNodesDimensions[id]?.height
      );

      if (!visibleStorageNodes.length || !nodesInitialized) {
        return false;
      }

      const bounds = getRectOfNodes({
        nodeIds: visibleStorageNodes.map(([id]) => id),
        nodeOrigin,
      });

      const { x, y, zoom } = getTransformForBounds(
        bounds,
        width,
        height,
        options.minZoom ?? minZoom,
        options.maxZoom ?? maxZoom,
        options.padding ?? 0.4 // TODO: make this responsive
      );

      const nextTransform = zoomIdentity.translate(x, y).scale(zoom);

      if (typeof options.duration === 'number' && options.duration > 0) {
        d3Zoom.transform(
          getD3Transition(d3Selection, options.duration),
          nextTransform
        );
      } else {
        d3Zoom.transform(d3Selection, nextTransform);
      }

      return true;
    },
    [d3Selection, d3Zoom, height, maxZoom, minZoom, storeApi, width]
  );

  return {
    zoomIn,
    zoomOut,
    zoomTo,
    setViewport,
    fitView,
    setCenter,
    fitBounds,
    transformX,
    transformY,
    transformZoom,
    getViewportPositionFromCanjuxPosition,
  };
};
