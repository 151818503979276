import {
  CanjuxState,
  DDPActionsParams,
  JuxStoreActionFn,
  setLayersData,
} from '@jux/canjux/core';

/**
 * Update a component style.
 */
export const updateComponentStyles: JuxStoreActionFn<
  DDPActionsParams['updateComponentStyles'],
  CanjuxState
> = ({ nodeId, styles, state }) => {
  state.components[nodeId].styles = styles;

  setLayersData(state);

  return state;
};
