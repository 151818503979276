import { makeInert, useStore } from '@jux/canjux/core';
import { styled } from '@jux/ui/components/common/mui';
import { withPx } from '@jux/ui/utils/css';
import type { FC, PropsWithChildren } from 'react';

const ViewportContainer = styled('div')({
  transformOrigin: '0 0',
  zIndex: 0,
  pointerEvents: 'none',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
});

/*
 * Viewport is a container for the canvas. It is responsible for rendering actual UI elements (nodes).
 */
export const Viewport: FC<PropsWithChildren> = ({ children }) => {
  const transform = useStore((s) => s.transform);
  const isLive = useStore((s) => s.disableNodesInteraction);
  const isTextEditMode = useStore((s) => s.textEditingNodeStack.length > 0);

  return (
    <ViewportContainer
      id="jux_viewport_container"
      style={{
        transform: `translate(${withPx(transform.x)}, ${withPx(
          transform.y
        )}) scale(${transform.zoom})`,
      }}
      inert={!isLive && !isTextEditMode ? makeInert() : undefined}
    >
      {children}
    </ViewportContainer>
  );
};
