import { MuiGrid, styled } from '@jux/ui/components/common/mui';
import { withImportant } from '@jux/ui/utils/css';
import { ItemBox } from '../../ItemLayout.style';

const UPLOADING_ASSET_Z_INDEX = 0;
const ASSET_PREVIEW_ITEM_CONTENT_Z_INDEX = UPLOADING_ASSET_Z_INDEX + 1;

export const AssetPreviewItemWrapper = styled(ItemBox, {
  shouldForwardProp: (prop) => prop !== 'isDraggable' && prop !== 'selected',
})<{
  isDraggable?: boolean;
  selected?: boolean;
}>(({ theme, isDraggable, selected }) => ({
  minWidth: 52,
  minHeight: 52,
  backgroundColor: theme.drimz.palette.transparent,
  border: '1px solid',
  borderColor: selected
    ? theme.drimz.palette.primary.main
    : theme.drimz.palette.divider.secondary,

  ...(!isDraggable && {
    cursor: withImportant('pointer'),
  }),

  '&:hover': {
    borderColor: selected
      ? theme.drimz.palette.primary.main
      : theme.drimz.palette.divider.primary,
  },
}));

export const AssetPreviewItemContent = styled(MuiGrid)(() => ({
  zIndex: ASSET_PREVIEW_ITEM_CONTENT_Z_INDEX,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'fit-content',
  height: 'fit-content',
  maxWidth: 42,
  maxHeight: 42,

  '> *': {
    width: '100%',
    height: '100%',
  },
}));
