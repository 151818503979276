/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';

export const DDPAlignVerticalIcon: IconType = (props: IconBaseProps) => (
  <CustomIcon
    tag="svg"
    width="16"
    height="16"
    attr={{
      viewBox: '0 0 16 16',
    }}
    child={[
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M2 2C2 1.44772 2.44772 1 3 1H13C13.5523 1 14 1.44772 14 2V3C14 3.55229 13.5523 4 13 4L3 4C2.44772 4 2 3.55229 2 3V2Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M2 7.5C2 6.94772 2.44772 6.5 3 6.5L13 6.5C13.5523 6.5 14 6.94772 14 7.5V8.5C14 9.05229 13.5523 9.5 13 9.5L3 9.5C2.44772 9.5 2 9.05229 2 8.5V7.5Z',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          fill: 'currentColor',
          d: 'M3 12C2.44772 12 2 12.4477 2 13V14C2 14.5523 2.44772 15 3 15L13 15C13.5523 15 14 14.5523 14 14V13C14 12.4477 13.5523 12 13 12L3 12Z',
        },
        child: [],
      },
    ]}
    {...props}
  />
);
