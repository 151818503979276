import { validateNodesComponents } from './validateNodesComponents';
import { validateNoDetachedNodes } from './validateNoDetachedNodes';
import { validateAssets } from './validateAssets';
import { CanjuxState } from '@jux/canjux/core';
import type { Draft as WritableDraft } from 'mutative';
import { validateRootNodes } from './validateRootNodes';
import { StateValidator } from './validator.interface';

export const validateAll: StateValidator = ({
  state,
  recover = false,
}: {
  state: WritableDraft<CanjuxState>;
  recover?: boolean;
}) => {
  validateRootNodes({ state, recover });
  validateNodesComponents({ state, recover });
  validateNoDetachedNodes({ state, recover });
  validateAssets({ state, recover });
};
