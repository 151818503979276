import { CanjuxState } from '@jux/canjux/core';
import { NodeProperties } from '@jux/data-entities';
import { createSelector } from 'reselect';

export const selectNodeProperties = (id: string) =>
  createSelector(
    (state: CanjuxState): NodeProperties | undefined =>
      state.canvases[state.currentCanvasName].nodes[id]?.properties,
    (properties) => properties
  );
