import { useCallback, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { mergeRefs } from 'react-merge-refs';
import { useHover } from 'usehooks-ts';
import {
  CANVAS_ITEMS,
  CanvasComponentItem,
  useAddCanvasItem,
  useDragItem,
  useGetTargetCenterPosition,
} from '@jux/ui/components/editor/hooks';
import { ComponentConfigWithStates } from '@jux/types';
import { useShowLoadingUntilElementIsReady } from './useShowLoadingUntilElementIsReady';
import { useReRenderOnStylesChangeEffect } from './useReRenderOnStylesChangeEffect';
import { getTransformRatio } from './getTransformRatio.util';

const LOADING_DISPLAY_THRESHOLD = 200;

export const useComponentPreviewItem = ({
  component,
}: {
  component: CanvasComponentItem;
}) => {
  const originStyles: ComponentConfigWithStates = component.data.styles ?? {
    root: {},
    states: {},
  };

  // Check if the component is currently visible to the user
  const [isInViewRef, isInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const { addItemToCanvas } = useAddCanvasItem();
  const { getTargetCenterPositionByDimensions } = useGetTargetCenterPosition();

  // used to calculate the size of the component
  const componentRectRef = useRef<HTMLElement>(null);
  const componentContainerRef = useRef<HTMLDivElement>(null);

  // used to control the drag mechanism of the component
  const {
    dragRef: componentPreviewItemWrapperDragRef,
    dragPreviewRef: componentDragRef,
    isDragging,
  } = useDragItem(CANVAS_ITEMS.Component, component);

  const componentPreviewItemWrapperHoverRef = useRef<HTMLDivElement>(null);
  const isPreviewItemHovered = useHover(componentPreviewItemWrapperHoverRef);

  const componentPreviewItemWrapperRef = mergeRefs([
    componentPreviewItemWrapperHoverRef,
    componentPreviewItemWrapperDragRef,
    isInViewRef,
  ]);

  const targetRectRef = useRef<DOMRect | null>(null);

  if (componentRectRef.current) {
    targetRectRef.current = componentRectRef.current.getBoundingClientRect();
  }
  const transformRatio = getTransformRatio({
    containerSize: {
      width: componentContainerRef.current?.clientWidth,
      height: componentContainerRef.current?.clientHeight,
    },
    targetSize: {
      width: targetRectRef.current?.width,
      height: targetRectRef.current?.height,
    },
  });

  const handleClick = useCallback(() => {
    if (componentRectRef.current === null) return;
    const { clientWidth, clientHeight } = componentRectRef.current;

    const centerPosition = getTargetCenterPositionByDimensions({
      width: clientWidth,
      height: clientHeight,
    });

    addItemToCanvas({
      item: component,
      dropPosition: centerPosition,
    });
  }, [addItemToCanvas, component, getTargetCenterPositionByDimensions]);

  const { showLoading } = useShowLoadingUntilElementIsReady({
    isElementInView: isInView,
    elementRef: componentRectRef,
    showLoadingThreshold: LOADING_DISPLAY_THRESHOLD,
  });

  /**
   * This is a way to trigger a re-render when the styles of the component changes.
   * In order to get the "up to date" computed component styles after the styles are changed,
   * it is necessary to trigger a re-render to this component.
   */
  useReRenderOnStylesChangeEffect(originStyles);

  return {
    componentContainerRef,
    componentDragRef,
    componentPreviewItemWrapperRef,
    componentRectRef,
    isDragging,
    isInView,
    isPreviewItemHovered,
    handleClick,
    originStyles,
    showLoading,
    transformRatio,
  };
};
