/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';

const { styled } = createDrimz();

type LeftNavContent_div_text_root_Props = PropsOf<
  typeof JuxReactElements.JuxText
>;

const LeftNavContent_div_text_root: FC<LeftNavContent_div_text_root_Props> =
  forwardRef((props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />);

LeftNavContent_div_text_root.displayName = 'LeftNavContent_div_text_root';

LeftNavContent_div_text_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        color: '#000000',
        width: 'auto',
        height: 'auto',
        fontFamily: 'Inter',
        fontSize: '20px',
        lineHeight: '130%',
        display: 'inline-block',
        overflowWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      },
    },
  }) as LeftNavContent_div_text_root_Props['rootElement'],
};

export type LeftNavContent_div_text_Props = LeftNavContent_div_text_root_Props;

export const LeftNavContent_div_text: FC<LeftNavContent_div_text_Props> =
  forwardRef<
    ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>,
    LeftNavContent_div_text_Props
  >((props, ref) => <LeftNavContent_div_text_root {...props} ref={ref} />);

LeftNavContent_div_text.displayName = 'LeftNavContent_div_text';
LeftNavContent_div_text.defaultProps = { text: 'Canvases' };

type LeftNavContent_div_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;

const LeftNavContent_div_root: FC<LeftNavContent_div_root_Props> = forwardRef(
  (props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />
);

LeftNavContent_div_root.displayName = 'LeftNavContent_div_root';

LeftNavContent_div_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: 'auto',
        height: 'auto',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        padding: '80px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderStyle: 'none',
        borderColor: '',
      },
    },
  }) as LeftNavContent_div_root_Props['rootElement'],
};

export type LeftNavContent_div_Props = LeftNavContent_div_root_Props & {
  LeftNavContent_div_text_Props?: LeftNavContent_div_text_Props;
};

export const LeftNavContent_div: FC<LeftNavContent_div_Props> = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>,
  LeftNavContent_div_Props
>(({ LeftNavContent_div_text_Props, ...props }, ref) => (
  <LeftNavContent_div_root
    {...props}
    ref={ref}
    data-jux-context="1d258b18-c790-405c-91a1-35d451783a40"
  >
    {props.children || (
      <>
        <LeftNavContent_div_text
          {...LeftNavContent_div_text_Props}
          data-jux-context="771681e6-8e48-4dc4-8f9c-f21e1361c798"
        />
      </>
    )}
  </LeftNavContent_div_root>
));

LeftNavContent_div.displayName = 'LeftNavContent_div';
LeftNavContent_div.defaultProps = {};

import { List_item, type List_item_Props } from '../';

import { List_action, type List_action_Props } from '../';

type LeftNavContent_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;

const LeftNavContent_root: FC<LeftNavContent_root_Props> = forwardRef(
  (props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />
);

LeftNavContent_root.displayName = 'LeftNavContent_root';

LeftNavContent_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        gap: '0px normal',
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '20px 10px 20px 10px',
        alignItems: 'stretch',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: 'rgba(255,203,71,0)',
      },
      variants: [
        {
          propsValues: {},
          styles: {
            '[data-jux-context="771681e6-8e48-4dc4-8f9c-f21e1361c798"]': {
              fontSize: '11px',
              fontWeight: '500',
              color: '{color.core.grays_500}',
            },
            '[data-jux-context="1d258b18-c790-405c-91a1-35d451783a40"]': {
              padding: '6px 8px 6px 8px',
              justifyContent: 'flex-start',
              display: 'flex',
              backgroundColor: '{color.core.grays_800}',
            },
          },
        },
      ],
    },
  }) as LeftNavContent_root_Props['rootElement'],
};

export type LeftNavContent_Props = LeftNavContent_root_Props & {
  LeftNavContent_div_Props?: LeftNavContent_div_Props;
  List_item_1_Props?: List_item_Props;
  List_item_2_Props?: List_item_Props;
  List_item_3_Props?: List_item_Props;
  List_item_4_Props?: List_item_Props;
  List_action_Props?: List_action_Props;
};

export const LeftNavContent: FC<LeftNavContent_Props> = forwardRef<
  ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>,
  LeftNavContent_Props
>(
  (
    {
      LeftNavContent_div_Props,
      List_item_1_Props,
      List_item_2_Props,
      List_item_3_Props,
      List_item_4_Props,
      List_action_Props,
      ...props
    },
    ref
  ) => (
    <LeftNavContent_root
      {...props}
      ref={ref}
      data-jux-context="88ceb51e-9193-4e96-9e1c-a55990d3ba8e"
    >
      {props.children || (
        <>
          <LeftNavContent_div
            {...LeftNavContent_div_Props}
            data-jux-context="1d258b18-c790-405c-91a1-35d451783a40"
          />
          <List_item
            disabled={false}
            Selected={'true'}
            {...List_item_1_Props}
          />
          <List_item
            disabled={false}
            Selected={'true'}
            {...List_item_2_Props}
          />
          <List_item
            disabled={false}
            Selected={'true'}
            {...List_item_3_Props}
          />
          <List_item
            disabled={false}
            Selected={'true'}
            {...List_item_4_Props}
          />
          <List_action
            disabled={false}
            hierarchy={'primary'}
            {...List_action_Props}
          />
        </>
      )}
    </LeftNavContent_root>
  )
);

LeftNavContent.displayName = 'LeftNavContent';
LeftNavContent.defaultProps = {};
