import { CanjuxState } from '@jux/canjux/core';
import { createSelector } from 'reselect';

export const selectHoveredNodeById = (id: string) =>
  createSelector(
    [(state: CanjuxState) => state.hoveredNodesStack || []],
    (hoveredNodesStack) => hoveredNodesStack.find((n) => n.id === id)
  );

export const isNodeSelected = (id: string) =>
  createSelector(
    [(state: CanjuxState) => state.selectedNodesStack],
    (selectedNodesStack) => selectedNodesStack.includes(id)
  );
