import {
  CommonActionsParams,
  JuxStore,
  JuxStoreActionFn,
} from '@jux/canjux/core';

/**
 * Update a node's canvas indicators position
 */
export const updateNodesIndicatorsPosition: JuxStoreActionFn<
  CommonActionsParams['updateNodesIndicatorsPosition'],
  JuxStore
> = ({ payload, state }) => {
  payload.forEach(({ nodeId, position }) => {
    state.canvasNodesIndicatorsPositions[nodeId] = {
      positionAbsolute: { ...position },
    };
  });

  return state;
};
