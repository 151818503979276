import { IsNotEmpty, IsString } from 'class-validator';

export class ComponentVersionBundle {
  @IsString()
  @IsNotEmpty()
  bundledCode: string;

  @IsString()
  @IsNotEmpty()
  reactLibraryCode: string;

  @IsString()
  @IsNotEmpty()
  reactDOMLibraryCode: string;
}
