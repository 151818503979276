import { createSelector } from 'reselect';
import { CanjuxState } from '@jux/canjux/core';
import { getNodeComponentById, getSelectedNodeComponent } from './utils';

export const selectedNodeDirectSource = createSelector(
  [
    (state: CanjuxState) => state.components,
    (state: CanjuxState) => state.selectedNodesStack,
  ],
  (components, selectedNodesStack) => {
    const component = getSelectedNodeComponent({
      components,
      selectedNodesStack,
    });
    if (!component) {
      return undefined;
    }

    const { sourceComponentId } = component;

    return sourceComponentId
      ? getNodeComponentById({ components, id: sourceComponentId })
      : undefined;
  }
);
