import { createSelector } from 'reselect';
import { CanjuxState } from '@jux/canjux/core';
import { getLocalComponentDependencies } from '../helpers/getLocalComponentDependencies';

export const selectNodeLocalComponentDependencies = createSelector(
  [(state: CanjuxState) => state.components],
  (components) =>
    (componentId: string): string[] | undefined => {
      const component = components[componentId];
      if (!component) return undefined;

      // Get local component dependencies as a list of display names
      return getLocalComponentDependencies({
        componentId: component.id,
        components,
      }).map((depId) => components[depId]?.displayName || depId);
    }
);
