import { CanjuxState, resolvedProps } from '@jux/canjux/core';
import { createSelector } from 'reselect';
import { selectedNodeId } from './selectedNodeId';

export const selectedNodeComponentVariantsPropsValues = createSelector(
  [
    selectedNodeId,
    (state: CanjuxState) => state.components,
    (state: CanjuxState) => state.assets,
  ],
  (id, components, assets) =>
    id
      ? resolvedProps({ assets, components, id, onlyVariantsProps: true })
      : undefined
);
